import { SnapPoints } from 'ui-components';

import { CanvasFrame } from '../types';

export default function computeFrameSnapPoints(
    frames: CanvasFrame[],
    excludedFrameId?: string,
    minX?: number,
    maxX?: number,
    minY?: number,
    maxY?: number,
): SnapPoints {
    const verticalPoints: {
        [key: number]: number;
    } = {};

    const horizontalPoints: {
        [key: number]: number;
    } = {};

    for (const frame of frames) {
        if (excludedFrameId && excludedFrameId === frame.frameId) {
            continue;
        }

        verticalPoints[frame.y] = frame.y;
        verticalPoints[frame.y + frame.height] = frame.y + frame.height;
        horizontalPoints[frame.x] = frame.x;
        horizontalPoints[frame.x + frame.width] = frame.x + frame.width;
    }

    if (minY !== undefined) {
        verticalPoints[minY] = minY;
    }

    if (maxY !== undefined) {
        verticalPoints[maxY] = maxY;
    }

    if (minX !== undefined) {
        horizontalPoints[minX] = minX;
    }

    if (maxX !== undefined) {
        horizontalPoints[maxX] = maxX;
    }

    return {
        vertical: Object.values(verticalPoints),
        horizontal: Object.values(horizontalPoints),
    };
}
