import { Domain } from 'api';

import { webshopPrescriptionApi } from '@/api';
import { makeActions, makeReducer, makeReducerActions, makeSelectors } from '@/makeOverviewState';

type WebshopPrescriptionProps = keyof Domain.WebshopPrescription;

export const defaultSorting: Domain.Sorting<WebshopPrescriptionProps> = {
    field: 'createdOn',
    direction: 'descending',
};

export const pageSize = 20;

export const selectors = makeSelectors<Domain.WebshopPrescription, WebshopPrescriptionProps>({
    getState: rootState => rootState.webshopPrescription.overview,
});

export const reducerActions = makeReducerActions<Domain.WebshopPrescription, WebshopPrescriptionProps>({
    reducerPrefix: '@webshopPrescription/overview',
});

export const actions = makeActions<Domain.WebshopPrescription, WebshopPrescriptionProps>({
    dataTableSaveKey: 'webshopPrescriptionsOverview-v5',
    loadApi: options => {
        if (options.globallySelectedWebshopId === undefined) {
            return;
        }

        return webshopPrescriptionApi.GetWebshopPrescriptions(
            options.pagination,
            options.sorting,
            options.globallySelectedWebshopId,
            options.filters,
            options.search,
        );
    },
    defaultSorting,
    pageSize,
    getBaseUrl: () => '/webshops/prescriptions',
    reducerActions,
    selectors,
});

export const overviewReducer = makeReducer<
    Domain.WebshopPrescription,
    WebshopPrescriptionProps,
    {
        name?: string;
        phoneNumber?: string;
        emailAddress?: string;
        barcode?: string;
    }
>({
    defaultSorting,
    pageSize,
    reducerActions,
});
