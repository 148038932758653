import { Domain } from 'api';

import { webshopDeliveryTimeApi } from '@/api';
import { makeActions, makeReducer, makeReducerActions, makeSelectors } from '@/makeDetailsState';

export const selectors = makeSelectors<Domain.WebshopDeliveryTime>({
    getState: rootState => rootState.webshopDeliveryTime.update,
});

export const reducerActions = makeReducerActions<Domain.WebshopDeliveryTime>({
    reducerPrefix: '@webshopDeliveryTime/update',
});

export const actions = makeActions<Domain.WebshopDeliveryTime>({
    loadApi: options => webshopDeliveryTimeApi.GetWebshopDeliveryTimeDetails(options.urlParams.webshopId, options.urlParams.deliveryTimeId),
    reducerActions,
    selectors,
});

export const updateReducer = makeReducer<Domain.WebshopDeliveryTime>({
    reducerActions,
});
