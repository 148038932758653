import { combineReducers } from '@reduxjs/toolkit';

import { Domain } from 'api';

import { ThunkAction } from '@/action';
import { companyApi, userApi, branchApi, deviceApi, webshopApi } from '@/api';
import { BranchProps, defaultSorting as branchesDefaultSorting, pageSize as branchesPageSize } from '@/Branch/overviewState';
import { DeviceProps, defaultSorting as devicesDefaultSorting, pageSize as devicesPageSize } from '@/Device/overviewState';
import * as makeDetailsState from '@/makeDetailsState';
import * as makeOverviewState from '@/makeOverviewState';
import { URLParams, URLQuery } from '@/routing';
import { UserProps, defaultSorting as usersDefaultSorting, pageSize as usersPageSize } from '@/User/overviewState';
import { WebshopProps, defaultSorting as webshopsDefaultSorting, pageSize as webshopsPageSize } from '@/Webshop/overviewState';

export const usersSelectors = makeOverviewState.makeSelectors<Domain.UserDetails, UserProps>({
    getState: rootState => rootState.company.details.users,
});

export const usersReducerActions = makeOverviewState.makeReducerActions<Domain.UserDetails, UserProps>({
    reducerPrefix: '@companyDetails/users/overview',
});

export const usersActions = makeOverviewState.makeActions<Domain.UserDetails, UserProps>({
    dataTableSaveKey: 'companyDetailsUsersOverview-v5',
    loadApi: options => userApi.GetUsers({ type: 'company', ownerId: options.urlParams.companyId }, options.pagination, options.sorting),
    defaultSorting: usersDefaultSorting,
    pageSize: usersPageSize,
    reducerActions: usersReducerActions,
    selectors: usersSelectors,
});

export const usersOverviewReducer = makeOverviewState.makeReducer<Domain.UserDetails, UserProps>({
    defaultSorting: usersDefaultSorting,
    pageSize: usersPageSize,
    reducerActions: usersReducerActions,
});

export const branchesSelectors = makeOverviewState.makeSelectors<Domain.Branch, BranchProps>({
    getState: rootState => rootState.company.details.branches,
});

export const branchesReducerActions = makeOverviewState.makeReducerActions<Domain.Branch, BranchProps>({
    reducerPrefix: '@companyDetails/branches/overview',
});

export const branchesActions = makeOverviewState.makeActions<Domain.Branch, BranchProps>({
    dataTableSaveKey: 'companyDetailsBranchesOverview-v5',
    loadApi: options =>
        branchApi.GetBranches({ type: 'company', ownerId: options.urlParams.companyId }, options.pagination, options.sorting),
    defaultSorting: branchesDefaultSorting,
    pageSize: branchesPageSize,
    reducerActions: branchesReducerActions,
    selectors: branchesSelectors,
});

export const branchesOverviewReducer = makeOverviewState.makeReducer<Domain.Branch, BranchProps>({
    defaultSorting: branchesDefaultSorting,
    pageSize: branchesPageSize,
    reducerActions: branchesReducerActions,
});

export const devicesSelectors = makeOverviewState.makeSelectors<Domain.Device, DeviceProps>({
    getState: rootState => rootState.company.details.devices,
});

export const devicesReducerActions = makeOverviewState.makeReducerActions<Domain.Device, DeviceProps>({
    reducerPrefix: '@companyDetails/devices/overview',
});

export const devicesActions = makeOverviewState.makeActions<Domain.Device, DeviceProps>({
    dataTableSaveKey: 'companyDetailsDevicesOverview-v5',
    loadApi: options =>
        deviceApi.GetDevices({ type: 'company', ownerId: options.urlParams.companyId }, options.pagination, options.sorting),
    defaultSorting: devicesDefaultSorting,
    pageSize: devicesPageSize,
    reducerActions: devicesReducerActions,
    selectors: devicesSelectors,
});

export const devicesOverviewReducer = makeOverviewState.makeReducer<Domain.Device, DeviceProps>({
    defaultSorting: devicesDefaultSorting,
    pageSize: devicesPageSize,
    reducerActions: devicesReducerActions,
});

export const webshopsSelectors = makeOverviewState.makeSelectors<Domain.Webshop, WebshopProps>({
    getState: rootState => rootState.company.details.webshops,
});

export const webshopsReducerActions = makeOverviewState.makeReducerActions<Domain.Webshop, WebshopProps>({
    reducerPrefix: '@companyDetails/webshops/overview',
});

export const webshopsActions = makeOverviewState.makeActions<Domain.Webshop, WebshopProps>({
    dataTableSaveKey: 'companyDetailsWebshopsOverview-v5',
    loadApi: options =>
        webshopApi.GetWebshops(
            { type: 'company', ownerId: options.urlParams.companyId },
            options.pagination,
            options.sorting,
            options.search,
            options.filters,
        ),
    defaultSorting: webshopsDefaultSorting,
    pageSize: webshopsPageSize,
    reducerActions: webshopsReducerActions,
    selectors: webshopsSelectors,
});

export const webshopsOverviewReducer = makeOverviewState.makeReducer<Domain.Webshop, WebshopProps>({
    defaultSorting: webshopsDefaultSorting,
    pageSize: webshopsPageSize,
    reducerActions: webshopsReducerActions,
});

export const selectors = makeDetailsState.makeSelectors<Domain.CompanyDetails>({
    getState: rootState => rootState.company.details.base,
});

export const reducerActions = makeDetailsState.makeReducerActions<Domain.CompanyDetails>({
    reducerPrefix: '@company/details',
});

const baseActions = makeDetailsState.makeActions<Domain.CompanyDetails>({
    loadApi: options => companyApi.GetCompanyDetails(options.urlParams.companyId),
    reducerActions,
    selectors,
});

export const actions = {
    ...baseActions,
    load:
        (options: { urlQuery: URLQuery; urlParams: URLParams }): ThunkAction =>
        async (dispatch, getState) => {
            const state = getState();
            const loadedCompany = selectors.maybeSelectDetails(state);

            if (loadedCompany && loadedCompany.companyId !== options.urlParams.companyId) {
                dispatch(usersActions.setPaginationPage(1));
                dispatch(branchesActions.setPaginationPage(1));
                dispatch(devicesActions.setPaginationPage(1));
                dispatch(webshopsActions.setPaginationPage(1));
            }

            const loads: Promise<any>[] = [dispatch(baseActions.load(options))];

            switch (options.urlParams.tab) {
                case 'users':
                    loads.push(dispatch(usersActions.load(options)));
                    break;
                case 'branches':
                    loads.push(dispatch(branchesActions.load(options)));
                    break;
                case 'devices':
                    loads.push(dispatch(devicesActions.load(options)));
                    break;
                case 'webshops':
                    loads.push(dispatch(webshopsActions.load(options)));
                    break;
                default:
            }

            await Promise.all(loads);
        },
};

const baseReducer = makeDetailsState.makeReducer<Domain.CompanyDetails>({
    reducerActions,
});

export const detailsReducer = combineReducers({
    base: baseReducer,
    users: usersOverviewReducer,
    branches: branchesOverviewReducer,
    devices: devicesOverviewReducer,
    webshops: webshopsOverviewReducer,
});
