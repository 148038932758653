import { Domain } from 'api';

import { webshopPromotionApi } from '@/api';
import { makeActions, makeReducer, makeReducerActions, makeSelectors } from '@/makeOverviewState';

type WebshopProductSelectionPromotionProps = keyof Domain.WebshopPromotion;

export const defaultSorting: Domain.Sorting<WebshopProductSelectionPromotionProps> = {
    field: 'campaignName',
    direction: 'ascending',
};

export const pageSize = 20;

export const selectors = makeSelectors<Domain.WebshopPromotion, WebshopProductSelectionPromotionProps>({
    getState: rootState => rootState.webshopPromotion.productSelectionPromotionOverview,
});

export const reducerActions = makeReducerActions<Domain.WebshopPromotion, WebshopProductSelectionPromotionProps>({
    reducerPrefix: '@webshopProductSelectionPromotion/overview',
});

export const actions = makeActions<Domain.WebshopPromotion, WebshopProductSelectionPromotionProps>({
    dataTableSaveKey: 'webshopProductSelectionPromotionsOverview-v5',
    loadApi: options => {
        if (options.globallySelectedWebshopId === undefined) {
            return;
        }

        return webshopPromotionApi.GetWebshopPromotions(options.pagination, options.sorting, options.globallySelectedWebshopId, {
            promotionType: 'productSelection',
        });
    },
    defaultSorting,
    pageSize,
    getBaseUrl: () => '/webshops/promotions/product-selection',
    reducerActions,
    selectors,
});

export const productSelectionPromotionReducer = makeReducer<Domain.WebshopPromotion, WebshopProductSelectionPromotionProps>({
    defaultSorting,
    pageSize,
    reducerActions,
});
