import {
    Locale,
    Ownership,
    Pagination,
    ProductSelectionType,
    CustomProductDetails,
    CustomProductsPage,
    CreateCustomProductPayload,
    UpdateCustomProductPayload,
    AssignMediaItemToCustomProductPayload,
    RemoveMediaItemFromCustomProductPayload,
    UpdateCustomProductSelectionTypesPayload,
    UpdateCustomProductPricingPayload,
} from '@/Domain';

import Endpoint from './Endpoint';

export default class CustomProductEndpoint extends Endpoint {
    public async GetCustomProducts(
        ownership: Ownership,
        pagination: Pagination,
        locale: Locale,
        selectionType?: ProductSelectionType,
        search?: string,
    ): Promise<CustomProductsPage> {
        if (ownership.type !== 'company' && ownership.type !== 'branch') {
            throw new Error('Unsupported ownership type');
        }

        const url = `/custom-product/${ownership.type}/${ownership.ownerId}/overview`;

        const response = await this.client.get({
            url,
            params: {
                pagination,
                selectionType,
                locale,
                search,
            },
        });

        this.guardResponseSchema('#/definitions/CustomProductsPage', response);

        return response;
    }

    public async GetCustomProductDetails(customProductId: string, locale?: Locale): Promise<CustomProductDetails> {
        const response: CustomProductDetails = await this.client.get({
            url: `/custom-product/${customProductId}/details`,
            params: {
                locale,
            },
        });

        if ((response.localizedDescriptions as any) instanceof Array) {
            response.localizedDescriptions = {};
        }

        this.guardResponseSchema('#/definitions/CustomProductDetails', response);

        return response;
    }

    public async CreateCustomProduct(payload: CreateCustomProductPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/CreateCustomProduct',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Product\\CustomProduct\\CreateCustomProduct\\CreateCustomProduct',
                payload,
            },
        });
    }

    public async UpdateCustomProduct(payload: UpdateCustomProductPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/UpdateCustomProduct',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Product\\CustomProduct\\UpdateCustomProduct\\UpdateCustomProduct',
                payload,
            },
        });
    }

    public async UpdateCustomProductPricing(payload: UpdateCustomProductPricingPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/UpdateCustomProductPricing',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Product\\CustomProduct\\UpdateCustomProductPricing\\UpdateCustomProductPricing',
                payload,
            },
        });
    }

    public async AssignMediaItemToCustomProduct(payload: AssignMediaItemToCustomProductPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/AssignMediaItemToCustomProduct',
            body: {
                commandName:
                    'App\\Domain\\WriteModel\\Product\\CustomProduct\\AssignMediaItemToCustomProduct\\AssignMediaItemToCustomProduct',
                payload,
            },
        });
    }

    public async UpdateCustomProductSelectionTypes(payload: UpdateCustomProductSelectionTypesPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/UpdateCustomProductSelectionTypes',
            body: {
                commandName:
                    'App\\Domain\\WriteModel\\Product\\CustomProduct\\UpdateCustomProductSelectionTypes\\UpdateCustomProductSelectionTypes',
                payload,
            },
        });
    }

    public async RemoveMediaItemFromCustomProduct(payload: RemoveMediaItemFromCustomProductPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/RemoveMediaItemFromCustomProduct',
            body: {
                commandName:
                    'App\\Domain\\WriteModel\\Product\\CustomProduct\\RemoveMediaItemFromCustomProduct\\RemoveMediaItemFromCustomProduct',
                payload,
            },
        });
    }

    public async RemoveCustomProduct(customProductId: string): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/DeleteCustomProduct',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Product\\CustomProduct\\DeleteCustomProduct\\DeleteCustomProduct',
                payload: {
                    productId: customProductId,
                },
            },
        });
    }
}
