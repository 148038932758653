import { combineReducers } from '@reduxjs/toolkit';

import { overviewReducer } from './overviewState';
import { previewReducer } from './previewState';
import { updateReducer } from './updateState';

export const productWallReducer = combineReducers({
    overview: overviewReducer,
    update: updateReducer,
    preview: previewReducer,
});
