import { Domain } from 'api';

import { getLanguageCodeFromLocale } from './getLanguageCodeFromLocale';

export default function localizeLanguageLabel(locale: Domain.Locale, gettext: (text: string) => string) {
    let languageLabel: string = locale;

    switch (getLanguageCodeFromLocale(locale)) {
        case 'en':
            languageLabel = gettext('English');
            break;
        case 'fr':
            languageLabel = gettext('French');
            break;
        case 'nl':
            languageLabel = gettext('Dutch');
            break;
        case 'de':
            languageLabel = gettext('German');
            break;
        case 'es':
            languageLabel = gettext('Spanish');
            break;
        case 'cs':
            languageLabel = gettext('Czech');
            break;
        case 'pt':
            languageLabel = gettext('Portuguese');
            break;
        case 'iw':
            languageLabel = gettext('Hebrew');
            break;
        case 'zh-CN':
            languageLabel = gettext('Chinese');
            break;
        case 'it':
            languageLabel = gettext('Italian');
            break;
        case 'ro':
            languageLabel = gettext('Romanian');
            break;
        case 'bg':
            languageLabel = gettext('Bulgarian');
            break;
        case 'pl':
            languageLabel = gettext('Polish');
            break;
        case 'tr':
            languageLabel = gettext('Turkish');
            break;
        case 'ar':
            languageLabel = gettext('Arabic');
            break;
        case 'ru':
            languageLabel = gettext('Russian');
            break;
    }

    return languageLabel;
}
