import { createAction, createReducer, Selector } from '@reduxjs/toolkit';

import { Domain } from 'api';
import { browserStorage } from 'utils';

import { ThunkAction, withPayloadType } from '@/action';
import { webshopApi } from '@/api';
import { maybeSelectLoggedInUser, selectLoggedInUserOwnership, selectLoggedInUserOwnershipIds } from '@/Authentication';
import { getCountryCodeFromLocale } from '@/getCountryCodeFromLocale';
import { RootState } from '@/store';

const WEBSHOP_GLOBAL_SELECTION_SAVE_KEY = 'webshopGlobalSelection-v1';

export interface State {
    noWebshopsToSelectFrom: boolean;
    webshop?: Domain.Webshop;
    locale?: Domain.Locale;
}

const initialState: State = {
    noWebshopsToSelectFrom: false,
};

const reducerActions = {
    setNoWebshopsToSelectFrom: createAction('@webshop/globalSelection/setNoWebshopsToSelectFrom', withPayloadType<boolean>()),
    setWebshop: createAction('@webshop/globalSelection/setWebshop', withPayloadType<Domain.Webshop | undefined>()),
    setWebshopLocale: createAction('@webshop/globalSelection/setWebshopLocale', withPayloadType<Domain.Locale | undefined>()),
};

export const globalSelectionReducer = createReducer(initialState, builder =>
    builder
        .addCase(reducerActions.setNoWebshopsToSelectFrom, (state, action) => {
            state.noWebshopsToSelectFrom = action.payload;
        })
        .addCase(reducerActions.setWebshop, (state, action) => {
            state.webshop = action.payload;
            state.locale = action.payload ? action.payload.defaultLocale || undefined : undefined;
        })
        .addCase(reducerActions.setWebshopLocale, (state, action) => {
            state.locale = action.payload;
        }),
);

export const selectNoWebshopsToSelectFrom: Selector<RootState, boolean> = state => {
    return state.webshop.globalSelection.noWebshopsToSelectFrom;
};

export const selectGlobalSelectedWebshop: Selector<RootState, Domain.Webshop | undefined> = state => {
    return state.webshop.globalSelection.webshop;
};

export const selectGlobalSelectedWebshopId: Selector<RootState, Domain.Webshop['webshopId'] | undefined> = state => {
    return state.webshop.globalSelection.webshop ? state.webshop.globalSelection.webshop.webshopId : undefined;
};

export const selectGlobalSelectedWebshopLocales: Selector<RootState, Domain.Webshop['locales']> = state => {
    return state.webshop.globalSelection.webshop ? state.webshop.globalSelection.webshop.locales : [];
};

export const selectGlobalSelectedWebshopCountry: Selector<RootState, Domain.Country> = state => {
    if (state.webshop.globalSelection.webshop) {
        if (state.webshop.globalSelection.webshop.defaultLocale) {
            return getCountryCodeFromLocale(state.webshop.globalSelection.webshop.defaultLocale);
        } else if (state.webshop.globalSelection.webshop.locales.length > 0) {
            return getCountryCodeFromLocale(state.webshop.globalSelection.webshop.locales[0]);
        }
    }

    return 'BE';
};

export const selectGlobalSelectedWebshopDefaultLocale: Selector<RootState, Domain.Locale | undefined> = state => {
    return state.webshop.globalSelection.webshop ? state.webshop.globalSelection.webshop.defaultLocale || undefined : undefined;
};

export const selectGlobalSelectedWebshopLocale: Selector<RootState, Domain.Locale | undefined> = state => {
    return state.webshop.globalSelection.locale;
};

export const InitGlobalSelectedWebshopId = (): ThunkAction => async (dispatch, getState) => {
    const state = getState();
    const alreadyLoaded =
        (selectGlobalSelectedWebshop(state) && selectGlobalSelectedWebshopLocale(state)) || selectNoWebshopsToSelectFrom(state);

    if (alreadyLoaded) {
        return;
    }

    const userIsLoggedIn = maybeSelectLoggedInUser(state);
    if (!userIsLoggedIn) {
        return;
    }

    const ownershipIds = selectLoggedInUserOwnershipIds(state);
    const stateSaveKey = WEBSHOP_GLOBAL_SELECTION_SAVE_KEY + '-' + ownershipIds.companyId + '-' + ownershipIds.branchId;
    const savedWebshopId = browserStorage.getItem(stateSaveKey);

    let loadedFromExistingWebshopId = false;
    if (savedWebshopId && savedWebshopId.value) {
        try {
            const webshop = await webshopApi.GetWebshopDetails(savedWebshopId.value);
            dispatch(reducerActions.setWebshop(webshop));
            loadedFromExistingWebshopId = true;
        } catch (e) {
            browserStorage.removeItem(stateSaveKey);
        }
    }

    if (!loadedFromExistingWebshopId) {
        const ownership = selectLoggedInUserOwnership(state);
        const results = await webshopApi.GetWebshops(
            ownership,
            {
                page: 1,
                size: 1,
            },
            {
                field: 'name',
                direction: 'ascending',
            },
        );

        if (results.items.length > 0) {
            const webshop = results.items[0];
            browserStorage.setItem(stateSaveKey, webshop.webshopId);
            dispatch(reducerActions.setWebshop(webshop));
            dispatch(reducerActions.setWebshopLocale(webshop.locales[0]));
        } else if (results.total === 0) {
            dispatch(reducerActions.setNoWebshopsToSelectFrom(true));
        }
    }
};

export const ClearGlobalSelectedWebshop = (): ThunkAction => async dispatch => {
    await dispatch(reducerActions.setWebshop(undefined));
    await dispatch(reducerActions.setNoWebshopsToSelectFrom(false));
};

export const SetGlobalSelectedWebshopId =
    (webshopId: Domain.Webshop['webshopId']): ThunkAction =>
    async (dispatch, getState) => {
        const state = getState();
        const userIsLoggedIn = maybeSelectLoggedInUser(state);
        if (!userIsLoggedIn) {
            return;
        }

        const ownershipIds = selectLoggedInUserOwnershipIds(state);
        const stateSaveKey = WEBSHOP_GLOBAL_SELECTION_SAVE_KEY + '-' + ownershipIds.companyId + '-' + ownershipIds.branchId;

        browserStorage.setItem(stateSaveKey, webshopId);
        const webshop = await webshopApi.GetWebshopDetails(webshopId);
        dispatch(reducerActions.setWebshop(webshop));
    };

export const SetGlobalSelectedWebshopLocale =
    (locale: Domain.Locale): ThunkAction =>
    async dispatch => {
        dispatch(reducerActions.setWebshopLocale(locale));
    };
