import { CanvasFrame, Canvas } from '../types';

export const SET_CANVAS = 'SET_CANVAS';
export const HISTORY_SAVE = 'HISTORY_SAVE';
export const HISTORY_UNDO = 'HISTORY_UNDO';
export const HISTORY_REDO = 'HISTORY_REDO';
export const HISTORY_RESET = 'HISTORY_RESET';
export const HISTORY_POP = 'HISTORY_POP';
export const SET_DRAGGED_BLOCK_TYPE = 'SET_DRAGGED_BLOCK_TYPE';
export const SET_DRAGGED_BLOCK_POSITION = 'SET_DRAGGED_BLOCK_POSITION';
export const SET_DRAGGED_BLOCK_DROPPED_FLAG = 'SET_DRAGGED_BLOCK_DROPPED_FLAG';
export const STOP_DRAGGING_BLOCK = 'STOP_DRAGGING_BLOCK';
export const ADD_FRAME = 'ADD_FRAME';
export const SET_FRAME_COORDINATES = 'SET_FRAME_COORDINATES';
export const SET_RESIZED_FRAME = 'SET_RESIZED_FRAME';
export const SET_FRAME_TOP_OFFSET = 'SET_FRAME_TOP_OFFSET';
export const SET_FRAME_LEFT_OFFSET = 'SET_FRAME_LEFT_OFFSET';
export const SET_FRAME_RIGHT_OFFSET = 'SET_FRAME_RIGHT_OFFSET';
export const SET_FRAME_BOTTOM_OFFSET = 'SET_FRAME_BOTTOM_OFFSET';
export const SET_FRAME_OFFSET = 'SET_FRAME_OFFSET';
export const SET_EDITED_FRAME = 'SET_EDITED_FRAME';
export const REMOVE_FRAME = 'REMOVE_FRAME';
export const SET_FRAME_DEPTH = 'SET_FRAME_DEPTH';

interface SetCanvasAction {
    type: typeof SET_CANVAS;
    canvas: Canvas;
}

interface HistorySaveAction {
    type: typeof HISTORY_SAVE;
}

interface HistoryResetAction {
    type: typeof HISTORY_RESET;
}

interface HistoryPopAction {
    type: typeof HISTORY_POP;
}

interface HistoryUndoAction {
    type: typeof HISTORY_UNDO;
}

interface HistoryRedoAction {
    type: typeof HISTORY_REDO;
}

interface SetDraggedBlockTypeAction {
    type: typeof SET_DRAGGED_BLOCK_TYPE;
    draggedBlockType: string;
    draggedBlockWidth?: number;
    draggedBlockHeight?: number;
}

interface SetDraggedBlockPositionAction {
    type: typeof SET_DRAGGED_BLOCK_POSITION;
    x: number;
    y: number;
}

interface SetDraggedBlockDroppedFlagAction {
    type: typeof SET_DRAGGED_BLOCK_DROPPED_FLAG;
    dropped: boolean;
}

interface StopDraggingBlockAction {
    type: typeof STOP_DRAGGING_BLOCK;
}

interface AddFrameAction {
    type: typeof ADD_FRAME;
    frame: CanvasFrame;
}

interface SetResizedFrameAction {
    type: typeof SET_RESIZED_FRAME;
    resizedFrameId?: string;
}

interface SetFrameTopOffsetAction {
    type: typeof SET_FRAME_TOP_OFFSET;
    frameId: string;
    offset: number;
}

interface SetFrameBottomOffsetAction {
    type: typeof SET_FRAME_BOTTOM_OFFSET;
    frameId: string;
    offset: number;
}

interface SetFrameLeftOffsetAction {
    type: typeof SET_FRAME_LEFT_OFFSET;
    frameId: string;
    offset: number;
}

interface SetFrameRightOffsetAction {
    type: typeof SET_FRAME_RIGHT_OFFSET;
    frameId: string;
    offset: number;
}

interface SetFrameOffsetAction {
    type: typeof SET_FRAME_OFFSET;
    frameId: string;
    offsetX: number;
    offsetY: number;
}

interface SetFrameCoordinatesAction {
    type: typeof SET_FRAME_COORDINATES;
    frameId: string;
    x: number;
    y: number;
    width: number;
    height: number;
}

interface SetEditedFrameAction {
    type: typeof SET_EDITED_FRAME;
    editedFrameId?: string;
}

interface RemoveFrameAction {
    type: typeof REMOVE_FRAME;
    frameId: string;
}

interface SetFrameDepthAction {
    type: typeof SET_FRAME_DEPTH;
    frameId: string;
    depth: number;
}

export type ActionTypes =
    | SetCanvasAction
    | HistorySaveAction
    | HistoryResetAction
    | HistoryPopAction
    | HistoryUndoAction
    | HistoryRedoAction
    | SetDraggedBlockTypeAction
    | SetDraggedBlockPositionAction
    | SetDraggedBlockDroppedFlagAction
    | StopDraggingBlockAction
    | AddFrameAction
    | SetResizedFrameAction
    | SetFrameTopOffsetAction
    | SetFrameBottomOffsetAction
    | SetFrameLeftOffsetAction
    | SetFrameRightOffsetAction
    | SetFrameOffsetAction
    | SetFrameCoordinatesAction
    | SetEditedFrameAction
    | RemoveFrameAction
    | SetFrameDepthAction;
