import * as React from 'react';
import { useSelector } from 'react-redux';

import { Device, maybeSelectLoggedInUser } from 'data-store';

import { useVideoCallContext } from './VideoCallContext';

export default function useVideoCallTrigger() {
    const user = useSelector(maybeSelectLoggedInUser);
    const device = useSelector(Device.globalSelectionState.selectGlobalSelectedDevice);
    const context = useVideoCallContext();

    const isEnabled = !!(
        user &&
        user.role === 'branchAdministrator' &&
        device &&
        device.videoPhoneIsAvailable &&
        device.videoPhoneAnswerAsSIPExtension &&
        device.videoPhoneAnswerAsSIPSecret
    );

    const handleClickInsidePanel = (event: React.MouseEvent) => {
        event.stopPropagation();
    };

    React.useEffect(() => {
        const handleClickOutsidePanel = () => {
            if (context.panelIsOpened && context.panelCanBeClosed) {
                context.setPanelIsOpened(false);
            }
        };

        document.addEventListener('click', handleClickOutsidePanel);

        return () => {
            document.removeEventListener('click', handleClickOutsidePanel);
        };
    }, [context.panelIsOpened]);

    return {
        isEnabled,
        sipCredentials: {
            extension: device?.videoPhoneAnswerAsSIPExtension || undefined,
            secret: device?.videoPhoneAnswerAsSIPSecret || undefined,
        },
        context,
        handleClickInsidePanel,
    };
}
