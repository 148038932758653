import * as React from 'react';

import { ISearchProvider } from 'utils';

import Card from '@/card/Card';

import Input, { InputProps } from './Input';

import styles from './SuggestionsInput.scss';

export type SuggestionsInputProps = InputProps & {
    suggestionsProvider: ISearchProvider<{
        value: string;
        label: React.ReactNode;
    }>;
    applySuggestion: (suggestion: string) => void;
};

function SuggestionsInput(props: SuggestionsInputProps) {
    const { suggestionsProvider, applySuggestion, ...rest } = props;
    const [showSuggestions, setShowSuggestions] = React.useState(false);
    const [suggestions, setSuggestions] = React.useState<
        {
            value: string;
            label: React.ReactNode;
        }[]
    >([]);

    const refresh = async (query: string) => {
        if (query) {
            setSuggestions(await suggestionsProvider.search(query));
        } else {
            setSuggestions([]);
        }
    };

    return (
        <div className={styles.SuggestionsInput}>
            <Input
                {...rest}
                onChange={newValue => {
                    refresh(newValue);

                    if (props.onChange) {
                        props.onChange(newValue);
                    }
                }}
                onFocus={() => setShowSuggestions(true)}
                onClick={() => setShowSuggestions(true)}
                onBlur={() => setShowSuggestions(false)}
            />
            {showSuggestions && suggestions.length > 0 ? (
                <Card
                    elevated={true}
                    className={styles.SuggestionsInputDropdown}
                    hSpacing="none"
                >
                    {suggestions.slice(0, 5).map(suggestion => (
                        <div
                            key={suggestion.value}
                            className={styles.SuggestionsInputSuggestion}
                            onMouseDown={e => {
                                e.preventDefault();
                                e.stopPropagation();
                                applySuggestion(suggestion.value);
                                refresh(suggestion.value);
                                setShowSuggestions(false);
                            }}
                        >
                            {suggestion.label}
                        </div>
                    ))}
                </Card>
            ) : null}
        </div>
    );
}

export default React.forwardRef<HTMLInputElement, SuggestionsInputProps>((props, ref) => {
    return (
        <SuggestionsInput
            forwardRef={ref}
            {...props}
        />
    );
});
