import { Pagination } from './Pagination';
import { Sorting } from './Sorting';

export const ApiKeyScopes = ['reservations', 'orders', 'shipments', 'webhooks', 'stock', 'prices'] as const;
export type ApiKeyScope = (typeof ApiKeyScopes)[number];

export type ApiKey = {
    apiKeyId: string;
    companyId: string;
    branchId: string;
    branchDetails: {
        branchName: string;
    };
    companyDetails: {
        name: string;
    };
    key: string;
    name: string;
    scopes: ApiKeyScope[];
    created: string;
    deactivatedOn?: string | null;
};

export type ApiKeyDetails = Omit<ApiKey, 'branchDetails' | 'companyDetails'>;

export interface ApiKeysPage {
    items: ApiKey[];
    total: number;
    pagination: Pagination;
    sorting?: Sorting<keyof ApiKey> | null;
    search?: string | null;
}

export interface CreateApiKeyPayload {
    companyId: string;
    branchId: string;
    name: string;
    scopes: ApiKeyScope[];
}

export interface UpdateApiKeyPayload {
    apiKeyId: ApiKey['apiKeyId'];
    name: string;
    scopes: ApiKeyScope[];
}

export interface DeactivateApiKeyPayload {
    apiKeyId: ApiKey['apiKeyId'];
}
