export const IHatchStateType = {
    CLOSED: 'CLOSED',
    OPENING: 'OPENING',
    OPENED: 'OPENED',
    CLOSING: 'CLOSING',
    UNKNOWN: 'UNKNOWN',
} as const;

export const IConveyorStateType = {
    IDLE: 'IDLE',
    MOVINGBACKWARD: 'MOVINGBACKWARD',
    MOVINGFORWARD: 'MOVINGFORWARD',
} as const;

export const ILightsStateType = {
    OFF: 'OFF',
    ON: 'ON',
} as const;

export interface IDeviceHardwareStatus {
    CustomerHatchState: keyof typeof IHatchStateType;
    PharmacistHatchState: keyof typeof IHatchStateType;
    ConveyorState: keyof typeof IConveyorStateType;
    LightsState: keyof typeof ILightsStateType;
}
