import { createAction, Selector, createReducer } from '@reduxjs/toolkit';

import { Domain } from 'api';

import { ThunkAction, withPayloadType } from '@/action';
import { webshopApi, webshopTagApi } from '@/api';
import { URLParams } from '@/routing';
import { RootState } from '@/store';

export interface State {
    webshop?: Domain.Webshop;
    webshopTag?: Domain.WebshopTag;
}

const initialState: State = {};

const reducerActions = {
    setWebshop: createAction('@webshopTag/update/setWebshop', withPayloadType<Domain.Webshop>()),
    setWebshopTag: createAction('@webshopTag/update/setWebshopTag', withPayloadType<Domain.WebshopTag>()),
};

export const updateReducer = createReducer(initialState, builder =>
    builder
        .addCase(reducerActions.setWebshop, (state, action) => {
            state.webshop = action.payload;
        })
        .addCase(reducerActions.setWebshopTag, (state, action) => {
            state.webshopTag = action.payload;
        }),
);

export const selectWebshop: Selector<RootState, Domain.Webshop> = state => {
    const webshop = state.webshopTag.update.webshop;
    if (!webshop) {
        throw new Error('Webshop not loaded');
    }

    return webshop;
};

export const selectWebshopTag: Selector<RootState, Domain.WebshopTag> = state => {
    const webshopTag = state.webshopTag.update.webshopTag;
    if (!webshopTag) {
        throw new Error('Tag not loaded');
    }

    return webshopTag;
};

export const loadWebshop =
    (webshopId: Domain.Webshop['webshopId']): ThunkAction<Promise<Domain.Webshop>> =>
    async dispatch => {
        const webshop = await webshopApi.GetWebshopDetails(webshopId);

        await dispatch(reducerActions.setWebshop(webshop));

        return webshop;
    };

export const loadWebshopTag =
    (webshopId: Domain.Webshop['webshopId'], tagId: Domain.WebshopTag['tagId']): ThunkAction<Promise<Domain.WebshopTag>> =>
    async dispatch => {
        const webshopTag = await webshopTagApi.GetWebshopTagDetails(webshopId, tagId);

        await dispatch(reducerActions.setWebshopTag(webshopTag));

        return webshopTag;
    };

export const load =
    (options: { urlParams: URLParams }): ThunkAction =>
    async dispatch => {
        await Promise.all([
            dispatch(loadWebshop(options.urlParams.webshopId)),
            dispatch(loadWebshopTag(options.urlParams.webshopId, options.urlParams.tagId)),
        ]);
    };
