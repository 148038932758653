export const SubscribableNotifications = [
    'dailyDigestOfOutOfStockProductsInDeviceEmail',
    'weeklyDigestOfOutOfStockProductsInDeviceEmail',
    'salesDailyDigestEmail',
    'salesWeeklyDigestEmail',
    'orderNeedsReviewEmail',
    'febelcoBackOrderCanceledEmail',
    'febelcoBackOrderConfirmedEmail',
    'febelcoBackOrderConfirmedWithNotesEmail',
    'customerMTMaticPickupFailed',
    'robotEmergencyStopWasTriggered',
] as const;

export type SubscribableNotification = (typeof SubscribableNotifications)[number];
