import { Domain } from 'api';
import { computeProductSize } from 'utils';

import { ShelfWithMeta, ShelfStyleParameters, Layout, PlacedProduct, ProductsShelfAreaBox, ProductImageSizes } from '../types';
import fillProductArea from './fillProductArea';
import getSelectedImage from './getSelectedImage';

export default function computeShelfMetas(
    layout: Layout,
    screenResolution: Domain.DeviceScreenResolution,
    availableProducts: Domain.SlideshowProduct[],
    productImageSizes: ProductImageSizes,
): ShelfWithMeta[] {
    const LayoutParams = ShelfStyleParameters[layout.style];

    return layout.shelves.map(shelf => {
        let meta;
        if (shelf.type === 'productsShelf') {
            let maxBottom = 0;
            const placedProducts: {
                product: Domain.SlideshowProduct;
                area: PlacedProduct;
                boxes: ProductsShelfAreaBox[];
            }[] = [];

            shelf.products.forEach(area => {
                const product = availableProducts.find(prod => prod.productId === area.productId);

                if (!product) {
                    placedProducts.push({
                        product: {
                            productId: area.productId,
                            width: 1,
                            length: 1,
                            depth: 1,
                            productCodes: {},
                        },
                        area,
                        boxes: [],
                    });
                    return;
                }

                const selectedImage = getSelectedImage(area, product);
                const imageUrl = selectedImage.url;

                let boxes: ProductsShelfAreaBox[] = [];
                if (productImageSizes.hasOwnProperty(imageUrl)) {
                    const productSize = computeProductSize(screenResolution, product, productImageSizes[imageUrl], true, area.version || 0);
                    const fill = fillProductArea({
                        areaWidth: area.width > 1 ? area.width : 1,
                        areaHeight: area.height > 1 ? area.height : 1,
                        productWidth: productSize.width,
                        productHeight: productSize.height,
                        scale: area.scale && area.scale > 0 ? area.scale : 1,
                        itemsLimit: area.enableItemsLimit ? area.itemsLimit : undefined,
                        alignItems: area.alignItems || 'center',
                        spacingX: area.spacingX || 0,
                        spacingY: area.spacingY || 0,
                        bottomOffset: LayoutParams.shelfBottomOffset,
                    });

                    boxes = fill.boxes;

                    if (boxes.length > 0) {
                        for (const box of boxes) {
                            maxBottom = Math.max(maxBottom, box.bottom + box.height);
                        }
                    }
                }

                placedProducts.push({
                    product,
                    area,
                    boxes,
                });
            });

            meta = {
                maxBottom,
                placedProducts,
            };
        }

        return {
            shelf,
            meta,
        };
    });
}
