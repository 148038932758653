import { createAction, createReducer, Selector } from '@reduxjs/toolkit';

import { Domain } from 'api';

import { ThunkAction, withPayloadType } from '@/action';
import { localisationApi } from '@/api';
import { RootState } from '@/store';

export interface State {
    localisations?: Domain.LocalisationsPage;
}

const initialState: State = {};

const reducerActions = {
    setLocalisations: createAction('@localisation/overview/setLocalisations', withPayloadType<Domain.LocalisationsPage>()),
};

export const overviewReducer = createReducer(initialState, builder =>
    builder.addCase(reducerActions.setLocalisations, (state, action) => {
        state.localisations = action.payload;
    }),
);

export const maybeSelectLocalisations: Selector<RootState, Domain.LocalisationsPage | undefined> = state =>
    state.localisation.overview.localisations;
export const selectLocalisations: Selector<RootState, Domain.LocalisationsPage> = state => {
    const localisations = maybeSelectLocalisations(state);

    if (!localisations) {
        throw new Error('Localisations not loaded');
    }

    return localisations;
};

export const loadLocalisations =
    (pageSize = 30): ThunkAction =>
    async dispatch => {
        const localisations = await localisationApi.GetLocalisations({ page: 1, size: pageSize });
        dispatch(reducerActions.setLocalisations(localisations));
    };

export const load = (): ThunkAction => async dispatch => {
    await dispatch(loadLocalisations());
};
