export class CancellablePromise<T> {
    private _logic: () => Promise<T>;
    private _cancel: (value: T) => void;
    private _isCanceled = false;

    public constructor(logic: () => Promise<T>) {
        this._logic = logic;
    }

    public execute(): Promise<T> {
        return new Promise(resolve => {
            this._cancel = resolve;
            this._logic()
            .then(data => {
                if (!this._isCanceled) {
                    resolve(data);
                }
            })
            .catch(error => {
                console.error("Promise rejected with: ", error);
                if (!this._isCanceled) {
                    resolve(error);
                }
            });
        })
    }

    public cancel(value: T): void {
        this._isCanceled = true;
        this._cancel(value);
    }
}