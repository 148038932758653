import * as React from 'react';

import classNames from 'classnames';

import { CanvasFrame } from 'editor-canvas';
import { RTView, RTValue } from 'ui-components';
import { formatPrice } from 'utils';

import { store } from '../state';
import type { ProductFrame } from '../types';
import { computeFrameMetas, getProductImages, getSelectedImage } from '../utils';
import FrameEyedropperArea from './FrameEyedropperArea';

import styles from './styles/ProductFrame.scss';

export default function ProductFrame(props: {
    frame: CanvasFrame & ProductFrame;
    onMouseDown?: () => void;
    onMouseUp?: () => void;
    onMouseMove?: (x: number, y: number, w: number, h: number) => void;
    onMouseOut?: () => void;
    locale: string;
    proxyImageURL: (url: string) => string;
}) {
    const { frame, locale, proxyImageURL } = props;
    const { state } = React.useContext(store);

    if (!frame.productId) {
        return null;
    }

    const product = state.availableProducts.find(searchedProduct => searchedProduct.productId === frame.productId);

    if (!product) {
        return null;
    }

    const frameMeta = computeFrameMetas(state.screenResolution, frame, state.availableProducts, state.productImageSizes);

    if (!frameMeta.boxes) {
        return null;
    }

    let priceStyle = state.canvas.priceStyle;
    let promoPriceStyle = state.canvas.promoPriceStyle;

    if (frame.placedProduct.enableCustomPriceStyling && frame.placedProduct.customPriceStyle) {
        priceStyle = frame.placedProduct.customPriceStyle;
    }

    if (frame.placedProduct.enableCustomPriceStyling && frame.placedProduct.customPromoPriceStyle) {
        promoPriceStyle = frame.placedProduct.customPromoPriceStyle;
    }

    const productImages = getProductImages(product);
    const selectedImage = getSelectedImage(productImages, frame.productMediaType, frame.imageId);

    return (
        <div className={styles.ProductFrame}>
            {frameMeta.boxes.map(box => (
                <React.Fragment key={box.key}>
                    {state.eyedropperEnabled ? (
                        <FrameEyedropperArea
                            onMouseDown={props.onMouseDown}
                            onMouseUp={props.onMouseUp}
                            onMouseMove={props.onMouseMove}
                            onMouseOut={props.onMouseOut}
                            style={{
                                height: box.height + 'px',
                                width: box.width + 'px',
                                left: box.left + 'px',
                                bottom: box.bottom + 'px',
                            }}
                        />
                    ) : null}

                    <img
                        src={proxyImageURL(
                            selectedImage
                                ? selectedImage.url
                                : frame.productMediaType === 'packshot'
                                  ? productImages.productImage
                                  : productImages.frontalImage,
                        )}
                        style={{
                            height: box.height + 'px',
                            width: box.width + 'px',
                            left: box.left + 'px',
                            bottom: box.bottom + 'px',
                        }}
                        alt=""
                    />
                </React.Fragment>
            ))}

            {frame.placedProduct.showPrice ? (
                <div className={styles.PlacedProductPriceWrap}>
                    {product.promoPrice !== undefined ? (
                        <div
                            className={classNames(
                                styles.PlacedProductPrice,
                                styles.PlacedProductPromoPrice,
                                frame.placedProduct.hideOriginalPrice ? styles.PlacedProductHideOriginalPrice : undefined,
                                promoPriceStyle.shape,
                            )}
                            style={{
                                backgroundColor: promoPriceStyle.backgroundColor,
                                borderColor: promoPriceStyle.borderColor,
                            }}
                        >
                            {!frame.placedProduct.hideOriginalPrice && product.price !== undefined ? (
                                <RTView
                                    value={new RTValue('', promoPriceStyle.text)}
                                    className={styles.PlacedProductOldPrice}
                                >
                                    {formatPrice(product.price.amount / 100, locale, product.price.currency.name)}
                                </RTView>
                            ) : null}

                            <RTView value={new RTValue('', promoPriceStyle.promoText)}>
                                {formatPrice(product.promoPrice.amount / 100, locale, product.promoPrice.currency.name)}
                            </RTView>
                        </div>
                    ) : product.price !== undefined ? (
                        <div
                            className={classNames(styles.PlacedProductPrice, priceStyle.shape)}
                            style={{
                                backgroundColor: priceStyle.backgroundColor,
                                borderColor: priceStyle.borderColor,
                            }}
                        >
                            <RTView value={new RTValue('', priceStyle.text)}>
                                {formatPrice(product.price.amount / 100, locale, product.price.currency.name)}
                            </RTView>
                        </div>
                    ) : null}
                </div>
            ) : null}
        </div>
    );
}
