import {
    Pagination,
    Sorting,
    Ownership,
    WebshopOrderShipmentsPage,
    WebshopOrderShipmentDetails,
    OrderShipmentLabelExportType,
    OrderShipmentExternalStatusesList,
} from '@/Domain';

import Endpoint from './Endpoint';

export default class WebshopOrderShipmentEndpoint extends Endpoint {
    public async GetWebshopOrderShipments(
        ownership: Ownership,
        pagination: Pagination,
        sorting: Sorting,
        filters?: {
            webshopId?: string;
            orderId?: string;
            shipmentStatus?: string;
            carrierCode?: string;
            externalReferenceId?: string;
        },
        search?: string,
    ): Promise<WebshopOrderShipmentsPage> {
        const filtersObject = { ...filters };
        let url = '/webshop/shipment/overview';

        if (ownership.type === 'branch' || ownership.type === 'company') {
            if (!filters || !filters.webshopId) {
                throw new Error('filters.webshopId is required for ownership type ' + ownership.type);
            }
            url = `/webshop/${filters.webshopId}/shipment/overview`;
            delete filtersObject.webshopId;
        }

        // Aparently api can't handle `all`
        if (filtersObject.webshopId === 'all') {
            filtersObject.webshopId = undefined;
        }

        const response = await this.client.get({
            url,
            params: {
                pagination,
                sorting,
                filters: filtersObject,
                search,
            },
        });

        this.guardResponseSchema('#/definitions/WebshopOrderShipmentsPage', response);

        return response;
    }

    public async GetWebshopOrderShipmentDetails(webshopId: string, shipmentId: string): Promise<WebshopOrderShipmentDetails> {
        const response = await this.client.get({
            url: `/webshop/${webshopId}/shipment/${shipmentId}/details`,
        });

        this.guardResponseSchema('#/definitions/WebshopOrderShipmentDetails', response);

        return response;
    }

    public GetWebshopOrderShipmentLabelExportURL(
        webshopId: string,
        shipmentId: string,
        labelSizeAndPlacement: OrderShipmentLabelExportType,
    ): string {
        return this.client.buildApiURL(`/webshop/${webshopId}/shipment/${shipmentId}/label/${labelSizeAndPlacement}`, true);
    }

    public async GetExternalStatuses(webshopId: string): Promise<OrderShipmentExternalStatusesList> {
        const response = await this.client.get({
            url: `/webshop/${webshopId}/shipment/externalStatusOverview`,
        });

        this.guardResponseSchema('#/definitions/OrderShipmentExternalStatusesList', response);

        return response;
    }
}
