import { Domain } from 'api';

import { ThunkAction } from '@/action';
import { webshopApi } from '@/api';
import { loadConfiguredIntegrationsCountByType } from '@/Integration/configuredIntegrationsTypeState';
import { makeActions, makeReducer, makeReducerActions, makeSelectors } from '@/makeDetailsState';
import { URLParams } from '@/routing';

export const selectors = makeSelectors<Domain.WebshopShippingMethodDetails>({
    getState: rootState => rootState.webshopShippingMethod.update,
});

export const reducerActions = makeReducerActions<Domain.WebshopShippingMethodDetails>({
    reducerPrefix: '@webshopShippingMethod/update',
});

const baseActions = makeActions<Domain.WebshopShippingMethodDetails>({
    loadApi: async options => {
        const shippingMethodType = options.urlParams.shippingMethodType as Domain.WebshopShippingMethodType;

        if (
            Domain.WebshopShippingMethodTypes.indexOf(shippingMethodType) === -1 ||
            shippingMethodType === 'clickAndCollect' ||
            shippingMethodType === 'doorDashDelivery'
        ) {
            throw new Error('Invalid shipping method type');
        }

        if (!options.globallySelectedWebshopId) {
            throw new Error('No selected webshop');
        }

        const configuration = await webshopApi.GetWebshopConfiguration(options.globallySelectedWebshopId);

        if (!configuration.shippingMethods) {
            throw new Error('Shipping method not found');
        }

        const shippingMethod = configuration.shippingMethods[shippingMethodType];

        if (!shippingMethod) {
            throw new Error('Shipping method not found');
        }

        return shippingMethod;
    },
    reducerActions,
    selectors,
});

export const actions = {
    ...baseActions,
    load:
        (options: { urlParams: URLParams }): ThunkAction =>
        async dispatch => {
            await Promise.all([dispatch(baseActions.load(options)), dispatch(loadConfiguredIntegrationsCountByType())]);
        },
};

export const updateReducer = makeReducer<Domain.WebshopShippingMethodDetails>({
    reducerActions,
});
