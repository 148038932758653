import {
    Locale,
    Pagination,
    Sorting,
    WebshopLibraryPageDetails,
    UpdateWebshopLibraryPagePayload,
    ValidateWebshopLibraryPageTitleResponse,
    RemoveWebshopLibraryPagePayload,
    WebshopLibraryPagesPage,
} from '@/Domain';

import Endpoint from './Endpoint';

export default class PageLibraryEndpoint extends Endpoint {
    public async GetWebshopLibraryPages(
        pagination: Pagination,
        sorting: Sorting,
        locale: Locale,
        search?: string,
        filters?: {
            locale?: Locale;
            webshopPageType?: WebshopLibraryPageDetails['webshopPageType'];
        },
    ): Promise<WebshopLibraryPagesPage> {
        const url = `/library-page/overview/${locale}`;

        const response = await this.client.get({
            url,
            params: {
                pagination,
                sorting,
                search,
                filters: {
                    ...filters,
                },
            },
        });

        this.guardResponseSchema('#/definitions/WebshopLibraryPagesPage', response);

        return response;
    }

    public async GetWebshopLibraryPageDetails(pageId: string): Promise<WebshopLibraryPageDetails> {
        const response = await this.client.get({
            url: `/library-page/${pageId}/details`,
        });

        if (response.blocks) {
            response.blocks = response.blocks.map((block: any) => {
                return {
                    ...block,
                    widgets: block.widgets.map((widget: any) => {
                        if (widget.details && widget.details instanceof Array) {
                            widget.details = {};
                        }

                        return widget;
                    }),
                };
            });
        }

        this.guardResponseSchema('#/definitions/WebshopLibraryPageDetails', response);

        return response;
    }

    public async ValidateWebshopLibraryPageTitle(
        pageId: string,
        locale: Locale,
        title: string,
    ): Promise<ValidateWebshopLibraryPageTitleResponse> {
        const response: ValidateWebshopLibraryPageTitleResponse = await this.client.get({
            url: `/library-page/${pageId}/${locale}/validateTitle/${title}`,
        });

        this.guardResponseSchema('#/definitions/ValidateWebshopLibraryPageTitleResponse', response);

        return response;
    }

    public async UpdateWebshopLibraryPage(payload: UpdateWebshopLibraryPagePayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/UpdateWebshopLibraryPage',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Webshop\\Page\\UpdateLibraryPage\\UpdateWebshopLibraryPage',
                payload,
            },
        });
    }

    public async CreateWebshopLibraryPage(payload: UpdateWebshopLibraryPagePayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/CreateWebshopLibraryPage',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Webshop\\Page\\UpdateLibraryPage\\UpdateWebshopLibraryPage',
                payload,
            },
        });
    }

    public async RemoveWebshopLibraryPage(payload: RemoveWebshopLibraryPagePayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/RemoveWebshopLibraryPage',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Webshop\\Page\\RemoveLibraryPage\\RemoveLibraryPage',
                payload,
            },
        });
    }
}
