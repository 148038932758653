import {
    Wwks2StatusDialog,
    wwks2StatusComponent,
    wwks2StatusResponse,
    wwks2StatusRequest
} from '../../../../Domain';
import Wwks2CommClient from '../../Wwks2CommClient';
import { DIALOG_TIMEOUTS } from '../Wwks2DefaultValues';


export default class Wwks2Status {

    private Wwks2CommClient: Wwks2CommClient;

    public constructor(Wwks2CommClient: Wwks2CommClient) {
        this.Wwks2CommClient = Wwks2CommClient;
    }

    public onStatusRequest: (data: wwks2StatusRequest) => void;
    public onStatusChange: (data: wwks2StatusResponse) => void;

    public sendStatusRequest(IncludeDetails: boolean = false, dialogId: string | number | boolean = false): Promise<Wwks2StatusDialog> {
        return new Promise(resolve => {

            if(typeof dialogId === 'boolean'){
                dialogId = this.Wwks2CommClient.GetWwks2DialogId();
            }

            const wwks = {
                'StatusRequest': {
                    '@': {
                        'Id': dialogId,
                        'Source': this.Wwks2CommClient.GetSourceId(),
                        'Destination': this.Wwks2CommClient.GetClientId(),
                        'IncludeDetails': IncludeDetails
                    }
                }
            }
            this.Wwks2CommClient.createAndSendDialog(wwks, 'Status', dialogId).then(status => {
                resolve(status);
            });

        });
    }

    public sendAndParseStatusRequest(IncludeDetails: boolean = false, dialogId: string | number | boolean = false): Promise<Wwks2StatusDialog> {
        return new Promise(resolve => {
            this.sendStatusRequest(IncludeDetails, dialogId)
                .then((data) => {
                    if (data.status) {
                        const timeoutTimer = setTimeout(() => {

                            let processStatusDialog = this.Wwks2CommClient.GetProcessedDialogData(data.dialogId);
                            if (typeof processStatusDialog !== 'boolean') {
                                if (processStatusDialog.typeDialog === 'Status') {
                                    processStatusDialog.status.cancel = true;
                                    this.Wwks2CommClient.SetProcessedDialogData(data.dialogId, processStatusDialog);
                                }
                            }
                        }, DIALOG_TIMEOUTS.Status);
                        this.Wwks2CommClient.getResponse(data.dialogId, 'Status')
                            .then((status) => {
                                clearTimeout(timeoutTimer);
                                resolve(status)
                            });
                    } else {
                        resolve(data);
                    }
                });
        });
    }

    public sendStatusResponse(state: string, stateText: string = '', components: wwks2StatusComponent[] = [], dialogId: string | number): Promise<Wwks2StatusDialog> {
        return new Promise(resolve => {
            const ComponentMap: any = [];
            components.forEach(component => {
                if (typeof component.type !== 'undefined' && typeof component.description !== 'undefined' && typeof component.state !== 'undefined') {
                    const comp: any = {
                        'Type': component.type,
                        'Description': component.description,
                        'State': component.state
                    };

                    if (typeof component.stateText !== 'undefined') {
                        comp.StateText = component.stateText;
                    }
                    ComponentMap.push({ '@': comp })
                }
            });

            const wwks = {
                'StatusResponse': {
                    '@': {
                        'Id': dialogId,
                        'Source': this.Wwks2CommClient.GetSourceId(),
                        'Destination': this.Wwks2CommClient.GetClientId(),
                        'State': state,
                        'StateText': stateText
                    },
                    '#': {
                        'Component': ComponentMap
                    }
                }
            }
            this.Wwks2CommClient.createAndSendDialog(wwks, 'Status', dialogId).then(status => {
                resolve(status);
            });

        });
    }

    public handleMessage(WWKS: any) {

        if (WWKS.StatusResponse) {
            const responseId = WWKS.StatusResponse['@attributes'].Id;
            const responseData: wwks2StatusResponse = {
                'clientId': WWKS.StatusResponse['@attributes'].Source,
                'dialogId': responseId,
                'state': 'notready',
                'stateText': '',
                'storageSystem': {},
                'boxSystem': {}
            }

            if (typeof WWKS.StatusResponse['@attributes'].State !== 'undefined') {
                responseData.state = WWKS.StatusResponse['@attributes'].State.toLowerCase();
            }

            if (typeof WWKS.StatusResponse.Component !== 'undefined') {

                let _components: any[];

                if (!Array.isArray(WWKS.StatusResponse.Component)) {
                    _components = [WWKS.StatusResponse.Component];
                } else {
                    _components = WWKS.StatusResponse.Component;
                }

                _components.forEach((component) => {
                    if (typeof component['@attributes'] !== 'undefined') {
                        if (typeof component['@attributes'].Type !== 'undefined' && typeof component['@attributes'].Description !== 'undefined' && typeof component['@attributes'].State !== 'undefined') {
                            const componentType: wwks2StatusComponent = {
                                'type': component['@attributes'].Type,
                                'description': component['@attributes'].Description,
                                'state': component['@attributes'].State.toLowerCase(),
                                'stateText': ''
                            }

                            if (typeof component['@attributes'].StateText !== 'undefined') {
                                componentType.stateText = component['@attributes'].StateText;
                            }

                            if (component['@attributes'].Type == 'StorageSystem') {
                                if (typeof responseData.storageSystem !== 'undefined') {
                                    if (typeof responseData.storageSystem[componentType.description] == 'undefined') {
                                        responseData.storageSystem[componentType.description] = componentType;
                                    }
                                    responseData.storageSystem[componentType.description] = componentType;
                                }
                            } else if (component['@attributes'].Type == 'BoxSystem') {

                                if (typeof responseData.boxSystem !== 'undefined') {
                                    if (typeof responseData.boxSystem[componentType.description] == 'undefined') {
                                        responseData.boxSystem[componentType.description] = componentType;
                                    }
                                    responseData.boxSystem[componentType.description] = componentType;
                                }
                            }
                        }
                    }
                });
            }

            let processStatusDialog = this.Wwks2CommClient.GetProcessedDialogData(responseId);
            if (typeof processStatusDialog !== 'boolean') {
                if (processStatusDialog.typeDialog === 'Status') {
                    processStatusDialog.status.response = true;
                    processStatusDialog.timestamps.response = Date.now();
                    processStatusDialog.data.response = responseData;
                    this.Wwks2CommClient.SetProcessedDialogData(responseId, processStatusDialog)
                }
            }else{
                //status response can also be pushed from the robot > trigger an event
                if (typeof this.onStatusChange !== 'undefined') {
                    this.onStatusChange(responseData);
                }
            }
        }

        if (WWKS.StatusRequest) {
            const requestId = WWKS.StatusRequest['@attributes'].Id;

            const responseData: wwks2StatusRequest = {
                'clientId': WWKS.StatusRequest['@attributes'].Source,
                'dialogId': requestId,
                'includeDetails': false
            }

            if (typeof WWKS.StatusRequest['@attributes'].IncludeDetails !== 'undefined') {
                responseData.includeDetails = WWKS.StatusRequest['@attributes'].IncludeDetails;
            }

            if (typeof this.onStatusRequest !== 'undefined') {
                this.onStatusRequest(responseData);
            }
        }

    }

    public setOnStatusRequestCallback(callback: (data: wwks2StatusRequest) => void) {
        this.onStatusRequest = callback;
    }

    public setOnStatusChangeCallback(callback: (data: wwks2StatusResponse) => void) {
        this.onStatusChange = callback;
    }
}