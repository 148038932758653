import * as React from 'react';

import styles from './Menu.scss';

export default function MenuItem(
    props: {
        children?: React.ReactNode;
        otherChildren?: React.ReactNode;
        className?: string;
    } & React.HTMLProps<HTMLSpanElement>,
) {
    const { className, children, otherChildren, ...rest } = props;
    return (
        <span
            className={styles.MenuItemWrapper + ' ' + (className || '')}
            {...rest}
        >
            <span className={styles.MenuItem}>{props.children}</span>

            {otherChildren}
        </span>
    );
}
