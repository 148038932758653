import { config } from 'config';
import { preloadImage, preloadVideo } from 'preloaders';

export function preloadMediaItem(mediaItemId: string | undefined, isVideo: boolean): Promise<void> {
    if (!mediaItemId) {
        return Promise.resolve();
    }

    const mediaItemUrl = `${config.apiBaseUrl}/media-item/${mediaItemId}/preview`;

    if (isVideo) {
        return preloadVideo(mediaItemUrl);
    }

    return preloadImage(mediaItemUrl);
}

export function preloadVideoMediaItemPreview(mediaItemId: string | undefined): Promise<void> {
    if (!mediaItemId) {
        return Promise.resolve();
    }

    return preloadImage(`${config.apiBaseUrl}/media-item/${mediaItemId}/preview/450x450`);
}
