import { Domain } from 'api';

import { webshopTagApi } from '@/api';
import { makeActions, makeReducer, makeReducerActions, makeSelectors } from '@/makeOverviewState';

type WebshopTagProps = keyof Domain.WebshopTag;

export const defaultSorting: Domain.Sorting<WebshopTagProps> = {
    field: 'name',
    direction: 'ascending',
};

export const pageSize = 20;

export const selectors = makeSelectors<Domain.WebshopTag, WebshopTagProps>({
    getState: rootState => rootState.webshopTag.overview,
});

export const reducerActions = makeReducerActions<Domain.WebshopTag, WebshopTagProps>({
    reducerPrefix: '@webshopTag/overview',
});

export const actions = makeActions<
    Domain.WebshopTag,
    WebshopTagProps,
    {
        authorUserId?: string;
        locale?: Domain.Locale;
    }
>({
    dataTableSaveKey: 'webshopTagsOverview-v5',
    loadApi: options => {
        if (options.globallySelectedWebshopId === undefined) {
            return;
        }

        return webshopTagApi.GetWebshopTags(
            options.pagination,
            options.sorting,
            options.globallySelectedWebshopId,
            options.filters.locale || options.globallySelectedWebshopDefaultLocale || options.locale,
            options.search,
            options.filters,
        );
    },
    defaultSorting,
    pageSize,
    getBaseUrl: () => '/webshops/news-article-tags',
    reducerActions,
    selectors,
});

export const overviewReducer = makeReducer<Domain.WebshopTag, WebshopTagProps>({
    defaultSorting,
    pageSize,
    reducerActions,
});
