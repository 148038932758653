import {
    SharingGroupsPage,
    Company,
    CreateSharingGroupPayload,
    InviteCompanyToSharingGroupPayload,
    RemoveCompaniesFromGroupPayload,
    AddCompaniesToGroupPayload,
    UpdateSharingGroupPayload,
    Pagination,
    Sorting,
    SharingGroup,
    SharingGroupDetails,
} from '@/Domain';

import Endpoint from './Endpoint';

export default class SharingGroupEndpoint extends Endpoint {
    public async GetCompanySharingGroups(
        companyId: Company['companyId'],
        pagination: Pagination,
        sorting: Sorting,
        search?: string,
        filters?: {
            isActive?: boolean;
        },
    ): Promise<SharingGroupsPage> {
        const companies = await this.client.get({
            url: `/company/${companyId}/sharing-groups/overview`,
            params: {
                pagination,
                sorting,
                search,
                filters,
            },
        });

        this.guardResponseSchema('#/definitions/SharingGroupsPage', companies);

        return companies;
    }

    public async GetSharingGroups(
        pagination: Pagination,
        sorting: Sorting,
        search?: string,
        filters?: {
            isActive?: boolean;
            ownerCompanyId?: Company['companyId'];
        },
    ): Promise<SharingGroupsPage> {
        const companies = await this.client.get({
            url: '/sharing-group/overview',
            params: {
                pagination,
                sorting,
                search,
                filters,
            },
        });

        this.guardResponseSchema('#/definitions/SharingGroupsPage', companies);

        return companies;
    }

    public async GetSharingGroupDetails(sharingGroupId: SharingGroup['sharingGroupId']): Promise<SharingGroupDetails> {
        const sharingGroup = await this.client.get({
            url: `/sharing-group/${sharingGroupId}/details`,
        });

        this.guardResponseSchema('#/definitions/SharingGroupDetails', sharingGroup);

        return sharingGroup;
    }

    public async CreateSharingGroup(payload: CreateSharingGroupPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/CreateSharingGroup',
            body: {
                commandName: 'App\\Domain\\WriteModel\\SharingGroup\\CreateSharingGroup\\CreateSharingGroup',
                payload,
            },
        });
    }

    public async UpdateSharingGroup(payload: UpdateSharingGroupPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/UpdateSharingGroup',
            body: {
                commandName: 'App\\Domain\\WriteModel\\SharingGroup\\UpdateSharingGroup\\UpdateSharingGroup',
                payload,
            },
        });
    }

    public async InviteCompanyToSharingGroup(payload: InviteCompanyToSharingGroupPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/InviteCompanyToSharingGroup',
            body: {
                commandName:
                    'App\\Domain\\WriteModel\\SharingGroup\\SharingGroupInvitationRequest\\InviteCompanyToSharingGroup\\InviteCompanyToSharingGroup',
                payload,
            },
        });
    }

    public async AcceptSharingGroupInvitation(sharingGroupInvitationCode: string): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/AcceptSharingGroupInvitation',
            body: {
                commandName:
                    'App\\Domain\\WriteModel\\SharingGroup\\SharingGroupInvitationRequest\\AcceptSharingGroupInvitation\\AcceptSharingGroupInvitation',
                payload: {
                    sharingGroupInvitationCode,
                },
            },
        });
    }

    public async RemoveCompaniesFromGroup(payload: RemoveCompaniesFromGroupPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/RemoveCompaniesFromGroup',
            body: {
                commandName: 'App\\Domain\\WriteModel\\SharingGroup\\RemoveCompaniesFromGroup\\RemoveCompaniesFromGroup',
                payload,
            },
        });
    }

    public async AddCompaniesToGroup(payload: AddCompaniesToGroupPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/AddCompaniesToGroup',
            body: {
                commandName: 'App\\Domain\\WriteModel\\SharingGroup\\AddCompaniesToGroup\\AddCompaniesToGroup',
                payload,
            },
        });
    }
}
