import { Domain } from 'api';

import { externalSourceOrderApi } from '@/api';
import { makeActions, makeReducer, makeReducerActions, makeSelectors } from '@/makeDetailsState';

export const selectors = makeSelectors<Domain.BaseOrderDetails>({
    getState: rootState => rootState.externalSourceOrder.details,
});

export const reducerActions = makeReducerActions<Domain.BaseOrderDetails>({
    reducerPrefix: '@externalSourceOrder/details',
});

export const actions = makeActions<Domain.BaseOrderDetails>({
    loadApi: options => externalSourceOrderApi.GetOrderDetails(options.urlParams.orderId),
    reducerActions,
    selectors,
});

export const detailsReducer = makeReducer<Domain.BaseOrderDetails>({
    reducerActions,
});
