import { Domain } from 'api';

import { resellerApi } from '@/api';
import { makeActions, makeReducer, makeReducerActions, makeSelectors } from '@/makeOverviewState';

type ResellerProps = keyof Domain.Reseller;

const defaultSorting: Domain.Sorting<ResellerProps> = {
    field: 'name',
    direction: 'ascending',
};

const pageSize = 20;

export const selectors = makeSelectors<Domain.Reseller, ResellerProps>({
    getState: rootState => rootState.reseller.overview,
});

export const reducerActions = makeReducerActions<Domain.Reseller, ResellerProps>({
    reducerPrefix: '@reseller/overview',
});

export const actions = makeActions<Domain.Reseller, ResellerProps>({
    dataTableSaveKey: 'resellersOverview-v5',
    loadApi: options => resellerApi.GetResellers(options.pagination, options.sorting, options.search, options.filters),
    defaultSorting,
    pageSize,
    getBaseUrl: () => '/customers/resellers',
    reducerActions,
    selectors,
});

export const overviewReducer = makeReducer<Domain.Reseller, ResellerProps>({
    defaultSorting,
    pageSize,
    reducerActions,
});
