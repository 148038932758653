import * as React from 'react';

import classNames from 'classnames';

import { InputBase, InputBaseProps } from '@/core';

import { CheckboxListContext } from './CheckboxListContext';

import styles from './CheckboxList.scss';

export type ControlledCheckboxListProps = InputBaseProps & {
    onChange?: (newValue: string[]) => void;
    forwardRef?: React.Ref<HTMLSelectElement>;
};

export type ControlledCheckboxListPropsWithValue = ControlledCheckboxListProps & {
    value: string[];
};

class ControlledCheckboxList extends InputBase<ControlledCheckboxListPropsWithValue> {
    protected renderInput(): React.ReactNode {
        const { forwardRef, disabled } = this.props;
        const name = this.getName();
        const value = this.getValue();

        const context = {
            name,
            onChange: this.handleCheckboxChange,
            value,
            disabled,
        };

        return (
            <>
                <CheckboxListContext.Provider value={context}>
                    <select
                        className={styles.HiddenSelect}
                        name={name}
                        disabled={disabled}
                        ref={forwardRef}
                        value={value}
                        multiple
                        onChange={() => {
                            // do nothing
                        }}
                    >
                        {value.map(
                            (option: string): React.ReactNode => (
                                <option
                                    key={option}
                                    value={option}
                                >
                                    {option}
                                </option>
                            ),
                        )}
                    </select>

                    {this.props.children}
                </CheckboxListContext.Provider>
            </>
        );
    }

    private handleCheckboxChange = (_1: boolean, checkValue?: string): void => {
        const { onChange } = this.props;
        const value = this.getValue();

        if (!checkValue || !onChange) {
            return;
        }

        const indexOfValue = value.indexOf(checkValue);
        let newValue: string[] = value;

        if (indexOfValue < 0) {
            newValue = [...value, checkValue];
        } else if (indexOfValue > -1) {
            newValue = value.filter((_, i) => i !== indexOfValue);
        }

        onChange(newValue);
    };

    private getValue(): string[] {
        return this.props.value || [];
    }

    private getName(): string {
        return this.props.name || 'checkbox-list';
    }

    protected getBaseWrapperComponent(): string {
        return 'div';
    }

    protected getClassName(): string {
        return classNames(super.getClassName(), styles.CheckboxList);
    }

    protected getStyle(): React.CSSProperties | undefined {
        return this.props.style;
    }
}

export default React.forwardRef<HTMLSelectElement, ControlledCheckboxListPropsWithValue>((props, ref) => {
    return (
        <ControlledCheckboxList
            forwardRef={ref}
            {...props}
        />
    );
});
