import * as React from 'react';

type IProps = React.HTMLAttributes<HTMLInputElement> & {
    value: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    forwardRef?: React.Ref<HTMLDivElement>;
};

class ContentEditable extends React.Component<IProps> {
    private ce = React.createRef<HTMLDivElement>();
    private lastValue: string;

    render() {
        const { value, onChange, forwardRef, ...rest } = this.props;

        if (forwardRef) {
            this.ce = forwardRef as any;
        }

        return (
            <div
                {...rest}
                ref={this.ce}
                onInput={this.handleChange}
                onBlur={this.handleChange}
                onPaste={this.handlePaste}
                contentEditable
                dangerouslySetInnerHTML={{ __html: value }}
            />
        );
    }

    shouldComponentUpdate(nextProps: Readonly<IProps>): boolean {
        return !!(this.ce.current && nextProps.value !== this.ce.current.innerHTML);
    }

    componentDidUpdate() {
        if (this.ce.current && this.props.value !== this.ce.current.innerHTML) {
            this.ce.current.innerHTML = this.props.value;
        }
    }

    private handleChange = () => {
        if (!this.ce.current) {
            return;
        }

        const value = this.ce.current.innerHTML;

        if (this.props.onChange && value !== this.lastValue) {
            this.props.onChange({
                target: {
                    value,
                },
            } as React.ChangeEvent<HTMLInputElement>);
        }

        this.lastValue = value;
    };

    private handlePaste = (event: React.ClipboardEvent) => {
        event.preventDefault();
        const text = event.clipboardData.getData('text/plain');
        document.execCommand('inserttext', false, text);
    };
}

export default React.forwardRef<HTMLDivElement, IProps>((props, ref) => {
    return (
        <ContentEditable
            forwardRef={ref}
            {...props}
        />
    );
});
