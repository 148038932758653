import { APIAvailableProductCategories, AvailableProductCategory, ScientificCategory, APIScientificCategories } from './Domain';

export function mapAPICategoriesToCategory(categoriesList: APIAvailableProductCategories): AvailableProductCategory {
    return {
        categoryId: 'ROOT',
        parentCategoryId: null,
        localizedNames: {},
        sequence: 0,
        industry: 'Pharmacy',
        country: 'BE',
        subCategories: mapAPICategoriesToCategoryList(categoriesList),
    };
}

function mapAPICategoriesToCategoryList(categoriesList: APIAvailableProductCategories): AvailableProductCategory[] {
    return categoriesList
        .map(category => {
            return {
                ...category,
                subCategories: mapAPICategoriesToCategoryList(category.subCategories),
            };
        })
        .sort((a, b) => a.sequence - b.sequence);
}

export function mapAPIScientificCategoriesToScientificCategory(categoriesList: APIScientificCategories): ScientificCategory {
    return {
        scientificCategoryId: 'ROOT',
        parentCategoryId: null,
        localizedNames: {},
        sequence: 0,
        externalReferenceId: '',
        subCategories: mapAPIScientificCategoriesToScientificCategoryList(categoriesList, null),
    };
}

function mapAPIScientificCategoriesToScientificCategoryList(
    categoriesList: APIScientificCategories,
    parentCategoryId: string | null,
): ScientificCategory[] {
    return categoriesList
        .filter(category => category.parentCategoryId === parentCategoryId)
        .map(category => {
            return {
                ...category,
                subCategories: mapAPIScientificCategoriesToScientificCategoryList(categoriesList, category.scientificCategoryId),
            };
        })
        .sort((a, b) => a.sequence - b.sequence);
}
