import { combineReducers } from '@reduxjs/toolkit';

import { calendarViewReducer } from './calendarViewState';
import { configurationReducer } from './configurationState';
import { detailsReducer } from './detailsState';
import { overviewReducer } from './overviewState';

export const appointmentsReducer = combineReducers({
    overview: overviewReducer,
    details: detailsReducer,
    configuration: configurationReducer,
    calendarView: calendarViewReducer,
});
