import * as React from 'react';

import { Portal } from 'ui-components';

import { store, Actions } from '../state';
import { Frame, getFrameMediaUrl, getFrameMediaCrop, hasEyedropCapableFrameMedia, ImageFrameCrop, ShelfWithMeta } from '../types';
import { findPlacedProduct, getSelectedImage } from '../utils';

import styles from './styles/EyedropperTool.scss';

const TOOL_SAMPLES = 9;
const TOOL_PIXEL_SCALE = 8;

export default function EyedropperTool(props: {
    getMediaItemURL: (mediaItemId: string) => string;
    getProxyURL: (url: string) => string;
    shelves: ShelfWithMeta[];
}) {
    const { state, dispatch } = React.useContext(store);
    const [x, setX] = React.useState(0);
    const [y, setY] = React.useState(0);
    const canvas = React.createRef<HTMLCanvasElement>();
    const canvasContext = React.useRef<CanvasRenderingContext2D | null>();
    const [img, setImg] = React.useState<HTMLImageElement | undefined>();
    const mouseWasDown = React.useRef(false);

    React.useEffect(() => {
        const moveHandler = (event: MouseEvent) => {
            setX(event.pageX);
            setY(event.pageY);
        };

        document.addEventListener('mousemove', moveHandler);

        return () => {
            document.removeEventListener('mousemove', moveHandler);
        };
    }, [canvasContext.current]);

    React.useEffect(() => {
        const clickHandler = (event: MouseEvent) => {
            if (canvasContext.current && state.eyedropperToolFrameInfo.frameId) {
                event.preventDefault();
                event.stopImmediatePropagation();
                event.stopPropagation();
            }
        };

        document.addEventListener('click', clickHandler);
        document.addEventListener('mousedown', clickHandler);

        return () => {
            document.removeEventListener('click', clickHandler);
            document.removeEventListener('mousedown', clickHandler);
        };
    }, [canvasContext.current, state.eyedropperToolFrameInfo]);

    React.useEffect(() => {
        if (
            canvasContext.current &&
            (state.eyedropperToolFrameInfo.frameId ||
                (state.eyedropperToolFrameInfo.shelfId && state.eyedropperToolFrameInfo.placedProductId)) &&
            state.eyedropperChangeHandlers
        ) {
            const pixels = canvasContext.current.getImageData(0, 0, TOOL_SAMPLES * TOOL_PIXEL_SCALE, TOOL_SAMPLES * TOOL_PIXEL_SCALE).data;
            const xyCenter = Math.ceil((TOOL_SAMPLES * TOOL_PIXEL_SCALE) / 2);
            const i = (xyCenter * TOOL_SAMPLES * TOOL_PIXEL_SCALE + xyCenter) * 4;

            if (!mouseWasDown.current && state.eyedropperToolFrameInfo.isDown) {
                state.eyedropperChangeHandlers.changeStart();
            }

            if (state.eyedropperToolFrameInfo.isDown) {
                state.eyedropperChangeHandlers.change(pixels[i], pixels[i + 1], pixels[i + 2]);
            }

            if (mouseWasDown.current && !state.eyedropperToolFrameInfo.isDown) {
                state.eyedropperChangeHandlers.changeComplete(pixels[i], pixels[i + 1], pixels[i + 2]);

                dispatch({
                    type: Actions.SET_EYEDROPPER_ENABLED,
                    eyedropperEnabled: false,
                });
            }

            mouseWasDown.current = state.eyedropperToolFrameInfo.isDown;
        }
    }, [canvasContext.current, state.eyedropperToolFrameInfo, state.eyedropperChangeHandlers]);

    React.useEffect(() => {
        if (canvas.current) {
            canvasContext.current = canvas.current.getContext('2d');
        }

        return () => {
            canvasContext.current = null;
        };
    }, [canvas.current]);

    React.useEffect(() => {
        let mediaItemUrl: string | undefined;
        if (state.eyedropperToolFrameInfo.frameId) {
            const frame = state.canvas.frames.find(frame => frame.frameId === state.eyedropperToolFrameInfo.frameId) as Frame | undefined;
            if (frame) {
                mediaItemUrl = getFrameMediaUrl(frame, props.getMediaItemURL);
            }
        } else if (state.eyedropperToolFrameInfo.shelfId && state.eyedropperToolFrameInfo.placedProductId) {
            const shelf = props.shelves.find(shelf => shelf.shelf.id === state.eyedropperToolFrameInfo.shelfId);
            if (shelf && shelf.meta) {
                const item = shelf.meta.placedProducts.find(product => product.area.id === state.eyedropperToolFrameInfo.placedProductId);

                if (item) {
                    const selectedImage = getSelectedImage(item.area, item.product);
                    mediaItemUrl = selectedImage.url;
                }
            }
        }

        if (mediaItemUrl) {
            const imageElement = new Image();

            imageElement.onload = () => {
                setImg(imageElement);
            };

            imageElement.crossOrigin = 'Anonymous';
            imageElement.src = props.getProxyURL(mediaItemUrl);
        }
    }, [state.eyedropperToolFrameInfo, state.canvas.frames]);

    React.useEffect(() => {
        if (img && canvasContext.current) {
            let hasMedia = false;
            let crop: ImageFrameCrop | undefined;
            if (state.eyedropperToolFrameInfo.frameId) {
                const frame = state.canvas.frames.find(frame => frame.frameId === state.eyedropperToolFrameInfo.frameId) as
                    | Frame
                    | undefined;

                if (frame && canvas.current && hasEyedropCapableFrameMedia(frame)) {
                    hasMedia = true;
                    crop = getFrameMediaCrop(frame);
                }
            } else if (state.eyedropperToolFrameInfo.shelfId && state.eyedropperToolFrameInfo.placedProductId) {
                const placedProduct = findPlacedProduct(state.canvas.shelves, state.eyedropperToolFrameInfo.placedProductId);
                if (placedProduct && placedProduct.productId) {
                    hasMedia = true;
                }
            }

            if (hasMedia) {
                const frameWidth = state.eyedropperToolFrameInfo.w;
                const frameHeight = state.eyedropperToolFrameInfo.h;

                let imageWidth = frameWidth;
                let imageHeight = frameHeight;

                if (crop !== 'stretch-fit') {
                    let scale = 1;
                    if (crop === undefined || crop === 'proportional-inside') {
                        scale = Math.min(frameWidth / img.width, frameHeight / img.height);
                    }

                    imageWidth = img.width * scale;
                    imageHeight = img.height * scale;
                }

                const leftPadding = (frameWidth - imageWidth) / 2;
                const topPadding = (frameHeight - imageHeight) / 2;

                if (
                    state.eyedropperToolFrameInfo.x >= leftPadding &&
                    state.eyedropperToolFrameInfo.y >= topPadding &&
                    state.eyedropperToolFrameInfo.x <= frameWidth - leftPadding &&
                    state.eyedropperToolFrameInfo.y <= frameHeight - topPadding
                ) {
                    const pX = ((state.eyedropperToolFrameInfo.x - leftPadding) / imageWidth) * 100;
                    const pY = ((state.eyedropperToolFrameInfo.y - topPadding) / imageHeight) * 100;

                    canvasContext.current.imageSmoothingEnabled = false;
                    canvasContext.current.clearRect(0, 0, TOOL_SAMPLES * TOOL_PIXEL_SCALE, TOOL_SAMPLES * TOOL_PIXEL_SCALE);
                    canvasContext.current.drawImage(
                        img,
                        Math.round((pX / 100) * img.width) - Math.ceil(TOOL_SAMPLES / 2),
                        Math.round((pY / 100) * img.height) - Math.ceil(TOOL_SAMPLES / 2),
                        TOOL_SAMPLES,
                        TOOL_SAMPLES,
                        0,
                        0,
                        TOOL_SAMPLES * TOOL_PIXEL_SCALE,
                        TOOL_SAMPLES * TOOL_PIXEL_SCALE,
                    );

                    for (let i = TOOL_PIXEL_SCALE; i < TOOL_SAMPLES * TOOL_PIXEL_SCALE; i += TOOL_PIXEL_SCALE) {
                        canvasContext.current.beginPath();
                        canvasContext.current.strokeStyle = 'rgba(0,0,0,0.57)';
                        canvasContext.current.moveTo(i, 0);
                        canvasContext.current.lineTo(i, TOOL_SAMPLES * TOOL_PIXEL_SCALE);
                        canvasContext.current.stroke();

                        canvasContext.current.beginPath();
                        canvasContext.current.strokeStyle = 'rgba(0,0,0,0.57)';
                        canvasContext.current.moveTo(0, i);
                        canvasContext.current.lineTo(TOOL_SAMPLES * TOOL_PIXEL_SCALE, i);
                        canvasContext.current.stroke();
                    }

                    canvasContext.current.beginPath();
                    canvasContext.current.strokeStyle = 'rgba(255,0,0,1)';
                    canvasContext.current.moveTo(
                        (Math.ceil(TOOL_SAMPLES / 2) - 1) * TOOL_PIXEL_SCALE,
                        (Math.ceil(TOOL_SAMPLES / 2) - 1) * TOOL_PIXEL_SCALE,
                    );
                    canvasContext.current.lineTo(
                        Math.ceil(TOOL_SAMPLES / 2) * TOOL_PIXEL_SCALE,
                        (Math.ceil(TOOL_SAMPLES / 2) - 1) * TOOL_PIXEL_SCALE,
                    );
                    canvasContext.current.lineTo(
                        Math.ceil(TOOL_SAMPLES / 2) * TOOL_PIXEL_SCALE,
                        Math.ceil(TOOL_SAMPLES / 2) * TOOL_PIXEL_SCALE,
                    );
                    canvasContext.current.lineTo(
                        (Math.ceil(TOOL_SAMPLES / 2) - 1) * TOOL_PIXEL_SCALE,
                        Math.ceil(TOOL_SAMPLES / 2) * TOOL_PIXEL_SCALE,
                    );
                    canvasContext.current.lineTo(
                        (Math.ceil(TOOL_SAMPLES / 2) - 1) * TOOL_PIXEL_SCALE,
                        (Math.ceil(TOOL_SAMPLES / 2) - 1) * TOOL_PIXEL_SCALE,
                    );
                    canvasContext.current.stroke();
                }
            }
        }
    }, [state.eyedropperToolFrameInfo, img, canvasContext.current]);

    return (
        <Portal aboveSidebar>
            <div
                className={styles.EyedropperTool}
                style={{
                    display:
                        state.eyedropperToolFrameInfo.frameId ||
                        (state.eyedropperToolFrameInfo.shelfId && state.eyedropperToolFrameInfo.placedProductId)
                            ? 'block'
                            : 'none',
                    left: x + 'px',
                    top: y + 'px',
                }}
            >
                <canvas
                    ref={canvas}
                    width={TOOL_SAMPLES * TOOL_PIXEL_SCALE}
                    height={TOOL_SAMPLES * TOOL_PIXEL_SCALE}
                />
            </div>
        </Portal>
    );
}
