import Fuse from 'fuse.js';

interface BaseValue {
    value: string;
}

export interface ISearchProvider<T extends BaseValue> {
    search(query: string): Promise<T[]>;
    loadMoreResults(): Promise<T[]>;
    getHasMoreResults(): boolean;

    byValue(value: string): Promise<T | undefined>;

    byValues(values: string[]): Promise<T[]>;
}

export class FuseSearchProvider<T extends BaseValue> implements ISearchProvider<T> {
    private readonly fuseIndex: Fuse<T>;
    private readonly allItems: T[];
    private query: string;
    private hasMoreResults: boolean;

    constructor(items: T[], searchOptions: Fuse.IFuseOptions<T>) {
        this.allItems = items;
        this.fuseIndex = new Fuse(items, {
            includeMatches: false,
            includeScore: false,
            ...searchOptions,
        });
    }

    async search(query: string): Promise<T[]> {
        return this.searchSync(query);
    }

    searchSync(query: string): T[] {
        if (!query) {
            return this.allItems;
        }

        this.query = query;
        return this.fuseIndex.search(query).map(result => result.item);
    }

    async loadMoreResults(): Promise<T[]> {
        return this.search(this.query);
    }

    getHasMoreResults(): boolean {
        return this.hasMoreResults;
    }

    async byValue(value: string): Promise<T | undefined> {
        if (!value) {
            return;
        }

        if (!this.allItems) {
            return;
        }

        return this.allItems.find(item => value === item.value);
    }

    async byValues(values: string[]): Promise<T[]> {
        if (!this.allItems) {
            return [];
        }

        return this.allItems.filter(item => values.indexOf(item.value) > -1);
    }
}
