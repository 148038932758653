import * as React from 'react';

import ControlledSlider, { ControlledSliderPropsWithValue } from './ControlledSlider';
import UncontrolledSlider, { UncontrolledSliderProps } from './UncontrolledSlider';

export type SliderProps = ControlledSliderPropsWithValue | UncontrolledSliderProps;

class Slider extends React.PureComponent<SliderProps> {
    render() {
        if (this.isControlled(this.props)) {
            return <ControlledSlider {...this.props} />;
        }

        return <UncontrolledSlider {...this.props} />;
    }

    private isControlled(props: SliderProps): props is ControlledSliderPropsWithValue {
        return (props as ControlledSliderPropsWithValue).hasOwnProperty('value');
    }
}

export default React.forwardRef<HTMLInputElement, SliderProps>((props, ref) => {
    return (
        <Slider
            forwardRef={ref}
            {...props}
        />
    );
});
