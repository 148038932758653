import * as React from 'react';

import { store } from '../state';
import { PlacedProduct, ShelfWithMeta, ShelfStyleParameters } from '../types';
import { makeShelfDropAreas } from '../utils';
import ProductsShelfDropArea from './ProductsShelfDropArea';

export default function ProductsShelfDropAreas(props: { shelf: ShelfWithMeta; placedProducts: PlacedProduct[] }) {
    const { state } = React.useContext(store);

    if (!state.draggedProduct) {
        return null;
    }

    const LayoutParams = ShelfStyleParameters[state.canvas.style];

    const dropAreas = makeShelfDropAreas(
        props.placedProducts,
        props.shelf.shelf.height - LayoutParams.shelfBottomOffset,
        state.canvas,
        state.screenResolution,
        state.productImageSizes,
        state.draggedProduct,
    );

    return (
        <>
            {dropAreas.map(dropArea => (
                <ProductsShelfDropArea
                    key={dropArea.x + '-' + dropArea.width}
                    dropArea={dropArea}
                    shelf={props.shelf}
                />
            ))}
        </>
    );
}
