import { createAction, createReducer, Selector } from '@reduxjs/toolkit';

import { Domain } from 'api';

import { ThunkAction, withPayloadType } from '@/action';
import { webshopApi, webshopLayoutApi, productCategoryApi } from '@/api';
import { selectLoggedInUser, selectLoggedInUserOwnershipIds } from '@/Authentication';
import { URLParams } from '@/routing';
import { RootState } from '@/store';
import { selectGlobalSelectedWebshopId, selectGlobalSelectedWebshop } from '@/Webshop/globalSelectionState';

export interface State {
    categories: Domain.AvailableProductCategory[];
    fonts?: Domain.WebshopFontSets;
    colorThemes?: Domain.WebshopColorSetsPage;
    colorThemeDetails?: Domain.WebshopColorSet;
    configuration?: Domain.WebshopConfiguration;
    layoutSet?: Domain.WebshopNavigationSet;
}

const initialState: State = {
    categories: [],
};

const reducerActions = {
    setFonts: createAction('@webshopLayout/setFonts', withPayloadType<Domain.WebshopFontSets>()),
    setColorThemes: createAction('@webshopLayout/setColorThemes', withPayloadType<Domain.WebshopColorSetsPage>()),
    setColorThemeDetails: createAction('@webshopLayout/setColorThemeDetails', withPayloadType<Domain.WebshopColorSet>()),
    setConfiguration: createAction('@webshopLayout/setConfiguration', withPayloadType<Domain.WebshopConfiguration>()),
    setCategories: createAction('@webshopLayout/setCategories', withPayloadType<Domain.AvailableProductCategory[]>()),
    setLayoutSet: createAction('@webshopLayout/setLayoutSet', withPayloadType<Domain.WebshopNavigationSet>()),
};

export const layoutReducer = createReducer(initialState, builder =>
    builder
        .addCase(reducerActions.setFonts, (state, action) => {
            state.fonts = action.payload;
        })
        .addCase(reducerActions.setColorThemes, (state, action) => {
            state.colorThemes = action.payload;
        })
        .addCase(reducerActions.setColorThemeDetails, (state, action) => {
            state.colorThemeDetails = action.payload;
        })
        .addCase(reducerActions.setConfiguration, (state, action) => {
            state.configuration = action.payload;
        })
        .addCase(reducerActions.setCategories, (state, action) => {
            state.categories = action.payload;
        })
        .addCase(reducerActions.setLayoutSet, (state, action) => {
            state.layoutSet = action.payload;
        }),
);

export const selectFonts: Selector<RootState, Domain.WebshopFontSets> = state => {
    const fonts = state.webshopLayout.layout.fonts;

    if (!fonts) {
        throw new Error('Fonts not loaded');
    }

    return fonts;
};

export const selectColorThemes: Selector<RootState, Domain.WebshopColorSetsPage> = state => {
    const colorThemes = state.webshopLayout.layout.colorThemes;

    if (!colorThemes) {
        throw new Error('Color themes not loaded');
    }

    return colorThemes;
};

export const selectColorThemeDetails: Selector<RootState, Domain.WebshopColorSet> = state => {
    const colorThemeDetails = state.webshopLayout.layout.colorThemeDetails;

    if (!colorThemeDetails) {
        throw new Error('Color theme not loaded');
    }

    return colorThemeDetails;
};

export const selectConfiguration: Selector<RootState, Domain.WebshopConfiguration> = state => {
    const configuration = state.webshopLayout.layout.configuration;
    if (!configuration) {
        throw new Error('Webshop configuration not loaded');
    }

    return configuration;
};

export const selectCategories: Selector<RootState, Domain.AvailableProductCategory[]> = state => state.webshopLayout.layout.categories;
export const selectLayoutSet: Selector<RootState, Domain.WebshopNavigationSet> = state => {
    const layoutSet = state.webshopLayout.layout.layoutSet;
    if (!layoutSet) {
        throw new Error('Webshop layoutSet not loaded');
    }

    return layoutSet;
};

export const loadColorThemes = (): ThunkAction => async (dispatch, getState) => {
    let companyId = undefined;
    const state = getState();
    const user = selectLoggedInUser(state);
    const ownershipIds = selectLoggedInUserOwnershipIds(state);

    if (user && (user.role === 'companyAdministrator' || user.role === 'branchAdministrator')) {
        companyId = ownershipIds.companyId;
    }
    const colorThemes = await webshopLayoutApi.GetWebshopColorSets(
        {
            page: 1,
            size: 999,
        },
        companyId,
    );
    await dispatch(reducerActions.setColorThemes(colorThemes));
};

export const loadColorThemeDetails =
    (colorThemeId: Domain.WebshopColorSet['colorSet']['colorset_id']): ThunkAction =>
    async dispatch => {
        const colorThemeDetails = await webshopLayoutApi.GetWebshopColorSetDetails(colorThemeId);
        await dispatch(reducerActions.setColorThemeDetails(colorThemeDetails));
    };

export const loadFonts = (): ThunkAction => async dispatch => {
    const fonts = await webshopLayoutApi.GetWebshopFontSets();
    await dispatch(reducerActions.setFonts(fonts));
};

export const loadConfiguration = (): ThunkAction => async (dispatch, getState) => {
    const state = getState();
    const webshopId = selectGlobalSelectedWebshopId(state);
    if (!webshopId) {
        return;
    }
    const configuration = await webshopApi.GetWebshopConfiguration(webshopId);

    await dispatch(reducerActions.setConfiguration(configuration));
};
export const loadLayoutSet = (): ThunkAction => async (dispatch, getState) => {
    const state = getState();
    const webshopId = selectGlobalSelectedWebshopId(state);
    if (!webshopId) {
        return;
    }
    const layoutSet = await webshopLayoutApi.GetWebshopNavigationSet(webshopId);
    await dispatch(reducerActions.setLayoutSet(layoutSet));
};

export const loadCategories = (): ThunkAction => async (dispatch, getState) => {
    const state = getState();
    const webshop = selectGlobalSelectedWebshop(state);
    if (!webshop) {
        return;
    }

    const tree = await productCategoryApi.GetCategoriesTree(webshop.locales[0]);
    await dispatch(reducerActions.setCategories(tree.subCategories));
};

export const load = (): ThunkAction => async dispatch => {
    await Promise.all([dispatch(loadFonts()), dispatch(loadColorThemes()), dispatch(loadConfiguration())]);
};

export const loadDetails =
    (options: { urlParams: URLParams }): ThunkAction =>
    async dispatch => {
        await Promise.all([
            dispatch(loadFonts()),
            dispatch(loadColorThemeDetails(options.urlParams.themeId)),
            dispatch(loadConfiguration()),
        ]);
    };

export const loadCategoriesSequence = (): ThunkAction => async dispatch => {
    await Promise.all([dispatch(loadCategories()), dispatch(loadConfiguration())]);
};
