import * as React from 'react';

import { TooltipOverflowDetectorContext } from './TooltipOverflowDetectorContext';

type IProps = React.HTMLAttributes<HTMLDivElement> & {
    forwardRef?: React.Ref<any>;
};

const TooltipOverflowDetector = React.memo((props: IProps) => {
    const { children, className, forwardRef, ...rest } = props;
    const { setHasOverflow, hasOverflow } = React.useContext(TooltipOverflowDetectorContext);
    const ref = forwardRef || React.useRef<any>();

    React.useEffect(() => {
        if (!setHasOverflow) {
            return;
        }

        if ((ref as any).current && (ref as any).current.scrollWidth > (ref as any).current.offsetWidth) {
            if (!hasOverflow) {
                setHasOverflow(true);
            }
        } else if (hasOverflow) {
            setHasOverflow(false);
        }
    }, [ref, setHasOverflow]);

    return (
        <div
            {...rest}
            ref={ref as any}
            className={className}
        >
            {children}
        </div>
    );
});

export default React.forwardRef<HTMLDivElement, IProps>((props, ref) => {
    return (
        <TooltipOverflowDetector
            forwardRef={ref}
            {...props}
        />
    );
});
