import { createAction, Selector, createReducer } from '@reduxjs/toolkit';

import { Domain } from 'api';

import { ThunkAction, withPayloadType } from '@/action';
import { webshopOrderApi, branchApi } from '@/api';
import { selectLoggedInCompanyOrBranchManagerOwnershipIds } from '@/Authentication';
import * as Integration from '@/Integration';
import { URLParams } from '@/routing';
import { RootState } from '@/store';

export interface State {
    order?: Domain.WebshopOrderDetails;
    branchRobotOutputDestination?: Domain.BranchRobotOutputDestination[];
}

const initialState: State = {};

const reducerActions = {
    setOrder: createAction('@pickup/create/setOrder', withPayloadType<Domain.WebshopOrderDetails>()),
    setBranchOutputDestinations: createAction(
        '@pickup/create/setBranchOutputDestinations',
        withPayloadType<Domain.BranchRobotOutputDestination[]>(),
    ),
};

export const reducer = createReducer(initialState, builder =>
    builder
        .addCase(reducerActions.setOrder, (state, action) => {
            state.order = action.payload;
        })
        .addCase(reducerActions.setBranchOutputDestinations, (state, action) => {
            state.branchRobotOutputDestination = action.payload;
        }),
);

export const selectOrder: Selector<RootState, Domain.WebshopOrderDetails> = state => {
    const order = state.pickup.create.order;
    if (!order) {
        throw new Error('Order not loaded');
    }
    return order;
};
export const selectBranchRobotOutputDestinations: Selector<RootState, Domain.BranchRobotOutputDestination[] | null> = state => {
    const branchRobotOutputDestinations = state.pickup.create.branchRobotOutputDestination;
    if (!branchRobotOutputDestinations) {
        return null;
    }
    return branchRobotOutputDestinations;
};

export const loadOrder =
    (webshopId: string, orderId: string): ThunkAction =>
    async dispatch => {
        const order = await webshopOrderApi.GetWebshopOrderDetails(webshopId, orderId);
        await dispatch(reducerActions.setOrder(order));
    };
export const loadBranch =
    (branchId: string): ThunkAction =>
    async dispatch => {
        const { robotOutputDestinations } = await branchApi.GetBranchDetails(branchId);
        if (robotOutputDestinations) {
            await dispatch(reducerActions.setBranchOutputDestinations(robotOutputDestinations));
        }
    };

export const actions = {
    load:
        (options: { urlParams: URLParams }): ThunkAction =>
        async (dispatch, getState) => {
            const promises: any[] = [];
            const state = getState();
            const { branchId } = selectLoggedInCompanyOrBranchManagerOwnershipIds(state);
            if (branchId) {
                promises.push(dispatch(loadBranch(branchId)));
            }
            promises.push(dispatch(Integration.configuredIntegrationsTypeState.loadConfiguredIntegrationsByType('deliverPickups')));

            if (options.urlParams.salesChannelId && options.urlParams.orderId) {
                promises.push(dispatch(loadOrder(options.urlParams.salesChannelId, options.urlParams.orderId)));
            }

            await Promise.all(promises);
        },
};
