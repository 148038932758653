import { Pagination, Sorting, NightHatchLogPage, CreateNightHatchLogLinePayload } from '@/Domain';

import Endpoint from './Endpoint';

export default class NightHatchLogEndpoint extends Endpoint {
    public async GetNightHatchLog(pagination: Pagination, sorting: Sorting, deviceId: string, search?: string): Promise<NightHatchLogPage> {
        const response = await this.client.get({
            url: `/vending-machine-night-hatch-log/${deviceId}`,
            params: {
                pagination,
                sorting,
                search,
            },
        });

        this.guardResponseSchema('#/definitions/NightHatchLogPage', response);

        return response;
    }

    public async CreateNightHatchLog(payload: CreateNightHatchLogLinePayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/CreateNightHatchLog',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Device\\NightHatchLog\\CreateNightHatchLog\\CreateNightHatchLog',
                payload,
            },
        });
    }
}
