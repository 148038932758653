import { IStockMutationEvent } from 'Domain/IStockMutationEvent';
import { EventEmitter } from 'events';

import { IEventEmitter } from './IEventEmitter';

export enum StockEvent {
    CheckingStock = 'CheckingStock',
    NeedsRefresh = 'NeedsRefresh',
    StockMutation = 'StockMutation',
}

export class StockEventEmitter
    extends EventEmitter
    implements
        IEventEmitter<{
            CheckingStock: any;
            NeedsRefresh: any;
            StockMutation: IStockMutationEvent;
        }> {}
