import { createAction, Selector } from '@reduxjs/toolkit';

import { Domain } from 'api';

import { ThunkAction, withPayloadType } from '@/action';
import { integrationApi } from '@/api';
import { makeActions, makeReducer, makeReducerActions, makeSelectors } from '@/makeOverviewState';
import { URLParams, URLQuery } from '@/routing';
import { RootState } from '@/store';

type IntegrationProps = keyof Domain.ConfiguredIntegration | 'integrationType';

export const defaultSorting: Domain.Sorting<IntegrationProps> = {
    field: 'configuredOn',
    direction: 'descending',
};

export const pageSize = 20;

export const setAvailableIntegrations = createAction(
    '@integration/overview/setAvailableIntegrations',
    withPayloadType<Domain.AvailableIntegrationsPage>(),
);
export const selectAvailableIntegrations: Selector<RootState, Domain.AvailableIntegrationsPage> = state =>
    state.integration.overview.availableIntegrations;
export const loadAvailableIntegrations = (): ThunkAction => async dispatch => {
    dispatch(
        setAvailableIntegrations(
            await integrationApi.GetAllAvailableIntegrations({ page: 1, size: 999 }, { field: 'name', direction: 'ascending' }),
        ),
    );
};

export const selectors = makeSelectors<Domain.ConfiguredIntegration, IntegrationProps>({
    getState: rootState => rootState.integration.overview,
});

export const reducerActions = makeReducerActions<Domain.ConfiguredIntegration, IntegrationProps>({
    reducerPrefix: '@integration/overview',
});

const baseActions = makeActions<
    Domain.ConfiguredIntegration,
    IntegrationProps,
    {
        configuredIntegrationId: string;
        availableIntegrationId: string;
        // name: string
        companyId?: Domain.Company['companyId'] | null;
        branchId?: Domain.Branch['branchId'] | null;
        config?: {
            [key: string]: any;
        };
        configuredOn?: string | null;
        activeStatus?: string | null;
    }
>({
    dataTableSaveKey: 'integrationsOverview-v5',
    loadApi: options =>
        integrationApi.GetConfiguredIntegrations(options.ownership, options.pagination, options.sorting, options.search, options.filters),
    defaultSorting,
    pageSize,
    getBaseUrl: () => '/integrations',
    reducerActions,
    selectors,
});

export const overviewReducer = makeReducer<
    Domain.ConfiguredIntegration,
    IntegrationProps,
    {
        availableIntegrations: Domain.AvailableIntegrationsPage;
    }
>({
    defaultSorting,
    pageSize,
    reducerActions,
    extraCases: builder =>
        builder.addCase(setAvailableIntegrations, (state, action) => {
            state.availableIntegrations = action.payload;
        }),
});

export const actions = {
    ...baseActions,
    load:
        (options: { urlParams: URLParams; urlQuery: URLQuery }): ThunkAction =>
        async dispatch => {
            await Promise.all([dispatch(baseActions.load(options)), dispatch(loadAvailableIntegrations())]);
        },
};
