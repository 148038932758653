import { CanvasFrame } from '../types';

export default function getMinFrameDepth(frames: CanvasFrame[]): number {
    let min = 1000;

    for (const frame of frames) {
        min = Math.min(min, frame.depth || 0);
    }

    return min;
}
