import { Route, anyAuthorization } from 'data-store';

import Error from './Error';

const routes: Route[] = [
    {
        path: '/error/:type',
        main: async () => Error,
        isAuthorized: anyAuthorization,
        isDevice: true,
    },
    {
        path: '*',
        main: async () => Error,
        isAuthorized: anyAuthorization,
        isDevice: true,
    },
];

export default routes;
