import { Domain } from 'api';

import { productSourceApi } from '@/api';
import { makeActions, makeReducer, makeReducerActions, makeSelectors } from '@/makeDetailsState';

export const selectors = makeSelectors<Domain.ProductSourceDetails>({
    getState: rootState => rootState.productSource.details,
});

export const reducerActions = makeReducerActions<Domain.ProductSourceDetails>({
    reducerPrefix: '@productSource/details',
});

export const actions = makeActions<Domain.ProductSourceDetails>({
    loadApi: options => productSourceApi.GetProductSourceDetails(options.urlParams.productSourceId),
    reducerActions,
    selectors,
});

export const detailsReducer = makeReducer<Domain.ProductSourceDetails>({
    reducerActions,
});
