import { Selector } from '@reduxjs/toolkit';

import { Domain } from 'api';

import { RootState } from '@/store';

import * as slideshowState from './slideshowState';
import { Slide, DuplicatedSlide, Stock } from './types';
import { getConfiguredIntegrationForTypes, getConfiguredIntegrationsForTypes } from './utils';

export const selectDevice: Selector<RootState, Domain.DeviceDetails | undefined> = state => state.tvScreen.device.device;
export const selectBranch: Selector<RootState, Domain.BranchDetails | undefined> = state => state.tvScreen.device.branch;
export const selectConfigurationIsInvalid: Selector<RootState, boolean> = state => state.tvScreen.device.configurationIsInvalid || false;
export const selectSchedule: Selector<RootState, Domain.DeviceSchedule | undefined> = state => state.tvScreen.device.schedule;
export const selectTimeline: Selector<
    RootState,
    | (Domain.Timeline & {
          startTime: number;
      })
    | undefined
> = state => {
    const schedule = selectSchedule(state);
    if (!schedule) {
        return;
    }

    const slideMinute = selectSlideMinute(state);

    for (const slot of schedule) {
        if (slot.startTime <= slideMinute && slot.endTime > slideMinute) {
            if (slot.timelineId) {
                const timeline = state.tvScreen.device.timelines[slot.timelineId];

                if (timeline) {
                    return {
                        ...timeline,
                        content: timeline.content.filter(slide => slide.duration > 0),
                        startTime: slot.startTime,
                    };
                }
            }
        }
    }
};

export const selectSlides: Selector<RootState, Slide[]> = state => state.tvScreen.device.slides || [];
export const selectSlideMinute: Selector<RootState, number> = state => state.tvScreen.device.slideMinute;
export const selectHiddenSlides: Selector<RootState, Slide[]> = state => state.tvScreen.device.hiddenSlides || [];

export const selectVisibleTimelineSlides: Selector<RootState, DuplicatedSlide[]> = state => {
    const timeline = selectTimeline(state);

    if (!timeline) {
        return [];
    }

    const longestSlideshowDuration = slideshowState.selectLongestSlideshowDuration(state);
    const slideshowDuration = selectSlideshowDuration(state);
    const slides = timeline.content.filter(filteredSlide => filteredSlide.type !== 'placeholder' && !filteredSlide.isHidden);

    let visibleTimelineSlides: DuplicatedSlide[] = [];
    if (longestSlideshowDuration && slideshowDuration < longestSlideshowDuration) {
        const howManyTimesToDuplicate = Math.floor(longestSlideshowDuration / slideshowDuration);
        const remainingDurationAfterDuplication = longestSlideshowDuration % slideshowDuration;

        const slidesWithDuplicates: DuplicatedSlide[] = [];
        for (let d = 0; d < howManyTimesToDuplicate; d += 1) {
            for (const slide of slides) {
                slidesWithDuplicates.push({
                    ...slide,
                    slideId: slide.slideId + '|' + d,
                    originalSlideId: slide.slideId,
                });
            }
        }

        if (remainingDurationAfterDuplication > 0) {
            slidesWithDuplicates[slidesWithDuplicates.length - 1].duration += remainingDurationAfterDuplication;
        }

        visibleTimelineSlides = slidesWithDuplicates;
    } else {
        visibleTimelineSlides = slides.map(slide => {
            return {
                ...slide,
                originalSlideId: slide.slideId,
            };
        });
    }

    if (visibleTimelineSlides.length === 1) {
        const onlySlide = visibleTimelineSlides[0];
        visibleTimelineSlides.unshift({
            ...onlySlide,
            slideId: onlySlide.slideId + '|single-slide-buster-pre',
            originalSlideId: onlySlide.slideId,
        });
        visibleTimelineSlides.push({
            ...onlySlide,
            slideId: onlySlide.slideId + '|single-slide-buster-post',
            originalSlideId: onlySlide.slideId,
        });
    }

    return visibleTimelineSlides;
};

export const selectSlideshowDuration: Selector<RootState, number> = state => {
    const timeline = selectTimeline(state);
    if (!timeline) {
        return 0;
    }

    let slideshowDuration = 0;
    let numSlides = 0;
    for (const timelineSlide of timeline.content) {
        if (timelineSlide.isHidden) {
            continue;
        } else {
            slideshowDuration += timelineSlide.duration;
            numSlides += 1;
        }
    }

    if (numSlides === 1) {
        slideshowDuration *= 3;
    }

    return slideshowDuration;
};

export const selectActiveConfiguredIntegrations: Selector<RootState, Domain.ConfiguredIntegration[]> = state => {
    return state.tvScreen.device.configuredIntegrations.filter(integration => integration.activeStatus === 'active');
};
export const selectDeviceDeliverPickupsIntegrations: Selector<RootState, Domain.DeviceDeliverPickupsIntegrations> = state => {
    const configuredIntegrations = selectActiveConfiguredIntegrations(state);
    return {
        robot: getConfiguredIntegrationForTypes(configuredIntegrations, ['robot', 'deliverPickups'])?.configuredIntegrationId,
        lockers: getConfiguredIntegrationForTypes(configuredIntegrations, ['locker', 'deliverPickups'])?.configuredIntegrationId,
        all: getConfiguredIntegrationsForTypes(configuredIntegrations, ['deliverPickups']).map(
            integration => integration.configuredIntegrationId,
        ),
    };
};

export const selectStock: Selector<RootState, Stock> = state => state.tvScreen.device.stock;
export const selectAllSlideshowProducts: Selector<RootState, Domain.SelectedProduct[]> = state => state.tvScreen.slideshow.allProducts;
export const selectIntegrationPaymentMethods: Selector<RootState, Domain.IntegrationPaymentMethods | undefined> = state => {
    return state.tvScreen.device.integrationPaymentMethods;
};
