import * as React from 'react';

import ControlledToggleButton, { ControlledToggleButtonPropsWithIsOn } from './ControlledToggleButton';
import UncontrolledToggleButton, { UncontrolledToggleButtonProps } from './UncontrolledToggleButton';

export type ToggleButtonProps = ControlledToggleButtonPropsWithIsOn | UncontrolledToggleButtonProps;

class ToggleButton extends React.PureComponent<ToggleButtonProps> {
    render() {
        if (this.isControlled(this.props)) {
            return <ControlledToggleButton {...this.props} />;
        }

        return <UncontrolledToggleButton {...this.props} />;
    }

    private isControlled(props: ToggleButtonProps): props is ControlledToggleButtonPropsWithIsOn {
        return (props as ControlledToggleButtonPropsWithIsOn).hasOwnProperty('isOn');
    }
}

export default React.forwardRef<HTMLInputElement, ToggleButtonProps>((props, ref) => {
    return (
        <ToggleButton
            forwardRef={ref}
            {...props}
        />
    );
});
