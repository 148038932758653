import {
    Country,
    VendingMachineCatalogBrandsAndCategoriesPage,
    VendingMachineProductsPage,
    APIVendingMachineProductsPage,
    VendingMachineProduct,
} from '@/Domain';

import Endpoint from './Endpoint';

export default class ProductSelectionEndpoint extends Endpoint {
    public async GetVendingMachineCatalogCategoriesAndBrands(): Promise<VendingMachineCatalogBrandsAndCategoriesPage> {
        const response = await this.client.get({
            url: '/vending-machine-catalog/categories-brands',
        });

        this.guardResponseSchema('#/definitions/VendingMachineCatalogBrandsAndCategoriesPage', response);

        return response;
    }

    public async GetVendingMachineCatalogByProductCodes(country: Country, productCodes: string[]): Promise<VendingMachineProduct[]> {
        if (productCodes.length === 0) {
            return [];
        }

        const response: APIVendingMachineProductsPage = await this.client.post({
            url: '/vending-machine-catalog/products-from-product-codes',
            body: {
                productCodes,
            },
        });

        this.guardResponseSchema('#/definitions/APIVendingMachineProductsPage', response);

        return this.cleanUpProducts(country, response.products);
    }

    public async GetVendingMachineCatalogByProductIds(country: Country, productIds: string[]): Promise<VendingMachineProduct[]> {
        if (productIds.length === 0) {
            return [];
        }

        const response: APIVendingMachineProductsPage = await this.client.post({
            url: '/vending-machine-catalog/products-from-product-ids',
            body: {
                productIds,
            },
        });

        this.guardResponseSchema('#/definitions/APIVendingMachineProductsPage', response);

        return this.cleanUpProducts(country, response.products);
    }

    public async GetVendingMachineCatalogByQuery(
        country: Country,
        params: {
            search: string;
            filters: {
                brandIds?: string[];
                categoryIds?: string[];
                organisationIds?: string[];
            };
        },
    ): Promise<VendingMachineProduct[]> {
        const response: APIVendingMachineProductsPage = await this.client.get({
            url: '/vending-machine-catalog/search',
            params,
        });

        this.guardResponseSchema('#/definitions/APIVendingMachineProductsPage', response);

        return this.cleanUpProducts(country, response.products);
    }

    private cleanUpProducts(country: Country, products: APIVendingMachineProductsPage['products']): VendingMachineProductsPage['products'] {
        const cleanProducts = products.filter(product => {
            if (!product.pcodes || Array.isArray(product.pcodes)) {
                return false;
            }

            if (!product.cids || product.cids.length === 0) {
                return false;
            }

            const anyPrice = product.price || product.pprice;
            if (anyPrice === undefined) {
                return false;
            }

            return true;
        }) as VendingMachineProductsPage['products'];

        if (country === 'LU') {
            return cleanProducts.filter(product => {
                if (!product.pcodes || !product.pcodes['CefipCode'] || product.pcodes['CefipCode'].length === 0) {
                    return false;
                }
                return true;
            });
        }

        return cleanProducts;
    }
}
