import { Selector } from '@reduxjs/toolkit';

import { Domain } from 'api';

import { RootState } from '@/store';

import { Translations } from './types';

export const selectCurrentLocale: Selector<RootState, Domain.Locale> = state => state.i18n.locale;
export const selectLoadedTranslationsLocale: Selector<RootState, Domain.Locale | undefined> = state => state.i18n.loadedTranslationsLocale;
export const selectCurrentISOLocale: Selector<RootState, string> = state => state.i18n.locale.replace('_', '-');
export const selectTranslations: Selector<RootState, Translations | undefined> = state => state.i18n.translations;
