import {
    Pagination,
    Locale,
    ProductSourceDetails,
    ProductSourcesPage,
    UpdateProductSourcePayload,
    ProductSourceOrganisationsPage,
} from '@/Domain';

import Endpoint from './Endpoint';

export default class ProductSourceEndpoint extends Endpoint {
    public async GetProductSources(pagination: Pagination): Promise<ProductSourcesPage> {
        const response = await this.client.get({
            url: '/product-source/overview',
            params: {
                pagination,
            },
        });

        this.guardResponseSchema('#/definitions/ProductSourcesPage', response);

        return response;
    }

    public async GetProductSourceDetails(productSourceId: string): Promise<ProductSourceDetails> {
        const response = await this.client.get({
            url: '/product-source/details/' + productSourceId,
        });

        this.guardResponseSchema('#/definitions/ProductSourceDetails', response);

        return response;
    }

    public async QueueMedipimImport(): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/QueueMedipimImport',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Product\\Medipim\\QueueMedipimImport\\QueueMedipimImport',
                payload: {},
            },
        });
    }

    public async UpdateProductSource(payload: UpdateProductSourcePayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/UpdateProductSource',
            body: {
                commandName: 'App\\Domain\\WriteModel\\ProductSource\\UpdateProductSource\\UpdateProductSource',
                payload,
            },
        });
    }

    public async GetOrganisations(productSourceId: string, locale: Locale, search?: string): Promise<ProductSourceOrganisationsPage> {
        const response = await this.client.get({
            url: `/organisation/overview/${productSourceId}`,
            params: {
                pagination: { page: 1, size: 10 },
                locale,
                search,
            },
        });

        this.guardResponseSchema('#/definitions/ProductSourceOrganisationsPage', response);

        return response;
    }
}
