import * as React from 'react';

export type Key = 'ENTER' | 'SPACE' | 'ESCAPE' | 'LEFT_ARROW' | 'RIGHT_ARROW' | 'UP_ARROW' | 'DOWN_ARROW' | 'B' | 'U' | 'I' | 'S';

const KEY_MAP = {
    ENTER: 'Enter',
    SPACE: ' ',
    ESCAPE: 'Escape',
    LEFT_ARROW: 'ArrowLeft',
    RIGHT_ARROW: 'ArrowRight',
    UP_ARROW: 'ArrowUp',
    DOWN_ARROW: 'ArrowDown',
    B: 'b',
    U: 'u',
    I: 'i',
    S: 's',
};

export default class KeyboardHandler {
    public handleKey =
        (
            keys: Key | Key[],
            callback?: (
                event: React.KeyboardEvent,
                key: Key,
                meta: {
                    ctrl: boolean;
                    shift: boolean;
                    alt: boolean;
                },
            ) => void,
            modifiers: {
                ctrl?: boolean;
                shift?: boolean;
            } = {
                ctrl: false,
                shift: false,
            },
            prevent = true,
            preventAll = false,
        ) =>
        (event: React.KeyboardEvent): void => {
            if (preventAll) {
                event.preventDefault();
            }

            if (modifiers.ctrl && !event.ctrlKey && !event.metaKey) {
                return;
            }

            if (modifiers.shift && !event.shiftKey) {
                return;
            }

            if (!(keys instanceof Array)) {
                keys = [keys];
            }

            for (const key of keys) {
                const keyCode = KEY_MAP[key];

                if (!keyCode) {
                    continue;
                }

                if (event.key === keyCode) {
                    if (prevent) {
                        event.preventDefault();
                    }

                    if (callback) {
                        callback(event, key, {
                            ctrl: event.ctrlKey || event.metaKey,
                            shift: event.shiftKey,
                            alt: event.altKey,
                        });
                    }
                    break;
                }
            }
        };

    public getEventKey(event: KeyboardEvent | React.KeyboardEvent): Key | undefined {
        const KEYS = Object.keys(KEY_MAP);
        for (const key of KEYS) {
            if (KEY_MAP[key as Key] === event.key) {
                return key as Key;
            }
        }

        return undefined;
    }
}
