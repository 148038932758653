import { Canvas, CanvasFrame } from '../types';
import { getMaxFrameDepth } from '../utils';
import * as Actions from './actions';
import { State } from './state';

const defaults = {
    undoStack: [],
    redoStack: [],
    draggedBlockType: undefined,
    draggedBlockPosition: {
        x: 0,
        y: 0,
        width: 0,
        height: 0,
    },
    draggedBlockDroppedFlag: false,
};

const initCanvas: Canvas = {
    width: 596,
    height: 1060,
    frames: [],
};

export const initialState: State = {
    canvas: initCanvas,
    initialState: JSON.stringify(initCanvas),
    ...defaults,
};

export function reducer(state = initialState, action: Actions.ActionTypes): State {
    switch (action.type) {
        case Actions.HISTORY_SAVE:
            return {
                ...state,
                undoStack: [...state.undoStack, JSON.stringify(state.canvas)],
                redoStack: [],
            };

        case Actions.HISTORY_RESET:
            return {
                ...state,
                canvas: JSON.parse(state.initialState),
                undoStack: [],
                redoStack: [JSON.stringify(state.canvas)],
            };

        case Actions.HISTORY_UNDO:
            const undoItem = state.undoStack.pop() || '';
            return {
                ...state,
                canvas: JSON.parse(undoItem),
                undoStack: [...state.undoStack],
                redoStack: [...state.redoStack, JSON.stringify(state.canvas)],
            };

        case Actions.HISTORY_POP:
            state.undoStack.pop();
            return {
                ...state,
                undoStack: [...state.undoStack],
            };

        case Actions.HISTORY_REDO:
            const redoItem = state.redoStack.pop() || '';
            return {
                ...state,
                canvas: JSON.parse(redoItem),
                redoStack: [...state.redoStack],
                undoStack: [...state.undoStack, JSON.stringify(state.canvas)],
            };

        case Actions.SET_CANVAS:
            return {
                ...state,
                canvas: {
                    ...action.canvas,
                    frames: action.canvas.frames.map(frame => {
                        if (frame.depth === undefined) {
                            return {
                                ...frame,
                                depth: getMaxFrameDepth(action.canvas.frames),
                            };
                        }
                        return frame;
                    }),
                },
                initialState: JSON.stringify(action.canvas),
                ...defaults,
            };

        case Actions.SET_DRAGGED_BLOCK_TYPE:
            return {
                ...state,
                draggedBlockType: action.draggedBlockType,
                draggedBlockPosition: {
                    ...state.draggedBlockPosition,
                    width: action.draggedBlockWidth || state.draggedBlockPosition.width,
                    height: action.draggedBlockHeight || state.draggedBlockPosition.height,
                },
            };

        case Actions.STOP_DRAGGING_BLOCK:
            return {
                ...state,
                draggedBlockType: undefined,
            };

        case Actions.SET_DRAGGED_BLOCK_POSITION:
            return {
                ...state,
                draggedBlockPosition: {
                    ...state.draggedBlockPosition,
                    x: action.x,
                    y: action.y,
                },
            };

        case Actions.SET_DRAGGED_BLOCK_DROPPED_FLAG:
            return {
                ...state,
                draggedBlockDroppedFlag: action.dropped,
            };

        case Actions.ADD_FRAME:
            if (!state.draggedBlockType) {
                throw new Error('Cannot add frame while not dragging a block');
            }

            return {
                ...state,
                canvas: {
                    ...state.canvas,
                    frames: [...state.canvas.frames, action.frame],
                },
                draggedBlockType: undefined,
                editedFrameId: action.frame.frameId,
            };

        case Actions.SET_RESIZED_FRAME:
            return {
                ...state,
                resizedFrameId: action.resizedFrameId,
            };

        case Actions.SET_FRAME_LEFT_OFFSET:
            return deepMapFrame(
                frame => {
                    return {
                        ...frame,
                        x: frame.x + action.offset,
                        width: frame.width - action.offset,
                    };
                },
                state,
                action.frameId,
            );

        case Actions.SET_FRAME_TOP_OFFSET:
            return deepMapFrame(
                frame => {
                    return {
                        ...frame,
                        y: frame.y + action.offset,
                        height: frame.height - action.offset,
                    };
                },
                state,
                action.frameId,
            );

        case Actions.SET_FRAME_RIGHT_OFFSET:
            return deepMapFrame(
                frame => {
                    return {
                        ...frame,
                        width: frame.width + action.offset,
                    };
                },
                state,
                action.frameId,
            );

        case Actions.SET_FRAME_BOTTOM_OFFSET:
            return deepMapFrame(
                frame => {
                    return {
                        ...frame,
                        height: frame.height + action.offset,
                    };
                },
                state,
                action.frameId,
            );

        case Actions.SET_FRAME_OFFSET:
            return deepMapFrame(
                frame => {
                    return {
                        ...frame,
                        x: frame.x + action.offsetX,
                        y: frame.y + action.offsetY,
                    };
                },
                state,
                action.frameId,
            );

        case Actions.SET_FRAME_COORDINATES:
            return deepMapFrame(
                frame => {
                    return {
                        ...frame,
                        x: action.x,
                        y: action.y,
                        width: action.width,
                        height: action.height,
                    };
                },
                state,
                action.frameId,
            );

        case Actions.REMOVE_FRAME:
            const newStateAfterRemoveFrame = {
                ...state,
            };

            if (state.resizedFrameId && state.resizedFrameId === action.frameId) {
                newStateAfterRemoveFrame.resizedFrameId = undefined;
            }

            if (state.editedFrameId && state.editedFrameId === action.frameId) {
                newStateAfterRemoveFrame.editedFrameId = undefined;
            }

            return deepMapFrames(frames => frames.filter(frame => frame.frameId !== action.frameId), newStateAfterRemoveFrame);

        case Actions.SET_EDITED_FRAME:
            return {
                ...state,
                editedFrameId: action.editedFrameId,
            };

        case Actions.SET_FRAME_DEPTH:
            return deepMapFrame(
                frame => {
                    return {
                        ...frame,
                        depth: action.depth,
                    };
                },
                state,
                action.frameId,
            );

        default:
            return state;
    }
}

function deepMapFrame(mapper: (frame: CanvasFrame) => CanvasFrame, state: State, frameId: string) {
    return deepMapFrames(
        frames => [
            ...frames.map(frame => {
                if (frame.frameId === frameId) {
                    return mapper(frame);
                }

                return frame;
            }),
        ],
        state,
    );
}

function deepMapFrames(mapper: (frames: CanvasFrame[]) => CanvasFrame[], state: State) {
    return {
        ...state,
        canvas: {
            ...state.canvas,
            frames: [...mapper(state.canvas.frames)],
        },
    };
}
