import { combineReducers } from '@reduxjs/toolkit';

import { categoriesTreeReducer } from './categoriesTreeState';
import { detailsReducer } from './detailsState';
import { overviewReducer } from './overviewState';

export const availableProductReducer = combineReducers({
    overview: overviewReducer,
    details: detailsReducer,
    categoriesTree: categoriesTreeReducer,
});
