import { ShelfWithMeta, ShelfStyleParameters, Layout } from '../types';

export default function computeShelfMoveMinY(layout: Layout, shelf: ShelfWithMeta): number {
    if (shelf.shelf.height === 0) {
        return shelf.shelf.y;
    }

    const LayoutParams = ShelfStyleParameters[layout.style];

    return shelf.shelf.y + Math.max(LayoutParams.minShelfHeight, shelf.meta ? shelf.meta.maxBottom : 0);
}
