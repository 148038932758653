import { createAction, Selector } from '@reduxjs/toolkit';

import { Domain } from 'api';

import { ThunkAction, withPayloadType } from '@/action';
import { pickupApi, branchApi } from '@/api';
import { selectLoggedInCompanyOrBranchManagerOwnershipIds } from '@/Authentication';
import * as Integration from '@/Integration';
import { makeActions, makeReducer, makeReducerActions, makeSelectors } from '@/makeDetailsState';
import { URLParams, URLQuery } from '@/routing';
import { RootState } from '@/store';

export const setBranch = createAction('@pickup/details/setBranch', withPayloadType<Domain.BranchDetails>());
export const selectBranch: Selector<RootState, Domain.BranchDetails | undefined> = state => state.pickup.details.branch;

export const selectors = makeSelectors<Domain.PickupDetails>({
    getState: rootState => rootState.pickup.details,
});

export const reducerActions = makeReducerActions<Domain.PickupDetails>({
    reducerPrefix: '@pickup/details',
});

export const baseActions = makeActions<Domain.PickupDetails>({
    loadApi: options => pickupApi.GetPickupDetails(options.urlParams.pickupId),
    reducerActions,
    selectors,
});
export const actions = {
    ...baseActions,
    load:
        (options: { urlParams: URLParams; urlQuery: URLQuery }): ThunkAction =>
        async (dispatch, getState) => {
            const promises: any[] = [dispatch(baseActions.load(options))];

            const state = getState();
            const { branchId } = selectLoggedInCompanyOrBranchManagerOwnershipIds(state);
            if (branchId) {
                promises.push(dispatch(loadBranch(branchId)));
            }

            promises.push(dispatch(Integration.configuredIntegrationsTypeState.loadConfiguredIntegrationsByType('deliverPickups')));

            await Promise.all(promises);
        },
};

export const loadBranch =
    (branchId: string): ThunkAction =>
    async dispatch => {
        const branch = await branchApi.GetBranchDetails(branchId);
        if (branch.robotOutputDestinations) {
            await dispatch(setBranch(branch));
        }
    };

export const detailsReducer = makeReducer<
    Domain.PickupDetails,
    {
        branch?: Domain.BranchDetails;
    }
>({
    reducerActions,
    extraCases: builder =>
        builder.addCase(setBranch, (state, action) => {
            state.branch = action.payload;
        }),
});
