import { Domain } from 'api';

const PRODUCT_SCALE_FIX_VERSION_2024_11_11 = 2; // this one should never be changed
export const PRODUCT_SCALE_ALGORITHM_VERSION = 2; // increment this one whenever we change the scaling alhorithm

export function getScreenSizeInPX(screenResolution: Domain.DeviceScreenResolution): {
    width: number;
    height: number;
} {
    const parts = screenResolution.split('x');

    return {
        width: parseInt(parts[0]),
        height: parseInt(parts[1]),
    };
}

export function getScaledScreenSizeInPX(
    screenResolution: Domain.DeviceScreenResolution,
    productScaleAlgorithmVersion: number,
): {
    width: number;
    height: number;
} {
    if (productScaleAlgorithmVersion < PRODUCT_SCALE_FIX_VERSION_2024_11_11) {
        return getScreenSizeInPX(screenResolution);
    }
    // add more options here whenever we change the scaling algorithm

    let width = 450;
    let height = 800;

    if (screenResolution === '1920x1080') {
        width = 800;
        height = 450;
    } else if (screenResolution === '1280x1024') {
        width = 565;
        height = 452;
    } else if (screenResolution === '2112x1048') {
        width = 800;
        height = 400;
    }

    return {
        width,
        height,
    };
}
