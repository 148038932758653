import { createAction, Selector, createReducer } from '@reduxjs/toolkit';

import { Domain } from 'api';

import { ThunkAction, withPayloadType } from '@/action';
import { appointmentTypeApi } from '@/api';
import { URLParams } from '@/routing';
import { RootState } from '@/store';

export interface State {
    appointmentType?: Domain.AppointmentTypeDetails;
}

const initialState: State = {};

const reducerActions = {
    setAppointmentType: createAction('@webshopFaqCategory/update/setFaqCategory', withPayloadType<Domain.AppointmentTypeDetails>()),
};

export const updateReducer = createReducer(initialState, builder =>
    builder.addCase(reducerActions.setAppointmentType, (state, action) => {
        state.appointmentType = action.payload;
    }),
);

export const selectAppointmentType: Selector<RootState, Domain.AppointmentTypeDetails> = state => {
    const appointmentType = state.appointmentTypesReducer.update.appointmentType;
    if (!appointmentType) {
        throw new Error('AppointmentType not loaded');
    }

    return appointmentType;
};

export const loadAppointmentType =
    (appointmentTypeId: Domain.AppointmentTypeDetails['appointmentTypeId']): ThunkAction<Promise<Domain.AppointmentTypeDetails>> =>
    async dispatch => {
        try {
            const appointmentType = await appointmentTypeApi.GetAppointmentTypeDetails(appointmentTypeId);
            await dispatch(reducerActions.setAppointmentType(appointmentType));
            return appointmentType;
        } catch (e) {
            throw new Error('Failed to load appointment type');
        }
    };

export const load =
    (options: { urlParams: URLParams }): ThunkAction =>
    async dispatch => {
        await Promise.all([dispatch(loadAppointmentType(options.urlParams.appointmentTypeId))]);
    };
