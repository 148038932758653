import { EnrichedBranch } from './Branch';
import { EnrichedCompany } from './Company';
import { Pagination } from './Pagination';
import { Sorting } from './Sorting';
import { User } from './User';

export const AUDIT_LOG_ACTIONS = [
    'userLoggedIn',
    'userWasImpersonated',
    'viewOrderDetails',
    'viewPickupDetails',
    'viewShipmentDetails',
    'userWasCreated',
    'userRoleGranted',
    'userWasInactivated',
    'userWasMadeBranchAdministrator',
    'userWasMadeCompanyAdministrator',
    'userWasMadePlatformAdministrator',
    'userWasUpdated',
] as const;
export type AuditLogAction = (typeof AUDIT_LOG_ACTIONS)[number];

export interface AuditLog {
    gdprAuditLogId: string;
    subjectId: string;
    action: AuditLogAction;
    user: User;
    loggedOn: string;
    company?: EnrichedCompany | null;
    branch?: EnrichedBranch | null;
    impersonatedByUser?: User | null;
}

export interface AuditLogPage {
    items: AuditLog[];
    total: number;
    pagination: Pagination;
    sorting?: Sorting<'loggedOn'> | null;
    search?: string | null;
}
