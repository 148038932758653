import {
    Pagination,
    Sorting,
    ConfiguredIntegrationsPage,
    AvailableIntegrationsPage,
    IntegrationDetails,
    Ownership,
    UpdateConfiguredIntegrationPayload,
    AssignAvailableIntegrationPayload,
    IntegrationsCountByType,
    AvailableIntegration,
    TestConfiguredIntegrationPayload,
    IntegrationSettings,
    IntegrationPaymentMethods,
} from '@/Domain';

import Endpoint from './Endpoint';

export default class IntegrationEndpoint extends Endpoint {
    public async GetConfiguredIntegrations(
        ownership: Ownership,
        pagination: Pagination,
        sorting: Sorting,
        search?: string,
        filters?: {
            companyId?: string | null;
            branchId?: string | null;
            integrationType?: string | null;
            availableForCountries?: string[] | null;
            deviceId?: string | null;
            activeStatus?: string | null;
        },
    ): Promise<ConfiguredIntegrationsPage> {
        let url = '';

        if (ownership.type === 'all') {
            url = '/configured-integration/overview/all';
        } else if (ownership.type === 'company') {
            url = `/configured-integration/overview/company/${ownership.ownerId}`;
        } else if (ownership.type === 'branch') {
            url = `/configured-integration/overview/branch/${ownership.ownerId}`;
        }
        const newFilters = filters
            ? { ...filters, country: filters.availableForCountries ? filters.availableForCountries : undefined }
            : filters;
        const configureIntegrations = await this.client.get({
            url,
            params: {
                pagination,
                sorting,
                search,
                filters: newFilters,
            },
        });

        this.guardResponseSchema('#/definitions/ConfiguredIntegrationsPage', configureIntegrations);

        return configureIntegrations;
    }

    public async GetAvailableIntegrations(pagination: Pagination, sorting: Sorting, search?: string): Promise<AvailableIntegrationsPage> {
        const url = '/available-integration/overview';

        const availableIntegrations = await this.client.get({
            url,
            params: {
                pagination,
                sorting,
                search,
            },
        });

        this.guardResponseSchema('#/definitions/AvailableIntegrationsPage', availableIntegrations);

        return availableIntegrations;
    }

    public async GetAllAvailableIntegrations(
        pagination: Pagination,
        sorting: Sorting,
        search?: string,
    ): Promise<AvailableIntegrationsPage> {
        const url = '/available-integration/overview/all';

        const allAvailableIntegrations = await this.client.get({
            url,
            params: {
                pagination,
                sorting,
                search,
            },
        });

        this.guardResponseSchema('#/definitions/AvailableIntegrationsPage', allAvailableIntegrations);

        return allAvailableIntegrations;
    }

    public async GetIntegrationDetail(configuredIntegrationId: string): Promise<IntegrationDetails> {
        const url = `/configured-integration/${configuredIntegrationId}/details`;

        let integrationDetails: Omit<IntegrationDetails, 'settings'> & {
            settings?: IntegrationSettings | [];
        } = await this.client.get({
            url,
        });

        if (Array.isArray(integrationDetails.settings)) {
            integrationDetails = {
                ...integrationDetails,
                settings: undefined,
            };
        }

        this.guardResponseSchema('#/definitions/IntegrationDetails', integrationDetails);

        return integrationDetails as IntegrationDetails;
    }

    public async GetAvailableIntegrationDetail(availableIntegrationId: string): Promise<AvailableIntegration> {
        const availableIntegrationDetails = await this.client.get({
            url: `/available-integration/${availableIntegrationId}/details`,
        });

        this.guardResponseSchema('#/definitions/AvailableIntegration', availableIntegrationDetails);

        return availableIntegrationDetails;
    }

    public async GetIntegrationPaymentMethods(configuredIntegrationId: string): Promise<IntegrationPaymentMethods> {
        const paymentMethods = await this.client.get({
            url: `/payment-integration/${configuredIntegrationId}/payment-methods`,
        });

        this.guardResponseSchema('#/definitions/IntegrationPaymentMethods', paymentMethods);

        return paymentMethods;
    }

    public async UpdateConfiguredIntegration(payload: UpdateConfiguredIntegrationPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/UpdateConfiguredIntegration',
            body: {
                commandName:
                    'App\\Domain\\WriteModel\\Integration\\ConfiguredIntegration\\UpdateConfiguredIntegration\\UpdateConfiguredIntegration',
                payload,
            },
        });
    }

    public async AssignAvailableIntegration(payload: AssignAvailableIntegrationPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/AssignAvailableIntegration',
            body: {
                commandName:
                    'App\\Domain\\WriteModel\\Integration\\ConfiguredIntegration\\UpdateConfiguredIntegration\\UpdateConfiguredIntegration',
                payload,
            },
        });
    }

    public async GetConfiguredIntegrationsCountByType(ownership: Ownership): Promise<IntegrationsCountByType> {
        let url = '';

        if (ownership.type === 'company') {
            url = `/configured-integration/types/company/${ownership.ownerId}`;
        } else if (ownership.type === 'branch') {
            url = `/configured-integration/types/branch/${ownership.ownerId}`;
        } else {
            throw new Error('Unsupported ownership type');
        }

        const results = await this.client.get({
            url,
        });

        this.guardResponseSchema('#/definitions/IntegrationsCountByType', results);

        return results;
    }

    public async GetConfiguredIntegrationsByType(
        pagination: Pagination,
        sorting: Sorting,
        integrationType: keyof IntegrationsCountByType,
    ): Promise<ConfiguredIntegrationsPage> {
        const configureIntegrations = await this.client.get({
            url: `/configured-integration/overview/type/${integrationType}`,
            params: {
                pagination,
                sorting,
            },
        });

        this.guardResponseSchema('#/definitions/ConfiguredIntegrationsPage', configureIntegrations);

        return configureIntegrations;
    }

    public async TestConfiguredIntegration(payload: TestConfiguredIntegrationPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/TestConfiguredIntegration',
            body: {
                commandName:
                    'App\\Domain\\WriteModel\\Integration\\ConfiguredIntegration\\TestConfiguredIntegration\\TestConfiguredIntegration',
                payload,
            },
        });
    }

    public async GetConfiguredIntegrationsForDevice(pagination: Pagination, sorting: Sorting): Promise<ConfiguredIntegrationsPage> {
        const configureIntegrations = await this.client.get({
            url: '/device/configured-integrations',
            params: {
                pagination,
                sorting,
            },
        });

        this.guardResponseSchema('#/definitions/ConfiguredIntegrationsPage', configureIntegrations);

        return configureIntegrations;
    }
}
