import { Domain } from 'api';

import { exportApi } from '@/api';
import { makeActions, makeReducer, makeReducerActions, makeSelectors } from '@/makeOverviewState';

type WebshopOrdersExportProps = keyof Domain.Export;

const defaultSorting: Domain.Sorting<WebshopOrdersExportProps> = {
    field: 'initializedOn',
    direction: 'descending',
};

const pageSize = 20;

export const selectors = makeSelectors<Domain.Export, WebshopOrdersExportProps>({
    getState: rootState => rootState.webshopOrderExport.overview,
});

export const reducerActions = makeReducerActions<Domain.Export, WebshopOrdersExportProps>({
    reducerPrefix: '@orderExport/overview',
});

export const actions = makeActions<Domain.Export, WebshopOrdersExportProps>({
    dataTableSaveKey: 'webshopOrderExportsOverview-v5',
    loadApi: options => exportApi.GetExports(options.pagination, options.sorting, { exportType: 'webshopOrderExport' }),
    defaultSorting,
    pageSize,
    getBaseUrl: () => '/webshops/order-export',
    reducerActions,
    selectors,
});

export const overviewReducer = makeReducer<Domain.Export, WebshopOrdersExportProps>({
    defaultSorting,
    pageSize,
    reducerActions,
});
