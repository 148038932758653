export function generateFakeOpeningHours() {
    return {
        Monday: [
            { from: '09:00', till: '12:00' },
            { from: '13:00', till: '19:00' },
        ],
        Tuesday: [
            { from: '09:00', till: '12:00' },
            { from: '13:00', till: '19:00' },
        ],
        Wednesday: [
            { from: '09:00', till: '12:00' },
            { from: '13:00', till: '19:00' },
        ],
        Thursday: [
            { from: '09:00', till: '12:00' },
            { from: '13:00', till: '19:00' },
        ],
        Friday: [
            { from: '09:00', till: '12:00' },
            { from: '13:00', till: '19:00' },
        ],
        Saturday: [
            { from: '10:00', till: '12:00' },
            { from: '13:00', till: '16:00' },
        ],
        Sunday: [],
    };
}
