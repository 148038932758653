import { combineReducers } from '@reduxjs/toolkit';

import { createShipmentReducer } from './createShipmentState';
import { detailsReducer } from './detailsState';
import { overviewReducer } from './overviewState';
import { shipmentExternalStatusesReducer } from './shipmentExternalStatusesState';

export const webshopOrderShipmentReducer = combineReducers({
    details: detailsReducer,
    overview: overviewReducer,
    createShipment: createShipmentReducer,
    shipmentExternalStatuses: shipmentExternalStatusesReducer,
});
