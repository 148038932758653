import * as React from 'react';

import ControlledToggle, { ControlledToggleProps } from './ControlledToggle';
import UncontrolledToggle, { UncontrolledToggleProps } from './UncontrolledToggle';

export type ToggleProps = ControlledToggleProps | UncontrolledToggleProps;

class Toggle extends React.PureComponent {
    render() {
        if (this.isControlled(this.props)) {
            return <ControlledToggle {...this.props} />;
        }

        return <UncontrolledToggle {...this.props} />;
    }

    private isControlled(props: ToggleProps): props is ControlledToggleProps {
        return (props as ControlledToggleProps).hasOwnProperty('isOn');
    }
}

export default React.forwardRef<HTMLInputElement, ToggleProps>((props, ref) => {
    return (
        <Toggle
            forwardRef={ref}
            {...props}
        />
    );
});
