import { createAction, Selector, createReducer as reduxCreateReducer } from '@reduxjs/toolkit';

import { Domain } from 'api';

import { ThunkAction, withPayloadType } from '@/action';
import { webshopFaqApi } from '@/api';
import { URLParams } from '@/routing';
import { RootState } from '@/store';

export interface State {
    faqCategories?: Domain.WebshopFaqCategoriesPage;
}

const initialState: State = {};

const reducerActions = {
    setFaqCategories: createAction('@webshopFaqItem/create/setFaqCategories', withPayloadType<Domain.WebshopFaqCategoriesPage>()),
};

export const createReducer = reduxCreateReducer(initialState, builder =>
    builder.addCase(reducerActions.setFaqCategories, (state, action) => {
        state.faqCategories = action.payload;
    }),
);

export const selectFaqCategories: Selector<RootState, Domain.WebshopFaqCategoriesPage> = state => {
    const faqCategories = state.webshopFaqItem.create.faqCategories;
    if (!faqCategories) {
        throw new Error('FAQ categories not loaded');
    }

    return faqCategories;
};

export const loadFaqCategories =
    (webshopId: Domain.Webshop['webshopId']): ThunkAction<Promise<Domain.WebshopFaqCategoriesPage>> =>
    async dispatch => {
        const faqCategories = await webshopFaqApi.GetWebshopFaqCategories(
            { page: 1, size: 999 },
            { field: 'sortOrder', direction: 'ascending' },
            webshopId,
        );

        await dispatch(reducerActions.setFaqCategories(faqCategories));

        return faqCategories;
    };

export const load =
    (options: { urlParams: URLParams }): ThunkAction =>
    async dispatch => {
        await dispatch(loadFaqCategories(options.urlParams.webshopId));
    };
