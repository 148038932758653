import { Domain } from 'api';

import { webshopApi } from '@/api';
import { makeActions, makeReducer, makeReducerActions, makeSelectors } from '@/makeOverviewState';

export type WebshopProps =
    | keyof Domain.Webshop
    | 'metaDataVersion'
    | 'metaDataLastDeployTime'
    | 'metaDataWebshopProductIds'
    | 'metaDataSelectedProductIds'
    | 'availableIntegrationIds'
    | 'hostname'
    | 'deployGroup';

export const defaultSorting: Domain.Sorting<WebshopProps> = {
    field: 'name',
    direction: 'ascending',
};

export const pageSize = 20;

export const selectors = makeSelectors<Domain.Webshop, WebshopProps>({
    getState: rootState => rootState.webshop.overview,
});

export const reducerActions = makeReducerActions<Domain.Webshop, WebshopProps>({
    reducerPrefix: '@webshop/overview',
});

export const actions = makeActions<
    Domain.Webshop,
    WebshopProps,
    {
        companyId?: Domain.Webshop['companyId'];
        branchId?: Domain.Webshop['branchId'];
    }
>({
    dataTableSaveKey: 'webshopsOverview-v5',
    loadApi: options => webshopApi.GetWebshops(options.ownership, options.pagination, options.sorting, options.search, options.filters),
    defaultSorting,
    pageSize,
    getBaseUrl: () => '/webshops/overview',
    reducerActions,
    selectors,
});

export const overviewReducer = makeReducer<Domain.Webshop, WebshopProps>({
    defaultSorting,
    pageSize,
    reducerActions,
});
