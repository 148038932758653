import { Domain } from 'api';

import { webhookApi } from '@/api';
import { makeActions, makeReducer, makeReducerActions, makeSelectors } from '@/makeOverviewState';

export type WebhookProps = keyof Domain.Webhook;

export const defaultSorting: Domain.Sorting<WebhookProps> = {
    field: 'createdOn',
    direction: 'descending',
};

export const pageSize = 20;

export const selectors = makeSelectors<Domain.Webhook, WebhookProps>({
    getState: rootState => rootState.webhook.overview,
});

export const reducerActions = makeReducerActions<Domain.Webhook, WebhookProps>({
    reducerPrefix: '@webhook/overview',
});

export const actions = makeActions<Domain.Webhook, WebhookProps>({
    dataTableSaveKey: 'webhooksOverview-v5',
    loadApi: options => webhookApi.GetWebhooks(options.pagination, options.sorting),
    defaultSorting,
    pageSize,
    getBaseUrl: () => '/webhooks',
    reducerActions,
    selectors,
});

export const overviewReducer = makeReducer<Domain.Webhook, WebhookProps>({
    defaultSorting,
    pageSize,
    reducerActions,
});
