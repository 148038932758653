import * as React from 'react';

import classNames from 'classnames';

import { slugify } from 'utils';

import color from '@/color';
import { BaseProps, KeyboardHandler } from '@/core';
import { Icon } from '@/icon';

import MenuItem from './MenuItem';

import styles from './Menu.scss';

export type IMenuItem<T = unknown> = T & {
    label: string | React.ReactNode;
    value: string;
    submenu?: React.ReactNode;
    action?: (event: React.MouseEvent | React.KeyboardEvent, item: IMenuItem<T>) => Promise<void> | void;
    disabled?: boolean;
    className?: string;
};

export interface IMenuProps<T> {
    children?: React.ReactNode;
    items?: IMenuItem<T>[];
    maxHeight?: string;
    minHeight?: string;
    selectedItems?: string[];
    onItemClick?: (event: React.MouseEvent | React.KeyboardEvent, item: IMenuItem<T>) => Promise<void> | void;
    itemRenderer?: (item: IMenuItem<T>) => string | React.ReactNode;
    disableItemFocus?: boolean;
    showSelectedOnTop?: boolean;
    leftSubMenus?: boolean;
}

export default class Menu<
    T = Record<string, unknown>,
    P = Record<string, unknown>,
    S = Record<string, unknown>,
> extends React.PureComponent<React.HTMLProps<HTMLDivElement> & BaseProps & IMenuProps<T> & P, S> {
    private keyHandler = new KeyboardHandler();

    render() {
        const { style, className, maxHeight, minHeight, children, leftSubMenus } = this.props;
        const rest = this.restOfProps();
        const menuItems = this.getItems();

        if (!menuItems) {
            return null;
        }
        const ownStyle: React.CSSProperties = {};

        if (maxHeight) {
            ownStyle.maxHeight = maxHeight;
        }

        if (minHeight) {
            ownStyle.minHeight = minHeight;
        }

        return (
            <div
                {...rest}
                style={{
                    ...ownStyle,
                    ...style,
                }}
                className={classNames(
                    styles.Menu,
                    maxHeight ? styles.WithMaxHeight : null,
                    leftSubMenus ? styles.LeftSubMenus : null,
                    className,
                )}
                onScroll={this.onScroll.bind(this)}
            >
                {menuItems.map(this.renderItem)}
                {children}
            </div>
        );
    }

    protected onScroll(_1: React.UIEvent<HTMLElement>): void {}

    protected restOfProps(): React.HTMLProps<HTMLDivElement> {
        const {
            className,
            style,
            children,
            items,
            selectedItems,
            maxHeight,
            minHeight,
            onItemClick,
            itemRenderer,
            disableItemFocus,
            showSelectedOnTop,
            leftSubMenus,
            ...rest
        } = this.props;

        return rest;
    }

    protected renderItem = (item: IMenuItem<T>): React.ReactNode => {
        return (
            <MenuItem
                key={item.value}
                className={classNames(
                    item.disabled ? styles.MenuItemDisabled : null,
                    item.submenu ? styles.MenuItemWrapperWithChildren : null,
                    this.isItemSelected(item) ? styles.SelectedMenuItem : null,
                    item.className ? item.className : null,
                )}
                onKeyDown={this.keyHandler.handleKey(['ENTER', 'SPACE'], event => this.handleItemSelect(event, item))}
                onClick={(event: React.MouseEvent) => {
                    this.handleItemSelect(event, item);
                }}
                tabIndex={this.props.disableItemFocus || item.disabled ? -1 : 0}
                otherChildren={
                    item.submenu ? (
                        <>
                            {this.props.leftSubMenus ? (
                                <Icon
                                    iconSize="m"
                                    type="action_left"
                                    className={color.Grey.Dark2}
                                />
                            ) : (
                                <Icon
                                    iconSize="m"
                                    type="action_right"
                                    className={color.Grey.Dark2}
                                />
                            )}
                            {item.submenu}
                        </>
                    ) : null
                }
            >
                <label data-test-id={this.getItemDataTestId(item)}>{this.renderItemLabel(item)}</label>
            </MenuItem>
        );
    };

    private renderItemLabel(item: IMenuItem<T>) {
        if (this.props.itemRenderer) {
            return this.props.itemRenderer(item);
        }

        return item.label;
    }

    private getItemDataTestId(item: IMenuItem<T>) {
        if (typeof item.label === 'string') {
            return `menu-item-${slugify(item.label)}`;
        }

        return `menu-item-${item.value}`;
    }

    private handleItemSelect = async (event: React.MouseEvent | React.KeyboardEvent, item: IMenuItem<T>): Promise<void> => {
        if (this.props.onItemClick) {
            await this.props.onItemClick(event, item);
        }

        if (item.action) {
            await item.action(event, item);
        }

        this.afterItemSelect();
    };

    // @ts-ignore
    protected afterItemSelect = (): void => {
        // do nothing
    };

    private isItemSelected(item: IMenuItem<T>): boolean {
        const { selectedItems } = this.props;

        if (!selectedItems || selectedItems.length === 0) {
            return false;
        }

        return selectedItems.indexOf(item.value) > -1;
    }

    protected getItems(): IMenuItem<T>[] {
        const { items, showSelectedOnTop, selectedItems } = this.props;

        if (!items) {
            return [];
        }

        if (!showSelectedOnTop || !selectedItems || selectedItems.length === 0) {
            return items!;
        }

        items!.sort((a, b) => {
            const aIsSelected = selectedItems.indexOf(a.value) > -1;
            const bIsSelected = selectedItems.indexOf(b.value) > -1;

            if (aIsSelected && bIsSelected) {
                return 0;
            } else if (aIsSelected) {
                return -1;
            }
            return 1;
        });

        return items!;
    }
}
