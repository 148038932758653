import { Domain } from 'api';

import { notificationApi } from '@/api';
import { makeActions, makeReducer, makeReducerActions, makeSelectors } from '@/makeOverviewState';

export type NotificationProps = keyof Domain.Notification;

export const defaultSorting: Domain.Sorting<NotificationProps> = {
    field: 'createdOn',
    direction: 'descending',
};

export const pageSize = 20;

export const selectors = makeSelectors<Domain.Notification, NotificationProps>({
    getState: rootState => rootState.platformNotificationAdminOverview,
});

export const reducerActions = makeReducerActions<Domain.Notification, NotificationProps>({
    reducerPrefix: '@platformNotification/adminOverview',
});

export const actions = makeActions<
    Domain.Notification,
    NotificationProps,
    {
        includeRead?: 'yes' | 'no';
        userId?: Domain.User['userId'];
        branchId?: Domain.Branch['branchId'];
        companyId?: Domain.Company['companyId'];
    }
>({
    dataTableSaveKey: 'notificationsAdminOverview-v5',
    loadApi: options => notificationApi.GetNotifications(options.pagination, options.sorting, options.filters),
    defaultSorting,
    pageSize,
    getBaseUrl: () => '/dashboard/all-platform-notifications',
    reducerActions,
    selectors,
});

export const overviewReducer = makeReducer<Domain.Notification, NotificationProps>({
    defaultSorting,
    pageSize,
    reducerActions,
});
