import { Pagination } from './Pagination';

export type Country = string;

export type CurrencyCode = string;
export interface Currency {
    name: CurrencyCode;
    symbol: string;
}

export const EURO_CURRENCY: Currency = {
    name: 'EUR',
    symbol: '€',
};

export type Locale = string;

export const WEEK_DAYS = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'] as const;
export type Day = (typeof WEEK_DAYS)[number];

export interface Localisation {
    country: Country;
    locales: Locale[];
}

export type LocalizedValue<T = string> = {
    [p in Locale]?: T | null;
};

export type UnusableLocalizedValue =
    | string
    | {
          [key: string]: string | null;
      };

export type UnusableLocalizedHTMLValue = {
    [key: string]:
        | string
        | {
              html: string | null;
              markdown: string | null;
          }
        | null;
};

export interface LocalisationsPage {
    items: Localisation[];
    total: number;
    pagination: Pagination;
}

export type CreateLocalisationPayload = Localisation;

export type UpdateLocalisationPayload = Localisation;
