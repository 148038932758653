import * as React from 'react';

import ControlledRadioGroup, { ControlledRadioGroupPropsWithValue } from './ControlledRadioGroup';
import UncontrolledRadioGroup, { UncontrolledRadioGroupProps } from './UncontrolledRadioGroup';

export type RadioGroupProps = ControlledRadioGroupPropsWithValue | UncontrolledRadioGroupProps;

class RadioGroup extends React.PureComponent<RadioGroupProps> {
    render() {
        if (this.isControlled(this.props)) {
            return <ControlledRadioGroup {...this.props} />;
        }

        return <UncontrolledRadioGroup {...this.props} />;
    }

    private isControlled(props: RadioGroupProps): props is ControlledRadioGroupPropsWithValue {
        return (props as ControlledRadioGroupPropsWithValue).hasOwnProperty('value');
    }
}

export default React.forwardRef<HTMLInputElement, RadioGroupProps>((props, ref) => {
    return (
        <RadioGroup
            forwardRef={ref}
            {...props}
        />
    );
});
