import * as React from 'react';

import { preloadFont } from 'preloaders';
import { HTMLEditorValue } from 'utils';

export default function HTMLEditorView(props: { value: string; style?: React.CSSProperties; innerStyle?: React.CSSProperties }) {
    HTMLEditorValue.mapFromString(props.value, {
        handleFontFamily: fontFamily => {
            preloadFont(fontFamily);
        },
    });

    return (
        <div
            className="RTView HTMLEditorView"
            style={props.style}
        >
            <div
                style={props.innerStyle}
                dangerouslySetInnerHTML={{ __html: props.value }}
            />
        </div>
    );
}
