import * as React from 'react';

import { store, Actions } from '../state';
import { getProductImages, getSelectedImage } from '../utils';

export default function ProductImageSizeComputer(props: { proxyImageURL: (url: string) => string }) {
    const { state, dispatch } = React.useContext(store);

    React.useEffect(() => {
        const images: HTMLImageElement[] = [];

        const loadImage = (imageUrl: string) => {
            if (!state.productImageSizes.hasOwnProperty(imageUrl)) {
                const img = document.createElement('img');
                images.push(img);

                img.onload = () => {
                    dispatch({
                        type: Actions.SET_PRODUCT_IMAGE_SIZE,
                        imageUrl: imageUrl,
                        width: img.width,
                        height: img.height,
                    });
                };

                img.src = props.proxyImageURL(imageUrl);
            }
        };

        for (const frame of state.canvas.frames) {
            if (frame.type === 'product') {
                const availableProduct = state.availableProducts.find(availableProduct => availableProduct.productId === frame.productId);

                if (availableProduct) {
                    const productImages = getProductImages(availableProduct);
                    const selectedImage = getSelectedImage(productImages, frame.productMediaType, frame.imageId);
                    loadImage(selectedImage.url);
                }
            }
        }

        return () => {
            for (const img of images) {
                img.onload = null;
                img.remove();
            }
        };
    }, [state.availableProducts, state.productImageSizes, state.canvas.frames]);

    return <span />;
}
