import * as React from 'react';

import TouchAndMouseDragHandler from './TouchAndMouseDragHandler';

import styles from './SortableList.scss';

interface IProps {
    items: React.ReactNode[];
    direction: 'vertical' | 'horizontal';
    itemSize: number;
    onItemMove: (itemIndex: number, moveOffset: number) => void;
}

interface IState {
    draggedItemIndex: number | undefined;
    totalXMove: number;
    totalYMove: number;
    moveXOffset: number;
    moveYOffset: number;
}

export default class SortableList extends React.PureComponent<IProps, IState> {
    private readonly moveHandler: TouchAndMouseDragHandler<number>;

    constructor(props: IProps) {
        super(props);

        this.state = {
            draggedItemIndex: undefined,
            totalXMove: 0,
            totalYMove: 0,
            moveXOffset: 0,
            moveYOffset: 0,
        };

        // we always use this.props inside the move handler, otherwise it's a reference to the initial constructor props
        this.moveHandler = new TouchAndMouseDragHandler<number>(
            move => {
                const newTotalXMove = this.state.totalXMove + move.movementX;
                const newTotalYMove = this.state.totalYMove + move.movementY;

                this.setState({
                    totalXMove: newTotalXMove,
                    totalYMove: newTotalYMove,
                    moveXOffset: Math.round(newTotalXMove / this.props.itemSize),
                    moveYOffset: Math.round(newTotalYMove / this.props.itemSize),
                });
            },
            (_1, index) => {
                this.setState({
                    draggedItemIndex: index,
                });
            },
            () => {
                const { draggedItemIndex, moveXOffset, moveYOffset } = this.state;
                const moveOffset = this.props.direction === 'horizontal' ? moveXOffset : moveYOffset;

                if (draggedItemIndex !== undefined && moveOffset !== 0) {
                    let cappedMoveOffset = moveOffset;
                    if (draggedItemIndex + cappedMoveOffset > this.props.items.length - 1) {
                        cappedMoveOffset = this.props.items.length - 1 - draggedItemIndex;
                    } else if (draggedItemIndex + cappedMoveOffset < 0) {
                        cappedMoveOffset = -draggedItemIndex;
                    }

                    this.props.onItemMove(draggedItemIndex, cappedMoveOffset);
                }

                this.setState({
                    draggedItemIndex: undefined,
                    totalXMove: 0,
                    totalYMove: 0,
                    moveXOffset: 0,
                    moveYOffset: 0,
                });
            },
        );
    }

    render() {
        const { items, direction, itemSize } = this.props;
        const { draggedItemIndex, totalXMove, totalYMove, moveXOffset, moveYOffset } = this.state;
        const moveOffset = direction === 'horizontal' ? moveXOffset : moveYOffset;
        let cappedMoveOffset = moveOffset;

        const itemIndexes = [];
        for (let i = 0; i < items.length; i++) {
            itemIndexes.push(i);
        }

        if (draggedItemIndex !== undefined && moveOffset !== 0) {
            if (draggedItemIndex + cappedMoveOffset > items.length - 1) {
                cappedMoveOffset = items.length - 1 - draggedItemIndex;
            } else if (draggedItemIndex + cappedMoveOffset < 0) {
                cappedMoveOffset = -draggedItemIndex;
            }

            const itemToMove = itemIndexes.splice(draggedItemIndex, 1)[0];
            itemIndexes.splice(draggedItemIndex + cappedMoveOffset, 0, itemToMove);
        }

        const listStyle: React.CSSProperties = {};
        if (direction === 'horizontal') {
            listStyle.width = itemSize * items.length + 'px';
        } else {
            listStyle.height = itemSize * items.length + 'px';
        }

        return (
            <div
                className={`${styles.SortableList} ${direction}`}
                style={listStyle}
            >
                {itemIndexes.map(index => {
                    const isDraggedItem = this.state.draggedItemIndex === index;
                    const itemStyle: React.CSSProperties = {};

                    if (isDraggedItem) {
                        if (direction === 'horizontal') {
                            itemStyle.left = totalXMove - cappedMoveOffset * itemSize + 'px';
                        } else {
                            itemStyle.top = totalYMove - cappedMoveOffset * itemSize + 'px';
                        }
                    }

                    return (
                        <div
                            key={index}
                            className={`
                                ${styles.SortableListItem}
                                ${this.state.draggedItemIndex !== undefined ? styles.DraggingSortableListItem : ''}
                                ${isDraggedItem ? styles.DraggedSortableListItem : ''}
                            `}
                            style={itemStyle}
                            onMouseDown={event => this.moveHandler.handleMouseDown(event, index)}
                            onTouchStart={event => this.moveHandler.handleTouchStart(event, index)}
                        >
                            {items[index]}
                        </div>
                    );
                })}
            </div>
        );
    }
}
