import { createAction, createReducer, Selector } from '@reduxjs/toolkit';

import { Domain } from 'api';

import { ThunkAction, withPayloadType } from '@/action';
import { webshopApi } from '@/api';
import { URLParams } from '@/routing';
import { RootState } from '@/store';

export interface State {
    webshop?: Domain.WebshopDetails;
    configuration?: Domain.WebshopConfiguration;
}

const initialState: State = {};

const reducerActions = {
    setWebshop: createAction('@webshop/details/setWebshop', withPayloadType<Domain.WebshopDetails>()),
    setConfiguration: createAction('@webshop/details/setConfiguration', withPayloadType<Domain.WebshopConfiguration>()),
};

export const detailsReducer = createReducer(initialState, builder =>
    builder
        .addCase(reducerActions.setWebshop, (state, action) => {
            state.webshop = action.payload;
        })
        .addCase(reducerActions.setConfiguration, (state, action) => {
            state.configuration = action.payload;
        }),
);

export const selectWebshop: Selector<RootState, Domain.WebshopDetails> = state => {
    const webshop = state.webshop.details.webshop;
    if (!webshop) {
        throw new Error('Webshop details not loaded');
    }

    return webshop;
};

export const selectConfiguration: Selector<RootState, Domain.WebshopConfiguration> = state => {
    const configuration = state.webshop.details.configuration;
    if (!configuration) {
        throw new Error('Webshop configuration not loaded');
    }

    return configuration;
};

export const loadWebshopDetails =
    (webshopId: string): ThunkAction =>
    async dispatch => {
        const webshopDetails = await webshopApi.GetWebshopDetails(webshopId);
        dispatch(reducerActions.setWebshop(webshopDetails));
    };

export const loadConfiguration =
    (webshopId: string): ThunkAction =>
    async dispatch => {
        const configuration = await webshopApi.GetWebshopConfiguration(webshopId);
        await dispatch(reducerActions.setConfiguration(configuration));
    };

export const load =
    (options: { urlParams: URLParams }): ThunkAction =>
    async dispatch => {
        await Promise.all([
            dispatch(loadWebshopDetails(options.urlParams.webshopId)),
            dispatch(loadConfiguration(options.urlParams.webshopId)),
        ]);
    };
