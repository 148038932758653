import { Domain } from 'api';

export default function localizeCountryLabel(country: Domain.Country, gettext: (text: string) => string) {
    let countryLabel = '';

    switch (country) {
        case 'BE':
            countryLabel = gettext('Belgium');
            break;
        case 'FR':
            countryLabel = gettext('France');
            break;
        case 'AU':
            countryLabel = gettext('Australia');
            break;
        case 'DE':
            countryLabel = gettext('Germany');
            break;
        case 'ES':
            countryLabel = gettext('Spain');
            break;
        case 'AT':
            countryLabel = gettext('Austria');
            break;
        case 'CZ':
            countryLabel = gettext('Czechia');
            break;
        case 'SG':
            countryLabel = gettext('Singapore');
            break;
        case 'PT':
            countryLabel = gettext('Portugal');
            break;
        case 'GB':
            countryLabel = gettext('Great Britain');
            break;
        case 'LU':
            countryLabel = gettext('Luxembourg');
            break;
    }

    return countryLabel;
}
