import { createAction, createReducer, Selector } from '@reduxjs/toolkit';

import { Domain } from 'api';

import { ThunkAction, withPayloadType } from '@/action';
import { sharingGroupApi } from '@/api';
import { getDataTableSavedSettings, setDataTableSavedSettings } from '@/dataTableSavedSettings';
import { getOverviewQueryParameters, updateURLWithQueryParams, URLQuery } from '@/routing';
import { RootState } from '@/store';

const SHARING_GROUPS_OVERVIEW_DATA_TABLE_SAVE_KEY = 'sharingGroupsOverview-v1';

type SharingGroupProp = keyof Domain.SharingGroupDetails;

interface Filters {
    [key: string]: string | undefined;

    ownerCompanyId?: Domain.Company['companyId'];
}

export interface State {
    sharingGroups?: Domain.SharingGroupsPage;
    sorting: Domain.Sorting<SharingGroupProp>;
    pagination: Domain.Pagination;
    search: string;
    filters: Filters;
    visibleColumns: undefined | SharingGroupProp[];
    sortableColumns: undefined | SharingGroupProp[];
}

const initialState: State = {
    pagination: {
        page: 1,
        size: 20,
    },
    sorting: {
        field: 'name',
        direction: 'ascending',
    },
    search: '',
    filters: {},
    visibleColumns: undefined,
    sortableColumns: undefined,
};

const reducerActions = {
    setSharingGroups: createAction('@sharingGroup/overview/setSharingGroups', withPayloadType<Domain.SharingGroupsPage>()),
    setPagination: createAction('@sharingGroup/overview/setPagination', withPayloadType<Domain.Pagination>()),
    setPaginationPage: createAction('@sharingGroup/overview/setPaginationPage', withPayloadType<Domain.Pagination['page']>()),
    setSorting: createAction('@sharingGroup/overview/setSorting', withPayloadType<Domain.Sorting<SharingGroupProp>>()),
    setSearch: createAction('@sharingGroup/overview/setSearch', withPayloadType<string>()),
    setFilters: createAction('@sharingGroup/overview/setFilters', withPayloadType<Filters>()),
    setVisibleColumns: createAction('@sharingGroup/overview/setVisibleColumns', withPayloadType<SharingGroupProp[]>()),
    setSortableColumns: createAction('@sharingGroup/overview/setSortableColumns', withPayloadType<SharingGroupProp[]>()),
};

export const overviewReducer = createReducer(initialState, builder =>
    builder
        .addCase(reducerActions.setSharingGroups, (state, action) => {
            state.sharingGroups = action.payload;
        })
        .addCase(reducerActions.setPagination, (state, action) => {
            state.pagination = action.payload;
        })
        .addCase(reducerActions.setPaginationPage, (state, action) => {
            state.pagination.page = action.payload;
        })
        .addCase(reducerActions.setSorting, (state, action) => {
            state.sorting = action.payload;
        })
        .addCase(reducerActions.setSearch, (state, action) => {
            state.search = action.payload;
        })
        .addCase(reducerActions.setFilters, (state, action) => {
            state.filters = action.payload;
        })
        .addCase(reducerActions.setVisibleColumns, (state, action) => {
            state.visibleColumns = action.payload;
        })
        .addCase(reducerActions.setSortableColumns, (state, action) => {
            state.sortableColumns = action.payload;
        }),
);

export const selectSharingGroups: Selector<RootState, Domain.SharingGroupsPage | undefined> = state =>
    state.sharingGroup.overview.sharingGroups;
export const selectPagination: Selector<RootState, Domain.Pagination> = state => state.sharingGroup.overview.pagination;
export const selectSorting: Selector<RootState, Domain.Sorting<SharingGroupProp>> = state => state.sharingGroup.overview.sorting;
export const selectSearch: Selector<RootState, string> = state => state.sharingGroup.overview.search;
export const selectFilters: Selector<RootState, Filters> = state => state.sharingGroup.overview.filters;
export const selectVisibleColumns: Selector<RootState, undefined | SharingGroupProp[]> = state =>
    state.sharingGroup.overview.visibleColumns;
export const selectSortableColumns: Selector<RootState, undefined | SharingGroupProp[]> = state =>
    state.sharingGroup.overview.sortableColumns;

export const setVisibleColumns =
    (visibleColumns: SharingGroupProp[]): ThunkAction =>
    async (dispatch, getState) => {
        const state = getState();
        const sorting = selectSorting(state);

        setDataTableSavedSettings(SHARING_GROUPS_OVERVIEW_DATA_TABLE_SAVE_KEY, {
            sorting,
            visibleColumns,
        });

        await dispatch(reducerActions.setVisibleColumns(visibleColumns as SharingGroupProp[]));
    };
export const setSortableColumns =
    (sortableColumns: SharingGroupProp[]): ThunkAction =>
    async (dispatch, getState) => {
        const state = getState();
        const sorting = selectSorting(state);

        setDataTableSavedSettings(SHARING_GROUPS_OVERVIEW_DATA_TABLE_SAVE_KEY, {
            sorting,
            sortableColumns,
        });

        await dispatch(reducerActions.setSortableColumns(sortableColumns as SharingGroupProp[]));
    };
export const setSorting =
    (sorting: Domain.Sorting<SharingGroupProp>): ThunkAction =>
    async (dispatch, getState) => {
        const state = getState();
        const visibleColumns = selectVisibleColumns(state);

        setDataTableSavedSettings(SHARING_GROUPS_OVERVIEW_DATA_TABLE_SAVE_KEY, {
            sorting,
            visibleColumns,
        });

        await dispatch(reducerActions.setSorting(sorting as Domain.Sorting<SharingGroupProp>));
    };

export const setSearch =
    (search: string): ThunkAction =>
    dispatch => {
        dispatch(reducerActions.setSearch(search));
    };

export const setFilters =
    (filters: Filters): ThunkAction =>
    async dispatch => {
        await dispatch(reducerActions.setFilters(filters));
    };

export const setPaginationPage =
    (page: Domain.Pagination['page']): ThunkAction =>
    async dispatch => {
        await dispatch(reducerActions.setPaginationPage(page));
    };

export const loadSharingGroups =
    (updateURL?: boolean): ThunkAction =>
    async (dispatch, getState, getNavigate) => {
        const state = getState();
        const navigate = getNavigate();
        const pagination = selectPagination(state);
        const sorting = selectSorting(state);
        const search = selectSearch(state);
        const filters = selectFilters(state);

        if (updateURL) {
            updateURLWithQueryParams({
                baseURL: '/customers/sharing-groups',
                navigate,
                pagination,
                sorting,
                search,
                filters,
                defaultSorting: initialState.sorting,
            });
        }

        const sharingGroups = await sharingGroupApi.GetSharingGroups(pagination, sorting, search, filters);
        await dispatch(reducerActions.setSharingGroups(sharingGroups));
    };

export const load =
    (options: { urlQuery: URLQuery }): ThunkAction =>
    async (dispatch, getState) => {
        const savedSettings = getDataTableSavedSettings(SHARING_GROUPS_OVERVIEW_DATA_TABLE_SAVE_KEY);
        if (savedSettings) {
            if (savedSettings.sorting) {
                await dispatch(reducerActions.setSorting(savedSettings.sorting as Domain.Sorting<SharingGroupProp>));
            }
            if (savedSettings.visibleColumns) {
                await dispatch(reducerActions.setVisibleColumns(savedSettings.visibleColumns as SharingGroupProp[]));
            }
            if (savedSettings.sortableColumns) {
                await dispatch(reducerActions.setSortableColumns(savedSettings.sortableColumns as SharingGroupProp[]));
            }
        }

        const state = getState();
        const defaultSorting = selectSorting(state);

        const queryParams = getOverviewQueryParameters({
            urlQuery: options.urlQuery,
            defaultSorting,
        });

        await dispatch(setSearch(queryParams.search !== null ? queryParams.search : ''));
        await dispatch(setPaginationPage(queryParams.page !== null ? queryParams.page : 1));
        await dispatch(
            setSorting(queryParams.sorting !== null ? (queryParams.sorting as Domain.Sorting<SharingGroupProp>) : defaultSorting),
        );
        await dispatch(setFilters(queryParams.filters !== null ? queryParams.filters : {}));

        await dispatch(loadSharingGroups());
    };
