import { createAction, createReducer, Selector } from '@reduxjs/toolkit';

import { Domain } from 'api';
import * as DeviceInterface from 'meditech-device-interface';

import { withPayloadType, ThunkAction } from '@/action';
import { productSelectionApi } from '@/api';
import { selectCurrentLocale } from '@/I18n';
import { meditechDeviceApi } from '@/meditechDeviceApi';
import { RootState } from '@/store';

import * as deviceState from './deviceState';
import { selectDevice } from './selectors';
import { HistoryItem, ProductEjectJob } from './types';

export interface InteractionState {
    history: HistoryItem[];
    accessibilityModeEnabled: boolean;
    hasBeenInteractedWith: boolean;
    lastInteractionTime: number;
    showInactivityDialog: boolean;
    disableInactivityPeriod: boolean;
    refreshRequestedDuringInteraction: boolean;
    contentVersionChangedDuringInteraction: false | number;
    ejectQueue: ProductEjectJob[];
}

const initialState: InteractionState = {
    history: [],
    accessibilityModeEnabled: true,
    hasBeenInteractedWith: false,
    lastInteractionTime: Date.now(),
    showInactivityDialog: false,
    disableInactivityPeriod: false,
    refreshRequestedDuringInteraction: false,
    contentVersionChangedDuringInteraction: false,
    ejectQueue: [],
};

export const reducerActions = {
    pushToHistory: createAction('@tvScreen/interaction/pushToHistory', withPayloadType<HistoryItem>()),
    goBackInHistory: createAction('@tvScreen/interaction/goBackInHistory', withPayloadType<void>()),
    clearHistory: createAction('@tvScreen/interaction/clearHistory', withPayloadType<void>()),
    setSearchQuery: createAction('@tvScreen/interaction/setSearchQuery', withPayloadType<string>()),
    setSearchResults: createAction('@tvScreen/interaction/setSearchResults', withPayloadType<undefined | Domain.SelectedProduct[]>()),
    setAccessibilityModeEnabled: createAction('@tvScreen/interaction/setAccessibilityModeEnabled', withPayloadType<boolean>()),
    updateLastInteractionTime: createAction('@tvScreen/interaction/updateLastInteractionTime', withPayloadType<void>()),
    setShowInactivityDialog: createAction('@tvScreen/interaction/setShowInactivityDialog', withPayloadType<boolean>()),
    setDisableInactivityPeriod: createAction('@tvScreen/interaction/setDisableInactivityPeriod', withPayloadType<boolean>()),
    resetAllInteractivity: createAction('@tvScreen/interaction/resetAllInteractivity', withPayloadType<void>()),
    setRefreshRequestedDuringInteraction: createAction(
        '@tvScreen/interaction/setRefreshRequestedDuringInteraction',
        withPayloadType<boolean>(),
    ),
    setContentVersionChangedDuringInteraction: createAction(
        '@tvScreen/interaction/setContentVersionChangedDuringInteraction',
        withPayloadType<false | number>(),
    ),
    queueEjectJob: createAction('@tvScreen/interaction/queueEjectJob', withPayloadType<ProductEjectJob>()),
    setEjectJobStatus: createAction(
        '@tvScreen/interaction/setEjectJobStatus',
        withPayloadType<{
            jobId: number;
            status: ProductEjectJob['status'];
        }>(),
    ),
    removeEjectJob: createAction('@tvScreen/interaction/removeEjectJob', withPayloadType<number>()),
};

function markThatDeviceHasBeenInteractedWith(state: InteractionState): InteractionState {
    // console.info('START interaction', new Date());
    state.hasBeenInteractedWith = true;
    return state;
}

function clearThatDeviceHasBeenInteractedWith(state: InteractionState): InteractionState {
    console.info('RESET interaction', new Date());
    state.hasBeenInteractedWith = false;
    return state;
}

export const interactionReducer = createReducer(initialState, builder =>
    builder
        .addCase(reducerActions.pushToHistory, (state, action) => {
            state = markThatDeviceHasBeenInteractedWith(state);
            state.history = [...state.history, action.payload];
        })
        .addCase(reducerActions.goBackInHistory, state => {
            state = markThatDeviceHasBeenInteractedWith(state);
            state.history = [...state.history.slice(0, state.history.length - 1)];
        })
        .addCase(reducerActions.clearHistory, state => {
            state = markThatDeviceHasBeenInteractedWith(state);
            state.history = [];
        })
        .addCase(reducerActions.setSearchQuery, (state, action) => {
            const history = state.history;
            if (history.length === 0) {
                throw new Error('Cannot set search query if not on search page');
            }

            const currentHistory = history[history.length - 1];
            if (currentHistory.type !== 'search' && currentHistory.type !== 'slidesOverview' && currentHistory.type !== 'manageScreen') {
                throw new Error('Cannot set search query if not on search page');
            }

            state = markThatDeviceHasBeenInteractedWith(state);
            state.history = [
                ...state.history.slice(0, state.history.length - 1),
                {
                    ...currentHistory,
                    query: action.payload,
                },
            ];
        })
        .addCase(reducerActions.setSearchResults, (state, action) => {
            const history = state.history;
            if (history.length === 0) {
                console.warn('Cannot set search results if not on search page');
                return;
            }

            const currentHistory = history[history.length - 1];
            if (currentHistory.type !== 'search') {
                console.warn('Cannot set search results if not on search page');
                return;
            }

            state = markThatDeviceHasBeenInteractedWith(state);
            state.history = [
                ...state.history.slice(0, state.history.length - 1),
                {
                    ...currentHistory,
                    query: currentHistory.query,
                    results: action.payload,
                },
            ];
        })
        .addCase(reducerActions.setAccessibilityModeEnabled, (state, action) => {
            state = markThatDeviceHasBeenInteractedWith(state);
            state.accessibilityModeEnabled = action.payload;
        })
        .addCase(reducerActions.updateLastInteractionTime, state => {
            state.lastInteractionTime = Date.now();
        })
        .addCase(reducerActions.setShowInactivityDialog, (state, action) => {
            state.showInactivityDialog = action.payload;
        })
        .addCase(reducerActions.setDisableInactivityPeriod, (state, action) => {
            console.info('SET INACTIVITY TIMEOUT DISABLED', action.payload);
            state.disableInactivityPeriod = action.payload;
        })
        .addCase(reducerActions.resetAllInteractivity, state => {
            state = clearThatDeviceHasBeenInteractedWith(state);
            state.lastInteractionTime = Date.now();
            state.showInactivityDialog = false;
            state.accessibilityModeEnabled = false;
            state.history = [];
        })
        .addCase(reducerActions.setRefreshRequestedDuringInteraction, (state, action) => {
            state.refreshRequestedDuringInteraction = action.payload;
        })
        .addCase(reducerActions.setContentVersionChangedDuringInteraction, (state, action) => {
            state.contentVersionChangedDuringInteraction = action.payload;
        })
        .addCase(reducerActions.queueEjectJob, (state, action) => {
            state.ejectQueue = [action.payload, ...state.ejectQueue];
        })
        .addCase(reducerActions.setEjectJobStatus, (state, action) => {
            state.ejectQueue = [
                ...state.ejectQueue.map(job => {
                    if (job.jobId === action.payload.jobId) {
                        return {
                            ...job,
                            status: action.payload.status,
                        };
                    }
                    return job;
                }),
            ];
        })
        .addCase(reducerActions.removeEjectJob, (state, action) => {
            state.ejectQueue = [
                ...state.ejectQueue.filter(job => {
                    if (job.jobId === action.payload) {
                        return false;
                    }
                    return true;
                }),
            ];
        }),
);

export const selectCurrentHistory: Selector<RootState, HistoryItem | undefined> = state => {
    const history = state.tvScreen.interaction.history;
    if (history.length < 1) {
        return undefined;
    }

    return history[history.length - 1];
};

export const selectPreviousHistory: Selector<RootState, HistoryItem | undefined> = state => {
    const history = state.tvScreen.interaction.history;
    if (history.length < 2) {
        return undefined;
    }

    return history[history.length - 2];
};

export const selectForcedSlideOffset: Selector<RootState, number | undefined> = state => {
    const currentHistory = selectCurrentHistory(state);

    if (currentHistory && currentHistory.type === 'forcedSlide') {
        return currentHistory.slideOffset;
    }
};

export const selectLastInteractionTime: Selector<RootState, number> = state => state.tvScreen.interaction.lastInteractionTime;
export const selectAccessibilityModeEnabled: Selector<RootState, boolean> = state => state.tvScreen.interaction.accessibilityModeEnabled;
export const selectShowInactivityDialog: Selector<RootState, boolean> = state => state.tvScreen.interaction.showInactivityDialog;
export const selectDisableInactivityPeriod: Selector<RootState, boolean> = state => state.tvScreen.interaction.disableInactivityPeriod;
export const selectHasBeenInteractedWith: Selector<RootState, boolean> = state => state.tvScreen.interaction.hasBeenInteractedWith;
export const selectRefreshRequestedDuringInteraction: Selector<RootState, boolean> = state =>
    state.tvScreen.interaction.refreshRequestedDuringInteraction;
export const selectContentVersionChangedDuringInteraction: Selector<RootState, false | number> = state =>
    state.tvScreen.interaction.contentVersionChangedDuringInteraction;
export const selectEjectQueue: Selector<RootState, ProductEjectJob[]> = state => state.tvScreen.interaction.ejectQueue;

export const handleBarcodeScan =
    (barcode: string): ThunkAction =>
    async (dispatch, getState) => {
        const state = getState();
        const device = selectDevice(state);
        const uiLocale = selectCurrentLocale(state);

        if (!device) {
            return;
        }

        dispatch(
            reducerActions.pushToHistory({
                type: 'search',
                query: '',
                results: [],
            }),
        );

        dispatch(
            reducerActions.pushToHistory({
                type: 'search',
                query: barcode,
                results: [],
            }),
        );

        const products = await productSelectionApi.GetSelectedProducts(
            { type: 'branch', ownerId: device.branchId },
            'inStoreProductSelection',
            { page: 1, size: 1 },
            uiLocale,
            barcode,
        );

        if (products.items.length === 1) {
            dispatch(
                reducerActions.pushToHistory({
                    type: 'productDetails',
                    productDetails: products.items[0],
                }),
            );
        } else {
            dispatch(reducerActions.setSearchResults(undefined));
        }
    };

export const HandleInteractionEnd = (): ThunkAction => async (dispatch, getState) => {
    const state = getState();
    const refreshRequestedDuringInteraction = selectRefreshRequestedDuringInteraction(state);
    const contentVersionChangedDuringInteraction = selectContentVersionChangedDuringInteraction(state);

    if (refreshRequestedDuringInteraction) {
        window.location.reload();
    } else if (contentVersionChangedDuringInteraction !== false) {
        dispatch(reducerActions.setContentVersionChangedDuringInteraction(false));
        dispatch(deviceState.handleNewDeviceContentVersion(contentVersionChangedDuringInteraction));
    }

    dispatch(reducerActions.resetAllInteractivity());
};

export const ejectProducts =
    (articleCode: string, quantity: number, articleTitle: Domain.LocalizedValue, afterRequest?: () => void): ThunkAction =>
    async (dispatch, getState) => {
        const state = getState();
        const device = selectDevice(state);
        const queue = selectEjectQueue(state);

        if (!device) {
            return;
        }

        const jobId = Math.max(0, ...queue.map(job => job.jobId)) + 1;
        dispatch(
            reducerActions.queueEjectJob({
                jobId,
                quantity,
                articleCode,
                articleTitle,
                status: 'in-progress',
            }),
        );

        const removeJob = () => {
            setTimeout(() => {
                dispatch(reducerActions.removeEjectJob(jobId));
            }, 3000);
        };

        let deliveryResponse: Promise<DeviceInterface.IDeliveryResponse>;
        if (!device.configuration.general?.simulateProductDelivery) {
            deliveryResponse = meditechDeviceApi.Deliver({
                Articles: [
                    {
                        articleCode,
                        quantity,
                    },
                ],
            });
        } else {
            deliveryResponse = new Promise(resolve => {
                setTimeout(() => {
                    console.info('SIMULATING DELIVERY OF PRODUCT WITH CODE', articleCode);
                    let success = true;
                    if (quantity > 5) {
                        console.info('QUANTITY TO DELIVER > 5, SIMULATING FAILED DELIVERY REQUEST');
                        success = false;
                    }

                    resolve({
                        Success: success,
                        Status: 'xxx',
                        Proof: [],
                        Log: [],
                    });
                }, 3000);
            });
        }

        deliveryResponse
            .then(deliveryResponse => {
                if (afterRequest) {
                    afterRequest();
                }

                if (deliveryResponse.Success) {
                    dispatch(
                        reducerActions.setEjectJobStatus({
                            jobId,
                            status: 'completed',
                        }),
                    );
                    removeJob();
                } else {
                    dispatch(
                        reducerActions.setEjectJobStatus({
                            jobId,
                            status: 'failed',
                        }),
                    );
                    removeJob();
                }
            })
            .catch(e => {
                dispatch(
                    reducerActions.setEjectJobStatus({
                        jobId,
                        status: 'failed',
                    }),
                );
                removeJob();

                throw e;
            });

        setTimeout(() => {
            dispatch(reducerActions.removeEjectJob(jobId));
        }, 20000);
    };
