import { Branch } from './Branch';
import { Company } from './Company';
import { LocalizedValue } from './Localisation';
import { Pagination } from './Pagination';
import { Sorting } from './Sorting';
import { User } from './User';
import { Webshop } from './Webshop';

export interface WebshopPromotionCoupon {
    couponCode: string;
    usages: number;
}

export type PromotionType = 'productSelection' | 'cart';

export const PROMOTION_RULE_TYPES_WITH_OPTIONS = ['category', 'brand', 'product'] as const;

export type PromotionRuleWithOptionsList = {
    type: (typeof PROMOTION_RULE_TYPES_WITH_OPTIONS)[number];
    condition: 'in' | 'not_in';
    values: string[];
};

export const PROMOTION_RULE_TYPES_WITH_TOGGLE = ['in_local_stock'] as const;
export type PromotionRuleWithToggle = {
    type: (typeof PROMOTION_RULE_TYPES_WITH_TOGGLE)[number];
    condition: 'is' | 'is_not';
    values: string[];
};
export const PROMOTION_RULE_TYPES_WITH_NUMBER_CONDITIONS = [
    'equal',
    'not_equal',
    'lower_than_or_equal',
    'greater_than_or_equal',
    'lower_than',
    'greater_than',
] as const;
export const PROMOTION_RULE_TYPES_WITH_NUMBER = ['cart_total_items_qty', 'cart_subtotal_incl_vat', 'cart_subtotal_excl_vat'] as const;
export type PromotionRuleWithNumber = {
    type: (typeof PROMOTION_RULE_TYPES_WITH_NUMBER)[number];
    condition: (typeof PROMOTION_RULE_TYPES_WITH_NUMBER_CONDITIONS)[number];
    values: string[];
};
export type PromotionRule = PromotionRuleWithOptionsList | PromotionRuleWithToggle | PromotionRuleWithNumber;

export type PromotionRuleWithOptionsListType = PromotionRuleWithOptionsList['type'];
export type PromotionRuleWithToggleType = PromotionRuleWithToggle['type'];
export type PromotionRuleWithNumberType = PromotionRuleWithNumber['type'];
export type PromotionRuleType = PromotionRule['type'];

export function promotionRuleTypeIsWithOptions(type: PromotionRuleType): type is PromotionRuleWithOptionsListType {
    return PROMOTION_RULE_TYPES_WITH_OPTIONS.indexOf(type as PromotionRuleWithOptionsListType) > -1;
}

export function promotionRuleTypeIsWithToggle(type: PromotionRuleType): type is PromotionRuleWithToggleType {
    return PROMOTION_RULE_TYPES_WITH_TOGGLE.indexOf(type as PromotionRuleWithToggleType) > -1;
}
export function promotionRuleTypeIsWithNumber(type: PromotionRuleType): type is PromotionRuleWithNumberType {
    return PROMOTION_RULE_TYPES_WITH_NUMBER.indexOf(type as PromotionRuleWithNumberType) > -1;
}

export interface BaseWebshopPromotion {
    promotionId: string;
    promotionType: PromotionType;
    webshopId: Webshop['webshopId'];
    ruleName: string;
    localizedLabel: LocalizedValue;
    promotionOffer: 'product_percentage_off' | 'order_amount_off';
    promotionValue: number;
    campaignName?: string | null;
    maxUsagesPerCoupon?: number | null;
    maxUsagesPerCustomer?: number | null;
    from?: string | null;
    till?: string | null;
    activeStatus: 'active' | 'inactive';
    rules?: PromotionRule[] | null;
    discardSubsequentRules?: boolean | null;
}

export type WebshopPromotion = BaseWebshopPromotion & {
    createdOn: string;
    updatedOn?: string | null;
    authorUserId: User['userId'];
    removedAt?: string | null;
    priority: number;
};

export type WebshopPromotionDetails = WebshopPromotion & {
    coupons: WebshopPromotionCoupon[];
    companyId: Company['companyId'];
    branchId: Branch['branchId'];
    priority: number;
};

export interface WebshopPromotionsPage {
    items: WebshopPromotion[];
    total: number;
    pagination: Pagination;
    sorting?: Sorting<keyof WebshopPromotion> | null;
    search?: string | null;
}

export type CreateWebshopPromotionPayload = BaseWebshopPromotion & {
    coupons: Omit<WebshopPromotionCoupon, 'usages'>[];
};
export type UpdateWebshopPromotionPayload = BaseWebshopPromotion & {
    coupons: Omit<WebshopPromotionCoupon, 'usages'>[];
};

export interface UpdateWebshopPromotionPrioritiesPayload {
    webshopId: Webshop['webshopId'];
    promotionIds: BaseWebshopPromotion['promotionId'][];
}
