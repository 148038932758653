import * as React from 'react';

import classNames from 'classnames';

import { BaseProps } from '@/core';

import styles from './Icon.scss';

export default class IconGroup extends React.PureComponent<BaseProps> {
    render() {
        const { className, children, ...rest } = this.props;

        return (
            <div
                {...rest}
                className={classNames(styles.IconGroup, className)}
            >
                {children}
            </div>
        );
    }
}
