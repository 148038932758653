import * as React from 'react';

import { Canvas } from '../types';
import { ActionTypes } from './actions';
import { reducer, initialState } from './reducer';

export interface State {
    canvas: Canvas;
    initialState: string;
    undoStack: string[];
    redoStack: string[];
    draggedBlockType?: string;
    draggedBlockPosition: {
        x: number;
        y: number;
        width: number;
        height: number;
    };
    draggedBlockDroppedFlag: boolean;
    resizedFrameId?: string;
    editedFrameId?: string;
}

export interface Context {
    state: State;
    dispatch: React.Dispatch<ActionTypes>;
}

export const store = React.createContext<Context>({
    state: initialState,
    dispatch: (() => undefined) as React.Dispatch<ActionTypes>,
});

const { Provider } = store;

export const BaseProvider = Provider;

export const StateProvider = (props: { children: React.ReactNode; canvas?: Canvas; onChange?: (canvas: Canvas) => void }) => {
    const [initialized, setInitialized] = React.useState(false);
    const [state, dispatch] = React.useReducer(reducer, initialState);

    React.useEffect(() => {
        if (!props.canvas) {
            return;
        }

        dispatch({
            type: 'SET_CANVAS',
            canvas: props.canvas,
        });

        setInitialized(true);
    }, [props.canvas]);

    React.useEffect(() => {
        if (initialized && props.onChange) {
            props.onChange(state.canvas);
        }
    }, [state.canvas]);

    return <Provider value={{ state, dispatch }}>{props.children}</Provider>;
};
