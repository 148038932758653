import * as React from 'react';

import { useThunkDispatch, useGettext, TVScreen } from 'data-store';
import { Icon, Container, Row, Col } from 'ui-components';

import Button from './Button';

import styles from './InteractiveScreenHeader.scss';

export default function InteractiveScreenHeader() {
    const dispatch = useThunkDispatch();
    const { gettext } = useGettext();

    return (
        <Container className={styles.InteractiveScreenHeader}>
            <Row>
                <Col hAlign="start">
                    <Button
                        variant="tertiary"
                        variantSize="xl"
                        onClick={() => dispatch(TVScreen.interactionState.reducerActions.goBackInHistory())}
                        startIcon={<Icon type="action_left" />}
                    >
                        {gettext('Back')}
                    </Button>
                </Col>

                <Col hAlign="end">
                    <Button
                        variant="tertiary"
                        variantSize="xl"
                        onClick={() => dispatch(TVScreen.interactionState.reducerActions.clearHistory())}
                        endIcon={<Icon type="action_remove" />}
                    >
                        {gettext('Close')}
                    </Button>
                </Col>
            </Row>
        </Container>
    );
}
