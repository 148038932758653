import { Domain } from 'api';

import { billableServiceApi } from '@/api';
import { makeActions, makeReducer, makeReducerActions, makeSelectors } from '@/makeDetailsState';

export const selectors = makeSelectors<Domain.BillableService>({
    getState: rootState => rootState.billableService.update,
});

export const reducerActions = makeReducerActions<Domain.BillableService>({
    reducerPrefix: '@billableService/update',
});

export const actions = makeActions<Domain.BillableService>({
    loadApi: options => billableServiceApi.GetBillableServiceDetails(options.urlParams.billableServiceId),
    reducerActions,
    selectors,
});

export const updateReducer = makeReducer<Domain.BillableService>({
    reducerActions,
});
