import {
    Pagination,
    Sorting,
    ApiKeysPage,
    CreateApiKeyPayload,
    UpdateApiKeyPayload,
    DeactivateApiKeyPayload,
    ApiKeyDetails,
} from '@/Domain';

import Endpoint from './Endpoint';

export default class ApiKeyEndpoint extends Endpoint {
    public async GetApiKeys(
        pagination: Pagination,
        sorting: Sorting,
        filters?: {
            companyId?: string;
            branchId?: string;
        },
    ): Promise<ApiKeysPage> {
        const response = await this.client.get({
            url: '/api-key/overview',
            params: {
                pagination,
                sorting,
                ...filters,
            },
        });

        this.guardResponseSchema('#/definitions/ApiKeysPage', response);

        return response;
    }

    public async GetApiKeyDetails(apiKeyId: string): Promise<ApiKeyDetails> {
        const response = await this.client.get({
            url: `/api-key/${apiKeyId}/details`,
        });

        this.guardResponseSchema('#/definitions/ApiKeyDetails', response);

        return response;
    }

    public async CreateApiKey(payload: CreateApiKeyPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/CreateApiKey',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Company\\Branch\\ApiKey\\CreateApiKey\\CreateApiKey',
                payload,
            },
        });
    }

    public async UpdateApiKey(payload: UpdateApiKeyPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/UpdateApiKey',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Company\\Branch\\ApiKey\\UpdateApiKey\\UpdateApiKey',
                payload,
            },
        });
    }

    public async DeactivateApiKey(payload: DeactivateApiKeyPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/DeactivateApiKey',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Company\\Branch\\ApiKey\\DeactivateApiKey\\DeactivateApiKey',
                payload,
            },
        });
    }
}
