import { Domain } from 'api';

import { webshopApi } from '@/api';
import { makeActions, makeReducer, makeReducerActions, makeSelectors } from '@/makeOverviewState';

type UnassignedWebshopProps = keyof Domain.UnassignedWebshop;

export const defaultSorting: Domain.Sorting<UnassignedWebshopProps> = {
    field: 'name',
    direction: 'ascending',
};

export const pageSize = 20;

export const selectors = makeSelectors<Domain.UnassignedWebshop, UnassignedWebshopProps>({
    getState: rootState => rootState.unassignedWebshop.overview,
});

export const reducerActions = makeReducerActions<Domain.UnassignedWebshop, UnassignedWebshopProps>({
    reducerPrefix: '@unassignedWebshop/overview',
});

export const actions = makeActions<Domain.UnassignedWebshop, UnassignedWebshopProps>({
    dataTableSaveKey: 'unassignedWebshopsOverview-v5',
    loadApi: options => webshopApi.GetUnassignedWebshops(options.pagination, options.sorting, options.search, options.filters),
    defaultSorting,
    pageSize,
    getBaseUrl: () => '/webshops/unassigned/overview',
    reducerActions,
    selectors,
});

export const overviewReducer = makeReducer<Domain.UnassignedWebshop, UnassignedWebshopProps>({
    defaultSorting,
    pageSize,
    reducerActions,
});
