import { CanvasFrame } from '../types';

export default function getMaxFrameDepth(frames: CanvasFrame[]): number {
    let max = -1;

    for (const frame of frames) {
        max = Math.max(max, frame.depth || 0);
    }

    return max;
}
