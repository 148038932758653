import { Domain } from 'api';

import { webshopNewsArticleApi } from '@/api';
import { makeActions, makeReducer, makeReducerActions, makeSelectors } from '@/makeOverviewState';

type WebshopNewsArticleProps = keyof Domain.WebshopNewsArticle;

export const defaultSorting: Domain.Sorting<WebshopNewsArticleProps> = {
    field: 'createdOn',
    direction: 'descending',
};

export const pageSize = 20;

export const selectors = makeSelectors<Domain.WebshopNewsArticle, WebshopNewsArticleProps>({
    getState: rootState => rootState.webshopNewsArticle.overview,
});

export const reducerActions = makeReducerActions<Domain.WebshopNewsArticle, WebshopNewsArticleProps>({
    reducerPrefix: '@webshopNewsArticle/overview',
});

export const actions = makeActions<Domain.WebshopNewsArticle, WebshopNewsArticleProps>({
    dataTableSaveKey: 'webshopNewsArticlesOverview-v5',
    loadApi: options => {
        if (options.globallySelectedWebshopId === undefined) {
            return;
        }

        return webshopNewsArticleApi.GetWebshopNewsArticles(
            options.pagination,
            options.sorting,
            options.globallySelectedWebshopId,
            options.globallySelectedWebshopLocale || options.globallySelectedWebshopDefaultLocale || 'nl_BE',
            options.search,
            options.filters,
        );
    },
    defaultSorting,
    pageSize,
    getBaseUrl: () => '/webshops/news-articles',
    reducerActions,
    selectors,
});

export const overviewReducer = makeReducer<Domain.WebshopNewsArticle, WebshopNewsArticleProps>({
    defaultSorting,
    pageSize,
    reducerActions,
});
