import * as React from 'react';

import { Domain } from 'api';
import { BaseProvider as CanvasProvider } from 'editor-canvas';

import { PageLayout, ProductImageSizes } from '../types';
import { ActionTypes } from './actions';
import { reducer, initialState } from './reducer';

export interface EyedropperChangeHandlers {
    changeStart: () => void;
    change: (r: number, g: number, b: number) => void;
    changeComplete: (r: number, g: number, b: number) => void;
}

export interface State {
    canvas: PageLayout;
    screenResolution: Domain.DeviceScreenResolution;
    availableProducts: Domain.SlideshowProduct[];
    productImageSizes: ProductImageSizes;
    initialState: string;
    undoStack: string[];
    redoStack: string[];
    draggedBlockType?: string;
    draggedBlockPosition: {
        x: number;
        y: number;
        width: number;
        height: number;
    };
    draggedBlockDroppedFlag: boolean;
    resizedFrameId?: string;
    editedFrameId?: string;
    settingsMenuVisible: boolean;
    inPreviewMode: boolean;
    eyedropperEnabled: boolean;
    eyedropperChangeHandlers?: EyedropperChangeHandlers;
    eyedropperToolFrameInfo: {
        frameId?: string;
        isDown: boolean;
        x: number;
        y: number;
        w: number;
        h: number;
    };
}

export interface Context {
    state: State;
    dispatch: React.Dispatch<ActionTypes>;
}

export const store = React.createContext<Context>({
    state: initialState,
    dispatch: (() => undefined) as React.Dispatch<ActionTypes>,
});

const { Provider } = store;

export type { PageLayout } from '../types';

export const StateProvider = (props: {
    children: React.ReactNode;
    screenResolution: Domain.DeviceScreenResolution;
    layout?: PageLayout;
    availableProducts?: Domain.SlideshowProduct[];
    onLayoutChange?: (layout: PageLayout) => void;
}) => {
    const [initialized, setInitialized] = React.useState(false);
    const [state, dispatch] = React.useReducer(reducer, {
        ...initialState,
        screenResolution: props.screenResolution,
    });

    React.useEffect(() => {
        if (!props.availableProducts) {
            return;
        }

        dispatch({
            type: 'SET_AVAILABLE_PRODUCTS',
            availableProducts: props.availableProducts,
        });
    }, [props.availableProducts]);

    React.useEffect(() => {
        if (!props.layout) {
            return;
        }

        dispatch({
            type: 'SET_CANVAS',
            canvas: props.layout,
        });
    }, [props.layout]);

    React.useEffect(() => {
        if (!props.layout || !props.availableProducts) {
            return;
        }

        const timeout = setTimeout(() => {
            setInitialized(true);
        }, 250);

        return () => {
            clearTimeout(timeout);
        };
    }, [props.layout, props.availableProducts]);

    React.useEffect(() => {
        if (initialized && props.onLayoutChange) {
            props.onLayoutChange(state.canvas);
        }
    }, [state.canvas]);

    if (!initialized) {
        return null;
    }

    return (
        <Provider value={{ state, dispatch }}>
            <CanvasProvider value={{ state, dispatch }}>{props.children}</CanvasProvider>
        </Provider>
    );
};

const presetColorsCache: string[] = [];
export function getPresetColors(state: State): string[] {
    const addColor = (color: string | undefined) => {
        if (color && presetColorsCache.indexOf(color.toLowerCase()) === -1) {
            presetColorsCache.push(color.toLowerCase());
        }
    };

    addColor(state.canvas.priceStyle.text.color);
    addColor(state.canvas.priceStyle.borderColor);
    addColor(state.canvas.priceStyle.backgroundColor);
    addColor(state.canvas.promoPriceStyle.text.color);
    addColor(state.canvas.promoPriceStyle.promoText.color);
    addColor(state.canvas.promoPriceStyle.borderColor);
    addColor(state.canvas.promoPriceStyle.backgroundColor);

    for (const frame of state.canvas.frames) {
        if (frame.type === 'text') {
            addColor(frame.textStyle.color);

            if (frame.backgroundType === 'color') {
                addColor(frame.backgroundColor);
            }
        } else if (frame.type === 'richText') {
            const colorMatches = frame.html.match(/color: (#.{1,6})/g);
            if (colorMatches) {
                for (const match of colorMatches) {
                    addColor(match.replace('color: ', ''));
                }
            }

            if (frame.backgroundType === 'color') {
                addColor(frame.backgroundColor);
            }
        } else if (frame.type === 'product') {
            if (frame.placedProduct.customPriceStyle) {
                addColor(frame.placedProduct.customPriceStyle.text.color);
                addColor(frame.placedProduct.customPriceStyle.borderColor);
                addColor(frame.placedProduct.customPriceStyle.backgroundColor);
            }

            if (frame.placedProduct.customPromoPriceStyle) {
                addColor(frame.placedProduct.customPromoPriceStyle.text.color);
                addColor(frame.placedProduct.customPromoPriceStyle.promoText.color);
                addColor(frame.placedProduct.customPromoPriceStyle.borderColor);
                addColor(frame.placedProduct.customPromoPriceStyle.backgroundColor);
            }
        } else if (frame.type === 'background') {
            if (frame.backgroundType === 'color') {
                addColor(frame.backgroundColor);
            }
        }
    }

    return presetColorsCache;
}
