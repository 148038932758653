import { Pagination, Organisation, OrganisationsPage, Locale } from '@/Domain';

import Endpoint from './Endpoint';

export default class OrganisationEndpoint extends Endpoint {
    public async GetOrganisations(
        pagination: Pagination,
        locale: Locale,
        search?: string,
        filters?: {
            organisationIds?: string;
        },
    ): Promise<OrganisationsPage> {
        const response = await this.client.get({
            url: '/organisation/overview',
            params: {
                pagination,
                locale,
                search,
                filters: filters
                    ? {
                          organisationIds: filters.organisationIds ? filters.organisationIds.split(',') : undefined,
                      }
                    : undefined,
            },
        });

        this.guardResponseSchema('#/definitions/OrganisationsPage', response);

        return response;
    }

    public async GetOrganisationDetails(organisationId: Organisation['organisationId']): Promise<Organisation> {
        const response = await this.client.get({
            url: `/organisation/${organisationId}/details`,
        });

        this.guardResponseSchema('#/definitions/Organisation', response);

        return response;
    }
}
