import { fonts } from 'config';

const LOADED_FAMILIES: string[] = [];
const LOADED_LINKS: string[] = [];

export function preloadFont(family?: string): Promise<void> {
    if (family) {
        const font = fonts.find(font => font.family.indexOf(family) > -1);

        if (font) {
            if (LOADED_FAMILIES.indexOf(font.family) === -1) {
                LOADED_FAMILIES.push(font.family);

                return new Promise((resolve, reject) => {
                    if (!font.sourceUrl) {
                        resolve();
                        return;
                    }

                    if (LOADED_LINKS.indexOf(font.sourceUrl) === -1) {
                        LOADED_LINKS.push(font.sourceUrl);

                        const link = document.createElement('link');
                        link.href = font.sourceUrl;
                        link.type = 'text/css';
                        link.rel = 'stylesheet';
                        link.media = 'screen,print';

                        link.onload = () => {
                            resolve();
                        };

                        link.onerror = () => {
                            reject(new Error(`Could not load font family "${family}"`));
                        };

                        document.getElementsByTagName('head')[0].appendChild(link);
                    } else {
                        resolve();
                    }
                });
            }
        }
    }

    return Promise.resolve();
}
