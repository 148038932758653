import * as React from 'react';
import { useLocation } from 'react-router-dom';

export type Location = ReturnType<typeof useLocation>;
export type LocationChangeEventListener = (newLocation: Location, oldLocation?: Location) => void;
export type LocationChangeCompleteEventListener = (location: Location) => void;

export interface AppCoreContext {
    handleLocationChange: LocationChangeEventListener;
    handleLocationChangeComplete: LocationChangeCompleteEventListener;
}

export const AppCoreContext = React.createContext<AppCoreContext>({
    handleLocationChange: () => {
        // not implemented here
    },
    handleLocationChangeComplete: () => {
        // not implemented here
    },
});
