import * as React from 'react';

import { Domain } from 'api';

import { store, Actions } from '../state';
import { ShelfWithMeta, PlacedProduct } from '../types';
import { findPlacedProduct, findPlacedProductShelf } from '../utils';

import styles from './styles/PlacedProductDropArea.scss';

export default function PlacedProductDropArea(props: { shelf: ShelfWithMeta; area: PlacedProduct; product: Domain.SlideshowProduct }) {
    const { state, dispatch } = React.useContext(store);
    const { shelf, area } = props;
    const dropAreaRef = React.useRef<HTMLDivElement>(null);

    let isDraggingOver = false;

    if (state.draggedProduct) {
        const target = document.elementFromPoint(state.draggedProductPosition.x, state.draggedProductPosition.y);

        if (target && dropAreaRef.current && dropAreaRef.current === target) {
            isDraggingOver = true;
        }

        if (isDraggingOver) {
            if (state.draggedProductDroppedFlag) {
                if (state.draggedPlacedProductId) {
                    const draggedProduct = findPlacedProduct(state.canvas.shelves, state.draggedPlacedProductId);
                    const draggedProductShelf = findPlacedProductShelf(state.canvas.shelves, state.draggedPlacedProductId);
                    const dropTargetPlacedProduct = findPlacedProduct(state.canvas.shelves, area.id);
                    if (draggedProduct && dropTargetPlacedProduct && draggedProductShelf) {
                        dispatch({
                            type: Actions.SET_DRAGGED_PRODUCT_DROPPED_FLAG,
                            dropped: false,
                        });

                        dispatch({
                            type: Actions.CanvasActions.HISTORY_SAVE,
                        });

                        dispatch({
                            type: Actions.REMOVE_PRODUCT_FROM_SHELF,
                            shelfId: shelf.shelf.id,
                            placedProductId: area.id,
                        });

                        dispatch({
                            type: Actions.REMOVE_PRODUCT_FROM_SHELF,
                            shelfId: draggedProductShelf.id,
                            placedProductId: state.draggedPlacedProductId,
                        });

                        dispatch({
                            type: Actions.ADD_PRODUCT_TO_SHELF,
                            product: state.draggedProduct,
                            shelfId: shelf.shelf.id,
                            area,
                            ...draggedProduct,
                            itemsLimit: draggedProduct.enableItemsLimit ? draggedProduct.itemsLimit : undefined,
                        });

                        dispatch({
                            type: Actions.ADD_PRODUCT_TO_SHELF,
                            product: props.product,
                            shelfId: draggedProductShelf.id,
                            area: {
                                x: draggedProduct.x,
                                width: draggedProduct.width,
                                height: draggedProduct.height,
                            },
                            ...dropTargetPlacedProduct,
                            itemsLimit: dropTargetPlacedProduct.enableItemsLimit ? dropTargetPlacedProduct.itemsLimit : undefined,
                        });
                    }
                }
            }
        }
    }

    return (
        <div
            ref={dropAreaRef}
            className={styles.PlacedProductDropArea}
        ></div>
    );
}
