import * as React from 'react';

import classNames from 'classnames';

import { BaseProps } from '@/core';

import styles from './Container.scss';

type NativeProps = Omit<React.HTMLProps<HTMLDivElement>, 'ref' | 'css'>;
type IProps = NativeProps &
    BaseProps & {
        fullWidth?: boolean;
        limitedWidth?: boolean;
        stickToLeft?: boolean;
        gutter?: false | 14 | 16 | 19 | 24 | 30;
        forwardRef?: React.Ref<HTMLDivElement>;
    };

class Container extends React.PureComponent<IProps> {
    static defaultProps = {
        fullWidth: false,
        limitedWidth: false,
        gutter: false,
    };

    render() {
        const { className, children, fullWidth, stickToLeft, limitedWidth, gutter, forwardRef, ...rest } = this.props;

        return (
            <div
                {...rest}
                ref={forwardRef}
                className={classNames(
                    styles.container,
                    fullWidth ? 'full-width' : null,
                    limitedWidth ? 'limited-width' : null,
                    stickToLeft ? styles.stickToLeft : null,
                    gutter ? 'gutter-' + gutter : null,
                    className,
                )}
            >
                {children}
            </div>
        );
    }
}

export default React.forwardRef<HTMLDivElement, IProps>((props, ref) => {
    return (
        <Container
            forwardRef={ref}
            {...props}
        />
    );
});
