import { createAction, createReducer, Selector } from '@reduxjs/toolkit';

import { Domain } from 'api';

import { ThunkAction, withPayloadType } from '@/action';
import { webshopApi } from '@/api';
import { URLParams } from '@/routing';
import { RootState } from '@/store';

export interface State {
    webshop?: Domain.UnassignedWebshop;
}

const initialState: State = {};

const reducerActions = {
    setWebshop: createAction('@unassignedWebshop/assign/setWebshop', withPayloadType<Domain.UnassignedWebshop>()),
};

export const assignReducer = createReducer(initialState, builder =>
    builder.addCase(reducerActions.setWebshop, (state, action) => {
        state.webshop = action.payload;
    }),
);

export const selectWebshop: Selector<RootState, Domain.UnassignedWebshop> = state => {
    const webshop = state.unassignedWebshop.assign.webshop;
    if (!webshop) {
        throw new Error('Webshop details not loaded');
    }

    return webshop;
};

export const loadWebshop =
    (options: { urlParams: URLParams }): ThunkAction =>
    async dispatch => {
        const webshop = await webshopApi.GetUnassignedWebshopDetails(options.urlParams.webshopId);
        await dispatch(reducerActions.setWebshop(webshop));
    };
