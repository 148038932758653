import { Pagination } from './Pagination';
import { Sorting } from './Sorting';

export const WebhookTypes = [
    'orderWasAccepted',
    'orderStatusWasUpdated',
    'reservationWasCreated',
    'reservationStatusWasUpdated',
    'shipmentStatusWasUpdated',
] as const;
export type WebhookType = (typeof WebhookTypes)[number];

export interface Webhook {
    webhookId: string;
    companyId: string;
    branchId: string;
    branchName?: string;
    companyName?: string;
    webhookType: WebhookType;
    url: string;
    secret?: string | null;
    createdOn: string;
    updatedOn: string;
}

export interface WebhooksPage {
    items: Webhook[];
    total: number;
    pagination: Pagination;
    sorting?: Sorting<keyof Webhook> | null;
    search?: string | null;
}

export interface RemoveWebhookPayload {
    webhookId: string;
}

export interface CreateWebhookPayload {
    webhookId: string;
    companyId: string;
    branchId: string;
    webhookType: WebhookType;
    url: string;
    secret?: string;
}

export type UpdateWebhookPayload = CreateWebhookPayload;
