import { LocalizedValue } from './Localisation';
import { Pagination } from './Pagination';
import { Sorting } from './Sorting';

export const WebshopAnnouncementTypes = ['banner', 'popup'] as const;
export type WebshopAnnouncementType = (typeof WebshopAnnouncementTypes)[number];

export const WebshopAnnouncementLinkTypes = ['product', 'category', 'page', 'custom'] as const;
export type WebshopAnnouncementLinkType = (typeof WebshopAnnouncementLinkTypes)[number];

export const WebshopAnnouncementBannerLocations = ['top_of_page', 'bottom_of_page', 'product_page', 'category_page'] as const;
export type WebshopAnnouncementBannerLocation = (typeof WebshopAnnouncementBannerLocations)[number];
export interface WebshopAnnouncementBannerLocationDetails {
    bannerLocation?: WebshopAnnouncementBannerLocation;
    categoryIds?: string[];
    productIds?: string[];
}
export type WebshopAnnouncementLink = {
    message?: LocalizedValue | null;
    announcementLinkType: WebshopAnnouncementLinkType;
} & (
    | {
          announcementLinkType: 'product';
          productId: string | null;
      }
    | {
          announcementLinkType: 'category';
          categoryId: string | null;
      }
    | {
          announcementLinkType: 'page';
          localizedPageIds: LocalizedValue | null;
      }
    | {
          announcementLinkType: 'custom';
          url: string | null;
      }
);

export interface WebshopAnnouncement {
    announcementId: string;
    webshopId: string;
    activeStatus: 'active' | 'inactive';
    name: string;
    dateTimeRange: {
        from: string;
        till: string;
    };
    announcementType: WebshopAnnouncementType;
    createdOn?: string | null;
    updatedOn?: string | null;
}

export type WebshopAnnouncementDetails = WebshopAnnouncement & {
    description: LocalizedValue;
    includeNewsletterSubscription: boolean;
    includeCountdown: boolean;
    announcementLink?: WebshopAnnouncementLink | null;
    bannerLocation?: WebshopAnnouncementBannerLocation | null;
    mediaItemId?: string | null;
    announcementBannerLocation?: WebshopAnnouncementBannerLocationDetails | null;
};

export interface WebshopAnnouncementsPage {
    items: WebshopAnnouncement[];
    total: number;
    pagination: Pagination;
    sorting?: Sorting<keyof WebshopAnnouncement> | null;
    search?: string | null;
}

export type UpdateWebshopAnnouncementPayload = Omit<WebshopAnnouncementDetails, 'createdOn' | 'updatedOn'>;
export type CreateWebshopAnnouncementPayload = Omit<WebshopAnnouncementDetails, 'createdOn' | 'updatedOn'>;

export interface RemoveWebshopAnnouncementPayload {
    announcementId: string;
    webshopId: string;
}
