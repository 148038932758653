import { Pagination, Sorting, WebshopPrescriptionsPage, WebshopPrescription } from '@/Domain';

import Endpoint from './Endpoint';

export default class WebshopPrescriptionEndpoint extends Endpoint {
    public async GetWebshopPrescriptions(
        pagination: Pagination,
        sorting: Sorting,
        webshopId: string,
        filters?: {
            name?: string;
            phoneNumber?: string;
            emailAddress?: string;
            barcode?: string;
        },
        search?: string,
    ): Promise<WebshopPrescriptionsPage> {
        const url = `/prescription/overview/webshop/${webshopId}`;

        const response = await this.client.get({
            url,
            params: {
                pagination,
                sorting,
                filters,
                search,
            },
        });

        this.guardResponseSchema('#/definitions/WebshopPrescriptionsPage', response);

        return response;
    }

    public async GetWebshopPrescriptionDetails(prescriptionId: string): Promise<WebshopPrescription> {
        const response = await this.client.get({
            url: `/prescription/details/${prescriptionId}`,
        });

        this.guardResponseSchema('#/definitions/WebshopPrescription', response);

        return response;
    }

    public async GetWebshopPrescriptionByBarcode(webshopId: string, barcode: string): Promise<WebshopPrescription> {
        const url = `/prescription/overview/webshop/${webshopId}`;

        const response: WebshopPrescriptionsPage = await this.client.get({
            url,
            params: {
                pagination: { page: 1, size: 1 },
                sorting: { field: 'createdOn', direction: 'ascending' },
                filters: {
                    barcode,
                },
                search: '',
            },
        });

        this.guardResponseSchema('#/definitions/WebshopPrescriptionsPage', response);

        for (const prescription of response.items) {
            if (prescription.barcode === barcode) {
                return prescription;
            }
        }

        throw new Error('Prescription with barcode ' + barcode + ' not found');
    }

    public async RemoveWebshopPrescription(prescriptionId: string): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/RemoveWebshopPrescription',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Webshop\\Prescription\\RemovePrescription\\RemovePrescription',
                payload: {
                    prescriptionId,
                },
            },
        });
    }

    public GetPrescriptionAttachmentURL(prescriptionId: string): string {
        return this.client.buildApiURL(`/prescription/${prescriptionId}/attachment`, true);
    }

    public async NotifyPatientOfPrescriptionReadyToPickUp(prescriptionId: string, comment: string): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/NotifyPatientOfPrescriptionReadyToPickUp',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Webshop\\Prescription\\NotifyPatient\\NotifyPatient',
                payload: {
                    prescriptionId,
                    comment: {
                        nl_BE: comment,
                    },
                },
            },
        });
    }
}
