import { combineReducers } from '@reduxjs/toolkit';

import { Domain } from 'api';

import { ThunkAction } from '@/action';
import { companyApi, branchApi } from '@/api';
import { BranchProps, defaultSorting as branchesDefaultSorting, pageSize as branchesPageSize } from '@/Branch/overviewState';
import { loadLocalisations } from '@/Localisation/overviewState';
import { makeActions, makeReducer, makeReducerActions, makeSelectors } from '@/makeDetailsState';
import * as makeOverviewState from '@/makeOverviewState';
import { URLParams, URLQuery } from '@/routing';

export const selectors = makeSelectors<Domain.CompanyDetails>({
    getState: rootState => rootState.company.update.base,
});

export const reducerActions = makeReducerActions<Domain.CompanyDetails>({
    reducerPrefix: '@company/update',
});

const baseActions = makeActions<Domain.CompanyDetails>({
    loadApi: options =>
        companyApi.GetCompanyDetails(options.ownership.type === 'company' ? options.ownership.ownerId : options.urlParams.companyId),
    reducerActions,
    selectors,
});

export const actions = {
    ...baseActions,
    load:
        (options: { urlParams: URLParams }): ThunkAction =>
        async dispatch => {
            await Promise.all([dispatch(baseActions.load(options)), dispatch(loadLocalisations())]);
        },
    loadLandingPage:
        (options: { urlQuery: URLQuery; urlParams: URLParams }): ThunkAction =>
        async dispatch => {
            await Promise.all([dispatch(baseActions.load(options)), dispatch(branchesActions.load(options))]);
        },
};

export const branchesSelectors = makeOverviewState.makeSelectors<Domain.Branch, BranchProps>({
    getState: rootState => rootState.company.details.branches,
});

export const branchesReducerActions = makeOverviewState.makeReducerActions<Domain.Branch, BranchProps>({
    reducerPrefix: '@companyDetails/branches/overview',
});

export const branchesActions = makeOverviewState.makeActions<Domain.Branch, BranchProps>({
    dataTableSaveKey: 'companyDetailsBranchesOverview-v5',
    loadApi: options =>
        branchApi.GetBranches(
            {
                type: 'company',
                ownerId: options.ownership.type === 'company' ? options.ownership.ownerId : options.urlParams.companyId,
            },
            {
                page: 1,
                size: 999,
            },
            options.sorting,
        ),
    defaultSorting: branchesDefaultSorting,
    pageSize: branchesPageSize,
    reducerActions: branchesReducerActions,
    selectors: branchesSelectors,
});

export const baseReducer = makeReducer<Domain.CompanyDetails>({
    reducerActions,
});

export const branchesOverviewReducer = makeOverviewState.makeReducer<Domain.Branch, BranchProps>({
    defaultSorting: branchesDefaultSorting,
    pageSize: branchesPageSize,
    reducerActions: branchesReducerActions,
});

export const updateReducer = combineReducers({
    base: baseReducer,
    branches: branchesOverviewReducer,
});
