import { createAction, Selector, createReducer } from '@reduxjs/toolkit';

import { Domain } from 'api';

import { ThunkAction, withPayloadType } from '@/action';
import { webshopApi, webshopAnnouncementApi } from '@/api';
import { categoriesTreeState } from '@/AvailableProduct';
import { URLParams } from '@/routing';
import { RootState } from '@/store';

export interface State {
    webshop?: Domain.Webshop;
    announcement?: Domain.WebshopAnnouncementDetails;
}

const initialState: State = {};

const reducerActions = {
    setWebshop: createAction('@webshopAnnouncement/update/setWebshop', withPayloadType<Domain.Webshop>()),
    setAnnouncement: createAction('@webshopAnnouncement/update/setAnnouncement', withPayloadType<Domain.WebshopAnnouncementDetails>()),
};

export const updateReducer = createReducer(initialState, builder =>
    builder
        .addCase(reducerActions.setWebshop, (state, action) => {
            state.webshop = action.payload;
        })
        .addCase(reducerActions.setAnnouncement, (state, action) => {
            state.announcement = action.payload;
        }),
);

export const selectWebshop: Selector<RootState, Domain.Webshop> = state => {
    const webshop = state.webshopAnnouncement.update.webshop;
    if (!webshop) {
        throw new Error('Webshop not loaded');
    }

    return webshop;
};

export const selectAnnouncement: Selector<RootState, Domain.WebshopAnnouncementDetails> = state => {
    const announcement = state.webshopAnnouncement.update.announcement;
    if (!announcement) {
        throw new Error('Announcement not loaded');
    }

    return announcement;
};

export const loadWebshop =
    (webshopId: string): ThunkAction<Promise<Domain.Webshop>> =>
    async dispatch => {
        const webshop = await webshopApi.GetWebshopDetails(webshopId);

        await dispatch(reducerActions.setWebshop(webshop));

        return webshop;
    };

export const loadAnnouncement =
    (webshopId: string, announcementId: string): ThunkAction<Promise<Domain.WebshopAnnouncementDetails>> =>
    async dispatch => {
        const announcement = await webshopAnnouncementApi.GetAnnouncementDetails(webshopId, announcementId);

        await dispatch(reducerActions.setAnnouncement(announcement));

        return announcement;
    };

export const load =
    (options: { urlParams: URLParams }): ThunkAction =>
    async dispatch => {
        await Promise.all([
            dispatch(loadWebshop(options.urlParams.webshopId)),
            dispatch(loadAnnouncement(options.urlParams.webshopId, options.urlParams.announcementId)),
            dispatch(categoriesTreeState.actions.load(options)),
        ]);
    };
