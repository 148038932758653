import * as React from 'react';

import classNames from 'classnames';

import { RTView, RTValue } from 'ui-components';
import { formatPrice } from 'utils';

import { Actions, store } from '../state';
import { ShelfWithMeta, ShelfStyleParameters } from '../types';
import { computeShelfMoveMaxY, computeShelfMoveMinY, findPlacedProductShelf, getSelectedImage } from '../utils';
import ClearShelfAction from './ClearShelfAction';
import FrameEyedropperArea from './FrameEyedropperArea';
import PlacedProductActions from './PlacedProductActions';
import PlacedProductDropArea from './PlacedProductDropArea';
import PlacedProductResizer from './PlacedProductResizer';
import ProductsShelfDropAreas from './ProductsShelfDropAreas';
import ShelfMover from './ShelfMover';

import styles from './styles/Canvas.scss';

export default function ProductsShelfBody({
    shelf,
    onTopOfPreviewImage,
    nextShelf,
    hideMoverHandle,
    disableEditing,
    locale,
    proxyImageURL,
    displayStock,
    onMouseDown,
    onMouseUp,
    onMouseMove,
    onMouseOut,
}: {
    shelf: ShelfWithMeta;
    onTopOfPreviewImage?: boolean;
    locale: string;
    proxyImageURL: (url: string) => string;
    prevShelf?: ShelfWithMeta;
    nextShelf?: ShelfWithMeta;
    hideMoverHandle?: boolean;
    disableEditing?: boolean;
    displayStock?: boolean;
    onMouseDown?: (placedProductId: number) => void;
    onMouseUp?: (placedProductId: number) => void;
    onMouseMove?: (placedProductId: number, x: number, y: number, w: number, h: number) => void;
    onMouseOut?: () => void;
}) {
    const { state, dispatch } = React.useContext(store);

    if (!shelf.meta) {
        throw new Error('Shelf meta must be present.');
    }

    const minXList = shelf
        .meta!.placedProducts.map(item => item.area.x + item.area.width)
        .concat([0])
        .sort((a, b) => a - b);
    const maxXList = shelf
        .meta!.placedProducts.map(item => item.area.x)
        .concat([state.canvas.width])
        .sort((a, b) => a - b);

    const LayoutParams = ShelfStyleParameters[state.canvas.style];

    return (
        <>
            {!onTopOfPreviewImage ? (
                <>
                    <div className={styles.ProductsShelf}>
                        {!disableEditing ? (
                            <ProductsShelfDropAreas
                                shelf={shelf}
                                placedProducts={shelf.meta.placedProducts.map(item => item.area)}
                            />
                        ) : null}

                        {shelf.meta.placedProducts
                            .sort((a, b) => a.area.x - b.area.x)
                            .map((item, index) => {
                                const resizedProductShelf = findPlacedProductShelf(
                                    state.canvas.shelves,
                                    state.resizedProductId ? state.resizedProductId : -1,
                                );
                                const productIsFocused =
                                    state.resizedProductId &&
                                    resizedProductShelf &&
                                    state.resizedProductId === item.area.id &&
                                    resizedProductShelf.id === shelf.shelf.id;

                                return (
                                    <div
                                        key={item.area.id + '-' + index}
                                        className={classNames(
                                            styles.PlacedProduct,
                                            item.boxes.length === 0 ? styles.PlacedProductNoBoxesVisible : undefined,
                                            productIsFocused ? styles.PlacedProductFocused : undefined,
                                        )}
                                        style={{
                                            left: item.area.x + 'px',
                                            width: item.area.width + 'px',
                                            height: item.area.height + 'px',
                                        }}
                                        onClick={event => {
                                            event.preventDefault();

                                            dispatch({
                                                type: Actions.SHOW_PLACED_PRODUCT_SETTINGS_DIALOG,
                                                placedProductId: item.area.id,
                                            });

                                            if (state.generalSettingsVisible) {
                                                dispatch({
                                                    type: Actions.HIDE_GENERAL_SETTINGS,
                                                });
                                            }
                                        }}
                                    >
                                        {state.draggedProduct && item.area.id !== state.draggedPlacedProductId ? (
                                            <PlacedProductDropArea
                                                shelf={shelf}
                                                area={item.area}
                                                product={item.product}
                                            />
                                        ) : null}

                                        {!disableEditing ? (
                                            <PlacedProductActions
                                                shelf={shelf}
                                                placedProduct={item.area}
                                                product={item.product}
                                            />
                                        ) : null}

                                        {!disableEditing ? (
                                            <PlacedProductResizer
                                                shelf={shelf}
                                                placedProduct={item.area}
                                                product={item.product}
                                                bottomOffset={LayoutParams.shelfBottomOffset}
                                                minX={minXList[index] || 0}
                                                maxX={maxXList[index + 1] || state.canvas.width}
                                            />
                                        ) : null}

                                        <>
                                            {item.boxes.map(box => {
                                                const selectedImage = getSelectedImage(item.area, item.product);

                                                return (
                                                    <React.Fragment key={box.key}>
                                                        <img
                                                            src={proxyImageURL(selectedImage.url)}
                                                            style={{
                                                                height: box.height + 'px',
                                                                width: box.width + 'px',
                                                                left: box.left + 'px',
                                                                bottom: box.bottom + 'px',
                                                            }}
                                                            alt=""
                                                        />

                                                        {state.eyedropperEnabled ? (
                                                            <FrameEyedropperArea
                                                                onMouseDown={() => (onMouseDown ? onMouseDown(item.area.id) : undefined)}
                                                                onMouseUp={() => (onMouseUp ? onMouseUp(item.area.id) : undefined)}
                                                                onMouseMove={(x, y, w, h) =>
                                                                    onMouseMove ? onMouseMove(item.area.id, x, y, w, h) : undefined
                                                                }
                                                                onMouseOut={onMouseOut}
                                                                style={{
                                                                    height: box.height + 'px',
                                                                    width: box.width + 'px',
                                                                    left: box.left + 'px',
                                                                    bottom: box.bottom + 'px',
                                                                }}
                                                            />
                                                        ) : null}
                                                    </React.Fragment>
                                                );
                                            })}
                                        </>
                                    </div>
                                );
                            })}

                        <span
                            className={styles.ProductsShelfBase}
                            style={{
                                height: LayoutParams.shelfBaseHeight,
                            }}
                        />
                    </div>

                    <ShelfMover
                        style={{
                            height: LayoutParams.shelfBaseHeight,
                        }}
                        shelf={shelf.shelf}
                        belowShelf={nextShelf ? nextShelf.shelf : undefined}
                        y={shelf.shelf.y + shelf.shelf.height}
                        minY={computeShelfMoveMinY(state.canvas, shelf)}
                        maxY={computeShelfMoveMaxY(state.canvas, nextShelf)}
                        hideMoverHandle={hideMoverHandle}
                        otherActions={
                            nextShelf ? (
                                <>
                                    <ClearShelfAction
                                        shelfId={shelf.shelf.id}
                                        shelfHeight={shelf.shelf.height}
                                        nextShelfId={nextShelf.shelf.id}
                                    />
                                </>
                            ) : null
                        }
                    />
                </>
            ) : null}

            {shelf.meta.placedProducts
                .sort((a, b) => a.area.x - b.area.x)
                .map((item, index) => {
                    if (!item.area.showPrice) {
                        return null;
                    }

                    let priceStyle = state.canvas.priceStyle;
                    let promoPriceStyle = state.canvas.promoPriceStyle;

                    if (item.area.enableCustomPriceStyling && item.area.customPriceStyle) {
                        priceStyle = item.area.customPriceStyle;
                    }

                    if (item.area.enableCustomPriceStyling && item.area.customPromoPriceStyle) {
                        promoPriceStyle = item.area.customPromoPriceStyle;
                    }

                    return (
                        <div
                            key={item.area.id + '-' + index + '-price'}
                            className={styles.PlacedProductPriceWrap}
                            style={{
                                left: item.area.x + 'px',
                                width: item.area.width + 'px',
                                height: item.area.height + 'px',
                            }}
                        >
                            {item.product.promoPrice !== undefined ? (
                                <div
                                    className={classNames(
                                        styles.PlacedProductPrice,
                                        styles.PlacedProductPromoPrice,
                                        item.area.hideOriginalPrice ? styles.PlacedProductPromoPriceOnly : undefined,
                                        promoPriceStyle.shape,
                                    )}
                                    style={{
                                        backgroundColor: promoPriceStyle.backgroundColor,
                                        borderColor: promoPriceStyle.borderColor,
                                    }}
                                >
                                    {!item.area.hideOriginalPrice && item.product.price !== undefined ? (
                                        <RTView
                                            value={new RTValue('', promoPriceStyle.text)}
                                            className={styles.PlacedProductOldPrice}
                                        >
                                            {formatPrice(item.product.price.amount / 100, locale, item.product.price.currency.name)}
                                        </RTView>
                                    ) : null}

                                    <RTView value={new RTValue('', promoPriceStyle.promoText)}>
                                        {formatPrice(item.product.promoPrice.amount / 100, locale, item.product.promoPrice.currency.name)}
                                    </RTView>
                                </div>
                            ) : item.product.price !== undefined ? (
                                <div
                                    className={classNames(styles.PlacedProductPrice, priceStyle.shape)}
                                    style={{
                                        backgroundColor: priceStyle.backgroundColor,
                                        borderColor: priceStyle.borderColor,
                                    }}
                                >
                                    <RTView value={new RTValue('', priceStyle.text)}>
                                        {formatPrice(item.product.price.amount / 100, locale, item.product.price.currency.name)}
                                    </RTView>
                                </div>
                            ) : null}
                        </div>
                    );
                })}

            {displayStock
                ? shelf.meta.placedProducts
                      .sort((a, b) => a.area.x - b.area.x)
                      .map((item, index) => {
                          const stock = state.stock[item.product.productId];
                          if (stock === undefined) {
                              return null;
                          }

                          const stockStyle = stock === 0 ? state.canvas.outOfStockStyle : state.canvas.inStockStyle;

                          return (
                              <div
                                  key={item.area.id + '-' + index + '-stock'}
                                  className={styles.PlacedProductStockWrap}
                                  style={{
                                      left: item.area.x + 'px',
                                      width: item.area.width + 'px',
                                      height: item.area.height + 'px',
                                  }}
                              >
                                  <div
                                      className={styles.PlacedProductStock}
                                      style={{
                                          backgroundColor: stockStyle.backgroundColor,
                                          borderColor: stockStyle.borderColor,
                                      }}
                                  >
                                      <RTView value={new RTValue('', stockStyle.text)}>
                                          <span>&nbsp;{stock}&nbsp;</span>
                                      </RTView>
                                  </div>
                              </div>
                          );
                      })
                : null}
        </>
    );
}
