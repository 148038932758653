import * as React from 'react';

import Color from './Color';
import ControlledColorPicker, { ControlledColorPickerProps } from './ControlledColorPicker';

export type UncontrolledColorPickerProps = ControlledColorPickerProps & {
    defaultValue?: string;
};

interface IState {
    color: null | Color;
}

class UncontrolledColorPicker extends React.PureComponent<UncontrolledColorPickerProps, IState> {
    constructor(props: UncontrolledColorPickerProps) {
        super(props);

        this.state = {
            color: props.defaultValue ? new Color(props.defaultValue) : null,
        };
    }

    render() {
        const { forwardRef, onChange, onChangeComplete, ...rest } = this.props;

        return (
            <ControlledColorPicker
                {...rest}
                ref={forwardRef}
                value={this.state.color}
                onChange={this.handleChange}
                onChangeComplete={this.handleChangeComplete}
            />
        );
    }

    protected handleChange = (newColorHex?: string, newColor?: Color): void => {
        const { onChange } = this.props;

        this.setState(
            {
                color: newColor || null,
            },
            () => {
                if (onChange) {
                    onChange(newColorHex, newColor);
                }
            },
        );
    };

    protected handleChangeComplete = (newColorHex?: string, newColor?: Color): void => {
        const { onChangeComplete } = this.props;

        this.setState(
            {
                color: newColor || null,
            },
            () => {
                if (onChangeComplete) {
                    onChangeComplete(newColorHex, newColor);
                }
            },
        );
    };
}

export default React.forwardRef<HTMLInputElement, UncontrolledColorPickerProps>((props, ref) => {
    return (
        <UncontrolledColorPicker
            forwardRef={ref}
            {...props}
        />
    );
});
