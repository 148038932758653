import {
    Wwks2StatusDialog,
    wwks2HelloResponse
} from '../../../../Domain';
import Wwks2CommClient from '../../Wwks2CommClient';
import { DIALOG_TIMEOUTS } from '../Wwks2DefaultValues';


export default class Wwks2Hello {

    private Wwks2CommClient: Wwks2CommClient;

    public constructor(Wwks2CommClient: Wwks2CommClient) {
        this.Wwks2CommClient = Wwks2CommClient;
    }

    public sendHelloRequest(dialogId: string | number | boolean = false): Promise<Wwks2StatusDialog> {
        return new Promise(resolve => {

            if(typeof dialogId === 'boolean'){
                dialogId = this.Wwks2CommClient.GetWwks2DialogId();
            }

            const activeCapabilities = this.Wwks2CommClient.GetOwnCompatibilities().map((Compatibility: string) => {
                return {
                    '@': {
                        'Name': Compatibility
                    }
                }
            });
            const wwks = {
                'HelloRequest': {
                    '@': {
                        'Id': dialogId
                    },
                    '#': {
                        'Subscriber': {
                            '@': {
                                'Id': this.Wwks2CommClient.GetSourceId(),
                                'Type': this.Wwks2CommClient.GetType(),
                                'Manufacturer': this.Wwks2CommClient.GetManufacturer(),
                                'ProductInfo': this.Wwks2CommClient.GetProductInfo(),
                                'VersionInfo': this.Wwks2CommClient.GetVersionInfo()
                            },
                            '#': {
                                'Capability': activeCapabilities
                            }
                        }
                    }
                }
            }
            this.Wwks2CommClient.createAndSendDialog(wwks, 'Hello', dialogId).then((status: Wwks2StatusDialog) => {
                resolve(status);
            });
        });
    }

    public sendHelloResponse(dialogId: string | number): Promise<Wwks2StatusDialog> {
        return new Promise(resolve => {
            const activeCapabilities = this.Wwks2CommClient.GetOwnCompatibilities().map((Compatibility: string) => {
                return {
                    '@': {
                        'Name': Compatibility
                    }
                }
            });

            const wwks = {
                'HelloResponse': {
                    '@': {
                        'Id': dialogId
                    },
                    '#': {
                        'Subscriber': {
                            '@': {
                                'Id': this.Wwks2CommClient.GetSourceId(),
                                'Type': this.Wwks2CommClient.GetType(),
                                'Manufacturer': this.Wwks2CommClient.GetManufacturer(),
                                'ProductInfo': this.Wwks2CommClient.GetProductInfo(),
                                'VersionInfo': this.Wwks2CommClient.GetVersionInfo()
                            },
                            '#': {
                                'Capability': activeCapabilities
                            }
                        }
                    }
                }
            }
            this.Wwks2CommClient.createAndSendDialog(wwks, 'Hello', dialogId, 'response').then((status: Wwks2StatusDialog) => {
                resolve(status);
            });
        })
    }

    public getHelloResponse(dialogId: string | number): Promise<Wwks2StatusDialog> {

        return new Promise((resolve) => {

            const statusDialog: Wwks2StatusDialog = {
                status: false,
                msg: '',
                dialogId: dialogId,
                dialogType: 'Hello',
                isDialogType: 'response',
                canceled: false,
                errorType: 'none'
            }

            let processStatusDialog = this.Wwks2CommClient.GetProcessedDialogData(dialogId);

            if (typeof processStatusDialog !== 'boolean') {
                if (processStatusDialog.typeDialog === 'Hello') {
                    if (processStatusDialog.status.response) {
                        const responseData = processStatusDialog.data.response as wwks2HelloResponse;

                        this.Wwks2CommClient.SetClientId(responseData.clientId);
                        this.Wwks2CommClient.SetClientCompatibilities(responseData.capabilities);

                        statusDialog.status = true;
                        statusDialog.msg = 'Hello Response received and processed';
                        resolve(statusDialog);
                    } else if (processStatusDialog.status.cancel) {
                        statusDialog.status = false;
                        statusDialog.msg = 'canceled for statusDialog (key = \'status_' + dialogId + '\')';
                        statusDialog.canceled = true;
                        statusDialog.errorType = 'checkStatusDialogCanceled';
                        resolve(statusDialog);
                    } else {
                        setTimeout(() => {
                            resolve(this.getHelloResponse(dialogId));
                        }, 500)
                    }
                } else {
                    statusDialog.status = false;
                    statusDialog.msg = 'statusDialog (key = \'status_' + dialogId + '\') is no Hello type';
                    statusDialog.errorType = 'wrongStatusDialogType';
                    resolve(statusDialog);
                }
            } else {

                statusDialog.status = false;
                statusDialog.msg = 'key \'status_' + dialogId + '\' not exist in statusDialog';
                statusDialog.errorType = 'noStatusDialog';
                resolve(statusDialog);
            }
        });
    }

    public sendAndProcessHelloRequest(dialogId: string | number | boolean = false): Promise<Wwks2StatusDialog> {
        return new Promise(resolve => {
            this.sendHelloRequest(dialogId)
                .then((data: Wwks2StatusDialog) => {
                    if (data.status) {
                        const timeoutTimer = setTimeout(() => {
                            let processStatusDialog = this.Wwks2CommClient.GetProcessedDialogData(data.dialogId);
                            if (typeof processStatusDialog !== 'boolean') {
                                if (processStatusDialog.typeDialog === 'Hello') {
                                    processStatusDialog.status.cancel = true;
                                    this.Wwks2CommClient.SetProcessedDialogData(data.dialogId, processStatusDialog);
                                }
                            }
                        }, DIALOG_TIMEOUTS.Hello);
                        this.getHelloResponse(data.dialogId)
                            .then((status) => {
                                clearTimeout(timeoutTimer);
                                resolve(status)
                            });
                    } else {
                        resolve(data);
                    }
                });
        })
    }

    public handleMessage(WWKS: any) {

        if (typeof WWKS.HelloRequest !== 'undefined') {
            const responseId = WWKS.HelloRequest['@attributes'].Id;
            if (typeof WWKS.HelloRequest.Subscriber !== 'undefined') {

                const clientId = WWKS.HelloRequest.Subscriber['@attributes'].Id;
                const clientCompatibilities: string[] = ['Hello']; // always supported

                if (typeof WWKS.HelloRequest.Subscriber.Capability !== 'undefined') {
                    const _capabilities = WWKS.HelloRequest.Subscriber.Capability;
                    if (Array.isArray(_capabilities)) {
                        _capabilities.forEach((capability) => {
                            if (typeof capability['@attributes'] !== 'undefined') {
                                if (typeof capability['@attributes'].Name !== 'undefined') {
                                    clientCompatibilities.push(capability['@attributes'].Name);
                                }
                            }
                        })
                    } else {
                        if (typeof _capabilities['@attributes'] !== 'undefined') {
                            if (typeof _capabilities['@attributes'].Name !== 'undefined') {
                                clientCompatibilities.push(_capabilities['@attributes'].Name);
                            }
                        }
                    }
                }

                this.sendHelloResponse(responseId).then((status) => {
                    if (status.status) {
                        this.Wwks2CommClient.SetClientId(clientId);
                        this.Wwks2CommClient.SetClientCompatibilities(clientCompatibilities);
                    }
                });
            }
        }
        if (typeof WWKS.HelloResponse !== 'undefined') {

            const responseId = WWKS.HelloResponse['@attributes'].Id;
            let processStatusDialog = this.Wwks2CommClient.GetProcessedDialogData(responseId);
            if (typeof processStatusDialog !== 'boolean') {

                const responseData: wwks2HelloResponse = {
                    'clientId': 0,
                    'capabilities': ['Hello'],
                }

                if (typeof WWKS.HelloResponse.Subscriber !== 'undefined') {
                    responseData.clientId = WWKS.HelloResponse.Subscriber['@attributes'].Id;
                    this.Wwks2CommClient.SetClientCompatibilities(['Hello']);

                    if (typeof WWKS.HelloResponse.Subscriber.Capability !== 'undefined') {
                        const _capabilities = WWKS.HelloResponse.Subscriber.Capability;
                        if (Array.isArray(_capabilities)) {
                            _capabilities.forEach((capability) => {
                                if (typeof capability['@attributes'] !== 'undefined') {
                                    if (typeof capability['@attributes'].Name !== 'undefined') {
                                        responseData.capabilities.push(capability['@attributes'].Name);
                                    }
                                }
                            })
                        } else {
                            if (typeof _capabilities['@attributes'] !== 'undefined') {
                                if (typeof _capabilities['@attributes'].Name !== 'undefined') {
                                    responseData.capabilities.push(_capabilities['@attributes'].Name);
                                }
                            }
                        }
                    }
                }

                if (processStatusDialog.typeDialog === 'Hello') {
                    processStatusDialog.status.response = true;
                    processStatusDialog.timestamps.response = Date.now();
                    processStatusDialog.data.response = responseData;
                    this.Wwks2CommClient.SetProcessedDialogData(responseId, processStatusDialog);
                }
            }
        }

    }

}