import { Country, Pagination, ZipCodesPage } from '@/Domain';

import Endpoint from './Endpoint';

export default class ZipCodeEndpoint extends Endpoint {
    public async GetZipCodes(country: Country, pagination: Pagination, search: string): Promise<ZipCodesPage> {
        const response = await this.client.get({
            url: `/available-zip-codes/${country}`,
            params: {
                pagination,
                search,
            },
        });

        this.guardResponseSchema('#/definitions/ZipCodesPage', response);

        return response;
    }
}
