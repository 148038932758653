import {
    UpdateWebshopLocalDeliveryShippingMethodPayload,
    UpdateWebshopLockerShippingMethodPayload,
    UpdateWebshopPickupShippingMethodPayload,
    UpdateWebshopPickupShippingMethodActiveStatusPayload,
    UpdateWebshopLockerShippingMethodActiveStatusPayload,
    UpdateWebshopLocalDeliveryShippingMethodActiveStatusPayload,
    UpdateWebshopServiceDeliveryShippingMethodPayload,
    UpdateWebshopServiceDeliveryShippingMethodActiveStatusPayload,
} from '@/Domain';

import Endpoint from './Endpoint';

export default class WebshopShippingMethodEndpoint extends Endpoint {
    public async UpdateWebshopPickupShippingMethod(payload: UpdateWebshopPickupShippingMethodPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/UpdateWebshopPickupShippingMethod',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Webshop\\UpdatePickupShippingMethod\\UpdatePickupShippingMethod',
                payload,
            },
        });
    }
    public async UpdateWebshopLockerShippingMethod(payload: UpdateWebshopLockerShippingMethodPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/UpdateWebshopLockerShippingMethod',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Webshop\\UpdateLockerShippingMethod\\UpdateLockerShippingMethod',
                payload,
            },
        });
    }

    public async UpdateWebshopLocalDeliveryShippingMethod(payload: UpdateWebshopLocalDeliveryShippingMethodPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/UpdateWebshopLocalDeliveryShippingMethod',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Webshop\\UpdateLocalDeliveryShippingMethod\\UpdateLocalDeliveryShippingMethod',
                payload,
            },
        });
    }

    public async UpdateWebshopServiceDeliveryShippingMethod(payload: UpdateWebshopServiceDeliveryShippingMethodPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/UpdateWebshopServiceDeliveryShippingMethod',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Webshop\\UpdateServiceDeliveryShippingMethod\\UpdateServiceDeliveryShippingMethod',
                payload,
            },
        });
    }

    public async UpdateWebshopPickupShippingMethodActiveStatus(
        payload: UpdateWebshopPickupShippingMethodActiveStatusPayload,
    ): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/UpdateWebshopPickupShippingMethodActiveStatus',
            body: {
                commandName:
                    'App\\Domain\\WriteModel\\Webshop\\UpdatePickupShippingMethodActiveStatus\\UpdatePickupShippingMethodActiveStatus',
                payload,
            },
        });
    }

    public async UpdateWebshopLockerShippingMethodActiveStatus(
        payload: UpdateWebshopLockerShippingMethodActiveStatusPayload,
    ): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/UpdateWebshopLockerShippingMethodActiveStatus',
            body: {
                commandName:
                    'App\\Domain\\WriteModel\\Webshop\\UpdateLockerShippingMethodActiveStatus\\UpdateLockerShippingMethodActiveStatus',
                payload,
            },
        });
    }

    public async UpdateWebshopLocalDeliveryShippingMethodActiveStatus(
        payload: UpdateWebshopLocalDeliveryShippingMethodActiveStatusPayload,
    ): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/UpdateWebshopLocalDeliveryShippingMethodActiveStatus',
            body: {
                commandName:
                    'App\\Domain\\WriteModel\\Webshop\\UpdateLocalDeliveryShippingMethodActiveStatus\\UpdateLocalDeliveryShippingMethodActiveStatus',
                payload,
            },
        });
    }

    public async UpdateWebshopServiceDeliveryShippingMethodActiveStatus(
        payload: UpdateWebshopServiceDeliveryShippingMethodActiveStatusPayload,
    ): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/UpdateWebshopServiceDeliveryShippingMethodActiveStatus',
            body: {
                commandName:
                    'App\\Domain\\WriteModel\\Webshop\\UpdateServiceDeliveryShippingMethodActiveStatus\\UpdateServiceDeliveryShippingMethodActiveStatus',
                payload,
            },
        });
    }
}
