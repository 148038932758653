import { Domain } from 'api';
import { preloadFont, preloadImage as basePreloadImage } from 'preloaders';
import { HTMLEditorValue } from 'utils';

import mapProductToSlideshowProduct from '@/mapProductToSlideshowProduct';

export function preloadProductWall(
    usePreviewImageForContents: boolean,
    content: any,
    products: Domain.SelectedProductDetails[],
    preview: string,
    locale: Domain.Locale,
    preloadMediaItem: (mediaItemId: string | undefined, isVideo: boolean) => Promise<void>,
    preloadVideoMediaItemPreview: (mediaItemId: string | undefined) => Promise<void>,
    preloadImage: (imageSrc: string) => Promise<any>,
): Promise<void>[] {
    let promises: Promise<void>[] = [];
    let doNotPreload = preview && usePreviewImageForContents && content.backgroundType !== 'video';

    for (const frame of content.frames) {
        if (frame.type === 'video') {
            doNotPreload = false;
            break;
        }
    }

    promises.push(preloadFont(content.priceStyle?.text.font));
    promises.push(preloadFont(content.promoPriceStyle?.text.font));
    promises.push(preloadFont(content.promoPriceStyle?.promoText.font));
    promises.push(preloadFont(content.inStockStyle?.text.font));
    promises.push(preloadFont(content.outOfStockStyle?.text.font));

    for (const shelf of content.shelves) {
        if (shelf.type === 'productsShelf') {
            for (const placedProduct of shelf.products) {
                if (placedProduct.customPriceStyle) {
                    promises.push(preloadFont(placedProduct.customPriceStyle.text.font));
                }

                if (placedProduct.customPromoPriceStyle) {
                    promises.push(preloadFont(placedProduct.customPromoPriceStyle.text.font));
                    promises.push(preloadFont(placedProduct.customPromoPriceStyle.promoText.font));
                }
            }
        }
    }

    if (doNotPreload) {
        promises.push(preloadImage(preview));
    } else {
        if (content.style === 'style2D') {
            promises = promises.concat(preloadShelf2D());
        } else if (content.style === 'style3D') {
            promises.push(basePreloadImage('/static/images/shelves-v1/3d/left.png'));
            promises.push(basePreloadImage('/static/images/shelves-v1/3d/right.png'));
            promises.push(basePreloadImage('/static/images/shelves-v1/3d/top.png'));
            promises.push(basePreloadImage('/static/images/shelves-v1/3d/bottom.png'));
            promises.push(basePreloadImage('/static/images/shelves-v1/3d/top-left.png'));
            promises.push(basePreloadImage('/static/images/shelves-v1/3d/top-right.png'));
            promises.push(basePreloadImage('/static/images/shelves-v1/3d/bottom-left.png'));
            promises.push(basePreloadImage('/static/images/shelves-v1/3d/bottom-right.png'));

            promises = promises.concat(preloadShelf3D());
        }

        if (content.backgroundType === 'image') {
            promises.push(preloadMediaItem(content.backgroundMediaItemId, false));
        }

        for (const frame of content.frames) {
            if (frame.type === 'image' && frame.mediaItemId) {
                promises.push(preloadMediaItem(frame.mediaItemId, false));
            } else if (frame.type === 'text') {
                promises.push(preloadFont(frame.textStyle.font));
            } else if (frame.type === 'richText' && frame.html) {
                HTMLEditorValue.mapFromString(frame.html, {
                    handleFontFamily: fontFamily => {
                        promises.push(preloadFont(fontFamily));
                    },
                });
            }
        }

        for (const shelf of content.shelves) {
            if (shelf.type === 'productsShelf') {
                for (const placedProduct of shelf.products) {
                    const selectedProduct = products.find(product => product.productId === placedProduct.productId);
                    if (selectedProduct) {
                        const slideshowProduct = mapProductToSlideshowProduct(selectedProduct, locale);

                        if (placedProduct && placedProduct.mediaItemId) {
                            promises.push(preloadMediaItem(placedProduct.mediaItemId, false));
                        } else if (
                            slideshowProduct &&
                            Domain.slideshowProductHasFrontalImage(slideshowProduct) &&
                            slideshowProduct.allFrontals
                        ) {
                            const productImage = slideshowProduct.allFrontals.find(image => {
                                if (placedProduct.imageId) {
                                    return image.id === placedProduct.imageId;
                                } else {
                                    return image.url === slideshowProduct.frontalImage;
                                }
                            });

                            if (productImage && productImage.url) {
                                promises.push(preloadImage(productImage.url));
                            }
                        }
                    }
                }
            }
        }

        if (content.backgroundType === 'video') {
            promises.push(preloadMediaItem(content.backgroundMediaItemId, true));
            promises.push(preloadVideoMediaItemPreview(content.backgroundMediaItemId));
        }

        for (const frame of content.frames) {
            if (frame.type === 'video' && frame.mediaItemId) {
                promises.push(preloadMediaItem(frame.mediaItemId, true));
                promises.push(preloadVideoMediaItemPreview(frame.mediaItemId));
            }
        }
    }

    return promises;
}

const preloadShelf2D = (): Promise<void>[] => {
    return [
        basePreloadImage('/static/images/shelves-v1/2d/shelf-middle.png'),
        basePreloadImage('/static/images/shelves-v1/2d/shelf-left.png'),
        basePreloadImage('/static/images/shelves-v1/2d/shelf-right.png'),
    ];
};

const preloadShelf3D = (): Promise<void>[] => {
    return [
        basePreloadImage('/static/images/shelves-v1/3d/shelf-middle.png'),
        basePreloadImage('/static/images/shelves-v1/3d/shelf-left.png'),
        basePreloadImage('/static/images/shelves-v1/3d/shelf-right.png'),
    ];
};
