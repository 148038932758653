import * as React from 'react';

import ControlledColorPicker, { ControlledColorPickerPropsWithValue } from './ControlledColorPicker';
import UncontrolledColorPicker, { UncontrolledColorPickerProps } from './UncontrolledColorPicker';

export type ColorPickerProps = ControlledColorPickerPropsWithValue | UncontrolledColorPickerProps;

class ColorPicker extends React.PureComponent<ColorPickerProps> {
    render() {
        if (this.isControlled(this.props)) {
            return <ControlledColorPicker {...this.props} />;
        }

        return <UncontrolledColorPicker {...this.props} />;
    }

    private isControlled(props: ColorPickerProps): props is ControlledColorPickerPropsWithValue {
        return (props as ControlledColorPickerPropsWithValue).hasOwnProperty('value');
    }
}

export default React.forwardRef<HTMLInputElement, ColorPickerProps>((props, ref) => {
    return (
        <ColorPicker
            forwardRef={ref}
            {...props}
        />
    );
});
