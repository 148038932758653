import { Domain } from 'api';
import { browserStorage } from 'utils';

export interface SavedSettings {
    search?: string;
    filters?: {
        [key: string]: string | undefined;
    };
    visibleColumns?: string[];
    sortableColumns?: string[];
    sorting?: Domain.Sorting;
    viewMode?: Domain.ViewMode;
}

export const getDataTableSavedSettings = (saveSettingsAs: string): SavedSettings | undefined => {
    const savedSettingsData = browserStorage.getItem<SavedSettings>(getDataTableSavedSettingsKey(saveSettingsAs));

    if (savedSettingsData) {
        return savedSettingsData.value;
    }

    return undefined;
};

export const setDataTableSavedSettings = (saveSettingsAs: string, settings: SavedSettings): void => {
    browserStorage.setItem(getDataTableSavedSettingsKey(saveSettingsAs), settings);
};

const getDataTableSavedSettingsKey = (saveSettingsAs: string): string => {
    return 'DataTable-' + saveSettingsAs;
};
