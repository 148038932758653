import * as React from 'react';

import { Domain } from 'api';

export default function getDeviceViewStyle(device?: Domain.Device): React.CSSProperties {
    const style: React.CSSProperties = {};
    if (device) {
        const parts = device.screenResolution.split('x');
        style.width = parseInt(parts[0]);
        style.height = parseInt(parts[1]);
    }

    return style;
}
