import { IPlcMessage } from '../../Domain/IPlcMessage';

export default class PlcMessageResolver {

    private readonly _plcMessages: Array<IPlcMessage> = new Array<IPlcMessage>();

    public Resolve(code: string): IPlcMessage {

        const plcMessage =
            this._plcMessages.find(pm => code === pm.Code);

        if (undefined === plcMessage) {
            return {
                Code: code,
                Severity: 3,
                Description: 'Unknown message-code'
            }
        }

        return plcMessage;
    }

    constructor() {

        this._plcMessages.push({
            Code: '1',
            Severity: 1,
            Description: 'Emergency circuit interrupted, push the blue button to start'
        });

        this._plcMessages.push({
            Code: '2',
            Severity: 1,
            Description: 'Unlock the E-stop button 1'
        });

        this._plcMessages.push({
            Code: '10',
            Severity: 2,
            Description: 'Fuse actuators interrupted'
        });

        this._plcMessages.push({
            Code: '11',
            Severity: 2,
            Description: 'Pre-alarm UPS battery discharged'
        });

        this._plcMessages.push({
            Code: '12',
            Severity: 2,
            Description: 'DC power supply interruption detected'
        });

        this._plcMessages.push({
            Code: '21',
            Severity: 2,
            Description: 'Lock error: Door lock is not reacting'
        });

        this._plcMessages.push({
            Code: '22',
            Severity: 2,
            Description: 'Lock error: Switch lock is not reacting'
        });

        this._plcMessages.push({
            Code: '23',
            Severity: 2,
            Description: 'Lock error: Front lock 1 is not reacting'
        });

        this._plcMessages.push({
            Code: '24',
            Severity: 2,
            Description: 'Lock error: Front lock 2 is not reacting'
        });

        this._plcMessages.push({
            Code: '50',
            Severity: 2,
            Description: 'CV-axis error (see NC error code for details)'
        });

        this._plcMessages.push({
            Code: '51',
            Severity: 2,
            Description: 'CV-axis blocked'
        });

        this._plcMessages.push({
            Code: '52',
            Severity: 2,
            Description: 'CV-axis error while executing MC-functionblock'
        });

        this._plcMessages.push({
            Code: '52',
            Severity: 2,
            Description: 'CV-axis error while executing MC-functionblock'
        });

        this._plcMessages.push({
            Code: '70',
            Severity: 2,
            Description: 'DOOR-axis error (se NC error code for details)'
        });

        this._plcMessages.push({
            Code: '71',
            Severity: 2,
            Description: 'DOOR-axis blocked'
        });

        this._plcMessages.push({
            Code: '72',
            Severity: 2,
            Description: 'DOOR-axis error while executing MC-functionblock'
        });

        this._plcMessages.push({
            Code: '75',
            Severity: 2,
            Description: 'DOOR-axis blocked after multiple retries'
        });

        this._plcMessages.push({
            Code: '90',
            Severity: 2,
            Description: 'SWITCH-axis error (see NC error code for details)'
        });

        this._plcMessages.push({
            Code: '91',
            Severity: 2,
            Description: 'SWITCH-axis blocked'
        });

        this._plcMessages.push({
            Code: '92',
            Severity: 2,
            Description: 'SWITCH-axis error while executing MC-functionblock'
        });

        this._plcMessages.push({
            Code: '100',
            Severity: 2,
            Description: 'Homing sensor indoor hatch not detected timeOut'
        });

        this._plcMessages.push({
            Code: '110',
            Severity: 2,
            Description: 'VENTILO-axis error (see NC error code for details)'
        });

        this._plcMessages.push({
            Code: '111',
            Severity: 2,
            Description: 'VENTILO-axis blocked'
        });

        this._plcMessages.push({
            Code: '112',
            Severity: 2,
            Description: 'VENTILO-axis error while executing MC-functionblock'
        });

        this._plcMessages.push({
            Code: '200',
            Severity: 3,
            Description: 'UPS failure'
        });

        this._plcMessages.push({
            Code: '201',
            Severity: 3,
            Description: 'MTMATIC in battery operation'
        });

        this._plcMessages.push({
            Code: '202',
            Severity: 3,
            Description: 'PLC automatic reset initiated'
        });

        this._plcMessages.push({
            Code: '203',
            Severity: 3,
            Description: 'ADS connection to MTXL error (Configured?)'
        });

        this._plcMessages.push({
            Code: '210',
            Severity: 3,
            Description: 'Default axes values loaded'
        });

        this._plcMessages.push({
            Code: '220',
            Severity: 3,
            Description: 'Door open timeout'
        });

        this._plcMessages.push({
            Code: '230',
            Severity: 3,
            Description: 'Homing sensor indoor hatch not detected'
        });

        this._plcMessages.push({
            Code: '500',
            Severity: 4,
            Description: 'GEN: Sleeping'
        });

        this._plcMessages.push({
            Code: '501',
            Severity: 4,
            Description: 'GEN: Reset button pushed'
        });

        this._plcMessages.push({
            Code: '502',
            Severity: 4,
            Description: 'GEN: HOST requests Reset'
        });

        this._plcMessages.push({
            Code: '503',
            Severity: 4,
            Description: 'GEN: HOST requests Start Man'
        });

        this._plcMessages.push({
            Code: '504',
            Severity: 4,
            Description: 'GEN: HOST requests Start Auto'
        });

        this._plcMessages.push({
            Code: '505',
            Severity: 4,
            Description: 'GEN: HOST requests Stop'
        });

        this._plcMessages.push({
            Code: '506',
            Severity: 4,
            Description: 'GEN: HOST request Homing'
        });

        this._plcMessages.push({
            Code: '507',
            Severity: 4,
            Description: 'GEN: Start PLC update'
        });

        this._plcMessages.push({
            Code: '508',
            Severity: 4,
            Description: 'GEN: Job requests disabled during update OR battery operation'
        });

        this._plcMessages.push({
            Code: '520',
            Severity: 4,
            Description: 'Servicedoor open'
        });

        this._plcMessages.push({
            Code: '521',
            Severity: 4,
            Description: 'Outdoor hatch open'
        });

        this._plcMessages.push({
            Code: '522',
            Severity: 4,
            Description: 'Indoor hatch open'
        });

        this._plcMessages.push({
            Code: '526',
            Severity: 4,
            Description: 'Outdoor hatch lock open'
        });

        this._plcMessages.push({
            Code: '527',
            Severity: 4,
            Description: 'Indoor hatch lock open'
        });

        this._plcMessages.push({
            Code: '528',
            Severity: 4,
            Description: 'Servicedoor lock 1 open'
        });

        this._plcMessages.push({
            Code: '529',
            Severity: 4,
            Description: 'Servicedoor lock 2 open'
        });

        this._plcMessages.push({
            Code: '601',
            Severity: 4,
            Description: 'CV-axe moving'
        });

        this._plcMessages.push({
            Code: '602',
            Severity: 4,
            Description: 'DOOR-axe moving'
        });

        this._plcMessages.push({
            Code: '603',
            Severity: 4,
            Description: 'SWITCH-axe moving'
        });

        this._plcMessages.push({
            Code: '604',
            Severity: 4,
            Description: 'VENTILO-axe moving'
        });

        this._plcMessages.push({
            Code: '611',
            Severity: 4,
            Description: 'CV-axe homing'
        });

        this._plcMessages.push({
            Code: '612',
            Severity: 4,
            Description: 'DOOR-axe homing'
        });

        this._plcMessages.push({
            Code: '613',
            Severity: 4,
            Description: 'SWITCH-axe homing'
        });

        this._plcMessages.push({
            Code: '614',
            Severity: 4,
            Description: 'VENTILO-axe homing'
        });

        this._plcMessages.push({
            Code: '621',
            Severity: 4,
            Description: 'CV-axe position unknown (homing necessary)'
        });

        this._plcMessages.push({
            Code: '622',
            Severity: 4,
            Description: 'DOOR-axe position unknown (homing necessary)'
        });

        this._plcMessages.push({
            Code: '623',
            Severity: 4,
            Description: 'SWITCH-axe position unknown (homing necessary)'
        });

        this._plcMessages.push({
            Code: '624',
            Severity: 4,
            Description: 'VENTILO-axe position unknown (homing necessary)'
        });

        this._plcMessages.push({
            Code: '631',
            Severity: 4,
            Description: 'CV-axe is not calibrated'
        });

        this._plcMessages.push({
            Code: '632',
            Severity: 4,
            Description: 'DOOR-axe is not calibrated'
        });

        this._plcMessages.push({
            Code: '633',
            Severity: 4,
            Description: 'SWITCH-axe is not calibrated'
        });

        this._plcMessages.push({
            Code: '634',
            Severity: 4,
            Description: 'VENTILO-axe is not calibrated'
        });

        this._plcMessages.push({
            Code: '900',
            Severity: 5,
            Description: 'Waiting for homing sensor DOOR'
        });

        this._plcMessages.push({
            Code: '901',
            Severity: 5,
            Description: 'Waiting for homing sensor SWITCH'
        });

        this._plcMessages.push({
            Code: '911',
            Severity: 5,
            Description: 'Opening DOOR lock'
        });

        this._plcMessages.push({
            Code: '912',
            Severity: 5,
            Description: 'Opening SWITCH lock'
        });

        this._plcMessages.push({
            Code: '913',
            Severity: 5,
            Description: 'Opening FrontLock 1'
        });

        this._plcMessages.push({
            Code: '914',
            Severity: 5,
            Description: 'Opening FrontLock 2'
        });

        this._plcMessages.push({
            Code: '916',
            Severity: 5,
            Description: 'Closing DOOR lock'
        });

        this._plcMessages.push({
            Code: '917',
            Severity: 5,
            Description: 'Closing SWITCH lock'
        });

        this._plcMessages.push({
            Code: '918',
            Severity: 5,
            Description: 'Closing FrontLock 1'
        });

        this._plcMessages.push({
            Code: '919',
            Severity: 5,
            Description: 'Closing FrontLock 2'
        });

        this._plcMessages.push({
            Code: '930',
            Severity: 5,
            Description: 'Door obstruction detected'
        });
    }

}