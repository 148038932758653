import { Domain } from 'api';
import { preloadImage } from 'preloaders';

import { ThunkAction } from '@/action';
import { customPageApi } from '@/api';
import { SetLocale } from '@/I18n';
import { makeActions, makeReducer, makeReducerActions, makeSelectors } from '@/makeDetailsState';
import * as Media from '@/Media';
import { URLParams } from '@/routing';

import { preloadCustomPage } from './preloader';

export const selectors = makeSelectors<Domain.CustomPage>({
    getState: rootState => rootState.customPage.preview,
});

export const reducerActions = makeReducerActions<Domain.CustomPage>({
    reducerPrefix: '@customPage/preview',
});

const baseActions = makeActions<Domain.CustomPage>({
    loadApi: options => customPageApi.GetCustomPageDetails(options.urlParams.customPageId),
    reducerActions,
    selectors,
});

export const actions = {
    ...baseActions,
    load:
        (options: { urlParams: URLParams }): ThunkAction =>
        async (dispatch, getState) => {
            await dispatch(baseActions.load(options));
            const state = getState();

            const customPage = selectors.selectDetails(state);

            await dispatch(SetLocale(customPage.locale));

            await Promise.all(
                preloadCustomPage(
                    false,
                    customPage.content as any,
                    customPage.products,
                    customPage.preview || '',
                    customPage.locale,
                    () => undefined,
                    () => undefined,
                    Media.preloader.preloadMediaItem,
                    Media.preloader.preloadVideoMediaItemPreview,
                    preloadImage,
                    () => undefined,
                ),
            );
        },
};

export const previewReducer = makeReducer<Domain.CustomPage>({
    reducerActions,
});
