import { IPictureResponse } from 'Domain';
import { EventEmitter } from 'events';

import { IEventEmitter } from './IEventEmitter';

export enum ProofEvent {
    DeliveryInitiated = 'DeliveryInitiated',
    RobotDelivered = 'RobotDelivered',
    ClientConfirmed = 'ClientConfirmed',
    Finalised = 'Finalised',
}

export class ProofEventEmitter
    extends EventEmitter
    implements
        IEventEmitter<{
            DeliveryInitiated: IPictureResponse;
            RobotDelivered: IPictureResponse;
            ClientConfirmed: IPictureResponse;
            Finalised: IPictureResponse;
        }> {}
