import LogStringifier from './LogStringifier';

export type StoredDebugConsoleLogType = 'debug' | 'info' | 'log' | 'error' | 'before-window-unload' | 'fetch-error' | 'uncaught-error';

export interface StoredDebugConsoleLog {
    logIndex: number;
    ts: number;
    type: StoredDebugConsoleLogType;
    data: string[];
}

type NewLogCallbackFn = (log: StoredDebugConsoleLog) => void;

export default class DebugConsole {
    private readonly maxLogs = 100;
    private readonly logStringifier: LogStringifier;
    private readonly originalConsoleDebug: typeof console.debug;
    private readonly originalConsoleInfo: typeof console.info;
    private readonly originalConsoleLog: typeof console.log;
    private readonly originalConsoleError: typeof console.error;
    private readonly originalOnUnhandledRejection: typeof window.onunhandledrejection;
    private readonly originalOnError: typeof window.onerror;
    private readonly originalFetch: typeof window.fetch;
    private storedLogs: StoredDebugConsoleLog[] = [];
    private newLogCallback: NewLogCallbackFn | undefined;
    private logIndex = 0;

    public constructor() {
        this.logStringifier = new LogStringifier();
        this.originalConsoleDebug = console.debug;
        this.originalConsoleInfo = console.info;
        this.originalConsoleLog = console.log;
        this.originalConsoleError = console.error;
        this.originalOnUnhandledRejection = window.onunhandledrejection;
        this.originalOnError = window.onerror;
        this.originalFetch = window.fetch;
    }

    public interceptConsole(): void {
        console.debug = (...data: any[]): void => {
            this.originalConsoleDebug.apply(console, data);
            this.storeLog('debug', data);
        };

        console.info = (...data: any[]): void => {
            this.originalConsoleInfo.apply(console, data);
            this.storeLog('info', data);
        };

        console.log = (...data: any[]): void => {
            this.originalConsoleLog.apply(console, data);
            this.storeLog('log', data);
        };

        console.error = (...data: any[]): void => {
            this.originalConsoleError.apply(console, data);
            this.storeLog('error', data);
        };

        window.addEventListener('beforeunload', this.handleBeforeUnload);

        // this.interceptFetch();
        // this.interceptUncauchtError();
        // this.interceptUnhandledRejection();
    }

    public stopInterceptingConsole(): void {
        window.removeEventListener('beforeunload', this.handleBeforeUnload);

        console.debug = this.originalConsoleDebug;
        console.info = this.originalConsoleInfo;
        console.log = this.originalConsoleLog;
        console.error = this.originalConsoleError;
        window.onunhandledrejection = this.originalOnUnhandledRejection;
        window.onerror = this.originalOnError;
        window.fetch = this.originalFetch;
    }

    public getAllLogs() {
        return this.storedLogs;
    }

    public onNewLog(newLogCallback?: NewLogCallbackFn) {
        this.newLogCallback = newLogCallback;
    }

    public run(command: string) {
        const results = eval?.(`"use strict";(${command})`);
        console.log(results);
    }

    private handleBeforeUnload = () => {
        this.storeLog('before-window-unload', [undefined]);
    };

    private storeLog(type: StoredDebugConsoleLogType, data: any[]): void {
        const log = {
            type,
            ts: Date.now(),
            data: data.map(item => this.logStringifier.stringify(item)),
            logIndex: this.logIndex,
        };

        this.logIndex += 1;

        this.storedLogs.push(log);

        if (this.storedLogs.length > this.maxLogs) {
            this.storedLogs = this.storedLogs.slice(this.storedLogs.length - this.maxLogs);
        }

        if (this.newLogCallback) {
            this.newLogCallback(log);
        }
    }

    private interceptFetch() {
        if (!('fetch' in window)) {
            return;
        }

        window.fetch = (...args: any[]) => {
            const { method, url } = this.parseFetchArgs(args);

            return this.originalFetch.apply(window, args).catch((error: Error) => {
                this.storeLog('fetch-error', [
                    {
                        error,
                        method,
                        url,
                    },
                ]);
                throw error;
            });
        };
    }

    private interceptUncauchtError() {
        window.onerror = (...args: any[]) => {
            if (this.originalOnError) {
                this.originalOnError.apply(window, args);
            }

            this.storeLog('uncaught-error', args);
        };
    }

    private interceptUnhandledRejection() {
        window.onunhandledrejection = (...args: any[]) => {
            if (this.originalOnUnhandledRejection) {
                this.originalOnUnhandledRejection.apply(window, args);
            }

            this.storeLog('uncaught-error', args);
        };
    }

    private hasProp(obj: any, prop: string) {
        return !!obj && typeof obj === 'object' && !!obj[prop];
    }

    private parseFetchArgs(fetchArgs: any[]) {
        if (fetchArgs.length === 0) {
            return { method: 'GET', url: '' };
        }

        if (fetchArgs.length === 2) {
            const [url, options] = fetchArgs;

            return {
                url,
                method: this.hasProp(options, 'method') ? String(options.method).toUpperCase() : 'GET',
            };
        }

        const arg = fetchArgs[0];
        return {
            url: arg,
            method: this.hasProp(arg, 'method') ? String(arg.method).toUpperCase() : 'GET',
        };
    }
}
