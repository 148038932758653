import * as React from 'react';

import { useGettext } from 'data-store';
import { Button, Icon, Card, CardItem, Tx, Flex, FlexItem } from 'ui-components';

import useVideoCall from './useVideoCall';

import styles from './VideoCall.scss';

export function VideoCall() {
    const { gettext } = useGettext();
    const call = useVideoCall();

    if (!call.isEnabled) {
        return null;
    }

    return (
        <>
            <div
                className={styles.VideoCallInfoPanel}
                style={{
                    display: call.panel.isOpened ? 'block' : 'none',
                    top: call.panel.position.top + 'px',
                    right: call.panel.position.right + 'px',
                }}
                onClick={call.panel.handleClickInsidePanel}
            >
                <audio
                    src="/static/matic/phone-ringing.mp3"
                    ref={element => call.setAlertAudioElement(element || undefined)}
                    autoPlay={false}
                    loop={true}
                ></audio>

                {call.context.registrationStatus === 'registration-failing' || call.showAudioPermissionsMessage ? (
                    <Card
                        hSpacing="none"
                        vSpacing="none"
                        elevated={true}
                        className="mt-7"
                    >
                        {call.context.registrationStatus === 'registration-failing' ? (
                            <Tx as="p">
                                <Icon type="message_warning" />
                                {gettext('We are having trouble connecting you to the MT.Matic video call service.')}
                            </Tx>
                        ) : null}

                        {call.showAudioPermissionsMessage ? (
                            <CardItem>
                                <a
                                    href="https://championcr.com/topic/enable-auto-play"
                                    target="_blank"
                                >
                                    {gettext(
                                        'We have detected that your browser is blocking the alert sound. Please click here and follow the guide to enable sounds in your browser.',
                                    )}
                                </a>
                            </CardItem>
                        ) : null}
                    </Card>
                ) : null}
            </div>

            <div className={styles.VideoCallContainer}>
                <Card
                    className={`
                        ${styles.VideoCallControls}
                        ${call.context.callStatus === 'ringing' ? styles.VideoCallControlsVisible : ''}
                        ${call.context.callStatus === 'in-progress' ? styles.VideoCallVisible : ''}
                        ${call.context.pipVideoElement || call.context.pipOnly ? styles.VideoCallWithPIP : ''}
                    `.trim()}
                >
                    <audio
                        ref={element => call.setAudioElement(element || undefined)}
                        autoPlay
                    ></audio>
                    <video
                        ref={element => call.setVideoElement(element || undefined)}
                        autoPlay
                    ></video>

                    {call.context.callStatus === 'ringing' ? (
                        <Flex
                            gap="16px"
                            alignItems="center"
                        >
                            <FlexItem
                                spaceRight
                                style={{
                                    minWidth: '0',
                                }}
                            >
                                <Tx
                                    level="body-xs"
                                    sx={{ color: '--content-tertiary', trim: true }}
                                    as="div"
                                >
                                    <Icon
                                        type="action_vending_machine"
                                        iconSize="xs"
                                        style={{ color: 'inherit' }}
                                        className="mr-4"
                                    />
                                    {gettext('Incoming call from')}
                                </Tx>
                                <Tx
                                    level="body-lg"
                                    sx={{ weight: 'medium' }}
                                    as="div"
                                    className={styles.DeviceName}
                                >
                                    {call.deviceName}
                                </Tx>
                            </FlexItem>

                            <Button
                                variant="success"
                                variantSize="m"
                                rounded
                                onClick={() => call.answerIncomingCall()}
                                className={styles.ShakeButton}
                                startIcon={<Icon type="action_phone" />}
                            />

                            <Button
                                variant="danger"
                                variantSize="m"
                                rounded
                                onClick={() => call.rejectIncomingCall()}
                                startIcon={<Icon type="action_phone_hangup" />}
                            />
                        </Flex>
                    ) : call.context.callStatus === 'in-progress' ? (
                        <Flex
                            gap="16px"
                            alignItems="center"
                        >
                            <FlexItem
                                spaceRight
                                style={{
                                    minWidth: '0',
                                }}
                            >
                                <Tx
                                    level="body-xs"
                                    sx={{ color: '--content-tertiary', trim: true }}
                                    as="div"
                                >
                                    <Icon
                                        type="action_vending_machine"
                                        iconSize="xs"
                                        style={{ color: 'inherit' }}
                                        className="mr-4"
                                    />
                                    {gettext('Calling with')}
                                </Tx>
                                <Tx
                                    level="body-lg"
                                    sx={{ weight: 'medium' }}
                                    as="div"
                                    className={styles.DeviceName}
                                >
                                    {call.deviceName}
                                </Tx>
                            </FlexItem>

                            <Button
                                variant="danger"
                                variantSize="m"
                                rounded
                                onClick={() => call.rejectIncomingCall()}
                                startIcon={<Icon type="action_phone_hangup" />}
                            />

                            {call.context.isMuted ? (
                                <Button
                                    variant="secondary"
                                    variantSize="m"
                                    rounded
                                    onClick={() => call.unmute()}
                                    startIcon={<Icon type="action_play" />}
                                />
                            ) : (
                                <Button
                                    variant="secondary"
                                    variantSize="m"
                                    rounded
                                    onClick={() => call.mute()}
                                    startIcon={<Icon type="action_pause" />}
                                />
                            )}
                        </Flex>
                    ) : null}
                </Card>
            </div>
        </>
    );
}
