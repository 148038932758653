import {
    Wwks2StatusDialog,
    wwks2StockInfoResponse,
    wwks2ArticlePack,
    wwks2Article,
    wwks2StockInfoMessage
} from '../../../../Domain';
import Wwks2CommClient from '../../Wwks2CommClient';
import { DIALOG_TIMEOUTS } from '../Wwks2DefaultValues';


export default class Wwks2StockInfo {

    private Wwks2CommClient: Wwks2CommClient;

    public constructor(Wwks2CommClient: Wwks2CommClient) {
        this.Wwks2CommClient = Wwks2CommClient;
    }

    public onStockChange: (data: wwks2StockInfoMessage) => void;

    public sendStockInfoRequest(barcodes: string[], reservationId: string = '', includePacks: boolean = true, includeArticleDetails: boolean = false, dialogId: string | number | boolean = false): Promise<Wwks2StatusDialog> {
        includePacks;
        includeArticleDetails;
        return new Promise(resolve => {

            if(typeof dialogId === 'boolean'){
                dialogId = this.Wwks2CommClient.GetWwks2DialogId();
            }

            const barcodesMap = barcodes.map((barcode) => {

                if (reservationId == '') {
                    return {
                        '@': {
                            'ArticleId': barcode
                        }
                    }
                } else {
                    return {
                        '@': {
                            'ArticleId': barcode,
                            'ReservationId': reservationId
                        }
                    }
                }

            })

            const wwks = {
                'StockInfoRequest': {
                    '@': {
                        'Id': dialogId,
                        'Source': this.Wwks2CommClient.GetSourceId(),
                        'Destination': this.Wwks2CommClient.GetClientId(),
                        'IncludePacks': includePacks,
                        'IncludeArticleDetails': includeArticleDetails
                    },
                    '#': {
                        'Criteria': barcodesMap
                    }
                }
            }
            this.Wwks2CommClient.createAndSendDialog(wwks, 'StockInfo', dialogId).then(status => {

                let processStatusDialog = this.Wwks2CommClient.GetProcessedDialogData(status.dialogId);

                if (typeof processStatusDialog !== 'boolean') {
                    if (processStatusDialog.typeDialog === 'StockInfo') {
                        processStatusDialog.reservationId = reservationId;
                        this.Wwks2CommClient.SetProcessedDialogData(status.dialogId, processStatusDialog);
                    }
                }
                resolve(status);
            });

        });
    }

    public sendAndProcessStockInfoRequest(barcodes: string[], reservationId: string = '', includePacks: boolean = true, includeArticleDetails: boolean = false, dialogId: string | number | boolean = false): Promise<Wwks2StatusDialog> {
        return new Promise(resolve => {
            this.sendStockInfoRequest(barcodes, reservationId, includePacks, includeArticleDetails, dialogId)
                .then((data: Wwks2StatusDialog) => {
                    if (data.status) {
                        const timeoutTimer = setTimeout(() => {
                            let processStatusDialog = this.Wwks2CommClient.GetProcessedDialogData(data.dialogId);
                            if (typeof processStatusDialog !== 'boolean') {
                                if (processStatusDialog.typeDialog === 'StockInfo') {
                                    processStatusDialog.status.cancel = true;
                                    this.Wwks2CommClient.SetProcessedDialogData(data.dialogId, processStatusDialog);
                                }
                            }

                        }, DIALOG_TIMEOUTS.StockInfo);
                        this.Wwks2CommClient.getResponse(data.dialogId, 'StockInfo')
                            .then((status) => {
                                clearTimeout(timeoutTimer);
                                resolve(status)
                            });
                    } else {
                        resolve(data);
                    }
                });
        });
    }

    public handleMessage(WWKS: any) {

        if (WWKS.StockInfoResponse) {

            const responseId = WWKS.StockInfoResponse['@attributes'].Id;
            let processStatusDialog = this.Wwks2CommClient.GetProcessedDialogData(responseId);
            if (typeof processStatusDialog !== 'boolean') {

                const responseData: wwks2StockInfoResponse = {
                    'clientId': 0,
                    'articles': [],
                    'reservationId': ''
                }

                responseData.clientId = WWKS.StockInfoResponse['@attributes'].Source;

                // wwks2StockInfoArticle
                if (WWKS.StockInfoResponse.Article) {
                    let _articles: any[];

                    if (!Array.isArray(WWKS.StockInfoResponse.Article)) {
                        _articles = [WWKS.StockInfoResponse.Article];
                    } else {
                        _articles = WWKS.StockInfoResponse.Article;
                    }
                    _articles.forEach((article) => {
                        if (typeof article['@attributes'] !== 'undefined') {
                            if (typeof article['@attributes'].Id !== 'undefined' && typeof article['@attributes'].Quantity !== 'undefined') {
                                const articlePacks: wwks2ArticlePack[] = [];
                                const availableArticlePacks: wwks2ArticlePack[] = [];
                                const notAvailableArticlePacks: wwks2ArticlePack[] = [];
                                if (typeof article.Pack !== 'undefined') {

                                    let _articlePacks: any[];
                                    if (!Array.isArray(article.Pack)) {
                                        _articlePacks = [article.Pack];
                                    } else {
                                        _articlePacks = article.Pack;
                                    }

                                    _articlePacks.forEach(articlePack => {
                                        if (typeof articlePack['@attributes'].Id !== 'undefined') {
                                            const _articlePack: wwks2ArticlePack = {
                                                id: articlePack['@attributes'].Id,
                                                state: 'available'
                                            }
                                            // below are optionals
                                            if (typeof articlePack['@attributes'].ScanCode !== 'undefined') {
                                                _articlePack.scanCode = articlePack['@attributes'].ScanCode;
                                            }
                                            if (typeof articlePack['@attributes'].State !== 'undefined') {
                                                _articlePack.state = articlePack['@attributes'].State.toLowerCase();
                                            }
                                            if (typeof articlePack['@attributes'].StockLocationId !== 'undefined') {
                                                _articlePack.stockLocationId = articlePack['@attributes'].StockLocationId;
                                            }
                                            if (typeof articlePack['@attributes'].Reserved !== 'undefined') {
                                                _articlePack.reserved = articlePack['@attributes'].Reserved;
                                            }
                                            if (typeof articlePack['@attributes'].DeliveryNumber !== 'undefined') {
                                                _articlePack.deliveryNumber = articlePack['@attributes'].DeliveryNumber;
                                            }
                                            if (typeof articlePack['@attributes'].BatchNumber !== 'undefined') {
                                                _articlePack.batchNumber = articlePack['@attributes'].BatchNumber;
                                            }
                                            if (typeof articlePack['@attributes'].ExternalId !== 'undefined') {
                                                _articlePack.externalId = articlePack['@attributes'].ExternalId;
                                            }
                                            if (typeof articlePack['@attributes'].ExpiryDate !== 'undefined') {
                                                _articlePack.expiryDate = articlePack['@attributes'].ExpiryDate;
                                            }
                                            if (typeof articlePack['@attributes'].StockInDate !== 'undefined') {
                                                _articlePack.stockInDate = articlePack['@attributes'].StockInDate;
                                            }
                                            if (typeof articlePack['@attributes'].SubItemQuantity !== 'undefined') {
                                                _articlePack.subItemQuantity = articlePack['@attributes'].SubItemQuantity;
                                            }
                                            if (typeof articlePack['@attributes'].Depth !== 'undefined') {
                                                _articlePack.depth = articlePack['@attributes'].Depth;
                                            }
                                            if (typeof articlePack['@attributes'].Width !== 'undefined') {
                                                _articlePack.width = articlePack['@attributes'].Width;
                                            }
                                            if (typeof articlePack['@attributes'].Height !== 'undefined') {
                                                _articlePack.height = articlePack['@attributes'].Height;
                                            }
                                            if (typeof articlePack['@attributes'].IsInFridge !== 'undefined') {
                                                _articlePack.isInFridge = articlePack['@attributes'].IsInFridge;
                                            }
                                            if (typeof articlePack['@attributes'].Shape !== 'undefined') {
                                                _articlePack.shape = articlePack['@attributes'].Shape;
                                            }
                                            if (typeof articlePack['@attributes'].BoxNumber !== 'undefined') {
                                                _articlePack.boxNumber = articlePack['@attributes'].BoxNumber;
                                            }
                                            if (typeof articlePack['@attributes'].OutputDestination !== 'undefined') {
                                                _articlePack.outputDestination = articlePack['@attributes'].OutputDestination;
                                            }
                                            if (typeof articlePack['@attributes'].OutputPoint !== 'undefined') {
                                                _articlePack.outputPoint = articlePack['@attributes'].OutputPoint;
                                            }
                                            if (typeof articlePack['@attributes'].MachineLocation !== 'undefined') {
                                                _articlePack.machineLocation = articlePack['@attributes'].MachineLocation;
                                            }

                                            articlePacks.push(_articlePack);

                                            if (_articlePack.state === 'available') {
                                                availableArticlePacks.push(_articlePack);
                                            } else if (_articlePack.state === 'notavailable') {
                                                notAvailableArticlePacks.push(_articlePack);
                                            }
                                        }
                                    });
                                }

                                const articleItem: wwks2Article = {
                                    id: article['@attributes'].Id,
                                    quantity: article['@attributes'].Quantity,
                                }
                                articleItem.packs = articlePacks;
                                articleItem.availablePacks = availableArticlePacks;
                                articleItem.notAvailablePacks = notAvailableArticlePacks;

                                if (typeof article['@attributes'].Name !== 'undefined') {
                                    articleItem.name = article['@attributes'].Name;
                                }
                                if (typeof article['@attributes'].DosageForm !== 'undefined') {
                                    articleItem.dosageForm = article['@attributes'].DosageForm;
                                }
                                if (typeof article['@attributes'].PackingUnit !== 'undefined') {
                                    articleItem.packingUnit = article['@attributes'].PackingUnit;
                                }
                                if (typeof article['@attributes'].MaxSubItemQuantity !== 'undefined') {
                                    articleItem.maxSubItemQuantity = article['@attributes'].MaxSubItemQuantity;
                                }
                                responseData.articles.push(articleItem);
                            }
                        }
                    });
                }

                if (processStatusDialog.typeDialog === 'StockInfo') {
                    responseData.reservationId = processStatusDialog.reservationId;
                    processStatusDialog.status.response = true;
                    processStatusDialog.timestamps.response = Date.now();
                    processStatusDialog.data.response = responseData;
                    this.Wwks2CommClient.SetProcessedDialogData(responseId, processStatusDialog)
                }
            }
        }

        if (WWKS.StockInfoMessage) {

            const requestId = WWKS.StockInfoMessage['@attributes'].Id;
            const responseData: wwks2StockInfoMessage = {
                'clientId': 0,
                'dialogId': 0,
                'articles': []
            }

            responseData.clientId = WWKS.StockInfoMessage['@attributes'].Source;
            responseData.dialogId = requestId;

            if (typeof WWKS.StockInfoMessage.Article !== 'undefined') {
                let _articles: any[];

                if (!Array.isArray(WWKS.StockInfoMessage.Article)) {
                    _articles = [WWKS.StockInfoMessage.Article];
                } else {
                    _articles = WWKS.StockInfoMessage.Article;
                }
                _articles.forEach((article) => {
                    if (typeof article['@attributes'] !== 'undefined') {
                        if (typeof article['@attributes'].Id !== 'undefined' && typeof article['@attributes'].Quantity !== 'undefined') {
                            const articlePacks: wwks2ArticlePack[] = [];
                            const availableArticlePacks: wwks2ArticlePack[] = [];
                            const notAvailableArticlePacks: wwks2ArticlePack[] = [];
                            if (typeof article.Pack !== 'undefined') {

                                let _articlePacks: any[];
                                if (!Array.isArray(article.Pack)) {
                                    _articlePacks = [article.Pack];
                                } else {
                                    _articlePacks = article.Pack;
                                }

                                _articlePacks.forEach(articlePack => {
                                    if (typeof articlePack['@attributes'].Id !== 'undefined') {
                                        const _articlePack: wwks2ArticlePack = {
                                            id: articlePack['@attributes'].Id,
                                            state: 'available'
                                        }
                                        // below are optionals
                                        if (typeof articlePack['@attributes'].ScanCode !== 'undefined') {
                                            _articlePack.scanCode = articlePack['@attributes'].ScanCode;
                                        }
                                        if (typeof articlePack['@attributes'].State !== 'undefined') {
                                            _articlePack.state = articlePack['@attributes'].State.toLowerCase();
                                        }
                                        if (typeof articlePack['@attributes'].StockLocationId !== 'undefined') {
                                            _articlePack.stockLocationId = articlePack['@attributes'].StockLocationId;
                                        }
                                        if (typeof articlePack['@attributes'].DeliveryNumber !== 'undefined') {
                                            _articlePack.deliveryNumber = articlePack['@attributes'].DeliveryNumber;
                                        }
                                        if (typeof articlePack['@attributes'].BatchNumber !== 'undefined') {
                                            _articlePack.batchNumber = articlePack['@attributes'].BatchNumber;
                                        }
                                        if (typeof articlePack['@attributes'].ExternalId !== 'undefined') {
                                            _articlePack.externalId = articlePack['@attributes'].ExternalId;
                                        }
                                        if (typeof articlePack['@attributes'].ExpiryDate !== 'undefined') {
                                            _articlePack.expiryDate = articlePack['@attributes'].ExpiryDate;
                                        }
                                        if (typeof articlePack['@attributes'].StockInDate !== 'undefined') {
                                            _articlePack.stockInDate = articlePack['@attributes'].StockInDate;
                                        }
                                        if (typeof articlePack['@attributes'].SubItemQuantity !== 'undefined') {
                                            _articlePack.subItemQuantity = articlePack['@attributes'].SubItemQuantity;
                                        }
                                        if (typeof articlePack['@attributes'].Depth !== 'undefined') {
                                            _articlePack.depth = articlePack['@attributes'].Depth;
                                        }
                                        if (typeof articlePack['@attributes'].Width !== 'undefined') {
                                            _articlePack.width = articlePack['@attributes'].Width;
                                        }
                                        if (typeof articlePack['@attributes'].Height !== 'undefined') {
                                            _articlePack.height = articlePack['@attributes'].Height;
                                        }
                                        if (typeof articlePack['@attributes'].IsInFridge !== 'undefined') {
                                            _articlePack.isInFridge = articlePack['@attributes'].IsInFridge;
                                        }
                                        if (typeof articlePack['@attributes'].Shape !== 'undefined') {
                                            _articlePack.shape = articlePack['@attributes'].Shape;
                                        }
                                        if (typeof articlePack['@attributes'].BoxNumber !== 'undefined') {
                                            _articlePack.boxNumber = articlePack['@attributes'].BoxNumber;
                                        }
                                        if (typeof articlePack['@attributes'].OutputDestination !== 'undefined') {
                                            _articlePack.outputDestination = articlePack['@attributes'].OutputDestination;
                                        }
                                        if (typeof articlePack['@attributes'].OutputPoint !== 'undefined') {
                                            _articlePack.outputPoint = articlePack['@attributes'].OutputPoint;
                                        }
                                        if (typeof articlePack['@attributes'].MachineLocation !== 'undefined') {
                                            _articlePack.machineLocation = articlePack['@attributes'].MachineLocation;
                                        }

                                        articlePacks.push(_articlePack);

                                        if (_articlePack.state === 'available') {
                                            availableArticlePacks.push(_articlePack);
                                        } else if (_articlePack.state === 'notavailable') {
                                            notAvailableArticlePacks.push(_articlePack);
                                        }
                                    }
                                });
                            }

                            const articleItem: wwks2Article = {
                                id: article['@attributes'].Id,
                                quantity: article['@attributes'].Quantity,
                            }
                            articleItem.packs = articlePacks;
                            articleItem.availablePacks = availableArticlePacks;
                            articleItem.notAvailablePacks = notAvailableArticlePacks;

                            if (typeof article['@attributes'].Name !== 'undefined') {
                                articleItem.name = article['@attributes'].Name;
                            }
                            if (typeof article['@attributes'].DosageForm !== 'undefined') {
                                articleItem.dosageForm = article['@attributes'].DosageForm;
                            }
                            if (typeof article['@attributes'].PackingUnit !== 'undefined') {
                                articleItem.packingUnit = article['@attributes'].PackingUnit;
                            }
                            if (typeof article['@attributes'].MaxSubItemQuantity !== 'undefined') {
                                articleItem.maxSubItemQuantity = article['@attributes'].MaxSubItemQuantity;
                            }
                            responseData.articles.push(articleItem);
                        }
                    }
                });
            }

            if (responseData.articles.length > 0) {
                // trigger the event
                if (typeof this.onStockChange !== 'undefined') {
                    this.onStockChange(responseData);
                }
            }
        }

    }

    public setOnStockChangeCallback(callback: (data: wwks2StockInfoMessage) => void) {
        this.onStockChange = callback;
    }
}