import * as React from 'react';

import classNames from 'classnames';

import { slugify } from 'utils';

import { Card } from '@/card';
import { BaseProps } from '@/core';
import { Tx } from '@/typography';

import styles from './Tabs.scss';

export type ITabItem<T> = T & {
    title: string | React.ReactNode;
    body: string | React.ReactNode;
};
export type ITabVariant = 'neutral' | 'accent';
type IProps<T> = BaseProps &
    React.HTMLProps<HTMLDivElement> & {
        activeTabIndex: number | null;
        onActiveTabIndexChange: (newActiveTabIndex: number) => void;
        items: (ITabItem<T> | false)[];
        variant?: ITabVariant;
        fullWidth?: boolean;
    };

export default class Tabs<T = Record<string, unknown>> extends React.PureComponent<IProps<T>> {
    render() {
        return (
            <div className={this.getBaseClassName()}>
                {this.renderTabsHeader()}
                {this.renderActiveTab()}
            </div>
        );
    }

    private renderTabsHeader() {
        const { items, activeTabIndex, onActiveTabIndexChange, fullWidth, ...rest } = this.props;
        return (
            <Card
                {...(rest as any)}
                elevated={true}
                className={classNames(styles.TabHeaders, fullWidth ? styles.FullWidthHeaders : null)}
            >
                {items.map((item, index) =>
                    item ? (
                        <Tx
                            as="a"
                            level="body-lg"
                            key={index}
                            href=""
                            className={this.getTabHeaderClassName(item, index)}
                            style={this.getTabHeaderStyle(item, index)}
                            onClick={(event: React.MouseEvent) => {
                                event.preventDefault();
                                this.handleTabHeaderClick(index);
                            }}
                            data-test-id={`tab-${typeof item.title === 'string' ? slugify(item.title) : index}`}
                        >
                            {this.renderItemLabel(item)}
                        </Tx>
                    ) : null,
                )}
            </Card>
        );
    }

    // @ts-ignore
    protected getTabHeaderClassName(_, index: number) {
        const { activeTabIndex, variant, fullWidth } = this.props;
        return classNames(
            styles.TabHeader,
            index === activeTabIndex ? styles.active : null,
            variant === 'neutral' ? styles.NeutralTabHeader : styles.AccentTabHeader,
            fullWidth ? styles.FullWidthTabHeader : null,
        );
    }

    protected renderItemLabel(item: ITabItem<T>) {
        return item.title;
    }

    protected renderActiveTab() {
        const activeTab = this.getActiveTab();

        if (!activeTab) {
            return null;
        }

        return <div className={styles.TabBody}>{activeTab.body}</div>;
    }

    private handleTabHeaderClick = (index: number) => {
        this.props.onActiveTabIndexChange(index);
    };

    protected getBaseClassName() {
        const { className } = this.props;
        return classNames(styles.Tabs, className);
    }

    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    protected getTabHeaderStyle(item: ITabItem<T>, index: number): React.CSSProperties {
        return {};
    }

    protected getActiveTab() {
        if (this.props.activeTabIndex === null) {
            return null;
        }

        return this.props.items[this.props.activeTabIndex];
    }
}
