import { Domain } from 'api';
import { TVScreen } from 'data-store';

export default function findSlideContents(
    slide: Domain.SlideshowSlide,
    slideContents: TVScreen.types.Slide[],
): TVScreen.types.Slide | undefined {
    return slideContents.find(searchedContents => {
        return (
            (slide.type === 'productWall' &&
                TVScreen.types.isProductWallSlideContents(searchedContents) &&
                searchedContents.productWallId === slide.productWallId) ||
            (slide.type === 'customPage' &&
                TVScreen.types.isCustomPageSlideContents(searchedContents) &&
                searchedContents.customPageId === slide.customPageId) ||
            (slide.type === 'video' &&
                TVScreen.types.isMediaItemSlideContents(searchedContents) &&
                searchedContents.mediaItemId === slide.mediaItemId) ||
            (slide.type === 'image' &&
                TVScreen.types.isMediaItemSlideContents(searchedContents) &&
                searchedContents.mediaItemId === slide.mediaItemId)
        );
    });
}
