import { Domain } from 'api';

import { scheduleApi } from '@/api';
import { makeActions, makeReducer, makeReducerActions, makeSelectors } from '@/makeOverviewState';

type ScheduleProps = keyof Domain.Schedule;

export const defaultSorting: Domain.Sorting<ScheduleProps> = {
    field: 'name',
    direction: 'ascending',
};

export const pageSize = 20;

export const selectors = makeSelectors<Domain.Schedule, ScheduleProps>({
    getState: rootState => rootState.schedule.overview,
});

export const reducerActions = makeReducerActions<Domain.Schedule, ScheduleProps>({
    reducerPrefix: '@schedule/overview',
});

export const actions = makeActions<
    Domain.Schedule,
    ScheduleProps,
    {
        name?: string;
        priority?: '1' | '2' | '3' | '4' | 'any';
        branchId?: Domain.Schedule['branchId'];
        companyId?: Domain.Schedule['companyId'];
        isActive?: 'any' | 'yes' | 'no';
    }
>({
    dataTableSaveKey: 'schedulesOverview-v5',
    loadApi: options =>
        scheduleApi.GetSchedules(
            options.ownership,
            options.pagination,
            options.sorting,
            options.search,
            options.filters
                ? {
                      ...options.filters,
                      isActive: options.filters.isActive ? options.filters.isActive === 'yes' : undefined,
                      priority: options.filters.priority && options.filters.priority !== 'any' ? options.filters.priority : undefined,
                  }
                : undefined,
        ),
    defaultSorting,
    pageSize,
    getBaseUrl: () => '/presentations/schedules',
    reducerActions,
    selectors,
});

export const overviewReducer = makeReducer<Domain.Schedule, ScheduleProps>({
    defaultSorting,
    pageSize,
    reducerActions,
});
