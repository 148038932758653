import * as React from 'react';

import { ColorPicker } from '@/colorPicker';
import { EyedropStartHandler } from '@/colorPicker/ControlledColorPicker';

interface IStatefulColorPickerProps {
    className: string;
    value: string;
    onChangeComplete: (newValue?: string) => void;
    presetColors?: (string | undefined)[];
    onEyedropStart?: EyedropStartHandler;
    onEyedropEnd?: () => void;
}

interface IStatefulColorPickerState {
    value: string | undefined;
    oldPropsValue: string;
}

export default class StatefulColorPicker extends React.Component<IStatefulColorPickerProps, IStatefulColorPickerState> {
    constructor(props: IStatefulColorPickerProps) {
        super(props);

        this.state = {
            value: props.value,
            oldPropsValue: props.value,
        };
    }

    static getDerivedStateFromProps(
        nextProps: Readonly<IStatefulColorPickerProps>,
        state: IStatefulColorPickerState,
    ): IStatefulColorPickerState {
        if (nextProps.value !== state.oldPropsValue) {
            return {
                value: nextProps.value,
                oldPropsValue: nextProps.value,
            };
        }

        return state;
    }

    render() {
        const { className, onChangeComplete, presetColors, onEyedropStart, onEyedropEnd } = this.props;
        return (
            <ColorPicker
                className={className}
                value={this.state.value}
                onChange={newValue => this.setActualValue(newValue)}
                onChangeComplete={newValue => {
                    this.setActualValue(newValue);
                    onChangeComplete(newValue);
                }}
                onEyedropStart={onEyedropStart}
                onEyedropEnd={onEyedropEnd}
                presetColors={presetColors}
            />
        );
    }

    private setActualValue = (newValue?: string) => {
        this.setState({
            value: newValue,
        });
    };
}
