import * as React from 'react';

import classNames from 'classnames';

import { CheckboxListContext, CheckboxListContextBase } from '@/checkboxList/CheckboxListContext';
import { InputBase, InputBaseProps, KeyboardHandler } from '@/core';
import { Tx } from '@/typography';

const checkboxOff = require('./assets/checkbox-default.svg');
const checkboxOn = require('./assets/checkbox-selected.svg');
const checkboxPartial = require('./assets/minus-box.svg');

import styles from './Checkbox.scss';

export type CheckboxSize = 's' | 'l';
export type CheckboxVariant = 'primary' | 'secondary' | 'error';

export type ControlledCheckboxProps = InputBaseProps & {
    value?: string;
    size?: CheckboxSize;
    active?: boolean;
    partial?: boolean;
    onChange?: (checked: boolean, value?: string) => void;
    forwardRef?: React.Ref<HTMLInputElement>;
    stopPropagation?: boolean;
    variant?: CheckboxVariant;
};

export type ControlledCheckboxPropsWithChecked = ControlledCheckboxProps & {
    checked: boolean;
};

class ControlledCheckbox<P = void> extends InputBase<ControlledCheckboxPropsWithChecked & P> {
    static contextType: React.Context<CheckboxListContextBase> = CheckboxListContext;
    declare context: React.ContextType<typeof CheckboxListContext>;
    protected readonly inputRef: React.RefObject<HTMLInputElement>;
    protected readonly keyHandler = new KeyboardHandler();

    constructor(props: ControlledCheckboxPropsWithChecked & P) {
        super(props);

        this.inputRef = (props.forwardRef || React.createRef<HTMLDivElement>()) as React.RefObject<HTMLInputElement>;
    }

    protected renderInput(): React.ReactNode {
        const { name, children, partial, variant = 'primary', size = 's' } = this.props;
        return (
            <>
                <input
                    ref={this.inputRef}
                    type={this.getInputType()}
                    name={name}
                    checked={this.isChecked()}
                    onChange={this.handleChange}
                    tabIndex={-1}
                    disabled={this.isDisabled()}
                    data-test-id={this.getDataTestId()}
                />

                <span
                    className={styles.gfx + ' ' + variant}
                    dangerouslySetInnerHTML={{ __html: this.isChecked() ? (partial ? checkboxPartial : checkboxOn) : checkboxOff }}
                />

                {children ? (
                    <Tx
                        className={styles.CheckboxLabel}
                        level={size === 'l' ? 'body-md' : 'body-sm'}
                        as="span"
                        sx={{
                            disableSelection: true,
                        }}
                    >
                        {children}
                    </Tx>
                ) : null}
            </>
        );
    }

    protected handleChange = (): void => {
        const checkboxList = this.context;
        const { onChange, checked, value } = this.props;

        if (checkboxList.name && checkboxList.onChange) {
            const groupChecked = !!(value && checkboxList.value.indexOf(value) > -1);
            checkboxList.onChange(!groupChecked, value);
        }

        if (onChange) {
            onChange(!checked);
        }
    };

    protected isChecked(): boolean | undefined {
        const checkboxList = this.context;
        let checked: boolean = this.props.checked;

        if (checkboxList.name) {
            const { value } = this.props;
            checked = !!(value && checkboxList.value.indexOf(value) > -1);
        }

        return checked;
    }

    protected isDisabled(): boolean {
        const checkboxList = this.context;

        let disabled: boolean | undefined = this.props.disabled;
        if (typeof checkboxList.disabled !== 'undefined') {
            disabled = checkboxList.disabled;
        }

        return !!disabled;
    }

    protected getClassName(): string {
        const { active, variant = 'primary', size = 's' } = this.props;

        return classNames(
            super.getClassName(),
            styles.Checkbox,
            'c-' + size,
            active ? 'active' : null,
            this.isChecked() ? 'checked' : null,
            variant,
        );
    }

    protected getStyle(): React.CSSProperties | undefined {
        return this.props.style;
    }

    protected getTabIndex(): number {
        return this.isDisabled() ? -1 : 0;
    }

    protected getOtherBaseWrapperProps(): any {
        let dataTestId = this.getDataTestId();
        if (dataTestId) {
            dataTestId = 'label-' + dataTestId;
        }

        return {
            onMouseDown: (event: React.MouseEvent) => {
                if (this.props.stopPropagation) {
                    event.stopPropagation();
                }
            },
            onKeyDown: this.keyHandler.handleKey(['ENTER', 'SPACE'], () => {
                this.handleChange();
            }),
            onClick: (event: React.MouseEvent) => {
                if (this.props.stopPropagation) {
                    event.stopPropagation();
                }
            },
            'data-test-id': dataTestId,
        };
    }

    protected getInputType(): string {
        return 'checkbox';
    }
}

export const ControlledCheckboxBase = ControlledCheckbox;

export default React.forwardRef<HTMLInputElement, ControlledCheckboxPropsWithChecked>((props, ref) => {
    return (
        <ControlledCheckbox
            forwardRef={ref}
            {...props}
        />
    );
});
