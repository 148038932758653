import { createAction, Selector } from '@reduxjs/toolkit';

import { Domain } from 'api';

import { withPayloadType } from '@/action';
import { webshopLibraryNewsArticleApi } from '@/api';
import { makeActions, makeReducer, makeReducerActions, makeSelectors } from '@/makeOverviewState';
import { RootState } from '@/store';

type WebshopLibraryNewsArticleProps = keyof Domain.WebshopLibraryNewsArticle;

export const defaultSorting: Domain.Sorting<WebshopLibraryNewsArticleProps> = {
    field: 'createdOn',
    direction: 'descending',
};

export const pageSize = 20;

export const setLocale = createAction('@webshopLibraryNewsArticle/overview/setLocale', withPayloadType<Domain.Locale>());
export const selectLocale: Selector<RootState, Domain.Locale | undefined> = state => state.webshopLibraryNewsArticle.overview.locale;

export const selectors = makeSelectors<Domain.WebshopLibraryNewsArticle, WebshopLibraryNewsArticleProps>({
    getState: rootState => rootState.webshopLibraryNewsArticle.overview,
});

export const reducerActions = makeReducerActions<Domain.WebshopLibraryNewsArticle, WebshopLibraryNewsArticleProps>({
    reducerPrefix: '@webshopLibraryNewsArticle/overview',
});

export const actions = makeActions<Domain.WebshopLibraryNewsArticle, WebshopLibraryNewsArticleProps>({
    dataTableSaveKey: 'webshopLibraryNewsArticlesOverview-v5',
    loadApi: options => {
        if (options.globallySelectedWebshopId === undefined) {
            return;
        }

        return webshopLibraryNewsArticleApi.GetWebshopLibraryNewsArticles(
            options.pagination,
            options.sorting,
            selectLocale(options.state) || options.globallySelectedWebshopLocale || options.globallySelectedWebshopDefaultLocale || 'nl_BE',
            options.search,
            options.filters,
        );
    },
    defaultSorting,
    pageSize,
    getBaseUrl: () => '/webshops/news-article-library',
    reducerActions,
    selectors,
});

export const overviewReducer = makeReducer<
    Domain.WebshopLibraryNewsArticle,
    WebshopLibraryNewsArticleProps,
    {
        locale?: Domain.Locale;
    }
>({
    defaultSorting,
    pageSize,
    reducerActions,
    extraCases: builder =>
        builder.addCase(setLocale, (state, action) => {
            state.locale = action.payload;
        }),
});
