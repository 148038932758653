import { Domain, Infrastructure } from 'api';

import { searchSynonymApi } from '@/api';
import { makeActions, makeReducer, makeReducerActions, makeSelectors } from '@/makeDetailsState';

export type SearchSynonymsWithExists = Domain.SearchSynonyms & {
    exists: boolean;
};

export const selectors = makeSelectors<SearchSynonymsWithExists>({
    getState: rootState => rootState.searchSynonym.update,
});

export const reducerActions = makeReducerActions<SearchSynonymsWithExists>({
    reducerPrefix: '@searchSynonyms/update',
});

export const actions = makeActions<SearchSynonymsWithExists>({
    loadApi: async options => {
        try {
            const searchSynonyms = await searchSynonymApi.GetSearchSynonyms(
                options.urlParams.searchTerm,
                options.urlParams.locale as Domain.Locale,
            );

            return {
                ...searchSynonyms,
                exists: true,
            };
        } catch (e) {
            if (e instanceof Infrastructure.Api.HttpApiException && e.status === 404) {
                return {
                    searchTerm: options.urlParams.searchTerm,
                    locale: options.urlParams.locale as Domain.Locale,
                    synonyms: [],
                    exists: false,
                };
            }

            throw e;
        }
    },
    reducerActions,
    selectors,
});

export const updateReducer = makeReducer<SearchSynonymsWithExists>({
    reducerActions,
});
