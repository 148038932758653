import { NightHatchTunnelMessage } from '@/Domain/NightHatch';

import DeviceTunnel from './DeviceTunnel';
import Peer from './Peer';
import { TunnelSide } from './PeerTunnel';

export default class NightHatchTunnel extends DeviceTunnel<NightHatchTunnelMessage> {
    public constructor(
        peer: Peer,
        deviceId: string,
        options: {
            tunnelSide: TunnelSide;
            maxEstablishTime?: number;
            doNotRetryConnection?: boolean;
            onConnectionFailure?: (e: Event) => void;
        },
    ) {
        super(peer, deviceId, {
            tunnelPrefix: 'nightHatch',
            ...options,
        });
    }
}
