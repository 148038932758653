import { createAction, createReducer, Selector } from '@reduxjs/toolkit';

import { Domain } from 'api';

import { ThunkAction, withPayloadType } from '@/action';
import { webshopOrderApi, productSelectionApi } from '@/api';
import { URLParams } from '@/routing';
import { RootState } from '@/store';

export interface State {
    order?: Domain.WebshopOrderDetails;
    orderProducts: Domain.BulkProductDetailsList;
}

const initialState: State = {
    order: undefined,
    orderProducts: [],
};

const reducerActions = {
    setOrder: createAction('@webshopOrderShipment/createShipment/setOrder', withPayloadType<Domain.WebshopOrderDetails>()),
    setOrderProducts: createAction(
        '@webshopOrderShipment/createShipment/setOrderProducts',
        withPayloadType<Domain.BulkProductDetailsList>(),
    ),
};

export const createShipmentReducer = createReducer(initialState, builder =>
    builder
        .addCase(reducerActions.setOrder, (state, action) => {
            state.order = action.payload;
        })
        .addCase(reducerActions.setOrderProducts, (state, action) => {
            state.orderProducts = action.payload;
        }),
);

export const selectOrder: Selector<RootState, Domain.WebshopOrderDetails> = state => {
    const order = state.webshopOrderShipment.createShipment.order;

    if (!order) {
        throw new Error('Webshop order not loaded');
    }

    return order;
};

export const selectOrderProducts: Selector<RootState, Domain.BulkProductDetailsList> = state => {
    return state.webshopOrderShipment.createShipment.orderProducts;
};

export const load =
    (options: { urlParams: URLParams }): ThunkAction =>
    async dispatch => {
        const order = await webshopOrderApi.GetWebshopOrderDetails(options.urlParams.webshopId, options.urlParams.orderId);

        const orderProducts = await productSelectionApi.BulkGetProductDetails(
            order.branchId,
            order.orderLines.filter(orderLine => !!orderLine.productId).map(orderLine => orderLine.productId!),
        );

        dispatch(reducerActions.setOrder(order));
        dispatch(reducerActions.setOrderProducts(orderProducts));
    };
