import { Domain } from 'api';

import { userApi } from '@/api';
import { makeActions, makeReducer, makeReducerActions, makeSelectors } from '@/makeDetailsState';

export const selectors = makeSelectors<Domain.UserDetails>({
    getState: rootState => rootState.user.details,
});

export const reducerActions = makeReducerActions<Domain.UserDetails>({
    reducerPrefix: '@user/details',
});

export const actions = makeActions<Domain.UserDetails>({
    loadApi: options => userApi.GetUserDetails(options.urlParams.userId),
    reducerActions,
    selectors,
});

export const detailsReducer = makeReducer<Domain.UserDetails>({
    reducerActions,
});
