import { Pagination, Sorting, WebshopNewsletterSubscriptionsPage } from '@/Domain';

import Endpoint from './Endpoint';

export default class WebshopNewsletterSubscriptionEndpoint extends Endpoint {
    public async getWebshopNewsletterSubscriptions(
        pagination: Pagination,
        sorting: Sorting,
        webshopId: string,
        search?: string,
    ): Promise<WebshopNewsletterSubscriptionsPage> {
        const url = `/newsletter-subscription/overview/webshop/${webshopId}`;

        const response = await this.client.get({
            url,
            params: {
                pagination,
                sorting,
                search,
            },
        });

        this.guardResponseSchema('#/definitions/WebshopNewsletterSubscriptionsPage', response);

        return response;
    }

    public GetNewsletterSubscriptionExportURL(webshopId: string): string {
        return this.client.buildApiURL(`/newsletter-subscription/overview/webshop/${webshopId}/export`, true);
    }
}
