import * as React from 'react';

import classNames from 'classnames';

import { BaseProps } from '@/core';
import { Tx } from '@/typography';

import styles from './Button.scss';

export type ButtonSize = 'xl' | 'l' | 'm' | 's' | 'xs' | 'xxs';
export type ButtonVariant = 'primary' | 'secondary' | 'tertiary' | 'plain' | 'danger' | 'success' | 'plainPrimary';

const typographyLevels: { [key in ButtonSize]: 'heading-3' | 'heading-5' | 'body-lg' | 'heading-6' | 'body-sm' } = {
    xl: 'heading-3',
    l: 'body-lg',
    m: 'body-lg',
    s: 'heading-6',
    xs: 'body-sm',
    xxs: 'body-sm',
};

interface BaseButtonProps extends BaseProps {
    variantSize?: ButtonSize;
    variant?: ButtonVariant;
    active?: boolean;
    disabled?: boolean;
    startIcon?: React.ReactNode;
    endIcon?: React.ReactNode;
    fullWidth?: boolean;
    rounded?: boolean;
    className?: string;
    type?: 'button' | 'submit' | 'reset';
}

type ButtonProps<C extends React.ElementType> = BaseButtonProps & {
    as?: C;
    children?: React.ReactNode;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
} & Omit<React.ComponentPropsWithRef<C>, 'as' | 'children' | 'className' | 'disabled'>;

const ButtonInner = <C extends React.ElementType = 'button'>(
    {
        as,
        variantSize = 'l',
        variant = 'secondary',
        active = false,
        disabled = false,
        startIcon,
        endIcon,
        fullWidth = false,
        rounded = false,
        className,
        children,
        type = 'button',
        ...restProps
    }: ButtonProps<C>,
    ref: React.Ref<React.ComponentPropsWithRef<C>['ref']>,
) => {
    const isIconOnly = (startIcon || endIcon) && !children;
    const Component = as || 'button';
    const classes = classNames(
        styles.Button,
        variantSize,
        variant,
        { iconButton: isIconOnly },
        active && 'active',
        fullWidth && 'fullWidth',
        rounded && 'rounded',
        className,
        disabled && 'disabled',
    );

    const content = (
        <>
            {startIcon && <span className={classNames(styles.IconContainer, styles.startIcon, styles[variantSize])}>{startIcon}</span>}
            {!isIconOnly && (
                <Tx
                    level={typographyLevels[variantSize] || 'heading-5'}
                    as="span"
                    sx={{ weight: 'medium' }}
                >
                    {children}
                </Tx>
            )}
            {endIcon && <span className={classNames(styles.IconContainer, styles.endIcon, styles[variantSize])}>{endIcon}</span>}
        </>
    );

    return (
        <Component
            ref={ref}
            className={classes}
            disabled={disabled}
            type={type}
            {...restProps}
        >
            {content}
        </Component>
    );
};

const Button = React.forwardRef(ButtonInner) as <C extends React.ElementType = 'button'>(
    props: ButtonProps<C> & { ref?: React.Ref<React.ComponentPropsWithRef<C>['ref']> },
) => JSX.Element;

export default Button;
