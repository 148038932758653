import { Domain } from 'api';

import { deviceApi } from '@/api';
import { makeActions, makeReducer, makeReducerActions, makeSelectors } from '@/makeOverviewState';

export type DeviceProps = keyof Domain.Device;

export const defaultSorting: Domain.Sorting<DeviceProps> = {
    field: 'name',
    direction: 'ascending',
};

export const pageSize = 20;

export const selectors = makeSelectors<Domain.Device, DeviceProps>({
    getState: rootState => rootState.device.vendingMachineOverview,
});

export const reducerActions = makeReducerActions<Domain.Device, DeviceProps>({
    reducerPrefix: '@vendingMachine/overview',
});

export const actions = makeActions<Domain.Device, DeviceProps>({
    dataTableSaveKey: 'vendingMachinesOverview-v5',
    loadApi: options =>
        deviceApi.GetDevices(options.ownership, options.pagination, options.sorting, options.search, {
            ...(options.filters as object),
            type: 'vending-machine',
        }),
    defaultSorting,
    pageSize,
    getBaseUrl: () => '/vending-machines/overview',
    reducerActions,
    selectors,
});

export const vendingMachineOverviewReducer = makeReducer<Domain.Device, DeviceProps>({
    defaultSorting,
    pageSize,
    reducerActions,
});
