import { Locale } from './Localisation';

export const TranslationScopes = ['shop', 'platform', 'api'] as const;
export type TranslationScope = (typeof TranslationScopes)[number];

export interface Translation {
    entry: string;
    locale: Locale;
    originalLocale: Locale;
    context: string | null;
    translation: string | null;
    translationScope: TranslationScope;
    isGenerated?: boolean | number | null;
    importedAt?: string | null;
}

export type TranslationList = Translation[];

export type UpdateTranslationPayload = {
    translationKey: string;
    locale: Locale;
    context: string | null;
    translation: string | null;
    translationScope: TranslationScope;
};
