import { Domain } from 'api';

export default function getProductImages(product: Domain.SlideshowProduct) {
    let productImage = '';
    let frontalImage = '';
    let allFrontals: Domain.SlideshowProductWithFrontalImage['allFrontals'] = [];

    if (Domain.slideshowProductHasProductImage(product)) {
        productImage = product.productImage;
    }

    if (Domain.slideshowProductHasFrontalImage(product)) {
        frontalImage = product.frontalImage;
        allFrontals = product.allFrontals || [];
    }

    return {
        productImage,
        frontalImage,
        allFrontals,
    };
}
