import moment from 'moment';

import { Infrastructure } from 'api';
import { browserStorage } from 'utils';

const JSON_WEB_TOKEN_VERSION = 'v6';
const JSON_WEB_TOKEN_STORAGE_KEY = 'jsonWebToken';
const IMPERSONATOR_JSON_WEB_TOKENS_STORAGE_KEY = 'impersonatorJsonWebTokens';
const DEVICE_SIGNALING_JSON_WEB_TOKEN_STORAGE_KEY = 'deviceSignalingJsonWebToken';
const IMPERSONATING_DEVICE_WITH_MAC_ADDRESS_STORAGE_KEY = 'impersonatingMacAddress';
const DEVICE_CONTENT_HASH_KEY = 'deviceHash-v2';

interface StoredJsonWebToken {
    accessToken: string;
    refreshToken: string;
    version: string;
}

export function clearJsonWebToken(): void {
    browserStorage.removeItem(JSON_WEB_TOKEN_STORAGE_KEY);
}

export function setJsonWebToken(token: Infrastructure.JWT.JsonWebToken, session?: boolean): void {
    browserStorage.setItem(
        JSON_WEB_TOKEN_STORAGE_KEY,
        JSON.stringify({
            version: JSON_WEB_TOKEN_VERSION,
            accessToken: token.value,
            refreshToken: token.refreshToken,
        }),
        session,
    );
}

export function getValidLocalJsonWebToken(): Infrastructure.JWT.JsonWebToken | undefined {
    const token = getValidOrExpiredLocalJsonWebToken();

    if (token && token.expiration <= moment.utc().valueOf() / 1000) {
        clearJsonWebToken();
        return undefined;
    }

    return token;
}

export function getValidOrExpiredLocalJsonWebToken(): Infrastructure.JWT.JsonWebToken | undefined {
    const tokenData = browserStorage.getItem<StoredJsonWebToken>(JSON_WEB_TOKEN_STORAGE_KEY);

    if (tokenData) {
        const version = tokenData.value.version;
        const accessToken = tokenData.value.accessToken;
        const refreshToken = tokenData.value.refreshToken;
        let jsonWebToken;

        try {
            jsonWebToken = new Infrastructure.JWT.JsonWebToken(accessToken, refreshToken);
        } catch (e) {
            // tslint:disable-next-line:no-console
            console.error(e);
        }

        if (!jsonWebToken || version !== JSON_WEB_TOKEN_VERSION) {
            browserStorage.removeItem(JSON_WEB_TOKEN_STORAGE_KEY, tokenData.session);
            return undefined;
        } else {
            return jsonWebToken;
        }
    }

    return undefined;
}

export function clearDeviceSignalingJsonWebToken(): void {
    browserStorage.removeItem(DEVICE_SIGNALING_JSON_WEB_TOKEN_STORAGE_KEY);
}

export function setDeviceSignalingJsonWebToken(token: Infrastructure.JWT.JsonWebToken): void {
    browserStorage.setItem(
        DEVICE_SIGNALING_JSON_WEB_TOKEN_STORAGE_KEY,
        JSON.stringify({
            version: JSON_WEB_TOKEN_VERSION,
            accessToken: token.value,
            refreshToken: token.refreshToken,
        }),
        true,
    );
}

export function getValidLocalDeviceSignalingJsonWebToken(deviceId: string): Infrastructure.JWT.JsonWebToken | undefined {
    const tokenData = browserStorage.getItem<StoredJsonWebToken>(DEVICE_SIGNALING_JSON_WEB_TOKEN_STORAGE_KEY);

    if (tokenData) {
        const version = tokenData.value.version;
        const accessToken = tokenData.value.accessToken;
        const refreshToken = tokenData.value.refreshToken;
        let jsonWebToken;

        try {
            jsonWebToken = new Infrastructure.JWT.JsonWebToken(accessToken, refreshToken);
        } catch (e) {
            // tslint:disable-next-line:no-console
            console.error(e);
        }

        if (
            !jsonWebToken ||
            version !== JSON_WEB_TOKEN_VERSION ||
            jsonWebToken.expiration <= moment.utc().valueOf() / 1000 ||
            !jsonWebToken.claims.channel.endsWith(deviceId)
        ) {
            browserStorage.removeItem(DEVICE_SIGNALING_JSON_WEB_TOKEN_STORAGE_KEY, tokenData.session);
            return undefined;
        } else {
            return jsonWebToken;
        }
    }

    return undefined;
}

export function setImpersonatingDeviceWithMacAddress(macAddress: string): void {
    browserStorage.setItem(IMPERSONATING_DEVICE_WITH_MAC_ADDRESS_STORAGE_KEY, macAddress, true);
}

export function getImpersonatingDeviceWithMacAddress(): string | undefined {
    const item = browserStorage.getItem(IMPERSONATING_DEVICE_WITH_MAC_ADDRESS_STORAGE_KEY);
    if (item) {
        return item.value;
    }
}

type StoredImpersonatorJsonWebTokens = (StoredJsonWebToken & {
    impersonatorAccessToken: string;
    impersonatorRefreshToken: string;
})[];

export function popAllImpersonatorJsonWebTokens(): void {
    browserStorage.removeItem(IMPERSONATOR_JSON_WEB_TOKENS_STORAGE_KEY);
}

export function popImpersonatorJsonWebToken(): void {
    const defaultValue = {
        value: [],
        session: true,
    };

    const currentTokens = browserStorage.getItem<StoredImpersonatorJsonWebTokens>(IMPERSONATOR_JSON_WEB_TOKENS_STORAGE_KEY) || defaultValue;
    currentTokens.value.pop();

    browserStorage.setItem(IMPERSONATOR_JSON_WEB_TOKENS_STORAGE_KEY, currentTokens.value, true);
}

export function pushImpersonatorJsonWebToken(
    token: Infrastructure.JWT.JsonWebToken,
    impersonatorToken: Infrastructure.JWT.JsonWebToken,
): void {
    const defaultValue = {
        value: [],
        session: true,
    };

    const currentTokens = browserStorage.getItem<StoredImpersonatorJsonWebTokens>(IMPERSONATOR_JSON_WEB_TOKENS_STORAGE_KEY) || defaultValue;
    currentTokens.value.push({
        version: JSON_WEB_TOKEN_VERSION,
        accessToken: token.value,
        refreshToken: token.refreshToken,
        impersonatorAccessToken: impersonatorToken.value,
        impersonatorRefreshToken: impersonatorToken.refreshToken,
    });

    browserStorage.setItem(IMPERSONATOR_JSON_WEB_TOKENS_STORAGE_KEY, currentTokens.value, true);
}

export function getValidLocalImpersonatorJsonWebToken():
    | {
          token: Infrastructure.JWT.JsonWebToken;
          impersonatorToken: Infrastructure.JWT.JsonWebToken;
      }
    | undefined {
    const tokenData = browserStorage.getItem<StoredImpersonatorJsonWebTokens>(IMPERSONATOR_JSON_WEB_TOKENS_STORAGE_KEY);

    if (tokenData && tokenData.value.length > 0) {
        const mostRecentTokenData = tokenData.value[tokenData.value.length - 1];
        const version = mostRecentTokenData.version;
        const accessToken = mostRecentTokenData.accessToken;
        const refreshToken = mostRecentTokenData.refreshToken;
        const impersonatorAccessToken = mostRecentTokenData.impersonatorAccessToken;
        const impersonatorRefreshToken = mostRecentTokenData.impersonatorRefreshToken;
        let jsonWebToken;
        let impersonatorJsonWebToken;

        try {
            jsonWebToken = new Infrastructure.JWT.JsonWebToken(accessToken, refreshToken);
            impersonatorJsonWebToken = new Infrastructure.JWT.JsonWebToken(impersonatorAccessToken, impersonatorRefreshToken);
        } catch (e) {
            // tslint:disable-next-line:no-console
            console.error(e);
        }

        if (
            !jsonWebToken ||
            !impersonatorJsonWebToken ||
            version !== JSON_WEB_TOKEN_VERSION ||
            jsonWebToken.expiration <= moment.utc().valueOf() / 1000 ||
            impersonatorJsonWebToken.expiration <= moment.utc().valueOf() / 1000
        ) {
            browserStorage.removeItem(IMPERSONATOR_JSON_WEB_TOKENS_STORAGE_KEY, true);
            return undefined;
        } else {
            return {
                token: jsonWebToken,
                impersonatorToken: impersonatorJsonWebToken,
            };
        }
    }

    return undefined;
}

export function setDeviceContentHash(hash: string) {
    browserStorage.setItem(DEVICE_CONTENT_HASH_KEY, hash);
}

export function getDeviceContentHash() {
    return browserStorage.getItem(DEVICE_CONTENT_HASH_KEY);
}

export function clearDeviceContentHash(): void {
    browserStorage.removeItem(DEVICE_CONTENT_HASH_KEY);
}

export function clearDeviceFootprint() {
    browserStorage.clearDeviceFingerprint();
    browserStorage.removeItem('deviceLastAutomaticRefresh');
    browserStorage.removeItem('locale');
    clearDeviceSignalingJsonWebToken();
    clearDeviceContentHash();
}
