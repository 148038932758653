export type ScannedParts =
    | {
          type: 'old-id';
          nationalIndentificationNumber: string;
          cardNumber: string;
      }
    | {
          type: 'new-eid';
          version: string;
          nationalIndentificationNumber: string;
          cardNumber: string;
          expiryDate: string;
          dateOfBirth: string;
      }
    | null;

export const getNationIdScanDetails = ({ barcode, countryCode = 'BE' }: { barcode: string; countryCode?: string }): ScannedParts => {
    switch (countryCode) {
        case 'BE':
            if (barcode.length === 20) {
                return {
                    type: 'old-id',
                    nationalIndentificationNumber: barcode.slice(0, 11),
                    cardNumber: barcode.slice(11, 20),
                };
            }
            if (barcode.indexOf(';') > -1 && barcode.split(';').length === 5) {
                const parts = barcode.split(';');
                if (parts.length === 5) {
                    return {
                        type: 'new-eid',
                        version: parts[0],
                        nationalIndentificationNumber: parts[1],
                        cardNumber: parts[2],
                        expiryDate: parts[3],
                        dateOfBirth: parts[4],
                    };
                }
            }
            return null;
        default:
            return null;
    }
};
