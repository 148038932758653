import { Pagination, SuggestProductCorrectionToMedipimPayload, ProvideProductFeedbackPayload, ProductFeedbackItemsPage } from '@/Domain';

import Endpoint from './Endpoint';

export default class ProductFeedbackEndpoint extends Endpoint {
    public async SuggestProductCorrectionToMedipim(payload: SuggestProductCorrectionToMedipimPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/SuggestProductCorrectionToMedipim',
            body: {
                commandName:
                    'App\\Domain\\WriteModel\\Product\\Correction\\SuggestProductCorrectionToMedipim\\SuggestProductCorrectionToMedipim',
                payload,
            },
        });
    }

    public async ProvideProductFeedback(payload: ProvideProductFeedbackPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/ProvideProductFeedback',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Product\\Correction\\ProvideProductFeedback\\ProvideProductFeedback',
                payload,
            },
        });
    }

    public async GetProductFeedbackItems(
        pagination: Pagination,
        filters?: {
            userId?: string;
            branchId?: string;
            companyId?: string;
            type?: string;
            status?: string;
        },
    ): Promise<ProductFeedbackItemsPage> {
        const response = await this.client.get({
            url: '/product/correction/overview/platform',
            params: {
                pagination,
                filters,
            },
        });

        this.guardResponseSchema('#/definitions/ProductFeedbackItemsPage', response);

        return response;
    }

    public async GetCompanyProductFeedbackItems(
        pagination: Pagination,
        filters?: {
            userId?: string;
            branchId?: string;
            type?: string;
            status?: string;
        },
    ): Promise<ProductFeedbackItemsPage> {
        const response = await this.client.get({
            url: '/product/correction/overview/company',
            params: {
                pagination,
                filters,
            },
        });

        this.guardResponseSchema('#/definitions/ProductFeedbackItemsPage', response);

        return response;
    }

    public async GetBranchProductFeedbackItems(
        pagination: Pagination,
        filters?: {
            userId?: string;
            type?: string;
            status?: string;
        },
    ): Promise<ProductFeedbackItemsPage> {
        const response = await this.client.get({
            url: '/product/correction/overview/branch',
            params: {
                pagination,
                filters,
            },
        });

        this.guardResponseSchema('#/definitions/ProductFeedbackItemsPage', response);

        return response;
    }

    public async GetUserProductFeedbackItems(
        pagination: Pagination,
        filters?: {
            type?: string;
            status?: string;
        },
    ): Promise<ProductFeedbackItemsPage> {
        const response = await this.client.get({
            url: '/product/correction/overview/user',
            params: {
                pagination,
                filters,
            },
        });

        this.guardResponseSchema('#/definitions/ProductFeedbackItemsPage', response);

        return response;
    }
}
