import {
    Wwks2StatusDialog,
    wwks2OutputResponse,
    wwks2OutputMessage,
    wwks2Article,
    wwks2ArticlePack
} from '../../../../Domain';
import Wwks2CommClient from '../../Wwks2CommClient';
import { DIALOG_TIMEOUTS } from '../Wwks2DefaultValues';


export default class Wwks2Output {

    private Wwks2CommClient: Wwks2CommClient;

    public constructor(Wwks2CommClient: Wwks2CommClient) {
        this.Wwks2CommClient = Wwks2CommClient;
    }

    public sendOutputRequest(barcodes: any[], dialogId: string | number | boolean = false): Promise<Wwks2StatusDialog> {
        return new Promise(resolve => {

            if(typeof dialogId === 'boolean'){
                dialogId = this.Wwks2CommClient.GetWwks2DialogId();
            }

            const barcodesMap = barcodes.map((barcode) => {
                if (!this.Wwks2CommClient.GetZone()) {
                    return {
                        '@': {
                            'ArticleId': barcode.barcode,
                            'Quantity': barcode.quantity
                        }
                    }
                } else {
                    return {
                        '@': {
                            'ArticleId': barcode.barcode,
                            'Quantity': barcode.quantity,
                            'StockLocationId': this.Wwks2CommClient.GetZone()
                        }
                    }
                }
            })

            const wwks = {
                'OutputRequest': {
                    '@': {
                        'Id': dialogId,
                        'Source': this.Wwks2CommClient.GetSourceId(),
                        'Destination': this.Wwks2CommClient.GetClientId(),
                    },
                    '#': {
                        'Details': {
                            '@': {
                                'OutputDestination': this.Wwks2CommClient.GetRobotExit(),
                                'Priority': 'High'
                            }
                        },
                        'Criteria': barcodesMap
                    }
                }
            }
            this.Wwks2CommClient.createAndSendDialog(wwks, 'Output', dialogId).then(status => {
                resolve(status);
            });

        });
    }

    public sendAndProcessOutputRequest(barcodes: any[], dialogId: string | number | boolean = false): Promise<Wwks2StatusDialog> {
        return new Promise(resolve => {
            this.sendOutputRequest(barcodes, dialogId)
                .then((data: Wwks2StatusDialog) => {
                    if (data.status) {
                        const timeoutTimer = setTimeout(() => {
                            let processStatusDialog = this.Wwks2CommClient.GetProcessedDialogData(data.dialogId);
                            if (typeof processStatusDialog !== 'boolean') {
                                if (processStatusDialog.typeDialog === 'Output') {
                                    processStatusDialog.status.cancel = true;
                                    this.Wwks2CommClient.SetProcessedDialogData(data.dialogId, processStatusDialog);
                                }
                            }
                        }, DIALOG_TIMEOUTS.Output);
                        this.Wwks2CommClient.getResponse(data.dialogId, 'Output')
                            .then((statusResponse) => {
                                if (statusResponse.status) {

                                    let processStatusDialog = this.Wwks2CommClient.GetProcessedDialogData(data.dialogId);
                                    if (typeof processStatusDialog !== 'boolean') {
                                        if (processStatusDialog.typeDialog === 'Output') {
                                            const outputResponse = processStatusDialog.data.response as wwks2OutputResponse;
                                            if (outputResponse.status === 'queued') {
                                                // next fase -> check Message
                                                this.processOutputMessage(data.dialogId)
                                                    .then((statusMessage) => {
                                                        clearTimeout(timeoutTimer);
                                                        resolve(statusMessage)
                                                    });
                                            } else if (outputResponse.status === 'rejected') {
                                                // do not check for Message because no Message will be send > send a negative status back
                                                resolve(statusResponse);
                                            }
                                        } else {
                                            statusResponse.msg = 'statusDialog (key = \'status_' + data.dialogId + '\') is no Output type';
                                            statusResponse.errorType = 'wrongStatusDialogType';
                                            statusResponse.status = false;
                                            resolve(statusResponse);
                                        }
                                    } else {
                                        statusResponse.msg = 'key \'status_' + data.dialogId + '\' not exist in statusDialog';
                                        statusResponse.errorType = 'noStatusDialog';
                                        statusResponse.status = false;
                                        resolve(statusResponse);
                                    }
                                } else {
                                    clearTimeout(timeoutTimer);
                                    resolve(statusResponse);
                                }
                            });
                    } else {
                        resolve(data);
                    }
                });
        });
    }

    public processOutputMessage(dialogId: string | number): Promise<Wwks2StatusDialog> {
        return new Promise((resolve) => {
            this.Wwks2CommClient.getMessage(dialogId, 'Output').then((result) => {
                if (result.status) {
                    let processStatusDialog = this.Wwks2CommClient.GetProcessedDialogData(result.dialogId);
                    if (typeof processStatusDialog !== 'boolean') {
                        if (processStatusDialog.typeDialog === 'Output') {
                            //const outputMessage = processStatusDialog.data.message as wwks2OutputMessage;

                            resolve(result);
                            /*if (outputMessage.status === 'completed') {
                                // robot has done with the delivery > resolve the status
                                resolve(result);

                            } else if (outputMessage.status === 'incomplete') {
                                // robot is still busy with delivery
                                // a part of the boxes are delivered
                                // robot is busy to deliver the other part
                                // check for the next message
                                setTimeout( () => {
                                    resolve(this.processOutputMessage(dialogId));
                                },500);
                            } else if (outputMessage.status === 'aborted') {
                                // robot has aborted the delivery > resolve the status result
                                resolve(result);
                            }*/
                        } else {
                            result.msg = 'statusDialog (key = \'status_' + result.dialogId + '\') is no Output type';
                            result.errorType = 'wrongStatusDialogType';
                            result.status = false;
                            resolve(result);
                        }
                    } else {
                        result.msg = 'key \'status_' + result.dialogId + '\' not exist in statusDialog';
                        result.errorType = 'noStatusDialog';
                        result.status = false;
                        resolve(result);
                    }
                } else {
                    resolve(result);
                }
            });
        });
    }

    public handleMessage(WWKS: any) {

        if (WWKS.OutputResponse) {

            const responseId = WWKS.OutputResponse['@attributes'].Id;
            let processStatusDialog = this.Wwks2CommClient.GetProcessedDialogData(responseId);
            if (typeof processStatusDialog !== 'boolean') {

                const responseData: wwks2OutputResponse = {
                    'clientId': 0,
                    'status': 'rejected',
                    'outputDestination': 0,
                    'articles': []

                }
                responseData.clientId = WWKS.OutputResponse['@attributes'].Source;
                if (typeof WWKS.OutputResponse.Details !== 'undefined') {

                    if (typeof WWKS.OutputResponse.Details['@attributes'].Status !== 'undefined') {
                        responseData.status = WWKS.OutputResponse.Details['@attributes'].Status.toLowerCase();
                    }

                    if (typeof WWKS.OutputResponse.Details['@attributes'].OutputDestination !== 'undefined') {
                        responseData.outputDestination = WWKS.OutputResponse.Details['@attributes'].OutputDestination;
                    }
                }

                if (typeof WWKS.OutputResponse.Criteria !== 'undefined') {

                    let _criterias: any[];

                    if (!Array.isArray(WWKS.OutputResponse.Criteria)) {
                        _criterias = [WWKS.OutputResponse.Criteria];
                    } else {
                        _criterias = WWKS.OutputResponse.Criteria;
                    }
                    _criterias.forEach((criteria) => {
                        if (typeof criteria['@attributes'] !== 'undefined') {
                            if (typeof criteria['@attributes'].ArticleId !== 'undefined' && typeof criteria['@attributes'].Quantity !== 'undefined') {

                                const _outputArticle: wwks2Article = {
                                    id: criteria['@attributes'].ArticleId,
                                    quantity: criteria['@attributes'].Quantity
                                }

                                if (typeof criteria['@attributes'].SubItemQuantity !== 'undefined') {
                                    _outputArticle.subItemQuantity = criteria['@attributes'].SubItemQuantity;
                                }
                                if (typeof criteria['@attributes'].MinimumExpiryDate !== 'undefined') {
                                    _outputArticle.minimumExpiryDate = criteria['@attributes'].MinimumExpiryDate;
                                }
                                if (typeof criteria['@attributes'].BatchNumber !== 'undefined') {
                                    _outputArticle.batchNumber = criteria['@attributes'].BatchNumber;
                                }
                                if (typeof criteria['@attributes'].SingleBatchNumber !== 'undefined') {
                                    _outputArticle.singleBatchNumber = criteria['@attributes'].SingleBatchNumber;
                                }
                                if (typeof criteria['@attributes'].ExternalId !== 'undefined') {
                                    _outputArticle.externalId = criteria['@attributes'].ExternalId;
                                }
                                if (typeof criteria['@attributes'].PackId !== 'undefined') {
                                    _outputArticle.packId = criteria['@attributes'].PackId;
                                }
                                if (typeof criteria['@attributes'].StockLocationId !== 'undefined') {
                                    _outputArticle.stockLocationId = criteria['@attributes'].StockLocationId;
                                }
                                if (typeof criteria['@attributes'].MachineLocation !== 'undefined') {
                                    _outputArticle.machineLocation = criteria['@attributes'].MachineLocation;
                                }

                                responseData.articles.push(_outputArticle);
                            }
                        }
                    });
                }

                if (processStatusDialog.typeDialog === 'Output') {
                    processStatusDialog.status.response = true;
                    processStatusDialog.timestamps.response = Date.now();
                    processStatusDialog.data.response = responseData;
                    this.Wwks2CommClient.SetProcessedDialogData(responseId, processStatusDialog)
                }
            }
        }

        if (WWKS.OutputMessage) {
            
            let messageData: wwks2OutputMessage = {
                'clientId': 0,
                'status': 'aborted',
                'outputDestination': 0,
                'articles': []
            }
            
            let responseId = WWKS.OutputMessage['@attributes'].Id;
            if ('1' === responseId) {
                let articles: any[]
                if (!Array.isArray(WWKS.OutputMessage.Article)) {
                    articles = [WWKS.OutputMessage.Article];
                } else {
                    articles = WWKS.OutputMessage.Article
                }
                let article: any = articles[0];

                let articlePacks: any[]
                if (!Array.isArray(article.Pack)) {
                    articlePacks = [article.Pack]
                } else {
                    articlePacks = article.Pack
                }
                let articlePack: any = articlePacks[0]
                
                responseId = articlePack['@attributes'].DeliveryNumber;
            }
               
            let processStatusDialog = this.Wwks2CommClient.GetProcessedDialogData(responseId);
            if (typeof processStatusDialog !== 'boolean') {
                // check if responseMessage already exist > if so > load
                if (processStatusDialog.typeDialog === 'Output') {
                    if (processStatusDialog.data.message !== undefined) {
                        messageData = processStatusDialog.data.message as wwks2OutputMessage;
                    }
                }
            } else {
                processStatusDialog = {
                    dialogId: responseId,
                    clientId: WWKS.OutputMessage['@attributes'].Source,
                    typeDialog: 'Output',
                    clearStatus: false,
                    isDialogType: 'message',
                    status: {
                        request: false,
                        response: false,
                        message: false,
                        cancel: false
                    },
                    data: {
                        request: undefined,
                        response: undefined,
                        message: undefined
                    },
                    timestamps: {
                        request: 0,
                        response: 0,
                        message: 0
                    },
                    createdTimestamp: 0
                }
            }

            messageData.clientId = WWKS.OutputMessage['@attributes'].Source;
            if (typeof WWKS.OutputMessage.Details !== 'undefined') {

                if (typeof WWKS.OutputMessage.Details['@attributes'].Status !== 'undefined') {
                    messageData.status = WWKS.OutputMessage.Details['@attributes'].Status.toLowerCase();
                }
                if (typeof WWKS.OutputMessage.Details['@attributes'].OutputDestination !== 'undefined') {
                    messageData.outputDestination = WWKS.OutputMessage.Details['@attributes'].OutputDestination;
                }
            }

            if (typeof WWKS.OutputMessage.Article !== 'undefined') {

                let _articles: any[];

                if (!Array.isArray(WWKS.OutputMessage.Article)) {
                    _articles = [WWKS.OutputMessage.Article];
                } else {
                    _articles = WWKS.OutputMessage.Article;
                }
                _articles.forEach((article) => {
                    if (typeof article['@attributes'] !== 'undefined') {
                        if (typeof article['@attributes'].Id !== 'undefined') {
                            const articlesPacks: wwks2ArticlePack[] = [];
                            if (typeof article.Pack !== 'undefined') {

                                let _articlePacks: any[];
                                if (!Array.isArray(article.Pack)) {
                                    _articlePacks = [article.Pack];
                                } else {
                                    _articlePacks = article.Pack;
                                }

                                _articlePacks.forEach(articlePack => {
                                    if (typeof articlePack['@attributes'].Id !== 'undefined') {
                                        const _articlePack: wwks2ArticlePack = {
                                            id: articlePack['@attributes'].Id
                                        }
                                        // below are optionals
                                        if (typeof articlePack['@attributes'].ScanCode !== 'undefined') {
                                            _articlePack.scanCode = articlePack['@attributes'].ScanCode;
                                        }
                                        if (typeof articlePack['@attributes'].StockLocationId !== 'undefined') {
                                            _articlePack.stockLocationId = articlePack['@attributes'].StockLocationId;
                                        }
                                        if (typeof articlePack['@attributes'].DeliveryNumber !== 'undefined') {
                                            _articlePack.deliveryNumber = articlePack['@attributes'].DeliveryNumber;                                        
                                        }
                                        if (typeof articlePack['@attributes'].BatchNumber !== 'undefined') {
                                            _articlePack.batchNumber = articlePack['@attributes'].BatchNumber;
                                        }
                                        if (typeof articlePack['@attributes'].ExternalId !== 'undefined') {
                                            _articlePack.externalId = articlePack['@attributes'].ExternalId;
                                        }
                                        if (typeof articlePack['@attributes'].ExpiryDate !== 'undefined') {
                                            _articlePack.expiryDate = articlePack['@attributes'].ExpiryDate;
                                        }
                                        if (typeof articlePack['@attributes'].StockInDate !== 'undefined') {
                                            _articlePack.stockInDate = articlePack['@attributes'].StockInDate;
                                        }
                                        if (typeof articlePack['@attributes'].SubItemQuantity !== 'undefined') {
                                            _articlePack.subItemQuantity = articlePack['@attributes'].SubItemQuantity;
                                        }
                                        if (typeof articlePack['@attributes'].Depth !== 'undefined') {
                                            _articlePack.depth = articlePack['@attributes'].Depth;
                                        }
                                        if (typeof articlePack['@attributes'].Width !== 'undefined') {
                                            _articlePack.width = articlePack['@attributes'].Width;
                                        }
                                        if (typeof articlePack['@attributes'].Height !== 'undefined') {
                                            _articlePack.height = articlePack['@attributes'].Height;
                                        }
                                        if (typeof articlePack['@attributes'].IsInFridge !== 'undefined') {
                                            _articlePack.isInFridge = articlePack['@attributes'].IsInFridge;
                                        }
                                        if (typeof articlePack['@attributes'].Shape !== 'undefined') {
                                            _articlePack.shape = articlePack['@attributes'].Shape;
                                        }
                                        if (typeof articlePack['@attributes'].BoxNumber !== 'undefined') {
                                            _articlePack.boxNumber = articlePack['@attributes'].BoxNumber;
                                        }
                                        if (typeof articlePack['@attributes'].OutputDestination !== 'undefined') {
                                            _articlePack.outputDestination = articlePack['@attributes'].OutputDestination;
                                        }
                                        if (typeof articlePack['@attributes'].OutputPoint !== 'undefined') {
                                            _articlePack.outputPoint = articlePack['@attributes'].OutputPoint;
                                        }
                                        if (typeof articlePack['@attributes'].MachineLocation !== 'undefined') {
                                            _articlePack.machineLocation = articlePack['@attributes'].MachineLocation;
                                        }
                                        articlesPacks.push(_articlePack);
                                    }
                                });
                            }

                            const articleItem: wwks2Article = {
                                id: article['@attributes'].Id,
                                packs: []
                            }
                            articleItem.packs = articlesPacks;
                            messageData.articles.push(articleItem);
                        }
                    }
                });

            }

            if (processStatusDialog.typeDialog === 'Output') {
                processStatusDialog.status.message = true;
                processStatusDialog.timestamps.message = Date.now();
                processStatusDialog.data.message = messageData;
                this.Wwks2CommClient.SetProcessedDialogData(responseId, processStatusDialog)
            }
        }

    }
}