import * as React from 'react';

import ControlledCheckboxList, { ControlledCheckboxListPropsWithValue } from './ControlledCheckboxList';
import UncontrolledCheckboxList, { UncontrolledCheckboxListProps } from './UncontrolledCheckboxList';

export type CheckboxListProps = ControlledCheckboxListPropsWithValue | UncontrolledCheckboxListProps;

class CheckboxList extends React.PureComponent<CheckboxListProps> {
    render() {
        if (this.isControlled(this.props)) {
            return <ControlledCheckboxList {...this.props} />;
        }

        return <UncontrolledCheckboxList {...this.props} />;
    }

    private isControlled(props: CheckboxListProps): props is ControlledCheckboxListPropsWithValue {
        return (props as ControlledCheckboxListPropsWithValue).hasOwnProperty('value');
    }
}

export default React.forwardRef<HTMLSelectElement, CheckboxListProps>((props, ref) => {
    return (
        <CheckboxList
            forwardRef={ref}
            {...props}
        />
    );
});
