import {
    Localisation,
    LocalisationsPage,
    CreateLocalisationPayload,
    UpdateLocalisationPayload,
    Pagination,
    Country,
    APIAvailableCountries,
    AvailableCountries,
} from '@/Domain';

import Endpoint from './Endpoint';

export default class LocalisationEndpoint extends Endpoint {
    public async GetLocalisations(pagination: Pagination): Promise<LocalisationsPage> {
        const response = await this.client.get({
            url: '/localisation/overview',
            params: {
                pagination,
            },
        });

        this.guardResponseSchema('#/definitions/LocalisationsPage', response);

        return response;
    }

    public async GetLocalisationDetails(country: Country): Promise<Localisation> {
        const response = await this.client.get({
            url: '/localisation/' + country + '/details',
        });

        this.guardResponseSchema('#/definitions/Localisation', response);

        return response;
    }

    public async CreateLocalisation(payload: CreateLocalisationPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/CreateLocalisation',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Localisation\\CreateLocalisation\\CreateLocalisation',
                payload,
            },
        });
    }

    public async UpdateLocalisation(payload: UpdateLocalisationPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/UpdateLocalisation',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Localisation\\UpdateLocalisation\\UpdateLocalisation',
                payload,
            },
        });
    }

    public async GetAvailableCountries(): Promise<AvailableCountries> {
        const response: APIAvailableCountries = await this.client.get({
            url: '/availableCountry/overview',
            noAuthHeader: true,
        });

        this.guardResponseSchema('#/definitions/APIAvailableCountries', response);

        const countries: AvailableCountries = {};

        for (const country of response.items) {
            countries[country.country] = country;
        }

        return countries;
    }
}
