import * as React from 'react';
import { useSelector } from 'react-redux';

import { Domain } from 'api';
import { config } from 'config';
import {
    StateProvider as CustomPageLivePreviewStateProvider,
    LivePreview as CustomPageLivePreview,
    PageLayout as CustomPageLayout,
    Frame as CustomPageFrame,
} from 'custom-page';
import { I18n, TVScreen, mapProductToSlideshowProduct, useGettext, deviceBackendApi } from 'data-store';
import { getPreloadedVideoDataURL } from 'preloaders';
import { StateProvider as ProductWallLivePreviewStateProvider, LivePreview as ProductWallLivePreview, Stock } from 'product-wall';
import { PharmacyOnDuty } from 'utils';

import findHiddenSlideContents from '@/utils/findHiddenSlideContents';
import findSlideContents from '@/utils/findSlideContents';

import SlideshowViewerSlidePlaceholder from './SlideshowViewerSlidePlaceholder';

import styles from './SlideshowViewer.scss';

interface IProps {
    className?: string;
    slideId: string;
    isHidden?: boolean;
    isActive?: boolean;
    isNext?: boolean;
    isPrev?: boolean;
    usePreviewImageForContents?: boolean;
    onProductClick: (productDetails: Domain.SelectedProductDetails) => void;
    onHiddenSlideClick: (slideId: string) => void;
    loopVideo: boolean;
    renderVideo: (videoProps: React.VideoHTMLAttributes<any>) => React.ReactElement;
}

const controlVideoPlayback = (element: HTMLElement | undefined, isActive: boolean | undefined) => {
    if (!element) {
        return;
    }

    const videosOnSlide: NodeListOf<HTMLVideoElement> = element.querySelectorAll('video');
    videosOnSlide.forEach(async video => {
        const onVideoReady = async () => {
            if (!video) {
                return;
            }

            if (isActive) {
                if (video.paused) {
                    try {
                        await video.play();
                    } catch (e) {
                        // ignore error
                    }
                }
            } else {
                if (!video.paused) {
                    video.pause();
                    video.currentTime = 0;
                }
            }
        };

        video.oncanplay = onVideoReady;
        if (video.readyState > 3) {
            onVideoReady();
        }
    });
};

export default function SlideshowViewerSlide(props: IProps) {
    const device = useSelector(TVScreen.selectors.selectDevice);
    const locale = useSelector(I18n.selectCurrentLocale);
    const timeline = useSelector(TVScreen.selectors.selectTimeline);
    const timelineSlides = useSelector(TVScreen.selectors.selectSlides);
    const hiddenSlides = useSelector(TVScreen.selectors.selectHiddenSlides);
    const pharmaciesOnDuty = useSelector(TVScreen.slideshowState.selectPharmaciesOnDuty);
    const emergencyAlerts = useSelector(TVScreen.slideshowState.selectEmergencyAlerts);
    const branch = useSelector(TVScreen.selectors.selectBranch);
    const stock = useSelector(TVScreen.selectors.selectStock);
    const [enableTransition, setEnableTransition] = React.useState(false);

    React.useEffect(() => {
        setTimeout(() => {
            setEnableTransition(props.isActive || props.isNext || props.isPrev || false);
        }, 10);
    }, [props.isActive, props.isNext, props.isPrev]);

    if (!device) {
        return null;
    }

    let slide: Domain.SlideshowSlide | Domain.DeviceHiddenSlide | undefined = timeline
        ? timeline.content.find(searchedSlide => searchedSlide.slideId === props.slideId)
        : undefined;
    if (device.hiddenSlides && props.isHidden) {
        slide = device.hiddenSlides.find(searchedSlide => searchedSlide.slideId === props.slideId);
    }

    let className = (props.className || '') + ' ' + styles.SlideshowViewerSlide;
    if (props.isActive) {
        className += ' ' + styles.SlideshowViewerSlideActive;
    } else if (props.isNext) {
        className += ' ' + styles.SlideshowViewerSlideNext;
    } else if (props.isPrev) {
        className += ' ' + styles.SlideshowViewerSlidePrev;
    }

    if (enableTransition) {
        className += ' ' + styles.SlideshowViewerSlideEnableTransition;
    }

    const placeholder = <SlideshowViewerSlidePlaceholder className={className} />;

    if (!slide || !timelineSlides || (!props.isActive && !props.isPrev && !props.isNext)) {
        return placeholder;
    }

    let slideContents: TVScreen.types.Slide | undefined;
    if (props.isHidden) {
        slideContents = findHiddenSlideContents(slide as Domain.DeviceHiddenSlide, hiddenSlides);
    } else {
        slideContents = findSlideContents(slide as Domain.SlideshowSlide, timelineSlides);
    }

    if (!slideContents) {
        return placeholder;
    }

    let isSharedWithMe = true;
    if (
        device &&
        (TVScreen.types.isProductWallSlideContents(slideContents) || TVScreen.types.isCustomPageSlideContents(slideContents)) &&
        device.companyId === slideContents.companyId &&
        device.branchId === slideContents.branchId
    ) {
        isSharedWithMe = false;
    }

    return (
        <BaseSlideshowViewerSlide
            {...props}
            className={className}
            data-test-id={props.isActive ? 'active-slide' : undefined}
            slide={slide}
            slideContents={slideContents}
            isSharedWithMe={isSharedWithMe}
            contentVersion={device.contentVersion || '0'}
            isInteractiveScreen={device.type === 'interactive-tv-screen'}
            pharmaciesOnDuty={pharmaciesOnDuty}
            emergencyAlerts={emergencyAlerts}
            openingHours={branch?.openingHours || null}
            locale={locale}
            screenResolution={device.screenResolution}
            enableStockDisplay={config.featureFlags.enableProductWallStockDisplayForBranches.includes(device.branchId)}
            stock={stock}
        />
    );
}

function BaseSlideshowViewerSlide({
    className,
    slide,
    slideId,
    contentVersion,
    slideContents,
    isSharedWithMe,
    isInteractiveScreen,
    locale,
    loopVideo,
    renderVideo,
    isActive,
    isNext,
    isPrev,
    usePreviewImageForContents,
    screenResolution,
    enableStockDisplay,
    stock,
    openingHours,
    pharmaciesOnDuty,
    emergencyAlerts,
    onProductClick,
    onHiddenSlideClick,
    ...rest
}: IProps & {
    pharmaciesOnDuty: PharmacyOnDuty[];
    emergencyAlerts: Domain.BeAlert[];
    openingHours: Domain.OpeningHours | null;
    slide: Domain.SlideshowSlide | Domain.DeviceHiddenSlide;
    screenResolution: Domain.DeviceScreenResolution;
    slideContents: TVScreen.types.Slide;
    contentVersion: string | number;
    isSharedWithMe: boolean;
    isInteractiveScreen: boolean;
    locale: Domain.Locale;
    enableStockDisplay: boolean;
    renderVideo: (videoProps: React.VideoHTMLAttributes<any>) => React.ReactElement;
    stock: Stock;
}) {
    const { gettext } = useGettext();
    const selfRef = React.useRef<HTMLDivElement>();

    const passThroughCacheProxy = (url: string) => {
        if (!url || !deviceBackendApi.cacheIsAvailable()) {
            return url;
        }

        return deviceBackendApi.buildCachedItemUrl(url);
    };

    const getMediaItemURL = (mediaItemId: string | undefined) => {
        return mediaItemId ? passThroughCacheProxy(`${config.apiBaseUrl}/media-item/${mediaItemId}/preview`) : '';
    };
    const getVideoMediaItemPreviewURL = (mediaItemId: string | undefined) => {
        return mediaItemId ? passThroughCacheProxy(`${config.apiBaseUrl}/media-item/${mediaItemId}/preview/450x450`) : '';
    };

    React.useEffect(() => {
        const timeout0 = setTimeout(() => {
            controlVideoPlayback(selfRef.current, isActive);
        }, 0);

        const timeout1 = setTimeout(() => {
            controlVideoPlayback(selfRef.current, isActive);
        }, 500);

        const timeout2 = setTimeout(() => {
            controlVideoPlayback(selfRef.current, isActive);
        }, 1000);

        const timeout3 = setTimeout(() => {
            controlVideoPlayback(selfRef.current, isActive);
        }, 1500);

        const timeout4 = setTimeout(() => {
            controlVideoPlayback(selfRef.current, isActive);
        }, 2000);

        return () => {
            clearTimeout(timeout0);
            clearTimeout(timeout1);
            clearTimeout(timeout2);
            clearTimeout(timeout3);
            clearTimeout(timeout4);
        };
    }, [selfRef.current, isActive]);

    if (TVScreen.types.isProductWallSlideContents(slideContents) || TVScreen.types.isCustomPageSlideContents(slideContents)) {
        let availableProducts = slideContents.products.map(product => mapProductToSlideshowProduct(product, slideContents.locale));
        availableProducts = availableProducts.filter(Boolean);

        if (slide.type === 'productWall') {
            return (
                <div
                    ref={selfRef as any}
                    className={className}
                    {...rest}
                >
                    <ProductWallLivePreviewStateProvider
                        layout={slideContents.content as any}
                        stock={stock}
                        availableProducts={availableProducts as any}
                        screenResolution={screenResolution}
                    >
                        <ProductWallLivePreview
                            usePreviewImageForContents={
                                usePreviewImageForContents && !isSharedWithMe && slideContents.preview
                                    ? passThroughCacheProxy(slideContents.preview)
                                    : false
                            }
                            proxyImageURL={passThroughCacheProxy}
                            getMediaItemURL={getMediaItemURL}
                            getVideoDataURL={!deviceBackendApi.cacheIsAvailable() ? getPreloadedVideoDataURL : undefined}
                            getVideoMediaItemPreviewURL={getVideoMediaItemPreviewURL}
                            autoPlayVideos={false}
                            disableVideoPosters={true}
                            loopVideos={loopVideo}
                            renderVideo={renderVideo}
                            onProductClick={productId => {
                                if (!isInteractiveScreen) {
                                    return;
                                }

                                const productDetails = slideContents.products.find(
                                    searchedProduct => searchedProduct.productId === productId,
                                );

                                if (productDetails) {
                                    onProductClick(productDetails);
                                }
                            }}
                            locale={locale}
                            enableStockDisplay={enableStockDisplay}
                        />
                    </ProductWallLivePreviewStateProvider>
                </div>
            );
        } else if (slide.type === 'customPage') {
            const includesPharmacistOnDutyFrame = (slideContents.content as CustomPageLayout).frames.find(
                (frame: CustomPageFrame) => frame.type === 'pharmacistOnDuty',
            );
            const includesEmergencyAlerts = (slideContents.content as CustomPageLayout).frames.find(
                (frame: CustomPageFrame) => frame.type === 'emergencyAlert',
            );
            const includesOpeningHoursFrame = (slideContents.content as CustomPageLayout).frames.find(
                (frame: CustomPageFrame) => frame.type === 'openingHours',
            );
            return (
                <div
                    ref={selfRef as any}
                    className={className}
                    {...rest}
                >
                    <CustomPageLivePreviewStateProvider
                        layout={slideContents.content as any}
                        availableProducts={availableProducts as any}
                        screenResolution={screenResolution}
                    >
                        <CustomPageLivePreview
                            gettext={gettext}
                            usePreviewImageForContents={
                                usePreviewImageForContents &&
                                !includesPharmacistOnDutyFrame &&
                                !includesOpeningHoursFrame &&
                                !includesEmergencyAlerts &&
                                !isSharedWithMe &&
                                slideContents.preview
                                    ? passThroughCacheProxy(slideContents.preview)
                                    : false
                            }
                            proxyImageURL={passThroughCacheProxy}
                            getMediaItemURL={getMediaItemURL}
                            getVideoDataURL={!deviceBackendApi.cacheIsAvailable() ? getPreloadedVideoDataURL : undefined}
                            getVideoMediaItemPreviewURL={getVideoMediaItemPreviewURL}
                            autoPlayVideos={false}
                            disableVideoPosters={true}
                            loopVideos={loopVideo}
                            renderVideo={renderVideo}
                            onProductClick={productId => {
                                if (!isInteractiveScreen) {
                                    return;
                                }

                                const productDetails = slideContents.products.find(
                                    searchedProduct => searchedProduct.productId === productId,
                                );

                                if (productDetails) {
                                    onProductClick(productDetails);
                                }
                            }}
                            onHiddenSlideClick={slideId => {
                                if (!isInteractiveScreen) {
                                    return;
                                }

                                onHiddenSlideClick(slideId);
                            }}
                            getPharmaciesOnDuty={numPharmacists => {
                                return pharmaciesOnDuty.slice(0, numPharmacists);
                            }}
                            getEmergencyAlerts={() => emergencyAlerts}
                            getOpeningHours={() => openingHours}
                            locale={locale}
                        />
                    </CustomPageLivePreviewStateProvider>
                </div>
            );
        }
    } else if (slide.type === 'image' && TVScreen.types.isMediaItemSlideContents(slideContents)) {
        return (
            <div
                ref={selfRef as any}
                className={className + ' SlideshowViewerImage'}
                {...rest}
            >
                <img
                    src={getMediaItemURL(slideContents.mediaItemId)}
                    alt=""
                />
            </div>
        );
    } else if (slide.type === 'video' && TVScreen.types.isMediaItemSlideContents(slideContents)) {
        const videoURL = getMediaItemURL(slideContents.mediaItemId);
        const src = deviceBackendApi.cacheIsAvailable() ? videoURL : getPreloadedVideoDataURL(videoURL) || '';

        return (
            <div
                ref={selfRef as any}
                className={className}
                {...rest}
            >
                {renderVideo({
                    width: '100%',
                    height: '100%',
                    autoPlay: false,
                    loop: loopVideo,
                    src,
                })}
            </div>
        );
    }

    return <SlideshowViewerSlidePlaceholder className={className} />;
}
