import * as React from 'react';

import moment from 'moment';

import { Domain } from 'api';
import { CanvasFrame } from 'editor-canvas';
import { HTMLEditorView } from 'ui-components';

import type { EmergencyAlert } from '../types';

export default function EmergencyAlertFrame(props: {
    frame: CanvasFrame & EmergencyAlert;
    getEmergencyAlerts: (locale: string) => Domain.BeAlert[];
    style?: React.CSSProperties;
    locale: string;
    gettext: (text: string) => string;
}) {
    const { style, locale, getEmergencyAlerts, gettext } = props;
    const alerts = getEmergencyAlerts(locale);
    let html = '';
    for (const alert of alerts) {
        const pubDate = moment(alert.pubDate);
        const now = moment();

        const minutesAgo = now.diff(pubDate, 'minutes');
        html += `
        <div style="background-color: #F6F8F9; padding: 16px; border-radius: 6px; margin-bottom: 4px;">
            <img src="/static/be-alert/be-alert-logo.svg" />
            <div style="
                color: #222932;
                font-size: 18px;
                font-weight: 500;
                line-height: 26px;
                margin-top: 4px;
            ">${alert.title}</div>
           
            <div style="
                color: #3F5165;
                font-size: 14px;
                line-height: 22px;
            ">${alert.description || ''}</div>
             <div style="
                color: #627D95;
                font-size: 12px;
                line-height: 20px;
                font-weight: 300;
            ">${minutesAgo} ${gettext('minutes ago')}</div>
        </div>
        `;
    }

    return (
        <HTMLEditorView
            style={{
                ...style,
            }}
            value={html}
        />
    );
}
