import { createSelector } from '@reduxjs/toolkit';

import {
    selectIsLoggedIn,
    selectIsLoggedInAsPlatformAdministrator,
    selectIsLoggedInAsBranchManager,
    selectIsLoggedInAsCompanyManager,
    selectIsLoggedInAsSupportAgent,
    selectIsLoggedInAsReseller,
} from './selectors';

export const anyAuthorization = () => true;

export const isSignedIn = createSelector(selectIsLoggedIn, isLoggedIn => isLoggedIn);

export const isPlatformAdministrator = createSelector(selectIsLoggedInAsPlatformAdministrator, isLoggedIn => isLoggedIn);

export const isSupportAgent = selectIsLoggedInAsSupportAgent;

export const isReseller = selectIsLoggedInAsReseller;

export const isSuperUser = createSelector(
    [selectIsLoggedInAsPlatformAdministrator, selectIsLoggedInAsSupportAgent, selectIsLoggedInAsReseller],
    (isPlatformAdmin, isSupportAgent, isReseller) => {
        return isPlatformAdmin || isSupportAgent || isReseller;
    },
);

export const isCompanyManager = selectIsLoggedInAsCompanyManager;

export const isBranchManager = selectIsLoggedInAsBranchManager;
