export type wwks2ObjectType = {
    [key: string]: any;
}

export type Wwks2ProcessStatusDialogs = {
    [key: string]: Wwks2ProcessStatus
}

export interface Wwks2StatusDialog {
    status: boolean
    msg: string
    dialogId: string | number
    dialogType: string
    isDialogType: 'request' | 'response' | 'message'
    canceled?: boolean
    errorType?: 'noResponseFound' | 'noRequestFound' | 'noMessageFound' | 'checkStatusDialogCanceled' | 'noStatusDialog' | 'wrongStatusDialogType' | 'noWebSocketConnection' | 'responseDialogNotFromClient' | 'clientDoNotSupportDialog' | 'none'
}

export interface Wwks2ResultData {
    status: Wwks2StatusDialog
    data: wwks2HelloResponse | wwks2ArticlePriceResponse | wwks2ArticleInfoResponse | wwks2OutputResult | wwks2StockInfoResponse  | wwks2ShoppingCartResponse | wwks2ShoppingCartUpdateResponse | wwks2ShoppingCartUpdateMessage | wwks2ShoppingCartResult | wwks2StatusResponse | boolean | string | object
}

export interface wwks2HelloResponse {
    clientId: string | number
    capabilities: string[]
}

export interface wwks2ArticlePriceResponse {
    clientId: string | number
    articles: wwks2Article[]
}

export interface wwks2ArticleInfoResponse {
    clientId: string | number
    articles: wwks2Article[]
}

export interface wwks2OutputResponse {
    clientId: string | number
    status: 'completed' | 'incomplete' | 'aborted' | 'queued' | 'rejected'
    articles: wwks2Article[]
    outputDestination: number
}

export interface wwks2OutputMessage {
    clientId: string | number
    status: 'completed' | 'incomplete' | 'aborted' | 'queued' | 'rejected'
    articles: wwks2Article[]
    outputDestination: number
}

export interface wwks2OutputResult {
    clientId: string | number
    status: 'completed' | 'incomplete' | 'aborted' | 'queued' | 'rejected'
    articles: wwks2Article[]
    outputDestination: number
}

export interface wwks2ArticlePriceItemInformation {
    category: string
    price: number
    description?: string
    quantity?: number
    basePrice?: number
    basePriceUnit?: string
    vat?: number
}

export interface wwks2StockInfoResponse {
    clientId: string | number
    articles: wwks2Article[]
    reservationId?: string
}

export interface wwks2StockInfoMessage {
    clientId: string | number
    dialogId: string | number
    articles: wwks2Article[]
}

export interface wwks2Article {
    id: string
    quantity?: string
    subItemQuantity?: string
    minimumExpiryDate?: string
    batchNumber?: string
    singleBatchNumber?: string
    externalId?: string
    packId?: string
    stockLocationId?: string
    machineLocation?: string
    name?: string
    dosageForm?: string
    packingUnit?: string
    maxSubItemQuantity?: string
    packs?: wwks2ArticlePack[]
    availablePacks?: wwks2ArticlePack[]
    notAvailablePacks?: wwks2ArticlePack[]
    priceInformations?: wwks2ArticlePriceItemInformation[]
    tags?: string[]
    crossSellingArticles?: string[]
    alternativeArticles?: string[]
    alternativePackSizeArticles?: string[]
}

export interface wwks2ArticlePack {
    id: string,
    state?: string
    stockLocationId?: string
    reserved?: string
    scanCode?: string
    deliveryNumber?: string
    batchNumber?: string
    externalId?: string
    expiryDate?: string
    stockInDate?: string
    subItemQuantity?: string
    depth?: string
    height?: string
    width?: string
    isInFridge?: boolean
    shape?: string
    boxNumber?: string
    outputDestination?: number
    outputPoint?: string
    machineLocation?: string
}

export interface wwks2ShoppingCart {
    id: string | number
    articles?: wwks2ShoppingCartItem[]
    paymentInfo?: wwks2ShoppingCartPayment
    status: Wwks2ShoppingCartStatus
}

export interface wwks2ShoppingCartItem {
    id: string | number
    price?: string
    orderedQuantity?: number
    dispensedQuantity?: number
    paidQuantity?: number
    currency?: string
}

export interface wwks2ShoppingCartPayment {
    status: string
    type: string
    transactionId: string
    details?: any
}

export interface wwks2ShoppingCartResponse {
    clientId: string | number
    dialogId: string | number
    shoppingcart: wwks2ShoppingCart
    requestShoppingCartId?: string | number
    shoppingCartType?: string
}


export interface wwks2ShoppingCartUpdateResponse {
    clientId: string | number
    dialogId: string | number
    shoppingcart: wwks2ShoppingCart
    updateResultStatus: string
}

export interface wwks2ShoppingCartUpdateMessage {
    clientId: string | number
    dialogId: string | number
    shoppingcart: wwks2ShoppingCart
    updateResultStatus?: string
}

export interface wwks2ShoppingCartResult {
    clientId: string | number
    dialogId: string | number
    shoppingcart: wwks2ShoppingCart
    updateResultStatus?: string
    requestShoppingCartId?: string | number
    shoppingCartType?: string
}

export interface wwks2StatusResponse {
    clientId: string | number
    dialogId: string | number
    state: string
    stateText?: string
    storageSystem?: { [key: string]: wwks2StatusComponent }
    boxSystem?: { [key: string]: wwks2StatusComponent }
}

export interface wwks2StatusRequest {
    clientId: string | number
    dialogId: string | number
    includeDetails: boolean
}

export interface wwks2StatusComponent {
    'state': string
    'description': string
    'type': string
    'stateText'?: string
}

export interface wwks2KeepAliveResponse {
    clientId: string | number
}

export interface Wwks2ProcessStatus {
    dialogId: string | number
    clientId: string | number
    reservationId?: string
    requestShoppingCartId?: string | number
    shoppingCartType?: string
    typeDialog: string
    clearStatus: boolean
    isDialogType: 'request' | 'response' | 'message'
    status: {
        request: boolean
        response: boolean
        message: boolean
        cancel: boolean
    };
    data: {
        request: object | string | undefined
        response: wwks2HelloResponse | wwks2KeepAliveResponse | wwks2ArticlePriceResponse | wwks2ArticleInfoResponse | wwks2StockInfoResponse | wwks2OutputResponse | wwks2ShoppingCartResponse | wwks2ShoppingCartUpdateResponse | wwks2StatusResponse | string | undefined
        message: wwks2OutputMessage | wwks2StockInfoMessage | wwks2ShoppingCartUpdateMessage | object | string | undefined
    };
    timestamps: {
        request: number
        response: number
        message: number
    }
    createdTimestamp: number
}

export interface wwks2MTRemoteLogRequest {
    clientId: string | number
    logs: Wwks2MTRemoteLine[]
}

export interface Wwks2MTRemoteLine {
    seq: string
    senderType: string
    senderName: string
    level: string
    logtime: string
    message: string
}

export enum Wwks2ShoppingCartStatus {
    Active = 'Active',
    NotActive = 'NotActive',
    Discarded = 'Discarded',
    Finished = 'Finished'
}

export class Wwks2ProcessStatusDialog {
    dialogId: string | number
    clientId: string | number
    reservationId?: string
    requestShoppingCartId?: string | number
    shoppingCartType?: string
    typeDialog: string
    clearStatus: boolean
    isDialogType: 'request' | 'response' | 'message'
    status: {
        request: boolean
        response: boolean
        message: boolean
        cancel: boolean
    };
    data: {
        request: object | string | undefined
        response: wwks2HelloResponse | wwks2KeepAliveResponse | wwks2ArticlePriceResponse | wwks2ArticleInfoResponse | wwks2StockInfoResponse | wwks2OutputResponse | wwks2ShoppingCartResponse | wwks2ShoppingCartUpdateResponse | wwks2StatusResponse | string | undefined
        message: wwks2OutputMessage | wwks2StockInfoMessage | wwks2ShoppingCartUpdateMessage | object | string | undefined
    };
    timestamps: {
        request: number
        response: number
        message: number
    }
    createdTimestamp: number

    public constructor() {
        this.dialogId = 0;
        this.clientId = 0;
        this.typeDialog = '';
        this.clearStatus = false;
        this.isDialogType = 'request';
        this.createdTimestamp = Date.now();
        this.status = {
            request: false,
            response: false,
            message: false,
            cancel: false
        }
        this.data = {
            request: undefined,
            response: undefined,
            message: undefined
        }
        this.timestamps = {
            request: 0,
            response: 0,
            message: 0
        }
    }
}

export const SOURCECOMPATIBILITIES: string[] = [
    'Hello',
    'KeepAlive',
    'Status',
    'StockLocationInfo',
    'StockInfo',
    'Output',
    'TaskInfo'
];

export const CLIENTCOMPATIBILITIES: string[] = [
    'Hello'
];