import { Domain } from 'api';

import { webshopPrescriptionApi } from '@/api';
import { makeActions, makeReducer, makeReducerActions, makeSelectors } from '@/makeDetailsState';

export const selectors = makeSelectors<Domain.WebshopPrescription>({
    getState: rootState => rootState.webshopPrescription.details,
});

export const reducerActions = makeReducerActions<Domain.WebshopPrescription>({
    reducerPrefix: '@webshopPrescription/details',
});

export const actions = makeActions<Domain.WebshopPrescription>({
    loadApi: options => webshopPrescriptionApi.GetWebshopPrescriptionDetails(options.urlParams.prescriptionId),
    reducerActions,
    selectors,
});

export const detailsReducer = makeReducer<Domain.WebshopPrescription>({
    reducerActions,
});
