import * as React from 'react';
import { useRouteError } from 'react-router-dom';

import AppCore from '@/Infrastructure/App/AppCore';
import Error from '@/Pages/Errors/Error';

export default function RouteErrorBoundary() {
    const error = useRouteError() as Error | null;
    if (error) {
        return (
            <AppCore>
                <Error error={error} />
            </AppCore>
        );
    }
}
