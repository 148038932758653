import * as React from 'react';

import { store, Actions } from '../state';
import { getSelectedImage } from '../utils';

export default function ProductImageSizeComputer(props: { proxyImageURL: (url: string) => string; usePreviewImageForContents: boolean }) {
    const { state, dispatch } = React.useContext(store);

    React.useEffect(() => {
        if (props.usePreviewImageForContents) {
            return;
        }

        const images: HTMLImageElement[] = [];

        const loadImage = (imageUrl: string) => {
            if (!state.productImageSizes.hasOwnProperty(imageUrl)) {
                const img = document.createElement('img');
                images.push(img);

                img.onload = () => {
                    dispatch({
                        type: Actions.SET_PRODUCT_IMAGE_SIZE,
                        imageUrl: imageUrl,
                        width: img.width,
                        height: img.height,
                    });
                };

                img.src = props.proxyImageURL(imageUrl);
            }
        };

        for (const availableProduct of state.availableProducts) {
            const selectedImage = getSelectedImage(undefined, availableProduct);
            loadImage(selectedImage.url);
        }

        for (const shelf of state.canvas.shelves) {
            if (shelf.type === 'productsShelf') {
                for (const placedProduct of shelf.products) {
                    const availableProduct = state.availableProducts.find(
                        availableProduct => availableProduct.productId === placedProduct.productId,
                    );

                    if (availableProduct) {
                        const selectedImage = getSelectedImage(placedProduct, availableProduct);
                        loadImage(selectedImage.url);
                    }
                }
            }
        }

        return () => {
            for (const img of images) {
                img.onload = null;
                img.remove();
            }
        };
    }, [state.availableProducts, state.productImageSizes, state.canvas.shelves, props.usePreviewImageForContents]);

    return <span />;
}
