import { AvailableProduct } from './AvailableProduct';
import { Locale } from './Localisation';
import { Pagination } from './Pagination';
import { SelectedProductDetails } from './ProductSelection';
import { Sorting } from './Sorting';

export const CorrectionTypes = [
    'obsolete_photo',
    'obsolete_description',
    'wrong_photo',
    'wrong_description',
    'missing_photo',
    'missing_description',
    'other' as const,
];
export type CorrectionType = (typeof CorrectionTypes)[number];

export const CorrectionStatuses = ['open', 'handled', 'in_progress' as const];
export type CorrectionStatus = (typeof CorrectionStatuses)[number];

export interface SuggestProductCorrectionToMedipimPayload {
    productId: AvailableProduct['productId'];
    locale: Locale;
    correctionType: CorrectionType;
    feedback: string;
}

export interface ProvideProductFeedbackPayload {
    productFeedback: string;
}

export interface ProductFeedbackItem {
    correctionId: string;
    productId: AvailableProduct['productId'];
    type: CorrectionType;
    text: string;
    status: CorrectionStatus;
    createdAt: string;
    updatedAt?: string | null;
    productDetails: SelectedProductDetails | null;
}

export interface ProductFeedbackItemsPage {
    items: ProductFeedbackItem[];
    total: number;
    pagination: Pagination;
    sorting?: Sorting<keyof ProductFeedbackItem> | null;
    search?: string | null;
}
