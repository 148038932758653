import { createAction, Selector, createReducer } from '@reduxjs/toolkit';

import { Domain } from 'api';

import { ThunkAction, withPayloadType } from '@/action';
import { webshopApi } from '@/api';
import * as categoryTreeState from '@/AvailableProduct/categoriesTreeState';
import { URLParams } from '@/routing';
import { RootState } from '@/store';
import { selectGlobalSelectedWebshopId, selectGlobalSelectedWebshop } from '@/Webshop/globalSelectionState';

export interface State {
    configuration?: Domain.WebshopConfiguration;
}

const initialState: State = {};

const reducerActions = {
    setConfiguration: createAction('@webshopCartRestriction/setConfiguration', withPayloadType<Domain.WebshopConfiguration>()),
};

export const updateReducer = createReducer(initialState, builder =>
    builder.addCase(reducerActions.setConfiguration, (state, action) => {
        state.configuration = action.payload;
    }),
);

export const selectConfiguration: Selector<RootState, Domain.WebshopConfiguration> = state => {
    const configuration = state.webshopCartRestriction.update.configuration;
    if (!configuration) {
        throw new Error('Webshop configuration not loaded');
    }

    return configuration;
};

export const loadConfiguration = (): ThunkAction => async (dispatch, getState) => {
    const state = getState();
    const webshopId = selectGlobalSelectedWebshopId(state);
    if (!webshopId) {
        return;
    }
    const configuration = await webshopApi.GetWebshopConfiguration(webshopId);
    await dispatch(reducerActions.setConfiguration(configuration));
};

export const load =
    (options: { urlParams: URLParams }): ThunkAction =>
    async (dispatch, getState) => {
        const state = getState();
        const webshop = selectGlobalSelectedWebshop(state);
        if (!webshop) {
            return;
        }

        await Promise.all([
            dispatch(loadConfiguration()),
            dispatch(
                categoryTreeState.actions.load({
                    ...options,
                    urlParams: {
                        locale: webshop.defaultLocale || webshop.locales[0],
                        ...options.urlParams,
                    },
                }),
            ),
        ]);
    };
