import { Domain } from 'api';

import { appointmentsApi } from '@/api';
import { makeActions, makeReducer, makeReducerActions, makeSelectors } from '@/makeOverviewState';

type AppointmentProps = keyof Domain.AppointmentsPageDetails;

export const defaultSorting: Domain.Sorting<AppointmentProps> = {
    field: 'startsAt',
    direction: 'ascending',
};

export const pageSize = 20;

export const selectors = makeSelectors<Domain.AppointmentsPageDetails, AppointmentProps>({
    getState: rootState => rootState.appointmentsReducer.overview,
});

export const reducerActions = makeReducerActions<Domain.AppointmentsPageDetails, AppointmentProps>({
    reducerPrefix: '@Appointments/overview',
});

export const actions = makeActions<
    Domain.AppointmentsPageDetails,
    AppointmentProps,
    {
        withoutPastAppointments?: string;
        startsAtFrom?: string;
        startsAtTo?: string;
        isToday?: string;
    }
>({
    dataTableSaveKey: 'appointmentsOverview-v1',
    loadApi: options => {
        return appointmentsApi.GetAppointments(options.pagination, options.sorting, options.search, options.filters);
    },
    defaultSorting,
    pageSize,
    getBaseUrl: () => '/webshops/appointments/overview/list',
    reducerActions,
    selectors,
});

export const overviewReducer = makeReducer<Domain.AppointmentsPageDetails, AppointmentProps>({
    defaultSorting,
    pageSize,
    reducerActions,
});
