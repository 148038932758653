import { IPickingArticleEvent } from 'Domain';
import { EventEmitter } from 'events';

import { IEventEmitter } from './IEventEmitter';

export enum DeliveryEvent {
    Faulted = 'Faulted',
    PickingArticle = 'PickingArticle',
    RobotDelivered = 'RobotDelivered',
    RobotReservationDelivered = 'RobotReservationDelivered',
    MovingConveyor = 'MovingConveyor',
    OpeningFrontDoor = 'OpeningFrontDoor',
    DeviceDelivered = 'DeviceDelivered',
    ClosingCustomerHatch = 'ClosingCustomerHatch',
    CustomerHatchBlocked = 'CustomerHatchBlocked',
    Finalising = 'Finalising',
    Completed = 'Completed',
}

export class DeliveryEventEmitter
    extends EventEmitter
    implements
        IEventEmitter<{
            Faulted: any;
            PickingArticle: IPickingArticleEvent;
            RobotDelivered: any;
            RobotReservationDelivered: any;
            MovingConveyor: any;
            OpeningFrontDoor: any;
            DeviceDelivered: any;
            ClosingCustomerHatch: any;
            CustomerHatchBlocked: any;
            Finalising: any;
            Completed: any;
        }> {}
