import Fuse from 'fuse.js';

import { Domain } from 'api';

export interface PriceRange {
    from: number;
    to: number;
}

export interface SearchParameters {
    query?: string;
    categoryId?: string;
    subCategoryIds?: string[];
    brandIds?: string[];
    priceRange?: PriceRange;
    excludeOutOfStock?: boolean;
}

export interface ProductDetails {
    images: Domain.SelectedProductDetails['images'];
    descriptions: Domain.SelectedProductDetails['localizedDescriptions'];
}

interface CategoryIndexBounds {
    categoryIndex: number;
    categoryIndexUpperBound: number;
}

export type BaseVendingMachineCatalogCategory = Domain.VendingMachineCategory & {
    isCustom?: boolean;
};

export type VendingMachineCatalogCategory = BaseVendingMachineCatalogCategory & {
    pcid: string;
    stock: number;
} & CategoryIndexBounds;

export type VendingMachineCatalogBrand = Domain.VendingMachineBrand & {
    bid: string;
    stock: number;
};

export type VendingMachineCatalogProduct = Domain.VendingMachineProduct & {
    stock: number;
    categoryIndexes: number[];
};

interface FuseIndexItem {
    productId: string;
    categoryId: string;
    brandIds: string;
    searchables_w3: string;
    searchables_w2: string;
    searchables_w1: string;
    price: number;
    stock: number;
}

export function productCodesToList(pcodes?: Domain.ProductCodes): string[] {
    let list: string[] = [];

    if (pcodes) {
        for (const codes of Object.values(pcodes)) {
            if (codes && Array.isArray(codes)) {
                list = [...list, ...codes];
            }
        }
    }

    return list;
}

export function pickupItemKey(productId?: string | undefined | null, productCodes?: Domain.ProductCodes | [] | null): string {
    return (productId || '') + '-' + (productCodes && !Array.isArray(productCodes) ? productCodesToList(productCodes) : []).join('-');
}

export function productCodesMatch(a?: Domain.ProductCodes | [], b?: Domain.ProductCodes | []): boolean {
    const listA = Array.isArray(a) ? a : productCodesToList(a);
    const listB = Array.isArray(b) ? b : productCodesToList(b);

    const intersection = listA.filter(code => listB.includes(code));
    return intersection.length > 0;
}

export function productCodesMatchFlat(a?: Domain.ProductCodes | [], b?: string[]): boolean {
    const listA = Array.isArray(a) ? a : productCodesToList(a);
    const listB = b || [];

    const intersection = listA.filter(code => listB.includes(code));
    return intersection.length > 0;
}

export function productCodesFlatMatchFlat(a?: string[], b?: string[]): boolean {
    const listA = a || [];
    const listB = b || [];

    const intersection = listA.filter(code => listB.includes(code));
    return intersection.length > 0;
}

export class VendingMachineCatalog {
    private readonly _homeScreenProducts: Domain.DeviceHomeScreenProductsV2 | undefined;

    private categoryIndexBoundsCache: {
        [key: string]: CategoryIndexBounds;
    } = {};

    private categoryParentCache: {
        [key: string]: string;
    } = {};

    private categoryInStockCache: {
        [key: string]: number;
    } = {};

    private categoryBrandsCache: {
        [key: string]: string[];
    } = {};

    private brandInStockCache: {
        [key: string]: number;
    } = {};

    private rawProducts: Domain.VendingMachineProduct[] = [];
    private rawStock: Domain.VendingMachineStockItem[] = [];
    private rawCategories: Domain.VendingMachineCategory[] = [];
    private rawBrands: Domain.VendingMachineBrand[] = [];

    private products: VendingMachineCatalogProduct[] = [];
    private categories: VendingMachineCatalogCategory[] = [];
    private brands: VendingMachineCatalogBrand[] = [];

    private fuseIndex: Fuse<FuseIndexItem> = new Fuse([], {
        keys: [
            { name: 'categoryId', weight: 1 },
            { name: 'brandIds', weight: 1 },
            { name: 'searchables_w1', weight: 1 },
            { name: 'searchables_w2', weight: 2 },
            { name: 'searchables_w3', weight: 3 },
        ],
        isCaseSensitive: false,
        includeMatches: false,
        includeScore: true,
        minMatchCharLength: 0,
        ignoreLocation: true,
        useExtendedSearch: true,
        threshold: 0.1,
    });

    private highestPrice = 0;

    public constructor(homeScreenProducts?: Domain.DeviceHomeScreenProductsV2) {
        this._homeScreenProducts = homeScreenProducts;
    }

    private buildCategoryCaches(categories: Domain.VendingMachineCategory[]) {
        this.categoryIndexBoundsCache = {};
        this.categoryParentCache = {};

        const rootCategories = categories.filter(subCategory => subCategory.pcid === undefined);
        for (let categoryIndex = 0; categoryIndex < rootCategories.length; categoryIndex += 1) {
            const categoryIndexLowerBound = (categoryIndex + 1) * 100000000000;
            this.categoryIndexBoundsCache[rootCategories[categoryIndex].cid] = {
                categoryIndex: categoryIndexLowerBound,
                categoryIndexUpperBound: (categoryIndex + 2) * 100000000000,
            };

            const subCategories = categories.filter(subCategory => subCategory.pcid === rootCategories[categoryIndex].cid);
            for (let subCategoryIndex = 0; subCategoryIndex < subCategories.length; subCategoryIndex += 1) {
                const subCategoryIndexLowerBound = categoryIndexLowerBound + (subCategoryIndex + 1) * 100000000;
                this.categoryIndexBoundsCache[subCategories[subCategoryIndex].cid] = {
                    categoryIndex: subCategoryIndexLowerBound,
                    categoryIndexUpperBound: categoryIndexLowerBound + (subCategoryIndex + 2) * 100000000,
                };
                this.categoryParentCache[subCategories[subCategoryIndex].cid] = rootCategories[categoryIndex].cid;

                const subSubCategories = categories.filter(subSubCategory => subSubCategory.pcid === subCategories[subCategoryIndex].cid);
                for (let subSubCategoryIndex = 0; subSubCategoryIndex < subSubCategories.length; subSubCategoryIndex += 1) {
                    const subSubCategoryIndexLowerBound = subCategoryIndexLowerBound + (subSubCategoryIndex + 1) * 100000;
                    this.categoryIndexBoundsCache[subSubCategories[subSubCategoryIndex].cid] = {
                        categoryIndex: subSubCategoryIndexLowerBound,
                        categoryIndexUpperBound: subCategoryIndexLowerBound + (subSubCategoryIndex + 2) * 100000,
                    };
                    this.categoryParentCache[subSubCategories[subSubCategoryIndex].cid] = subCategories[subCategoryIndex].cid;

                    const subSubSubCategories = categories.filter(
                        subSubSubCategory => subSubSubCategory.pcid === subSubCategories[subSubCategoryIndex].cid,
                    );
                    for (let subSubSubCategoryIndex = 0; subSubSubCategoryIndex < subSubSubCategories.length; subSubSubCategoryIndex += 1) {
                        const subSubSubCategoryIndexLowerBound = subSubCategoryIndexLowerBound + (subSubSubCategoryIndex + 1);
                        this.categoryIndexBoundsCache[subSubSubCategories[subSubSubCategoryIndex].cid] = {
                            categoryIndex: subSubSubCategoryIndexLowerBound,
                            categoryIndexUpperBound: subSubCategoryIndexLowerBound + (subSubSubCategoryIndex + 2),
                        };
                        this.categoryParentCache[subSubSubCategories[subSubSubCategoryIndex].cid] =
                            subSubCategories[subSubCategoryIndex].cid;
                    }
                }
            }
        }
    }

    private updateCategoryInStockCache(cid: string, stockToAdd: number) {
        if (!this.categoryInStockCache.hasOwnProperty(cid)) {
            this.categoryInStockCache[cid] = stockToAdd;
        } else {
            this.categoryInStockCache[cid] = this.categoryInStockCache[cid] + stockToAdd;
        }
    }

    private updateBrandInStockCache(bid: string, stockToAdd: number) {
        if (!this.brandInStockCache.hasOwnProperty(bid)) {
            this.brandInStockCache[bid] = stockToAdd;
        } else {
            this.brandInStockCache[bid] += stockToAdd;
        }
    }

    private updateCategoryBrandsCache(cid: string, bid: string) {
        if (!this.categoryBrandsCache.hasOwnProperty(cid)) {
            this.categoryBrandsCache[cid] = [];
        }

        if (this.categoryBrandsCache[cid].indexOf(bid) === -1) {
            this.categoryBrandsCache[cid].push(bid);
        }
    }

    private updateProducts(
        products: Domain.VendingMachineProduct[],
        stock: Domain.VendingMachineStockItem[],
        categories: Domain.VendingMachineCategory[],
        brands: Domain.VendingMachineBrand[],
    ) {
        this.products = [];
        this.categoryInStockCache = {};
        this.brandInStockCache = {};
        this.categoryBrandsCache = {};
        this.highestPrice = 0;
        this.fuseIndex.remove(() => true);

        for (const product of products) {
            const barcodes = productCodesToList(product.pcodes);

            let quantityInStock = 0;
            const stockItems: Domain.VendingMachineStockItem[] = stock.filter(
                searchedStockItem => searchedStockItem.barcodes.filter(stockBarcode => barcodes.indexOf(stockBarcode) > -1).length > 0,
            );
            for (const stockItem of stockItems) {
                quantityInStock += stockItem.quantity as number;
            }

            const fullProductCID: string[] = [];

            if (product.cids) {
                for (const cid of product.cids) {
                    fullProductCID.push(cid);
                    this.updateCategoryInStockCache(cid, quantityInStock);

                    if (this.categoryParentCache.hasOwnProperty(cid)) {
                        const parentCategoryId = this.categoryParentCache[cid];
                        fullProductCID.push(parentCategoryId);
                        this.updateCategoryInStockCache(parentCategoryId, quantityInStock);

                        if (this.categoryParentCache.hasOwnProperty(parentCategoryId)) {
                            const parentParentCategoryId = this.categoryParentCache[parentCategoryId];
                            fullProductCID.push(parentParentCategoryId);
                            this.updateCategoryInStockCache(parentParentCategoryId, quantityInStock);

                            if (this.categoryParentCache.hasOwnProperty(parentParentCategoryId)) {
                                const parentParentParentCategoryId = this.categoryParentCache[parentParentCategoryId];
                                fullProductCID.push(parentParentParentCategoryId);
                                this.updateCategoryInStockCache(parentParentParentCategoryId, quantityInStock);
                            }
                        }
                    }
                }
            }

            if (product.cids && product.cids.length > 0 && product.name) {
                if (product.bids) {
                    for (const bid of product.bids) {
                        this.updateBrandInStockCache(bid, quantityInStock);
                        for (const cid of fullProductCID) {
                            this.updateCategoryBrandsCache(cid, bid);
                        }
                    }
                }

                const price = product.pprice || product.price || -1;

                const categoryNames = product.cids
                    .map(cid => {
                        const category = categories.find(category => category.cid === cid);
                        if (category && category.name) {
                            return Object.values(category.name).join(' ');
                        }
                        return '';
                    })
                    .join(' ');

                const brandNames = (product.bids || [])
                    .map(bid => {
                        const brand = brands.find(brand => brand.bid === bid);
                        if (brand && brand.name) {
                            return Object.values(brand.name).join(' ');
                        }
                        return '';
                    })
                    .join(' ');

                this.fuseIndex.add({
                    productId: product.pid,
                    categoryId: fullProductCID.join(' '),
                    brandIds: product.bids ? product.bids.join(' ') : '',
                    searchables_w3: [...Object.values(product.name), ...productCodesToList(product.pcodes)].join(' '),
                    searchables_w2: categoryNames,
                    searchables_w1: brandNames,
                    price,
                    stock: quantityInStock,
                });

                if (quantityInStock > 0 && price && this.highestPrice < price) {
                    this.highestPrice = price;
                }

                this.products.push({
                    ...product,
                    stock: quantityInStock,
                    categoryIndexes: product.cids.map(cid => this.getCategoryIndexBounds(cid).categoryIndex),
                });
            }
        }

        this.highestPrice = Math.ceil(this.highestPrice / 100) * 100;
    }

    private updateCategories(categories: Domain.VendingMachineCategory[]) {
        this.categories = [];

        for (const category of categories) {
            const stock = this.categoryInStockCache.hasOwnProperty(category.cid) ? this.categoryInStockCache[category.cid] : 0;

            this.categories.push({
                ...category,
                pcid: category.pcid || 'ROOT',
                stock,
                ...this.getCategoryIndexBounds(category.cid),
            });
        }
    }

    private updateBrands(brands: Domain.VendingMachineBrand[]) {
        this.brands = [];

        for (const brand of brands) {
            const stock = this.brandInStockCache.hasOwnProperty(brand.bid) ? this.brandInStockCache[brand.bid] : 0;

            this.brands.push({
                ...brand,
                stock,
            });
        }
    }

    private updateRawProducts(products: Domain.VendingMachineProduct[]) {
        for (const product of products) {
            const exists = this.rawProducts.find(searchedProduct => searchedProduct.pid === product.pid);
            if (!exists) {
                this.rawProducts.push(product);
            } else {
                this.rawProducts = this.rawProducts.map(searchedProduct => {
                    if (searchedProduct.pid === product.pid) {
                        return product;
                    }

                    return searchedProduct;
                });
            }
        }
    }

    public init(data: {
        categories: Domain.VendingMachineCategory[];
        brands: Domain.VendingMachineBrand[];
        products: Domain.VendingMachineProduct[];
        stock: Domain.VendingMachineStockItem[];
    }) {
        this.rawCategories = data.categories;
        this.rawBrands = data.brands;
        this.updateRawStock(data.stock);
        this.updateRawProducts(data.products);

        this.updateFromRaw();
    }

    private updateFromRaw() {
        let allCategories: BaseVendingMachineCatalogCategory[] = this.rawCategories;
        const allProducts = this.rawProducts;

        if (this._homeScreenProducts) {
            allCategories = allCategories.map(category => {
                const homeScreenCategory = this._homeScreenProducts!.categories.find(
                    searchedCategory => searchedCategory.categoryId === category.cid,
                );

                if (homeScreenCategory) {
                    return {
                        ...category,
                        banner: homeScreenCategory.customCategoryMediaItemId,
                    };
                }

                return category;
            });

            for (const category of this._homeScreenProducts.categories) {
                if (category.isSelected && category.customCategoryName) {
                    allCategories.push({
                        cid: category.categoryId,
                        pcid: undefined,
                        name: category.customCategoryName,
                        banner: category.customCategoryMediaItemId,
                        isCustom: true,
                    });

                    for (const pid of category.productIds) {
                        for (const product of allProducts) {
                            if (product.pid === pid) {
                                if (!product.cids) {
                                    product.cids = [];
                                }
                                if (product.cids.indexOf(category.categoryId) === -1) {
                                    product.cids = [...product.cids, category.categoryId];
                                }
                            }
                        }
                    }
                }
            }

            allCategories = [
                ...allCategories.sort((a, b) => {
                    const homeA = this.getHomeScreenConfiguredCategory(a.cid);
                    const homeB = this.getHomeScreenConfiguredCategory(b.cid);
                    const homeAIndex = homeA ? homeA.sequence : 999;
                    const homeBIndex = homeB ? homeB.sequence : 999;

                    return homeAIndex - homeBIndex;
                }),
            ];
        }

        this.buildCategoryCaches(allCategories);
        this.updateProducts(allProducts, this.rawStock, allCategories, this.rawBrands);
        this.updateCategories(allCategories);
        this.updateBrands(this.rawBrands);
    }

    private updateRawStock(newStockItems: Domain.VendingMachineStockItem[]) {
        for (const newStockItem of newStockItems) {
            let isNewStockItem = true;
            for (const rawStockItem of this.rawStock) {
                if (
                    JSON.stringify(newStockItem.location) === JSON.stringify(rawStockItem.location) &&
                    productCodesFlatMatchFlat(newStockItem.barcodes, rawStockItem.barcodes)
                ) {
                    rawStockItem.quantity = newStockItem.quantity;
                    isNewStockItem = false;
                    break;
                }
            }

            if (isNewStockItem) {
                this.rawStock.push(newStockItem);
            }
        }
    }

    public getRawStock(products: Domain.ProductCodes[]) {
        const stock: {
            pcodes: Domain.ProductCodes;
            robotStock: Domain.VendingMachineStockItem[];
            lockerStock: Domain.VendingMachineStockItem[];
        }[] = [];

        for (const productCodes of products) {
            const rawStockItems = this.rawStock.filter(rawStockItem => productCodesMatchFlat(productCodes, rawStockItem.barcodes));
            const robotStock = rawStockItems.filter(rawStockItem => rawStockItem.location.type === 'robot');
            const lockerStock = rawStockItems.filter(rawStockItem => rawStockItem.location.type === 'collect');

            if (robotStock.reduce((sum, item) => sum + item.quantity, 0) + lockerStock.reduce((sum, item) => sum + item.quantity, 0) > 0) {
                stock.push({
                    pcodes: productCodes,
                    robotStock,
                    lockerStock,
                });
            }
        }

        return stock;
    }

    public getRawStockCounts(products: Domain.ProductCodes[]) {
        const stock = this.getRawStock(products);

        return stock.map(item => ({
            ...item,
            robotStock: item.robotStock.reduce((sum, item) => sum + item.quantity, 0),
            lockerStock: item.lockerStock.reduce((sum, item) => sum + item.quantity, 0),
        }));
    }

    public updateStock(data: { products: Domain.VendingMachineProduct[]; stock: Domain.VendingMachineStockItem[] }) {
        this.updateRawProducts(data.products);
        this.updateRawStock(data.stock);

        this.updateFromRaw();
    }

    public hasHomeScreenConfiguration(): boolean {
        return !!(this._homeScreenProducts && this._homeScreenProducts.categories.length > 0);
    }

    public getHomeScreenConfiguredCategory(categoryId: string): Domain.DeviceHomeScreenCategory | undefined {
        if (this._homeScreenProducts) {
            return this._homeScreenProducts.categories.find(homeScreenCategory => homeScreenCategory.categoryId === categoryId);
        }
    }

    public hasCustomCategories(): boolean {
        if (this._homeScreenProducts) {
            return !!this._homeScreenProducts.categories.find(homeScreenCategory => !homeScreenCategory.categoryId.startsWith('cagegory-'));
        }

        return false;
    }

    public getRootCategories(includeOutOfStock = false): VendingMachineCatalogCategory[] {
        return this.getSubcategoriesForCategoryId('ROOT', includeOutOfStock);
    }

    public getSubcategoriesForCategoryId(categoryId: string, includeOutOfStock = false): VendingMachineCatalogCategory[] {
        return this.categories.filter(category => {
            if (category.pcid !== categoryId) {
                return false;
            }

            if (category.stock === 0 && !includeOutOfStock) {
                return false;
            }

            return true;
        });
    }

    public getProductsForCategoryId(categoryId: string, limit = -1, includeOutOfStock = false): VendingMachineCatalogProduct[] {
        return this.getProductsForCategoryIds([categoryId], limit, includeOutOfStock);
    }

    public getProductsByIdForCategoryId(
        categoryId: string,
        productIds: string[],
        includeOutOfStock = false,
    ): VendingMachineCatalogProduct[] {
        const products = this.getProductsForCategoryIds([categoryId], -1, includeOutOfStock);

        const requestedProducts: VendingMachineCatalogProduct[] = [];
        for (const productId of productIds) {
            const foundProduct = products.find(searchedProduct => searchedProduct.pid === productId);
            if (foundProduct) {
                requestedProducts.push(foundProduct);
            }
        }

        return requestedProducts;
    }

    public getProductsForCategoryIds(categoryIds: string[], limit = -1, includeOutOfStock = false): VendingMachineCatalogProduct[] {
        const categoryIndexBoundsList = [];

        for (const categoryId of categoryIds) {
            const category = this.getCategoryById(categoryId);
            if (!category) {
                continue;
            }

            categoryIndexBoundsList.push({
                categoryIndex: category.categoryIndex,
                categoryIndexUpperBound: category.categoryIndexUpperBound,
            });
        }

        return this.getProductsForCategoryIndexBoundsList(categoryIndexBoundsList, limit, includeOutOfStock);
    }

    private getProductsForCategoryIndexBoundsList(
        categoryIndexBoundsList: CategoryIndexBounds[],
        limit = -1,
        includeOutOfStock = false,
    ): VendingMachineCatalogProduct[] {
        const products = this.products
            .filter(product => {
                if (product.stock === 0 && !includeOutOfStock) {
                    return false;
                }

                for (const categoryIndex of product.categoryIndexes) {
                    for (const categoryIndexBounds of categoryIndexBoundsList) {
                        if (
                            categoryIndex >= categoryIndexBounds.categoryIndex &&
                            categoryIndex < categoryIndexBounds.categoryIndexUpperBound
                        ) {
                            return true;
                        }
                    }
                }

                return false;
            })
            .sort((a, b) => {
                return b.stock - a.stock;
            });

        if (limit > 0) {
            return products.slice(0, limit);
        }

        return products;
    }

    public getCategoryById(categoryId: string): VendingMachineCatalogCategory | undefined {
        return this.categories.find(category => category.cid === categoryId);
    }

    public getBrandById(brandId: string): VendingMachineCatalogBrand | undefined {
        return this.brands.find(brand => brand.bid === brandId);
    }

    public getCategoryParentsById(categoryId: string): VendingMachineCatalogCategory[] {
        const category = this.getCategoryById(categoryId);
        if (!category) {
            throw new Error('Category not found in index, cannot get parents');
        }

        const parents: VendingMachineCatalogCategory[] = [];

        if (category.pcid !== 'ROOT') {
            const parent = this.getCategoryById(category.pcid);

            if (!parent) {
                throw new Error('Category parent not found in index, cannot get parents');
            }

            parents.unshift(parent);

            if (parent.pcid !== 'ROOT') {
                const parentParent = this.getCategoryById(parent.pcid);

                if (!parentParent) {
                    throw new Error('Category parent > parent not found in index, cannot get parents');
                }

                parents.unshift(parentParent);
            }
        }

        return parents;
    }

    public getBrands(): VendingMachineCatalogBrand[] {
        return this.brands.filter(brand => brand.stock > 0);
    }

    public getBrandsForCategoryId(categoryId: string): VendingMachineCatalogBrand[] {
        const brandIds = this.categoryBrandsCache[categoryId];
        if (!brandIds) {
            return [];
        }

        return this.brands.filter(brand => {
            return brand.stock > 0 && brandIds.indexOf(brand.bid) > -1;
        });
    }

    public getProductById(productId: string): VendingMachineCatalogProduct | undefined {
        return this.products.find(product => product.pid === productId);
    }

    public getProductByProductCode(productCode: string): VendingMachineCatalogProduct | undefined {
        return this.products.find(product => {
            if (!product.pcodes) {
                return false;
            }

            for (const codeType in product.pcodes) {
                if (!product.pcodes.hasOwnProperty(codeType)) {
                    continue;
                }

                if (product.pcodes[codeType] && product.pcodes[codeType]!.indexOf(productCode) > -1) {
                    return true;
                }
            }

            return false;
        });
    }

    public getProductByProductCodes(productCodes: Domain.ProductCodes): VendingMachineCatalogProduct | undefined {
        return this.products.find(product => {
            if (!product.pcodes) {
                return false;
            }

            return productCodesMatch(product.pcodes, productCodes);
        });
    }

    public getProductByProductCodesList(productCodes: string[]): VendingMachineCatalogProduct | undefined {
        return this.products.find(product => {
            if (!product.pcodes) {
                return false;
            }

            return productCodesMatchFlat(product.pcodes, productCodes);
        });
    }

    public getSimilarProductsByProductId(productId: string): VendingMachineCatalogProduct[] {
        const product = this.getProductById(productId);

        if (product && product.cids && product.cids.length > 0) {
            const refPrice = product.pprice || product.price || 0;
            return this.getProductsForCategoryIds(product.cids)
                .filter(product => product.pid !== productId)
                .sort((a, b) => {
                    const aPrice = a.pprice || a.price || 0;
                    const bPrice = b.pprice || b.price || 0;

                    return Math.abs(aPrice - refPrice) - Math.abs(bPrice - refPrice);
                })
                .slice(0, 4);
        }

        return [];
    }

    public getProductsByIds(productIds: string[], limit = -1): VendingMachineCatalogProduct[] {
        let products = this.products.filter(product => productIds.indexOf(product.pid) > -1);

        const productsInStock = products.filter(product => product.stock > 0);
        const productsNotInStock = products.filter(product => product.stock === 0);

        const sortByOriginalOrder = (a: VendingMachineCatalogProduct, b: VendingMachineCatalogProduct): number => {
            return productIds.indexOf(a.pid) - productIds.indexOf(b.pid);
        };

        products = [...productsInStock.sort(sortByOriginalOrder), ...productsNotInStock.sort(sortByOriginalOrder)];

        if (limit > 0) {
            return products.slice(0, limit);
        }

        return products;
    }

    public queryProducts(search: SearchParameters, limit?: number): VendingMachineCatalogProduct[] {
        const cleanQuery = (search.query || '').replace('|', ' ');
        const searchPattern: Fuse.Expression[] = [{ searchables_w3: "!'never-ever-ever" }];

        if (search.categoryId) {
            searchPattern.push({
                categoryId: `'${search.categoryId}`,
            });
        }

        if (search.subCategoryIds && search.subCategoryIds.length > 0) {
            searchPattern.push({
                categoryId: search.subCategoryIds.map(categoryId => `'${categoryId}`).join('|'),
            });
        }

        if (search.brandIds && search.brandIds.length > 0) {
            searchPattern.push({
                brandIds: search.brandIds.map(brandId => `'${brandId}`).join('|'),
            });
        }

        if (search.query) {
            searchPattern.push({
                $or: [
                    { searchables_w3: cleanQuery.replace(' ', '|') },
                    { searchables_w2: cleanQuery.replace(' ', '|') },
                    { searchables_w1: cleanQuery },
                ],
            });
        }

        let results = this.fuseIndex.search({ $and: searchPattern }).sort((a, b) => (a.score || 0) - (b.score || 0));

        if (!search.query || search.excludeOutOfStock) {
            results = results.filter(result => result.item.stock > 0);
        }

        if (search.priceRange) {
            let from = this.scalePercentageToPrice(search.priceRange.from);
            let to = this.scalePercentageToPrice(search.priceRange.to);

            from = Math.round(from / 100) * 100;
            to = Math.round(to / 100) * 100;

            results = results.filter(result => result.item.price >= from && result.item.price <= to);
        }

        if (limit !== undefined && limit > 0) {
            results = results.slice(0, limit);
        }

        return this.getProductsByIds(results.map(result => result.item.productId));
    }

    public scalePercentageToPrice(percentage: number) {
        const factor = percentage / 100;
        let power = 1;
        if (this.highestPrice > 20000) {
            power = 1.5;
        }
        if (this.highestPrice > 50000) {
            power = 2;
        }
        if (this.highestPrice > 100000) {
            power = 2.5;
        }
        if (this.highestPrice > 200000) {
            power = 3;
        }
        if (this.highestPrice > 300000) {
            power = 3.5;
        }
        return Math.floor(this.highestPrice * Math.pow(factor, power));
    }

    private getCategoryIndexBounds(categoryId: string): CategoryIndexBounds {
        if (this.categoryIndexBoundsCache.hasOwnProperty(categoryId)) {
            return this.categoryIndexBoundsCache[categoryId];
        }

        return {
            categoryIndex: -1,
            categoryIndexUpperBound: -1,
        };
    }
}
