import { Domain } from 'api';

import { ThunkAction } from '@/action';
import { branchApi } from '@/api';
import { loadLocalisations } from '@/Localisation/overviewState';
import { makeActions, makeReducer, makeReducerActions, makeSelectors } from '@/makeDetailsState';
import { URLParams } from '@/routing';

export const selectors = makeSelectors<Domain.BranchDetails>({
    getState: rootState => rootState.branch.update,
});

export const reducerActions = makeReducerActions<Domain.BranchDetails>({
    reducerPrefix: '@branch/update',
});

const baseActions = makeActions<Domain.BranchDetails>({
    loadApi: options => branchApi.GetBranchDetails(options.urlParams.branchId),
    reducerActions,
    selectors,
});

export const actions = {
    ...baseActions,
    load:
        (options: { urlParams: URLParams }): ThunkAction =>
        async dispatch => {
            await Promise.all([dispatch(baseActions.load(options)), dispatch(loadLocalisations())]);
        },
    loadBranch: baseActions.load,
};

export const updateReducer = makeReducer<Domain.BranchDetails>({
    reducerActions,
});
