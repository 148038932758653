import * as React from 'react';

import debounce from 'lodash.debounce';

import { ISearchProvider } from 'utils';

import { Button } from '@/button';
import color from '@/color';
import { Icon } from '@/icon';
import { Input } from '@/input';

import Menu, { IMenuProps, IMenuItem } from './Menu';

import styles from './MenuWithSearch.scss';

export interface ISearchableMenuItem {
    action?: never;
}

interface IMenuWithSearchProps {
    items?: never;
    query?: string;
    onQueryChange?: (newValue: string) => void;
    defaultQuery?: string;
    searchProvider?: ISearchProvider<IMenuItem<ISearchableMenuItem>>;
    searchPlaceholder?: string;
    noResultsPlaceholder?: React.ReactNode;
    enabledNoResultsItem?: boolean;
    searchInProgressPlaceholder?: string;
    extraHeader?: (doSearch?: () => void) => React.ReactNode;
    noSearchInputPadding?: boolean;
    searchOnBottom?: boolean;
    showLoadMore?: boolean;
    loadMoreLabel?: string;
}

interface IState {
    query: string;
    visibleItems: IMenuItem<ISearchableMenuItem>[];
    selectedVisibleItems: IMenuItem<ISearchableMenuItem>[];
    isQuerying: boolean;
    isLoadingMore: boolean;
    showLoadMoreOnScroll: boolean;
}

export default class MenuWithSearch extends Menu<ISearchableMenuItem, IMenuWithSearchProps, IState> {
    constructor(props: IMenuProps<ISearchableMenuItem> & IMenuWithSearchProps) {
        super(props);

        this.state = {
            query: props.defaultQuery || '',
            visibleItems: props.items || [],
            selectedVisibleItems: [],
            isQuerying: !props.items,
            isLoadingMore: false,
            showLoadMoreOnScroll: false,
        };
    }

    static getDerivedStateFromProps(nextProps: Readonly<IMenuProps<ISearchableMenuItem> & IMenuWithSearchProps>, state: IState): IState {
        if (typeof nextProps.query !== 'undefined') {
            return {
                query: nextProps.query,
                visibleItems: state.visibleItems,
                selectedVisibleItems: state.selectedVisibleItems,
                isQuerying: state.isQuerying,
                isLoadingMore: state.isLoadingMore,
                showLoadMoreOnScroll: state.showLoadMoreOnScroll,
            };
        }

        return state;
    }

    componentDidMount(): void {
        this.search();
        this.initSelectedVisibleItems();
    }

    render() {
        const { searchPlaceholder, extraHeader, noSearchInputPadding, searchOnBottom, showLoadMore, loadMoreLabel, searchProvider } =
            this.props;
        const { query, showLoadMoreOnScroll } = this.state;

        const header = (
            <div
                className={
                    noSearchInputPadding
                        ? 'py-0 px-9'
                        : 'py-0 px-18' + ' ' + (extraHeader ? styles.MenuWithSearchExtraHeader : '') + ' ' + (searchOnBottom ? 'pt-11' : '')
                }
            >
                <Input
                    placeholder={searchPlaceholder || 'Search'}
                    iconRight={
                        <Icon
                            type="action_search"
                            iconSize="m"
                            className={color.Grey.Dark2}
                        />
                    }
                    value={query}
                    onChange={newQuery => {
                        this.setState(
                            {
                                query: newQuery,
                            },
                            () => {
                                if (this.props.onQueryChange) {
                                    this.props.onQueryChange(newQuery);
                                }

                                this.search();
                            },
                        );
                    }}
                    onKeyDown={e => {
                        if (e.key === 'Enter') {
                            e.preventDefault();
                        }
                    }}
                    className="mb-8"
                />
                {extraHeader ? extraHeader(this.search) : null}
            </div>
        );

        return (
            <div className={styles.MenuWithSearch}>
                {!searchOnBottom ? header : null}

                <div className="px-0">
                    {super.render()}
                    {searchProvider && searchProvider.getHasMoreResults() && showLoadMore ? (
                        showLoadMoreOnScroll ? (
                            <div className="py-5 align-center">
                                <Button
                                    type="button"
                                    variant="primary"
                                    variantSize="xxs"
                                    disabled={this.state.isLoadingMore}
                                    onClick={async () => {
                                        this.setState(
                                            {
                                                isLoadingMore: true,
                                            },
                                            async () => {
                                                if (searchProvider) {
                                                    const newItems = await searchProvider.loadMoreResults();
                                                    this.setState({
                                                        visibleItems: newItems,
                                                        isLoadingMore: false,
                                                    });
                                                }
                                            },
                                        );
                                    }}
                                >
                                    {loadMoreLabel ? loadMoreLabel : 'Load more'}
                                </Button>
                            </div>
                        ) : null
                    ) : null}
                </div>
                {searchOnBottom ? header : null}
            </div>
        );
    }

    protected onScroll(event: React.UIEvent<HTMLElement>): void {
        this.setState({
            showLoadMoreOnScroll: false,
        });

        const select = event.currentTarget;

        if (Math.abs(select.scrollHeight - select.scrollTop - select.clientHeight) <= 3) {
            this.setState({
                showLoadMoreOnScroll: true,
            });
        }
    }

    protected restOfProps(): React.HTMLProps<HTMLDivElement> {
        const {
            query,
            onQueryChange,
            defaultQuery,
            searchProvider,
            searchPlaceholder,
            noResultsPlaceholder,
            noSearchInputPadding,
            searchInProgressPlaceholder,
            extraHeader,
            loadMoreLabel,
            showLoadMore,
            searchOnBottom,
            ...rest
        } = super.restOfProps() as any;

        return rest;
    }

    protected getItems(): IMenuItem<ISearchableMenuItem>[] {
        if (this.state.isQuerying) {
            return [{ label: this.props.searchInProgressPlaceholder || 'Searching...', value: '--searching--', disabled: true }];
        }

        let items = this.state.visibleItems;

        if (items.length === 0) {
            items = [
                {
                    label: this.props.noResultsPlaceholder || 'No matching results.',
                    value: '--no-results--',
                    disabled: !this.props.enabledNoResultsItem,
                },
            ];
        }
        if (this.state.query !== '' && this.props.showSelectedOnTop) {
            items = items.filter(
                item => !this.state.selectedVisibleItems.find(selectedVisibleItem => selectedVisibleItem.value === item.value),
            );
            items = [...items, ...this.state.selectedVisibleItems];
        } else if (this.props.showSelectedOnTop) {
            items = items.filter(
                item => !this.state.selectedVisibleItems.find(selectedVisibleItem => selectedVisibleItem.value === item.value),
            );

            items = [...this.state.selectedVisibleItems, ...items];
        }

        return items;
    }

    protected afterItemSelect = (): void => {
        setTimeout(() => {
            this.initSelectedVisibleItems();
        }, 0);
    };

    private search = debounce(() => {
        this.setState(
            {
                isQuerying: !!this.props.searchProvider,
            },
            async () => {
                if (this.props.searchProvider) {
                    const newItems = await this.props.searchProvider.search(this.state.query);
                    this.setState({
                        visibleItems: newItems,
                        isQuerying: false,
                    });
                }
            },
        );
    }, 300);

    private async initSelectedVisibleItems() {
        if (!this.props.showSelectedOnTop || !this.props.searchProvider || !this.props.selectedItems) {
            return;
        }

        const selectedVisibleItems = await this.props.searchProvider.byValues(this.props.selectedItems);
        this.setState({
            selectedVisibleItems,
        });
    }
}
