import * as React from 'react';

import { Container, Row, Col, Button, Icon, color, Tx } from 'ui-components';

import { Actions, store } from '../state';
import { CanvasFrame } from '../types';
import { getMaxFrameDepth, getMinFrameDepth } from '../utils';

export default function FrameDepthSorting(props: { gettext: (text: string) => string; frame: CanvasFrame }) {
    const { gettext, frame } = props;
    const { state, dispatch } = React.useContext(store);

    const minFrameDepth = getMinFrameDepth(state.canvas.frames);
    const maxFrameDepth = getMaxFrameDepth(state.canvas.frames);
    const numFramesWithMinDepth = state.canvas.frames.filter(searchedFrame => searchedFrame.depth === minFrameDepth).length;
    const numFramesWithMaxDepth = state.canvas.frames.filter(searchedFrame => searchedFrame.depth === maxFrameDepth).length;

    return (
        <Container
            gutter={19}
            onMouseUp={event => {
                event.nativeEvent.stopImmediatePropagation();
            }}
        >
            <Row>
                <Col vAlign="center">
                    <Tx
                        level="heading-6"
                        className="my-0"
                    >
                        {gettext('Depth sorting')}
                    </Tx>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Button
                        variant="tertiary"
                        variantSize="s"
                        onClick={() =>
                            dispatch({
                                type: Actions.SET_FRAME_DEPTH,
                                frameId: frame.frameId,
                                depth: minFrameDepth - 1,
                            })
                        }
                        disabled={frame.depth === minFrameDepth && numFramesWithMinDepth === 1}
                    >
                        {gettext('Send to back')}
                    </Button>
                </Col>
                <Col>
                    <Button
                        variant="tertiary"
                        variantSize="s"
                        onClick={() =>
                            dispatch({
                                type: Actions.SET_FRAME_DEPTH,
                                frameId: frame.frameId,
                                depth: (frame.depth || 0) - 1,
                            })
                        }
                        disabled={frame.depth === minFrameDepth && numFramesWithMinDepth === 1}
                        startIcon={
                            <Icon
                                type="text_align_bottom"
                                color={color.Grey.Dark1}
                            />
                        }
                    />
                </Col>
                <Col>
                    <Button
                        variant="tertiary"
                        variantSize="s"
                        onClick={() =>
                            dispatch({
                                type: Actions.SET_FRAME_DEPTH,
                                frameId: frame.frameId,
                                depth: (frame.depth || 0) + 1,
                            })
                        }
                        disabled={frame.depth === maxFrameDepth && numFramesWithMaxDepth === 1}
                        startIcon={
                            <Icon
                                type="text_align_top"
                                color={color.Grey.Dark1}
                            />
                        }
                    />
                </Col>
                <Col>
                    <Button
                        variant="tertiary"
                        variantSize="s"
                        onClick={() =>
                            dispatch({
                                type: Actions.SET_FRAME_DEPTH,
                                frameId: frame.frameId,
                                depth: maxFrameDepth + 1,
                            })
                        }
                        disabled={frame.depth === maxFrameDepth && numFramesWithMaxDepth === 1}
                    >
                        {gettext('Bring to front')}
                    </Button>
                </Col>
            </Row>
        </Container>
    );
}
