import * as React from 'react';

import { Domain } from 'api';
import { BaseProvider as CanvasProvider } from 'editor-canvas';

import { Layout, Stock, ProductImageSizes } from '../types';
import { ActionTypes } from './actions';
import { productWallReducer, initialState } from './reducer';

export interface EyedropperChangeHandlers {
    changeStart: () => void;
    change: (r: number, g: number, b: number) => void;
    changeComplete: (r: number, g: number, b: number) => void;
}

export interface ProductWallState {
    canvas: Layout;
    screenResolution: Domain.DeviceScreenResolution;
    availableProducts: Domain.SlideshowProduct[];
    productImageSizes: ProductImageSizes;
    initialState: string;
    stock: Stock;
    undoStack: string[];
    redoStack: string[];
    draggedBlockType?: string;
    draggedBlockPosition: {
        x: number;
        y: number;
        width: number;
        height: number;
    };
    draggedBlockDroppedFlag: boolean;
    draggedProduct?: Domain.SlideshowProduct;
    draggedPlacedProductId?: number;
    resizedProductId?: number;
    placedProductSettingsDialog?: {
        placedProductId: number;
    };
    draggedProductPosition: {
        x: number;
        y: number;
    };
    draggedProductDroppedFlag: boolean;
    resizedFrameId?: string;
    editedFrameId?: string;
    draggingShelf: boolean;
    generalSettingsVisible: boolean;
    eyedropperEnabled: boolean;
    eyedropperChangeHandlers?: EyedropperChangeHandlers;
    eyedropperToolFrameInfo: {
        frameId?: string;
        shelfId?: number;
        placedProductId?: number;
        isDown: boolean;
        x: number;
        y: number;
        w: number;
        h: number;
    };
}

export interface Context {
    state: ProductWallState;
    dispatch: React.Dispatch<ActionTypes>;
}

export const store = React.createContext<Context>({
    state: initialState,
    dispatch: (() => undefined) as React.Dispatch<ActionTypes>,
});

const { Provider } = store;

export type { Layout } from '../types';

export const StateProvider = (props: {
    children: React.ReactNode;
    screenResolution: Domain.DeviceScreenResolution;
    layout?: Layout;
    stock: Stock;
    availableProducts?: Domain.SlideshowProduct[];
    onLayoutChange?: (layout: Layout) => void;
}) => {
    const [initialized, setInitialized] = React.useState(false);
    const [state, dispatch] = React.useReducer(productWallReducer, {
        ...initialState,
        screenResolution: props.screenResolution,
        stock: props.stock,
    });

    React.useEffect(() => {
        if (!props.availableProducts) {
            return;
        }

        dispatch({
            type: 'SET_AVAILABLE_PRODUCTS',
            availableProducts: props.availableProducts,
        });
    }, [props.layout, props.availableProducts]);

    React.useEffect(() => {
        if (!props.layout) {
            return;
        }

        dispatch({
            type: 'SET_CANVAS',
            canvas: props.layout,
        });
    }, [props.layout, props.availableProducts]);

    React.useEffect(() => {
        dispatch({
            type: 'SET_STOCK',
            stock: props.stock,
        });
    }, [props.stock]);

    React.useEffect(() => {
        if (!props.layout || !props.availableProducts) {
            return;
        }

        setTimeout(() => {
            setInitialized(true);
        }, 250);
    }, [props.layout, props.availableProducts]);

    React.useEffect(() => {
        if (initialized && props.onLayoutChange) {
            props.onLayoutChange(state.canvas);
        }
    }, [state.canvas, initialized]);

    if (!initialized) {
        return null;
    }

    return (
        <Provider value={{ state, dispatch }}>
            <CanvasProvider value={{ state, dispatch }}>{props.children}</CanvasProvider>
        </Provider>
    );
};

const presetColorsCache: string[] = [];
export function getPresetColors(state: ProductWallState): string[] {
    const addColor = (color: string | undefined) => {
        if (color && presetColorsCache.indexOf(color.toLowerCase()) === -1) {
            presetColorsCache.push(color.toLowerCase());
        }
    };

    addColor(state.canvas.backgroundColor);
    addColor(state.canvas.headerColor);
    addColor(state.canvas.priceStyle.text.color);
    addColor(state.canvas.priceStyle.borderColor);
    addColor(state.canvas.priceStyle.backgroundColor);
    addColor(state.canvas.promoPriceStyle.text.color);
    addColor(state.canvas.promoPriceStyle.promoText.color);
    addColor(state.canvas.promoPriceStyle.borderColor);
    addColor(state.canvas.promoPriceStyle.backgroundColor);

    for (const frame of state.canvas.frames) {
        if (frame.type === 'text') {
            addColor(frame.textStyle.color);
        } else if (frame.type === 'richText' && frame.html) {
            const colorMatches = frame.html.match(/color: (#.{1,6})/g);
            if (colorMatches) {
                for (const match of colorMatches) {
                    addColor(match.replace('color: ', ''));
                }
            }
        }
    }

    for (const shelf of state.canvas.shelves) {
        if (shelf.type === 'productsShelf') {
            for (const product of shelf.products) {
                if (product.customPriceStyle) {
                    addColor(product.customPriceStyle.text.color);
                    addColor(product.customPriceStyle.borderColor);
                    addColor(product.customPriceStyle.backgroundColor);
                }

                if (product.customPromoPriceStyle) {
                    addColor(product.customPromoPriceStyle.text.color);
                    addColor(product.customPromoPriceStyle.promoText.color);
                    addColor(product.customPromoPriceStyle.borderColor);
                    addColor(product.customPromoPriceStyle.backgroundColor);
                }
            }
        }
    }

    return presetColorsCache;
}
