import {
    Wwks2StatusDialog,
    wwks2ArticleInfoResponse,
    wwks2Article
} from '../../../../Domain';
import Wwks2CommClient from '../../Wwks2CommClient';
import { DIALOG_TIMEOUTS } from '../Wwks2DefaultValues';


export default class Wwks2ArticlePrice {

    private Wwks2CommClient: Wwks2CommClient;

    public constructor(Wwks2CommClient: Wwks2CommClient) {
        this.Wwks2CommClient = Wwks2CommClient;
    }

    public sendArticleInfoRequest(barcodes: string[], includeCrossSellingArticles: boolean = false, includeAlternativeArticles: boolean = false, includeAlternativePackSizeArticles: boolean = false, dialogId: string | number | boolean = false): Promise<Wwks2StatusDialog> {
        return new Promise(resolve => {

            if(typeof dialogId === 'boolean'){
                dialogId = this.Wwks2CommClient.GetWwks2DialogId();
            }

            const barcodesMap = barcodes.map((barcode) => {
                return {
                    '@': {
                        'Id': barcode
                    }
                }
            })

            const wwks = {
                'ArticleInfoRequest': {
                    '@': {
                        'Id': dialogId,
                        'Source': this.Wwks2CommClient.GetSourceId(),
                        'Destination': this.Wwks2CommClient.GetClientId(),
                        'IncludeCrossSellingArticles': includeCrossSellingArticles,
                        'IncludeAlternativeArticles': includeAlternativeArticles,
                        'IncludeAlternativePackSizeArticles': includeAlternativePackSizeArticles
                    },
                    '#': {
                        'Article': barcodesMap
                    }
                }
            }
            this.Wwks2CommClient.createAndSendDialog(wwks, 'ArticleInfo', dialogId).then(status => {
                resolve(status);
            });

        });
    }

    public sendAndProcessArticleInfoRequest(barcodes: string[], includeCrossSellingArticles: boolean = false, includeAlternativeArticles: boolean = false, includeAlternativePackSizeArticles: boolean = false, dialogId: string | number | boolean = false): Promise<Wwks2StatusDialog> {
        return new Promise(resolve => {
            this.sendArticleInfoRequest(barcodes, includeCrossSellingArticles, includeAlternativeArticles, includeAlternativePackSizeArticles, dialogId)
                .then((data: Wwks2StatusDialog) => {
                    if (data.status) {
                        const timeoutTimer = setTimeout(() => {
                            let processStatusDialog = this.Wwks2CommClient.GetProcessedDialogData(data.dialogId);
                            if (typeof processStatusDialog !== 'boolean') {
                                if (processStatusDialog.typeDialog === 'ArticleInfo') {
                                    processStatusDialog.status.cancel = true;
                                    this.Wwks2CommClient.SetProcessedDialogData(data.dialogId, processStatusDialog);
                                }
                            }
                        }, DIALOG_TIMEOUTS.ArticleInfo);
                        this.Wwks2CommClient.getResponse(data.dialogId, 'ArticleInfo')
                            .then((status) => {
                                clearTimeout(timeoutTimer);
                                resolve(status)
                            });
                    } else {
                        resolve(data);
                    }
                });
        });
    }

    public handleMessage(WWKS: any) {

        if (WWKS.ArticleInfoResponse) {

            const responseId = WWKS.ArticleInfoResponse['@attributes'].Id;
            let processStatusDialog = this.Wwks2CommClient.GetProcessedDialogData(responseId);
            if (typeof processStatusDialog !== 'boolean') {

                const responseData: wwks2ArticleInfoResponse = {
                    'clientId': 0,
                    'articles': [],
                }

                responseData.clientId = WWKS.ArticleInfoResponse['@attributes'].Source;

                if (typeof WWKS.ArticleInfoResponse.Article !== 'undefined') {
                    let _articles: any[];

                    if (!Array.isArray(WWKS.ArticleInfoResponse.Article)) {
                        _articles = [WWKS.ArticleInfoResponse.Article];
                    } else {
                        _articles = WWKS.ArticleInfoResponse.Article;
                    }
                    _articles.forEach((article) => {
                        if (typeof article['@attributes'] !== 'undefined') {
                            if (typeof article['@attributes'].Id !== 'undefined') {

                                const articleItem: wwks2Article = {
                                    id: article['@attributes'].Id
                                }

                                if (typeof article['@attributes'].Name !== 'undefined') {
                                    articleItem.name = article['@attributes'].Name;
                                }
                                if (typeof article['@attributes'].Quantity !== 'undefined') {
                                    articleItem.quantity = article['@attributes'].Quantity;
                                }
                                if (typeof article['@attributes'].DosageForm !== 'undefined') {
                                    articleItem.dosageForm = article['@attributes'].DosageForm;
                                }
                                if (typeof article['@attributes'].PackingUnit !== 'undefined') {
                                    articleItem.packingUnit = article['@attributes'].PackingUnit;
                                }
                                if (typeof article['@attributes'].MaxSubItemQuantity !== 'undefined') {
                                    articleItem.maxSubItemQuantity = article['@attributes'].MaxSubItemQuantity;
                                }

                                // tag
                                if (typeof article.Tag !== 'undefined') {
                                    let _tags: any[];
                                    articleItem.tags = [];
                                    if (!Array.isArray(article.Tag)) {
                                        _tags = [article.Tag];
                                    } else {
                                        _tags = article.Tag;
                                    }
                                    _tags.forEach((tag) => {
                                        if (typeof tag['@attributes'] !== 'undefined') {
                                            if (typeof tag['@attributes'].Value !== 'undefined') {
                                                if (Array.isArray(articleItem.tags)) {
                                                    articleItem.tags.push(tag['@attributes'].Value);
                                                }
                                            }
                                        }
                                    });
                                }

                                // CrossSellingArticles
                                if (typeof article.CrossSellingArticles !== 'undefined') {
                                    if (typeof article.CrossSellingArticles.Article !== 'undefined') {
                                        let _crossSellingArticles: any[];
                                        articleItem.crossSellingArticles = [];
                                        if (!Array.isArray(article.CrossSellingArticles.Article)) {
                                            _crossSellingArticles = [article.CrossSellingArticles.Article];
                                        } else {
                                            _crossSellingArticles = article.CrossSellingArticles.Article;
                                        }
                                        _crossSellingArticles.forEach((crossSellingArticle) => {
                                            if (typeof crossSellingArticle['@attributes'] !== 'undefined') {
                                                if (typeof crossSellingArticle['@attributes'].Id !== 'undefined') {
                                                    if (Array.isArray(articleItem.crossSellingArticles)) {
                                                        articleItem.crossSellingArticles.push(crossSellingArticle['@attributes'].Id);
                                                    }
                                                }
                                            }
                                        });
                                    }
                                }

                                // AlternativeArticles
                                if (typeof article.AlternativeArticles !== 'undefined') {
                                    if (typeof article.AlternativeArticles.Article !== 'undefined') {
                                        let _alternativeArticles: any[];
                                        articleItem.alternativeArticles = [];
                                        if (!Array.isArray(article.AlternativeArticles.Article)) {
                                            _alternativeArticles = [article.AlternativeArticles.Article];
                                        } else {
                                            _alternativeArticles = article.AlternativeArticles.Article;
                                        }
                                        _alternativeArticles.forEach((alternativeArticle) => {
                                            if (typeof alternativeArticle['@attributes'] !== 'undefined') {
                                                if (typeof alternativeArticle['@attributes'].Id !== 'undefined') {
                                                    if (Array.isArray(articleItem.alternativeArticles)) {
                                                        articleItem.alternativeArticles.push(alternativeArticle['@attributes'].Id);
                                                    }
                                                }
                                            }
                                        });
                                    }
                                }

                                // AlternativePackSizeArticles
                                if (typeof article.AlternativePackSizeArticles !== 'undefined') {
                                    if (typeof article.AlternativePackSizeArticles.Article !== 'undefined') {
                                        let _alternativePackSizeArticles: any[];
                                        articleItem.alternativePackSizeArticles = [];
                                        if (!Array.isArray(article.AlternativePackSizeArticles.Article)) {
                                            _alternativePackSizeArticles = [article.AlternativePackSizeArticles.Article];
                                        } else {
                                            _alternativePackSizeArticles = article.AlternativePackSizeArticles.Article;
                                        }
                                        _alternativePackSizeArticles.forEach((alternativePackSizeArticle) => {
                                            if (typeof alternativePackSizeArticle['@attributes'] !== 'undefined') {
                                                if (typeof alternativePackSizeArticle['@attributes'].Id !== 'undefined') {
                                                    if (Array.isArray(articleItem.alternativePackSizeArticles)) {
                                                        articleItem.alternativePackSizeArticles.push(alternativePackSizeArticle['@attributes'].Id);
                                                    }
                                                }
                                            }
                                        });
                                    }
                                }
                                responseData.articles.push(articleItem);
                            }
                        }
                    });
                }

                if (processStatusDialog.typeDialog === 'ArticleInfo') {
                    processStatusDialog.status.response = true;
                    processStatusDialog.timestamps.response = Date.now();
                    processStatusDialog.data.response = responseData;
                    this.Wwks2CommClient.SetProcessedDialogData(responseId, processStatusDialog)
                }
            }
        }

    }
}