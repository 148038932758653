import * as React from 'react';
import { useSelector } from 'react-redux';

import { Domain } from 'api';
import { useGettext, TVScreen } from 'data-store';

import styles from './ProductEjectQueue.scss';

export default function ProductEjectQueue(props: { localizeArticleTitle: (articleTitle: Domain.LocalizedValue) => string }) {
    const { gettext } = useGettext();
    const queue = useSelector(TVScreen.interactionState.selectEjectQueue);

    if (queue.length === 0) {
        return null;
    }

    return (
        <div className={styles.TVScreenProductEjectQueue}>
            {queue.map(job => (
                <div
                    key={job.jobId}
                    className={`${styles.TVScreenProductEjectJob} ${job.status}`}
                >
                    <div>
                        <div>
                            {job.status === 'in-progress' ? (
                                <div>
                                    {gettext('Delivering :num:x :productName:. Patience...')
                                        .replace(':num:', job.quantity.toString())
                                        .replace(':productName:', props.localizeArticleTitle(job.articleTitle))}
                                    <br />
                                    <span>1/2</span>
                                </div>
                            ) : null}

                            {job.status === 'completed' ? (
                                <div>
                                    {gettext('Remove your products from the bottom of the device.')}
                                    <br />
                                    <span>2/2</span>
                                </div>
                            ) : null}

                            {job.status === 'failed' ? (
                                <div>
                                    {gettext(':num:x :productName: could not be fetched. Please try again.')
                                        .replace(':num:', job.quantity.toString())
                                        .replace(':productName:', props.localizeArticleTitle(job.articleTitle))}
                                </div>
                            ) : null}
                        </div>

                        <div>
                            <span className={styles.TVScreenProductEjectFilledBar} />
                            <span className={job.status === 'completed' ? styles.TVScreenProductEjectFilledBar : undefined} />
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}
