import { createAction, createReducer, Selector } from '@reduxjs/toolkit';
import moment from 'moment';

import { Domain } from 'api';
import { browserStorage, rangeGranularity } from 'utils';

import { ThunkAction, withPayloadType } from '@/action';
import { fraankApi } from '@/api';
import { selectLoggedInUserOwnershipIds, maybeSelectLoggedInUser } from '@/Authentication';
import { RootState } from '@/store';

const ANALYTICS_SALES_CHANNEL_SAVE_KEY = 'analyticsSalesChannelId-v2';
const ANALYTICS_TIME_FRAME_SAVE_KEY = 'analyticsTimeFrame-v3';
const ANALYTICS_SEARCH_SAVE_KEY = 'analyticsSearch-v1';
const ANALYTICS_MAIN_DATA_AGGREGATION = 'analyticsMainDataAggregation-v1';

export interface SalesChannelSearch {
    query: string;
    type: ('webshop' | 'vendingMachine')[];
}

export interface State {
    search: SalesChannelSearch;
    salesChannelFilters?: Domain.FraankSalesChannelFilters;
    timeFrame: Domain.TimeFrameComparison;
    salesChannelData?: Domain.FraankSalesChannelData;
    top100: boolean;
    salesChannels: Domain.FraankSalesChannel[];
    salesChannelTree: Domain.FraankSalesChannelTree;
    mainDataAggregation: Domain.FrankMainDataAggregation;
}

const defaultTimeFrame: Domain.TimeFrameComparison = {
    from: moment().subtract(30, 'day'),
    to: moment(),
    with: 'nothing',
};

const initialState: State = {
    search: {
        query: '',
        type: ['webshop', 'vendingMachine'],
    },
    timeFrame: defaultTimeFrame,
    top100: false,
    salesChannels: [],
    salesChannelTree: [],
    mainDataAggregation: 'average',
};

export const reducerActions = {
    setSearch: createAction('@fraank/overview/setSearch', withPayloadType<SalesChannelSearch>()),
    setSalesChannelFilters: createAction('@fraank/overview/setSalesChannelFilters', withPayloadType<Domain.FraankSalesChannelFilters>()),
    setTimeFrame: createAction('@fraank/overview/setTimeFrame', withPayloadType<Domain.TimeFrameComparison>()),
    setSalesChannelData: createAction('@fraank/overview/setSalesChannelData', withPayloadType<Domain.FraankSalesChannelData>()),
    setTop100: createAction('@fraank/overview/setTop100', withPayloadType<boolean>()),
    setSalesChannels: createAction('@fraank/overview/setSalesChannels', withPayloadType<Domain.FraankSalesChannel[]>()),
    setMainDataAggregation: createAction('@fraank/overview/setMainDataAggregation', withPayloadType<Domain.FrankMainDataAggregation>()),
};

export const overviewReducer = createReducer(initialState, builder =>
    builder
        .addCase(reducerActions.setSearch, (state, action) => {
            state.search = action.payload;
        })
        .addCase(reducerActions.setSalesChannelFilters, (state, action) => {
            state.salesChannelFilters = action.payload;
        })
        .addCase(reducerActions.setTimeFrame, (state, action) => {
            state.timeFrame = action.payload;
        })
        .addCase(reducerActions.setSalesChannelData, (state, action) => {
            state.salesChannelData = action.payload;
        })
        .addCase(reducerActions.setTop100, (state, action) => {
            state.top100 = action.payload;
        })
        .addCase(reducerActions.setSalesChannels, (state, action) => {
            state.salesChannels = action.payload;
            state.salesChannelTree = buildtSalesChannelTree(action.payload);
        })
        .addCase(reducerActions.setMainDataAggregation, (state, action) => {
            state.mainDataAggregation = action.payload;
        }),
);

function extractTag(tag: string, allTags?: string[]): string | undefined {
    if (!allTags || allTags.length === 0) {
        return undefined;
    }

    const foundTag = allTags.find(searchedTag => searchedTag.startsWith(tag));
    if (!foundTag) {
        return undefined;
    }

    return foundTag.replace(tag + ':', '');
}

function buildtSalesChannelTree(salesChannels: Domain.FraankSalesChannel[]): Domain.FraankSalesChannelTree {
    const allCountries: string[] = [];
    for (const salesChannel of salesChannels) {
        const salesChannelCountry = extractTag('country', salesChannel.tags);
        if (salesChannelCountry && !allCountries.includes(salesChannelCountry)) {
            allCountries.push(salesChannelCountry);
        }
    }

    const tree: Domain.FraankSalesChannelTree = [];

    for (const country of allCountries) {
        tree.push({
            id: country,
            type: 'country',
            label: country,
            filters: {
                tags: ['country:' + country],
            },
            children: buildStateChildren(salesChannels, country),
        });
    }

    return tree;
}

function buildStateChildren(salesChannels: Domain.FraankSalesChannel[], country: string): Domain.FraankSalesChannelTree {
    const states: string[] = [];

    for (const salesChannel of salesChannels) {
        const salesChannelCountry = extractTag('country', salesChannel.tags);
        if (salesChannelCountry !== country) {
            continue;
        }

        const salesChannelState = extractTag('state', salesChannel.tags);
        if (salesChannelState && !states.includes(salesChannelState)) {
            states.push(salesChannelState);
        }
    }

    let children: Domain.FraankSalesChannelTreeNode[] = [];

    if (states.length === 0) {
        children = getSalesChannelsForFilters(salesChannels, ['country:' + country]);
    } else {
        for (const state of states) {
            children.push({
                id: country + state,
                type: 'state',
                label: state,
                filters: {
                    tags: ['country:' + country, 'state:' + state],
                },
                children: buildCountyChildren(salesChannels, country, state),
            });
        }
    }

    return children;
}

function buildCountyChildren(salesChannels: Domain.FraankSalesChannel[], country: string, state: string): Domain.FraankSalesChannelTree {
    const counties: string[] = [];

    for (const salesChannel of salesChannels) {
        const salesChannelCountry = extractTag('country', salesChannel.tags);
        if (salesChannelCountry !== country) {
            continue;
        }

        const salesChannelState = extractTag('state', salesChannel.tags);
        if (salesChannelState !== state) {
            continue;
        }

        const salesChannelCounty = extractTag('county', salesChannel.tags);
        if (salesChannelCounty && !counties.includes(salesChannelCounty)) {
            counties.push(salesChannelCounty);
        }
    }

    let children: Domain.FraankSalesChannelTreeNode[] = [];

    if (counties.length === 0) {
        children = getSalesChannelsForFilters(salesChannels, ['country:' + country, 'state:' + state]);
    } else {
        for (const county of counties) {
            children.push({
                id: country + state + county,
                type: 'county',
                label: county,
                filters: {
                    tags: ['country:' + country, 'state:' + state, 'county:' + county],
                },
                children: getSalesChannelsForFilters(salesChannels, ['country:' + country, 'state:' + state, 'county:' + county]),
            });
        }
    }

    return children;
}

function getSalesChannelsForFilters(salesChannels: Domain.FraankSalesChannel[], tags: string[]): Domain.FraankSalesChannelTreeNode[] {
    const filters: Domain.FraankSalesChannelTreeNode[] = [];

    for (const salesChannel of salesChannels) {
        let hasAllTags = true;
        for (const tag of tags) {
            if (!salesChannel.tags.includes(tag)) {
                hasAllTags = false;
                break;
            }
        }

        if (!hasAllTags) {
            continue;
        }

        filters.push({
            id: salesChannel.salesChannelId,
            type: salesChannel.tags.includes('type:vendingMachine') ? 'vendingMachine' : 'webshop',
            label: salesChannel.name,
            filters: {
                salesChannelIds: [salesChannel.salesChannelId],
            },
            children: [],
        });
    }

    return filters;
}

export const selectSearch: Selector<RootState, SalesChannelSearch> = state => state.fraank.overview.search;
export const maybeSelectSalesChannelFilters: Selector<RootState, Domain.FraankSalesChannelFilters | undefined> = state =>
    state.fraank.overview.salesChannelFilters;
export const selectTimeFrame: Selector<RootState, Domain.TimeFrameComparison> = state => state.fraank.overview.timeFrame;
export const maybeSelectSalesChannelData: Selector<RootState, Domain.FraankSalesChannelData | undefined> = state =>
    state.fraank.overview.salesChannelData;
export const selectIsTop100: Selector<RootState, boolean> = state => state.fraank.overview.top100;
export const selectSalesChannels: Selector<RootState, Domain.FraankSalesChannel[]> = state => state.fraank.overview.salesChannels;
export const selectSalesChannelTree: Selector<RootState, Domain.FraankSalesChannelTree> = state => state.fraank.overview.salesChannelTree;
export const selectedMainDataAggregation: Selector<RootState, Domain.FrankMainDataAggregation> = state =>
    state.fraank.overview.mainDataAggregation;
export const maybeSelectSelectedSalesChannel: Selector<RootState, Domain.FraankSalesChannel | undefined> = state => {
    const filters = state.fraank.overview.salesChannelFilters;
    const salesChannels = state.fraank.overview.salesChannels;

    const selectedSalesChannelId =
        filters && filters.salesChannelIds && filters.salesChannelIds.length === 1 ? filters.salesChannelIds[0] : undefined;

    return selectedSalesChannelId ? salesChannels.find(salesChannel => salesChannel.salesChannelId === selectedSalesChannelId) : undefined;
};

export const SetSelectedTimeFrame =
    (timeFrame: Domain.TimeFrameComparison): ThunkAction =>
    async (dispatch, getState) => {
        const state = getState();
        const userIsLoggedIn = maybeSelectLoggedInUser(state);
        if (!userIsLoggedIn) {
            return;
        }

        const ownershipIds = selectLoggedInUserOwnershipIds(state);
        const stateSaveKey = ANALYTICS_TIME_FRAME_SAVE_KEY + '-' + ownershipIds.companyId + '-' + ownershipIds.branchId;

        browserStorage.setItem(stateSaveKey, JSON.stringify(timeFrame));
        await dispatch(reducerActions.setTimeFrame(timeFrame));
    };

export const SetSelectedSalesChannelFilters =
    (filters: Domain.FraankSalesChannelFilters): ThunkAction =>
    async (dispatch, getState) => {
        const state = getState();
        const userIsLoggedIn = maybeSelectLoggedInUser(state);
        if (!userIsLoggedIn) {
            return;
        }

        const ownershipIds = selectLoggedInUserOwnershipIds(state);
        const stateSaveKey = ANALYTICS_SALES_CHANNEL_SAVE_KEY + '-' + ownershipIds.companyId + '-' + ownershipIds.branchId;

        browserStorage.setItem(stateSaveKey, filters);
        await dispatch(reducerActions.setSalesChannelFilters(filters));
    };

export const SetSearch =
    (search: SalesChannelSearch): ThunkAction =>
    async (dispatch, getState) => {
        const state = getState();
        const userIsLoggedIn = maybeSelectLoggedInUser(state);
        if (!userIsLoggedIn) {
            return;
        }

        const ownershipIds = selectLoggedInUserOwnershipIds(state);
        const stateSaveKey = ANALYTICS_SEARCH_SAVE_KEY + '-' + ownershipIds.companyId + '-' + ownershipIds.branchId;

        browserStorage.setItem(stateSaveKey, search);
        await dispatch(reducerActions.setSearch(search));
    };

export const SetMainDataAggregation =
    (mainDataAggregation: Domain.FrankMainDataAggregation): ThunkAction =>
    async (dispatch, getState) => {
        const state = getState();
        const userIsLoggedIn = maybeSelectLoggedInUser(state);
        if (!userIsLoggedIn) {
            return;
        }

        const ownershipIds = selectLoggedInUserOwnershipIds(state);
        const stateSaveKey = ANALYTICS_MAIN_DATA_AGGREGATION + '-' + ownershipIds.companyId + '-' + ownershipIds.branchId;

        browserStorage.setItem(stateSaveKey, mainDataAggregation);
        await dispatch(reducerActions.setMainDataAggregation(mainDataAggregation));
    };

export const InitSelectedSalesChannelFilters = (): ThunkAction => async (dispatch, getState) => {
    const state = getState();
    const userIsLoggedIn = maybeSelectLoggedInUser(state);
    if (!userIsLoggedIn) {
        return;
    }

    const ownershipIds = selectLoggedInUserOwnershipIds(state);

    const stateSaveKey = ANALYTICS_SALES_CHANNEL_SAVE_KEY + '-' + ownershipIds.companyId + '-' + ownershipIds.branchId;
    const savedFilters = browserStorage.getItem<Domain.FraankSalesChannelFilters>(stateSaveKey);

    if (savedFilters && savedFilters.value) {
        await dispatch(reducerActions.setSalesChannelFilters(savedFilters.value));
    } else {
        const saleChannels = selectSalesChannels(state);
        await dispatch(
            SetSelectedSalesChannelFilters({
                salesChannelIds: saleChannels.length === 0 ? [] : [saleChannels[0].salesChannelId],
            }),
        );
    }
};

export const InitSearch = (): ThunkAction => async (dispatch, getState) => {
    const state = getState();
    const userIsLoggedIn = maybeSelectLoggedInUser(state);
    if (!userIsLoggedIn) {
        return;
    }

    const ownershipIds = selectLoggedInUserOwnershipIds(state);

    const stateSaveKey = ANALYTICS_SEARCH_SAVE_KEY + '-' + ownershipIds.companyId + '-' + ownershipIds.branchId;
    const savedSearch = browserStorage.getItem<SalesChannelSearch>(stateSaveKey);

    if (savedSearch && savedSearch.value) {
        await dispatch(reducerActions.setSearch(savedSearch.value));
    }
};

export const InitSelectedTimeFrame = (): ThunkAction => async (dispatch, getState) => {
    const state = getState();
    const userIsLoggedIn = maybeSelectLoggedInUser(state);
    if (!userIsLoggedIn) {
        return;
    }

    const ownershipIds = selectLoggedInUserOwnershipIds(state);

    const stateSaveKey = ANALYTICS_TIME_FRAME_SAVE_KEY + '-' + ownershipIds.companyId + '-' + ownershipIds.branchId;
    const timeFrame = browserStorage.getItem<Domain.TimeFrameComparison>(stateSaveKey);

    let loadedFromValidTimeFrame = false;
    if (timeFrame && timeFrame.value) {
        const loadedTimeFrame = selectTimeFrame(state);
        if (JSON.stringify(loadedTimeFrame) !== JSON.stringify(timeFrame.value)) {
            let parsedTimeFrame: Domain.TimeFrameComparison = {
                with: 'market',
                from: moment(timeFrame.value.from),
                to: moment(timeFrame.value.to),
            };

            if (timeFrame.value.with === 'self') {
                parsedTimeFrame = {
                    ...parsedTimeFrame,
                    with: 'self',
                    compareFrom: moment(timeFrame.value.compareFrom),
                    compareTo: moment(timeFrame.value.compareTo),
                };
            } else {
                parsedTimeFrame = {
                    ...parsedTimeFrame,
                    with: timeFrame.value.with,
                };
            }

            await dispatch(reducerActions.setTimeFrame(parsedTimeFrame));
            loadedFromValidTimeFrame = true;
        }
    }

    if (!loadedFromValidTimeFrame) {
        browserStorage.removeItem(stateSaveKey);
        await dispatch(reducerActions.setTimeFrame(defaultTimeFrame));
    }
};
export const InitMainDataAggregation = (): ThunkAction => async (dispatch, getState) => {
    const state = getState();
    const userIsLoggedIn = maybeSelectLoggedInUser(state);
    if (!userIsLoggedIn) {
        return;
    }

    const ownershipIds = selectLoggedInUserOwnershipIds(state);

    const stateSaveKey = ANALYTICS_MAIN_DATA_AGGREGATION + '-' + ownershipIds.companyId + '-' + ownershipIds.branchId;
    const savedMainDataAggregation = browserStorage.getItem<Domain.FrankMainDataAggregation>(stateSaveKey);

    if (savedMainDataAggregation && savedMainDataAggregation.value) {
        await dispatch(reducerActions.setMainDataAggregation(savedMainDataAggregation.value));
    }
};

export const loadSalesChannelData = (): ThunkAction => async (dispatch, getState) => {
    const state = getState();

    const timeFrame = selectTimeFrame(state);
    const granularity = rangeGranularity(timeFrame);
    const filters = maybeSelectSalesChannelFilters(state);
    const selected = maybeSelectSelectedSalesChannel(state);
    const mainDataAggregation = selectedMainDataAggregation(state);
    const salesChannelIds = filters?.salesChannelIds ? filters.salesChannelIds.join(',') : undefined;
    const tags = !filters?.salesChannelIds && filters?.tags ? filters.tags.join(',') : undefined;
    const type = extractTag('type', selected?.tags);

    const salesChannelData = await fraankApi.GetSalesChannelData(
        timeFrame,
        granularity,
        mainDataAggregation,
        filters
            ? {
                  salesChannelIds,
                  tags,
              }
            : undefined,
        timeFrame.with === 'market' && selected
            ? {
                  tags: [selected.tags.filter(tag => tag.startsWith('country:')), type ? 'type:' + type : false].filter(Boolean).join(','),
              }
            : timeFrame.with === 'region' && selected
              ? {
                    tags: [selected.tags.filter(tag => tag.startsWith('county:')), type ? 'type:' + type : false].filter(Boolean).join(','),
                }
              : undefined,
    );
    dispatch(reducerActions.setSalesChannelData(salesChannelData));
};

export const loadAllSalesChannels = (): ThunkAction => async dispatch => {
    await dispatch(reducerActions.setSalesChannels(await fraankApi.GetSalesChannels()));
};

export const load = (): ThunkAction => async dispatch => {
    await dispatch(loadAllSalesChannels());
    await dispatch(InitSearch());
    await dispatch(InitSelectedSalesChannelFilters());
    await dispatch(InitSelectedTimeFrame());
    await dispatch(InitMainDataAggregation());
    await dispatch(loadSalesChannelData());
};
