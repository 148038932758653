import { Domain } from 'api';

import { appointmentsApi } from '@/api';
import { makeActions, makeReducer, makeReducerActions, makeSelectors } from '@/makeDetailsState';

export const selectors = makeSelectors<Domain.AppointmentDetails>({
    getState: rootState => rootState.appointmentsReducer.details,
});

export const reducerActions = makeReducerActions<Domain.AppointmentDetails>({
    reducerPrefix: '@appointment/details',
});

export const actions = makeActions<Domain.AppointmentDetails>({
    loadApi: options => appointmentsApi.GetAppointmentDetails(options.urlParams.appointmentId),
    reducerActions,
    selectors,
});

export const detailsReducer = makeReducer<Domain.AppointmentDetails>({
    reducerActions,
});
