import { createAction, createReducer, Selector } from '@reduxjs/toolkit';
import moment from 'moment';

import { Infrastructure, Domain } from 'api';
import { config, tvAppVersion } from 'config';
import { DeviceLog } from 'logger';
import { browserStorage, uaData, PharmacyOnDuty, getPharmaciesOnDuty } from 'utils';

import { ThunkAction, withPayloadType } from '@/action';
import { deviceSignalingApi, collectApi, pharmacyOnDutyApi, emergencyAlertApi } from '@/api';
import { SignInWithDeviceSignalingToken, maybeSelectLoggedInUser, getValidLocalDeviceSignalingJsonWebToken } from '@/Authentication';
import { meditechCollectApi } from '@/meditechCollectApi';
import { meditechDeviceApi } from '@/meditechDeviceApi';
import { RootState } from '@/store';

import * as deviceState from './deviceState';
import * as interactionState from './interactionState';
import * as livePreviewState from './livePreviewState';
import { selectSlideshowDuration, selectVisibleTimelineSlides, selectDevice, selectTimeline } from './selectors';
import { DuplicatedSlide } from './types';
import { getDeviceCountry, getDeviceHasPharmacyOnDutyInformation } from './utils';
import * as vendingMachineState from './vendingMachineState';

type ProductImageSizes = {
    [key: string]: {
        width: number;
        height: number;
    };
};

export interface SlideshowState {
    preloaded: boolean;
    longestSlideshowDuration?: number;
    earliestSlideshowStartTime?: number;
    localTimeDelta: number;
    productImageSizes: ProductImageSizes;
    pharmaciesOnDuty: PharmacyOnDuty[];
    allProducts: Domain.SelectedProduct[];
    emergencyAlerts: Domain.BeAlert[];
}

const initialState: SlideshowState = {
    preloaded: false,
    longestSlideshowDuration: undefined,
    earliestSlideshowStartTime: undefined,
    localTimeDelta: 0,
    productImageSizes: {},
    pharmaciesOnDuty: [],
    emergencyAlerts: [],
    allProducts: [],
};

export const reducerActions = {
    setPreloaded: createAction('@tvScreen/slideshow/setPreloaded', withPayloadType<boolean>()),
    setAllProducts: createAction('@tvScreen/slideshow/setAllProducts', withPayloadType<Domain.SelectedProduct[]>()),
    setLongestSlideshowDuration: createAction('@tvScreen/slideshow/setLongestSlideshowDuration', withPayloadType<number | undefined>()),
    setEarliestSlideshowStartTime: createAction('@tvScreen/slideshow/setEarliestSlideshowStartTime', withPayloadType<number | undefined>()),
    setLocalTimeDelta: createAction('@tvScreen/slideshow/setLocalTimeDelta', withPayloadType<number>()),
    setPharmaciesOnDuty: createAction('@tvScreen/slideshow/setPharmaciesOnDuty', withPayloadType<PharmacyOnDuty[]>()),
    setEmergencyAlerts: createAction('@tvScreen/slideshow/setEmergencyAlerts', withPayloadType<Domain.BeAlert[]>()),
};

export const slideshowReducer = createReducer(initialState, builder =>
    builder
        .addCase(reducerActions.setPreloaded, (state, action) => {
            state.preloaded = action.payload;
        })
        .addCase(reducerActions.setAllProducts, (state, action) => {
            state.allProducts = action.payload;
        })
        .addCase(reducerActions.setLongestSlideshowDuration, (state, action) => {
            state.longestSlideshowDuration = action.payload;
        })
        .addCase(reducerActions.setEarliestSlideshowStartTime, (state, action) => {
            state.earliestSlideshowStartTime = action.payload;
        })
        .addCase(reducerActions.setPharmaciesOnDuty, (state, action) => {
            state.pharmaciesOnDuty = action.payload;
        })
        .addCase(reducerActions.setEmergencyAlerts, (state, action) => {
            state.emergencyAlerts = action.payload;
        })
        .addCase(reducerActions.setLocalTimeDelta, (state, action) => {
            state.localTimeDelta = action.payload;
        }),
);

export const selectPreloaded: Selector<RootState, boolean> = state => {
    return state.tvScreen.slideshow.preloaded;
};

export const getNextSlideIdAfter = (slides: DuplicatedSlide[], afterSlideId: string | undefined) => {
    if (!slides || slides.length === 0 || !afterSlideId) {
        return undefined;
    }

    const slideIndex =
        slides.findIndex(slide => {
            let isActive = slide.slideId === afterSlideId;

            if (slide.slideId !== slide.originalSlideId && afterSlideId && afterSlideId.indexOf('|') === -1) {
                isActive = slide.slideId === afterSlideId + '|0';
            }

            return isActive;
        }) || 0;
    const nextIndex = slideIndex === slides.length - 1 ? 0 : slideIndex + 1;

    if (slides[nextIndex]) {
        return slides[nextIndex].slideId;
    }

    return undefined;
};

export const getPrevSlideIdBefore = (slides: DuplicatedSlide[], beforeSlideId: string | undefined) => {
    if (!slides || slides.length === 0 || !beforeSlideId) {
        return undefined;
    }

    const slideIndex =
        slides.findIndex(slide => {
            let isActive = slide.slideId === beforeSlideId;

            if (slide.slideId !== slide.originalSlideId && beforeSlideId && beforeSlideId.indexOf('|') === -1) {
                isActive = slide.slideId === beforeSlideId + '|0';
            }

            return isActive;
        }) || 0;
    const prevIndex = slideIndex === 0 ? slides.length - 1 : slideIndex - 1;

    if (slides[prevIndex]) {
        return slides[prevIndex].slideId;
    }

    return undefined;
};

export const selectNextSlideIdAfter: (afterSlideId: string) => Selector<RootState, string | undefined> = afterSlideId => state => {
    const visibleTimelineSlides = selectVisibleTimelineSlides(state);

    return getNextSlideIdAfter(visibleTimelineSlides, afterSlideId);
};

export const selectPrevSlideIdBefore: (beforeSlideId: string) => Selector<RootState, string | undefined> = beforeSlideId => state => {
    const visibleTimelineSlides = selectVisibleTimelineSlides(state);

    return getPrevSlideIdBefore(visibleTimelineSlides, beforeSlideId);
};

export const selectLongestSlideshowDuration: Selector<RootState, number | undefined> = state => {
    return state.tvScreen.slideshow.longestSlideshowDuration;
};

export const selectEarliestSlideshowStartTime: Selector<RootState, number | undefined> = state => {
    return state.tvScreen.slideshow.earliestSlideshowStartTime;
};

export const selectLocalTimeDelta: Selector<RootState, number> = state => {
    return state.tvScreen.slideshow.localTimeDelta;
};

export const selectDurationToPad: Selector<RootState, number> = state => {
    const slideshowDuration = selectSlideshowDuration(state);
    const longestSlideshowDuration = state.tvScreen.slideshow.longestSlideshowDuration || slideshowDuration;

    return longestSlideshowDuration % slideshowDuration;
};

export const selectPharmaciesOnDuty: Selector<RootState, PharmacyOnDuty[]> = state => {
    return state.tvScreen.slideshow.pharmaciesOnDuty;
};
export const selectEmergencyAlerts: Selector<RootState, Domain.BeAlert[]> = state => {
    const nonExpiredAlerts = state.tvScreen.slideshow.emergencyAlerts.filter(alert => {
        const now = moment();
        const pubDate = moment(alert.pubDate);
        const expirationDate = moment(alert.expirationDate);

        return pubDate.isAfter(now.subtract(2, 'hours')) && expirationDate.isAfter(now);
    });
    return nonExpiredAlerts;
};

const listenForDeviceSignals = (): ThunkAction => async (dispatch, getState) => {
    const state = getState();
    const device = selectDevice(state);

    let livePreviewTimeout: ReturnType<typeof setTimeout> | undefined;
    const startLivePreviewTimeout = () => {
        if (livePreviewTimeout) {
            clearTimeout(livePreviewTimeout);
        }

        livePreviewTimeout = setTimeout(
            () => {
                dispatch(livePreviewState.reducerActions.setEnabled(false));
            },
            3 * 60 * 1000,
        );
    };

    deviceSignalingApi.listenForNightHatch(
        device?.type === 'vending-machine',
        info => {
            dispatch(
                interactionState.reducerActions.pushToHistory({
                    type: 'nightHatch',
                    nightHatchV2: !!info?.nightHatchV2,
                }),
            );
        },
        () => {
            dispatch(interactionState.reducerActions.clearHistory());
        },
    );

    deviceSignalingApi.listenForRemoteDebugConsole();

    deviceSignalingApi.onDeviceSignal(signal => {
        DeviceLog.signalingConnectionSignalReceived(signal);
        if (signal.type === 'deviceRefresh') {
            const currentState = getState();
            const hasBeenInteractedWith = interactionState.selectHasBeenInteractedWith(currentState);
            if (!hasBeenInteractedWith) {
                setTimeout(() => {
                    window.location.reload();
                }, signal.payload.delay * 1000);
            } else {
                dispatch(interactionState.reducerActions.setRefreshRequestedDuringInteraction(true));
            }
        } else if (signal.type === 'deviceReboot') {
            setTimeout(() => {
                meditechDeviceApi.RebootPC();
            }, signal.payload.delay * 1000);
        } else if (signal.type === 'deviceScreenshot') {
            if (device) {
                meditechDeviceApi.PrintScreen(
                    `${config.apiBaseUrl}/device/upload-screenshot/${device.deviceId}?auth=${Infrastructure.Container.getConstant('apiToken')}`,
                    'file.png',
                );
            }
        } else if (signal.type === 'reloadDeviceSoftware') {
            setTimeout(() => {
                meditechDeviceApi.RestartSoftware();
            }, signal.payload.delay);
        } else if (signal.type === 'contentVersion') {
            const currentState = getState();
            const hasBeenInteractedWith = interactionState.selectHasBeenInteractedWith(currentState);
            const disableInactivityPeriod = interactionState.selectDisableInactivityPeriod(currentState);
            if (!hasBeenInteractedWith && !disableInactivityPeriod) {
                dispatch(deviceState.handleNewDeviceContentVersion(signal.payload.contentVersion));
            } else {
                dispatch(interactionState.reducerActions.setContentVersionChangedDuringInteraction(signal.payload.contentVersion));
            }
        } else if (signal.type === 'livePreview') {
            if (signal.payload.type === 'start') {
                dispatch(livePreviewState.reducerActions.setEnabled(true));

                startLivePreviewTimeout();
            } else if (signal.payload.type === 'stop') {
                if (livePreviewTimeout) {
                    clearTimeout(livePreviewTimeout);
                }

                dispatch(livePreviewState.reducerActions.setEnabled(false));
            } else {
                dispatch(livePreviewState.reducerActions.setLivePreviewMessage(signal.payload));

                startLivePreviewTimeout();
            }
        } else if (signal.type === 'openMtCollectLocker') {
            if (device?.type === 'vending-machine' && device?.connectedToLockers) {
                const openLocker = async () => {
                    const status = await meditechCollectApi.OpenLock(parseInt(signal.payload.moduleId), parseInt(signal.payload.lockerId));
                    console.log('status', status);
                    if (status.success) {
                        await collectApi.UpdateLockerItems({
                            deviceId: device.deviceId,
                            moduleId: signal.payload.moduleId,
                            lockerId: signal.payload.lockerId,
                            items: [],
                            pickupId: undefined,
                        });
                        await dispatch(vendingMachineState.refreshCollectStock());
                    }
                };

                openLocker();
            }
        } else if (signal.type === 'refreshMtCollectLockerItems') {
            if (device?.type === 'vending-machine' && device?.connectedToLockers) {
                dispatch(vendingMachineState.refreshCollectStock());
            }
        }
    });
};

export const startWatchingForSlideshowTimingChanges = (): ThunkAction => async (dispatch, getState) => {
    deviceSignalingApi.onSlideshowTimingChange((duration, startTime) => {
        console.info('NEW SLIDESHOW TIMING', duration, startTime);
        const state = getState();
        const longestSlideshowDuration = selectLongestSlideshowDuration(state) || 0;
        const earliestSlideshowStartTime = selectEarliestSlideshowStartTime(state) || 0;

        if (duration !== longestSlideshowDuration || longestSlideshowDuration === 0) {
            dispatch(reducerActions.setLongestSlideshowDuration(duration));
        }

        if (startTime !== earliestSlideshowStartTime || earliestSlideshowStartTime === 0) {
            dispatch(reducerActions.setEarliestSlideshowStartTime(startTime));
        }
    });

    deviceSignalingApi.onLocalTimeDeltaCange(delta => {
        const state = getState();
        const localTimeDelta = selectLocalTimeDelta(state) || 0;

        if (delta !== localTimeDelta || localTimeDelta === 0) {
            dispatch(reducerActions.setLocalTimeDelta(delta));
        }
    });
};

let startSignalingTimer: ReturnType<typeof setTimeout>;

export const handleNewSlideshow = (): ThunkAction => async (_1, getState) => {
    const state = getState();
    const device = selectDevice(state);
    const timeline = selectTimeline(state);
    const slideshowDuration = selectSlideshowDuration(state);

    if (device && device.deviceId && device.groupName) {
        if (!deviceSignalingApi.isDisabled() && deviceSignalingApi.isConnected()) {
            deviceSignalingApi.update(
                timeline ? timeline.slideshowId : 'no-slideshow',
                timeline ? timeline.timelineId : 'no-timeline',
                slideshowDuration,
                timeline?.startTime || 0,
                device.contentVersion,
            );
        }
    }
};

export const startSignaling = (): ThunkAction => async (dispatch, getState) => {
    const state = getState();
    const device = selectDevice(state);
    const timeline = selectTimeline(state);
    const slideshowDuration = selectSlideshowDuration(state);
    const fingerprint = browserStorage.getDeviceFingerprint();
    const userAgentData = uaData();

    if (startSignalingTimer) {
        clearTimeout(startSignalingTimer);
    }

    if (deviceSignalingApi.isDisabled()) {
        return;
    }

    if (device && device.deviceId && device.groupName && device.macAddress && !deviceSignalingApi.isConnected()) {
        deviceSignalingApi.onConnect(() => {
            DeviceLog.signalingConnected();
            dispatch(listenForDeviceSignals());
        });

        deviceSignalingApi.onDisconnect(async () => {
            DeviceLog.signalingDisconnected();
            await dispatch(SignInWithDeviceSignalingToken(device.deviceId, true));
            clearTimeout(startSignalingTimer);
            startSignalingTimer = setTimeout(() => {
                dispatch(startSignaling());
            }, 10000);
        });

        const handleFailedToConnectOrNoToken = async () => {
            await dispatch(SignInWithDeviceSignalingToken(device.deviceId, true));
            clearTimeout(startSignalingTimer);
            startSignalingTimer = setTimeout(() => {
                dispatch(startSignaling());
            }, 10000);
        };

        const signalingToken = getValidLocalDeviceSignalingJsonWebToken(device.deviceId);
        if (!signalingToken) {
            DeviceLog.signalingNoOrExpiredToken();
            await handleFailedToConnectOrNoToken();
            return;
        }

        deviceSignalingApi.connect(
            device.companyId,
            device.branchId,
            device.groupName.trim().replace(/\s/g, '_'),
            timeline ? timeline.slideshowId : 'no-slideshow',
            device.deviceId,
            device.macAddress,
            slideshowDuration,
            timeline?.startTime || 0,
            {
                version: tvAppVersion,
                contentVersion: device.contentVersion,
                fingerprint,
                ...userAgentData,
                timelineId: timeline ? timeline.timelineId : 'no-timeline',
            },
            async () => {
                DeviceLog.signalingFailedToConnect();
                await handleFailedToConnectOrNoToken();
            },
        );
    }
};

let loadPharmaciesOnDutyTimeout: ReturnType<typeof setTimeout>;
let lastLoadedPharmaciesOnDuty = 0;
const LOAD_PHARMACIES_ON_DUTY_INTERVAL = 1000 * 60 * 30; // 30 minutes in seconds

export const loadPharmaciesOnDuty = (): ThunkAction => async (dispatch, getState) => {
    if (Date.now() - lastLoadedPharmaciesOnDuty < LOAD_PHARMACIES_ON_DUTY_INTERVAL) {
        return;
    }

    lastLoadedPharmaciesOnDuty = Date.now();

    const state = getState();
    const user = maybeSelectLoggedInUser(state);
    const device = selectDevice(state);
    if (
        user &&
        user.role === 'displayDevice' &&
        device &&
        getDeviceHasPharmacyOnDutyInformation(getDeviceCountry(device), user.apbNumber)
    ) {
        dispatch(
            reducerActions.setPharmaciesOnDuty(
                await getPharmaciesOnDuty({
                    country: getDeviceCountry(device),
                    apb: user.apbNumber,
                    limit: 10,
                    GetPharmaciesOnDuty: pharmacyOnDutyApi.GetPharmaciesOnDuty.bind(pharmacyOnDutyApi),
                }),
            ),
        );
    }

    if (loadPharmaciesOnDutyTimeout) {
        clearTimeout(loadPharmaciesOnDutyTimeout);
    }

    loadPharmaciesOnDutyTimeout = setTimeout(() => {
        dispatch(loadPharmaciesOnDuty());
    }, LOAD_PHARMACIES_ON_DUTY_INTERVAL + 1000);
};

let loadEmergencyAlertsTimeout: ReturnType<typeof setTimeout>;
let lastLoadedEmergencyAlerts = 0;
const LOAD_EMERGENCY_ALERTS_INTERVAL = 1000 * 60 * 1; // 1 minute in seconds

export const loadEmergencyAlerts = (): ThunkAction => async (dispatch, getState) => {
    if (Date.now() - lastLoadedEmergencyAlerts < LOAD_EMERGENCY_ALERTS_INTERVAL) {
        return;
    }

    lastLoadedEmergencyAlerts = Date.now();

    const state = getState();
    const user = maybeSelectLoggedInUser(state);
    const device = selectDevice(state);
    if (user && user.role === 'displayDevice' && device && getDeviceCountry(device) === 'BE') {
        const emergencyAlerts = await emergencyAlertApi.GetEmergencyAlerts({
            branchId: device.branchId,
            country: getDeviceCountry(device),
        });
        dispatch(reducerActions.setEmergencyAlerts(emergencyAlerts || []));
    }

    if (loadEmergencyAlertsTimeout) {
        clearTimeout(loadEmergencyAlertsTimeout);
    }

    loadEmergencyAlertsTimeout = setTimeout(() => {
        dispatch(loadEmergencyAlerts());
    }, LOAD_EMERGENCY_ALERTS_INTERVAL + 1000);
};
