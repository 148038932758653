import { createAction, createReducer, Selector } from '@reduxjs/toolkit';

import { Domain } from 'api';

import { ThunkAction, withPayloadType } from '@/action';
import { localisationApi } from '@/api';
import { RootState } from '@/store';

export let COUNTRY_CONFIG: Domain.AvailableCountries = {};
export let ALL_LOCALES: Domain.Locale[] = [];
export let ALL_COUNTRIES: Domain.Country[] = [];

export interface State {
    countries: Domain.AvailableCountries;
}

const initialState: State = {
    countries: {},
};

const reducerActions = {
    setCountries: createAction('@localisation/availableCountries/setCountries', withPayloadType<Domain.AvailableCountries>()),
};

export const selectCountries: Selector<RootState, Domain.AvailableCountries> = state => state.localisation.availableCountries.countries;

export const availableCountriesReducer = createReducer(initialState, builder =>
    builder.addCase(reducerActions.setCountries, (state, action) => {
        state.countries = action.payload;
        COUNTRY_CONFIG = action.payload;
        ALL_LOCALES = [];
        ALL_COUNTRIES = Object.keys(COUNTRY_CONFIG);
        for (const country of Object.values(COUNTRY_CONFIG)) {
            ALL_LOCALES = [...ALL_LOCALES, ...country.locales];
        }
    }),
);

export const loadCountries = (): ThunkAction => async dispatch => {
    dispatch(reducerActions.setCountries(await localisationApi.GetAvailableCountries()));
};

export const load = (): ThunkAction => async dispatch => {
    await dispatch(loadCountries());
};
