import { Domain } from 'api';

import { webshopPageApi } from '@/api';
import { makeActions, makeReducer, makeReducerActions, makeSelectors } from '@/makeOverviewState';

type WebshopPageProps = keyof Domain.WebshopPage;

export const defaultSorting: Domain.Sorting<WebshopPageProps> = {
    field: 'title',
    direction: 'ascending',
};

export const pageSize = 20;

export const selectors = makeSelectors<Domain.WebshopPage, WebshopPageProps>({
    getState: rootState => rootState.webshopPage.overview,
});

export const reducerActions = makeReducerActions<Domain.WebshopPage, WebshopPageProps>({
    reducerPrefix: '@webshopPage/overview',
});

export const actions = makeActions<Domain.WebshopPage, WebshopPageProps>({
    dataTableSaveKey: 'webshopPagesOverview-v5',
    loadApi: options => {
        if (options.globallySelectedWebshopId === undefined) {
            return;
        }

        return webshopPageApi.GetWebshopPages(options.pagination, options.sorting, options.globallySelectedWebshopId, undefined, {
            locale: options.globallySelectedWebshopLocale || options.globallySelectedWebshopDefaultLocale,
        });
    },
    defaultSorting,
    pageSize,
    getBaseUrl: () => '/webshops/pages',
    reducerActions,
    selectors,
});

export const overviewReducer = makeReducer<Domain.WebshopPage, WebshopPageProps>({
    defaultSorting,
    pageSize,
    reducerActions,
});
