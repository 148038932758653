import { Domain } from 'api';

import { webshopFaqLibraryApi } from '@/api';
import { makeActions, makeReducer, makeReducerActions, makeSelectors } from '@/makeOverviewState';

type WebshopFaqCategoryLibraryProps = keyof Domain.WebshopFaqCategory;

export const defaultSorting: Domain.Sorting<WebshopFaqCategoryLibraryProps> = {
    field: 'sortOrder',
    direction: 'ascending',
};

export const pageSize = 20;

export const selectors = makeSelectors<Domain.WebshopFaqCategory, WebshopFaqCategoryLibraryProps>({
    getState: rootState => rootState.webshopFaqCategoryLibrary.overview,
});

export const reducerActions = makeReducerActions<Domain.WebshopFaqCategory, WebshopFaqCategoryLibraryProps>({
    reducerPrefix: '@webshopFaqCategory/overview',
});

export const actions = makeActions<Domain.WebshopFaqCategory, WebshopFaqCategoryLibraryProps>({
    dataTableSaveKey: 'webshopFaqCategoriesLibraryOverview-v5',
    loadApi: options => {
        if (options.globallySelectedWebshopId === undefined) {
            return;
        }

        return webshopFaqLibraryApi.GetWebshopFaqLibraryCategories(options.pagination, options.sorting);
    },
    defaultSorting,
    pageSize,
    getBaseUrl: () => '/webshops/faq-categories-library',
    reducerActions,
    selectors,
});

export const overviewReducer = makeReducer<Domain.WebshopFaqCategory, WebshopFaqCategoryLibraryProps>({
    defaultSorting,
    pageSize,
    reducerActions,
});
