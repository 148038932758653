import { Company, Branch, StockSourceIds } from '@/Domain';

import Endpoint from './Endpoint';

export default class StockSourceEndpoint extends Endpoint {
    public async GetStockSourceIds(
        companyId: Company['companyId'],
        branchId?: Branch['branchId'],
        filters?: {
            stockTypes?: string;
        },
    ): Promise<StockSourceIds> {
        const url = `/stock-source/company/${companyId}/branch/${branchId}`;

        return await this.client.get({
            url,
            params: {
                filters,
            },
        });
    }
}
