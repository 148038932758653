import {
    Locale,
    Pagination,
    Sorting,
    WebshopPagesPage,
    WebshopPageDetails,
    UpdateWebshopPagePayload,
    ValidateWebshopPageTitleResponse,
    RemoveWebshopPagePayload,
} from '@/Domain';

import Endpoint from './Endpoint';

export default class PageEndpoint extends Endpoint {
    public async GetWebshopPages(
        pagination: Pagination,
        sorting: Sorting,
        webshopId: string,
        search?: string,
        filters?: {
            locale?: Locale;
        },
    ): Promise<WebshopPagesPage> {
        const url = `/webshop/${webshopId}/page/overview`;

        const response = await this.client.get({
            url,
            params: {
                pagination,
                sorting,
                search,
                filters: {
                    ...filters,
                    ...(webshopId
                        ? {
                              webshopId,
                          }
                        : null),
                },
            },
        });

        this.guardResponseSchema('#/definitions/WebshopPagesPage', response);

        return response;
    }

    public async GetWebshopPageDetails(webshopId: string, pageId: string, locale: Locale): Promise<WebshopPageDetails> {
        const response = await this.client.get({
            url: `/webshop/${webshopId}/page/${pageId}/${locale}/details`,
        });

        if (response.blocks) {
            response.blocks = response.blocks.map((block: any) => {
                return {
                    ...block,
                    widgets: block.widgets.map((widget: any) => {
                        if (widget.details && widget.details instanceof Array) {
                            widget.details = {};
                        }

                        return widget;
                    }),
                };
            });
        }

        this.guardResponseSchema('#/definitions/WebshopPageDetails', response);

        return response;
    }

    public async ValidateWebshopPageTitle(
        webshopId: string,
        pageId: string,
        locale: Locale,
        title: string,
    ): Promise<ValidateWebshopPageTitleResponse> {
        const respose = await this.client.get({
            url: `/webshop/${webshopId}/page/${pageId}/${locale}/validateTitle/${title}`,
        });

        this.guardResponseSchema('#/definitions/ValidateWebshopPageTitleResponse', respose);

        return respose;
    }

    public async UpdateWebshopPage(payload: UpdateWebshopPagePayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/UpdateWebshopPage',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Webshop\\Page\\UpdatePage\\UpdateWebshopPage',
                payload,
            },
        });
    }

    public async CreateWebshopPage(payload: UpdateWebshopPagePayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/CreateWebshopPage',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Webshop\\Page\\UpdatePage\\UpdateWebshopPage',
                payload,
            },
        });
    }

    public async RemoveWebshopPage(payload: RemoveWebshopPagePayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/RemoveWebshopPage',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Webshop\\Page\\RemoveWebshopPage\\RemoveWebshopPage',
                payload,
            },
        });
    }
}
