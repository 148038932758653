export default class ApiException extends Error {
    // tslint:disable-next-line:variable-name
    __proto__ = Error;

    constructor(message?: string) {
        super(message);

        Object.setPrototypeOf(this, ApiException.prototype);
    }
}
