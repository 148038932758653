import { LocalizedValue } from './Localisation';

export const ScientificCategorySources = ['bcfi', 'atc', 'osp'] as const;
export type ScientificCategorySource = (typeof ScientificCategorySources)[number];

export interface APIScientificCategory {
    scientificCategoryId: string;
    localizedNames: LocalizedValue;
    externalReferenceId: string;
    sequence: number;
    parentCategoryId: string | null;
}

export type APIScientificCategories = APIScientificCategory[];

export type ScientificCategory = APIScientificCategory & {
    subCategories: ScientificCategory[];
};

export type ScientificCategoriesValues = {
    [key in ScientificCategorySource]?: {
        [key: string]: number;
    };
};
