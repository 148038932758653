import { Domain } from 'api';

import { customPageApi } from '@/api';
import { makeActions, makeReducer, makeReducerActions, makeSelectors } from '@/makeOverviewState';

export type CustomPageProps = keyof Domain.CustomPage;

export const defaultSorting: Domain.Sorting<CustomPageProps> = {
    field: 'name',
    direction: 'ascending',
};

export const pageSize = 20;

export const selectors = makeSelectors<Domain.CustomPage, CustomPageProps>({
    getState: rootState => rootState.customPage.overview,
});

export const reducerActions = makeReducerActions<Domain.CustomPage, CustomPageProps>({
    reducerPrefix: '@customPage/overview',
});

export const actions = makeActions<
    Domain.CustomPage,
    CustomPageProps,
    {
        companyId?: Domain.Company['companyId'];
        branchId?: Domain.Branch['branchId'];
        locale?: Domain.Locale;
        includeShared?: string;
        ownedByCompany?: string;
    }
>({
    dataTableSaveKey: 'customPagesOverview-v5',
    loadApi: options =>
        customPageApi.GetCustomPages(options.ownership, options.pagination, options.sorting, options.search, options.filters),
    defaultSorting,
    pageSize,
    getBaseUrl: () => '/presentations/custom-pages',
    reducerActions,
    selectors,
});

export const overviewReducer = makeReducer<Domain.CustomPage, CustomPageProps>({
    defaultSorting,
    pageSize,
    reducerActions,
});
