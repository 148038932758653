import { createAction, Selector, createReducer } from '@reduxjs/toolkit';

import { Domain } from 'api';

import { ThunkAction, withPayloadType } from '@/action';
import { productCategoryApi, webshopPageApi, productSelectionApi, branchApi } from '@/api';
import { isValidLocale } from '@/I18n';
import { URLParams } from '@/routing';
import { RootState } from '@/store';

import { selectedCategoriesTreeBuilder } from './selectedCategoriesTreeBuilder';

export interface State {
    page?: Domain.WebshopPageDetails;
    selection?: Domain.ProductSelection;
    categoriesTree?: Domain.AvailableProductCategory;
    openingHours?: Domain.OpeningHours;
}

const initialState: State = {};

const reducerActions = {
    setPage: createAction('@webshopPage/update/setPage', withPayloadType<Domain.WebshopPageDetails>()),
    setSelection: createAction('@webshopPage/update/setSelection', withPayloadType<Domain.ProductSelection>()),
    setCategoriesTree: createAction('@webshopPage/update/setCategoriesTree', withPayloadType<Domain.AvailableProductCategory>()),
    setOpeningHours: createAction('@webshopPage/update/setOpeningHours', withPayloadType<Domain.OpeningHours | undefined>()),
};

export const updateReducer = createReducer(initialState, builder =>
    builder
        .addCase(reducerActions.setPage, (state, action) => {
            state.page = action.payload;
        })
        .addCase(reducerActions.setSelection, (state, action) => {
            state.selection = action.payload;
        })
        .addCase(reducerActions.setCategoriesTree, (state, action) => {
            state.categoriesTree = action.payload;
        })
        .addCase(reducerActions.setOpeningHours, (state, action) => {
            state.openingHours = action.payload;
        }),
);

export const selectOpeningHours: Selector<RootState, Domain.OpeningHours | undefined> = state => state.webshopPage.update.openingHours;

export const selectPage: Selector<RootState, Domain.WebshopPageDetails> = state => {
    const page = state.webshopPage.update.page;
    if (!page) {
        throw new Error('Page details not loaded');
    }

    return page;
};

export const selectSelection: Selector<RootState, Domain.ProductSelection> = state => {
    const selection = state.webshopPage.update.selection;
    if (!selection) {
        throw new Error('Selection not loaded');
    }
    return selection;
};

export const selectAllCategoriesTree: Selector<RootState, Domain.AvailableProductCategory> = state => {
    const categories = state.webshopPage.update.categoriesTree;

    if (!categories) {
        throw new Error('Categories not loaded');
    }

    return categories;
};

export const selectSelectedCategoriesTree: Selector<RootState, Domain.AvailableProductCategory | undefined> = state => {
    const categories = state.webshopPage.update.categoriesTree;
    const selection = selectSelection(state);

    if (!categories) {
        throw new Error('Categories not loaded');
    }

    return selectedCategoriesTreeBuilder(categories, selection);
};

export const loadPage =
    (webshopId: string, pageId: string, locale: Domain.Locale): ThunkAction<Promise<Domain.WebshopPageDetails>> =>
    async dispatch => {
        const pagepDetails = await webshopPageApi.GetWebshopPageDetails(webshopId, pageId, locale);

        await dispatch(reducerActions.setPage(pagepDetails));

        return pagepDetails;
    };

export const loadSelection =
    (branchId: string): ThunkAction =>
    async dispatch => {
        const selection = await productSelectionApi.GetProductSelection(
            {
                type: 'branch',
                ownerId: branchId,
            },
            'webShopProductSelection',
        );
        dispatch(reducerActions.setSelection(selection));
    };

export const loadCategoriesTree =
    (locale: Domain.Locale): ThunkAction =>
    async dispatch => {
        const categoriesTree = await productCategoryApi.GetCategoriesTree(locale);
        await dispatch(reducerActions.setCategoriesTree(categoriesTree));
    };

export const loadOpeningHours =
    (branchId: string): ThunkAction =>
    async dispatch => {
        const branchDetails = await branchApi.GetBranchDetails(branchId);
        await dispatch(reducerActions.setOpeningHours(branchDetails.openingHours || undefined));
    };

export const load =
    (options: { urlParams: URLParams }): ThunkAction =>
    async dispatch => {
        const locale = options.urlParams.locale;

        if (!isValidLocale(locale)) {
            throw new Error('Invalid locale');
        }

        const page = await dispatch(loadPage(options.urlParams.webshopId, options.urlParams.pageId, locale as Domain.Locale));

        await Promise.all([
            dispatch(loadCategoriesTree(page.locale)),
            dispatch(loadSelection(page.branchId)),
            dispatch(loadOpeningHours(page.branchId)),
        ]);
    };
