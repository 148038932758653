import * as React from 'react';

import { getScreenSizeInPX } from 'utils';

import { store } from '../state';
import Canvas from './Canvas';
import ProductImageSizeComputer from './ProductImageSizeComputer';
import TouchTargets from './TouchTargets';

import styles from './styles/Designer.scss';

export default function LivePreview(props: {
    className?: string;
    style?: React.CSSProperties;
    proxyImageURL: (url: string) => string;
    getMediaItemURL: (mediaItemId: string) => string;
    getVideoDataURL?: (videoURL: string) => string | undefined;
    getVideoMediaItemPreviewURL: (mediaItemId: string) => string;
    autoPlayVideos: boolean;
    disableVideoPosters: boolean;
    loopVideos: boolean;
    onProductClick: (productId: string) => void;
    usePreviewImageForContents?: false | string;
    renderVideo: (videoProps: React.VideoHTMLAttributes<any>) => React.ReactElement;
    locale: string;
    screenSizeInPX?: {
        width: number;
        height: number;
    };
    enableStockDisplay: boolean;
}) {
    const {
        className,
        style,
        proxyImageURL,
        getMediaItemURL,
        getVideoDataURL,
        getVideoMediaItemPreviewURL,
        onProductClick,
        autoPlayVideos,
        disableVideoPosters,
        loopVideos,
        renderVideo,
        locale,
    } = props;
    const { state } = React.useContext(store);
    const enableStockDisplay = props.enableStockDisplay && !state.canvas.forceHideStockDisplay;

    const screenSizeInPX = props.screenSizeInPX || getScreenSizeInPX(state.screenResolution);

    let usePreviewImageForContents = props.usePreviewImageForContents;

    if (usePreviewImageForContents) {
        if (state.canvas.backgroundType === 'video') {
            usePreviewImageForContents = false;
        }
    }

    if (usePreviewImageForContents) {
        for (const frame of state.canvas.frames) {
            if (frame.type === 'video') {
                usePreviewImageForContents = false;
                break;
            }
        }
    }

    return (
        <div
            className={(className || '') + ' ' + styles.ProductWallDesigner + ' ' + styles.ProductWallDesignerLiveMode}
            style={style}
        >
            {usePreviewImageForContents ? (
                <img
                    src={usePreviewImageForContents}
                    alt=""
                />
            ) : null}

            <Canvas
                onTopOfPreviewImage={!!usePreviewImageForContents}
                gettext={text => text}
                proxyImageURL={proxyImageURL}
                getProxyURL={url => url}
                getMediaItemURL={getMediaItemURL}
                getVideoDataURL={getVideoDataURL}
                getVideoMediaItemPreviewURL={getVideoMediaItemPreviewURL}
                renderMediaItemPicker={() => null}
                localizeProductName={() => ''}
                disableEditing={true}
                autoPlayVideos={autoPlayVideos}
                disableVideoPosters={disableVideoPosters}
                loopVideos={loopVideos}
                scaleTo={screenSizeInPX}
                renderVideo={renderVideo}
                locale={locale}
                enableStockDisplay={enableStockDisplay}
            />

            <TouchTargets
                scaleTo={screenSizeInPX}
                onProductClick={onProductClick}
            />

            <ProductImageSizeComputer
                proxyImageURL={proxyImageURL}
                usePreviewImageForContents={!!usePreviewImageForContents}
            />
        </div>
    );
}
