export function getItem<T = string>(
    key: string,
):
    | {
          value: T;
          session: boolean;
      }
    | undefined {
    const sessionItem = sessionStorage.getItem(key);
    const localItem = localStorage.getItem(key);
    let value = null;

    if (sessionItem !== null) {
        value = sessionItem;
    } else if (localItem !== null) {
        value = localItem;
    }

    if (value !== null) {
        if (value[0] === '{' || value[0] === '[') {
            try {
                value = JSON.parse(value);
            } catch (e) {
                // tslint:disable-next-line:no-console
                console.error(e);
                return undefined;
            }
        }

        return {
            value,
            session: !!sessionItem,
        };
    }

    return undefined;
}

export function setItem(key: string, value: string | object, session = false): void {
    if (typeof value !== 'string') {
        value = JSON.stringify(value);
    }

    if (session) {
        sessionStorage.setItem(key, value);
    } else {
        localStorage.setItem(key, value);
    }
}

export function removeItem(key: string, session?: boolean): void {
    if (session === undefined) {
        sessionStorage.removeItem(key);
        localStorage.removeItem(key);
    } else if (session) {
        sessionStorage.removeItem(key);
    } else {
        localStorage.removeItem(key);
    }
}

export function getDeviceFingerprint(): string {
    const item = getItem('device-fingerprint');

    if (item) {
        return item.value;
    }

    const fingerprint = Date.now().toString(10) + '-' + Math.floor(Math.random() * 10000).toString(10);
    setItem('device-fingerprint', fingerprint);

    return fingerprint;
}

export function clearDeviceFingerprint(): void {
    removeItem('device-fingerprint');
}
