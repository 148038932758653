import { createAction, Selector, createReducer } from '@reduxjs/toolkit';

import { Domain } from 'api';

import { ThunkAction, withPayloadType } from '@/action';
import { cmsApi } from '@/api';
import { URLParams } from '@/routing';
import { RootState } from '@/store';

export interface State {
    cmsItem?: Domain.CmsItem;
}

const initialState: State = {};

const reducerActions = {
    setCmsItem: createAction('@cmsItem/update/setCmsItem', withPayloadType<Domain.CmsItem>()),
};

export const updateReducer = createReducer(initialState, builder =>
    builder.addCase(reducerActions.setCmsItem, (state, action) => {
        state.cmsItem = action.payload;
    }),
);

export const selectCmsItem: Selector<RootState, Domain.CmsItem> = state => {
    const cmsItem = state.cmsItem.update.cmsItem;
    if (!cmsItem) {
        throw new Error('CMS Item not loaded');
    }

    return cmsItem;
};

export const loadCmsItem =
    (cmsItemId: string): ThunkAction<Promise<Domain.CmsItem>> =>
    async dispatch => {
        const cmsItem = await cmsApi.GetCmsItemDetails(cmsItemId);

        await dispatch(reducerActions.setCmsItem(cmsItem));

        return cmsItem;
    };

export const load =
    (options: { urlParams: URLParams }): ThunkAction =>
    async dispatch => {
        await dispatch(loadCmsItem(options.urlParams.cmsItemId));
    };
