import {
    Pagination,
    Sorting,
    CmsItem,
    CmsItemsPage,
    CreateCmsItemPayload,
    UpdateCmsItemPayload,
    RemoveCmsItemPayload,
    CmsTag,
    CmsTagsPage,
    CreateCmsTagPayload,
    UpdateCmsTagPayload,
    RemoveCmsTagPayload,
    PublicAnnouncementsPage,
    PublicCmsTagsPage,
} from '@/Domain';

import Endpoint from './Endpoint';

export default class CmsEndpoint extends Endpoint {
    public async GetCmsItems(
        pagination: Pagination,
        sorting: Sorting,
        filters?: {
            type?: string;
            locale?: string;
            status?: string;
        },
    ): Promise<CmsItemsPage> {
        const response = await this.client.get({
            url: '/cms/item/overview',
            params: {
                pagination,
                sorting,
                filters,
            },
        });

        this.guardResponseSchema('#/definitions/CmsItemsPage', response);

        return response;
    }

    public async GetPublicAnnouncements(
        locale: string,
        pagination: Pagination,
        sorting: Sorting,
        search?: string,
        filters?: {
            locale?: string;
            tagId?: CmsTag['cmsTagId'];
        },
    ): Promise<PublicAnnouncementsPage> {
        const response = await this.client.get({
            url: `/public-cms/announcement/locale/${locale}/overview`,
            params: {
                pagination,
                sorting,
                search,
                filters,
            },
        });

        this.guardResponseSchema('#/definitions/PublicAnnouncementsPage', response);

        return response;
    }

    public async GetCmsItemDetails(cmsItemId: string): Promise<CmsItem> {
        const response = await this.client.get({
            url: `/cms/item/${cmsItemId}/details`,
        });

        this.guardResponseSchema('#/definitions/CmsItem', response);

        return response;
    }

    public async GetCmsItemDetailsByTypeAndLocale(type: string, locale: string): Promise<CmsItem> {
        const response = await this.client.get({
            url: `/public-cms/item/type/${type}/locale/${locale}/details`,
        });

        this.guardResponseSchema('#/definitions/CmsItem', response);

        return response;
    }

    public async CreateCmsItem(payload: CreateCmsItemPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/CreateCmsItem',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Cms\\CreateCmsItem\\CreateCmsItem',
                payload,
            },
        });
    }

    public async UpdateCmsItem(payload: UpdateCmsItemPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/UpdateCmsItem',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Cms\\UpdateCmsItem\\UpdateCmsItem',
                payload,
            },
        });
    }

    public async RemoveCmsItem(payload: RemoveCmsItemPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/RemoveCmsItem',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Cms\\RemoveCmsItem\\RemoveCmsItem',
                payload,
            },
        });
    }

    public async GetCmsTags(
        pagination: Pagination,
        sorting: Sorting,
        filters?: {
            locale?: string;
        },
        search?: string,
    ): Promise<CmsTagsPage> {
        const response = await this.client.get({
            url: '/cms/tag/overview',
            params: {
                pagination,
                sorting,
                filters,
                search,
            },
        });

        this.guardResponseSchema('#/definitions/CmsTagsPage', response);

        return response;
    }

    public async GetPublicCmsTags(pagination: Pagination, sorting: Sorting, locale: string, search?: string): Promise<PublicCmsTagsPage> {
        const response = await this.client.get({
            url: `/public-cms/tag/locale/${locale}/overview`,
            params: {
                pagination,
                sorting,
                locale,
                search,
            },
        });

        this.guardResponseSchema('#/definitions/PublicCmsTagsPage', response);

        return response;
    }

    public async GetCmsTagDetails(cmsTagId: string): Promise<CmsTag> {
        const response = await this.client.get({
            url: `/cms/tag/${cmsTagId}/details`,
        });

        this.guardResponseSchema('#/definitions/CmsTag', response);

        return response;
    }

    public async CreateCmsTag(payload: CreateCmsTagPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/CreateCmsTag',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Cms\\CreateCmsTag\\CreateCmsTag',
                payload,
            },
        });
    }

    public async UpdateCmsTag(payload: UpdateCmsTagPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/UpdateCmsTag',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Cms\\UpdateCmsTag\\UpdateCmsTag',
                payload,
            },
        });
    }

    public async RemoveCmsTag(payload: RemoveCmsTagPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/RemoveCmsTag',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Cms\\RemoveCmsTag\\RemoveCmsTag',
                payload,
            },
        });
    }
}
