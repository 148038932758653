import { Domain } from 'api';
import { preloadFont, preloadImage as basePreloadImage } from 'preloaders';

import mapProductToSlideshowProduct from '@/mapProductToSlideshowProduct';

export function preloadCustomPage(
    usePreviewImageForContents: boolean,
    content: any,
    products: Domain.SelectedProductDetails[],
    preview: string,
    locale: Domain.Locale,
    preloadHiddenSlideHotspot: (
        hiddenSlideType: 'customPage' | 'productWall' | 'video' | 'image',
        hiddenSlideId: string,
        name: string,
    ) => void,
    preloadPharmacistsOnDuty: () => void,
    preloadMediaItem: (mediaItemId: string | undefined, isVideo: boolean) => Promise<void>,
    preloadVideoMediaItemPreview: (mediaItemId: string | undefined) => Promise<void>,
    preloadImage: (imageSrc: string) => Promise<any>,
    preloadEmergencyAlerts: () => void,
): Promise<void>[] {
    let promises: Promise<void>[] = [];

    let doNotPreload = preview && usePreviewImageForContents;
    for (const frame of content.frames) {
        if (
            (frame.type === 'text' && frame.styleBackground && frame.backgroundType === 'video') ||
            (frame.type === 'media' && frame.mediaType === 'video') ||
            (frame.type === 'background' && frame.backgroundType === 'video')
        ) {
            doNotPreload = false;
            break;
        }
    }

    const includesPharmacistOnDutyFrame = content.frames.find((frame: any) => frame.type === 'pharmacistOnDuty');
    const includesOpeningHoursFrame = content.frames.find((frame: any) => frame.type === 'openingHours');
    const includeEmergencyAlert = content.frames.find((frame: any) => frame.type === 'emergencyAlert');

    if (includesPharmacistOnDutyFrame || includesOpeningHoursFrame || includeEmergencyAlert) {
        doNotPreload = false;
    }

    if (doNotPreload) {
        promises.push(preloadImage(preview));
    } else {
        promises.push(preloadFont(content.priceStyle.text.font));
        promises.push(preloadFont(content.promoPriceStyle.text.font));

        for (const frame of content.frames) {
            if (frame.type === 'media' && frame.mediaType === 'image' && frame.mediaItemId) {
                promises.push(preloadMediaItem(frame.mediaItemId, false));
            } else if (
                (frame.type === 'background' || frame.type === 'text') &&
                frame.backgroundType === 'image' &&
                frame.backgroundMediaItemId
            ) {
                promises.push(preloadMediaItem(frame.backgroundMediaItemId, false));
            } else if (frame.type === 'shelf') {
                if (frame.shelfStyle === '2D') {
                    promises = promises.concat(preloadShelf2D());
                } else if (frame.shelfStyle === '3D') {
                    promises = promises.concat(preloadShelf3D());
                }
            } else if (frame.type === 'product' && frame.productId) {
                if (frame.placedProduct.customPriceStyle) {
                    promises.push(preloadFont(frame.placedProduct.customPriceStyle.text.font));
                }

                if (frame.placedProduct.customPromoPriceStyle) {
                    promises.push(preloadFont(frame.placedProduct.customPromoPriceStyle.text.font));
                    promises.push(preloadFont(frame.placedProduct.customPromoPriceStyle.promoText.font));
                }

                const selectedProduct = products.find(product => product.productId === frame.productId);
                if (selectedProduct) {
                    const slideshowProduct = mapProductToSlideshowProduct(selectedProduct, locale);

                    if (frame.productMediaType === 'frontal' && Domain.slideshowProductHasFrontalImage(slideshowProduct)) {
                        promises.push(preloadImage(slideshowProduct.frontalImage));
                    } else if (frame.productMediaType === 'packshot' && Domain.slideshowProductHasProductImage(slideshowProduct)) {
                        promises.push(preloadImage(slideshowProduct.productImage));
                    }
                }
            } else if (frame.type === 'text' || frame.type === 'pharmacistOnDuty' || frame.type === 'emergencyAlert') {
                promises.push(preloadFont(frame.textStyle.font));
            }
        }

        for (const frame of content.frames) {
            if (frame.type === 'media' && frame.mediaType === 'video' && frame.mediaItemId) {
                promises.push(preloadMediaItem(frame.mediaItemId, true));
                promises.push(preloadVideoMediaItemPreview(frame.mediaItemId));
            } else if (
                (frame.type === 'background' || frame.type === 'text') &&
                frame.backgroundType === 'video' &&
                frame.backgroundMediaItemId
            ) {
                promises.push(preloadMediaItem(frame.backgroundMediaItemId, true));
                promises.push(preloadVideoMediaItemPreview(frame.backgroundMediaItemId));
            }
        }
    }
    for (const frame of content.frames) {
        if (frame.type === 'hotSpot' && frame.hotSpotType === 'hiddenSlide' && frame.hiddenSlideType && frame.hiddenSlideId) {
            preloadHiddenSlideHotspot(frame.hiddenSlideType, frame.hiddenSlideId, frame.hiddenSlideName);
        } else if (frame.type === 'pharmacistOnDuty') {
            preloadPharmacistsOnDuty();
        } else if (frame.type === 'emergencyAlert') {
            preloadEmergencyAlerts();
        }
    }

    return promises;
}

const preloadShelf2D = (): Promise<void>[] => {
    return [
        basePreloadImage('/static/images/shelves-v1/2d/shelf-middle.png'),
        basePreloadImage('/static/images/shelves-v1/2d/shelf-left.png'),
        basePreloadImage('/static/images/shelves-v1/2d/shelf-right.png'),
    ];
};

const preloadShelf3D = (): Promise<void>[] => {
    return [
        basePreloadImage('/static/images/shelves-v1/3d/shelf-middle.png'),
        basePreloadImage('/static/images/shelves-v1/3d/shelf-left.png'),
        basePreloadImage('/static/images/shelves-v1/3d/shelf-right.png'),
    ];
};
