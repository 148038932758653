import { Domain } from 'api';

import { userApi } from '@/api';
import { makeActions, makeReducer, makeReducerActions, makeSelectors } from '@/makeDetailsState';

export const selectors = makeSelectors<Domain.UserSubscribedNotifications>({
    getState: rootState => rootState.user.subscribedNotifications,
});

export const reducerActions = makeReducerActions<Domain.UserSubscribedNotifications>({
    reducerPrefix: '@user/subscribedNotifications',
});

export const actions = makeActions<Domain.UserSubscribedNotifications>({
    loadApi: options =>
        options.user
            ? userApi.GetUserSubscribedNotifications(options.user?.userId)
            : Promise.resolve({} as Domain.UserSubscribedNotifications),
    reducerActions,
    selectors,
});

export const subscribedNotificationsReducer = makeReducer<Domain.UserSubscribedNotifications>({
    reducerActions,
});
