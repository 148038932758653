import { createAction, createReducer, Selector } from '@reduxjs/toolkit';

import { Domain } from 'api';

import { ThunkAction, withPayloadType } from '@/action';
import { slideshowApi, timelineApi } from '@/api';
import { URLParams } from '@/routing';
import { RootState } from '@/store';

export interface State {
    slideshow?: Domain.Slideshow;
    timelines?: Domain.Timeline[];
}

const initialState: State = {};

const reducerActions = {
    setSlideshow: createAction('@slideshow/update/setSlideshow', withPayloadType<Domain.Slideshow>()),
    setTimelines: createAction('@slideshow/update/setTimelines', withPayloadType<Domain.Timeline[]>()),
};

export const updateReducer = createReducer(initialState, builder =>
    builder
        .addCase(reducerActions.setSlideshow, (state, action) => {
            state.slideshow = action.payload;
        })
        .addCase(reducerActions.setTimelines, (state, action) => {
            state.timelines = action.payload;
        }),
);

export const selectSlideshow: Selector<RootState, Domain.Slideshow> = state => {
    const slideshow = state.slideshow.update.slideshow;

    if (!slideshow) {
        throw new Error('Slideshow details not loaded');
    }
    return slideshow;
};

export const selectTimelines: Selector<RootState, Domain.Timeline[]> = state => {
    const timelines = state.slideshow.update.timelines;

    if (!timelines) {
        throw new Error('Slideshow timelines not loaded');
    }
    return timelines;
};

export const loadSlideshow =
    (slideshowId: string): ThunkAction =>
    async dispatch => {
        const [slideshowDetails, timelinesPage] = await Promise.all([
            slideshowApi.GetSlideshowDetails(slideshowId),
            timelineApi.GetTimelines(slideshowId, { page: 1, size: 999 }, { field: 'positionInEditor', direction: 'ascending' }),
        ]);

        dispatch(reducerActions.setSlideshow(slideshowDetails));
        dispatch(reducerActions.setTimelines(timelinesPage.items));
    };

export const load =
    (options: { urlParams: URLParams }): ThunkAction =>
    async dispatch => {
        await dispatch(loadSlideshow(options.urlParams.slideshowId));
    };
