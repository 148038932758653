import ApiException from './ApiException';

export default class HttpApiException extends ApiException {
    // tslint:disable-next-line:variable-name
    __proto__ = Error;

    protected _status: number | undefined;
    protected _text: any | undefined;
    protected _shortMessage: any | undefined;

    constructor(message?: string, status?: number, text?: string, shortMessage?: string) {
        super(message);

        this._status = status;
        this._text = text;
        this._shortMessage = shortMessage;

        Object.setPrototypeOf(this, HttpApiException.prototype);
    }

    public get status(): number | undefined {
        return this._status;
    }

    public get text(): string | undefined {
        return this._text;
    }

    public get shortMessage(): string | undefined {
        return this._shortMessage;
    }

    public get json(): any | undefined {
        try {
            return JSON.parse(this.message);
        } catch (e) {
            return undefined;
        }
    }

    public toString(): string {
        return `${this._status}: ${this._text} - ${this.message}`;
    }
}
