import { LocalizedValue, Day } from './Localisation';
import { Pagination } from './Pagination';
import { Sorting } from './Sorting';
export enum AppointmentTypeQuestionType {
    Text = 'text',
    YesNo = 'yesNo',
    SingleSelect = 'singleSelect',
    MultiSelect = 'multiSelect',
}
export interface AdditionalQuestion {
    question: LocalizedValue;
    type: AppointmentTypeQuestionType;
    options: LocalizedValue[];
    isRequired: boolean;
}

export interface TimeWindow {
    from: string;
    till: string;
}
export type BookableTimeWindows = {
    [key in Day]?: TimeWindow[] | undefined;
};

export interface AppointmentTypeConfiguration {
    backgroundColor?: string | null;
}
export interface AppointmentTypeDetails {
    branchId: string;
    appointmentTypeId: string;
    type: LocalizedValue;
    description: LocalizedValue;
    startDate: string;
    endDate: string;
    slotDurationInMinutes: number;
    maxConcurrentSlots: number;
    additionalQuestions: AdditionalQuestion[];
    shouldConsiderPublicHolidays: boolean;
    shouldConsiderVacations: boolean;
    advanceBookingPeriodInHours: number;
    bookableTimeWindows: BookableTimeWindows;
    configuration?: AppointmentTypeConfiguration | null;
    sortOrder: number;
}

export interface AppointmentTypes {
    appointmentTypeId: string;
    type: LocalizedValue;
    startDate: string;
    endDate: string;
    createdAt: string;
    updatedAt: string | null;
    configuration?: AppointmentTypeConfiguration | null;
    sortOrder: number;
}

export interface AppointmentTypesPage {
    items: AppointmentTypes[];
    total: number;
    pagination: Pagination;
    sorting?: Sorting<keyof AppointmentTypes> | null;
    search?: string | null;
}

export type UpdateAppointmentTypePayload = Omit<AppointmentTypeDetails, 'createdOn' | 'updatedOn'>;
export type CreateAppointmentTypePayload = Omit<AppointmentTypeDetails, 'createdOn' | 'updatedOn'>;

export interface RemoveAppointmentTypePayload {
    appointmentTypeId: string;
}

export interface UpdateAppointmentTypeSequencePayload {
    appointmentTypeIds: string[];
    branchId: string;
}

export interface AppointmentTypeSlot {
    startsAt: string;
    durationInMinutes: number;
}
export type AppointmentTypeSlotsList = AppointmentTypeSlot[];
