import { SnapPoints } from 'ui-components';

import { Shelf } from '../types';

export default function computeShelfSnapPoints(shelves: Shelf[], areaHeight: number): SnapPoints {
    const top = shelves[0].height;
    let numShelves = shelves.length;

    if (shelves[0].type === 'customShelf') {
        numShelves -= 1;
    }

    if (shelves.length > 1 && shelves[shelves.length - 1].type === 'emptyShelf') {
        numShelves -= 1;
    }

    if (
        shelves.length > 2 &&
        shelves[shelves.length - 2].type === 'productsShelf' &&
        shelves[shelves.length - 2].y + shelves[shelves.length - 2].height === areaHeight
    ) {
        numShelves -= 1;
    }

    const vertical = [];
    const step = (areaHeight - top) / (numShelves + 1);

    for (let i = 1; i <= numShelves; i++) {
        vertical.push(Math.round(top + i * step));
    }

    return {
        vertical,
        horizontal: [],
    };
}
