import { UrlParams } from './ApiClient';

export default function urlParamsToString(params?: UrlParams, prefix?: string): string {
    if (!params) {
        return '';
    }

    const urlParts = [];
    for (const param in params) {
        if (params.hasOwnProperty(param)) {
            const key = prefix ? prefix + '[' + param + ']' : param;
            const value = params[param];

            if (value !== null && value !== undefined) {
                urlParts.push(
                    typeof value === 'object'
                        ? urlParamsToString(value, key)
                        : encodeAndEscapeURIComponent(key) + '=' + encodeAndEscapeURIComponent(value),
                );
            }
        }
    }

    if (urlParts.length === 0) {
        return '';
    }

    const urlPrefix = !prefix ? '?' : '';
    return urlPrefix + urlParts.join('&');
}

export function encodeAndEscapeURIComponent(value: string): string {
    return encodeURIComponent(value)
        .replace(/\-/g, '%2D')
        .replace(/\_/g, '%5F')
        .replace(/\./g, '%2E')
        .replace(/\!/g, '%21')
        .replace(/\~/g, '%7E')
        .replace(/\*/g, '%2A')
        .replace(/\'/g, '%27')
        .replace(/\(/g, '%28')
        .replace(/\)/g, '%29');
}
