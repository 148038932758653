export default function getDecimalPrecision(x: number): number {
    if (Math.abs(x) < 1) {
        const parts = x.toExponential().split('e-');
        const matissaDecimalPart = parts[0].split('.')[1];
        return (matissaDecimalPart ? matissaDecimalPart.length : 0) + parseInt(parts[1], 10);
    }

    const decimalPart = x.toString().split('.')[1];
    return decimalPart ? decimalPart.length : 0;
}
