import {
    Wwks2StatusDialog,
    wwks2ArticlePriceResponse,
    wwks2ArticlePriceItemInformation,
    wwks2Article
} from '../../../../Domain';
import Wwks2CommClient from '../../Wwks2CommClient';
import { DIALOG_TIMEOUTS } from '../Wwks2DefaultValues';


export default class Wwks2ArticlePrice {

    private Wwks2CommClient: Wwks2CommClient;

    public constructor(Wwks2CommClient: Wwks2CommClient) {
        this.Wwks2CommClient = Wwks2CommClient;
    }

    public sendArticlePriceRequest(barcodes: string[], currency: string = 'EUR', dialogId: string | number | boolean = false): Promise<Wwks2StatusDialog> {
        return new Promise(resolve => {

            if(typeof dialogId === 'boolean'){
                dialogId = this.Wwks2CommClient.GetWwks2DialogId();
            }

            const barcodesMap = barcodes.map((barcode) => {
                return {
                    '@': {
                        'Id': barcode
                    }
                }
            })

            const wwks = {
                'ArticlePriceRequest': {
                    '@': {
                        'Id': dialogId,
                        'Source': this.Wwks2CommClient.GetSourceId(),
                        'Destination': this.Wwks2CommClient.GetClientId(),
                        'Currency': currency
                    },
                    '#': {
                        'Article': barcodesMap
                    }
                }
            }
            this.Wwks2CommClient.createAndSendDialog(wwks, 'ArticlePrice', dialogId).then(status => {
                resolve(status);
            });

        });
    }

    public sendAndProcessArticlePriceRequest(barcodes: string[], currency: string = 'EUR', dialogId: string | number | boolean = false): Promise<Wwks2StatusDialog> {
        return new Promise(resolve => {
            this.sendArticlePriceRequest(barcodes, currency, dialogId)
                .then((data: Wwks2StatusDialog) => {
                    if (data.status) {
                        const timeoutTimer = setTimeout(() => {
                            let processStatusDialog = this.Wwks2CommClient.GetProcessedDialogData(data.dialogId);
                            if (typeof processStatusDialog !== 'boolean') {
                                if (processStatusDialog.typeDialog === 'ArticlePrice') {
                                    processStatusDialog.status.cancel = true;
                                    this.Wwks2CommClient.SetProcessedDialogData(data.dialogId, processStatusDialog);
                                }
                            }

                        }, DIALOG_TIMEOUTS.ArticlePrice);
                        this.Wwks2CommClient.getResponse(data.dialogId, 'ArticlePrice')
                            .then((status) => {
                                clearTimeout(timeoutTimer);
                                resolve(status)
                            });
                    } else {
                        resolve(data);
                    }
                });
        });
    }

    public handleMessage(WWKS: any) {

        if (typeof WWKS.ArticlePriceResponse !== 'undefined') {

            const responseId = WWKS.ArticlePriceResponse['@attributes'].Id;
            let processStatusDialog = this.Wwks2CommClient.GetProcessedDialogData(responseId);
            if (typeof processStatusDialog !== 'boolean') {

                const responseData: wwks2ArticlePriceResponse = {
                    'clientId': 0,
                    'articles': [],
                }

                responseData.clientId = WWKS.ArticlePriceResponse['@attributes'].Source;

                if (typeof WWKS.ArticlePriceResponse.Article !== 'undefined') {
                    let _articles: any[];

                    if (!Array.isArray(WWKS.ArticlePriceResponse.Article)) {
                        _articles = [WWKS.ArticlePriceResponse.Article];
                    } else {
                        _articles = WWKS.ArticlePriceResponse.Article;
                    }
                    _articles.forEach((article) => {
                        if (typeof article['@attributes'] !== 'undefined') {
                            if (typeof article['@attributes'].Id !== 'undefined') {
                                const priceInformations: wwks2ArticlePriceItemInformation[] = [];
                                if (typeof article.PriceInformation !== 'undefined') {

                                    let _priceInformations: any[];
                                    if (!Array.isArray(article.PriceInformation)) {
                                        _priceInformations = [article.PriceInformation];
                                    } else {
                                        _priceInformations = article.PriceInformation;
                                    }

                                    _priceInformations.forEach(priceInformation => {
                                        if (typeof priceInformation['@attributes'].Category !== 'undefined' && priceInformation['@attributes'].Price) {
                                            const _priceInformation: wwks2ArticlePriceItemInformation = {
                                                price: parseInt(priceInformation['@attributes'].Price),
                                                category: priceInformation['@attributes'].Category.toLowerCase(),
                                                quantity: 1,
                                            }
                                            // below are optionals
                                            if (typeof priceInformation['@attributes'].BasePrice !== 'undefined') {
                                                _priceInformation.basePrice = parseInt(priceInformation['@attributes'].BasePrice);
                                            }
                                            if (typeof priceInformation['@attributes'].BasePriceUnit !== 'undefined') {
                                                _priceInformation.basePriceUnit = priceInformation['@attributes'].BasePriceUnit;
                                            }
                                            if (typeof priceInformation['@attributes'].VAT !== 'undefined') {
                                                _priceInformation.vat = parseFloat(priceInformation['@attributes'].VAT);
                                            }
                                            if (typeof priceInformation['@attributes'].Description !== 'undefined') {
                                                _priceInformation.description = priceInformation['@attributes'].Description;
                                            }
                                            priceInformations.push(_priceInformation);
                                        }
                                    });
                                }

                                const articleItem: wwks2Article = {
                                    id: article['@attributes'].Id,
                                    priceInformations
                                }
                                responseData.articles.push(articleItem);
                            }
                        }
                    });
                }

                if (processStatusDialog.typeDialog === 'ArticlePrice') {
                    processStatusDialog.status.response = true;
                    processStatusDialog.timestamps.response = Date.now();
                    processStatusDialog.data.response = responseData;
                    this.Wwks2CommClient.SetProcessedDialogData(responseId, processStatusDialog)
                }
            }
        }

    }
}