import { combineReducers } from '@reduxjs/toolkit';

import { detailsReducer } from './detailsState';
import { globalSelectionReducer } from './globalSelectionState';
import { overviewReducer } from './overviewState';
import { updateReducer } from './updateState';
import { nightHatchReducer } from './vendingMachineNightHatchState';
import { vendingMachineOverviewReducer } from './vendingMachineOverviewState';
import { updateHomeScreenProductsReducer } from './vendingMachineUpdateHomeScreenProductsState';

export const deviceReducer = combineReducers({
    globalSelection: globalSelectionReducer,
    details: detailsReducer,
    overview: overviewReducer,
    vendingMachineOverview: vendingMachineOverviewReducer,
    update: updateReducer,
    updateHomeScreenProducts: updateHomeScreenProductsReducer,
    nightHatch: nightHatchReducer,
});
