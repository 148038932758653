import { NightHatchOnDutyFeeList, SaveNightHatchOnDutyFees } from '@/Domain';

import Endpoint from './Endpoint';

export default class NightHatchOnDutyFeeEndpoint extends Endpoint {
    public async GetFees(): Promise<NightHatchOnDutyFeeList> {
        const response = await this.client.get({
            url: '/branch/custom-duty-fees',
        });

        this.guardResponseSchema('#/definitions/NightHatchOnDutyFeeList', response);

        return response;
    }

    public async SaveFees(payload: SaveNightHatchOnDutyFees): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/SaveNightHatchOnDutyFees',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Company\\Branch\\UpdateCustomDutyFees\\UpdateCustomDutyFees',
                payload,
            },
        });
    }
}
