import { useSelector } from 'react-redux';

import { Domain } from 'api';

import { selectCurrentLocale } from './I18n';

export default function useLocalize() {
    const uiLocale = useSelector(selectCurrentLocale);

    return (value: Domain.LocalizedValue, locale?: Domain.Locale) => {
        if (!locale) {
            locale = uiLocale;
        }

        if (value[locale]) {
            return value[locale];
        }

        return null;
    };
}
