import * as React from 'react';

import classNames from 'classnames';

import { KeyboardHandler } from '@/core';

import Color from './Color';

import styles from './ColorPicker.scss';

interface IProps {
    color?: Color;
    onChange: (newColor?: Color) => void;
    presets: (string | undefined)[];
    closeDropdown: (fromClick?: boolean) => void;
}

export default class Presets extends React.PureComponent<IProps> {
    private keyHandler = new KeyboardHandler();

    render() {
        const { presets } = this.props;

        return (
            <div className={styles.ColorPickerPresets}>
                {presets.map((preset, index) => {
                    let presetColor: Color | undefined = new Color(preset);
                    let backgroundColor = presetColor.toHexString();
                    let borderColor = presetColor.clone().delimitFromWhiteBackground().toHexString();

                    if (preset === '' || preset === undefined) {
                        presetColor = undefined;
                        backgroundColor = 'transparent';
                        borderColor = '#000';
                    }

                    return (
                        <a
                            key={index}
                            className={classNames(
                                styles.ColorPickerPresetsColorBox,
                                preset === '' || preset === undefined ? styles.ColorPickerPresetsColorBoxTransparent : undefined,
                            )}
                            style={{
                                backgroundColor,
                                borderColor,
                            }}
                            onMouseDown={event => event.preventDefault()}
                            onClick={(event: React.MouseEvent) => {
                                event.preventDefault();
                                this.handleColorClick(presetColor);
                            }}
                            onKeyDown={this.keyHandler.handleKey(['ENTER', 'SPACE'], () => {
                                this.handleColorClick(presetColor);
                                this.props.closeDropdown(false);
                            })}
                            href=""
                            tabIndex={0}
                        />
                    );
                })}
            </div>
        );
    }

    private handleColorClick(presetColor?: Color) {
        const { onChange } = this.props;

        if (!onChange) {
            return;
        }

        onChange(presetColor);
    }
}
