import { createAction, Selector } from '@reduxjs/toolkit';

import { Domain } from 'api';

import { ThunkAction, withPayloadType } from '@/action';
import { appointmentsApi, appointmentTypeApi } from '@/api';
import { makeActions, makeReducer, makeReducerActions, makeSelectors } from '@/makeOverviewState';
import { URLParams, URLQuery } from '@/routing';
import { RootState } from '@/store';

type AppointmentProps = keyof Domain.AppointmentsPageDetails;

export const defaultSorting: Domain.Sorting<AppointmentProps> = {
    field: 'startsAt',
    direction: 'ascending',
};

export const pageSize = 1000;
export const setAppointmentTypeList = createAction(
    '@Appointments/calendarView/setAppointmentTypeList',
    withPayloadType<Domain.AppointmentTypesPage>(),
);
export const selectAppointmentTypeList: Selector<RootState, Domain.AppointmentTypesPage> = state =>
    state.appointmentsReducer.calendarView.appointmentTypeList;
export const loadAppointmentTypeList = (): ThunkAction => async dispatch => {
    const appointmentTypeList = await appointmentTypeApi.GetAppointmentTypes({
        page: 1,
        size: 1000,
    });
    dispatch(setAppointmentTypeList(appointmentTypeList));
};

export const selectors = makeSelectors<Domain.AppointmentsPageDetails, AppointmentProps>({
    getState: rootState => rootState.appointmentsReducer.calendarView,
});

export const reducerActions = makeReducerActions<Domain.AppointmentsPageDetails, AppointmentProps>({
    reducerPrefix: '@Appointments/calendarView',
});

export const baseActions = makeActions<
    Domain.AppointmentsPageDetails,
    AppointmentProps,
    {
        withoutPastAppointments?: string;
        startsAtFrom?: string;
        startsAtTo?: string;
        isToday?: string;
    }
>({
    dataTableSaveKey: 'appointmentsCalendar-v1',
    loadApi: options => {
        return appointmentsApi.GetAppointments(
            {
                page: 1,
                size: pageSize,
            },
            defaultSorting,
            '',
            {
                ...options.filters,
                withoutPastAppointments: 'false',
            },
        );
    },
    defaultSorting,
    pageSize,
    getBaseUrl: () => '/webshops/appointments/overview/calendar',
    reducerActions,
    selectors,
});
export const actions = {
    ...baseActions,
    load:
        (options: { urlParams: URLParams; urlQuery: URLQuery }): ThunkAction =>
        async dispatch => {
            await Promise.all([dispatch(baseActions.load(options)), dispatch(loadAppointmentTypeList())]);
        },
};

export const loadCalendarView =
    ({ endDate, startDate }: { endDate: string; startDate: string }): ThunkAction =>
    async (dispatch, getState) => {
        const state = getState();
        const filters = selectors.selectFilters(state);
        const response = await appointmentsApi.GetAppointments(
            {
                page: 1,
                size: 1000,
            },
            defaultSorting,
            '',
            {
                ...filters,
                startsAtTo: endDate,
                startsAtFrom: startDate,
                withoutPastAppointments: 'false',
            },
        );
        await dispatch(reducerActions.setCurrentPage(response));
    };

export const calendarViewReducer = makeReducer<
    Domain.AppointmentsPageDetails,
    AppointmentProps,
    {
        appointmentTypeList: Domain.AppointmentTypesPage;
    }
>({
    defaultSorting,
    pageSize,
    reducerActions,
    extraCases: builder =>
        builder.addCase(setAppointmentTypeList, (state, action) => {
            state.appointmentTypeList = action.payload;
        }),
});
