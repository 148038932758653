import { Domain } from 'api';

import { auditLogApi } from '@/api';
import { makeActions, makeReducer, makeReducerActions, makeSelectors } from '@/makeOverviewState';

export type AuditLogProps = keyof Domain.AuditLog | 'companyId' | 'branchId';

export const defaultSorting: Domain.Sorting<AuditLogProps> = {
    field: 'loggedOn',
    direction: 'descending',
};

export const pageSize = 20;

export const selectors = makeSelectors<Domain.AuditLog, AuditLogProps>({
    getState: rootState => rootState.auditLog.overview,
});

export const reducerActions = makeReducerActions<Domain.AuditLog, AuditLogProps>({
    reducerPrefix: '@auditLog/overview',
});

export const actions = makeActions<Domain.AuditLog, AuditLogProps>({
    dataTableSaveKey: 'AuditLogsOverview-v5',
    loadApi: options => auditLogApi.GetAuditLog(options.pagination, options.sorting, options.filters),
    defaultSorting,
    pageSize,
    getBaseUrl: () => '/dashboard/audit-log',
    reducerActions,
    selectors,
});

export const overviewReducer = makeReducer<Domain.AuditLog, AuditLogProps>({
    defaultSorting,
    pageSize,
    reducerActions,
});
