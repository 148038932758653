import {
    Wwks2StatusDialog,
    Wwks2MTRemoteLine
} from '../../../../Domain';
import Wwks2CommClient from '../../Wwks2CommClient';


export default class Wwks2MTRemoteLogs {

    private Wwks2CommClient: Wwks2CommClient;

    public constructor(Wwks2CommClient: Wwks2CommClient) {
        this.Wwks2CommClient = Wwks2CommClient;
    }

    public sendMTRemoteLogs(logs: Wwks2MTRemoteLine[], dialogId: string | number | boolean = false): Promise<Wwks2StatusDialog> {
        return new Promise(resolve => {

            if(typeof dialogId === 'boolean'){
                dialogId = this.Wwks2CommClient.GetWwks2DialogId();
            }

            const remoteLogLines = logs.map((log) => {
                return {
                    '@': {
                        'Seq': log.seq,
                        'SenderType': log.senderType,
                        'SenderName': log.senderName,
                        'Level': log.level,
                        'RemoteLogTime': log.logtime
                    },
                    '#':{
                        'Msg':{
                            'CDATA': log.message
                        }
                    }
                }
            });

            const wwks = {
                'MTRemoteLogRequest': {
                    '@': {
                        'Id': dialogId
                    },
                    '#': {
                        'RemoteLogLine': remoteLogLines
                    }
                }
            }
            this.Wwks2CommClient.createAndSendDialog(wwks, 'MTRemoteLog', dialogId).then(status => {
                resolve(status);
            });

        });
    }
}