import DelegatedEventTarget from './DelegatedEventTarget';
import Peer from './Peer';

interface GroupPeer {
    peerId: string;
    peerMeta?: {
        slideshowDuration: number;
        slideshowStartTime: number;
    };
}

export default class HeartbeatSyncGroup extends DelegatedEventTarget {
    private readonly peer: Peer;
    private readonly groupId: string;
    private groupInfo: GroupPeer[] = [];

    public constructor(peer: Peer, groupId: string) {
        super();

        this.peer = peer;
        this.groupId = groupId;

        this.peer.onFirstConnect(() => {
            this.peer.joinGroup(this.groupId);
            this.peer.addEventListener('groupChanged', this.handleGroupChanged);
        });
    }

    public setMetaData(metaData: GroupPeer['peerMeta']): void {
        if (this.peer.isConnected()) {
            this.peer.setGroupMetaData(this.groupId, metaData);
        }
    }

    public getPeerWithLongestSlideshowInfo(): GroupPeer {
        return this.getGroupPeerWithLongestSlideshow();
    }

    public getPeerWithEarliestSlideshowStartTimeInfo(): GroupPeer {
        return this.getGroupPeerWithEarliestSlideshowStartTime();
    }

    public close(): void {
        this.peer.removeEventListener('groupChanged', this.handleGroupChanged);
    }

    private handleGroupChanged = (event: MessageEvent): void => {
        const data = JSON.parse(event.data);
        if (data.payload.groupId === this.groupId) {
            this.groupInfo = data.payload.peers;
            this.dispatchEvent(new Event('groupChanged'));
        }
    };

    private getGroupPeerWithLongestSlideshow(): GroupPeer {
        let maxSlideshowDuration = -1;
        for (const otherPeer of this.groupInfo) {
            if (otherPeer.peerMeta && otherPeer.peerMeta.slideshowDuration > maxSlideshowDuration) {
                maxSlideshowDuration = otherPeer.peerMeta.slideshowDuration;
            }
        }

        for (const otherPeer of this.groupInfo) {
            if (otherPeer.peerMeta && otherPeer.peerMeta.slideshowDuration === maxSlideshowDuration) {
                return otherPeer;
            }
        }

        return {
            peerId: this.peer.id,
            peerMeta: this.peer.getInfo().peerMeta as unknown as GroupPeer['peerMeta'],
        };
    }

    private getGroupPeerWithEarliestSlideshowStartTime(): GroupPeer {
        let earliestStartTime = Number.MAX_SAFE_INTEGER;
        for (const otherPeer of this.groupInfo) {
            if (otherPeer.peerMeta && otherPeer.peerMeta.slideshowStartTime < earliestStartTime) {
                earliestStartTime = otherPeer.peerMeta.slideshowStartTime;
            }
        }

        for (const otherPeer of this.groupInfo) {
            if (otherPeer.peerMeta && otherPeer.peerMeta.slideshowStartTime === earliestStartTime) {
                return otherPeer;
            }
        }

        return {
            peerId: this.peer.id,
            peerMeta: this.peer.getInfo().peerMeta as unknown as GroupPeer['peerMeta'],
        };
    }
}
