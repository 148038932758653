import { createAction, Selector } from '@reduxjs/toolkit';

import { Domain } from 'api';

import { ThunkAction, withPayloadType } from '@/action';
import { deviceApi, branchApi } from '@/api';
import { makeActions, makeReducer, makeReducerActions, makeSelectors } from '@/makeDetailsState';
import { URLParams, URLQuery } from '@/routing';
import { RootState } from '@/store';

export const setBranch = createAction('@device/details/setBranch', withPayloadType<Domain.BranchDetails>());
export const selectBranch: Selector<RootState, Domain.BranchDetails | undefined> = state => state.device.details.branch;

export const selectors = makeSelectors<Domain.DeviceDetails>({
    getState: rootState => rootState.device.details,
});

export const reducerActions = makeReducerActions<Domain.DeviceDetails>({
    reducerPrefix: '@device/details',
});

const baseActions = makeActions<Domain.DeviceDetails>({
    loadApi: options => deviceApi.GetDeviceDetails(options.urlParams.deviceId),
    reducerActions,
    selectors,
});

export const detailsReducer = makeReducer<
    Domain.DeviceDetails,
    {
        branch?: Domain.BranchDetails;
    }
>({
    reducerActions,
    extraCases: builder =>
        builder.addCase(setBranch, (state, action) => {
            state.branch = action.payload;
        }),
});

export const actions = {
    ...baseActions,
    load:
        (options: { urlParams: URLParams; urlQuery: URLQuery }): ThunkAction =>
        async dispatch => {
            const device = await dispatch(baseActions.load(options));

            if (device) {
                const branch = await branchApi.GetBranchDetails(device.branchId);
                dispatch(setBranch(branch));
            }
        },
};
