import { createAction, Selector, createReducer } from '@reduxjs/toolkit';

import { Domain } from 'api';

import { ThunkAction, withPayloadType } from '@/action';
import { cmsApi } from '@/api';
import { loadLocalisations } from '@/Localisation/overviewState';
import { URLParams } from '@/routing';
import { RootState } from '@/store';

export interface State {
    cmsTag?: Domain.CmsTag;
}

const initialState: State = {};

const reducerActions = {
    setCmsTag: createAction('@cmsTag/update/setCmsTag', withPayloadType<Domain.CmsTag>()),
};

export const updateReducer = createReducer(initialState, builder =>
    builder.addCase(reducerActions.setCmsTag, (state, action) => {
        state.cmsTag = action.payload;
    }),
);

export const selectCmsTag: Selector<RootState, Domain.CmsTag> = state => {
    const cmsTag = state.cmsTag.update.cmsTag;
    if (!cmsTag) {
        throw new Error('CMS Tag not loaded');
    }

    return cmsTag;
};

export const loadCmsTag =
    (cmsTagId: string): ThunkAction<Promise<Domain.CmsTag>> =>
    async dispatch => {
        const cmsTag = await cmsApi.GetCmsTagDetails(cmsTagId);

        await dispatch(reducerActions.setCmsTag(cmsTag));

        return cmsTag;
    };

export const load =
    (options: { urlParams: URLParams }): ThunkAction =>
    async dispatch => {
        await Promise.all([dispatch(loadCmsTag(options.urlParams.cmsTagId)), dispatch(loadLocalisations())]);
    };
