import {
    Pagination,
    ApprovedEmailAddressesPage,
    ApproveEmailAddressPayload,
    BulkApproveEmailAddressesPayload,
    UnapproveEmailAddressPayload,
    OrderReviewConditions,
    ConfigureOrderReviewConditionsPayload,
} from '@/Domain';

import Endpoint from './Endpoint';

export default class OrderReviewEndpoint extends Endpoint {
    public async GetApprovedEmailAddresses(branchId: string, pagination: Pagination): Promise<ApprovedEmailAddressesPage> {
        const response = await this.client.get({
            url: `/branch/${branchId}/approved-email-address/overview`,
            params: {
                pagination,
            },
        });

        this.guardResponseSchema('#/definitions/ApprovedEmailAddressesPage', response);
        return response;
    }

    public async ApproveEmailAddress(payload: ApproveEmailAddressPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/ApproveEmailAddress',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Order\\ApprovedEmailAddress\\ApproveEmailAddress\\ApproveEmailAddress',
                payload,
            },
        });
    }

    public async BulkApproveEmailAddresses(payload: BulkApproveEmailAddressesPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/BulkApproveEmailAddresses',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Order\\ApprovedEmailAddress\\BulkApproveEmailAddresses\\BulkApproveEmailAddresses',
                payload,
            },
        });
    }

    public async UnapproveEmailAddress(payload: UnapproveEmailAddressPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/UnapproveEmailAddress',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Order\\ApprovedEmailAddress\\UnapproveEmailAddress\\UnapproveEmailAddress',
                payload,
            },
        });
    }

    public async GetOrderReviewConditions(branchId: string): Promise<OrderReviewConditions> {
        const response = await this.client.get({
            url: `/branch/order-review-conditions/${branchId}`,
        });

        this.guardResponseSchema('#/definitions/OrderReviewConditions', response);
        return response;
    }

    public async ConfigureOrderReviewConditions(payload: ConfigureOrderReviewConditionsPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/ConfigureOrderReviewConditions',
            body: {
                commandName:
                    'App\\Domain\\WriteModel\\Company\\Branch\\BranchOrderReviewConditions\\ConfigureBranchOrderReviewConditions\\ConfigureBranchOrderReviewConditions',
                payload,
            },
        });
    }
}
