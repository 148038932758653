import { createAction, createReducer as createReduxReducer, Selector, combineReducers } from '@reduxjs/toolkit';

import { Domain } from 'api';

import { ThunkAction, withPayloadType } from '@/action';
import { productSelectionApi } from '@/api';
import * as categoriesTreeState from '@/AvailableProduct/categoriesTreeState';
import {
    makeSelectors,
    makeActions,
    makeReducerActions,
    makeReducer,
    OverviewState,
    initialState,
} from '@/ProductSelection/makeOverviewState';
import { URLParams } from '@/routing';
import { RootState } from '@/store';

import { selectGlobalSelectedDevice } from './globalSelectionState';

export const productSelectors = makeSelectors({
    getState: rootState => rootState.device.updateHomeScreenProducts.products,
});

const productReducerActions = makeReducerActions({
    reducerPrefix: '@vendingMachine/updateHomeScreenProducts/products',
});

export const productActions = makeActions(
    {
        dataTableSaveKey: 'updateHomeScreenProducts-v3',
        reducerActions: productReducerActions,
        selectors: productSelectors,
    },
    'robotProductSelection',
);

const productReducer = makeReducer({
    reducerActions: productReducerActions,
    initialState: {
        ...initialState,
        completeness: 'suitedForVendingMachine',
    },
});

export interface HomeScreenState {
    selection?: Domain.ProductSelection;
}

const initialHomeScreenState: HomeScreenState = {};

export interface State {
    main: HomeScreenState;
    products: OverviewState;
}

const reducerActions = {
    setSelection: createAction('@vendingMachine/homeScreenProducts/setSelection', withPayloadType<Domain.ProductSelection>()),
};

export const updateHomeScreenProductsReducer = combineReducers({
    main: createReduxReducer(initialHomeScreenState, builder =>
        builder.addCase(reducerActions.setSelection, (state, action) => {
            state.selection = action.payload;
        }),
    ),
    products: productReducer,
});

export const selectProductSelection: Selector<RootState, Domain.ProductSelection> = state => {
    const selection = state.device.updateHomeScreenProducts.main.selection;

    if (!selection) {
        throw new Error('Selection not loaded');
    }

    return selection;
};

export const loadSelection =
    (ownership: Domain.Ownership): ThunkAction<Promise<Domain.ProductSelection>> =>
    async dispatch => {
        const selection = await productSelectionApi.GetProductSelection(ownership, 'robotProductSelection');
        dispatch(reducerActions.setSelection(selection));

        return selection;
    };

export const load =
    (options: { urlParams: URLParams }): ThunkAction =>
    async (dispatch, getState) => {
        const state = getState();
        const device = selectGlobalSelectedDevice(state);

        if (!device) {
            throw new Error('No selected device');
        }

        await Promise.all([
            dispatch(
                categoriesTreeState.actions.load({
                    ...options,
                    urlParams: {
                        locale: device.companyLocales[0],
                        ...options.urlParams,
                    },
                }),
            ),
            dispatch(
                loadSelection({
                    type: 'branch',
                    ownerId: device.branchId,
                }),
            ),
            dispatch(productActions.loadProducts()),
        ]);
    };
