import { Domain } from 'api';

import { ThunkAction } from '@/action';
import { webshopApi } from '@/api';
import { loadConfiguredIntegrationsCountByType } from '@/Integration/configuredIntegrationsTypeState';
import { makeActions, makeReducer, makeReducerActions, makeSelectors } from '@/makeDetailsState';
import { URLParams } from '@/routing';

export const selectors = makeSelectors<Domain.WebshopShippingMethodDetailsList>({
    getState: rootState => rootState.webshopShippingMethod.overview,
});

export const reducerActions = makeReducerActions<Domain.WebshopShippingMethodDetailsList>({
    reducerPrefix: '@webshopShippingMethod/overview',
});

const baseActions = makeActions<Domain.WebshopShippingMethodDetailsList>({
    loadApi: async options => {
        if (!options.globallySelectedWebshopId) {
            throw new Error('No selected webshop');
        }

        const configuration = await webshopApi.GetWebshopConfiguration(options.globallySelectedWebshopId);

        const shippingMethodsList: Domain.WebshopShippingMethodDetailsList = [];

        if (configuration.shippingMethods && configuration.shippingMethods.pickup) {
            shippingMethodsList.push({
                type: 'pickup',
                ...configuration.shippingMethods.pickup,
            });
        }

        if (configuration.shippingMethods && configuration.shippingMethods.locker) {
            shippingMethodsList.push({
                type: 'locker',
                ...configuration.shippingMethods.locker,
            });
        }

        if (configuration.shippingMethods && configuration.shippingMethods.localDelivery) {
            shippingMethodsList.push({
                type: 'localDelivery',
                ...configuration.shippingMethods.localDelivery,
            });
        }

        if (configuration.shippingMethods && configuration.shippingMethods.serviceDelivery) {
            shippingMethodsList.push({
                type: 'serviceDelivery',
                ...configuration.shippingMethods.serviceDelivery,
            });
        }

        return shippingMethodsList;
    },
    reducerActions,
    selectors,
});

export const actions = {
    ...baseActions,
    load:
        (options: { urlParams: URLParams }): ThunkAction =>
        async dispatch => {
            await Promise.all([dispatch(baseActions.load(options)), dispatch(loadConfiguredIntegrationsCountByType())]);
        },
};

export const overviewReducer = makeReducer<Domain.WebshopShippingMethodDetailsList>({
    reducerActions,
});
