import { Domain } from 'api';

import { importApi } from '@/api';
import { makeActions, makeReducer, makeReducerActions, makeSelectors } from '@/makeOverviewState';

type ImportErrorProps = keyof Domain.ImportError;

const defaultSorting: Domain.Sorting<ImportErrorProps> = {
    field: 'lineNumber',
    direction: 'descending',
};

const pageSize = 20;

export const selectors = makeSelectors<Domain.ImportError, ImportErrorProps>({
    getState: rootState => rootState.productImport.importErrors,
});

export const reducerActions = makeReducerActions<Domain.ImportError, ImportErrorProps>({
    reducerPrefix: '@productImport/importErrors',
});

export const actions = makeActions<Domain.ImportError, ImportErrorProps>({
    dataTableSaveKey: 'importErrors-v5',
    loadApi: options => importApi.GetImportErrors(options.urlParams.importId, options.pagination, options.sorting),
    defaultSorting,
    pageSize,
    reducerActions,
    selectors,
});

export const importErrorsReducer = makeReducer<Domain.ImportError, ImportErrorProps>({
    defaultSorting,
    pageSize,
    reducerActions,
});
