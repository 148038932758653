import {
    Pagination,
    Sorting,
    TimelinesPage,
    Timeline,
    TimelineSearchResultItemsPage,
    CreateTimelinePayload,
    UpdateTimelinePayload,
    DeviceScreenResolution,
    TimelineUsage,
    TimelineUsages,
} from '@/Domain';

import Endpoint from './Endpoint';

export default class TimelineEndpoint extends Endpoint {
    public async GetTimelines(slideshowId: string, pagination: Pagination, sorting: Sorting, search?: string): Promise<TimelinesPage> {
        const response = await this.client.get({
            url: '/slideshow/' + slideshowId + '/timeline/overview',
            params: {
                pagination,
                sorting,
                search,
            },
        });

        this.guardResponseSchema('#/definitions/TimelinesPage', response);

        return response;
    }

    public async GetTimelineDetails(slideshowId: string, timelineId: string): Promise<Timeline> {
        const timeline = await this.client.get({
            url: `/slideshow/${slideshowId}/timeline/${timelineId}/details`,
        });

        this.guardResponseSchema('#/definitions/Timeline', timeline);

        return timeline;
    }

    public async TimelinesSearch(
        branchId: string,
        pagination: Pagination,
        search?: string,
        filters?: {
            screenResolution?: DeviceScreenResolution;
            includeShared?: string;
            ownedByCompany?: string;
        },
    ): Promise<TimelineSearchResultItemsPage> {
        const response = await this.client.get({
            url: `/timeline/search/${branchId}`,
            params: {
                pagination,
                search,
                filters,
                sorting: {
                    field: 'name',
                    direction: 'ascending',
                },
            },
        });

        this.guardResponseSchema('#/definitions/TimelineSearchResultItemsPage', response);

        return response;
    }

    public async CreateTimeline(payload: CreateTimelinePayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/CreateTimeline',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Slideshow\\Timeline\\CreateTimeline\\CreateTimeline',
                payload,
            },
        });
    }

    public async UpdateTimeline(payload: UpdateTimelinePayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/UpdateTimeline',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Slideshow\\Timeline\\UpdateTimeline\\UpdateTimeline',
                payload,
            },
        });
    }

    public async RemoveTimeline(payload: { timelineId: string; slideshowId: string }): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/RemoveTimeline',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Slideshow\\Timeline\\RemoveTimeline\\RemoveTimeline',
                payload,
            },
        });
    }

    public async GetTimelineUsage(slideshowId: string, timelineId: string): Promise<TimelineUsage> {
        const timeline = await this.client.get({
            url: `/slideshow/${slideshowId}/timeline/${timelineId}/usage`,
        });

        this.guardResponseSchema('#/definitions/TimelineUsage', timeline);

        return timeline;
    }

    public async GetTimelineUsageBySlideshowId(slideshowId: string): Promise<TimelineUsages> {
        const timeline = await this.client.get({
            url: `/slideshow/${slideshowId}/timeline/usage`,
        });

        this.guardResponseSchema('#/definitions/TimelineUsages', timeline);

        return timeline;
    }
}
