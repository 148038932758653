import { combineReducers } from '@reduxjs/toolkit';

import { createAndUpdateReducer } from './createAndUpdateState';
import { overviewReducer } from './overviewState';
import { previewReducer } from './previewState';
import { updateReducer } from './updateState';

export const customPageReducer = combineReducers({
    overview: overviewReducer,
    update: updateReducer,
    preview: previewReducer,
    createAndUpdate: createAndUpdateReducer,
});
