import * as React from 'react';
import { useSelector } from 'react-redux';

import { Domain } from 'api';
import { useThunkDispatch, TVScreen } from 'data-store';

import getDeviceViewStyle from '@/utils/getDeviceViewStyle';

import InteractiveScreenNavigation from './InteractiveScreenNavigation';
import SlideshowViewerSlide from './SlideshowViewerSlide';

import styles from './SlideshowViewer.scss';

export default function SlideshowViewer(props: {
    usePreviewImageForContents?: boolean;
    getLanguageLabel: (locale: Domain.Locale) => React.ReactNode;
    renderVideo: (videoProps: React.VideoHTMLAttributes<any>) => React.ReactElement;
}) {
    const dispatch = useThunkDispatch();
    const device = useSelector(TVScreen.selectors.selectDevice);
    const timeline = useSelector(TVScreen.selectors.selectTimeline);
    const localTimeDelta = useSelector(TVScreen.slideshowState.selectLocalTimeDelta);
    const longestSlideshowDuration = useSelector(TVScreen.slideshowState.selectLongestSlideshowDuration);
    const ownSlideshowDuration = useSelector(TVScreen.selectors.selectSlideshowDuration);
    const visibleTimelineSlides = useSelector(TVScreen.selectors.selectVisibleTimelineSlides);
    const currentHistory = useSelector(TVScreen.interactionState.selectCurrentHistory);
    const forcedSlideOffset = useSelector(TVScreen.interactionState.selectForcedSlideOffset);
    const [activeSlideId, setActiveSlideId] = React.useState('');

    const TIMER_DELAY = 30;

    React.useEffect(() => {
        const timer = setInterval(() => {
            let newActiveSlideId = '';

            if (forcedSlideOffset === undefined) {
                const now = new Date().getTime();
                const secondsSinceMidnight = Math.floor((now - localTimeDelta) / 1000);

                let secondsIntoSlideshow = secondsSinceMidnight % (longestSlideshowDuration || ownSlideshowDuration);
                if (secondsIntoSlideshow < 0) {
                    secondsIntoSlideshow = 0;
                }

                let slideTime = 0;
                for (const slide of visibleTimelineSlides) {
                    if (slideTime <= secondsIntoSlideshow) {
                        newActiveSlideId = slide.slideId;
                    }
                    slideTime += slide.duration;
                }
            } else {
                let adjustedOffset = forcedSlideOffset % visibleTimelineSlides.length;
                if (adjustedOffset < 0) {
                    adjustedOffset = visibleTimelineSlides.length + adjustedOffset;
                }

                if (visibleTimelineSlides[adjustedOffset]) {
                    newActiveSlideId = visibleTimelineSlides[adjustedOffset].slideId;
                }
            }

            if (newActiveSlideId !== activeSlideId) {
                setActiveSlideId(newActiveSlideId);
            }
        }, TIMER_DELAY);

        return () => {
            clearInterval(timer);
        };
    }, [visibleTimelineSlides, longestSlideshowDuration, ownSlideshowDuration, localTimeDelta, activeSlideId, forcedSlideOffset]);

    if (!device) {
        return null;
    }

    if (!timeline) {
        return (
            <div
                className={styles.SlideshowViewer}
                style={getDeviceViewStyle(device)}
            >
                &nbsp;
            </div>
        );
    }

    const nextSlideId = TVScreen.slideshowState.getNextSlideIdAfter(visibleTimelineSlides, activeSlideId);
    const prevSlideId = TVScreen.slideshowState.getPrevSlideIdBefore(visibleTimelineSlides, activeSlideId);

    return (
        <div
            className={`${styles.SlideshowViewer} ${timeline.transition}`}
            style={getDeviceViewStyle(device)}
        >
            <div className={styles.SlideshowViewerSlidesWrap}>
                {visibleTimelineSlides.map(slideToRender => {
                    let isActive = !!(activeSlideId && slideToRender.slideId === activeSlideId);
                    const isNext = !!(nextSlideId && slideToRender.slideId === nextSlideId);
                    const isPrev = !!(prevSlideId && slideToRender.slideId === prevSlideId);

                    if (
                        currentHistory &&
                        currentHistory.type === 'forcedSlide' &&
                        slideToRender.slideId !== slideToRender.originalSlideId &&
                        activeSlideId &&
                        activeSlideId.indexOf('|') === -1
                    ) {
                        isActive = !!(activeSlideId && slideToRender.slideId === activeSlideId + '|0');
                    }

                    let loopVideo = false;
                    if (slideToRender.type === 'video' && slideToRender.loop === true) {
                        loopVideo = true;
                    } else if (slideToRender.type === 'customPage' || slideToRender.type === 'productWall') {
                        loopVideo = true;
                    }

                    return (
                        <SlideshowViewerSlide
                            key={slideToRender.slideId}
                            usePreviewImageForContents={props.usePreviewImageForContents}
                            slideId={slideToRender.originalSlideId}
                            loopVideo={loopVideo}
                            renderVideo={props.renderVideo}
                            isActive={isActive}
                            isNext={isNext}
                            isPrev={isPrev}
                            onProductClick={productDetails =>
                                dispatch(
                                    TVScreen.interactionState.reducerActions.pushToHistory({
                                        type: 'productDetails',
                                        productDetails,
                                    }),
                                )
                            }
                            onHiddenSlideClick={slideId =>
                                dispatch(
                                    TVScreen.interactionState.reducerActions.pushToHistory({
                                        type: 'hiddenSlide',
                                        slideId,
                                    }),
                                )
                            }
                        />
                    );
                })}
            </div>

            {currentHistory && currentHistory.type === 'forcedSlide' ? (
                <InteractiveScreenNavigation
                    withBack={true}
                    withClose={true}
                    getLanguageLabel={props.getLanguageLabel}
                />
            ) : null}
        </div>
    );
}
