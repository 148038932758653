import { combineReducers } from '@reduxjs/toolkit';

import { detailsReducer } from './detailsState';
import { importErrorsReducer } from './importErrorsState';
import { overviewReducer } from './overviewState';

export const productImportReducer = combineReducers({
    overview: overviewReducer,
    details: detailsReducer,
    importErrors: importErrorsReducer,
});
