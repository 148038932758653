import { createAction, Selector, createReducer } from '@reduxjs/toolkit';

import { Domain } from 'api';

import { ThunkAction, withPayloadType } from '@/action';
import { webshopPageLibraryApi } from '@/api';
import { isValidLocale } from '@/I18n';
import { URLParams } from '@/routing';
import { RootState } from '@/store';

export interface State {
    page?: Domain.WebshopLibraryPageDetails;
}

const initialState: State = {};

const reducerActions = {
    setPage: createAction('@webshopPageLibrary/update/setPage', withPayloadType<Domain.WebshopLibraryPageDetails>()),
};

export const updateReducer = createReducer(initialState, builder =>
    builder.addCase(reducerActions.setPage, (state, action) => {
        state.page = action.payload;
    }),
);

export const selectPage: Selector<RootState, Domain.WebshopLibraryPageDetails> = state => {
    const page = state.webshopPageLibrary.update.page;
    if (!page) {
        throw new Error('Page details not loaded');
    }

    return page;
};

export const loadPage =
    (pageId: string): ThunkAction<Promise<Domain.WebshopLibraryPageDetails>> =>
    async dispatch => {
        const pagepDetails = await webshopPageLibraryApi.GetWebshopLibraryPageDetails(pageId);

        await dispatch(reducerActions.setPage(pagepDetails));

        return pagepDetails;
    };

export const load =
    (options: { urlParams: URLParams }): ThunkAction =>
    async dispatch => {
        const locale = options.urlParams.locale;

        if (!isValidLocale(locale)) {
            throw new Error('Invalid locale');
        }

        await dispatch(loadPage(options.urlParams.pageId));
    };
