import { createAction, Selector, createReducer } from '@reduxjs/toolkit';
import moment from 'moment/moment';

import { Domain } from 'api';
import { browserStorage } from 'utils';

import { ThunkAction, withPayloadType } from '@/action';
import { cmsApi } from '@/api';
import { selectCurrentLocale } from '@/I18n';
import { RootState } from '@/store';

export interface State {
    publicAnnouncements?: Domain.PublicAnnouncementsPage;
    pagination: Domain.Pagination;
}
export const defaultSorting: Domain.Sorting = {
    field: 'createdOn',
    direction: 'descending',
};

export const initialState: State = {
    publicAnnouncements: undefined,
    pagination: {
        page: 1,
        size: 30,
    },
};

const reducerActions = {
    setPublicAnnouncements: createAction(
        '@publicAnnouncementsPopUp/setPublicAnnouncements',
        withPayloadType<Domain.PublicAnnouncementsPage>(),
    ),
};

export const publicAnnouncementsPopUpReducer = createReducer(initialState, builder =>
    builder.addCase(reducerActions.setPublicAnnouncements, (state, action) => {
        state.publicAnnouncements = action.payload;
    }),
);

export const selectPopUpAnnouncements: Selector<RootState, Domain.CmsItem[]> = state => {
    const closedAnnouncementsInPopupModals = browserStorage.getItem<string[]>('ClosedAnnouncementsInPopupModals')?.value || [];

    const selectedPublicAnnouncements = state.cmsItem.publicAnnouncementsPopUp.publicAnnouncements;

    if (!selectedPublicAnnouncements) {
        return [];
    }
    const currentDate = moment();

    const popupAnnouncements = selectedPublicAnnouncements?.items.filter(announcement => {
        if (announcement.configuration.popup) {
            if (closedAnnouncementsInPopupModals.length > 0) {
                if (!closedAnnouncementsInPopupModals.includes(announcement.cmsItemId)) {
                    if (announcement.activeFrom && announcement.activeUntil) {
                        const activeFrom = moment(announcement.activeFrom);
                        const activeUntill = moment(announcement.activeUntil);

                        if (activeFrom.isBefore(currentDate) && currentDate.isBefore(activeUntill)) {
                            return announcement;
                        }
                    } else {
                        return announcement;
                    }
                }
            } else {
                return announcement;
            }
        }
    });
    return popupAnnouncements;
};
export const selectPagination: Selector<RootState, Domain.Pagination> = state => state.cmsItem.publicAnnouncementsPopUp.pagination;

export const loadPublicAnnouncements = (): ThunkAction => async (dispatch, getState) => {
    const state = getState();
    const locale = selectCurrentLocale(state);
    const pagination = selectPagination(state);

    const publicAnnouncements = await cmsApi.GetPublicAnnouncements(locale, pagination, defaultSorting);

    await dispatch(reducerActions.setPublicAnnouncements(publicAnnouncements));
};

export const load = (): ThunkAction => async dispatch => {
    await dispatch(loadPublicAnnouncements());
};
