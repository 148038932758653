import * as React from 'react';
import { useSelector } from 'react-redux';

import { Domain } from 'api';
import { config } from 'config';
import { useThunkDispatch, useGettext, TVScreen } from 'data-store';
import { Icon, Input, Container, Row, Col, Pill } from 'ui-components';
import { FuseSearchProvider } from 'utils';

import Button from '@/components/Button';
import InteractiveScreenHeader from '@/components/InteractiveScreenHeader';
import InteractiveScreenNavigation from '@/components/InteractiveScreenNavigation';
import OnScreenKeyboard from '@/components/OnScreenKeyboard';
import findHiddenSlideContents from '@/utils/findHiddenSlideContents';
import findSlideContents from '@/utils/findSlideContents';

import styles from './SlidesOverview.scss';
import screenStyles from '@/components/InteractiveScreen.scss';

interface Searchable {
    label: string;
    value: string;
}

export default function SlidesOverview(props: { getLanguageLabel: (locale: Domain.Locale) => React.ReactNode }) {
    const dispatch = useThunkDispatch();
    const { gettext } = useGettext();
    const device = useSelector(TVScreen.selectors.selectDevice);
    const timeline = useSelector(TVScreen.selectors.selectTimeline);
    const slides = useSelector(TVScreen.selectors.selectSlides);
    const hiddenSlides = useSelector(TVScreen.selectors.selectHiddenSlides);
    const currentHistory = useSelector(TVScreen.interactionState.selectCurrentHistory);
    const accessibilityModeEnabled = useSelector(TVScreen.interactionState.selectAccessibilityModeEnabled);
    const inputRef = React.useRef<HTMLInputElement>();
    const [isSearching, setIsSearching] = React.useState(false);
    const [results, setResults] = React.useState<string[]>([]);
    const [oskVisible, setOskVisible] = React.useState(false);

    const handleFocus = () => {
        setOskVisible(true);
    };

    const handleBlur = () => {
        setTimeout(() => {
            setOskVisible(false);
        }, 250);
    };

    React.useEffect(() => {
        if (inputRef.current) {
            inputRef.current.addEventListener('focus', handleFocus);
            inputRef.current.addEventListener('blur', handleBlur);
        }

        return () => {
            if (inputRef.current) {
                inputRef.current.removeEventListener('focus', handleFocus);
                inputRef.current.removeEventListener('blur', handleBlur);
            }
        };
    }, [inputRef.current]);

    React.useEffect(() => {
        if (currentHistory && currentHistory.type === 'slidesOverview' && !currentHistory.query) {
            setResults([...(timeline ? timeline.content : []), ...((device && device.hiddenSlides) || [])].map(slide => slide.slideId));
        }
    }, [currentHistory]);

    if (!device || !currentHistory || currentHistory.type !== 'slidesOverview') {
        return null;
    }

    const searchables: Searchable[] = [];

    if (timeline) {
        timeline.content.forEach(slide => {
            searchables.push({
                value: slide.slideId,
                label: getSlideName(slide, timeline.slideNames) || '',
            });
        });
    }

    if (device.hiddenSlides) {
        device.hiddenSlides.forEach(hiddenSlide => {
            searchables.push({
                value: hiddenSlide.slideId,
                label: hiddenSlide.name,
            });
        });
    }

    const searchProvider = new FuseSearchProvider(searchables, {
        includeMatches: false,
        includeScore: false,
        keys: ['label'],
        isCaseSensitive: false,
        threshold: 0.3,
        ignoreLocation: true,
        useExtendedSearch: true,
    });

    const searchForm = (
        <div className={screenStyles.SearchForm}>
            {accessibilityModeEnabled && oskVisible ? <OnScreenKeyboard inputElement={inputRef.current} /> : null}

            <Input
                ref={inputRef as any}
                value={currentHistory.query}
                onChange={newQuery => dispatch(TVScreen.interactionState.reducerActions.setSearchQuery(newQuery))}
                placeholder={gettext('Search by name')}
                buttonRight={
                    <>
                        {currentHistory.query ? (
                            <Button
                                variant="plain"
                                onClick={() => dispatch(TVScreen.interactionState.reducerActions.setSearchQuery(''))}
                                startIcon={
                                    <Icon
                                        type="action_remove"
                                        iconSize="m"
                                    />
                                }
                                data-test-id="clear-query"
                            />
                        ) : null}

                        <Button
                            variant="primary"
                            disabled={!currentHistory.query || isSearching}
                            onClick={async () => {
                                setIsSearching(true);

                                if (currentHistory.query) {
                                    const searchResults = await searchProvider.search(currentHistory.query);
                                    if (searchResults) {
                                        setResults(searchResults.map(result => result.value));
                                    }
                                } else {
                                    setResults(timeline ? timeline.content.map(slide => slide.slideId) : []);
                                }

                                setIsSearching(false);
                            }}
                            data-test-id="search-submit"
                        >
                            {gettext('Search')}
                        </Button>
                    </>
                }
                data-test-id="search-query"
            />

            {!accessibilityModeEnabled && oskVisible ? <OnScreenKeyboard inputElement={inputRef.current} /> : null}
        </div>
    );

    const searchStatus = isSearching ? (
        <h1 className={screenStyles.SearchStatus}>{gettext('Searching...')}</h1>
    ) : results.length === 0 ? (
        <h1 className={screenStyles.SearchStatus}>{gettext('No results. Try a different query.')}</h1>
    ) : null;

    const renderSlideResult = (
        slideId: string,
        slideName: string,
        slideContents: TVScreen.types.Slide,
        type: string,
        clickAction: () => void,
    ) => {
        let slidePreview;
        if (TVScreen.types.isProductWallSlideContents(slideContents) || TVScreen.types.isCustomPageSlideContents(slideContents)) {
            slidePreview = (
                <img
                    src={slideContents.preview || ''}
                    alt=""
                />
            );
        } else {
            slidePreview = (
                <img
                    src={`${config.apiBaseUrl}/media-item/${slideContents.mediaItemId}/preview/450x450`}
                    alt=""
                />
            );
        }

        return (
            <Col key={slideId}>
                <a
                    className={screenStyles.SearchResult}
                    href=""
                    onClick={e => {
                        e.preventDefault();

                        clickAction();
                    }}
                >
                    <span className={screenStyles.SearchResultImage}>{slidePreview}</span>
                    <span className={screenStyles.SearchResultTitle}>{slideName}</span>
                    {type === 'hiddenSlide' ? (
                        <Pill
                            variant="outlined"
                            color="s3"
                            className={screenStyles.HiddenLabel}
                        >
                            {gettext('Hidden')}
                        </Pill>
                    ) : null}
                </a>
            </Col>
        );
    };

    return (
        <div
            className={`${screenStyles.InteractiveScreen} ${styles.TVSlidesOverview} ${accessibilityModeEnabled ? screenStyles.AccessibilityMode : ''}`}
        >
            {!accessibilityModeEnabled ? (
                <>
                    <InteractiveScreenHeader />
                    <h1 className={screenStyles.ScreenTitle}>{gettext('Slides overview')}</h1>
                    {searchForm}
                    {searchStatus}
                </>
            ) : null}

            <Container
                gutter={24}
                className={`${screenStyles.SearchResults} ${oskVisible ? screenStyles.WithOSK : ''}`}
            >
                <Row wrap={true}>
                    {device.hiddenSlides
                        ? device.hiddenSlides.map(hiddenSlide => {
                              if (results.indexOf(hiddenSlide.slideId) === -1) {
                                  return null;
                              }

                              const slideContents = findHiddenSlideContents(hiddenSlide, hiddenSlides);
                              if (!slideContents) {
                                  return null;
                              }

                              return renderSlideResult(
                                  hiddenSlide.slideId,
                                  getHiddenSlideName(hiddenSlide, timeline ? timeline.hiddenSlideNames : undefined) || '',
                                  slideContents,
                                  'hiddenSlide',
                                  () =>
                                      dispatch(
                                          TVScreen.interactionState.reducerActions.pushToHistory({
                                              type: 'hiddenSlide',
                                              slideId: hiddenSlide.slideId,
                                          }),
                                      ),
                              );
                          })
                        : null}

                    {timeline
                        ? timeline.content.map((slide, slideOffset) => {
                              if (results.indexOf(slide.slideId) === -1) {
                                  return null;
                              }

                              if (slide.isHidden) {
                                  return null;
                              }

                              const slideContents = findSlideContents(slide, slides);
                              if (!slideContents) {
                                  return null;
                              }

                              return renderSlideResult(
                                  slide.slideId,
                                  getSlideName(slide, timeline.slideNames) || '',
                                  slideContents,
                                  'forcedSlide',
                                  () =>
                                      dispatch(
                                          TVScreen.interactionState.reducerActions.pushToHistory({
                                              type: 'forcedSlide',
                                              slideOffset,
                                          }),
                                      ),
                              );
                          })
                        : []}
                </Row>
            </Container>

            {accessibilityModeEnabled ? (
                <>
                    {searchStatus}
                    {searchForm}
                    <InteractiveScreenHeader />
                    <h1 className={screenStyles.ScreenTitle}>{gettext('Slides overview')}</h1>
                </>
            ) : null}

            <InteractiveScreenNavigation getLanguageLabel={props.getLanguageLabel} />
        </div>
    );
}

function getSlideName(slideContents: Domain.SlideshowSlide, slideNames: Domain.Timeline['slideNames']) {
    if (!slideNames || Array.isArray(slideNames)) {
        return null;
    }

    return slideContents.type === 'productWall'
        ? slideNames[slideContents.productWallId]
        : slideContents.type === 'customPage'
          ? slideNames[slideContents.customPageId]
          : slideContents.type === 'video' || slideContents.type === 'image'
            ? slideNames[slideContents.mediaItemId]
            : null;
}

function getHiddenSlideName(slideContents: Domain.DeviceHiddenSlide, slideNames: Domain.Timeline['hiddenSlideNames']) {
    if (!slideNames || Array.isArray(slideNames)) {
        return null;
    }

    return slideNames[slideContents.slideId];
}
