import { ShelfWithMeta, ShelfStyleParameters, Layout } from '../types';

export default function computeShelfMoveMaxY(layout: Layout, nextShelf?: ShelfWithMeta): number {
    if (nextShelf && nextShelf.shelf.type === 'emptyShelf') {
        return nextShelf.shelf.y + nextShelf.shelf.height;
    }

    const LayoutParams = ShelfStyleParameters[layout.style];

    if (nextShelf) {
        return (
            nextShelf.shelf.y +
            nextShelf.shelf.height -
            Math.max(LayoutParams.minShelfHeight, nextShelf.meta ? nextShelf.meta.maxBottom : 0)
        );
    }

    return layout.height;
}
