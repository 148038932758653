import {
    Pagination,
    Sorting,
    Locale,
    WebshopLibraryNewsArticlesPage,
    WebshopLibraryNewsArticleDetails,
    CreateWebshopLibraryNewsArticlePayload,
    UpdateWebshopLibraryNewsArticlePayload,
    CopyWebshopLibraryNewsArticlePayload,
} from '@/Domain';

import Endpoint from './Endpoint';

export default class WebshopLibraryNewsArticleEndpoint extends Endpoint {
    public async GetWebshopLibraryNewsArticles(
        pagination: Pagination,
        sorting: Sorting,
        locale: Locale,
        search?: string,
        filters?: {
            activeStatus?: string;
            authorUserId?: string;
            without?: string;
        },
    ): Promise<WebshopLibraryNewsArticlesPage> {
        const url = `/library-article/overview/${locale}`;

        const response = await this.client.get({
            url,
            params: {
                pagination,
                sorting,
                search,
                filters,
            },
        });

        this.guardResponseSchema('#/definitions/WebshopLibraryNewsArticlesPage', response);

        return response;
    }

    public async GetWebshopLibraryNewsArticleDetails(articleId: string): Promise<WebshopLibraryNewsArticleDetails> {
        const response = await this.client.get({
            url: `/library-article/${articleId}/details`,
        });

        this.guardResponseSchema('#/definitions/WebshopLibraryNewsArticleDetails', response);

        return response;
    }

    public async CreateWebshopLibraryNewsArticle(payload: CreateWebshopLibraryNewsArticlePayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/CreateWebshopLibraryNewsArticle',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Webshop\\Blog\\UpdateLibraryArticle\\UpdateLibraryArticle',
                payload,
            },
        });
    }

    public async UpdateWebshopLibraryNewsArticle(payload: UpdateWebshopLibraryNewsArticlePayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/UpdateWebshopLibraryNewsArticle',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Webshop\\Blog\\UpdateLibraryArticle\\UpdateLibraryArticle',
                payload,
            },
        });
    }

    public async CopyWebshopLibraryNewsArticle(payload: CopyWebshopLibraryNewsArticlePayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/CopyLibraryArticleToWebshop',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Webshop\\Blog\\CopyLibraryArticleToWebshop\\CopyLibraryArticleToWebshop',
                payload,
            },
        });
    }
}
