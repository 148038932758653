import { Domain } from 'api';
import { browserStorage } from 'utils';

const CURRENT_LOCALE_KEY = 'locale';

export function setCurrentLocale(locale: Domain.Locale): void {
    browserStorage.setItem(CURRENT_LOCALE_KEY, locale);
}

export function getCurrentLocale(): Domain.Locale | undefined {
    const item = browserStorage.getItem(CURRENT_LOCALE_KEY);

    if (item) {
        return item.value as Domain.Locale;
    }
}
