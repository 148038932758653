import { Domain } from 'api';

import { webshopCustomerApi } from '@/api';
import { makeActions, makeReducer, makeReducerActions, makeSelectors } from '@/makeOverviewState';

type WebshopCustomerProps = keyof Domain.WebshopCustomer;

export const defaultSorting: Domain.Sorting<WebshopCustomerProps> = {
    field: 'createdOn',
    direction: 'descending',
};

export const pageSize = 20;

export const selectors = makeSelectors<Domain.WebshopCustomer, WebshopCustomerProps>({
    getState: rootState => rootState.webshopCustomer.overview,
});

export const reducerActions = makeReducerActions<Domain.WebshopCustomer, WebshopCustomerProps>({
    reducerPrefix: '@webshopCustomer/overview',
});

export const actions = makeActions<Domain.WebshopCustomer, WebshopCustomerProps>({
    dataTableSaveKey: 'webshopCustomersOverview-v5',
    loadApi: options => {
        if (options.globallySelectedWebshopId === undefined) {
            return;
        }

        return webshopCustomerApi.GetWebshopCustomers(
            options.globallySelectedWebshopId,
            options.pagination,
            options.sorting,
            options.filters,
            options.search,
        );
    },
    defaultSorting,
    pageSize,
    getBaseUrl: () => '/webshops/customers',
    reducerActions,
    selectors,
});

export const overviewReducer = makeReducer<
    Domain.WebshopCustomer,
    WebshopCustomerProps,
    {
        locale?: Domain.Locale;
    }
>({
    defaultSorting,
    pageSize,
    reducerActions,
});
