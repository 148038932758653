type OS = 'linux' | 'windows' | 'mac' | 'other';
type ChromiumVersion = string | 'unknown';

export function uaData(): {
    os: OS;
    chromiumVersion: ChromiumVersion;
} {
    let os: OS = 'other';
    if (window.navigator.userAgent.indexOf('Windows NT') != -1) {
        os = 'windows';
    } else if (window.navigator.userAgent.indexOf('Mac') != -1) {
        os = 'mac';
    }
    if (window.navigator.userAgent.indexOf('x11') != -1 || window.navigator.userAgent.indexOf('Linux') != -1) {
        os = 'linux';
    }

    let chromiumVersion: ChromiumVersion = 'unknown';
    if (window.navigator.userAgent.includes('Chrome/')) {
        let parts = window.navigator.userAgent.split('Chrome/');
        if (parts.length > 1) {
            parts = parts[1].split('.');
            chromiumVersion = parts[0];
        }
    }

    return {
        os,
        chromiumVersion,
    };
}
