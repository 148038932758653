import { createAction, Selector, createReducer } from '@reduxjs/toolkit';

import { Domain } from 'api';

import { ThunkAction, withPayloadType } from '@/action';
import { pickupApi } from '@/api';
import { selectLoggedInUserOwnership } from '@/Authentication';
import { RootState } from '@/store';

export interface State {
    pickups: Domain.Pickup[];
}

const initialState: State = {
    pickups: [],
};

const reducerActions = {
    setPickups: createAction('@pickup/incompleteReport/setPickups', withPayloadType<Domain.Pickup[]>()),
};

export const reducer = createReducer(initialState, builder =>
    builder.addCase(reducerActions.setPickups, (state, action) => {
        state.pickups = action.payload;
    }),
);

export const selectPickups: Selector<RootState, Domain.Pickup[]> = state => {
    return state.pickup.incompleteReport.pickups;
};

export const load = (): ThunkAction => async (dispatch, getState) => {
    const state = getState();
    const ownership = selectLoggedInUserOwnership(state);
    const pickupsPage = await pickupApi.GetPickups(
        ownership,
        { page: 1, size: 1000 },
        { field: 'createdAt', direction: 'descending' },
        undefined,
        {
            pickupStatus: 'incomplete',
        },
    );

    await dispatch(reducerActions.setPickups(pickupsPage.items));
};
