import { Pagination, Sorting, Locale, WebshopTagsPage, WebshopTag, CreateWebshopTagPayload, UpdateWebshopTagPayload } from '@/Domain';

import Endpoint from './Endpoint';

export default class WebshopTagEndpoint extends Endpoint {
    public async GetWebshopTags(
        pagination: Pagination,
        sorting: Sorting,
        webshopId: string,
        locale: Locale,
        search?: string,
        filters?: {
            authorUserId?: string;
        },
    ): Promise<WebshopTagsPage> {
        const url = `/webshop/${webshopId}/tag/overview`;

        const response = await this.client.get({
            url,
            params: {
                pagination,
                sorting,
                locale,
                search,
                filters,
            },
        });

        this.guardResponseSchema('#/definitions/WebshopTagsPage', response);

        return response;
    }

    public async GetWebshopTagDetails(webshopId: string, tagId: string): Promise<WebshopTag> {
        const response = await this.client.get({
            url: `/webshop/${webshopId}/tag/${tagId}/details`,
        });

        this.guardResponseSchema('#/definitions/WebshopTag', response);

        return response;
    }

    public async CreateWebshopTag(payload: CreateWebshopTagPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/CreateWebshopTag',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Webshop\\Tag\\UpdateTag\\UpdateTag',
                payload,
            },
        });
    }

    public async UpdateWebshopTag(payload: UpdateWebshopTagPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/UpdateWebshopTag',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Webshop\\Tag\\UpdateTag\\UpdateTag',
                payload,
            },
        });
    }

    public async RemoveWebshopTag(webshopId: string, tagId: string): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/RemoveWebshopTag',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Webshop\\Tag\\RemoveTag\\RemoveTag',
                payload: {
                    webshopId,
                    tagId,
                },
            },
        });
    }
}
