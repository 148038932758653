import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import { useThunkDispatch, Authentication, useGettext } from 'data-store';
import { DeviceLog } from 'logger';
import { Container, Card, Tx } from 'ui-components';
import { useParams } from 'utils';

import refreshOnError from '../refreshOnError';

export default function Error() {
    const { gettext } = useGettext();
    const dispatch = useThunkDispatch();
    const navigate = useNavigate();
    const { type } = useParams();

    React.useEffect(() => {
        if (type !== 'invalid-device-configuration') {
            refreshOnError({
                callback: async () => {
                    await dispatch(Authentication.SignOut());
                    navigate('/');
                },
            });
        } else {
            console.info('Detected invalid Device configuration, possibly related to an invalid MAC address reported by localcomm.');
        }
    }, [type]);

    return (
        <div className="MainNavigation hidden-overflow-sm">
            <Container fullWidth={true}>
                <Card>
                    {type === 'invalid-device-configuration' ? (
                        <Tx level="heading-1">{gettext('Invalid configuration')}</Tx>
                    ) : (
                        <Tx level="heading-1">{gettext('An error occurred')}</Tx>
                    )}

                    <a
                        href=""
                        onClick={async e => {
                            e.preventDefault();

                            await dispatch(Authentication.SignOut());
                            navigate('/');

                            DeviceLog.refreshedDueToError();
                            window.location.reload();
                        }}
                    >
                        {gettext('Refresh')}
                    </a>
                </Card>
            </Container>
        </div>
    );
}
