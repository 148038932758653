import {
    PlcStatus,
    PlcMessages,
    PlcHandleValue,
    ReceivedPlcMessage,
    PlcJobHandleStatus,
    PlcChangeRunningModeProcess,
    PlcJobStatus,
    PLCSTATUS_DEFAULT,
    PLCSUPPORTEDJOBCODES,
    PLCMESSAGEERRORCODES,
    PLCMESSAGEWARNINGCODES,
    PLCMESSAGEINFOCODES,
    PLCMESSAGEDEBUGCODES,
    keyMessageCallback
} from '../../Domain';

import LocalCommClient from './LocalCommClient';


const PLCJOBTIMEOUTS: keyNumberObject = {
    'jobCode_201': 90000,
    'jobCode_202': 30000,
    'jobCode_203': 30000,
    'jobCode_204': 300000,
    'jobCode_205': 30000,
    'jobCode_206': 60000
};

type keyNumberObject = {
    [key: string]: number
}

export type PlcJobStatuses = {
    [key: string]: PlcJobProcessStatus
}

export class PlcJobProcessStatus {
    public jobId: number = 0;
    public jobCode: number = 0;
    public jobIsAccepted: boolean = false;
    public jobIsEvaluated: boolean = false;
    public jobIsNotAccepted: boolean = false;
    public jobIsDone: boolean = false;
    public jobIsFailed: boolean = false;
    public jobIsBusy: boolean = false;
    public status: string = 'idle';
    public mode: string = '';
    public state: string = '';
    public errorCode: string = '';
    public job: {
        state: string
        reqCode: string
        ackCode: string
        errorCode: string
    }
    public createdTime: number
    public updatedTime: number
    public isTimeOut: boolean = false;


    public constructor(jobCode: number, jobId: number = Date.now()) {
        this.jobCode = jobCode;
        this.jobId = jobId;
        this.createdTime = this.createdTime = Date.now();
        this.job = {
            state: '',
            reqCode: '',
            ackCode: '',
            errorCode: ''
        }
    }

    public setUpdateTime() {
        this.updatedTime = Date.now();
    }

    public updateJobStatus(status: string) {
        this.status = status;
        this.setUpdateTime();
    }
}

export class MaticPlc {

    public plcJobProcessStatus: PlcJobStatuses = {};
    public plcJobTimeouts: keyNumberObject = PLCJOBTIMEOUTS;
    public plcSupportedJobCodes = PLCSUPPORTEDJOBCODES;
    public plcStatus: PlcStatus = PLCSTATUS_DEFAULT;
    public plcChangeRunningModeProcessStatus: PlcChangeRunningModeProcess = {
        type: 'none',
        nextStep: 'stop',
        busyWith: 'none',
        busyWithType: false,
        cancel: false
    }

    private localComm: LocalCommClient;

    public constructor(localcomm: LocalCommClient) {
        this.localComm = localcomm;
    }

    public onPlcMessageCallbacks: keyMessageCallback = {};
    public onPlcEmergencyCallbacks: keyMessageCallback = {};
    public onPlcReleasedCallbacks: keyMessageCallback = {};

    public getLastPLCStatus(): PlcStatus {
        return this.plcStatus;
    }

    public getPlcJobProcessStatus(statusId: string | number): boolean | PlcJobStatus {
        if (this.plcJobProcessStatus.hasOwnProperty(statusId)) {
            return {
                jobId: this.plcJobProcessStatus[statusId].jobId,
                jobCode: this.plcJobProcessStatus[statusId].jobCode,
                jobIsAccepted: this.plcJobProcessStatus[statusId].jobIsAccepted,
                jobIsEvaluated: this.plcJobProcessStatus[statusId].jobIsEvaluated,
                jobIsNotAccepted: this.plcJobProcessStatus[statusId].jobIsNotAccepted,
                jobIsDone: this.plcJobProcessStatus[statusId].jobIsDone,
                jobIsFailed: this.plcJobProcessStatus[statusId].jobIsFailed,
                jobIsBusy: this.plcJobProcessStatus[statusId].jobIsBusy,
                status: this.plcJobProcessStatus[statusId].status,
                mode: this.plcJobProcessStatus[statusId].mode,
                state: this.plcJobProcessStatus[statusId].state,
                errorCode: this.plcJobProcessStatus[statusId].errorCode,
                job: this.plcJobProcessStatus[statusId].job,
                createdTime: this.plcJobProcessStatus[statusId].createdTime,
                updatedTime: this.plcJobProcessStatus[statusId].updatedTime,
                isTimeOut: this.plcJobProcessStatus[statusId].isTimeOut,
                statusId: statusId
            } as PlcJobStatus
        } else {
            return false;
        }
    }

    public deletePlcProcessStatus(statusId: string | number): boolean {
        if (this.plcJobProcessStatus.hasOwnProperty(statusId)) {
            delete this.plcJobProcessStatus[statusId];
            return true;
        }
        return false;
    }

    public canPlcExecuteAJob(jobCode: number): boolean {
        if (this.plcStatus.MTMATIC.status.mode === 'auto') {
            if (this.plcSupportedJobCodes.indexOf(jobCode) !== -1 && this.plcStatus.currentJobId === 0 && this.plcStatus.currentJobCode === 0) {
                return true;
            }
        }
        return false;
    }

    public setPlcJobStatus(jobCode: number, jobId: number) {
        this.plcStatus.currentJobId = jobId;
        this.plcStatus.currentJobCode = jobCode;
    }

    public resetPlcJobStatus() {
        this.plcStatus.currentJobId = 0;
        this.plcStatus.currentJobCode = 0;
        this.plcStatus.currentJobIsAccepted = false;
        this.plcStatus.currentJobIsEvaluated = false;
        this.plcStatus.currentJobIsNotAccepted = false;
        this.plcStatus.currentJobIsDone = false;
        this.plcStatus.currentJobIsFailed = false;
        this.plcStatus.currentJobIsBusy = false;
    }

    public checkJobProcess(statusId: number | string, jobCode: number): Promise<PlcJobHandleStatus> {
        return new Promise(resolve => {

            let plcJobHandleStatus: PlcJobHandleStatus = {
                status: false,
                statusId: statusId
            }

            if (this.plcJobProcessStatus.hasOwnProperty(statusId)) {
                if (this.plcJobProcessStatus[statusId].jobCode === jobCode) {
                    if (this.plcJobProcessStatus[statusId].jobIsDone || this.plcJobProcessStatus[statusId].jobIsFailed || this.plcJobProcessStatus[statusId].jobIsNotAccepted || this.plcJobProcessStatus[statusId].isTimeOut) {

                        if (this.plcJobProcessStatus[statusId].jobIsDone) {
                            this.plcJobProcessStatus[statusId].updateJobStatus('done');
                        } else if (this.plcJobProcessStatus[statusId].jobIsFailed) {
                            this.plcJobProcessStatus[statusId].updateJobStatus('failed');
                        } else if (this.plcJobProcessStatus[statusId].jobIsNotAccepted) {
                            this.plcJobProcessStatus[statusId].updateJobStatus('invalid');
                        } else if (this.plcJobProcessStatus[statusId].isTimeOut) {
                            this.plcJobProcessStatus[statusId].updateJobStatus('timeout');
                        }

                        plcJobHandleStatus.status = true;
                        this.resetPlcJobStatus();
                        resolve(plcJobHandleStatus);
                    } else {
                        setTimeout(() => {
                            resolve(this.checkJobProcess(statusId, jobCode));
                        }, 500)
                    }
                } else {
                    resolve(plcJobHandleStatus);
                }
            } else {
                resolve(plcJobHandleStatus);
            }
        });
    }

    public setOnPLCMessageCallback(callback: (plcHandleValue: PlcHandleValue, plcStatus: PlcStatus) => void, id: string = 'default') {
        this.onPlcMessageCallbacks[id] = callback;
    }

    public unsetOnPLCMessageCallback(id: string = 'default') {
        if(typeof this.onPlcMessageCallbacks[id] !== 'undefined'){
            delete this.onPlcMessageCallbacks[id];
        }
    }

    public SetOnPLCEmergencyCallback(callback: (plcEmergencyValue: any, plcStatus: PlcStatus) => void, id: string = 'default') {
        this.onPlcEmergencyCallbacks[id] = callback;
    }

    public unsetOnPLCEmergencyCallback(id: string = 'default') {
        if(typeof this.onPlcEmergencyCallbacks[id] !== 'undefined'){
            delete this.onPlcEmergencyCallbacks[id];
        }
    }

    public SetOnPLCHasBeenReleasedCallback(callback: (mode: string, afterEmergency: boolean) => void, id: string = 'default') {
        this.onPlcReleasedCallbacks[id] = callback;
    }


    public unsetOnPLCReleasedCallback(id: string = 'default') {
        if(typeof this.onPlcReleasedCallbacks[id] !== 'undefined'){
            delete this.onPlcReleasedCallbacks[id];
        }
    }

    public resetBoxDetectCounter(type: 'software' | 'plc' | 'all' = 'all') {
        if (type === 'all' || type === 'software') {
            this.plcStatus.status.boxDetect.softwareCounter = 0;
        }
        if (type === 'all' || type === 'plc') {
            this.plcStatus.status.boxDetect.plcCounter = 0;
        }
    }

    public handlePLCMessage(message: ReceivedPlcMessage) {

        let returnStatus = false;
        let handleValue: PlcHandleValue = {};

        //value can have value of false > so the if statement can be false negative
        if (message.command && message.command.variable && typeof message.command.value !== 'undefined') {

            if (message.command.variable === 'bSleeping' && typeof message.command.value === 'boolean') {
                handleValue = {
                    'status': {
                        'sleeping': message.command.value
                    }
                }
                this.plcStatus.status.sleeping = message.command.value;

                if(!this.localComm.localCommStatus.connected){
                    //connection initialization
                    this.plcStatus.statusEventsReceivedSinceLocalcommConnectionWasEstablished.sleeping = true;
                }

                returnStatus = true;
            } else if (message.command.variable === 'bUPS_BattDischarged' && typeof message.command.value === 'boolean') {
                handleValue = {
                    'status': {
                        'upsBattDischarged': message.command.value
                    }
                }
                this.plcStatus.status.upsBattDischarged = message.command.value;

                if(!this.localComm.localCommStatus.connected){
                    //connection initialization
                    this.plcStatus.statusEventsReceivedSinceLocalcommConnectionWasEstablished.upsBattDischarged = true;
                }

                returnStatus = true;
            } else if (message.command.variable === 'bLightOn' && typeof message.command.value === 'boolean') {
                handleValue = {
                    'status': {
                        'led': message.command.value
                    }
                }
                this.plcStatus.status.led = message.command.value;

                if(!this.localComm.localCommStatus.connected){
                    //connection initialization
                    this.plcStatus.statusEventsReceivedSinceLocalcommConnectionWasEstablished.led = true;
                }

                returnStatus = true;
            } else if (message.command.variable === 'bTransactionBusy' && typeof message.command.value === 'boolean') {
                handleValue = {
                    'status': {
                        'transactionBusy': message.command.value
                    }
                }
                this.plcStatus.status.transactionBusy = message.command.value;

                if(!this.localComm.localCommStatus.connected){
                    //connection initialization
                    this.plcStatus.statusEventsReceivedSinceLocalcommConnectionWasEstablished.transactionBusy = true;
                }

                returnStatus = true;
            } else if (message.command.variable === 'bOpenFront' && typeof message.command.value === 'boolean') {
                handleValue = {
                    'status': {
                        'techinalFrontDoorOpen': message.command.value
                    }
                }
                this.plcStatus.status.techinalFrontDoorOpen = message.command.value;

                if(!this.localComm.localCommStatus.connected){
                    //connection initialization
                    this.plcStatus.statusEventsReceivedSinceLocalcommConnectionWasEstablished.techinalFrontDoorOpen = true;
                }

                returnStatus = true;
            } else if (message.command.variable === 'bUPS_BattOperation' && typeof message.command.value === 'boolean') {
                handleValue = {
                    'status': {
                        'bUPS_BattOperation': message.command.value
                    }
                }
                this.plcStatus.status.bUPS_BattOperation = message.command.value;

                if(!this.localComm.localCommStatus.connected){
                    //connection initialization
                    this.plcStatus.statusEventsReceivedSinceLocalcommConnectionWasEstablished.bUPS_BattOperation = true;
                }

                returnStatus = true;
            } else if (message.command.variable === 'bShutDownMtMATIC' && typeof message.command.value === 'boolean') {
                handleValue = {
                    'status': {
                        'shutdownMTMATIC': message.command.value
                    }
                }
                this.plcStatus.status.shutdownMTMATIC = message.command.value;

                if(!this.localComm.localCommStatus.connected){
                    //connection initialization
                    this.plcStatus.statusEventsReceivedSinceLocalcommConnectionWasEstablished.shutdownMTMATIC = true;
                }

                returnStatus = true;
            } else if (message.command.variable === 'bBoxDetect' && typeof message.command.value === 'boolean') {
                handleValue = {
                    'status': {
                        'boxDetect': {
                            'status': message.command.value
                        }
                    }
                }
                this.plcStatus.status.boxDetect.sensor = message.command.value;

                if(!this.localComm.localCommStatus.connected){
                    //connection initialization
                    this.plcStatus.statusEventsReceivedSinceLocalcommConnectionWasEstablished.boxDetect.sensor = true;
                }

                if (this.plcStatus.status.boxDetect.sensor) {
                    this.plcStatus.status.boxDetect.softwareCounter += 1;
                }

                returnStatus = true;
            } else if (message.command.variable === 'iBoxDetectCounter' && typeof message.command.value === 'number') {
                handleValue = {
                    'status': {
                        'boxDetect': {
                            'counter': message.command.value
                        }
                    }
                }
                this.plcStatus.status.boxDetect.plcCounter = message.command.value;

                if(!this.localComm.localCommStatus.connected){
                    //connection initialization
                    this.plcStatus.statusEventsReceivedSinceLocalcommConnectionWasEstablished.boxDetect.plcCounter = true;
                }

                returnStatus = true;
            } else if (message.command.variable === 'iMachineType' && typeof message.command.value === 'number') {
                handleValue = {
                    'status': {
                        'machineType': message.command.value
                    }
                }
                this.plcStatus.status.machineType = message.command.value;

                if(!this.localComm.localCommStatus.connected){
                    //connection initialization
                    this.plcStatus.statusEventsReceivedSinceLocalcommConnectionWasEstablished.machineType = true;
                }

                returnStatus = true;
            } else if (message.command.variable === 'iPrgVersion' && typeof message.command.value === 'number') {
                handleValue = {
                    'status': {
                        'prgVersion': message.command.value
                    }
                }
                this.plcStatus.status.prgVersion = message.command.value;

                if(!this.localComm.localCommStatus.connected){
                    //connection initialization
                    this.plcStatus.statusEventsReceivedSinceLocalcommConnectionWasEstablished.prgVersion = true;
                }

                returnStatus = true;
            } else if (message.command.variable === 'tSystemUpTime' && typeof message.command.value === 'number') {
                handleValue = {
                    'status': {
                        'plcUpTime': message.command.value
                    }
                }
                this.plcStatus.status.plcUpTime = message.command.value;

                if(!this.localComm.localCommStatus.connected){
                    //connection initialization
                    this.plcStatus.statusEventsReceivedSinceLocalcommConnectionWasEstablished.plcUpTime = true;
                }

                returnStatus = true;
            } else if (message.command.variable === 'bEStopCircuitClosed' && typeof message.command.value === 'boolean') {
                handleValue = {
                    'status': {
                        'emergency': {
                            'stopCircuitClosed': message.command.value
                        }
                    }
                }
                this.plcStatus.status.emergency.stopCircuitClosed = message.command.value;

                if(!this.localComm.localCommStatus.connected){
                    //connection initialization
                    this.plcStatus.statusEventsReceivedSinceLocalcommConnectionWasEstablished.emergency.stopCircuitClosed = true;
                }

                this.checkPLCEmergecyValue();
                returnStatus = true;
            } else if (message.command.variable === 'bHMI_PilzStatus' && typeof message.command.value === 'boolean') {
                handleValue = {
                    'status': {
                        'emergency': {
                            'pilzStatus': message.command.value
                        }
                    }
                }
                this.plcStatus.status.emergency.hmiPilzStatus = message.command.value;

                if(!this.localComm.localCommStatus.connected){
                    //connection initialization
                    this.plcStatus.statusEventsReceivedSinceLocalcommConnectionWasEstablished.emergency.hmiPilzStatus = true;
                }

                this.checkPLCEmergecyValue();
                returnStatus = true;
            } else if (message.command.variable === 'bHMI_EstopBtn1' && typeof message.command.value === 'boolean') {
                handleValue = {
                    'status': {
                        'emergency': {
                            'hmiStopbtn': message.command.value
                        }
                    }
                }
                this.plcStatus.status.emergency.hmiEStopBtn1 = message.command.value;

                if(!this.localComm.localCommStatus.connected){
                    //connection initialization
                    this.plcStatus.statusEventsReceivedSinceLocalcommConnectionWasEstablished.emergency.hmiEStopBtn1 = true;
                }

                this.checkPLCEmergecyValue();
                returnStatus = true;
            } else if (message.command.variable === 'bFunctionButton' && typeof message.command.value === 'boolean') {
                handleValue = {
                    'status': {
                        'functionButton': message.command.value
                    }
                }
                this.plcStatus.status.functionButton = message.command.value;

                if(!this.localComm.localCommStatus.connected){
                    //connection initialization
                    this.plcStatus.statusEventsReceivedSinceLocalcommConnectionWasEstablished.functionButton = true;
                }

                returnStatus = true;
            }

        } else if (message.status) {

            if (typeof message.status.Connection === 'boolean') {
                handleValue = {
                    'status': {
                        'plcDevice': {
                            'localCommIsConnectedWithPLC': message.status.Connection
                        }
                    }
                }
                this.plcStatus.status.plcDevice.localCommIsConnectedWithPLC = message.status.Connection;
                returnStatus = true;
            }
            if (message.status.AdsState) {
                handleValue = {
                    'status': {
                        'plcDevice': {
                            'adsState': message.status.AdsState
                        }
                    }
                }
                this.plcStatus.status.plcDevice.adsState = message.status.AdsState;

                if(!this.localComm.localCommStatus.connected){
                    //connection initialization
                    this.plcStatus.statusEventsReceivedSinceLocalcommConnectionWasEstablished.plcDevice.adsState = true;
                }

                returnStatus = true;
            }
            if (message.status.DeviceState) {
                handleValue = {
                    'status': {
                        'plcDevice': {
                            'deviceState': message.status.DeviceState
                        }
                    }
                }
                this.plcStatus.status.plcDevice.deviceState = message.status.DeviceState;

                if(!this.localComm.localCommStatus.connected){
                    //connection initialization
                    this.plcStatus.statusEventsReceivedSinceLocalcommConnectionWasEstablished.plcDevice.deviceState = true;
                }

                returnStatus = true;
            }

        } else if (message.MT_MATIC) {

            handleValue = {
                'MTMATIC': {}
            }

            //bError can have value of 0 ==> it can be seen as false
            if (message.MT_MATIC.iMode && message.MT_MATIC.iState && typeof message.MT_MATIC.bError !== 'undefined') {

                if (typeof handleValue['MTMATIC'] !== 'undefined') {
                    if (typeof handleValue['MTMATIC']['status'] === 'undefined') {
                        handleValue['MTMATIC']['status'] = {
                            mode: '',
                            state: '',
                            errorCode: ''
                        };
                    }
                    handleValue['MTMATIC']['status'] = {
                        mode: message.MT_MATIC.iMode,
                        state: message.MT_MATIC.iState,
                        errorCode: message.MT_MATIC.bError
                    }
                    this.plcStatus.MTMATIC.status.mode = message.MT_MATIC.iMode.toLowerCase();
                    this.plcStatus.MTMATIC.status.state = message.MT_MATIC.iState.toLowerCase();
                    this.plcStatus.MTMATIC.status.errorCode = message.MT_MATIC.bError;

                    if(!this.localComm.localCommStatus.connected){
                        //connection initialization
                        this.plcStatus.statusEventsReceivedSinceLocalcommConnectionWasEstablished.MTMATIC.status.mode = true;
                        this.plcStatus.statusEventsReceivedSinceLocalcommConnectionWasEstablished.MTMATIC.status.state = true;
                        this.plcStatus.statusEventsReceivedSinceLocalcommConnectionWasEstablished.MTMATIC.status.errorCode = true;
                    }

                    this.checkPLCEmergecyValue();

                    if (this.plcStatus.currentJobId !== 0) {
                        if (this.plcJobProcessStatus.hasOwnProperty('status_' + this.plcStatus.currentJobId)) {
                            this.plcJobProcessStatus['status_' + this.plcStatus.currentJobId].mode = this.plcStatus.MTMATIC.status.mode;
                            this.plcJobProcessStatus['status_' + this.plcStatus.currentJobId].state = this.plcStatus.MTMATIC.status.state;
                            this.plcJobProcessStatus['status_' + this.plcStatus.currentJobId].errorCode = this.plcStatus.MTMATIC.status.errorCode;
                            this.plcJobProcessStatus['status_' + this.plcStatus.currentJobId].setUpdateTime();

                        }
                    }
                }
                returnStatus = true;
            }
            //iReqCode, iAckCode, iErrorCode can have value of 0 ==> it can be seen as false
            if (message.MT_MATIC.Job && message.MT_MATIC.Job.iState && typeof message.MT_MATIC.Job.iReqCode !== 'undefined' && typeof message.MT_MATIC.Job.iAckCode !== 'undefined' && typeof message.MT_MATIC.Job.iErrorCode !== 'undefined') {
                if (typeof handleValue['MTMATIC'] !== 'undefined') {
                    if (typeof handleValue['MTMATIC']['job'] === 'undefined') {
                        handleValue['MTMATIC']['job'] = {
                            state: '',
                            reqCode: '',
                            ackCode: '',
                            errorCode: ''
                        };
                    }
                    handleValue['MTMATIC']['job'] = {
                        state: message.MT_MATIC.Job.iState,
                        reqCode: message.MT_MATIC.Job.iReqCode,
                        ackCode: message.MT_MATIC.Job.iAckCode,
                        errorCode: message.MT_MATIC.Job.iErrorCode
                    }
                    this.plcStatus.MTMATIC.job.state = message.MT_MATIC.Job.iState.toLowerCase();
                    this.plcStatus.MTMATIC.job.reqCode = message.MT_MATIC.Job.iReqCode;
                    this.plcStatus.MTMATIC.job.ackCode = message.MT_MATIC.Job.iAckCode;
                    this.plcStatus.MTMATIC.job.errorCode = message.MT_MATIC.Job.iErrorCode;

                    if(!this.localComm.localCommStatus.connected){
                        //connection initialization
                        this.plcStatus.statusEventsReceivedSinceLocalcommConnectionWasEstablished.MTMATIC.job.state = true;
                        this.plcStatus.statusEventsReceivedSinceLocalcommConnectionWasEstablished.MTMATIC.job.reqCode = true;
                        this.plcStatus.statusEventsReceivedSinceLocalcommConnectionWasEstablished.MTMATIC.job.ackCode = true;
                        this.plcStatus.statusEventsReceivedSinceLocalcommConnectionWasEstablished.MTMATIC.job.errorCode = true;
                    }


                    if (this.plcStatus.currentJobId !== 0 && this.plcStatus.currentJobCode !== 0) {
                        if (this.plcStatus.MTMATIC.job.state === 'evaluating' && ((this.plcStatus.MTMATIC.job.reqCode === this.plcStatus.currentJobCode.toString()) || (this.plcStatus.MTMATIC.job.ackCode === this.plcStatus.currentJobCode.toString()))) {
                            this.plcStatus.currentJobIsEvaluated = true;
                        }
                        if (this.plcStatus.currentJobIsEvaluated && this.plcStatus.MTMATIC.job.state === 'valid' && this.plcStatus.MTMATIC.job.ackCode === this.plcStatus.currentJobCode.toString()) {
                            this.plcStatus.currentJobIsAccepted = true;
                        }
                        if (this.plcStatus.currentJobIsEvaluated && this.plcStatus.MTMATIC.job.state === 'invalid') {
                            this.plcStatus.currentJobIsNotAccepted = true;
                        }
                        if (this.plcStatus.currentJobIsAccepted && this.plcStatus.MTMATIC.job.state === 'eojok') {
                            this.plcStatus.currentJobIsDone = true;
                            this.plcStatus.currentJobIsBusy = false;
                        }
                        if (this.plcStatus.currentJobIsAccepted && this.plcStatus.MTMATIC.job.state === 'eojnok') {
                            this.plcStatus.currentJobIsFailed = true;
                            this.plcStatus.currentJobIsBusy = false;
                        }
                        if (this.plcStatus.currentJobIsAccepted && this.plcStatus.MTMATIC.job.state === 'positioning') {
                            this.plcStatus.currentJobIsBusy = true;
                        }
                        if (this.plcJobProcessStatus.hasOwnProperty('status_' + this.plcStatus.currentJobId)) {
                            this.plcJobProcessStatus['status_' + this.plcStatus.currentJobId].job.state = this.plcStatus.MTMATIC.job.state;
                            this.plcJobProcessStatus['status_' + this.plcStatus.currentJobId].job.ackCode = this.plcStatus.MTMATIC.job.ackCode;
                            this.plcJobProcessStatus['status_' + this.plcStatus.currentJobId].job.reqCode = this.plcStatus.MTMATIC.job.reqCode;
                            this.plcJobProcessStatus['status_' + this.plcStatus.currentJobId].job.errorCode = this.plcStatus.MTMATIC.job.errorCode;

                            this.plcJobProcessStatus['status_' + this.plcStatus.currentJobId].jobIsEvaluated = this.plcStatus.currentJobIsEvaluated;
                            this.plcJobProcessStatus['status_' + this.plcStatus.currentJobId].jobIsAccepted = this.plcStatus.currentJobIsAccepted;
                            this.plcJobProcessStatus['status_' + this.plcStatus.currentJobId].jobIsNotAccepted = this.plcStatus.currentJobIsNotAccepted;
                            this.plcJobProcessStatus['status_' + this.plcStatus.currentJobId].jobIsFailed = this.plcStatus.currentJobIsFailed;
                            this.plcJobProcessStatus['status_' + this.plcStatus.currentJobId].jobIsDone = this.plcStatus.currentJobIsDone;
                            this.plcJobProcessStatus['status_' + this.plcStatus.currentJobId].jobIsBusy = this.plcStatus.currentJobIsBusy;
                            this.plcJobProcessStatus['status_' + this.plcStatus.currentJobId].setUpdateTime();
                        }
                    }
                }
                returnStatus = true;
            }
        } else if (message.MT_MATIC_CV) {

            handleValue = {
                'MTMATIC_CV': {}
            }

            if (message.MT_MATIC_CV.iState && typeof message.MT_MATIC_CV.bError !== 'undefined') {
                if (typeof handleValue['MTMATIC_CV'] !== 'undefined') {
                    if (typeof handleValue['MTMATIC_CV']['status'] === 'undefined') {
                        handleValue['MTMATIC_CV']['status'] = {
                            state: '',
                            errorCode: ''
                        };
                    }
                    handleValue['MTMATIC_CV']['status'] = {
                        state: message.MT_MATIC_CV.iState,
                        errorCode: message.MT_MATIC_CV.bError
                    }
                    this.plcStatus.MTMATIC_CV.status.state = message.MT_MATIC_CV.iState.toLowerCase();
                    this.plcStatus.MTMATIC_CV.status.errorCode = message.MT_MATIC_CV.bError;

                    if(!this.localComm.localCommStatus.connected){
                        //connection initialization
                        this.plcStatus.statusEventsReceivedSinceLocalcommConnectionWasEstablished.MTMATIC_CV.status.state = true;
                        this.plcStatus.statusEventsReceivedSinceLocalcommConnectionWasEstablished.MTMATIC_CV.status.errorCode = true;
                    }

                }
                returnStatus = true;
            }
            if (message.MT_MATIC_CV.Job && message.MT_MATIC_CV.Job.iState && typeof message.MT_MATIC_CV.Job.iReqCode !== 'undefined' && typeof message.MT_MATIC_CV.Job.iAckCode !== 'undefined' && typeof message.MT_MATIC_CV.Job.iErrorCode !== 'undefined') {
                if (typeof handleValue['MTMATIC_CV'] !== 'undefined') {
                    if (typeof handleValue['MTMATIC_CV']['job'] === 'undefined') {
                        handleValue['MTMATIC_CV']['job'] = {
                            state: '',
                            ackCode: '',
                            reqCode: '',
                            errorCode: ''
                        };
                    }
                    handleValue['MTMATIC_CV']['job'] = {
                        state: message.MT_MATIC_CV.Job.iState,
                        reqCode: message.MT_MATIC_CV.Job.iReqCode,
                        ackCode: message.MT_MATIC_CV.Job.iAckCode,
                        errorCode: message.MT_MATIC_CV.Job.iErrorCode
                    }
                    this.plcStatus.MTMATIC_CV.job.state = message.MT_MATIC_CV.Job.iState.toLowerCase();
                    this.plcStatus.MTMATIC_CV.job.reqCode = message.MT_MATIC_CV.Job.iReqCode;
                    this.plcStatus.MTMATIC_CV.job.ackCode = message.MT_MATIC_CV.Job.iAckCode;
                    this.plcStatus.MTMATIC_CV.job.errorCode = message.MT_MATIC_CV.Job.iErrorCode;

                    if(!this.localComm.localCommStatus.connected){
                        //connection initialization
                        this.plcStatus.statusEventsReceivedSinceLocalcommConnectionWasEstablished.MTMATIC_CV.job.state = true;
                        this.plcStatus.statusEventsReceivedSinceLocalcommConnectionWasEstablished.MTMATIC_CV.job.reqCode = true;
                        this.plcStatus.statusEventsReceivedSinceLocalcommConnectionWasEstablished.MTMATIC_CV.job.ackCode = true;
                        this.plcStatus.statusEventsReceivedSinceLocalcommConnectionWasEstablished.MTMATIC_CV.job.errorCode = true;
                    }

                }
                returnStatus = true;
            }
        } else if (message.MT_MATIC_SWITCH) {

            //MT_MATIC_SWITCH and MT_MATIC_R_DOOR are the same, switch is used by the full matic, r_door is used by the light matic

            handleValue = {
                'MTMATIC_R_DOOR': {}
            }
            if (message.MT_MATIC_SWITCH.iState && typeof message.MT_MATIC_SWITCH.bError !== 'undefined') {
                if (typeof handleValue['MTMATIC_R_DOOR'] !== 'undefined') {
                    if (typeof handleValue['MTMATIC_R_DOOR']['status'] === 'undefined') {
                        handleValue['MTMATIC_R_DOOR']['status'] = {
                            state: '',
                            errorCode: ''
                        };
                    }
                    handleValue['MTMATIC_R_DOOR']['status'] = {
                        state: message.MT_MATIC_SWITCH.iState,
                        errorCode: message.MT_MATIC_SWITCH.bError
                    }
                    this.plcStatus.MTMATIC_R_DOOR.status.state = message.MT_MATIC_SWITCH.iState.toLowerCase();
                    this.plcStatus.MTMATIC_R_DOOR.status.errorCode = message.MT_MATIC_SWITCH.bError;

                    if(!this.localComm.localCommStatus.connected){
                        //connection initialization
                        this.plcStatus.statusEventsReceivedSinceLocalcommConnectionWasEstablished.MTMATIC_R_DOOR.status.state = true;
                        this.plcStatus.statusEventsReceivedSinceLocalcommConnectionWasEstablished.MTMATIC_R_DOOR.status.errorCode = true;
                    }

                }
                returnStatus = true;
            }
            if (message.MT_MATIC_SWITCH.Job && message.MT_MATIC_SWITCH.Job.iState && typeof message.MT_MATIC_SWITCH.Job.iReqCode !== 'undefined' && typeof message.MT_MATIC_SWITCH.Job.iAckCode !== 'undefined' && typeof message.MT_MATIC_SWITCH.Job.iErrorCode !== 'undefined') {
                if (typeof handleValue['MTMATIC_R_DOOR'] !== 'undefined') {
                    if (typeof handleValue['MTMATIC_R_DOOR']['job'] === 'undefined') {
                        handleValue['MTMATIC_R_DOOR']['job'] = {
                            reqCode: '',
                            state: '',
                            ackCode: '',
                            errorCode: ''
                        };
                    }
                    handleValue['MTMATIC_R_DOOR']['job'] = {
                        state: message.MT_MATIC_SWITCH.Job.iState,
                        reqCode: message.MT_MATIC_SWITCH.Job.iReqCode,
                        ackCode: message.MT_MATIC_SWITCH.Job.iAckCode,
                        errorCode: message.MT_MATIC_SWITCH.Job.iErrorCode
                    }
                    this.plcStatus.MTMATIC_R_DOOR.job.state = message.MT_MATIC_SWITCH.Job.iState.toLowerCase();
                    this.plcStatus.MTMATIC_R_DOOR.job.reqCode = message.MT_MATIC_SWITCH.Job.iReqCode;
                    this.plcStatus.MTMATIC_R_DOOR.job.ackCode = message.MT_MATIC_SWITCH.Job.iAckCode;
                    this.plcStatus.MTMATIC_R_DOOR.job.errorCode = message.MT_MATIC_SWITCH.Job.iErrorCode;

                    if(!this.localComm.localCommStatus.connected){
                        //connection initialization
                        this.plcStatus.statusEventsReceivedSinceLocalcommConnectionWasEstablished.MTMATIC_R_DOOR.job.state = true;
                        this.plcStatus.statusEventsReceivedSinceLocalcommConnectionWasEstablished.MTMATIC_R_DOOR.job.reqCode = true;
                        this.plcStatus.statusEventsReceivedSinceLocalcommConnectionWasEstablished.MTMATIC_R_DOOR.job.ackCode = true;
                        this.plcStatus.statusEventsReceivedSinceLocalcommConnectionWasEstablished.MTMATIC_R_DOOR.job.errorCode = true;
                    }

                }
                returnStatus = true;
            }
        } else if (message.MT_MATIC_R_DOOR) {

            //MT_MATIC_SWITCH and MT_MATIC_R_DOOR are the same, switch is used by the full matic, r_door is used by the light matic

            handleValue = {
                'MTMATIC_R_DOOR': {}
            }
            if (message.MT_MATIC_R_DOOR.iState && typeof message.MT_MATIC_R_DOOR.bError !== 'undefined') {
                if (typeof handleValue['MTMATIC_R_DOOR'] !== 'undefined') {
                    if (typeof handleValue['MTMATIC_R_DOOR']['status'] === 'undefined') {
                        handleValue['MTMATIC_R_DOOR']['status'] = {
                            state: '',
                            errorCode: ''
                        };
                    }
                    handleValue['MTMATIC_R_DOOR']['status'] = {
                        state: message.MT_MATIC_R_DOOR.iState,
                        errorCode: message.MT_MATIC_R_DOOR.bError
                    }
                    this.plcStatus.MTMATIC_R_DOOR.status.state = message.MT_MATIC_R_DOOR.iState.toLowerCase();
                    this.plcStatus.MTMATIC_R_DOOR.status.errorCode = message.MT_MATIC_R_DOOR.bError;

                    if(!this.localComm.localCommStatus.connected){
                        //connection initialization
                        this.plcStatus.statusEventsReceivedSinceLocalcommConnectionWasEstablished.MTMATIC_R_DOOR.status.state = true;
                        this.plcStatus.statusEventsReceivedSinceLocalcommConnectionWasEstablished.MTMATIC_R_DOOR.status.errorCode = true;
                    }
                }
                returnStatus = true;
            }
            if (message.MT_MATIC_R_DOOR.Job && message.MT_MATIC_R_DOOR.Job.iState && typeof message.MT_MATIC_R_DOOR.Job.iReqCode !== 'undefined' && typeof message.MT_MATIC_R_DOOR.Job.iAckCode !== 'undefined' && typeof message.MT_MATIC_R_DOOR.Job.iErrorCode !== 'undefined') {
                if (typeof handleValue['MTMATIC_R_DOOR'] !== 'undefined') {
                    if (typeof handleValue['MTMATIC_R_DOOR']['job'] === 'undefined') {
                        handleValue['MTMATIC_R_DOOR']['job'] = {
                            state: '',
                            errorCode: '',
                            reqCode: '',
                            ackCode: ''
                        };
                    }
                    handleValue['MTMATIC_R_DOOR']['job'] = {
                        state: message.MT_MATIC_R_DOOR.Job.iState,
                        reqCode: message.MT_MATIC_R_DOOR.Job.iReqCode,
                        ackCode: message.MT_MATIC_R_DOOR.Job.iAckCode,
                        errorCode: message.MT_MATIC_R_DOOR.Job.iErrorCode
                    }
                    this.plcStatus.MTMATIC_R_DOOR.job.state = message.MT_MATIC_R_DOOR.Job.iState.toLowerCase();
                    this.plcStatus.MTMATIC_R_DOOR.job.reqCode = message.MT_MATIC_R_DOOR.Job.iReqCode;
                    this.plcStatus.MTMATIC_R_DOOR.job.ackCode = message.MT_MATIC_R_DOOR.Job.iAckCode;
                    this.plcStatus.MTMATIC_R_DOOR.job.errorCode = message.MT_MATIC_R_DOOR.Job.iErrorCode;

                    if(!this.localComm.localCommStatus.connected){
                        //connection initialization
                        this.plcStatus.statusEventsReceivedSinceLocalcommConnectionWasEstablished.MTMATIC_R_DOOR.job.state = true;
                        this.plcStatus.statusEventsReceivedSinceLocalcommConnectionWasEstablished.MTMATIC_R_DOOR.job.reqCode = true;
                        this.plcStatus.statusEventsReceivedSinceLocalcommConnectionWasEstablished.MTMATIC_R_DOOR.job.ackCode = true;
                        this.plcStatus.statusEventsReceivedSinceLocalcommConnectionWasEstablished.MTMATIC_R_DOOR.job.errorCode = true;
                    }


                }
                returnStatus = true;
            }
        } else if (message.MT_MATIC_F_DOOR) {

            //MT_MATIC_DOOR and MT_MATIC_F_DOOR are the same, door is used by the full matic, f_door is used by the light matic

            handleValue = {
                'MTMATIC_F_DOOR': {}
            }
            if (message.MT_MATIC_F_DOOR.iState && typeof message.MT_MATIC_F_DOOR.bError !== 'undefined') {
                if (typeof handleValue['MTMATIC_F_DOOR'] !== 'undefined') {
                    if (typeof handleValue['MTMATIC_F_DOOR']['status'] === 'undefined') {
                        handleValue['MTMATIC_F_DOOR']['status'] = {
                            state: '',
                            errorCode: ''
                        };
                    }
                    handleValue['MTMATIC_F_DOOR']['status'] = {
                        state: message.MT_MATIC_F_DOOR.iState,
                        errorCode: message.MT_MATIC_F_DOOR.bError
                    }
                    this.plcStatus.MTMATIC_F_DOOR.status.state = message.MT_MATIC_F_DOOR.iState.toLowerCase();
                    this.plcStatus.MTMATIC_F_DOOR.status.errorCode = message.MT_MATIC_F_DOOR.bError;

                    if(!this.localComm.localCommStatus.connected){
                        //connection initialization
                        this.plcStatus.statusEventsReceivedSinceLocalcommConnectionWasEstablished.MTMATIC_F_DOOR.status.state = true;
                        this.plcStatus.statusEventsReceivedSinceLocalcommConnectionWasEstablished.MTMATIC_F_DOOR.status.errorCode = true;
                    }

                }
                returnStatus = true;
            }
            if (message.MT_MATIC_F_DOOR.Job && message.MT_MATIC_F_DOOR.Job.iState && typeof message.MT_MATIC_F_DOOR.Job.iReqCode !== 'undefined' && typeof message.MT_MATIC_F_DOOR.Job.iAckCode !== 'undefined' && typeof message.MT_MATIC_F_DOOR.Job.iErrorCode !== 'undefined') {
                if (typeof handleValue['MTMATIC_F_DOOR'] !== 'undefined') {
                    if (typeof handleValue['MTMATIC_F_DOOR']['job'] === 'undefined') {
                        handleValue['MTMATIC_F_DOOR']['job'] = {
                            state: '',
                            reqCode: '',
                            ackCode: '',
                            errorCode: ''
                        };
                    }
                    handleValue['MTMATIC_F_DOOR']['job'] = {
                        state: message.MT_MATIC_F_DOOR.Job.iState,
                        reqCode: message.MT_MATIC_F_DOOR.Job.iReqCode,
                        ackCode: message.MT_MATIC_F_DOOR.Job.iAckCode,
                        errorCode: message.MT_MATIC_F_DOOR.Job.iErrorCode
                    }
                    this.plcStatus.MTMATIC_F_DOOR.job.state = message.MT_MATIC_F_DOOR.Job.iState.toLowerCase();
                    this.plcStatus.MTMATIC_F_DOOR.job.reqCode = message.MT_MATIC_F_DOOR.Job.iReqCode;
                    this.plcStatus.MTMATIC_F_DOOR.job.ackCode = message.MT_MATIC_F_DOOR.Job.iAckCode;
                    this.plcStatus.MTMATIC_F_DOOR.job.errorCode = message.MT_MATIC_F_DOOR.Job.iErrorCode;

                    if(!this.localComm.localCommStatus.connected){
                        //connection initialization
                        this.plcStatus.statusEventsReceivedSinceLocalcommConnectionWasEstablished.MTMATIC_F_DOOR.job.state = true;
                        this.plcStatus.statusEventsReceivedSinceLocalcommConnectionWasEstablished.MTMATIC_F_DOOR.job.reqCode = true;
                        this.plcStatus.statusEventsReceivedSinceLocalcommConnectionWasEstablished.MTMATIC_F_DOOR.job.ackCode = true;
                        this.plcStatus.statusEventsReceivedSinceLocalcommConnectionWasEstablished.MTMATIC_F_DOOR.job.errorCode = true;
                    }

                }
                returnStatus = true;
            }
        } else if (message.MT_MATIC_DOOR) {

            //MT_MATIC_DOOR and MT_MATIC_F_DOOR are the same, door is used by the full matic, f_door is used by the light matic

            handleValue = {
                'MTMATIC_F_DOOR': {}
            }
            if (message.MT_MATIC_DOOR.iState && typeof message.MT_MATIC_DOOR.bError !== 'undefined') {
                if (typeof handleValue['MTMATIC_F_DOOR'] !== 'undefined') {
                    if (typeof handleValue['MTMATIC_F_DOOR']['status'] === 'undefined') {
                        handleValue['MTMATIC_F_DOOR']['status'] = {
                            state: '',
                            errorCode: ''
                        };
                    }
                    handleValue['MTMATIC_F_DOOR']['status'] = {
                        state: message.MT_MATIC_DOOR.iState,
                        errorCode: message.MT_MATIC_DOOR.bError
                    }
                    this.plcStatus.MTMATIC_F_DOOR.status.state = message.MT_MATIC_DOOR.iState.toLowerCase();
                    this.plcStatus.MTMATIC_F_DOOR.status.errorCode = message.MT_MATIC_DOOR.bError;

                    if(!this.localComm.localCommStatus.connected){
                        //connection initialization
                        this.plcStatus.statusEventsReceivedSinceLocalcommConnectionWasEstablished.MTMATIC_F_DOOR.status.state = true;
                        this.plcStatus.statusEventsReceivedSinceLocalcommConnectionWasEstablished.MTMATIC_F_DOOR.status.errorCode = true;
                    }
                }

                returnStatus = true;
            }
            if (message.MT_MATIC_DOOR.Job && message.MT_MATIC_DOOR.Job.iState && typeof message.MT_MATIC_DOOR.Job.iReqCode !== 'undefined' && typeof message.MT_MATIC_DOOR.Job.iAckCode !== 'undefined' && typeof message.MT_MATIC_DOOR.Job.iErrorCode !== 'undefined') {
                if (typeof handleValue['MTMATIC_F_DOOR'] !== 'undefined') {
                    if (typeof handleValue['MTMATIC_F_DOOR']['job'] === 'undefined') {
                        handleValue['MTMATIC_F_DOOR']['job'] = {
                            errorCode: '',
                            ackCode: '',
                            state: '',
                            reqCode: ''
                        };
                    }
                    handleValue['MTMATIC_F_DOOR']['job'] = {
                        state: message.MT_MATIC_DOOR.Job.iState,
                        reqCode: message.MT_MATIC_DOOR.Job.iReqCode,
                        ackCode: message.MT_MATIC_DOOR.Job.iAckCode,
                        errorCode: message.MT_MATIC_DOOR.Job.iErrorCode
                    }
                    this.plcStatus.MTMATIC_F_DOOR.job.state = message.MT_MATIC_DOOR.Job.iState.toLowerCase();
                    this.plcStatus.MTMATIC_F_DOOR.job.reqCode = message.MT_MATIC_DOOR.Job.iReqCode;
                    this.plcStatus.MTMATIC_F_DOOR.job.ackCode = message.MT_MATIC_DOOR.Job.iAckCode;
                    this.plcStatus.MTMATIC_F_DOOR.job.errorCode = message.MT_MATIC_DOOR.Job.iErrorCode;

                    if(!this.localComm.localCommStatus.connected){
                        //connection initialization
                        this.plcStatus.statusEventsReceivedSinceLocalcommConnectionWasEstablished.MTMATIC_F_DOOR.job.state = true;
                        this.plcStatus.statusEventsReceivedSinceLocalcommConnectionWasEstablished.MTMATIC_F_DOOR.job.reqCode = true;
                        this.plcStatus.statusEventsReceivedSinceLocalcommConnectionWasEstablished.MTMATIC_F_DOOR.job.ackCode = true;
                        this.plcStatus.statusEventsReceivedSinceLocalcommConnectionWasEstablished.MTMATIC_F_DOOR.job.errorCode = true;
                    }

                }
                returnStatus = true;
            }
        } else if (message.plc_msg) {
            if (Array.isArray(message.plc_msg)) {

                this.plcStatus.messages = [];
                this.plcStatus.plcMessagesCodes = [];
                message.plc_msg.forEach((plcMessageCode: string, plcMessageCodeIndex: number) => {

                    //let LocalcommLastInfo = this.localComm.getLastInfo();
                    let iMessageCode = parseInt(plcMessageCode);
                    // if(LocalcommLastInfo.versionInteger < 200){
                    if (iMessageCode <= 1) {
                        if(plcMessageCodeIndex === 0 ){
                            iMessageCode = iMessageCode === 1 ? 1 : 0; 
                        }else if(iMessageCode !== 0){
                            iMessageCode = plcMessageCodeIndex + 1;
                        }
                    }

                    if (iMessageCode != 0) {
                        this.plcStatus.plcMessagesCodes.push(iMessageCode);
                    }

                });

                this.checkPlcMessagesValues();

                handleValue = {
                    'messages': this.plcStatus.messages
                }
                returnStatus = true;
            }
        }

        if (returnStatus) {
            Object.keys(this.onPlcMessageCallbacks).forEach( callBackId => {
                this.onPlcMessageCallbacks[callBackId](handleValue, this.plcStatus);
            })
        }

        return returnStatus;

    }

    public getBoxDetectCounterPlc(): number {
        return this.plcStatus.status.boxDetect.plcCounter;
    }

    public getBoxDetectCounterSoftware(): number {
        return this.plcStatus.status.boxDetect.softwareCounter;
    }

    public sendStartJob(jobCode: number, jobId: number = Date.now()): Promise<PlcJobHandleStatus> {

        return new Promise(resolve => {

            let plcJobHandleStatus: PlcJobHandleStatus = {
                status: false,
                statusId: ''
            }
            if (!this.plcJobProcessStatus.hasOwnProperty('status_' + jobId)) {
                this.plcJobProcessStatus['status_' + jobId] = new PlcJobProcessStatus(jobCode, jobId);
            }
            if (this.canPlcExecuteAJob(jobCode)) {
                this.localComm.send({
                    action: 'plc',
                    command: {
                        Job: jobCode,
                        PrmTable:[100,0,0,0,0,0,0,0]
                    }
                });
                this.plcJobProcessStatus['status_' + jobId].updateJobStatus('requested');
                this.setPlcJobStatus(jobCode, jobId);
                plcJobHandleStatus.status = true;
                plcJobHandleStatus.statusId = 'status_' + jobId;
                resolve(plcJobHandleStatus);
            } else {
                this.plcJobProcessStatus['status_' + jobId].updateJobStatus('plcIsNotReady');
                plcJobHandleStatus.status = false;
                plcJobHandleStatus.statusId = 'status_' + jobId;
                resolve(plcJobHandleStatus);
            }
        });
    }

    public sendStartAndProcessTheJob(jobCode: number, jobId: number = Date.now()): Promise<PlcJobHandleStatus> {

        return new Promise(resolve => {

            this.sendStartJob(jobCode, jobId).then((returnedStatus: PlcJobHandleStatus) => {

                let plcJobHandleStatus: PlcJobHandleStatus = {
                    status: false,
                    statusId: returnedStatus.statusId
                }

                if (returnedStatus.status) {
                    if (this.plcJobProcessStatus.hasOwnProperty(returnedStatus.statusId)) {

                        if (this.plcJobProcessStatus[returnedStatus.statusId].status === 'requested') {
                            let timeoutValue = 30000;
                            if (this.plcJobTimeouts.hasOwnProperty('jobCode_' + jobCode)) {
                                timeoutValue = this.plcJobTimeouts[('jobCode_' + jobCode)];
                            }
                            const timeoutTimer = setTimeout(() => {
                                if (this.plcJobProcessStatus.hasOwnProperty(returnedStatus.statusId)) {
                                    if (this.plcJobProcessStatus[returnedStatus.statusId].jobCode === jobCode) {
                                        this.plcJobProcessStatus[returnedStatus.statusId].isTimeOut = true;
                                        console.log("Job timed out:");
                                        console.log(returnedStatus);
                                        resolve(plcJobHandleStatus);
                                    }
                                }
                            }, timeoutValue);
                            this.plcJobProcessStatus[returnedStatus.statusId].updateJobStatus('checkJobProcess');
                            this.checkJobProcess(returnedStatus.statusId, jobCode)
                                .then((jobStatus) => {
                                    clearTimeout(timeoutTimer);
                                    if (!this.plcJobProcessStatus[jobStatus.statusId].jobIsDone) {
                                        jobStatus.status = false;
                                    }
                                    
                                    resolve(jobStatus);
                                });
                        } else {
                            resolve(plcJobHandleStatus);
                        }
                    } else {
                        resolve(plcJobHandleStatus);
                    }
                } else {
                    resolve(plcJobHandleStatus);
                }
            });

        });

    }

    public plcChangeRunningMode(type: 'auto' | 'man' | 'reset' | 'stop' | 'resetPlcError'): Promise<boolean> {
        return new Promise(resolve => {

            if (this.plcChangeRunningModeProcessStatus.busyWithType === false && this.plcChangeRunningModeProcessStatus.type === 'none' && this.plcStatus.MTMATIC.status.state !== 'busy' && this.plcStatus.MTMATIC.status.state !== 'init' && this.plcStatus.MTMATIC.status.state !== 'unknown') {

                const timeoutTimer = setTimeout(() => {
                    if (this.plcChangeRunningModeProcessStatus.type === 'reset') {
                        this.plcChangeRunningModeProcessStatus.cancel = false;
                    }
                }, 120000);

                this.plcChangeRunningModeProcess(type).then(status => {
                    clearTimeout(timeoutTimer);
                    resolve(status);
                })
            } else {
                resolve(false);
            }
        });
    }

    public plcChangeRunningModeReset() {
        this.plcChangeRunningModeProcessStatus.type = 'none';
        this.plcChangeRunningModeProcessStatus.nextStep = 'none';
        this.plcChangeRunningModeProcessStatus.busyWith = 'none';
        this.plcChangeRunningModeProcessStatus.busyWithType = false;
        this.plcChangeRunningModeProcessStatus.cancel = false;
    }

    public plcChangeRunningModeProcess(type: 'man' | 'stop' | 'auto' | 'reset' | 'resetPlcError' = 'stop'): Promise<boolean> {
        return new Promise(resolve => {

            let continueProcess = false;
            let processEnded = false;
            let status = false;
            let timeoutValue = 500;
            if (this.plcChangeRunningModeProcessStatus.nextStep === 'none') {
                this.plcChangeRunningModeProcessStatus.type = type;
                this.plcChangeRunningModeProcessStatus.busyWithType = true;
                if (type === 'reset' || type === 'auto' || type === 'stop' || type === 'man') {

                    if (
                        (type === 'auto' && this.plcStatus.MTMATIC.status.mode === 'auto')
                        || (type === 'man' && this.plcStatus.MTMATIC.status.mode === 'man')
                        || (type === 'stop' && this.plcStatus.MTMATIC.status.mode === 'armed')
                    ) {
                        //process is already in the correct mode
                        this.plcChangeRunningModeProcessStatus.nextStep = 'done';
                        this.plcChangeRunningModeProcessStatus.busyWith = 'none';
                        continueProcess = true;
                    } else if (
                        (this.plcStatus.MTMATIC.status.state === 'notready' && (this.plcStatus.MTMATIC.status.mode === 'armed' || this.plcStatus.MTMATIC.status.mode === 'error' || this.plcStatus.MTMATIC.status.mode === 'fatalerror'))
                        || (this.plcStatus.MTMATIC.status.state === 'idle' && (this.plcStatus.MTMATIC.status.mode === 'auto' || this.plcStatus.MTMATIC.status.mode === 'man'))
                    ) {
                        this.plcChangeRunningModeProcessStatus.nextStep = 'stop';
                        this.plcChangeRunningModeProcessStatus.busyWith = 'none';
                        continueProcess = true;
                    }
                    //in other cases, plc cannnot change the running mode > this process will be aborted
                } else if (type === 'resetPlcError' && this.plcStatus.MTMATIC.status.state === 'notready' && this.plcStatus.MTMATIC.status.mode === 'armed') {
                    this.plcChangeRunningModeProcessStatus.nextStep = 'reset';
                    this.plcChangeRunningModeProcessStatus.busyWith = 'none';
                    continueProcess = true;
                }
                //in other types, process will be aborted
            } else if (this.plcChangeRunningModeProcessStatus.nextStep === 'stop') {
                if (this.plcChangeRunningModeProcessStatus.busyWith === 'none') {


                    if (this.plcStatus.MTMATIC.status.state === 'idle') {
                        //state is only on idle if the plc is in auto or in manual mode
                        if (this.plcStatus.MTMATIC.status.mode === 'auto' || this.plcStatus.MTMATIC.status.mode === 'man') {
                            this.plcChangeRunningModeProcessStatus.nextStep = 'stop';
                            this.plcChangeRunningModeProcessStatus.busyWith = 'stop';
                            //send commando
                            this.localComm.send({
                                action: 'plc',
                                command: {
                                    variable: 'bReqStop',
                                    value: true
                                }
                            });
                            continueProcess = true;
                        }
                    } else if (this.plcStatus.MTMATIC.status.state === 'notready') {

                        if (this.plcStatus.MTMATIC.status.mode === 'error' || this.plcStatus.MTMATIC.status.mode === 'fatalerror' || this.plcStatus.MTMATIC.status.mode === 'armed') {
                            this.plcChangeRunningModeProcessStatus.nextStep = 'stop';
                            this.plcChangeRunningModeProcessStatus.busyWith = 'stop';
                            //send commando
                            this.localComm.send({
                                action: 'plc',
                                command: {
                                    variable: 'bReqStop',
                                    value: true
                                }
                            });
                            continueProcess = true;
                        } else if (this.plcStatus.MTMATIC.status.mode === 'inithoming' || this.plcStatus.MTMATIC.status.mode === 'initauto') {
                            if (type === 'auto') {
                                this.plcChangeRunningModeProcessStatus.nextStep = 'auto';
                                this.plcChangeRunningModeProcessStatus.busyWith = 'none';
                                continueProcess = true;
                            } else if (type === 'man') {
                                this.plcChangeRunningModeProcessStatus.nextStep = 'man';
                                this.plcChangeRunningModeProcessStatus.busyWith = 'none';
                                continueProcess = true;
                            }
                        } else if (this.plcStatus.MTMATIC.status.mode === 'auto' || this.plcStatus.MTMATIC.status.mode === 'man') {
                            continueProcess = true;
                        }
                        //in mode readytoarm = the plc must first released via the fysical blue button = so this process will be aborted
                        //notready state cannot triggered when plc mode is in auto or in man
                    }

                } else if (this.plcChangeRunningModeProcessStatus.busyWith === 'stop') {
                    if (this.plcStatus.MTMATIC.status.state === 'notready') {

                        if (this.plcStatus.MTMATIC.status.mode === 'armed') {
                            if (type === 'auto' || type === 'reset' || type === 'man') {
                                //if plc mode is in armed > oke now can the error of the plc resetted
                                this.plcChangeRunningModeProcessStatus.nextStep = 'reset';
                                this.plcChangeRunningModeProcessStatus.busyWith = 'none';
                                continueProcess = true;
                            } else if (type === 'stop') {
                                //process is done
                                this.plcChangeRunningModeProcessStatus.nextStep = 'done';
                                this.plcChangeRunningModeProcessStatus.busyWith = 'none';
                                continueProcess = true;
                            }
                        } else if (this.plcStatus.MTMATIC.status.mode === 'error' || this.plcStatus.MTMATIC.status.mode === 'fatalerror') {
                            //if plc mode is in error or in fatalerror > plc software is not stopped > retry to send the stop command
                            this.plcChangeRunningModeProcessStatus.nextStep = 'stop';
                            this.plcChangeRunningModeProcessStatus.busyWith = 'none';
                            continueProcess = true;
                        } else if (this.plcStatus.MTMATIC.status.mode === 'inithoming') {
                            if (type === 'auto' || type === 'reset') {
                                //if plc mode is in inithoming or in initauto > plc is busy to change to auto mode
                                this.plcChangeRunningModeProcessStatus.nextStep = 'auto';
                                this.plcChangeRunningModeProcessStatus.busyWith = 'none';
                                continueProcess = true;
                            } else if (type === 'man') {
                                //if plc mode is in inithoming or in initauto > plc is busy to change to auto mode
                                this.plcChangeRunningModeProcessStatus.nextStep = 'man';
                                this.plcChangeRunningModeProcessStatus.busyWith = 'none';
                                continueProcess = true;
                            }
                        } else if (this.plcStatus.MTMATIC.status.mode === 'initauto') {
                            if (type === 'auto' || type === 'reset') {
                                //if plc mode is in inithoming or in initauto > plc is busy to change to auto mode
                                this.plcChangeRunningModeProcessStatus.nextStep = 'auto';
                                this.plcChangeRunningModeProcessStatus.busyWith = 'none';
                                continueProcess = true;
                            }
                        } else if (this.plcStatus.MTMATIC.status.mode === 'auto' || this.plcStatus.MTMATIC.status.mode === 'man') {
                            continueProcess = true;
                        }
                        //if the plc mode is in readytoarm > plc software must be released via the fysical blue button > so this process will be aborted
                    } else if (this.plcStatus.MTMATIC.status.state === 'idle') {
                        if (this.plcStatus.MTMATIC.status.mode === 'man' || this.plcStatus.MTMATIC.status.mode === 'auto'){
                            continueProcess = true; //process is still busy
                        }
                        //idle state is only in mode auto or man > in other cases this process will be aborted
                    }
                    //in other state, this process will be aborted

                }
            } else if (this.plcChangeRunningModeProcessStatus.nextStep === 'reset') {

                //send commando
                this.localComm.send({
                    action: 'plc',
                    command: {
                        variable: 'bReqReset',
                        value: true
                    }
                });
                timeoutValue = 1000;
                if (type === 'reset' || type === 'auto') {
                    this.plcChangeRunningModeProcessStatus.nextStep = 'auto';
                } else if (type === 'man') {
                    this.plcChangeRunningModeProcessStatus.nextStep = 'man';
                } else if (type === 'resetPlcError') {
                    this.plcChangeRunningModeProcessStatus.nextStep = 'done';
                }
                this.plcChangeRunningModeProcessStatus.busyWith = 'none';
                continueProcess = true;
            } else if (this.plcChangeRunningModeProcessStatus.nextStep === 'auto') {

                if (this.plcChangeRunningModeProcessStatus.busyWith === 'none') {
                    if (this.plcStatus.MTMATIC.status.state === 'notready') {

                        if (this.plcStatus.MTMATIC.status.mode === 'error' || this.plcStatus.MTMATIC.status.mode === 'fatalerror') {
                            this.plcChangeRunningModeProcessStatus.nextStep = 'stop';
                            this.plcChangeRunningModeProcessStatus.busyWith = 'none';
                            continueProcess = true;
                        } else if (this.plcStatus.MTMATIC.status.mode === 'armed' || this.plcStatus.MTMATIC.status.mode === 'inithoming' || this.plcStatus.MTMATIC.status.mode === 'initauto') {
                            if (this.plcStatus.MTMATIC.status.mode === 'armed') {
                                //send command
                                this.localComm.send({
                                    action: 'plc',
                                    command: {
                                        variable: 'bReqStartAuto',
                                        value: true
                                    }
                                });
                            }
                            this.plcChangeRunningModeProcessStatus.nextStep = 'auto';
                            this.plcChangeRunningModeProcessStatus.busyWith = 'auto';
                            continueProcess = true;
                        } else if (this.plcStatus.MTMATIC.status.mode === 'auto') {
                            continueProcess = true;
                        }
                    } else if (this.plcStatus.MTMATIC.status.state === 'idle' && this.plcStatus.MTMATIC.status.mode === 'auto') {
                        this.plcChangeRunningModeProcessStatus.nextStep = 'done';
                        this.plcChangeRunningModeProcessStatus.busyWith = 'none';
                        continueProcess = true;
                    } else if (this.plcStatus.MTMATIC.status.state === 'idle' && this.plcStatus.MTMATIC.status.mode === 'initauto') {
                        continueProcess = true;
                        this.plcChangeRunningModeProcessStatus.nextStep = 'auto';
                        this.plcChangeRunningModeProcessStatus.busyWith = 'auto';
                    }
                    //any other plc state > this process will be aborted
                } else if (this.plcChangeRunningModeProcessStatus.busyWith === 'auto') {
                    if (this.plcStatus.MTMATIC.status.state === 'idle' && this.plcStatus.MTMATIC.status.mode === 'auto') {
                        this.plcChangeRunningModeProcessStatus.nextStep = 'done';
                        this.plcChangeRunningModeProcessStatus.busyWith = 'none';
                        continueProcess = true;
                    } else if (this.plcStatus.MTMATIC.status.state === 'notready' && (this.plcStatus.MTMATIC.status.mode === 'error' || this.plcStatus.MTMATIC.status.mode === 'fatalerror')) {
                        this.plcChangeRunningModeProcessStatus.nextStep = 'stop';
                        this.plcChangeRunningModeProcessStatus.busyWith = 'none';
                        continueProcess = true;
                    } else if (this.plcStatus.MTMATIC.status.state === 'notready' && (this.plcStatus.MTMATIC.status.mode === 'inithoming' || this.plcStatus.MTMATIC.status.mode === 'initauto' || this.plcStatus.MTMATIC.status.mode === 'armed')) {
                        //plc is busy
                        continueProcess = true;
                    } else if (this.plcStatus.MTMATIC.status.state === 'idle' && this.plcStatus.MTMATIC.status.mode === 'initauto') {
                        //plc is busy
                        continueProcess = true;
                    }
                    //in other cases, this process will be aborted
                }

            } else if (this.plcChangeRunningModeProcessStatus.nextStep === 'man') {

                if (this.plcChangeRunningModeProcessStatus.busyWith === 'none') {
                    if (this.plcStatus.MTMATIC.status.state === 'notready') {

                        if (this.plcStatus.MTMATIC.status.mode === 'error' || this.plcStatus.MTMATIC.status.mode === 'fatalerror') {
                            this.plcChangeRunningModeProcessStatus.nextStep = 'stop';
                            this.plcChangeRunningModeProcessStatus.busyWith = 'none';
                            continueProcess = true;
                        } else if (this.plcStatus.MTMATIC.status.mode === 'armed' || this.plcStatus.MTMATIC.status.mode === 'inithoming') {
                            //send command
                            if (this.plcStatus.MTMATIC.status.mode === 'armed') {
                                this.localComm.send({
                                    action: 'plc',
                                    command: {
                                        variable: 'bReqStartMan',
                                        value: true
                                    }
                                });
                            }
                            this.plcChangeRunningModeProcessStatus.nextStep = 'man';
                            this.plcChangeRunningModeProcessStatus.busyWith = 'man';
                            continueProcess = true;
                        }

                    } else if (this.plcStatus.MTMATIC.status.state === 'idle' && this.plcStatus.MTMATIC.status.mode === 'man') {
                        this.plcChangeRunningModeProcessStatus.nextStep = 'done';
                        this.plcChangeRunningModeProcessStatus.busyWith = 'none';
                        continueProcess = true;
                    }
                    //any other plc state > this process will be aborted
                } else if (this.plcChangeRunningModeProcessStatus.busyWith === 'man') {
                    if (this.plcStatus.MTMATIC.status.state === 'idle' && this.plcStatus.MTMATIC.status.mode === 'man') {
                        this.plcChangeRunningModeProcessStatus.nextStep = 'done';
                        this.plcChangeRunningModeProcessStatus.busyWith = 'none';
                        continueProcess = true;
                    } else if (this.plcStatus.MTMATIC.status.state === 'notready' && (this.plcStatus.MTMATIC.status.mode === 'error' || this.plcStatus.MTMATIC.status.mode === 'fatalerror')) {
                        this.plcChangeRunningModeProcessStatus.nextStep = 'stop';
                        this.plcChangeRunningModeProcessStatus.busyWith = 'none';
                        continueProcess = true;
                    } else if (this.plcStatus.MTMATIC.status.state === 'notready' && (this.plcStatus.MTMATIC.status.mode === 'inithoming' || this.plcStatus.MTMATIC.status.mode === 'armed')) {
                        continueProcess = true;
                    } if (this.plcStatus.MTMATIC.status.state === 'idle' && this.plcStatus.MTMATIC.status.mode === 'inithoming') {
                        continueProcess = true;
                    }
                    //in other cases, this process will be aborted
                }

            } else if (this.plcChangeRunningModeProcessStatus.nextStep === 'done') {
                status = true;
                processEnded = true;
                this.plcChangeRunningModeReset();
            } else if (this.plcChangeRunningModeProcessStatus.nextStep === 'failed') {
                status = false;
                processEnded = true;
                this.plcChangeRunningModeReset();
            }

            if (this.plcChangeRunningModeProcessStatus.cancel) {
                continueProcess = false;
                processEnded = true;
                status = false;
            }

            if (continueProcess) {
                setTimeout(() => {
                    resolve(this.plcChangeRunningModeProcess(type));
                }, timeoutValue);
            } else if (processEnded) {
                resolve(status);
            } else {
                this.plcChangeRunningModeProcessStatus.nextStep = 'failed';
                this.plcChangeRunningModeProcessStatus.busyWith = 'none';
                setTimeout(() => {
                    resolve(this.plcChangeRunningModeProcess(type));
                }, timeoutValue);
            }
        });
    }

    public plcResetPlcMessages(){
        this.plcStatus.messages = [];
        this.plcStatus.plcMessagesCodes = [];
        this.plcStatus.errorPLCMessageStatus = {...PLCSTATUS_DEFAULT.errorPLCMessageStatus};
        this.plcStatus.warningPLCMessageStatus = {...PLCSTATUS_DEFAULT.warningPLCMessageStatus};
        this.plcStatus.infoPLCMessageStatus = {...PLCSTATUS_DEFAULT.infoPLCMessageStatus};
        this.plcStatus.debugPLCMessageStatus = {...PLCSTATUS_DEFAULT.debugPLCMessageStatus};
    }

    private checkPLCEmergecyValue(){



        //this.plcStatus.status.emergency.hmiEStopBtn1; === emergency button
        //this.plcStatus.status.emergency.hmiPilzStatus; === blue release button
        //this.plcStatus.status.emergency.stopCircuitClosed; === emergency electrical circuit; in normal mode, the circuit is closes = true

        let doEmergencyCallBack = false;
        if(
            this.plcStatus.status.emergency.stopCircuitClosed === false &&
            this.plcStatus.status.emergency.hmiPilzStatus === false &&
            this.plcStatus.status.emergency.hmiEStopBtn1 === true
        ){
            if(this.plcStatus.status.emergency.activated === false){
                doEmergencyCallBack=true;
            }
            this.plcStatus.status.emergency.activated = true;
            this.plcStatus.status.emergency.status = 'activated';
            this.plcStatus.status.releaseStatus = 'notReadyForRelease';
            this.plcStatus.status.released = false;
        }else if(
            this.plcStatus.status.emergency.stopCircuitClosed === true &&
            this.plcStatus.status.emergency.hmiPilzStatus === false &&
            this.plcStatus.status.emergency.hmiEStopBtn1 === false
        ){

            if(this.plcStatus.status.emergency.activated === false || this.plcStatus.status.emergency.status !== 'readyToRelease'){
                doEmergencyCallBack=true;
            }
            this.plcStatus.status.emergency.activated = true;
            this.plcStatus.status.emergency.status = 'readyToRelease';
            this.plcStatus.status.releaseStatus = 'readyToRelease';
            this.plcStatus.status.released = false;
        }else if(
            this.plcStatus.status.emergency.stopCircuitClosed === true &&
            this.plcStatus.status.emergency.hmiPilzStatus === true &&
            this.plcStatus.status.emergency.hmiEStopBtn1 === false
        ){
            if(this.plcStatus.MTMATIC.status.mode === 'auto' || this.plcStatus.MTMATIC.status.mode === 'man'){
                if((this.plcStatus.status.releaseStatus === 'busyWithRelease' || this.plcStatus.status.releaseStatus === 'readyToRelease')){
                    //PLC has been restarted after an emergency break
                    Object.keys(this.onPlcReleasedCallbacks).forEach( callBackId => {
                        this.onPlcReleasedCallbacks[callBackId](this.plcStatus.MTMATIC.status.mode, this.plcStatus.status.emergency.activated);
                    })
                }
                if(this.plcStatus.status.releaseStatus !== 'released'){
                    doEmergencyCallBack=true;
                }
                this.plcStatus.status.emergency.status = 'idle';
                this.plcStatus.status.releaseStatus = 'released';
                this.plcStatus.status.emergency.activated = false;
                this.plcStatus.status.released = true;
            }else if(this.plcStatus.MTMATIC.status.mode === 'initauto' || this.plcStatus.MTMATIC.status.mode === 'inithoming' || this.plcStatus.MTMATIC.status.mode === 'armed' ){
                if(this.plcStatus.status.emergency.status !== 'busyWithRelease'){
                    doEmergencyCallBack=true;
                }
                this.plcStatus.status.emergency.status = 'busyWithRelease';
                this.plcStatus.status.releaseStatus = 'busyWithRelease';
                this.plcStatus.status.released = false;
            }else if(this.plcStatus.MTMATIC.status.mode === 'fatalerror'){
                if(this.plcStatus.status.emergency.activated === false || this.plcStatus.status.emergency.status !== 'activated'){
                    doEmergencyCallBack=true;
                }
                //fatalerror = emergency break is activated
                this.plcStatus.status.emergency.status = 'activated';
                this.plcStatus.status.releaseStatus = 'notReadyForRelease';
                this.plcStatus.status.emergency.activated = true;
                this.plcStatus.status.released = false;
            }else if(this.plcStatus.MTMATIC.status.mode === 'error' || this.plcStatus.MTMATIC.status.mode === 'readytoarm'){
                if(this.plcStatus.status.emergency.status !== 'readyToRelease'){
                    doEmergencyCallBack=true;
                }
                this.plcStatus.status.emergency.status = 'readyToRelease';
                this.plcStatus.status.releaseStatus = 'readyToRelease';
                this.plcStatus.status.released = false;
            }
        }

        if(doEmergencyCallBack){
            Object.keys(this.onPlcEmergencyCallbacks).forEach( callBackId => {
                this.onPlcEmergencyCallbacks[callBackId](this.plcStatus.status.emergency.activated, this.plcStatus);
            })
        }
    }

    private checkPlcMessagesValues(){
        for(let key in PLCMESSAGEERRORCODES){
            if(typeof this.plcStatus.errorPLCMessageStatus[key] !== 'undefined'){
                if(this.plcStatus.plcMessagesCodes.indexOf(PLCMESSAGEERRORCODES[key]) !== -1){
                    if(!this.plcStatus.errorPLCMessageStatus[key]){
                        //add to logging
                        let plcMessages: PlcMessages = {
                            code: PLCMESSAGEERRORCODES[key],
                            name: key,
                            level: 'error',
                            timestamp: Date.now()
                        }
                        this.plcStatus.messages.push(plcMessages);
                    }
                    this.plcStatus.errorPLCMessageStatus[key] = true;
                }else{
                    this.plcStatus.errorPLCMessageStatus[key] = false;
                }
            }
        }

        for(let key in PLCMESSAGEWARNINGCODES){
            if(typeof this.plcStatus.warningPLCMessageStatus[key] !== 'undefined'){
                if(this.plcStatus.plcMessagesCodes.indexOf(PLCMESSAGEWARNINGCODES[key]) !== -1){
                    if(!this.plcStatus.warningPLCMessageStatus[key]){
                        //add to logging
                        let plcMessages: PlcMessages = {
                            code: PLCMESSAGEWARNINGCODES[key],
                            name: key,
                            level: 'warning',
                            timestamp: Date.now()
                        }
                        this.plcStatus.messages.push(plcMessages);
                    }
                    this.plcStatus.warningPLCMessageStatus[key] = true;
                }else{
                    this.plcStatus.warningPLCMessageStatus[key] = false;
                }
            }
        }
        for(let key in PLCMESSAGEINFOCODES){
            if(typeof this.plcStatus.infoPLCMessageStatus[key] !== 'undefined'){
                if(this.plcStatus.plcMessagesCodes.indexOf(PLCMESSAGEINFOCODES[key]) !== -1){
                    if(!this.plcStatus.infoPLCMessageStatus[key]){
                        //add to logging
                        let plcMessages: PlcMessages = {
                            code: PLCMESSAGEINFOCODES[key],
                            name: key,
                            level: 'info',
                            timestamp: Date.now()
                        }
                        this.plcStatus.messages.push(plcMessages);
                    }
                    this.plcStatus.infoPLCMessageStatus[key] = true;
                }else{
                    this.plcStatus.infoPLCMessageStatus[key] = false;
                }
            }
        }
        for(let key in PLCMESSAGEDEBUGCODES){
            if(typeof this.plcStatus.debugPLCMessageStatus[key] !== 'undefined'){
                if(this.plcStatus.plcMessagesCodes.indexOf(PLCMESSAGEDEBUGCODES[key]) !== -1){
                    if(!this.plcStatus.debugPLCMessageStatus[key]){
                        //add to logging
                        let plcMessages: PlcMessages = {
                            code: PLCMESSAGEDEBUGCODES[key],
                            name: key,
                            level: 'debug',
                            timestamp: Date.now()
                        }
                        this.plcStatus.messages.push(plcMessages);
                    }
                    this.plcStatus.debugPLCMessageStatus[key] = true;
                }else{
                    this.plcStatus.debugPLCMessageStatus[key] = false;
                }
            }
        }
    }
}