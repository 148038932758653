import * as React from 'react';
import { createPortal } from 'react-dom';

import { Tx } from '@/typography';

import { PortalContext } from './PortalContext';

function Portal(
    {
        aboveSidebar,
        className,
        style,
        children,
    }: {
        aboveSidebar?: boolean;
        className?: string;
        style?: React.CSSProperties;
        children?: React.ReactNode;
    },
    ref: React.ForwardedRef<HTMLDivElement>,
) {
    const parentPortalContext = React.useContext(PortalContext);
    const [portalContainer, setPortalContainer] = React.useState<HTMLElement | null>(document.getElementById('app-portal-container'));

    React.useEffect(() => {
        if (!portalContainer) {
            setPortalContainer(document.getElementById('app-portal-container'));
        }
    }, []);

    // depends on SCSS $depth-main-sidebar variable (4000 > $depth-main-sidebar > 2000)
    const zIndex = Math.max(aboveSidebar ? 4000 : 2000, parentPortalContext.parentMaxDepth);

    const ownStyle: React.CSSProperties = {
        position: 'absolute',
        zIndex,
        ...style,
    };

    if (!portalContainer) {
        return <span />;
    }

    return createPortal(
        <PortalContext.Provider
            value={{
                parentPortals: parentPortalContext.parentPortals + 1,
                parentMaxDepth: zIndex,
            }}
        >
            <Tx>
                <div
                    ref={ref}
                    className={className}
                    style={ownStyle}
                >
                    {children}
                </div>
            </Tx>
        </PortalContext.Provider>,
        portalContainer,
    );
}

export default React.forwardRef(Portal);
