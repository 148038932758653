import {
    Ownership,
    Pagination,
    Sorting,
    UpdateWebshopConfigurationPayload,
    UpdateWebshopPayload,
    WebshopDetails,
    WebshopConfiguration,
    WebshopsPage,
    UnassignedWebshopsPage,
    UpdateWebshopImagePayload,
    UpdateWebshopImageWithSizePayload,
    UpdateWebshopSocialMediaLinksPayload,
    UpdateWebshopPaymentConfigurationPayload,
    UpdateWebshopQualityLabelsPayload,
    AssignWebshopToBranchPayload,
    UnassignedWebshopDetails,
    UpdateWebshopVacationConfigurationPayload,
    UpdateWebshopVacationAnnouncementConfigurationPayload,
    UpdateWebshopMailServerPayload,
    UpdateTranslateOptionActiveStatusPayload,
    UpdateWebshopExtraProcessingTimesPayload,
    UpdateWebshopCategoriesSequencePayload,
    UpdateWebshopCartRestrictionsPayload,
    UpdateActiveTranslationLanguages,
} from '@/Domain';

import Endpoint from './Endpoint';

export default class WebshopEndpoint extends Endpoint {
    public async GetWebshops(
        ownership: Ownership,
        pagination: Pagination,
        sorting: Sorting,
        search?: string,
        filters?: {
            companyId?: string;
            branchId?: string;
            magentoApiHost?: string;
            magentoApiKey?: string;
            availableIntegrationIds?: string;
        },
    ): Promise<WebshopsPage> {
        let url = '/webshop/overview';
        if (ownership.type === 'branch' || ownership.type === 'company') {
            url = `/${ownership.type}/${ownership.ownerId}/webshops`;
        }

        const response = await this.client.get({
            url,
            params: {
                pagination,
                sorting,
                search,
                filters: filters
                    ? {
                          ...filters,
                          availableIntegrationIds: filters.availableIntegrationIds ? filters.availableIntegrationIds.split(',') : undefined,
                      }
                    : {},
            },
        });

        this.guardResponseSchema('#/definitions/WebshopsPage', response);

        return response;
    }

    public async GetUnassignedWebshops(
        pagination: Pagination,
        sorting: Sorting,
        search?: string,
        filters?: {
            companyId?: string;
            branchId?: string;
            magentoApiHost?: string;
            magentoApiKey?: string;
        },
    ): Promise<UnassignedWebshopsPage> {
        const url = '/webshop/unassigned-overview';

        const response = await this.client.get({
            url,
            params: {
                pagination,
                sorting,
                search,
                filters,
            },
        });

        this.guardResponseSchema('#/definitions/UnassignedWebshopsPage', response);

        return response;
    }

    public async GetWebshopDetails(webshopId: string): Promise<WebshopDetails> {
        const response = await this.client.get({
            url: '/webshop/' + webshopId + '/details',
        });

        this.guardResponseSchema('#/definitions/WebshopDetails', response);

        return response;
    }

    public async GetUnassignedWebshopDetails(webshopId: string): Promise<UnassignedWebshopDetails> {
        const response = await this.client.get({
            url: '/webshop/unassigned/' + webshopId + '/details',
        });

        this.guardResponseSchema('#/definitions/UnassignedWebshopDetails', response);

        return response;
    }

    public async UpdateWebshop(payload: UpdateWebshopPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/UpdateWebshop',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Webshop\\UpdateWebshop\\UpdateWebshop',
                payload,
            },
        });
    }

    public async AssignWebshopToBranch(payload: AssignWebshopToBranchPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/AssignWebshopToBranch',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Webshop\\AssignWebshopToBranch\\AssignWebshopToBranch',
                payload,
            },
        });
    }

    public async GetWebshopConfiguration(webshopId: string): Promise<WebshopConfiguration> {
        let response: WebshopConfiguration = await this.client.get({
            url: '/webshop/' + webshopId + '/configuration',
        });

        if (
            response.vacationConfiguration &&
            response.vacationConfiguration.description &&
            Array.isArray(response.vacationConfiguration.description)
        ) {
            response = {
                ...response,
                vacationConfiguration: {
                    ...response.vacationConfiguration,
                    description: {},
                },
            };
        }

        if (
            response.vacationAnnouncementConfiguration &&
            response.vacationAnnouncementConfiguration.description &&
            Array.isArray(response.vacationAnnouncementConfiguration.description)
        ) {
            response = {
                ...response,
                vacationAnnouncementConfiguration: {
                    ...response.vacationAnnouncementConfiguration,
                    description: {},
                },
            };
        }

        this.guardResponseSchema('#/definitions/WebshopConfiguration', response);

        return response;
    }

    public async UpdateWebshopConfiguration(payload: UpdateWebshopConfigurationPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/UpdateWebshopConfiguration',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Webshop\\UpdateGeneralConfiguration\\UpdateGeneralConfiguration',
                payload,
            },
        });
    }

    public async UpdateWebshopSocialMediaLinks(payload: UpdateWebshopSocialMediaLinksPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/UpdateWebshopSocialMediaLinks',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Webshop\\UpdateSocialMediaLinks\\UpdateSocialMediaLinks',
                payload,
            },
        });
    }

    public async UpdateWebshopQualityLabels(payload: UpdateWebshopQualityLabelsPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/UpdateWebshopQualityLabels',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Webshop\\UpdateWebshopQualityLabels\\UpdateWebshopQualityLabels',
                payload,
            },
        });
    }

    public async UpdateWebshopLogo(payload: UpdateWebshopImageWithSizePayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/UpdateWebshopLogo',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Webshop\\UpdateWebshopLogo\\UpdateWebshopLogo',
                payload,
            },
        });
    }

    public async UpdateDarkWebshopLogo(payload: UpdateWebshopImageWithSizePayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/UpdateDarkWebshopLogo',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Webshop\\UpdateDarkWebshopLogo\\UpdateDarkWebshopLogo',
                payload,
            },
        });
    }

    public async UpdateWebshopEmailLogo(payload: UpdateWebshopImageWithSizePayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/UpdateEmailLogo',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Webshop\\UpdateEmailLogo\\UpdateEmailLogo',
                payload,
            },
        });
    }

    public async UpdateWebshopFavIcon(payload: UpdateWebshopImagePayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/UpdateFavIcon',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Webshop\\UpdateFavIcon\\UpdateFavIcon',
                payload,
            },
        });
    }

    public async UpdateWebshopPaymentConfiguration(payload: UpdateWebshopPaymentConfigurationPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/UpdateWebshopPaymentConfigurationPayload',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Webshop\\UpdatePaymentConfiguration\\UpdatePaymentConfiguration',
                payload,
            },
        });
    }

    public async UpdateWebshopVacationConfiguration(payload: UpdateWebshopVacationConfigurationPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/UpdateWebshopVacationConfigurationPayload',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Webshop\\UpdateVacationConfiguration\\UpdateVacationConfiguration',
                payload,
            },
        });
    }

    public async UpdateWebshopVacationAnnouncementConfiguration(
        payload: UpdateWebshopVacationAnnouncementConfigurationPayload,
    ): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/UpdateWebshopVacationAnnouncementConfiguration',
            body: {
                commandName:
                    'App\\Domain\\WriteModel\\Webshop\\UpdateVacationAnnouncementConfiguration\\UpdateVacationAnnouncementConfiguration',
                payload,
            },
        });
    }

    public async UpdateWebshopMailServer(payload: UpdateWebshopMailServerPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/UpdateMailServer',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Webshop\\UpdateMailServer\\UpdateMailServer',
                payload,
            },
        });
    }

    public async UpdateTranslateOptionActiveStatus(payload: UpdateTranslateOptionActiveStatusPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/UpdateTranslateOptionActiveStatus',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Webshop\\UpdateTranslateOptionActiveStatus\\UpdateTranslateOptionActiveStatus',
                payload,
            },
        });
    }
    public async UpdateActiveTranslationLanguages(payload: UpdateActiveTranslationLanguages): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/UpdateActiveTranslationLanguages',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Webshop\\UpdateActiveTranslationLanguages\\UpdateActiveTranslationLanguages',
                payload,
            },
        });
    }
    public async UpdateExtraProcessingTimes(payload: UpdateWebshopExtraProcessingTimesPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/UpdateExtraProcessingTimes',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Webshop\\UpdateExtraProcessingTimes\\UpdateExtraProcessingTimes',
                payload,
            },
        });
    }

    public async UpdateWebshopCategoriesSequence(payload: UpdateWebshopCategoriesSequencePayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/UpdateWebshopCategoriesSequence',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Webshop\\Product\\Category\\UpdateCategoriesSequence\\UpdateCategoriesSequence',
                payload,
            },
        });
    }

    public async UpdateCartRestrictions(payload: UpdateWebshopCartRestrictionsPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/UpdateCartRestrictions',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Webshop\\UpdateShoppingCartRestrictions\\UpdateShoppingCartRestrictions',
                payload,
            },
        });
    }
}
