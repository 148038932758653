import * as React from 'react';

import ControlledSelect, { ControlledSelectProps } from './ControlledSelect';

type SingleSelectProps = {
    multiple?: never;
    defaultValue?: string;
};

type MultiSelectProps = {
    multiple?: true;
    defaultValue?: string[];
};

export type UncontrolledSelectProps = ControlledSelectProps & (SingleSelectProps | MultiSelectProps);

interface IState {
    value?: string | string[];
}

class UncontrolledSelect extends React.PureComponent<UncontrolledSelectProps, IState> {
    constructor(props: UncontrolledSelectProps) {
        super(props);

        this.state = {
            value: props.defaultValue || (this.isMultiSelect(props) ? [] : ''),
        };
    }

    render() {
        const { children, onChange, forwardRef, ...rest } = this.props;

        return (
            <ControlledSelect
                {...rest}
                ref={forwardRef}
                value={this.state.value as any}
                onChange={this.handleChange}
            >
                {children}
            </ControlledSelect>
        );
    }

    protected handleChange = (newValue: string | string[], callback?: () => void): void => {
        const { onChange } = this.props;

        this.setState(
            {
                value: newValue,
            },
            () => {
                if (onChange) {
                    onChange(this.state.value as any);
                }

                if (callback) {
                    callback();
                }
            },
        );
    };

    private isMultiSelect(props: UncontrolledSelectProps): props is ControlledSelectProps & MultiSelectProps {
        return (props as MultiSelectProps).hasOwnProperty('multiple') && !!(props as MultiSelectProps).multiple;
    }
}

export default React.forwardRef<HTMLSelectElement, UncontrolledSelectProps>((props, ref) => {
    return (
        <UncontrolledSelect
            forwardRef={ref}
            {...props}
        />
    );
});
