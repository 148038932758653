import * as React from 'react';

import styles from './styles/FrameEyedropArea.scss';

export default function FrameEyedropperArea(props: {
    style?: React.CSSProperties;
    onMouseDown?: () => void;
    onMouseUp?: () => void;
    onMouseMove?: (x: number, y: number, width: number, height: number) => void;
    onMouseOut?: () => void;
}) {
    const frameEyedropperArea = React.createRef<HTMLDivElement>();
    const [justAdded, setJustAdded] = React.useState(true);

    React.useEffect(() => {
        setTimeout(() => {
            setJustAdded(false);
        }, 10);
    }, []);

    return (
        <div
            className={`${styles.FrameEyedropArea} ${justAdded ? '' : styles.FrameEyedropAreaJustAdded}`}
            style={props.style}
            ref={frameEyedropperArea}
            onMouseMove={event => {
                if (props.onMouseMove && frameEyedropperArea.current) {
                    const box = frameEyedropperArea.current.getBoundingClientRect();
                    props.onMouseMove(event.clientX - box.x, event.clientY - box.y, box.width, box.height);
                }
            }}
            onMouseOut={props.onMouseOut}
            onClick={event => {
                event.nativeEvent.stopImmediatePropagation();
                event.preventDefault();
                event.stopPropagation();
            }}
            onMouseDown={event => {
                event.nativeEvent.stopImmediatePropagation();
                event.preventDefault();
                event.stopPropagation();

                if (props.onMouseDown) {
                    props.onMouseDown();
                }
            }}
            onMouseUp={event => {
                event.nativeEvent.stopImmediatePropagation();
                event.preventDefault();
                event.stopPropagation();

                if (props.onMouseUp) {
                    props.onMouseUp();
                }
            }}
        />
    );
}
