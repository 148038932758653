import * as React from 'react';

import ControlledSelectTree, { ControlledSelectTreePropsWithValue } from './ControlledSelectTree';
import UncontrolledSelectTree, { UncontrolledSelectTreeProps } from './UncontrolledSelectTree';

export type SelectTreeProps = ControlledSelectTreePropsWithValue | UncontrolledSelectTreeProps;

class SelectTree extends React.PureComponent<SelectTreeProps> {
    render() {
        if (this.isControlled(this.props)) {
            return <ControlledSelectTree {...this.props} />;
        }

        return <UncontrolledSelectTree {...this.props} />;
    }

    private isControlled(props: SelectTreeProps): props is ControlledSelectTreePropsWithValue {
        return (props as ControlledSelectTreePropsWithValue).hasOwnProperty('value');
    }
}

export default React.forwardRef<HTMLSelectElement, SelectTreeProps>((props, ref) => {
    return (
        <SelectTree
            forwardRef={ref}
            {...props}
        />
    );
});
