import { Domain } from 'api';
import { CollectMockApi, CollectApi, LocalComm } from 'meditech-device-api';
import { ICollect } from 'meditech-device-interface';

class CollectApiWrapper implements ICollect {
    private deviceDetails: Domain.DeviceDetails | undefined;
    private implementation: ICollect | undefined;

    public initialize(deviceDetails: Domain.DeviceDetails, localComm: LocalComm) {
        this.deviceDetails = deviceDetails;

        if (this.deviceDetails.configuration.general?.simulateLockers) {
            this.implementation = new CollectMockApi();
        } else {
            this.implementation = new CollectApi(localComm);
        }
    }

    public get LockerEvent() {
        if (!this.implementation) {
            throw new Error('CollectApi not initialized');
        }

        return this.implementation.LockerEvent;
    }

    public OpenLock(moduleNumber: number, lockerNumber: number) {
        if (!this.implementation) {
            throw new Error('CollectApi not initialized');
        }

        return this.implementation.OpenLock(moduleNumber, lockerNumber);
    }

    public GetLockStatus(moduleNumber: number, lockerNumber: number) {
        if (!this.implementation) {
            throw new Error('CollectApi not initialized');
        }

        return this.implementation.GetLockStatus(moduleNumber, lockerNumber);
    }

    public GetLockStatuses() {
        if (!this.implementation) {
            throw new Error('CollectApi not initialized');
        }

        return this.implementation.GetLockStatuses();
    }

    public GetModuleGridConfiguration() {
        if (!this.implementation) {
            throw new Error('CollectApi not initialized');
        }

        return this.implementation.GetModuleGridConfiguration();
    }

    public SimulateCloseAllLocks() {
        if (!this.implementation) {
            throw new Error('CollectApi not initialized');
        }

        if (!(this.implementation instanceof CollectMockApi)) {
            throw new Error('CollectApi is not a mock implementation');
        }

        return this.implementation.SimulateCloseAllLocks();
    }
}

export const meditechCollectApi = new CollectApiWrapper();
