import * as React from 'react';

import { Icon } from '@/icon';

import Input from './Input';

import styles from './PasswordInput.scss';

export interface PasswordInputProps {
    name: string;
    value?: string;
    onChange: (newValue?: string) => void;
    label?: React.ReactNode;
    placeholder?: string;
    forwardRef?: React.Ref<any>;
    disabled?: boolean;
    error?: boolean;
    errorMessage?: React.ReactNode;
    className?: string;
    allowLastPass?: boolean;
}

function PasswordInput(props: PasswordInputProps) {
    const [showPassword, setShowPassword] = React.useState(false);

    return (
        <Input
            name={props.name}
            value={props.value}
            onChange={props.onChange}
            type={showPassword ? 'text' : 'password'}
            label={props.label}
            placeholder={props.placeholder}
            ref={props.forwardRef}
            error={props.error}
            errorMessage={props.errorMessage}
            disabled={props.disabled}
            iconRight={
                <a
                    className={styles.TogglePasswordVisibility}
                    href=""
                    onClick={event => {
                        event.preventDefault();
                        setShowPassword(!showPassword);
                    }}
                >
                    <Icon
                        type={showPassword ? 'action_visibility_off' : 'action_visibility_on'}
                        iconSize="m"
                    />
                </a>
            }
            className={styles.PasswordInput + ' ' + (props.className || '')}
            allowLastPass={props.allowLastPass}
        />
    );
}

export default React.forwardRef<HTMLInputElement, PasswordInputProps>((props, ref) => {
    return (
        <PasswordInput
            forwardRef={ref}
            {...props}
        />
    );
});
