import { DeviceLog } from 'logger';
import { browserStorage } from 'utils';

const MIN_REFRESH_DELAY = 1000 * 60; // one minute

export default function refreshOnError(options?: { callback?: () => Promise<void> }) {
    let numPreviousErrors = 0;
    let lastErrorTimestamp = Date.now();

    const numPreviousErrorsItem = browserStorage.getItem('previousErrors');
    if (numPreviousErrorsItem) {
        numPreviousErrors = parseInt(numPreviousErrorsItem.value, 10);
    }

    const lastErrorTimestampItem = browserStorage.getItem('lastErrorTimestamp');
    if (lastErrorTimestampItem) {
        lastErrorTimestamp = parseInt(lastErrorTimestampItem.value, 10);
    }

    numPreviousErrors += 1;

    const delay = MIN_REFRESH_DELAY + numPreviousErrors * 2500;
    if (delay > 1000 * 60 * 5) {
        numPreviousErrors = 0;
    }

    if (Date.now() - lastErrorTimestamp > 1000 * 60 * 60) {
        numPreviousErrors = 0;
    }

    browserStorage.setItem('previousErrors', numPreviousErrors.toString(10), true);
    browserStorage.setItem('lastErrorTimestamp', Date.now().toString(10), true);

    console.warn('Will refresh due to error. Delay is', delay);
    setTimeout(async () => {
        if (options && options.callback) {
            await options.callback();
        }

        DeviceLog.refreshedDueToError();
        console.warn('Refreshing due to error. Delay was', delay);
        window.location.reload();
    }, delay);
}
