import { createAction, createReducer, Selector } from '@reduxjs/toolkit';

import { Domain } from 'api';

import { ThunkAction, withPayloadType } from '@/action';
import { webshopApi, webshopLayoutApi, billableServiceApi, qualityLabelApi } from '@/api';
import { URLParams } from '@/routing';
import { RootState } from '@/store';
import { globalSelectionState } from '@/Webshop';

export interface State {
    webshop?: Domain.Webshop;
    configuration?: Domain.WebshopConfiguration;
    colorThemes?: Domain.WebshopColorSetsPage;
    billableServices?: Domain.BillableServicesPage;
    qualityLabels?: Domain.QualityLabelsPage;
}

const initialState: State = {};

const reducerActions = {
    setWebshop: createAction('@webshop/update/setWebshop', withPayloadType<Domain.Webshop>()),
    setConfiguration: createAction('@webshop/update/setConfiguration', withPayloadType<Domain.WebshopConfiguration>()),
    setColorThemes: createAction('@webshop/update/setColorThemes', withPayloadType<Domain.WebshopColorSetsPage>()),
    setBillableServices: createAction('@webshop/update/setBillableServices', withPayloadType<Domain.BillableServicesPage>()),
    setQualityLabels: createAction('@webshop/update/setQualityLabels', withPayloadType<Domain.QualityLabelsPage>()),
};

export const updateReducer = createReducer(initialState, builder =>
    builder
        .addCase(reducerActions.setWebshop, (state, action) => {
            state.webshop = action.payload;
        })
        .addCase(reducerActions.setConfiguration, (state, action) => {
            state.configuration = action.payload;
        })
        .addCase(reducerActions.setColorThemes, (state, action) => {
            state.colorThemes = action.payload;
        })
        .addCase(reducerActions.setBillableServices, (state, action) => {
            state.billableServices = action.payload;
        })
        .addCase(reducerActions.setQualityLabels, (state, action) => {
            state.qualityLabels = action.payload;
        }),
);

export const selectWebshop: Selector<RootState, Domain.Webshop> = state => {
    const webshop = state.webshop.update.webshop;
    if (!webshop) {
        throw new Error('Webshop details not loaded');
    }

    return webshop;
};

export const selectConfiguration: Selector<RootState, Domain.WebshopConfiguration> = state => {
    const configuration = state.webshop.update.configuration;
    if (!configuration) {
        throw new Error('Webshop configurations not loaded');
    }

    return configuration;
};

export const selectColorThemes: Selector<RootState, Domain.WebshopColorSetsPage> = state => {
    const colorThemes = state.webshop.update.colorThemes;

    if (!colorThemes) {
        throw new Error('Color themes not loaded');
    }

    return colorThemes;
};

export const selectBillableServices: Selector<RootState, Domain.BillableServicesPage> = state => {
    const billableServices = state.webshop.update.billableServices;
    if (!billableServices) {
        throw new Error('Billable services not loaded');
    }

    return billableServices;
};

export const selectQualityLabels: Selector<RootState, Domain.QualityLabelsPage> = state => {
    const qualityLabels = state.webshop.update.qualityLabels;
    if (!qualityLabels) {
        throw new Error('Quality labels not loaded');
    }

    return qualityLabels;
};

export const loadWebshop =
    (options: { urlParams: URLParams }): ThunkAction =>
    async dispatch => {
        const webshop = await webshopApi.GetWebshopDetails(options.urlParams.webshopId);
        await dispatch(reducerActions.setWebshop(webshop));
    };

export const loadConfiguration = (): ThunkAction => async (dispatch, getState) => {
    const state = getState();
    const webshopId = globalSelectionState.selectGlobalSelectedWebshopId(state);

    if (!webshopId) {
        return;
    }

    const [webshop, configuration] = await Promise.all([
        webshopApi.GetWebshopDetails(webshopId),
        webshopApi.GetWebshopConfiguration(webshopId),
    ]);

    await dispatch(reducerActions.setWebshop(webshop));
    await dispatch(reducerActions.setConfiguration(configuration));
};

export const loadColorThemes = (): ThunkAction => async dispatch => {
    const colorThemes = await webshopLayoutApi.GetWebshopColorSets({
        page: 1,
        size: 999,
    });
    await dispatch(reducerActions.setColorThemes(colorThemes));
};

export const loadBillabeServices = (): ThunkAction => async dispatch => {
    const billableServicesPage = await billableServiceApi.GetBillableServices({ page: 1, size: 999 });
    await dispatch(reducerActions.setBillableServices(billableServicesPage));
};

export const loadQualityLabels = (): ThunkAction => async dispatch => {
    const qualityLabelsPage = await qualityLabelApi.GetQualityLabels({ page: 1, size: 999 }, { field: 'name', direction: 'ascending' });
    await dispatch(reducerActions.setQualityLabels(qualityLabelsPage));
};

export const loadWebshopAndConfiguration =
    (options: { urlParams: URLParams }): ThunkAction =>
    async dispatch => {
        await Promise.all([dispatch(loadWebshop(options)), dispatch(loadConfiguration())]);
    };

export const loadColorThemesAndConfiguration = (): ThunkAction => async dispatch => {
    await Promise.all([dispatch(loadColorThemes()), dispatch(loadConfiguration())]);
};

export const loadBillableServicesAndConfiguration = (): ThunkAction => async dispatch => {
    await Promise.all([dispatch(loadBillabeServices()), dispatch(loadConfiguration())]);
};

export const loadQualityLabelsAndConfiguration = (): ThunkAction => async dispatch => {
    await Promise.all([dispatch(loadQualityLabels()), dispatch(loadConfiguration())]);
};
