import {
    Pagination,
    Sorting,
    WebshopPromotionsPage,
    WebshopPromotionDetails,
    CreateWebshopPromotionPayload,
    UpdateWebshopPromotionPayload,
    UpdateWebshopPromotionPrioritiesPayload,
} from '@/Domain';

import Endpoint from './Endpoint';

export default class WebshopPromotionEndpoint extends Endpoint {
    public async GetWebshopPromotions(
        pagination: Pagination,
        sorting: Sorting,
        webshopId: string,
        filters?: {
            promotionType?: string;
        },
    ): Promise<WebshopPromotionsPage> {
        const url = `/webshop/${webshopId}/promotion/overview`;

        const response = await this.client.get({
            url,
            params: {
                pagination,
                sorting,
                filters,
            },
        });

        this.guardResponseSchema('#/definitions/WebshopPromotionsPage', response);

        return response;
    }

    public async GetWebshopPromotionDetails(webshopId: string, promotionId: string): Promise<WebshopPromotionDetails> {
        const response = await this.client.get({
            url: `/webshop/${webshopId}/promotion/${promotionId}/details`,
        });

        this.guardResponseSchema('#/definitions/WebshopPromotionDetails', response);

        return response;
    }

    public async CreateWebshopPromotion(payload: CreateWebshopPromotionPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/CreateWebshopPromotion',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Webshop\\Promotion\\UpdatePromotion\\UpdatePromotion',
                payload,
            },
        });
    }

    public async UpdateWebshopPromotion(payload: UpdateWebshopPromotionPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/UpdateWebshopPromotion',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Webshop\\Promotion\\UpdatePromotion\\UpdatePromotion',
                payload,
            },
        });
    }

    public async RemoveWebshopPromotion(promotionId: WebshopPromotionDetails['promotionId']): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/RemoveWebshopPromotion',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Webshop\\Promotion\\RemovePromotion\\RemovePromotion',
                payload: {
                    promotionId,
                },
            },
        });
    }

    public async UpdateWebshopPromotionPriorities(payload: UpdateWebshopPromotionPrioritiesPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/UpdateWebshopPromotionPriorities',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Webshop\\Promotion\\UpdatePromotionPriorities\\UpdatePromotionPriorities',
                payload,
            },
        });
    }
}
