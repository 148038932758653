import { EditorState, ContentBlock, SelectionState } from 'draft-js';

import { getSelectedBlocksList } from './block';

/**
 * Function returns size at a offset.
 */
function getStyleAtOffset(block: ContentBlock, stylePrefix: string, offset: number) {
    const styles = block.getInlineStyleAt(offset).toList();
    const style = styles.filter(s => !!(s && s.startsWith(stylePrefix)));
    if (style && style.size > 0) {
        return style.get(0);
    }
    return undefined;
}

/**
 * Function returns an object of custom inline styles currently applicable.
 */
export function getSelectionCustomInlineStyle(editorState: EditorState, stylePrefix: string, currentSelection?: SelectionState) {
    if (!currentSelection) {
        currentSelection = editorState.getSelection();
    }
    const inlineStyles: string[] = [];
    const start = currentSelection.getStartOffset();
    const end = currentSelection.getEndOffset();
    const selectedBlocks = getSelectedBlocksList(editorState, currentSelection);
    if (selectedBlocks.size > 0) {
        for (let i = 0; i < selectedBlocks.size; i += 1) {
            let blockStart = i === 0 ? start : 0;
            let blockEnd = i === selectedBlocks.size - 1 ? end : selectedBlocks.get(i).getText().length;
            if (blockStart === blockEnd && blockStart === 0) {
                blockStart = 1;
                blockEnd = 2;
            } else if (blockStart === blockEnd) {
                blockStart -= 1;
            }
            for (let j = blockStart; j < blockEnd; j += 1) {
                const selectionStyle = getStyleAtOffset(selectedBlocks.get(i), stylePrefix, j);
                if (selectionStyle && inlineStyles.indexOf(selectionStyle) === -1) {
                    inlineStyles.push(selectionStyle);
                }
            }
        }
    }
    return inlineStyles;
}

export function getBlockInlineStyleIfSameForEntireBlock(editorState: EditorState, stylePrefix: string, currentSelection?: SelectionState) {
    if (!currentSelection) {
        currentSelection = editorState.getSelection();
    }
    const inlineStyles: string[] = [];
    const start = currentSelection.getStartOffset();
    const end = currentSelection.getEndOffset();
    const selectedBlocks = getSelectedBlocksList(editorState, currentSelection);
    if (selectedBlocks.size > 0) {
        for (let i = 0; i < selectedBlocks.size; i += 1) {
            let blockStart = i === 0 ? start : 0;
            let blockEnd = i === selectedBlocks.size - 1 ? end : selectedBlocks.get(i).getText().length;
            if (blockStart === blockEnd && blockStart === 0) {
                blockStart = 1;
                blockEnd = 2;
            } else if (blockStart === blockEnd) {
                blockStart -= 1;
            }

            let styleForBlock: string | undefined;
            let sameStyle = true;
            for (let j = blockStart; j < blockEnd; j += 1) {
                const selectionStyle = getStyleAtOffset(selectedBlocks.get(i), stylePrefix, j);
                if (j > blockStart) {
                    if (styleForBlock !== selectionStyle) {
                        sameStyle = false;
                        break;
                    }
                }

                styleForBlock = selectionStyle;
            }

            if (sameStyle && styleForBlock && inlineStyles.indexOf(styleForBlock) === -1) {
                inlineStyles.push(styleForBlock);
            }
        }
    }
    return inlineStyles;
}
