import {
    Pagination,
    Sorting,
    Ownership,
    MediaItem,
    MediaItemType,
    MediaItemsPage,
    CreateMediaItemPayload,
    UpdateMediaItemPayload,
    ShareMediaItemPayload,
    BulkShareMediaItemsPayload,
    CloneMediaItemPayload,
    MediaItemUploadResponse,
    UpdateMediaItemStorageLocationPayload,
} from '@/Domain';

import Endpoint from './Endpoint';

export default class MediaItemEndpoint extends Endpoint {
    public async GetMediaItems(
        ownership: Ownership,
        pagination: Pagination,
        sorting: Sorting,
        type?: MediaItemType,
        search?: string,
        filters?: {
            companyId?: string | null;
            branchId?: string | null;
            includeShared?: string;
            ownedByCompany?: string;
        },
    ): Promise<MediaItemsPage> {
        let url = '/media-item/overview';
        if (ownership.type !== 'all') {
            url = `/media-item/${ownership.type}/${ownership.ownerId}/overview`;
        }

        const mediaItems = await this.client.get({
            url,
            params: {
                pagination,
                sorting,
                type,
                search,
                filters,
            },
        });

        this.guardResponseSchema('#/definitions/MediaItemsPage', mediaItems);

        return mediaItems;
    }

    public async GetMediaItemDetails(mediaItemId: string): Promise<MediaItem> {
        const mediaItem = await this.client.get({
            url: `/media-item/${mediaItemId}/details`,
        });

        this.guardResponseSchema('#/definitions/MediaItem', mediaItem);

        return mediaItem;
    }

    public async CreateMediaItem(payload: CreateMediaItemPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/CreateMediaItem',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Media\\Item\\CreateMediaItem\\CreateMediaItem',
                payload,
            },
        });
    }

    public async UpdateMediaItem(payload: UpdateMediaItemPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/UpdateMediaItem',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Media\\Item\\UpdateMediaItem\\UpdateMediaItem',
                payload,
            },
        });
    }

    public async CloneMediaItem(payload: CloneMediaItemPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/CloneMediaItem',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Media\\Item\\CloneMediaItem\\CloneMediaItem',
                payload,
            },
        });
    }

    public async ShareMediaItem(payload: ShareMediaItemPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/ShareMediaItem',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Media\\Item\\ShareMediaItem\\ShareMediaItem',
                payload,
            },
        });
    }

    public async BulkShareMediaItems(payload: BulkShareMediaItemsPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/ShareMediaItems',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Media\\Item\\ShareMediaItems\\ShareMediaItems',
                payload,
            },
        });
    }

    public async RemoveMediaItem(mediaItemId: string): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/DeleteMediaItem',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Media\\Item\\DeleteMediaItem\\DeleteMediaItem',
                payload: {
                    mediaItemId,
                },
            },
        });
    }

    public async Upload(mediaItemId: MediaItem['mediaItemId'], file: File, uploadAsPdf: boolean): Promise<MediaItemUploadResponse> {
        const response = await this.client.post({
            url: `/media-item/${mediaItemId}/upload`,
            body: {
                file,
                uploadAsPdf,
            },
            bodyIsFormData: true,
        });

        this.guardResponseSchema('#/definitions/MediaItemUploadResponse', response);

        return response;
    }

    public ProxyURL = (url: string): string => {
        return this.client.buildApiURL(`/media-proxy/${encodeURIComponent(url)}`, true);
    };

    public async UpdateMediaItemStorageLocation(payload: UpdateMediaItemStorageLocationPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/UpdateMediaItemStorageLocation',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Media\\Item\\UpdateMediaItemStorageLocation\\UpdateMediaItemStorageLocation',
                payload,
            },
        });
    }
}
