import { Domain } from 'api';

import { ThunkAction } from '@/action';
import { availableProductApi } from '@/api';
import * as categoriesTreeState from '@/AvailableProduct/categoriesTreeState';
import { loadConfiguredIntegrationsCountByType } from '@/Integration/configuredIntegrationsTypeState';
import { makeActions, makeReducer, makeReducerActions, makeSelectors } from '@/makeOverviewState';
import { URLParams, URLQuery } from '@/routing';

export type AvailableProductProps = keyof Domain.AvailableProduct | 'name' | 'brandIds' | 'productImageShotType';

export const defaultSorting: Domain.Sorting<AvailableProductProps> = {
    field: 'localizedNames',
    direction: 'ascending',
};

export const pageSize = 20;

export const selectors = makeSelectors<Domain.AvailableProduct, AvailableProductProps>({
    getState: rootState => rootState.availableProduct.overview,
});

export const reducerActions = makeReducerActions<Domain.AvailableProduct, AvailableProductProps>({
    reducerPrefix: '@availableProduct/overview',
});

const baseActions = makeActions<Domain.AvailableProduct, AvailableProductProps>({
    dataTableSaveKey: 'availableProductsOverview-v5',
    loadApi: options =>
        availableProductApi.GetAvailableProducts(options.pagination, options.sorting, options.locale, options.search, options.filters),
    defaultSorting,
    pageSize,
    getBaseUrl: () => '/products/available-products',
    reducerActions,
    selectors,
});

export const actions = {
    ...baseActions,
    load:
        (options: { urlParams: URLParams; urlQuery: URLQuery }): ThunkAction =>
        async dispatch => {
            await Promise.all([
                dispatch(baseActions.load(options)),
                dispatch(categoriesTreeState.actions.load(options)),
                dispatch(loadConfiguredIntegrationsCountByType()),
            ]);
        },
};

export const overviewReducer = makeReducer<Domain.AvailableProduct, AvailableProductProps>({
    defaultSorting,
    pageSize,
    reducerActions,
});
