import { Domain } from 'api';

import { resellerApi } from '@/api';
import * as makeDetailsState from '@/makeDetailsState';

export const selectors = makeDetailsState.makeSelectors<Domain.Reseller>({
    getState: rootState => rootState.reseller.update,
});

export const reducerActions = makeDetailsState.makeReducerActions<Domain.Reseller>({
    reducerPrefix: '@reseller/update',
});

export const actions = makeDetailsState.makeActions<Domain.Reseller>({
    loadApi: options => resellerApi.GetResellerDetails(options.urlParams.resellerId),
    reducerActions,
    selectors,
});

export const updateReducer = makeDetailsState.makeReducer<Domain.Reseller>({
    reducerActions,
});
