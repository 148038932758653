import { Domain } from 'api';

import { invoiceApi } from '@/api';
import { makeActions, makeReducer, makeReducerActions, makeSelectors } from '@/makeOverviewState';

export type InvoiceProps = keyof Domain.Invoice;

export const defaultSorting: Domain.Sorting<InvoiceProps> = {
    field: 'createdOn',
    direction: 'ascending',
};

export const pageSize = 20;

export const selectors = makeSelectors<Domain.Invoice, InvoiceProps>({
    getState: rootState => rootState.invoice.overview,
});

export const reducerActions = makeReducerActions<Domain.Invoice, InvoiceProps>({
    reducerPrefix: '@invoice/overview',
});

export const actions = makeActions<Domain.Invoice, InvoiceProps>({
    dataTableSaveKey: 'invoicesOverview-v5',
    loadApi: options => invoiceApi.GetInvoices(options.ownership, options.pagination, options.sorting, options.search, options.filters),
    defaultSorting,
    pageSize,
    getBaseUrl: () => '/invoices',
    reducerActions,
    selectors,
});

export const overviewReducer = makeReducer<Domain.Invoice, InvoiceProps>({
    defaultSorting,
    pageSize,
    reducerActions,
});
