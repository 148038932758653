import { BaseOrder, BaseOrderDetails, OrderStatus } from './BaseOrder';
import { Country, LocalizedValue } from './Localisation';
import { OrderShipmentAddress } from './OrderShipment';
import { Pagination } from './Pagination';
import { Sorting } from './Sorting';
import { WebshopOrderShipmentDetails } from './WebshopOrderShipment';
import { WebshopShippingMethodType, WebshopPickupShippingMethodLocationWithAddress } from './WebshopShippingMethod';

export type WebshopOrderShippingMethod = WebshopShippingMethodType | null;

export const WebshopOrderPickupFrom = ['LockerOrVendingMachine', 'pharmacy'] as const;
export type WebshopOrderPickupFrom = (typeof WebshopOrderPickupFrom)[number];

export type WebshopOrder = BaseOrder & {
    customerName: string;
    customerFirstName?: string | null;
    customerLastName?: string | null;
    shippingMethod: WebshopOrderShippingMethod;
    shippingName: string;
    paymentMethod: string;
    shipmentCanBeStarted?: boolean | null;
    prescriptionBarcodes?: string[] | null;
    isUnderReview?: boolean | null;
    flaggedForReviewOn?: string | null;

    preferPickupFrom: WebshopOrderPickupFrom | null;
};

export type WebshopOrderEmbeddedShipment = WebshopOrderShipmentDetails &
    OrderShipmentAddress & {
        branchId: string;
        companyId: string;
    };

export type WebshopOrderDetails = BaseOrderDetails &
    WebshopOrder & {
        customerEmailAddress: string;
        customerAddress: string;
        customerZipCode: string;
        customerCity: string;
        customerCountry: Country;
        shippingAddress: string;
        shippingZipCode: string;
        shippingCity: string;
        shippingCountry: Country;

        shipments: WebshopOrderEmbeddedShipment[];
        backOrderReferenceId?: string | null;
        backOrderConfiguredIntegrationId?: string | null;
        pickupLocation?: WebshopPickupShippingMethodLocationWithAddress | null;
        reviewedOn?: string | null;
        reviewedByUserId?: string | null;
        reviewFeedback?: string | null;
    };

export interface WebshopOrdersPage {
    items: WebshopOrder[];
    total: number;
    pagination: Pagination;
    sorting?: Sorting<keyof WebshopOrder> | null;
    search?: string | null;
}

export interface UpdateWebshopOrderStatusPayload {
    orderId: WebshopOrder['orderId'];
    orderStatus: OrderStatus;
    orderComment: LocalizedValue;
}

export interface AcceptOrderInReviewPayload {
    orderId: WebshopOrder['orderId'];
}

export interface RejectOrderInReviewPayload {
    orderId: WebshopOrder['orderId'];
    feedback: string;
}

export type OrderReviewReasons = string[];
