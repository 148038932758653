import { Domain } from 'api';

import { apiKeyApi } from '@/api';
import { makeActions, makeReducer, makeReducerActions, makeSelectors } from '@/makeOverviewState';

export type ApiKeyProps = keyof Domain.ApiKey;

export const defaultSorting: Domain.Sorting<ApiKeyProps> = {
    field: 'created',
    direction: 'descending',
};

export const pageSize = 20;

export const selectors = makeSelectors<Domain.ApiKey, ApiKeyProps>({
    getState: rootState => rootState.apiKey.overview,
});

export const reducerActions = makeReducerActions<Domain.ApiKey, ApiKeyProps>({
    reducerPrefix: '@apiKey/overview',
});

export const actions = makeActions<Domain.ApiKey, ApiKeyProps>({
    dataTableSaveKey: 'apiKeysOverview-v5',
    loadApi: options => apiKeyApi.GetApiKeys(options.pagination, options.sorting, options.filters),
    defaultSorting,
    pageSize,
    getBaseUrl: () => '/customers/api-keys',
    reducerActions,
    selectors,
});

export const overviewReducer = makeReducer<Domain.ApiKey, ApiKeyProps>({
    defaultSorting,
    pageSize,
    reducerActions,
});
