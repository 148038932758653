import { BeAlert } from '@/Domain';

import Endpoint from './Endpoint';

export default class EmergencyAlertEndpoint extends Endpoint {
    public GetEmergencyAlerts = async ({ branchId, country }: { branchId: string; country: string }): Promise<BeAlert[] | null> => {
        if (country === 'BE') {
            try {
                const response = await this.client.get({
                    url: `/branch/${branchId}/beAlert`,
                });

                this.guardResponseSchema('#/definitions/BeAlertResponse', response);
                return response;
            } catch (e) {
                console.error(e);
            }
        }
        return null;
    };
}
