import { createAction, Selector, createReducer } from '@reduxjs/toolkit';

import { Domain } from 'api';

import { ThunkAction, withPayloadType } from '@/action';
import { branchApi } from '@/api';
import { RootState } from '@/store';
import { selectGlobalSelectedWebshop } from '@/Webshop/globalSelectionState';

export interface State {
    selectedBranchDetails?: Domain.BranchDetails;
}

export const initialState: State = {
    selectedBranchDetails: undefined,
};

const reducerActions = {
    setBrachDetails: createAction('@appointmentConfiguration/setBranchDetails', withPayloadType<Domain.BranchDetails>()),
};

export const configurationReducer = createReducer(initialState, builder =>
    builder.addCase(reducerActions.setBrachDetails, (state, action) => {
        state.selectedBranchDetails = action.payload;
    }),
);

export const selectedBranchDetails: Selector<RootState, Domain.BranchDetails> = state => {
    const selectedPublicAnnouncements = state.appointmentsReducer.configuration.selectedBranchDetails;

    if (!selectedPublicAnnouncements) {
        throw new Error('Public announcements data not loaded');
    }

    return selectedPublicAnnouncements;
};

export const loadCurrentBranchDetails = (): ThunkAction => async (dispatch, getState) => {
    const state = getState();
    const webshop = selectGlobalSelectedWebshop(state);

    if (!webshop) {
        return null;
    }
    const branchDetails = await branchApi.GetBranchDetails(webshop.branchId);

    await dispatch(reducerActions.setBrachDetails(branchDetails));
};

export const load = (): ThunkAction => async dispatch => {
    await Promise.all([dispatch(loadCurrentBranchDetails())]);
};
