import * as React from 'react';

import ControlledCheckbox, { ControlledCheckboxProps } from './ControlledCheckbox';

export type UncontrolledCheckboxProps = ControlledCheckboxProps & {
    defaultChecked?: boolean;
};

interface IState {
    checked: boolean;
}

class UncontrolledCheckbox extends React.PureComponent<UncontrolledCheckboxProps, IState> {
    constructor(props: UncontrolledCheckboxProps) {
        super(props);

        this.state = {
            checked: props.defaultChecked || false,
        };
    }

    render() {
        const { children, defaultChecked, onChange, forwardRef, ...rest } = this.props;

        return (
            <ControlledCheckbox
                {...rest}
                ref={forwardRef}
                checked={this.state.checked}
                onChange={this.handleChange}
            >
                {children}
            </ControlledCheckbox>
        );
    }

    protected handleChange = (): void => {
        const { onChange } = this.props;
        const { checked } = this.state;

        this.setState(
            {
                checked: !checked,
            },
            () => {
                if (onChange) {
                    onChange(this.state.checked);
                }
            },
        );
    };
}

export default React.forwardRef<HTMLInputElement, UncontrolledCheckboxProps>((props, ref) => {
    return (
        <UncontrolledCheckbox
            forwardRef={ref}
            {...props}
        />
    );
});
