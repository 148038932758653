import { combineReducers } from '@reduxjs/toolkit';

import { deviceReducer } from './deviceState';
import { interactionReducer } from './interactionState';
import { livePreviewReducer } from './livePreviewState';
import { slideshowReducer } from './slideshowState';
import { vendingMachineReducer } from './vendingMachineState';

export const tvScreenReducer = combineReducers({
    device: deviceReducer,
    slideshow: slideshowReducer,
    interaction: interactionReducer,
    livePreview: livePreviewReducer,
    vendingMachine: vendingMachineReducer,
});
