import { combineReducers } from '@reduxjs/toolkit';

import { cartPromotionReducer } from './cartPromotionOverviewState';
import { managePrioritiesReducer } from './managePrioritiesState';
import { productSelectionPromotionReducer } from './productSelectionPromotionOverviewState';
import { updateReducer } from './updateState';

export const webshopPromotionReducer = combineReducers({
    cartPromotionOverview: cartPromotionReducer,
    productSelectionPromotionOverview: productSelectionPromotionReducer,
    update: updateReducer,
    managePriorities: managePrioritiesReducer,
});
