import { browserStorage } from 'utils';

const DEVICE_LAST_AUTOMATIC_REFRESH_STORAGE_KEY = 'deviceLastAutomaticRefresh';

export function setDeviceLastAutomaticRefresh(lastRefresh: number): void {
    browserStorage.setItem(DEVICE_LAST_AUTOMATIC_REFRESH_STORAGE_KEY, lastRefresh.toString(), false);
}

export function getDeviceLastAutomaticRefresh(): number | undefined {
    const item = browserStorage.getItem(DEVICE_LAST_AUTOMATIC_REFRESH_STORAGE_KEY);
    if (item && item.value) {
        return parseInt(item.value);
    }
}
