import { Domain } from 'api';

import { getCountryCodeFromLocale } from './getCountryCodeFromLocale';
import { COUNTRY_CONFIG } from './Localisation/availableCountriesState';

const getLocalizedImages = (images: Domain.ProductImage[], locale: Domain.Locale, noFallback?: boolean): Domain.ProductImage[] => {
    let localizedImages: Domain.ProductImage[] = [];

    const getImagesForLocale = (searchedLocale: Domain.Locale) => {
        const searchedImages: Domain.ProductImage[] = [];
        let anyDesiredLocaleImages = false;
        for (const image of images) {
            if (image.locales.indexOf(searchedLocale) > -1) {
                anyDesiredLocaleImages = true;
                break;
            }
        }

        if (anyDesiredLocaleImages) {
            for (const image of images) {
                if (image.locales.indexOf(searchedLocale) > -1) {
                    searchedImages.push(image);
                }
            }
        }

        return searchedImages;
    };

    if (images.length > 0) {
        const foundImages = getImagesForLocale(locale);
        if (foundImages.length > 0) {
            localizedImages = foundImages;
        } else if (!noFallback) {
            const country = getCountryCodeFromLocale(locale);
            for (const fallbackLocale of COUNTRY_CONFIG[country].locales) {
                const foundImages = getImagesForLocale(fallbackLocale);
                if (foundImages.length > 0) {
                    localizedImages = foundImages;
                    break;
                }
            }
        }
    }

    return localizedImages.sort((a, b) => {
        if (a.type === 'productshot' && b.type === 'packshot') {
            return 1;
        }

        return 0;
    });
};

export default getLocalizedImages;
