import { Domain } from 'api';

import { exportApi } from '@/api';
import { makeActions, makeReducer, makeReducerActions, makeSelectors } from '@/makeOverviewState';

type StockReplenishExportProps = keyof Domain.Export;

const defaultSorting: Domain.Sorting<StockReplenishExportProps> = {
    field: 'initializedOn',
    direction: 'descending',
};

const pageSize = 20;

export const selectors = makeSelectors<Domain.Export, StockReplenishExportProps>({
    getState: rootState => rootState.stockReplenishExportReducer.overview,
});

export const reducerActions = makeReducerActions<Domain.Export, StockReplenishExportProps>({
    reducerPrefix: '@stockReplenishExport/overview',
});

export const actions = makeActions<Domain.Export, StockReplenishExportProps>({
    dataTableSaveKey: 'stockReplenishExportOverview-v5',
    loadApi: options => exportApi.GetExports(options.pagination, options.sorting, { exportType: 'robotStockExport' }),
    defaultSorting,
    pageSize,
    getBaseUrl: () => '/vending-machines/stock-replenish/stock-export',
    reducerActions,
    selectors,
});

export const overviewReducer = makeReducer<Domain.Export, StockReplenishExportProps>({
    defaultSorting,
    pageSize,
    reducerActions,
});
