import * as React from 'react';

import Button from './Button';

import styles from './Spinner.scss';

export default function Spinner(props: { value: number; onChange: (newValue: number) => void; min?: number; max?: number }) {
    const handleChange = (changeAmount: number) => () => {
        let newValue = props.value + changeAmount;

        if (props.min !== undefined && newValue < props.min) {
            newValue = +props.min;
        }

        if (props.max !== undefined && newValue > props.max) {
            newValue = +props.max;
        }

        props.onChange(newValue);
    };

    return (
        <div className={styles.VisionSpinner}>
            <Button onClick={handleChange(-1)}>
                <img
                    src="/static/assets/vision-icon-minus.png"
                    alt=""
                />
            </Button>
            <span>{props.value}</span>
            <Button onClick={handleChange(+1)}>
                <img
                    src="/static/assets/vision-icon-plus.png"
                    alt=""
                />
            </Button>
        </div>
    );
}
