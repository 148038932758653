import { uaData } from './uaData';

/* remove a=extmap-allow-mixed for chromium < v71 */
export function webRTCShim() {
    if (!window.RTCPeerConnection) {
        return;
    }

    const browserDetails = uaData();

    if (parseInt(browserDetails.chromiumVersion, 10) >= 71) {
        return;
    }

    const nativeSRD = window.RTCPeerConnection.prototype.setRemoteDescription;
    window.RTCPeerConnection.prototype.setRemoteDescription = function setRemoteDescription(desc) {
        if (desc && desc.sdp && desc.sdp.indexOf('\na=extmap-allow-mixed') !== -1) {
            const sdp = desc.sdp
                .split('\n')
                .filter(line => {
                    return line.trim() !== 'a=extmap-allow-mixed';
                })
                .join('\n');
            // Safari enforces read-only-ness of RTCSessionDescription fields.
            if (window.RTCSessionDescription && desc instanceof window.RTCSessionDescription) {
                // eslint-disable-next-line prefer-rest-params
                arguments[0] = new window.RTCSessionDescription({
                    type: desc.type,
                    sdp,
                });
            } else {
                desc.sdp = sdp;
            }
        }
        // eslint-disable-next-line prefer-rest-params
        return nativeSRD.apply(this, arguments);
    };
}
