import * as React from 'react';

import ControlledRTEditor, { ControlledRTEditorProps } from './ControlledRTEditor';
import RTValue from './RTValue';

export type UncontrolledRTEditorProps = ControlledRTEditorProps & {
    defaultValue?: RTValue;
};

interface IState {
    value: RTValue;
}

class UncontrolledRTEditor extends React.PureComponent<UncontrolledRTEditorProps, IState> {
    constructor(props: UncontrolledRTEditorProps) {
        super(props);

        this.state = {
            value: props.defaultValue || new RTValue(''),
        };
    }

    render() {
        const { children, defaultValue, onChange, onChangeStart, forwardRef, ...rest } = this.props;

        return (
            <ControlledRTEditor
                {...rest}
                ref={forwardRef}
                value={this.state.value}
                onChangeStart={onChangeStart}
                onChange={this.handleChange}
            >
                {children}
            </ControlledRTEditor>
        );
    }

    protected handleChange = (newValue: RTValue): void => {
        const { onChange } = this.props;

        this.setState(
            {
                value: newValue,
            },
            () => {
                if (onChange) {
                    onChange(this.state.value);
                }
            },
        );
    };
}

export default React.forwardRef<HTMLInputElement, UncontrolledRTEditorProps>((props, ref) => {
    return (
        <UncontrolledRTEditor
            forwardRef={ref}
            {...props}
        />
    );
});
