import { CustomerEmailAddressList } from '@/Domain';

import Endpoint from './Endpoint';

export default class CustomerEmailAddressEndpoint extends Endpoint {
    public async GetEmailAddresses(branchId: string, search: string): Promise<CustomerEmailAddressList> {
        const response = await this.client.get({
            url: `/customer/email/overview/${branchId}/${search}`,
        });

        this.guardResponseSchema('#/definitions/CustomerEmailAddressList', response);

        return response;
    }
}
