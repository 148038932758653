export type CartRestrictionType = 'product' | 'category' | 'brand';

export interface CartRestriction {
    referenceId: string;
    limit: number;
}

export function getCartRestrictionType(referenceId: string): CartRestrictionType {
    if (referenceId.startsWith('product-')) {
        return 'product';
    } else if (referenceId.startsWith('category-')) {
        return 'category';
    } else if (referenceId.startsWith('brand-')) {
        return 'brand';
    }

    throw new Error('Unexpected cart restriction referenceId: ' + referenceId);
}
