import * as React from 'react';

import classNames from 'classnames';

import { Icon, TouchAndMouseDragHandler, TouchAndMouseMove } from 'ui-components';

import { store, Actions } from '../state';
import { Shelf } from '../types';
import { computeShelfSnapPoints } from '../utils';

import styles from './styles/ShelfMover.scss';

interface IProps {
    className?: string;
    style?: React.CSSProperties;
    children?: React.ReactNode;

    y: number;
    minY: number;
    maxY: number;
    shelf?: Shelf;
    belowShelf?: Shelf;
    otherActions?: React.ReactNode;
    hideMoverHandle?: boolean;
}

export default class ShelfMover extends React.PureComponent<IProps> {
    static contextType = store;
    declare context: React.ContextType<typeof store>;
    private handle = React.createRef<HTMLAnchorElement>();
    private readonly dragHandler: TouchAndMouseDragHandler;

    constructor(props: IProps) {
        super(props);

        this.dragHandler = new TouchAndMouseDragHandler(
            this.handleMove,
            () => {
                this.context.dispatch({
                    type: Actions.SET_DRAGGING_SHELF,
                    draggingShelf: true,
                });
            },
            () => {
                this.context.dispatch({
                    type: Actions.SET_DRAGGING_SHELF,
                    draggingShelf: false,
                });
                this.context.dispatch({
                    type: Actions.CanvasActions.HISTORY_SAVE,
                });
            },
        );

        this.dragHandler.setSnapDistance(3);
    }

    componentDidMount(): void {
        if (!this.handle.current) {
            return;
        }

        this.handle.current.addEventListener('touchstart', this.dragHandler.handleTouchStart, { passive: false });
    }

    componentWillUnmount(): void {
        if (!this.handle.current) {
            return;
        }

        this.handle.current.removeEventListener('touchstart', this.dragHandler.handleTouchStart);
    }

    render() {
        const { className, style, children, otherActions, hideMoverHandle } = this.props;

        return (
            <div
                className={classNames(styles.ShelfMover, className)}
                style={style}
            >
                {children}

                {!hideMoverHandle ? (
                    <a
                        ref={this.handle}
                        href=""
                        className={styles.ShelfMoverHandle}
                        onMouseDown={this.dragHandler.handleMouseDown}
                        onClick={event => event.preventDefault()}
                    >
                        <Icon type="action_drag" />
                    </a>
                ) : null}

                {otherActions ? <span className={styles.ShelfMoverOtherActions}>{otherActions}</span> : null}
            </div>
        );
    }

    private handleMove = (move: TouchAndMouseMove) => {
        const { state, dispatch } = this.context;
        const { y, minY, maxY, shelf, belowShelf } = this.props;

        this.dragHandler.setSnapPoints(computeShelfSnapPoints(state.canvas.shelves, state.canvas.height));
        this.dragHandler.snapMove(move, [{ point: y, vertical: true }]);

        let offset = move.movementY;

        if ((offset < 0 && y + offset < minY) || (offset > 0 && y + offset > maxY)) {
            offset = 0;
        }

        if (!offset) {
            return;
        }

        if (shelf !== undefined) {
            dispatch({
                type: Actions.SET_SHELF_Y_OFFSET,
                shelfId: shelf.id,
                offset,
                position: 'self',
            });
        }

        if (belowShelf !== undefined) {
            dispatch({
                type: Actions.SET_SHELF_Y_OFFSET,
                shelfId: belowShelf.id,
                offset,
                position: 'below',
            });
        }
    };
}
