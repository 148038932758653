import { ContextValue } from '@datadog/browser-core';
import { datadogLogs } from '@datadog/browser-logs';

import { config, tvAppVersion } from 'config';

if (config.logging.enabled) {
    datadogLogs.init({
        clientToken: config.logging.dataDog.clientToken,
        site: 'datadoghq.eu',
        forwardErrorsToLogs: true,
        forwardConsoleLogs: ['error'],
        sampleRate: 100,
        service: 'lochting_ui',
        env: config.logging.dataDog.env,
        version: tvAppVersion,
    });
}

export function log(status: 'info' | 'warn' | 'error', type: string, messageContext?: object): void {
    // console[status](type, messageContext);

    if (!config.logging.enabled) {
        return;
    }

    datadogLogs.logger.log(
        type,
        {
            type,
            ...messageContext,
        },
        status,
    );
}

export function setContextProperty(key: string, value: ContextValue): void {
    datadogLogs.setGlobalContextProperty(key, value);
}

export function clearContextProperty(key: string): void {
    datadogLogs.removeGlobalContextProperty(key);
}

export function getContextProperty(key: string): ContextValue {
    return datadogLogs.getGlobalContext()[key];
}

export function setApplication(name: 'device' | 'platform'): void {
    datadogLogs.setGlobalContextProperty('application', name);
}

type LogFn = (payload: { subjects: string[]; companyId: string; branchId: string; log: object; createdOnInMilliseconds?: number }) => void;

let logActivityFn: LogFn | undefined;

export function setLogActivityFn(fn: LogFn | undefined): void {
    logActivityFn = fn;
}

export function getLogActivityFn(): LogFn | undefined {
    return logActivityFn;
}
