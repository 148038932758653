import {
    Pagination,
    Sorting,
    NotificationsPage,
    NotificationsCount,
    MarkNotificationAsReadPayload,
    MarkAllNotificationsAsReadPayload,
    User,
    Branch,
    Company,
} from '@/Domain';

import Endpoint from './Endpoint';

export default class NotificationEndpoint extends Endpoint {
    public async GetUserNotificationsCount(): Promise<NotificationsCount> {
        const response = await this.client.get({
            url: '/notification/counts',
        });

        this.guardResponseSchema('#/definitions/NotificationsCount', response);

        return response;
    }

    public async GetUserUnreadNotifications(pagination: Pagination, sorting: Sorting): Promise<NotificationsPage> {
        const response = await this.client.get({
            url: '/notification/user/overview',
            params: {
                pagination,
                sorting,
                filters: {
                    includeRead: false,
                },
            },
        });

        this.guardResponseSchema('#/definitions/NotificationsPage', response);

        return response;
    }

    public async GetUserNotifications(
        pagination: Pagination,
        sorting: Sorting,
        filters?: {
            notificationCategory?: string;
        },
    ): Promise<NotificationsPage> {
        const response = await this.client.get({
            url: '/notification/user/overview',
            params: {
                pagination,
                sorting,
                filters,
            },
        });

        this.guardResponseSchema('#/definitions/NotificationsPage', response);

        return response;
    }

    public async GetNotifications(
        pagination: Pagination,
        sorting: Sorting,
        filters?: {
            includeRead?: string;
            userId?: User['userId'];
            branchId?: Branch['branchId'];
            companyId?: Company['companyId'];
            notificationCategory?: string;
        },
    ): Promise<NotificationsPage> {
        const response = await this.client.get({
            url: '/notification/platform/overview',
            params: {
                pagination,
                sorting,
                filters: {
                    ...filters,
                    includeRead: filters && filters.includeRead === 'no' ? false : undefined,
                },
            },
        });

        this.guardResponseSchema('#/definitions/NotificationsPage', response);

        return response;
    }

    public async MarkNotificationAsRead(payload: MarkNotificationAsReadPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/MarkNotificationAsRead',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Notification\\MarkNotificationAsRead\\MarkNotificationAsRead',
                payload,
            },
        });
    }

    public async MarkAllNotificationsAsRead(payload: MarkAllNotificationsAsReadPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/MarkAllNotificationsAsRead',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Notification\\MarkAllUserNotificationsAsRead\\MarkAllUserNotificationsAsRead',
                payload,
            },
        });
    }

    public async GetNotificationCategories(): Promise<string[]> {
        return await this.client.get({
            url: '/notification/categories',
        });
    }
}
