import * as React from 'react';

import { Domain } from 'api';
import { useThunkDispatch, TVScreen } from 'data-store';

import InteractiveScreenNavigation from './InteractiveScreenNavigation';
import SlideshowViewerSlide from './SlideshowViewerSlide';

import styles from './HiddenSlideViewer.scss';
import screenStyles from './InteractiveScreen.scss';

export default function HiddenSlideViewer(props: {
    slideId: string;
    getLanguageLabel: (locale: Domain.Locale) => React.ReactNode;
    renderVideo: (videoProps: React.VideoHTMLAttributes<any>) => React.ReactElement;
}) {
    const dispatch = useThunkDispatch();

    return (
        <div className={`${screenStyles.InteractiveScreen} ${styles.HiddenSlideViewerScreen}`}>
            <div className={`${styles.HiddenSlideViewer} SlideshowViewer`}>
                <div className={`${styles.HiddenSlideViewerSlidesWrap} SlideshowViewerSlidesWrap`}>
                    <SlideshowViewerSlide
                        slideId={props.slideId}
                        isHidden={true}
                        isActive={true}
                        isNext={false}
                        loopVideo={true}
                        renderVideo={props.renderVideo}
                        onProductClick={productDetails =>
                            dispatch(
                                TVScreen.interactionState.reducerActions.pushToHistory({
                                    type: 'productDetails',
                                    productDetails,
                                }),
                            )
                        }
                        onHiddenSlideClick={slideId =>
                            dispatch(
                                TVScreen.interactionState.reducerActions.pushToHistory({
                                    type: 'hiddenSlide',
                                    slideId,
                                }),
                            )
                        }
                    />
                </div>
            </div>

            <InteractiveScreenNavigation
                withBack={true}
                withClose={true}
                getLanguageLabel={props.getLanguageLabel}
            />
        </div>
    );
}
