import { Pagination, Sorting, AuditLogPage } from '@/Domain';

import Endpoint from './Endpoint';

export default class AuditLogEndpoint extends Endpoint {
    public async GetAuditLog(
        pagination: Pagination,
        sorting: Sorting,
        filters?: {
            companyId?: string;
            branchId?: string;
        },
    ): Promise<AuditLogPage> {
        const response = await this.client.get({
            url: '/gdpr-audit-log',
            params: {
                pagination,
                sorting,
                filters,
            },
        });

        this.guardResponseSchema('#/definitions/AuditLogPage', response);
        return response;
    }
}
