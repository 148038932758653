import { AvailableProductDetails } from './AvailableProduct';
import { CustomProductDetails } from './CustomProduct';

export type BulkProductDetails = AvailableProductDetails | CustomProductDetails;
export type BulkProductDetailsList = BulkProductDetails[];

export const ProductCompletenessReasons = [
    'emptyPriceIncludingVat',
    'emptyVatRate',
    'emptyName',
    'emptyLocale',
    'emptyCategoryIds',
    'emptyFrontalsAndPackshots',
    'inactiveAndNoLocalStock',
    'conservationNeedsCooling',
    'cannotBeSoldWithoutPharmacist',
    'cip13NotAllowed',
    'cefipCodeMissingForLuxemburg',
    'selectionTypeNotSupported',
    'noProductCodes',
] as const;
export type ProductCompletenessReason = (typeof ProductCompletenessReasons)[number];

export interface ProductNotSuitedForReasons {
    customPage: ProductCompletenessReason[];
    productWall: ProductCompletenessReason[];
    vendingMachine: ProductCompletenessReason[];
    webshop: ProductCompletenessReason[];
}
