import { combineReducers } from '@reduxjs/toolkit';

import { detailsReducer } from './detailsState';
import { overviewReducer } from './overviewState';
import { updateReducer } from './updateState';

export const customProductReducer = combineReducers({
    overview: overviewReducer,
    update: updateReducer,
    details: detailsReducer,
});
