import { createAction, Selector, createReducer } from '@reduxjs/toolkit';

import { Domain } from 'api';

import { ThunkAction, withPayloadType } from '@/action';
import { webshopApi, webshopFaqApi } from '@/api';
import { URLParams } from '@/routing';
import { RootState } from '@/store';

export interface State {
    webshop?: Domain.Webshop;
    faqItem?: Domain.WebshopFaqItemDetails;
    faqCategories?: Domain.WebshopFaqCategoriesPage;
}

const initialState: State = {};

const reducerActions = {
    setWebshop: createAction('@webshopFaqItem/update/setWebshop', withPayloadType<Domain.Webshop>()),
    setFaqItem: createAction('@webshopFaqItem/update/setFaqItem', withPayloadType<Domain.WebshopFaqItemDetails>()),
    setFaqCategories: createAction('@webshopFaqItem/update/setFaqCategories', withPayloadType<Domain.WebshopFaqCategoriesPage>()),
};

export const updateReducer = createReducer(initialState, builder =>
    builder
        .addCase(reducerActions.setWebshop, (state, action) => {
            state.webshop = action.payload;
        })
        .addCase(reducerActions.setFaqItem, (state, action) => {
            state.faqItem = action.payload;
        })
        .addCase(reducerActions.setFaqCategories, (state, action) => {
            state.faqCategories = action.payload;
        }),
);

export const selectWebshop: Selector<RootState, Domain.Webshop> = state => {
    const webshop = state.webshopFaqItem.update.webshop;
    if (!webshop) {
        throw new Error('Webshop not loaded');
    }

    return webshop;
};

export const selectFaqItem: Selector<RootState, Domain.WebshopFaqItemDetails> = state => {
    const faqItem = state.webshopFaqItem.update.faqItem;
    if (!faqItem) {
        throw new Error('FAQ item not loaded');
    }

    return faqItem;
};

export const selectFaqCategories: Selector<RootState, Domain.WebshopFaqCategoriesPage> = state => {
    const faqCategories = state.webshopFaqItem.update.faqCategories;
    if (!faqCategories) {
        throw new Error('FAQ categories not loaded');
    }

    return faqCategories;
};

export const loadWebshop =
    (webshopId: Domain.Webshop['webshopId']): ThunkAction<Promise<Domain.Webshop>> =>
    async dispatch => {
        const webshop = await webshopApi.GetWebshopDetails(webshopId);

        await dispatch(reducerActions.setWebshop(webshop));

        return webshop;
    };

export const loadFaqItem =
    (
        webshopId: Domain.Webshop['webshopId'],
        faqItemId: Domain.WebshopFaqItem['faqItemId'],
    ): ThunkAction<Promise<Domain.WebshopFaqItemDetails>> =>
    async dispatch => {
        const faqItem = await webshopFaqApi.GetWebshopFaqItemDetails(webshopId, faqItemId);

        await dispatch(reducerActions.setFaqItem(faqItem));

        return faqItem;
    };

export const loadFaqCategories =
    (webshopId: Domain.Webshop['webshopId']): ThunkAction<Promise<Domain.WebshopFaqCategoriesPage>> =>
    async dispatch => {
        const faqCategories = await webshopFaqApi.GetWebshopFaqCategories(
            { page: 1, size: 999 },
            { field: 'sortOrder', direction: 'ascending' },
            webshopId,
        );

        await dispatch(reducerActions.setFaqCategories(faqCategories));

        return faqCategories;
    };

export const load =
    (options: { urlParams: URLParams }): ThunkAction =>
    async dispatch => {
        await Promise.all([
            dispatch(loadWebshop(options.urlParams.webshopId)),
            dispatch(loadFaqCategories(options.urlParams.webshopId)),
            dispatch(loadFaqItem(options.urlParams.webshopId, options.urlParams.faqItemId)),
        ]);
    };
