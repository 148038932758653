import * as React from 'react';

import classNames from 'classnames';

import { BaseProps } from '@/core';

import styles from './ButtonGroup.scss';

type IProps = {
    separated?: boolean;
} & BaseProps;

export default class ButtonGroup extends React.PureComponent<IProps & React.HTMLProps<HTMLDivElement>> {
    render() {
        const { className, children, separated, ...rest } = this.props;

        return (
            <div
                {...rest}
                className={classNames(styles.ButtonGroup, separated ? styles.Separated : undefined, className)}
            >
                {children}
            </div>
        );
    }
}
