import { Domain } from 'api';

import { ThunkAction } from '@/action';
import { customProductApi } from '@/api';
import * as categoriesTreeState from '@/AvailableProduct/categoriesTreeState';
import { makeActions, makeReducer, makeReducerActions, makeSelectors } from '@/makeDetailsState';
import { URLParams } from '@/routing';

export const selectors = makeSelectors<Domain.CustomProductDetails>({
    getState: rootState => rootState.customProduct.update,
});

export const reducerActions = makeReducerActions<Domain.CustomProductDetails>({
    reducerPrefix: '@customProduct/update',
});

const baseActions = makeActions<Domain.CustomProductDetails>({
    loadApi: options => customProductApi.GetCustomProductDetails(options.urlParams.customProductId, options.locale),
    reducerActions,
    selectors,
});

export const actions = {
    ...baseActions,
    load:
        (options: { urlParams: URLParams }): ThunkAction =>
        async dispatch => {
            await Promise.all([dispatch(baseActions.load(options)), dispatch(categoriesTreeState.actions.load(options))]);
        },
};

export const updateReducer = makeReducer<Domain.CustomProductDetails>({
    reducerActions,
});
