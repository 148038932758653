import {
    Pagination,
    Sorting,
    Locale,
    WebshopFaqCategoriesPage,
    WebshopFaqCategory,
    WebshopFaqCategoryDetails,
    UpdateWebshopFaqCategoryPayload,
    CreateWebshopFaqCategoryPayload,
    WebshopFaqItemsPage,
    WebshopFaqItem,
    WebshopFaqItemDetails,
    CreateWebshopFaqItemPayload,
    UpdateWebshopFaqItemPayload,
    RemoveWebshopFaqItemPayload,
    RemoveWebshopFaqCategoryPayload,
} from '@/Domain';

import Endpoint from './Endpoint';

export default class WebshopFaqLibraryEndpoint extends Endpoint {
    public async GetWebshopFaqLibraryCategories(pagination: Pagination, sorting: Sorting): Promise<WebshopFaqCategoriesPage> {
        const url = '/library-faq-category/overview';

        const response = await this.client.get({
            url,
            params: {
                pagination,
                sorting,
            },
        });

        this.guardResponseSchema('#/definitions/WebshopFaqCategoriesPage', response);

        return response;
    }

    public async GetWebshopFaqLibraryCategoryDetails(
        faqCategoryId: WebshopFaqCategory['faqCategoryId'],
    ): Promise<WebshopFaqCategoryDetails> {
        const response = await this.client.get({
            url: `/library-faq-category/${faqCategoryId}/details`,
        });

        this.guardResponseSchema('#/definitions/WebshopFaqCategoryDetails', response);

        return response;
    }

    public async CreateWebshopFaqLibraryCategory(payload: CreateWebshopFaqCategoryPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/CreateWebshopFaqLibraryCategory',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Webshop\\Faq\\FaqCategory\\UpdateLibraryFaqCategory\\UpdateLibraryFaqCategory',
                payload,
            },
        });
    }

    public async UpdateWebshopFaqLibraryCategory(payload: UpdateWebshopFaqCategoryPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/UpdateWebshopFaqLibraryCategory',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Webshop\\Faq\\FaqCategory\\UpdateLibraryFaqCategory\\UpdateLibraryFaqCategory',
                payload,
            },
        });
    }

    public async GetWebshopFaqLibraryItems(pagination: Pagination, sorting: Sorting, locale: Locale): Promise<WebshopFaqItemsPage> {
        const url = `/library-faq-item/overview/${locale}`;

        const response = await this.client.get({
            url,
            params: {
                pagination,
                sorting,
            },
        });

        this.guardResponseSchema('#/definitions/WebshopFaqItemsPage', response);

        return response;
    }

    public async GetWebshopFaqLibraryItemDetails(faqItemId: WebshopFaqItem['faqItemId']): Promise<WebshopFaqItemDetails> {
        const response = await this.client.get({
            url: `/library-faq-item/${faqItemId}/details`,
        });

        this.guardResponseSchema('#/definitions/WebshopFaqItemDetails', response);

        return response;
    }

    public async CreateWebshopFaqLibraryItem(payload: CreateWebshopFaqItemPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/CreateWebshopFaqLibraryItem',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Webshop\\Faq\\FaqItem\\UpdateLibraryFaqItem\\UpdateLibraryFaqItem',
                payload,
            },
        });
    }

    public async UpdateWebshopFaqLibraryItem(payload: UpdateWebshopFaqItemPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/UpdateWebshopFaqLibraryItem',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Webshop\\Faq\\FaqItem\\UpdateLibraryFaqItem\\UpdateLibraryFaqItem',
                payload,
            },
        });
    }

    public async RemoveWebshopFaqLibraryItem(payload: RemoveWebshopFaqItemPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/RemoveWebshopFaqLibraryItem',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Webshop\\Faq\\FaqItem\\RemoveLibraryFaqItem\\RemoveLibraryFaqItem',
                payload,
            },
        });
    }

    public async RemoveWebshopFaqLibraryCategory(payload: RemoveWebshopFaqCategoryPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/RemoveWebshopFaqLibraryCategory',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Webshop\\Faq\\FaqCategory\\RemoveLibraryFaqCategory\\RemoveLibraryFaqCategory',
                payload,
            },
        });
    }
}
