import { Company } from './Company';
import { LocalizedValue } from './Localisation';
import { Pagination } from './Pagination';
import { Sorting } from './Sorting';

export interface Color {
    red: number;
    green: number;
    blue: number;
}

export interface WebshopColorSet {
    companyId?: Company['companyId'] | null;
    colorSet: {
        colorset_id: string;
        colorset_name: string;
        '--color-primary-darker': Color;
        '--color-primary': Color;
        '--color-primary-lighter': Color;
        '--color-secondary-darker': Color;
        '--color-secondary': Color;
        '--color-secondary-lighter': Color;
        '--color-contrast-primary': Color;
        '--color-contrast-primary-darker': Color;
        '--color-contrast-primary-lighter': Color;
        '--color-contrast-secondary': Color;
        '--color-contrast-secondary-darker': Color;
        '--color-contrast-secondary-lighter': Color;
        '--color-gray-100': Color;
        '--color-gray-200': Color;
        '--color-gray-300': Color;
        '--color-gray-400': Color;
        '--color-gray-500': Color;
        '--color-gray-600': Color;
        '--color-error-bg': Color;
        '--color-error-text': Color;
        '--color-info-bg': Color;
        '--color-info-text': Color;
        '--color-success-bg': Color;
        '--color-success-text': Color;
        '--color-white': Color;
        '--color-contrast-gray-100': Color;
        '--color-contrast-gray-200': Color;
        '--color-contrast-gray-300': Color;
        '--color-contrast-gray-400': Color;
        '--color-contrast-gray-500': Color;
        '--color-contrast-gray-600': Color;
        '--color-labels_drug_bg': Color;
        '--color-labels_drug_text': Color;
        '--color-labels_in-stock_bg': Color;
        '--color-labels_in-stock_text': Color;
        '--color-labels_new_bg': Color;
        '--color-labels_new_text': Color;
        '--color-labels_out-stock_bg': Color;
        '--color-labels_out-stock_text': Color;
        '--color-labels_prescription_bg': Color;
        '--color-labels_prescription_text': Color;
        '--color-labels_sales_bg': Color;
        '--color-labels_sales_text': Color;
        '--color-labels_written_request_bg': Color;
        '--color-labels_written_request_text': Color;
    };
}

export interface WebshopColorSetsPage {
    items: WebshopColorSet[];
    total: number;
    pagination: Pagination;
    sorting?: Sorting<keyof WebshopColorSet['colorSet']> | null;
    search?: string | null;
}

export type UpdateAvailableColorSetPayload = WebshopColorSet;
export type CreateAvailableColorSetPayload = WebshopColorSet;

export interface WebshopFontSet {
    font_set_id: string;
    font_set_name: string;
    '--font-family': string;
    font_set_url: string;
    font_set_fallback: string[];
    '--h1-font-weight': number;
    '--h2-font-weight': number;
    '--h3-font-weight': number;
    '--h4-font-weight': number;
    '--h5-font-weight': number;
    '--h6-font-weight': number;
    '--p-font-weight': number;
    '--p-small-font-weight': number;
    '--strong-font-weight': number;
    '--h1-font-size': number;
    '--h2-font-size': number;
    '--h3-font-size': number;
    '--h4-font-size': number;
    '--h5-font-size': number;
    '--h6-font-size': number;
    '--p-font-size': number;
    '--p-small-font-size': number;
    '--h1-line-height': number;
    '--h2-line-height': number;
    '--h3-line-height': number;
    '--h4-line-height': number;
    '--h5-line-height': number;
    '--h6-line-height': number;
    '--p-line-height': number;
    '--p-small-line-height': number;
    font_provider?: string;
}

export type WebshopFontSets = WebshopFontSet[];

export const WebshopShapeSetShapes = ['filled', 'outlined', 'mix'] as const;
export type WebshopShapeSetShape = (typeof WebshopShapeSetShapes)[number];

export const WebshopShapeSetBorderRadiuses = ['fully_rounded', 'partly_rounded', 'subtle_rounded', 'rectangular'] as const;
export type WebshopShapeSetBorderRadius = (typeof WebshopShapeSetBorderRadiuses)[number];

export interface WebshopShapeSet {
    shape?: WebshopShapeSetShape | null;
    borderRadius?: WebshopShapeSetBorderRadius | null;
}

export type WebshopNavigationHeaderType =
    | 'header_horizontal_1'
    | 'header_horizontal_2'
    | 'header_horizontal_3'
    | 'header_vertical_1'
    | 'header_vertical_2'
    | 'header_vertical_3';
export type WebshopNavigationFooterType = 'footer_horizontal_1' | 'footer_horizontal_2' | 'footer_horizontal_3';
export type WebshopLayouActiveStatus = 'active' | 'inactive';
export type FooterContentGroupType = 'contact' | 'customerService' | 'yourPharmacy' | 'newsletter' | 'socials';

export type NavigationContentLink = {
    activeStatus: WebshopLayouActiveStatus;
    children?: NavigationContentLink[] | null;
    type:
        | 'contact'
        | 'promotions'
        | 'blog'
        | 'appointments'
        | 'faq'
        | 'on_duty'
        | 'cms'
        | 'custom'
        | 'customerService'
        | 'yourPharmacy'
        | 'newsletter'
        | 'escripts'
        | 'socials';
    url: string;
    label: string | null;
    id?: string | null;
};

export interface FooterContent {
    activeStatus: WebshopLayouActiveStatus;
    groupType: FooterContentGroupType;
    name: LocalizedValue;
    links: LocalizedValue<NavigationContentLink[]> | null;
}
export type HeaderContents = {
    links: LocalizedValue<NavigationContentLink[]>;
};
export interface WebshopNavigationSet {
    header?: {
        header_name: any;
        header_type: WebshopNavigationHeaderType;
        header_contents: HeaderContents;
    } | null;
    footer?: {
        footer_name: any;
        footer_type: WebshopNavigationFooterType;
        footer_contents: FooterContent[];
    } | null;
}

export interface WebshopLayout {
    layoutSet?: WebshopNavigationSet | null;
    colorSet?: WebshopColorSet['colorSet']['colorset_id'] | null;
    fontSet?: WebshopFontSet['font_set_id'] | null;
    shapeSet?: WebshopShapeSet | null;
    qualityLabelIds?: string[];
    useDarkHeaderBackground?: boolean;
}

export interface UpdateWebshopColorSetPayload {
    webshopId: string;
    colorSetId: WebshopColorSet['colorSet']['colorset_id'];
    useDarkHeaderBackground?: boolean;
}

export interface UpdateWebshopFontSetPayload {
    webshopId: string;
    fontSetId: WebshopFontSet['font_set_id'];
}

export interface UpdateWebshopQualityLabelsPayload {
    webshopId: string;
    qualityLabelIds: string[];
}

export interface UpdateWebshopShapeSetPayload {
    webshopId: string;
    shape: WebshopShapeSetShape;
    borderRadius: WebshopShapeSetBorderRadius;
}

export interface UpdateWebshopNavigationSetPayload {
    webshopId: string;
    layoutSet: WebshopNavigationSet;
}
