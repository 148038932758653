import { StoredDebugConsoleLog } from '@/Infrastructure/DebugConsole';

import DeviceTunnel from './DeviceTunnel';
import Peer from './Peer';
import { TunnelSide } from './PeerTunnel';

type RemoteDebugConsoleTunnelMessage =
    | {
          type: 'logs';
          logs: StoredDebugConsoleLog[];
          tunnel: true;
      }
    | {
          type: 'execute-command';
          command: string;
          tunnel: true;
      };

export default class RemoteDebugConsoleTunnel extends DeviceTunnel<RemoteDebugConsoleTunnelMessage> {
    public constructor(
        peer: Peer,
        deviceId: string,
        options: {
            tunnelSide: TunnelSide;
            maxEstablishTime?: number;
            doNotRetryConnection?: boolean;
            onConnectionFailure?: (e: Event) => void;
        },
    ) {
        super(peer, deviceId, {
            tunnelPrefix: 'debugConsole',
            ...options,
        });
    }
}
