import * as React from 'react';

import { uuid } from 'api';

import { store, Actions } from '../state';
import { CanvasFrame } from '../types';
import DraggableBlockDragLayer from './DraggableBlockDragLayer';
import Frame from './Frame';

import styles from './styles/EditorCanvas.scss';

export default function EditorCanvas(props: {
    children?: React.ReactNode;
    frameRenderer: (frame: CanvasFrame, frameIsFocused: boolean, frameIsEdited: boolean) => React.ReactNode;
    draggedBlockRenderer?: (type: string) => React.ReactNode;
    resizeMiddleIsDisabledWhenEditingFrame: (frame: CanvasFrame) => boolean;
    getFrameClassName: (frame: CanvasFrame) => string;
    disableEditing?: boolean;
    scaleTo?: {
        width: number;
        height: number;
    };
}) {
    const { state, dispatch } = React.useContext(store);

    const scaleFactor = props.scaleTo ? props.scaleTo.width / state.canvas.width : 1;

    const dropAreaRef = React.useRef<HTMLDivElement>(null);
    const DROP_WIDTH = state.draggedBlockPosition.width;
    const DROP_HEIGHT = state.draggedBlockPosition.height;

    let isDraggingOver = false;
    if (state.draggedBlockType && dropAreaRef.current) {
        const rect = dropAreaRef.current.getBoundingClientRect();
        if (
            state.draggedBlockPosition.x >= rect.x + DROP_WIDTH / 2 &&
            state.draggedBlockPosition.x <= rect.x + rect.width - DROP_WIDTH / 2 &&
            state.draggedBlockPosition.y >= rect.y + DROP_HEIGHT / 2 &&
            state.draggedBlockPosition.y <= rect.y + rect.height - DROP_HEIGHT / 2
        ) {
            isDraggingOver = true;
        }
    }

    if (state.draggedBlockDroppedFlag && isDraggingOver && dropAreaRef.current) {
        const rect = dropAreaRef.current.getBoundingClientRect();

        dispatch({
            type: Actions.HISTORY_SAVE,
        });

        dispatch({
            type: Actions.ADD_FRAME,
            frame: {
                frameId: uuid.canvasFrame(),
                x: state.draggedBlockPosition.x - rect.x - DROP_WIDTH / 2,
                y: state.draggedBlockPosition.y - rect.y - DROP_HEIGHT / 2,
                width: DROP_WIDTH,
                height: DROP_HEIGHT,
            },
        });
    }

    const style: React.CSSProperties = {};
    style.width = (props.scaleTo ? props.scaleTo.width : state.canvas.width) + 'px';
    style.height = (props.scaleTo ? props.scaleTo.height : state.canvas.height) + 'px';
    if (scaleFactor !== 1) {
        style.transform = 'scale(' + scaleFactor + ')';
    }

    return (
        <div
            ref={dropAreaRef}
            className={styles.EditorCanvas}
            style={style}
        >
            {state.canvas.frames.map(frame => (
                <Frame
                    key={frame.frameId}
                    className={props.getFrameClassName(frame)}
                    frame={frame}
                    frameRenderer={props.frameRenderer}
                    resizeMiddleIsDisabledWhenEditingFrame={props.resizeMiddleIsDisabledWhenEditingFrame}
                    onClickOutside={() => {
                        dispatch({
                            type: Actions.SET_RESIZED_FRAME,
                            resizedFrameId: undefined,
                        });

                        dispatch({
                            type: Actions.SET_EDITED_FRAME,
                            editedFrameId: undefined,
                        });
                    }}
                    disableEditing={props.disableEditing}
                />
            ))}

            <DraggableBlockDragLayer draggedBlockRenderer={props.draggedBlockRenderer} />

            {props.children}
        </div>
    );
}
