import { config } from 'config';

const LOADED_VIDEOS: {
    [key: string]: Promise<any>;
} = {};

const LOADED_BLOBS: {
    [key: string]: undefined | string;
} = {};

async function fetchWithCORS(url: string): Promise<any> {
    if (url.indexOf(config.apiBaseUrl) === 0) {
        return fetch(url + '?v=4', {
            mode: 'cors',
        });
    } else if (url.indexOf('.amazonaws.com/') > -1) {
        return fetch(url + '?v=4', {});
    }
    return fetch(url, {
        mode: 'no-cors',
    });
}

export function preloadVideo(videoSrc: string): Promise<any> {
    if (!LOADED_VIDEOS.hasOwnProperty(videoSrc)) {
        LOADED_VIDEOS[videoSrc] = fetchWithCORS(videoSrc)
            .then(res => res.blob())
            .then(blob => {
                LOADED_BLOBS[videoSrc] = URL.createObjectURL(blob);
                return LOADED_BLOBS[videoSrc];
            });
    }

    return LOADED_VIDEOS[videoSrc];
}

export function getPreloadedVideoDataURL(videoSrc: string): undefined | string {
    if (LOADED_BLOBS.hasOwnProperty(videoSrc)) {
        return LOADED_BLOBS[videoSrc];
    }

    return undefined;
}
