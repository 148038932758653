import * as React from 'react';

import classNames from 'classnames';

import { colorTransform } from '@/color';
import { BaseProps } from '@/core';

import { IconSize } from './Icon';

import styles from './Icon.scss';

interface IProps {
    color?: string;
    iconSize?: IconSize;
}

export default class CircleIcon extends React.PureComponent<BaseProps & IProps & React.HTMLProps<HTMLSpanElement>> {
    render() {
        const { color, iconSize, className, children, ...rest } = this.props;

        return (
            <i
                {...rest}
                className={classNames(styles.Icon, styles.Circle, styles[iconSize || 'l'], colorTransform(color, 'BG'), className)}
            >
                {children}
            </i>
        );
    }
}
