import { Domain } from 'api';

import { ThunkAction } from '@/action';
import { notificationApi } from '@/api';
import { selectIsLoggedIn, selectLoggedInUser } from '@/Authentication';

import { setAllUserNotifications, setUserUnreadNotificationsCount } from './state';

let isFreshSession = true;
const POLL_INTERVAL = 1000 * 60 * 3;
let pollingInterval: ReturnType<typeof setInterval>;

export const LoadUserNotificationsCount = (): ThunkAction => async (dispatch, getState) => {
    const state = getState();
    const isLoggedIn = selectIsLoggedIn(state);

    if (isLoggedIn) {
        await dispatch(setUserUnreadNotificationsCount(await notificationApi.GetUserNotificationsCount()));
    }
};

export const LoadUserAllNotifications = (): ThunkAction => async (dispatch, getState) => {
    const state = getState();
    const isLoggedIn = selectIsLoggedIn(state);

    if (isLoggedIn) {
        await dispatch(
            setAllUserNotifications(
                await notificationApi.GetUserNotifications(
                    {
                        page: 1,
                        size: 999,
                    },
                    {
                        field: 'createdOn',
                        direction: 'descending',
                    },
                ),
            ),
        );
    }
};

export const PollUserUnreadNotificationsCount =
    (forceFreshSession?: boolean): ThunkAction =>
    async dispatch => {
        if (forceFreshSession) {
            isFreshSession = true;
        }

        if (isFreshSession) {
            isFreshSession = false;
            await dispatch(LoadUserNotificationsCount());
        }

        if (pollingInterval) {
            clearInterval(pollingInterval);
        }

        pollingInterval = setInterval(() => {
            dispatch(LoadUserNotificationsCount());
        }, POLL_INTERVAL);
    };

export const MarkNotificationAsRead =
    (notificationId: Domain.Notification['notificationId']): ThunkAction =>
    async dispatch => {
        await notificationApi.MarkNotificationAsRead({
            notificationId,
        });

        await dispatch(LoadUserNotificationsCount());
    };

export const MarkAllNotificationsAsRead = (): ThunkAction => async (dispatch, getState) => {
    const state = getState();
    const user = selectLoggedInUser(state);

    await notificationApi.MarkAllNotificationsAsRead({
        userId: user.userId,
    });

    await dispatch(LoadUserNotificationsCount());
};
