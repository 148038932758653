import { createAction, createReducer, Selector } from '@reduxjs/toolkit';

import { browserStorage } from 'utils';

import { ThunkAction, withPayloadType } from '@/action';
import { selectLoggedInUserOwnershipIds, selectLoggedInUserOwnership, maybeSelectLoggedInUser } from '@/Authentication';
import { RootState } from '@/store';
import {
    loadWebshopOrDevice,
    mapWebshopOrDeviceDetailsToSalesChannel,
    queryWebshopsAndDevices,
    isWebshop,
    WebshopOrDevice,
} from '@/webshopOrDevice';

const PLAUSIBLE_SALES_CHANNEL_SAVE_KEY = 'plausibleSalesChannelId-v1';

export interface State {
    salesChannel?: WebshopOrDevice;
}

const initialState: State = {};

export const reducerActions = {
    setSalesChannel: createAction('@plausible/overview/setSalesChannel', withPayloadType<WebshopOrDevice>()),
};

export const overviewReducer = createReducer(initialState, builder =>
    builder.addCase(reducerActions.setSalesChannel, (state, action) => {
        state.salesChannel = action.payload;
    }),
);

export const maybeSelectSalesChannel: Selector<RootState, WebshopOrDevice | undefined> = state => state.plausible.overview.salesChannel;

export const SetSelectedSalesChannelId =
    (originalSalesChannelId: string): ThunkAction =>
    async (dispatch, getState) => {
        const state = getState();
        const userIsLoggedIn = maybeSelectLoggedInUser(state);
        if (!userIsLoggedIn) {
            return;
        }

        const ownershipIds = selectLoggedInUserOwnershipIds(state);
        const stateSaveKey = PLAUSIBLE_SALES_CHANNEL_SAVE_KEY + '-' + ownershipIds.companyId + '-' + ownershipIds.branchId;

        browserStorage.setItem(stateSaveKey, originalSalesChannelId);
        const item = await loadWebshopOrDevice(originalSalesChannelId);
        await dispatch(reducerActions.setSalesChannel(mapWebshopOrDeviceDetailsToSalesChannel(item)));
    };

export const InitSelectedSalesChannel =
    (webshopsOnly = false): ThunkAction =>
    async (dispatch, getState) => {
        const state = getState();
        const userIsLoggedIn = maybeSelectLoggedInUser(state);
        if (!userIsLoggedIn) {
            return;
        }

        const ownershipIds = selectLoggedInUserOwnershipIds(state);
        const ownership = selectLoggedInUserOwnership(state);

        const stateSaveKey = PLAUSIBLE_SALES_CHANNEL_SAVE_KEY + '-' + ownershipIds.companyId + '-' + ownershipIds.branchId;
        const savedOriginalSalesChannelId = browserStorage.getItem(stateSaveKey);

        let loadedFromExistingSalesChannelId = false;
        if (savedOriginalSalesChannelId && savedOriginalSalesChannelId.value) {
            try {
                const loadedSalesChannel = maybeSelectSalesChannel(state);
                if (!loadedSalesChannel || loadedSalesChannel.webshopOrDeviceId !== savedOriginalSalesChannelId.value) {
                    const item = await loadWebshopOrDevice(savedOriginalSalesChannelId.value);
                    const salesChannel = mapWebshopOrDeviceDetailsToSalesChannel(item);
                    await dispatch(reducerActions.setSalesChannel(salesChannel));
                    loadedFromExistingSalesChannelId = true;
                }
            } catch (e) {
                browserStorage.removeItem(stateSaveKey);
            }
        }

        if (!loadedFromExistingSalesChannelId) {
            const items = await queryWebshopsAndDevices(ownership, webshopsOnly, undefined, 1);

            let webshopOrDeviceId: string | undefined;
            if (items.length > 0) {
                const item = items[0];
                if (isWebshop(item)) {
                    webshopOrDeviceId = item.webshopId;
                } else {
                    webshopOrDeviceId = item.deviceId;
                }
            }

            if (webshopOrDeviceId) {
                try {
                    const item = await loadWebshopOrDevice(webshopOrDeviceId);
                    const salesChannel = mapWebshopOrDeviceDetailsToSalesChannel(item);

                    browserStorage.setItem(stateSaveKey, salesChannel.webshopOrDeviceId);
                    await dispatch(reducerActions.setSalesChannel(salesChannel));
                } catch (e) {
                    // ignore
                }
            }
        }
    };

export const load = (): ThunkAction => async dispatch => {
    await dispatch(InitSelectedSalesChannel(true));
};
