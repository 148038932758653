import { Domain } from 'api';

import { ThunkAction } from '@/action';
import { webshopFaqApi } from '@/api';
import { makeActions, makeReducer, makeReducerActions, makeSelectors } from '@/makeOverviewState';
import { URLParams, URLQuery } from '@/routing';

type WebshopFaqItemProps = keyof Domain.WebshopFaqItem;

export const defaultSorting: Domain.Sorting<WebshopFaqItemProps> = {
    field: 'sortOrder',
    direction: 'ascending',
};

export const pageSize = 20;

export const selectors = makeSelectors<Domain.WebshopFaqItem, WebshopFaqItemProps>({
    getState: rootState => rootState.webshopFaqItem.overview,
});

export const reducerActions = makeReducerActions<Domain.WebshopFaqItem, WebshopFaqItemProps>({
    reducerPrefix: '@webshopFaqItem/overview',
});

export const baseActions = makeActions<Domain.WebshopFaqItem, WebshopFaqItemProps>({
    dataTableSaveKey: 'webshopFaqItemsOverview-v5',
    loadApi: options => {
        if (options.globallySelectedWebshopId === undefined) {
            return;
        }

        return webshopFaqApi.GetWebshopFaqItems(
            options.pagination,
            options.sorting,
            options.globallySelectedWebshopId,
            options.globallySelectedWebshopLocale || options.globallySelectedWebshopDefaultLocale || 'nl_BE',
            options.filters,
        );
    },
    defaultSorting,
    pageSize,
    getBaseUrl: () => '/webshops/faq-items',
    reducerActions,
    selectors,
});

export const actions = {
    ...baseActions,
    load:
        (options: { urlParams: URLParams; urlQuery: URLQuery }): ThunkAction =>
        async dispatch => {
            await Promise.all([dispatch(baseActions.load(options))]);
        },
};

export const overviewReducer = makeReducer<Domain.WebshopFaqItem, WebshopFaqItemProps>({
    defaultSorting,
    pageSize,
    reducerActions,
});
