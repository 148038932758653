import { Domain } from 'api';

import { webshopStockSubscriptionApi } from '@/api';
import { makeActions, makeReducer, makeReducerActions, makeSelectors } from '@/makeOverviewState';

type WebshopStockSubscriptionProps = keyof Domain.WebshopStockSubscription;

export const defaultSorting: Domain.Sorting<WebshopStockSubscriptionProps> = {
    field: 'createdOn',
    direction: 'descending',
};

export const pageSize = 20;

export const selectors = makeSelectors<Domain.WebshopStockSubscription, WebshopStockSubscriptionProps>({
    getState: rootState => rootState.webshopStockSubscription.overview,
});

export const reducerActions = makeReducerActions<Domain.WebshopStockSubscription, WebshopStockSubscriptionProps>({
    reducerPrefix: '@WebshopStockSubscription/overview',
});

export const actions = makeActions<Domain.WebshopStockSubscription, WebshopStockSubscriptionProps>({
    dataTableSaveKey: 'WebshopStockSubscriptionsOverview-v5',
    loadApi: options => {
        if (options.globallySelectedWebshopId === undefined) {
            return;
        }

        return webshopStockSubscriptionApi.getWebshopStockSubscriptions(
            options.pagination,
            options.sorting,
            options.globallySelectedWebshopId,
            options.search,
        );
    },
    defaultSorting,
    pageSize,
    getBaseUrl: () => '/webshops/stock-subscriptions',
    reducerActions,
    selectors,
});

export const overviewReducer = makeReducer<
    Domain.WebshopStockSubscription,
    WebshopStockSubscriptionProps,
    {
        emailAddress?: string;
    }
>({
    defaultSorting,
    pageSize,
    reducerActions,
});
