import { createAction, createReducer, Selector } from '@reduxjs/toolkit';

import { withPayloadType, ThunkAction } from '@/action';
import { RootState } from '@/store';

export const setDarkMode = createAction('@DarkMode/setDarkMode', withPayloadType<boolean>());

export interface State {
    darkMode: boolean;
}

const initialState: State = {
    darkMode: false,
};

export const SetDarkMode =
    (darkMode: boolean): ThunkAction =>
    async dispatch => {
        await dispatch(setDarkMode(darkMode));
    };

export const selectCurrentDarkMode: Selector<RootState, boolean> = state => state.darkMode.darkMode;

export const darkModeReducer = createReducer(initialState, builder =>
    builder.addCase(setDarkMode, (state, action) => {
        state.darkMode = action.payload;
    }),
);
