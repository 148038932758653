import { Domain } from 'api';

import { cmsApi } from '@/api';
import { makeActions, makeReducer, makeReducerActions, makeSelectors } from '@/makeOverviewState';

type CmsTagProps = keyof Domain.CmsTag;

export const defaultSorting: Domain.Sorting<CmsTagProps> = {
    field: 'createdOn',
    direction: 'descending',
};

export const pageSize = 20;

export const selectors = makeSelectors<Domain.CmsTag, CmsTagProps>({
    getState: rootState => rootState.cmsTag.overview,
});

export const reducerActions = makeReducerActions<Domain.CmsTag, CmsTagProps>({
    reducerPrefix: '@cmsTag/overview',
});

export const actions = makeActions<Domain.CmsTag, CmsTagProps>({
    dataTableSaveKey: 'cmsTag-v1',
    loadApi: options => cmsApi.GetCmsTags(options.pagination, options.sorting, options.filters),
    defaultSorting,
    pageSize,
    getBaseUrl: () => '/cms/tags',
    reducerActions,
    selectors,
});

export const overviewReducer = makeReducer<Domain.CmsTag, CmsTagProps>({
    defaultSorting,
    pageSize,
    reducerActions,
});
