import * as React from 'react';

import { CheckboxListContextBase } from '@/checkboxList/CheckboxListContext';

export type RadioGroupContext = CheckboxListContextBase & {
    value?: string;
};

export const RadioGroupContext = React.createContext<RadioGroupContext>({
    value: undefined,
});
