import { Domain } from 'api';

import getLocalizedImages from './getLocalizedImages';
import getPreferredProductImageURL from './getPreferredProductImageURL';
import { productCodesToList } from './TVScreen/VendingMachineCatalog';

export function getFirstProductCode(productCodes: Domain.ProductCodes | []): string {
    const productCodesList = Array.isArray(productCodes) ? productCodes : productCodesToList(productCodes);
    return productCodesList.length > 0 ? productCodesList[0] : '';
}

export function getProductDisplayInformation(
    productDetails: Domain.SelectedProduct | undefined,
    productCodes: Domain.ProductCodes,
    locale: Domain.Locale,
) {
    let imageUrl: string | undefined = undefined;
    if (productDetails) {
        const images = [...productDetails.frontals, ...productDetails.images];
        if (images.length > 0) {
            const localizedImages = getLocalizedImages(images, locale);
            for (const image of localizedImages) {
                if (image.type === 'frontal') {
                    imageUrl = getPreferredProductImageURL(image, true);
                }
                if (imageUrl) {
                    break;
                }
            }
            if (!imageUrl) {
                for (const image of localizedImages) {
                    imageUrl = getPreferredProductImageURL(image, true);
                    if (imageUrl) {
                        break;
                    }
                }
            }
        }
    }

    const firstProductCode = getFirstProductCode(productCodes);

    return {
        imageUrl,
        firstProductCode,
    };
}
