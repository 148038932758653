import {
    Pagination,
    WebshopDeliveryTimesPage,
    WebshopDeliveryTime,
    CreateWebshopDeliveryTimePayload,
    UpdateWebshopDeliveryTimePayload,
} from '@/Domain';

import Endpoint from './Endpoint';

export default class WebshopDeliveryTimeEndpoint extends Endpoint {
    public async GetWebshopDeliveryTimes(pagination: Pagination, webshopId: string): Promise<WebshopDeliveryTimesPage> {
        const url = `/webshop/${webshopId}/deliveryTime/overview`;

        const response = await this.client.get({
            url,
            params: {
                pagination,
            },
        });

        this.guardResponseSchema('#/definitions/WebshopDeliveryTimesPage', response);

        return response;
    }

    public async GetWebshopDeliveryTimeDetails(webshopId: string, deliveryTimeId: string): Promise<WebshopDeliveryTime> {
        const response = await this.client.get({
            url: `/webshop/${webshopId}/deliveryTime/${deliveryTimeId}/details`,
        });

        this.guardResponseSchema('#/definitions/WebshopDeliveryTime', response);

        return response;
    }

    public async CreateWebshopDeliveryTime(payload: CreateWebshopDeliveryTimePayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/CreateWebshopDeliveryTime',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Webshop\\DeliveryTime\\UpdateDeliveryTime\\UpdateDeliveryTime',
                payload,
            },
        });
    }

    public async UpdateWebshopDeliveryTime(payload: UpdateWebshopDeliveryTimePayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/UpdateWebshopDeliveryTime',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Webshop\\DeliveryTime\\UpdateDeliveryTime\\UpdateDeliveryTime',
                payload,
            },
        });
    }

    public async RemoveWebshopDeliveryTime(deliveryTimeId: string): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/RemoveWebshopDeliveryTime',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Webshop\\DeliveryTime\\RemoveDeliveryTime\\RemoveDeliveryTime',
                payload: {
                    deliveryTimeId,
                },
            },
        });
    }
}
