import * as React from 'react';

import Portal from './Portal';

export interface AnchoredPortalPositioningProps {
    anchorVPosition?: 'top' | 'center' | 'bottom' | 'auto';
    anchorHPosition?: 'left' | 'center' | 'right' | 'left-align';
}

export default function AnchoredPortal({
    className,
    style,
    children,
    anchorElement,
    inheritWidthFromAnchor,
    inheritMinWidthFromAnchor,
    anchorCanMove,
    anchorVPosition,
    anchorHPosition,
    aboveSidebar,
}: {
    className?: string;
    style?: React.CSSProperties;
    children?: React.ReactNode;
    anchorElement: HTMLElement | null;
    inheritWidthFromAnchor?: boolean;
    inheritMinWidthFromAnchor?: boolean;
    anchorCanMove?: boolean;
    aboveSidebar?: boolean;
} & AnchoredPortalPositioningProps) {
    const refreshTimer = React.useRef<ReturnType<typeof setTimeout> | undefined>(undefined);
    const [contentsElement, setContentsElement] = React.useState<HTMLDivElement | null>(null);
    const [sidebarElement, setSidebarElement] = React.useState<HTMLElement | null>(document.getElementById('main-sidebar'));
    const contentsRef = useHookWithRefCallback<HTMLDivElement>(setContentsElement);
    const [x, setX] = React.useState<number | undefined>(undefined);
    const [y, setY] = React.useState<number | undefined>(undefined);
    const [anchor, setAnchor] = React.useState<HTMLElement | null>(null);
    const [anchorHeight, setAnchorHeight] = React.useState<number | undefined>(undefined);
    const [anchorWidth, setAnchorWidth] = React.useState<number | undefined>(undefined);
    const [contentsHeight, setContentsHeight] = React.useState<number | undefined>(undefined);
    const [contentsWidth, setContentsWidth] = React.useState<number | undefined>(undefined);

    React.useEffect(() => {
        if (!sidebarElement) {
            setSidebarElement(document.getElementById('main-sidebar'));
        }
    }, []);

    const refresh = () => {
        if (refreshTimer.current) {
            clearTimeout(refreshTimer.current);
        }

        refreshTimer.current = setTimeout(
            () => {
                refresh();
            },
            anchorCanMove ? 10 : 1000,
        );

        if (!anchor) {
            return;
        }

        const anchorBox = anchor.getBoundingClientRect();

        setY(anchorBox.y);
        setX(anchorBox.x);
        setAnchorHeight(anchorBox.height);
        setAnchorWidth(anchorBox.width);

        if (contentsElement && contentsElement.children.length > 0) {
            const contentsBox = contentsElement.children[0].getBoundingClientRect();
            setContentsHeight(contentsBox.height);
            setContentsWidth(contentsBox.width);
        }
    };

    React.useEffect(() => {
        refresh();
    }, [anchorCanMove]);

    React.useEffect(() => {
        setAnchor(anchorElement);
    }, [anchorElement]);

    React.useEffect(() => {
        refresh();
    }, [anchor, contentsElement]);

    React.useEffect(() => {
        return () => {
            if (refreshTimer.current) {
                clearTimeout(refreshTimer.current);
            }
        };
    }, []);

    const ownStyle: React.CSSProperties = {
        ...style,
    };

    let left = 0;
    let top = 0;
    let opensOnTop = false;
    let minLeft = sidebarElement ? sidebarElement.getBoundingClientRect().width : 0;
    if (minLeft >= window.outerWidth) {
        minLeft = 0;
    }

    if (
        x === undefined ||
        y === undefined ||
        anchorWidth === undefined ||
        anchorHeight === undefined ||
        contentsWidth === undefined ||
        contentsHeight === undefined
    ) {
        ownStyle.opacity = 0;
        ownStyle.top = top + 'px';
        ownStyle.left = left + 'px';
    } else {
        if (inheritWidthFromAnchor) {
            ownStyle.width = anchorWidth + 'px';
        } else if (inheritMinWidthFromAnchor) {
            ownStyle.minWidth = anchorWidth + 'px';
        }

        const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
        const scrollLeft = document.documentElement.scrollLeft || document.body.scrollLeft;

        top = y + scrollTop;
        left = x + scrollLeft;

        if (anchorVPosition === 'top') {
            top -= contentsHeight;
        } else if (anchorVPosition === 'bottom' || anchorVPosition === 'auto') {
            top += anchorHeight;
        } else if (anchorVPosition === 'center') {
            top += anchorHeight / 2;
            top -= contentsHeight / 2;
        }

        if (anchorHPosition === 'right') {
            left += anchorWidth;
        } else if (anchorHPosition === 'left') {
            left -= contentsWidth;
        } else if (anchorHPosition === 'left-align') {
            left += anchorWidth;
            left -= contentsWidth;
        } else if (anchorHPosition === 'center') {
            left += anchorWidth / 2;
            left -= contentsWidth / 2;
        }

        if (
            anchorVPosition === 'auto' &&
            top + contentsHeight - scrollTop > document.documentElement.clientHeight &&
            top - anchorHeight - contentsHeight > 0
        ) {
            top -= anchorHeight + contentsHeight;
            opensOnTop = true;
        }

        const bodyRect = document.body.getBoundingClientRect();

        if (left + contentsWidth > bodyRect.width) {
            left = bodyRect.width - contentsWidth;
        }

        if (left < minLeft) {
            left = minLeft;
        }

        ownStyle.left = left + 'px';

        if (!opensOnTop) {
            ownStyle.top = top + 'px';
        } else {
            ownStyle.bottom = document.documentElement.clientHeight - top - contentsHeight + 'px';
        }
    }

    if (x === undefined || y === undefined) {
        return <span />;
    }

    return (
        <Portal
            ref={contentsRef as any}
            aboveSidebar={aboveSidebar}
            className={className}
            style={ownStyle}
        >
            {children}
        </Portal>
    );
}

function useHookWithRefCallback<T>(callback: (element: T | null) => void) {
    const ref = React.useRef<T | null>(null);
    return React.useCallback((element: T) => {
        callback(element);
        ref.current = element;
    }, []);
}
