import * as React from 'react';

import ControlledCheckbox, { ControlledCheckboxPropsWithChecked } from './ControlledCheckbox';
import UncontrolledCheckbox, { UncontrolledCheckboxProps } from './UncontrolledCheckbox';

export type CheckboxProps = ControlledCheckboxPropsWithChecked | UncontrolledCheckboxProps;

class Checkbox extends React.PureComponent<CheckboxProps> {
    render() {
        if (this.isControlled(this.props)) {
            return <ControlledCheckbox {...this.props} />;
        }

        return <UncontrolledCheckbox {...this.props} />;
    }

    private isControlled(props: CheckboxProps): props is ControlledCheckboxPropsWithChecked {
        return (props as ControlledCheckboxPropsWithChecked).hasOwnProperty('checked');
    }
}

export default React.forwardRef<HTMLInputElement, CheckboxProps>((props, ref) => {
    return (
        <Checkbox
            forwardRef={ref}
            {...props}
        />
    );
});
