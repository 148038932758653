import { createAction, createReducer, Selector, Draft, ActionReducerMapBuilder } from '@reduxjs/toolkit';

import { Domain } from 'api';

import { ThunkAction, withPayloadType } from './action';
import {
    selectLoggedInUserOwnership,
    selectLoggedInUserOwnershipIds,
    maybeSelectLoggedInUserOwnershipIds,
    selectLoggedInUser,
} from './Authentication';
import { getDataTableSavedSettings, setDataTableSavedSettings, SavedSettings } from './dataTableSavedSettings';
import { selectGlobalSelectedDeviceId } from './Device/globalSelectionState';
import { selectCurrentLocale } from './I18n';
import { getOverviewQueryParameters, updateURLWithQueryParams, URLParams, URLQuery } from './routing';
import { RootState } from './store';
import {
    selectGlobalSelectedWebshopId,
    selectGlobalSelectedWebshopDefaultLocale,
    selectGlobalSelectedWebshopLocale,
    selectGlobalSelectedWebshop,
} from './Webshop/globalSelectionState';

export interface Filters {
    [key: string]: string | undefined;
}

export interface CurrentPage<T, P extends string> {
    items: T[];
    total: number;
    pagination: Domain.Pagination;
    sorting?: Domain.Sorting<P> | null;
    search?: string | null;
}

export interface OverviewState<T, P extends string> {
    currentPage?: CurrentPage<T, P>;
    sorting: Domain.Sorting<P>;
    pagination: Domain.Pagination;
    search: string;
    filters: Filters;
    visibleColumns: undefined | P[];
    sortableColumns?: P[];
    viewMode: Domain.ViewMode;
    urlParams: URLParams;
}

interface ReducerActionsStateOptions {
    reducerPrefix: string;
}

interface ActionsStateOptions<T, P extends string, F = void> {
    dataTableSaveKey: string;
    loadApi: (options: {
        pagination: Domain.Pagination;
        sorting: Domain.Sorting<P>;
        search: string;
        filters: Filters & F;
        viewMode: Domain.ViewMode;
        ownership: Domain.Ownership;
        ownershipIds: Domain.OptionalOwnershipIds;
        userId: Domain.User['userId'];
        locale: Domain.Locale;
        globallySelectedDeviceId: Domain.Device['deviceId'] | undefined;
        globallySelectedWebshopId: Domain.Webshop['webshopId'] | undefined;
        globallySelectedWebshopDefaultLocale: Domain.Locale | undefined;
        globallySelectedWebshopLocale: Domain.Locale | undefined;
        globallySelectedWebshopBranchId: Domain.Webshop['branchId'] | undefined;
        urlParams: URLParams;
        state: any;
    }) => Promise<CurrentPage<T, P>> | undefined;
    defaultSorting: Domain.Sorting<P>;
    getBaseUrl?: (ownership: Domain.Ownership) => string;
}

interface ReducerStateOptions<T, P extends string, X = void> {
    defaultSorting: Domain.Sorting<P>;
    extraCases?: (builder: ActionReducerMapBuilder<OverviewState<T, P> & X>) => void;
}

interface SelectorsStateOptions<T, P extends string> {
    getState: (rootState: RootState) => OverviewState<T, P>;
}

function makeInitialState<T, P extends string, X = void>(
    defaultSorting: Domain.Sorting<P>,
    pageSize: number,
    defaultViewMode: Domain.ViewMode = 'list',
): OverviewState<T, P> & X {
    return {
        pagination: {
            page: 1,
            size: pageSize,
        },
        sorting: defaultSorting,
        search: '',
        filters: {},
        visibleColumns: undefined,
        viewMode: defaultViewMode,
        urlParams: {},
    } as OverviewState<T, P> & X;
}

export function makeReducerActions<T, P extends string>(stateOptions: ReducerActionsStateOptions) {
    return {
        setCurrentPage: createAction(stateOptions.reducerPrefix + '/setCurrentPage', withPayloadType<CurrentPage<T, P>>()),
        setSorting: createAction(stateOptions.reducerPrefix + '/setSorting', withPayloadType<Domain.Sorting<P>>()),
        setPagination: createAction(stateOptions.reducerPrefix + '/setPagination', withPayloadType<Domain.Pagination>()),
        setPaginationPage: createAction(stateOptions.reducerPrefix + '/setPaginationPage', withPayloadType<Domain.Pagination['page']>()),
        setPageSize: createAction(stateOptions.reducerPrefix + '/setPageSize', withPayloadType<Domain.Pagination['size']>()),
        setSearch: createAction(stateOptions.reducerPrefix + '/setSearch', withPayloadType<string>()),
        setFilters: createAction(stateOptions.reducerPrefix + '/setFilters', withPayloadType<Filters>()),
        setVisibleColumns: createAction(stateOptions.reducerPrefix + '/setVisibleColumns', withPayloadType<undefined | P[]>()),
        setSortableColumns: createAction(stateOptions.reducerPrefix + '/setSortableColumns', withPayloadType<undefined | P[]>()),
        setViewMode: createAction(stateOptions.reducerPrefix + '/setViewMode', withPayloadType<Domain.ViewMode>()),
        setUrlParams: createAction(stateOptions.reducerPrefix + '/setUrlParams', withPayloadType<URLParams>()),
    };
}

export function makeActions<T, P extends string, F = void>(
    stateOptions: ActionsStateOptions<T, P, F> & {
        reducerActions: ReturnType<typeof makeReducerActions>;
        selectors: ReturnType<typeof makeSelectors>;
        pageSize: number;
    },
) {
    const initialState = makeInitialState<T, P>(stateOptions.defaultSorting, stateOptions.pageSize);

    const updateDataTableSavedSettings =
        (newSettings: SavedSettings): ThunkAction =>
        async (_1, getState) => {
            const state = getState();
            const search = stateOptions.selectors.selectSearch(state);
            const filters = stateOptions.selectors.selectFilters(state);
            const viewMode = stateOptions.selectors.selectViewMode(state);
            const sorting = stateOptions.selectors.selectSorting(state);
            const visibleColumns = stateOptions.selectors.selectVisibleColumns(state);
            const ownershipIds = selectLoggedInUserOwnershipIds(state);

            setDataTableSavedSettings(stateOptions.dataTableSaveKey + '-' + ownershipIds.companyId + '-' + ownershipIds.branchId, {
                search,
                filters,
                sorting,
                visibleColumns,
                viewMode,
                ...newSettings,
            });
        };

    const setVisibleColumns =
        (visibleColumns: P[]): ThunkAction =>
        async dispatch => {
            await dispatch(
                updateDataTableSavedSettings({
                    visibleColumns,
                }),
            );

            await dispatch(stateOptions.reducerActions.setVisibleColumns(visibleColumns));
        };
    const setSortableColumns =
        (sortableColumns: P[]): ThunkAction =>
        async dispatch => {
            await dispatch(
                updateDataTableSavedSettings({
                    sortableColumns,
                }),
            );
            await dispatch(stateOptions.reducerActions.setSortableColumns(sortableColumns));
        };
    const setSorting =
        (sorting: Domain.Sorting<P>): ThunkAction =>
        async dispatch => {
            await dispatch(
                updateDataTableSavedSettings({
                    sorting,
                }),
            );

            await dispatch(stateOptions.reducerActions.setSorting(sorting));
        };

    const setSearch =
        (search: string): ThunkAction =>
        dispatch => {
            dispatch(
                updateDataTableSavedSettings({
                    search,
                }),
            );

            dispatch(stateOptions.reducerActions.setSearch(search));
        };

    const setFilters =
        (filters: Filters): ThunkAction =>
        async dispatch => {
            await dispatch(
                updateDataTableSavedSettings({
                    filters,
                }),
            );

            await dispatch(stateOptions.reducerActions.setFilters(filters));
        };

    const setPaginationPage =
        (page: Domain.Pagination['page']): ThunkAction =>
        async dispatch => {
            await dispatch(stateOptions.reducerActions.setPaginationPage(page));
        };

    const setPageSize =
        (size: Domain.Pagination['size']): ThunkAction =>
        async dispatch => {
            await dispatch(stateOptions.reducerActions.setPageSize(size));
        };

    const setViewMode =
        (viewMode: Domain.ViewMode, updateURL = false): ThunkAction =>
        async (dispatch, getState, getNavigate) => {
            const state = getState();
            const navigate = getNavigate();
            const ownership = selectLoggedInUserOwnership(state);
            const sorting = stateOptions.selectors.selectSorting(state);
            const pagination = stateOptions.selectors.selectPagination(state);
            const search = stateOptions.selectors.selectSearch(state);
            const filters = stateOptions.selectors.selectFilters(state) as Filters & F;

            await dispatch(
                updateDataTableSavedSettings({
                    viewMode,
                }),
            );

            await dispatch(stateOptions.reducerActions.setViewMode(viewMode));

            if (updateURL && stateOptions.getBaseUrl) {
                updateURLWithQueryParams({
                    baseURL: stateOptions.getBaseUrl(ownership),
                    navigate,
                    pagination,
                    sorting,
                    search,
                    viewMode,
                    filters,
                    defaultSorting: initialState.sorting,
                    defaultViewMode: initialState.viewMode,
                });
            }
        };

    const loadCurrentPage =
        (pushNewHistoryItem?: boolean): ThunkAction<Promise<CurrentPage<T, P> | undefined>> =>
        async (dispatch, getState, getNavigate) => {
            if (stateOptions.loadApi) {
                const state = getState();
                const navigate = getNavigate();
                const loggedInUser = selectLoggedInUser(state);
                const ownership = selectLoggedInUserOwnership(state);
                const ownershipIds = maybeSelectLoggedInUserOwnershipIds(state);
                const locale = selectCurrentLocale(state);
                const globallySelectedDeviceId = selectGlobalSelectedDeviceId(state);
                const globallySelectedWebshopId = selectGlobalSelectedWebshopId(state);
                const globallySelectedWebshop = selectGlobalSelectedWebshop(state);
                const globallySelectedWebshopDefaultLocale = selectGlobalSelectedWebshopDefaultLocale(state);
                const globallySelectedWebshopLocale = selectGlobalSelectedWebshopLocale(state);
                const urlParams = stateOptions.selectors.selectUrlParams(state);
                const pagination = stateOptions.selectors.selectPagination(state);
                const sorting = stateOptions.selectors.selectSorting(state) as Domain.Sorting<P>;
                const search = stateOptions.selectors.selectSearch(state);
                const viewMode = stateOptions.selectors.selectViewMode(state);
                const filters = stateOptions.selectors.selectFilters(state) as Filters & F;

                let urlUpdated = false;
                if (stateOptions.getBaseUrl) {
                    urlUpdated = updateURLWithQueryParams(
                        {
                            baseURL: stateOptions.getBaseUrl(ownership),
                            navigate,
                            pagination,
                            sorting,
                            viewMode,
                            search,
                            filters,
                            defaultSorting: initialState.sorting,
                            defaultViewMode: initialState.viewMode,
                        },
                        {
                            replace: !pushNewHistoryItem,
                        },
                    );
                }

                if (!urlUpdated) {
                    const currentPage = await stateOptions.loadApi({
                        pagination,
                        sorting,
                        search,
                        filters,
                        viewMode,
                        ownership,
                        ownershipIds,
                        userId: loggedInUser.userId,
                        locale,
                        globallySelectedDeviceId,
                        globallySelectedWebshopId,
                        globallySelectedWebshopDefaultLocale,
                        globallySelectedWebshopLocale,
                        globallySelectedWebshopBranchId: globallySelectedWebshop?.branchId,
                        urlParams,
                        state,
                    });

                    if (currentPage !== undefined) {
                        await dispatch(stateOptions.reducerActions.setCurrentPage(currentPage));
                    }

                    return currentPage;
                }
            }
        };

    const load =
        (loadOptions: { urlQuery: URLQuery; urlParams: URLParams }): ThunkAction<Promise<CurrentPage<T, P> | undefined>> =>
        async (dispatch, getState) => {
            const state = getState();
            const ownershipIds = selectLoggedInUserOwnershipIds(state);

            const savedSettings = getDataTableSavedSettings(
                stateOptions.dataTableSaveKey + '-' + ownershipIds.companyId + '-' + ownershipIds.branchId,
            );

            if (savedSettings) {
                if (savedSettings.search) {
                    await dispatch(stateOptions.reducerActions.setSearch(savedSettings.search));
                }
                if (savedSettings.filters) {
                    await dispatch(stateOptions.reducerActions.setFilters(savedSettings.filters));
                }
                if (savedSettings.viewMode) {
                    await dispatch(stateOptions.reducerActions.setViewMode(savedSettings.viewMode));
                }
                if (savedSettings.sorting) {
                    await dispatch(stateOptions.reducerActions.setSorting(savedSettings.sorting as Domain.Sorting<P>));
                }
                if (savedSettings.visibleColumns) {
                    await dispatch(stateOptions.reducerActions.setVisibleColumns(savedSettings.visibleColumns as P[]));
                }
                if (savedSettings.sortableColumns) {
                    await dispatch(stateOptions.reducerActions.setSortableColumns(savedSettings.sortableColumns as P[]));
                }
            } else {
                await dispatch(stateOptions.reducerActions.setSearch(''));
                await dispatch(stateOptions.reducerActions.setFilters({}));
                await dispatch(stateOptions.reducerActions.setViewMode(initialState.viewMode));
                await dispatch(stateOptions.reducerActions.setSorting(initialState.sorting));
                await dispatch(stateOptions.reducerActions.setVisibleColumns(undefined));
                await dispatch(stateOptions.reducerActions.setSortableColumns(undefined));
            }

            const defaultSorting = stateOptions.selectors.selectSorting(state);
            const defaultViewMode = stateOptions.selectors.selectViewMode(state);

            const queryParams = getOverviewQueryParameters({
                urlQuery: loadOptions.urlQuery,
                defaultSorting,
                defaultViewMode,
            });

            await dispatch(stateOptions.reducerActions.setUrlParams(loadOptions.urlParams));

            if (queryParams.search !== null) {
                await dispatch(stateOptions.reducerActions.setSearch(queryParams.search));
            }
            if (queryParams.filters !== null && Object.keys(queryParams.filters).length > 0) {
                await dispatch(stateOptions.reducerActions.setFilters(queryParams.filters));
            }
            if (queryParams.viewMode !== null) {
                await dispatch(stateOptions.reducerActions.setViewMode(queryParams.viewMode));
            }
            if (queryParams.sorting !== null) {
                await dispatch(stateOptions.reducerActions.setSorting(queryParams.sorting));
            }
            if (queryParams.page !== null) {
                await dispatch(stateOptions.reducerActions.setPaginationPage(queryParams.page));
            }
            if (queryParams.pageSize !== null) {
                await dispatch(stateOptions.reducerActions.setPageSize(queryParams.pageSize));
            }

            return await dispatch(loadCurrentPage(false));
        };

    return {
        setVisibleColumns,
        setSortableColumns,
        setViewMode,
        setSorting,
        setSearch,
        setFilters,
        setPaginationPage,
        setPageSize,
        loadCurrentPage,
        load,
    };
}

export function makeReducer<T, P extends string, X = void>(
    stateOptions: ReducerStateOptions<T, P, X> & {
        reducerActions: ReturnType<typeof makeReducerActions>;
        pageSize: number;
    },
) {
    const initialState = makeInitialState<T, P, X>(stateOptions.defaultSorting, stateOptions.pageSize);

    return createReducer(initialState, builder => {
        builder
            .addCase(stateOptions.reducerActions.setCurrentPage, (state, action) => {
                state.currentPage = action.payload as Draft<CurrentPage<T, P>>;
            })
            .addCase(stateOptions.reducerActions.setSorting, (state, action) => {
                state.sorting = action.payload as Draft<Domain.Sorting<P>>;
            })
            .addCase(stateOptions.reducerActions.setPagination, (state, action) => {
                state.pagination = action.payload;
            })
            .addCase(stateOptions.reducerActions.setPaginationPage, (state, action) => {
                state.pagination.page = action.payload;
            })
            .addCase(stateOptions.reducerActions.setPageSize, (state, action) => {
                state.pagination.size = action.payload;
            })
            .addCase(stateOptions.reducerActions.setSearch, (state, action) => {
                state.search = action.payload;
            })
            .addCase(stateOptions.reducerActions.setFilters, (state, action) => {
                state.filters = action.payload;
            })
            .addCase(stateOptions.reducerActions.setViewMode, (state, action) => {
                state.viewMode = action.payload;
            })
            .addCase(stateOptions.reducerActions.setVisibleColumns, (state, action) => {
                state.visibleColumns = action.payload as Draft<P>[];
            })
            .addCase(stateOptions.reducerActions.setSortableColumns, (state, action) => {
                state.sortableColumns = action.payload as Draft<P>[];
            })
            .addCase(stateOptions.reducerActions.setUrlParams, (state, action) => {
                state.urlParams = action.payload;
            });

        if (stateOptions.extraCases) {
            stateOptions.extraCases(builder);
        }
    });
}

export function makeSelectors<T, P extends string>(stateOptions: SelectorsStateOptions<T, P>) {
    const maybeSelectCurrentPage: Selector<any, CurrentPage<T, P> | undefined> = state => stateOptions.getState(state).currentPage;
    const selectCurrentPage: Selector<any, CurrentPage<T, P>> = state => {
        const currentPage = maybeSelectCurrentPage(state);
        if (!currentPage) {
            throw new Error('Overview page not loaded');
        }
        return currentPage;
    };

    const selectVisibleColumns: Selector<any, undefined | P[]> = state => stateOptions.getState(state).visibleColumns;
    const selectSortableColumns: Selector<any, undefined | P[]> = state => stateOptions.getState(state).sortableColumns;

    const selectViewMode: Selector<any, Domain.ViewMode> = state => stateOptions.getState(state).viewMode;
    const selectSorting: Selector<any, Domain.Sorting<P>> = state => stateOptions.getState(state).sorting;
    const selectPagination: Selector<any, Domain.Pagination> = state => stateOptions.getState(state).pagination;
    const selectSearch: Selector<any, string> = state => stateOptions.getState(state).search;
    const selectFilters: Selector<any, Filters> = state => stateOptions.getState(state).filters;
    const selectUrlParams: Selector<any, URLParams> = state => stateOptions.getState(state).urlParams;

    return {
        maybeSelectCurrentPage,
        selectCurrentPage,
        selectSorting,
        selectPagination,
        selectFilters,
        selectSearch,
        selectVisibleColumns,
        selectViewMode,
        selectUrlParams,
        selectSortableColumns,
    };
}
