import { Domain } from 'api';
import { Actions as CanvasActions } from 'editor-canvas';

import {
    Frame,
    TextFrame,
    MediaFrame,
    ShelfFrame,
    TextStyle,
    ImageCrop,
    ProductFrame,
    HotSpotFrame,
    PriceStyleShape,
    HotSpotFrameHiddenSlideType,
    OpeningHoursFrame,
} from '../types';
import { EyedropperChangeHandlers } from './state';

export const SET_AVAILABLE_PRODUCTS = 'SET_AVAILABLE_PRODUCTS';
export const UPDATE_AVAILABLE_PRODUCT = 'UPDATE_AVAILABLE_PRODUCT';
export const SET_PRODUCT_IMAGE_SIZE = 'SET_PRODUCT_IMAGE_SIZE';
export const SET_FRAME_TYPE = 'SET_FRAME_TYPE';
export const SET_FRAME_TEXT = 'SET_FRAME_TEXT';
export const SET_FRAME_HTML = 'SET_FRAME_HTML';
export const SET_FRAME_TEXT_STYLE = 'SET_FRAME_TEXT_STYLE';
export const SET_TEXT_FRAME_HAS_STYLED_BACKGROUND = 'SET_TEXT_FRAME_HAS_STYLED_BACKGROUND';
export const SET_TEXT_FRAME_BACKGROUND_TYPE = 'SET_TEXT_FRAME_BACKGROUND_TYPE';
export const SET_TEXT_FRAME_BACKGROUND_COLOR = 'SET_TEXT_FRAME_BACKGROUND_COLOR';
export const SET_TEXT_FRAME_BACKGROUND_MEDIA_ITEM_ID = 'SET_TEXT_FRAME_BACKGROUND_MEDIA_ITEM_ID';
export const SET_TEXT_FRAME_BACKGROUND_IMAGE_CROP = 'SET_TEXT_FRAME_BACKGROUND_IMAGE_CROP';
export const SET_MEDIA_FRAME_TYPE = 'SET_MEDIA_FRAME_TYPE';
export const SET_MEDIA_FRAME_MEDIA_ITEM_ID = 'SET_MEDIA_FRAME_MEDIA_ITEM_ID';
export const SET_MEDIA_FRAME_MEDIA_CROP = 'SET_MEDIA_FRAME_MEDIA_CROP';
export const SET_SHELF_FRAME_STYLE = 'SET_SHELF_FRAME_STYLE';
export const SET_PRODUCT_FRAME_PRODUCT = 'SET_PRODUCT_FRAME_PRODUCT';
export const SET_PRODUCT_FRAME_PRODUCT_MEDIA_TYPE = 'SET_PRODUCT_FRAME_PRODUCT_MEDIA_TYPE';
export const SET_FRAME_HOTSPOT_PRODUCT_ID = 'SET_FRAME_HOTSPOT_PRODUCT_ID';
export const SET_PLACED_PRODUCT_ITEMS_LIMIT_ENABLED = 'SET_PLACED_PRODUCT_ITEMS_LIMIT_ENABLED';
export const SET_PLACED_PRODUCT_ITEMS_LIMIT = 'SET_PLACED_PRODUCT_ITEMS_LIMIT';
export const SET_PLACED_PRODUCT_SCALE = 'SET_PLACED_PRODUCT_SCALE';
export const SET_PLACED_PRODUCT_ALIGN = 'SET_PLACED_PRODUCT_ALIGN';
export const SET_PLACED_PRODUCT_X_SPACING = 'SET_PLACED_PRODUCT_X_SPACING';
export const SET_PLACED_PRODUCT_Y_SPACING = 'SET_PLACED_PRODUCT_Y_SPACING';
export const SET_PLACED_PRODUCT_IMAGE_ID = 'SET_PLACED_PRODUCT_IMAGE_ID';
export const SET_PLACED_PRODUCT_SHOW_PRICE = 'SET_PLACED_PRODUCT_SHOW_PRICE';
export const SET_PLACED_PRODUCT_HIDE_ORIGINAL_PRICE = 'SET_PLACED_PRODUCT_HIDE_ORIGINAL_PRICE';
export const SET_PLACED_PRODUCT_ENABLE_CUSTOM_PRICE_STYLE = 'SET_PLACED_PRODUCT_ENABLE_CUSTOM_PRICE_STYLE';
export const SET_PLACED_PRODUCT_CUSTOM_PRICE_STYLE_LABEL_SHAPE = 'SET_PLACED_PRODUCT_CUSTOM_PRICE_STYLE_LABEL_SHAPE';
export const SET_PLACED_PRODUCT_CUSTOM_PRICE_STYLE_LABEL_TEXT = 'SET_PLACED_PRODUCT_CUSTOM_PRICE_STYLE_LABEL_TEXT';
export const SET_PLACED_PRODUCT_CUSTOM_PRICE_STYLE_LABEL_PROMO_TEXT = 'SET_PLACED_PRODUCT_CUSTOM_PRICE_STYLE_LABEL_PROMO_TEXT';
export const SET_PLACED_PRODUCT_CUSTOM_PRICE_STYLE_LABEL_BACKGROUND_COLOR = 'SET_PLACED_PRODUCT_CUSTOM_PRICE_STYLE_LABEL_BACKGROUND_COLOR';
export const SET_PLACED_PRODUCT_CUSTOM_PRICE_STYLE_LABEL_BORDER_COLOR = 'SET_PLACED_PRODUCT_CUSTOM_PRICE_STYLE_LABEL_BORDER_COLOR';
export const SET_BACKGROUND_FRAME_BACKGROUND_TYPE = 'SET_BACKGROUND_FRAME_BACKGROUND_TYPE';
export const SET_BACKGROUND_FRAME_BACKGROUND_COLOR = 'SET_BACKGROUND_FRAME_BACKGROUND_COLOR';
export const SET_BACKGROUND_FRAME_BACKGROUND_MEDIA_ITEM_ID = 'SET_BACKGROUND_FRAME_BACKGROUND_MEDIA_ITEM_ID';
export const SET_BACKGROUND_FRAME_BACKGROUND_MEDIA_CROP = 'SET_BACKGROUND_FRAME_BACKGROUND_MEDIA_CROP';
export const TOGGLE_SETTINGS_MENU = 'TOGGLE_SETTINGS_MENU';
export const SET_STYLE_PRICE_LABEL_SHAPE = 'SET_STYLE_PRICE_LABEL_SHAPE';
export const SET_STYLE_PRICE_LABEL_TEXT = 'SET_STYLE_PRICE_LABEL_TEXT';
export const SET_STYLE_PRICE_LABEL_PROMO_TEXT = 'SET_STYLE_PRICE_LABEL_PROMO_TEXT';
export const SET_STYLE_PRICE_LABEL_BACKGROUND_COLOR = 'SET_STYLE_PRICE_LABEL_BACKGROUND_COLOR';
export const SET_STYLE_PRICE_LABEL_BORDER_COLOR = 'SET_STYLE_PRICE_LABEL_BORDER_COLOR';
export const SET_IN_PREVIEW_MODE = 'SET_IN_PREVIEW_MODE';
export const SET_EYEDROPPER_ENABLED = 'SET_EYEDROPPER_ENABLED';
export const SET_EYEDROPPER_TOOL_FRAME_COORDINATES = 'SET_EYEDROPPER_TOOL_FRAME_COORDINATES';
export const SET_EYEDROPPER_TOOL_FRAME_MOUSE_IS_DOWN = 'SET_EYEDROPPER_TOOL_FRAME_MOUSE_IS_DOWN';
export const SET_FRAME_HOTSPOT_TYPE = 'SET_FRAME_HOTSPOT_TYPE';
export const SET_FRAME_HOTSPOT_HIDDEN_SLIDE = 'SET_FRAME_HOTSPOT_HIDDEN_SLIDE';
export const SET_PHARMACIST_ON_DUTY_FRAME_NUM_PHARMACISTS = 'SET_PHARMACIST_ON_DUTY_FRAME_NUM_PHARMACISTS';
export const SET_OPENING_HOURS_FRAME_SHOW_DAY = 'SET_OPENING_HOURS_FRAME_SHOW_DAY';

interface SetAvailableProductsAction {
    type: typeof SET_AVAILABLE_PRODUCTS;
    availableProducts: Domain.SlideshowProduct[];
}

interface UpdateAvailableProductAction {
    type: typeof UPDATE_AVAILABLE_PRODUCT;
    availableProduct: Domain.SlideshowProduct;
}

interface SetProductImageSizeAction {
    type: typeof SET_PRODUCT_IMAGE_SIZE;
    imageUrl: string;
    width: number;
    height: number;
}

interface SetFrameTypeAction {
    type: typeof SET_FRAME_TYPE;
    frameId: string;
    frameType: Frame['type'];
}

interface SetFrameTextAction {
    type: typeof SET_FRAME_TEXT;
    frameId: string;
    text?: string;
    textStyle: TextStyle;
}

interface SetFrameTextStyleAction {
    type: typeof SET_FRAME_TEXT_STYLE;
    frameId: string;
    textStyle: TextStyle;
}

interface SetFrameHtmlAction {
    type: typeof SET_FRAME_HTML;
    frameId: string;
    html?: string;
}

interface SetTextFrameHasStyledBackgroundAction {
    type: typeof SET_TEXT_FRAME_HAS_STYLED_BACKGROUND;
    frameId: string;
    styleBackground: boolean;
}

interface SetTextFrameBackgroundTypeAction {
    type: typeof SET_TEXT_FRAME_BACKGROUND_TYPE;
    frameId: string;
    backgroundType: TextFrame['backgroundType'];
}

interface SetTextFrameBackgroundColorAction {
    type: typeof SET_TEXT_FRAME_BACKGROUND_COLOR;
    frameId: string;
    backgroundColor?: string;
}

interface SetTextFrameBackgroundMediaItemIdAction {
    type: typeof SET_TEXT_FRAME_BACKGROUND_MEDIA_ITEM_ID;
    frameId: string;
    backgroundMediaItemId?: string;
}

interface SetTextFrameBackgroundImageCropAction {
    type: typeof SET_TEXT_FRAME_BACKGROUND_IMAGE_CROP;
    frameId: string;
    backgroundImageCrop?: ImageCrop;
}

interface SetMediaFrameTypeAction {
    type: typeof SET_MEDIA_FRAME_TYPE;
    frameId: string;
    mediaType: MediaFrame['mediaType'];
}

interface SetMediaFrameMediaItemIdAction {
    type: typeof SET_MEDIA_FRAME_MEDIA_ITEM_ID;
    frameId: string;
    mediaItemId?: string;
    videoDuration?: number;
}

interface SetMediaFrameMediaCropAction {
    type: typeof SET_MEDIA_FRAME_MEDIA_CROP;
    frameId: string;
    imageCrop?: ImageCrop;
}

interface SetShelfFrameStyleAction {
    type: typeof SET_SHELF_FRAME_STYLE;
    frameId: string;
    shelfStyle: ShelfFrame['shelfStyle'];
}

interface SetProductFrameProductAction {
    type: typeof SET_PRODUCT_FRAME_PRODUCT;
    frameId: string;
    product: Domain.SlideshowProduct;
}

interface SetProductFrameProductMediaTypeAction {
    type: typeof SET_PRODUCT_FRAME_PRODUCT_MEDIA_TYPE;
    frameId: string;
    productMediaType: ProductFrame['productMediaType'];
}

interface SetFrameHotSpotProductIdAction {
    type: typeof SET_FRAME_HOTSPOT_PRODUCT_ID;
    frameId: string;
    product: Domain.SlideshowProduct;
}

interface SetPlacedProductItemsLimitEnabledAction {
    type: typeof SET_PLACED_PRODUCT_ITEMS_LIMIT_ENABLED;
    frameId: string;
    enabled: boolean;
}

interface SetPlacedProductItemsLimitAction {
    type: typeof SET_PLACED_PRODUCT_ITEMS_LIMIT;
    frameId: string;
    limit: number;
}

interface SetPlacedProductScaleAction {
    type: typeof SET_PLACED_PRODUCT_SCALE;
    frameId: string;
    scale: number;
}

interface SetPlacedProductAlignAction {
    type: typeof SET_PLACED_PRODUCT_ALIGN;
    frameId: string;
    align: ProductFrame['placedProduct']['alignItems'];
}

interface SetPlacedProductXSpacingAction {
    type: typeof SET_PLACED_PRODUCT_X_SPACING;
    frameId: string;
    spacing: number;
}

interface SetPlacedProductYSpacingAction {
    type: typeof SET_PLACED_PRODUCT_Y_SPACING;
    frameId: string;
    spacing: number;
}

interface SetPlacedProductImageIdAction {
    type: typeof SET_PLACED_PRODUCT_IMAGE_ID;
    frameId: string;
    imageId: string;
}

interface SetPlacedProductShowPriceAction {
    type: typeof SET_PLACED_PRODUCT_SHOW_PRICE;
    frameId: string;
    showPrice: boolean;
}

interface SetPlacedProductHideOriginalPriceAction {
    type: typeof SET_PLACED_PRODUCT_HIDE_ORIGINAL_PRICE;
    frameId: string;
    hideOriginalPrice: boolean;
}

interface SetPlacedProductEnableCustomPriceStyleAction {
    type: typeof SET_PLACED_PRODUCT_ENABLE_CUSTOM_PRICE_STYLE;
    frameId: string;
    enableCustomPriceStyling: boolean;
}

interface SetPlacedProductCustomPriceStyleLabelShapeAction {
    type: typeof SET_PLACED_PRODUCT_CUSTOM_PRICE_STYLE_LABEL_SHAPE;
    frameId: string;
    shape: PriceStyleShape;
    promo?: boolean;
}

interface SetPlacedProductCustomPriceStyleLabelTextAction {
    type: typeof SET_PLACED_PRODUCT_CUSTOM_PRICE_STYLE_LABEL_TEXT;
    frameId: string;
    text: TextStyle;
    promo?: boolean;
}

interface SetPlacedProductCustomPriceStyleLabelPromoTextAction {
    type: typeof SET_PLACED_PRODUCT_CUSTOM_PRICE_STYLE_LABEL_PROMO_TEXT;
    frameId: string;
    promoText: TextStyle;
}

interface SetPlacedProductCustomPriceStyleLabelBackgroundColorAction {
    type: typeof SET_PLACED_PRODUCT_CUSTOM_PRICE_STYLE_LABEL_BACKGROUND_COLOR;
    frameId: string;
    backgroundColor: string;
    promo?: boolean;
}

interface SetPlacedProductCustomPriceStyleLabelBorderColorAction {
    type: typeof SET_PLACED_PRODUCT_CUSTOM_PRICE_STYLE_LABEL_BORDER_COLOR;
    frameId: string;
    borderColor: string;
    promo?: boolean;
}

interface SetBackgroundFrameBackgroundTypeAction {
    type: typeof SET_BACKGROUND_FRAME_BACKGROUND_TYPE;
    frameId: string;
    backgroundType: TextFrame['backgroundType'];
}

interface SetBackgroundFrameBackgroundColorAction {
    type: typeof SET_BACKGROUND_FRAME_BACKGROUND_COLOR;
    frameId: string;
    backgroundColor?: string;
}

interface SetBackgroundFrameBackgroundMediaItemIdAction {
    type: typeof SET_BACKGROUND_FRAME_BACKGROUND_MEDIA_ITEM_ID;
    frameId: string;
    backgroundMediaItemId?: string;
}

interface SetBackgroundFrameBackgroundMediaCropAction {
    type: typeof SET_BACKGROUND_FRAME_BACKGROUND_MEDIA_CROP;
    frameId: string;
    backgroundImageCrop?: ImageCrop;
}

interface ToggleSettingsMenuAction {
    type: typeof TOGGLE_SETTINGS_MENU;
    settingsMenuVisible: boolean;
}

interface SetStylePriceLabelShapeAction {
    type: typeof SET_STYLE_PRICE_LABEL_SHAPE;
    shape: PriceStyleShape;
    promo?: boolean;
}

interface SetStylePriceLabelTextAction {
    type: typeof SET_STYLE_PRICE_LABEL_TEXT;
    text: TextStyle;
    promo?: boolean;
}

interface SetStylePriceLabelPromoTextAction {
    type: typeof SET_STYLE_PRICE_LABEL_PROMO_TEXT;
    promoText: TextStyle;
}

interface SetStylePriceLabelBackgroundColorAction {
    type: typeof SET_STYLE_PRICE_LABEL_BACKGROUND_COLOR;
    backgroundColor: string;
    promo?: boolean;
}

interface SetStylePriceLabelBorderColorAction {
    type: typeof SET_STYLE_PRICE_LABEL_BORDER_COLOR;
    borderColor: string;
    promo?: boolean;
}

interface SetInPreviewModeAction {
    type: typeof SET_IN_PREVIEW_MODE;
    inPreviewMode: boolean;
}

interface SetEyedropperEnabled {
    type: typeof SET_EYEDROPPER_ENABLED;
    eyedropperEnabled: boolean;
    changeHandlers?: EyedropperChangeHandlers;
}

interface SetEyedropperToolFrameCoordinates {
    type: typeof SET_EYEDROPPER_TOOL_FRAME_COORDINATES;
    frameId?: string;
    x: number;
    y: number;
    w: number;
    h: number;
}

interface SetEyedropperToolFrameMoseIsDown {
    type: typeof SET_EYEDROPPER_TOOL_FRAME_MOUSE_IS_DOWN;
    frameId?: string;
    isDown: boolean;
}

interface SetFrameHotSpotTypeAction {
    type: typeof SET_FRAME_HOTSPOT_TYPE;
    frameId: string;
    hotSpotType: HotSpotFrame['hotSpotType'];
}

interface SetFrameHotSpotHiddenSlideAction {
    type: typeof SET_FRAME_HOTSPOT_HIDDEN_SLIDE;
    frameId: string;
    hiddenSlideType: HotSpotFrameHiddenSlideType;
    hiddenSlideId: string;
    hiddenSlideName: string;
}

interface SetPharmacistOnDutyFrameNumPharmacistsAction {
    type: typeof SET_PHARMACIST_ON_DUTY_FRAME_NUM_PHARMACISTS;
    frameId: string;
    numPharmacists: number;
}

interface SetOpeningHoursFrameShowDayAction {
    type: typeof SET_OPENING_HOURS_FRAME_SHOW_DAY;
    frameId: string;
    day: keyof OpeningHoursFrame['showDays'];
    show: boolean;
}

export { Actions as CanvasActions } from 'editor-canvas';

export type ActionTypes =
    | CanvasActions.ActionTypes
    | SetAvailableProductsAction
    | UpdateAvailableProductAction
    | SetProductImageSizeAction
    | SetFrameTypeAction
    | SetFrameTextAction
    | SetFrameTextStyleAction
    | SetFrameHtmlAction
    | SetTextFrameHasStyledBackgroundAction
    | SetTextFrameBackgroundTypeAction
    | SetTextFrameBackgroundColorAction
    | SetTextFrameBackgroundMediaItemIdAction
    | SetTextFrameBackgroundImageCropAction
    | SetMediaFrameTypeAction
    | SetMediaFrameMediaItemIdAction
    | SetMediaFrameMediaCropAction
    | SetShelfFrameStyleAction
    | SetProductFrameProductAction
    | SetProductFrameProductMediaTypeAction
    | SetFrameHotSpotProductIdAction
    | SetPlacedProductShowPriceAction
    | SetPlacedProductEnableCustomPriceStyleAction
    | SetPlacedProductItemsLimitEnabledAction
    | SetPlacedProductItemsLimitAction
    | SetPlacedProductScaleAction
    | SetPlacedProductAlignAction
    | SetPlacedProductXSpacingAction
    | SetPlacedProductYSpacingAction
    | SetPlacedProductImageIdAction
    | SetPlacedProductHideOriginalPriceAction
    | SetPlacedProductCustomPriceStyleLabelShapeAction
    | SetPlacedProductCustomPriceStyleLabelTextAction
    | SetPlacedProductCustomPriceStyleLabelPromoTextAction
    | SetPlacedProductCustomPriceStyleLabelBackgroundColorAction
    | SetPlacedProductCustomPriceStyleLabelBorderColorAction
    | SetBackgroundFrameBackgroundTypeAction
    | SetBackgroundFrameBackgroundColorAction
    | SetBackgroundFrameBackgroundMediaItemIdAction
    | SetBackgroundFrameBackgroundMediaCropAction
    | ToggleSettingsMenuAction
    | SetStylePriceLabelShapeAction
    | SetStylePriceLabelTextAction
    | SetStylePriceLabelPromoTextAction
    | SetStylePriceLabelBackgroundColorAction
    | SetStylePriceLabelBorderColorAction
    | SetInPreviewModeAction
    | SetEyedropperEnabled
    | SetEyedropperToolFrameCoordinates
    | SetEyedropperToolFrameMoseIsDown
    | SetFrameHotSpotTypeAction
    | SetFrameHotSpotHiddenSlideAction
    | SetPharmacistOnDutyFrameNumPharmacistsAction
    | SetOpeningHoursFrameShowDayAction;
