import * as React from 'react';

import ControlledDateTimePicker, { ControlledDateTimePickerPropsWithValue } from './ControlledDateTimePicker';
import UncontrolledDateTimePicker, { UncontrolledDateTimePickerProps } from './UncontrolledDateTimePicker';

export type DateTimePickerProps = ControlledDateTimePickerPropsWithValue | UncontrolledDateTimePickerProps;

class DateTimePicker extends React.PureComponent<DateTimePickerProps> {
    render() {
        if (this.isControlled(this.props)) {
            return <ControlledDateTimePicker {...this.props} />;
        }

        return <UncontrolledDateTimePicker {...this.props} />;
    }

    private isControlled(props: DateTimePickerProps): props is ControlledDateTimePickerPropsWithValue {
        return (props as ControlledDateTimePickerPropsWithValue).hasOwnProperty('value');
    }
}

export default React.forwardRef<HTMLInputElement, DateTimePickerProps>((props, ref) => {
    return (
        <DateTimePicker
            forwardRef={ref}
            {...props}
        />
    );
});
