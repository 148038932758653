import { Domain } from 'api';

import { notificationApi } from '@/api';
import { makeActions, makeReducer, makeReducerActions, makeSelectors } from '@/makeOverviewState';

export type NotificationProps = keyof Domain.Notification;

export const defaultSorting: Domain.Sorting<NotificationProps> = {
    field: 'createdOn',
    direction: 'descending',
};

export const pageSize = 15;

export const selectors = makeSelectors<Domain.Notification, NotificationProps>({
    getState: rootState => rootState.platformNotificationOverview,
});

export const reducerActions = makeReducerActions<Domain.Notification, NotificationProps>({
    reducerPrefix: '@platformNotification/overview',
});

export const actions = makeActions<Domain.Notification, NotificationProps>({
    dataTableSaveKey: 'notificationsOverview-v5',
    loadApi: options => notificationApi.GetUserNotifications(options.pagination, options.sorting, options.filters),
    defaultSorting,
    pageSize,
    getBaseUrl: () => '/dashboard/all-notifications',
    reducerActions,
    selectors,
});

export const overviewReducer = makeReducer<Domain.Notification, NotificationProps>({
    defaultSorting,
    pageSize,
    reducerActions,
});
