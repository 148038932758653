import * as React from 'react';

import ControlledRadioGroup, { ControlledRadioGroupProps } from './ControlledRadioGroup';

export type UncontrolledRadioGroupProps = ControlledRadioGroupProps & {
    defaultValue?: string;
};

interface IState {
    value: null | string;
}

class RadioGroup extends React.PureComponent<UncontrolledRadioGroupProps, IState> {
    constructor(props: UncontrolledRadioGroupProps) {
        super(props);

        this.state = {
            value: props.defaultValue || null,
        };
    }

    render() {
        const { children, defaultValue, forwardRef, ...rest } = this.props;

        return (
            <ControlledRadioGroup
                {...rest}
                ref={forwardRef}
                value={this.state.value}
                onChange={this.handleChange}
            >
                {children}
            </ControlledRadioGroup>
        );
    }

    private handleChange = (newValue: string): void => {
        const { onChange } = this.props;

        this.setState(
            {
                value: newValue,
            },
            () => {
                if (onChange && this.state.value !== null) {
                    onChange(this.state.value);
                }
            },
        );
    };
}

export default React.forwardRef<HTMLInputElement, UncontrolledRadioGroupProps>((props, ref) => {
    return (
        <RadioGroup
            forwardRef={ref}
            {...props}
        />
    );
});
