import { wwks2ObjectType } from '../../../Domain';


export function parse2XML(wwksDialog: any): string {
    let stringXML = '';
    for (const tag in wwksDialog) {
        if (Array.isArray(wwksDialog[tag])) {
            for (let i = 0; i < wwksDialog[tag].length; i++) {
                stringXML += '<' + tag;
                const item = wwksDialog[tag][i];
                if (typeof item['@'] !== 'undefined') {
                    for (const attr in item['@']) {
                        stringXML += ' ' + attr + '="' + item['@'][attr] + '"';
                    }
                }
                if (typeof item['#'] !== 'undefined' || typeof item.CDATA !== 'undefined') {
                    stringXML += '>';
                    if (typeof item['#'] !== 'undefined') {
                        for (const child in item['#']) {
                            const parseObject: wwks2ObjectType = {}
                            parseObject[child] = item['#'][child];
                            stringXML += parse2XML(parseObject);
                        }
                    }
                    if (typeof item.CDATA !== 'undefined') {
                        stringXML += '<![CDATA[' + item.CDATA + ']]>';
                    }
                    stringXML += '</' + tag + '>';
                } else {
                    stringXML += '/>';
                }
            }
        } else {
            stringXML += '<' + tag;
            if (typeof wwksDialog[tag]['@'] !== 'undefined') {
                for (const attr in wwksDialog[tag]['@']) {
                    stringXML += ' ' + attr + '="' + wwksDialog[tag]['@'][attr] + '"';
                }
            }
            if (typeof wwksDialog[tag]['#'] !== 'undefined' || typeof wwksDialog[tag].CDATA !== 'undefined') {
                stringXML += '>';
                if (typeof wwksDialog[tag]['#'] !== 'undefined') {
                    for (const child in wwksDialog[tag]['#']) {
                        const parseObject: wwks2ObjectType = {}
                        parseObject[child] = wwksDialog[tag]['#'][child];
                        stringXML += parse2XML(parseObject);
                    }
                }
                if (typeof wwksDialog[tag].CDATA !== 'undefined') {
                    stringXML += '<![CDATA[' + wwksDialog[tag].CDATA + ']]>';
                }
                stringXML += '</' + tag + '>';
            } else {
                stringXML += '/>';
            }
        }
    }
    return stringXML;
}

export function xml2Json(xml: any) {
    let obj: any = {};
    if (xml.nodeType == 1) {
        if (xml.attributes.length > 0) {
            obj['@attributes'] = {};
            for (let j = 0; j < xml.attributes.length; j++) {
                const attribute = xml.attributes.item(j);
                obj['@attributes'][attribute.nodeName] = attribute.nodeValue;
            }
        }
    } else if (xml.nodeType == 3) {
        obj = xml.nodeValue;
    }
    if (xml.hasChildNodes()) {
        for (let i = 0; i < xml.childNodes.length; i++) {
            const item = xml.childNodes.item(i);
            const nodeName = item.nodeName;
            if (typeof (obj[nodeName]) == 'undefined') {
                obj[nodeName] = xml2Json(item);
            } else {
                if (typeof (obj[nodeName].push) == 'undefined') {
                    const old = obj[nodeName];
                    obj[nodeName] = [];
                    obj[nodeName].push(old);
                }
                obj[nodeName].push(xml2Json(item));
            }
        }
    }
    return obj;
}
