import { Domain } from 'api';

import { ThunkAction } from '@/action';
import { webshopOrderShipmentApi } from '@/api';
import { loadConfiguredIntegrationsByType } from '@/Integration/configuredIntegrationsTypeState';
import { makeActions, makeReducer, makeReducerActions, makeSelectors } from '@/makeOverviewState';
import { URLParams, URLQuery } from '@/routing';
import { selectGlobalSelectedWebshopId } from '@/Webshop/globalSelectionState';

import * as shipmentExternalStatuses from './shipmentExternalStatusesState';

type WebshopOrderShipmentProps = keyof Domain.WebshopOrderShipment;

export const defaultSorting: Domain.Sorting<WebshopOrderShipmentProps> = {
    field: 'createdAt',
    direction: 'descending',
};

export const pageSize = 20;

export const selectors = makeSelectors<Domain.WebshopOrderShipment, WebshopOrderShipmentProps>({
    getState: rootState => rootState.webshopOrderShipment.overview,
});

export const reducerActions = makeReducerActions<Domain.WebshopOrderShipment, WebshopOrderShipmentProps>({
    reducerPrefix: '@webshopOrderShipment/overview',
});

const baseActions = makeActions<Domain.WebshopOrderShipment, WebshopOrderShipmentProps>({
    dataTableSaveKey: 'webshopOrderShipmentsOverview-v5',
    loadApi: options => {
        return webshopOrderShipmentApi.GetWebshopOrderShipments(
            options.ownership,
            options.pagination,
            options.sorting,
            {
                ...(options.filters ? options.filters : {}),
                webshopId: options.filters.webshopId ? options.filters.webshopId : options.globallySelectedWebshopId,
            },
            options.search,
        );
    },
    defaultSorting,
    pageSize,
    getBaseUrl: () => '/webshops/shipments',
    reducerActions,
    selectors,
});

export const actions = {
    ...baseActions,
    load:
        (options: { urlParams: URLParams; urlQuery: URLQuery }): ThunkAction =>
        async (dispatch, getState) => {
            const state = getState();
            const globallySelectedWebshopId = selectGlobalSelectedWebshopId(state);

            await Promise.all([
                dispatch(baseActions.load(options)),
                dispatch(loadConfiguredIntegrationsByType('sendShipments')),
                globallySelectedWebshopId ? dispatch(shipmentExternalStatuses.load(globallySelectedWebshopId)) : Promise.resolve(),
            ]);
        },
};

export const overviewReducer = makeReducer<
    Domain.WebshopOrderShipment,
    WebshopOrderShipmentProps,
    {
        webshopId?: string;
        orderId?: string;
        shipmentStatus?: string;
        carrierCode?: string;
        externalReferenceId?: string;
    }
>({
    defaultSorting,
    pageSize,
    reducerActions,
});
