import {
    Pagination,
    Sorting,
    Locale,
    Webshop,
    WebshopFaqCategoriesPage,
    WebshopFaqCategory,
    WebshopFaqCategoryDetails,
    UpdateWebshopFaqCategoryPayload,
    CreateWebshopFaqCategoryPayload,
    WebshopFaqItemsPage,
    WebshopFaqItem,
    WebshopFaqItemDetails,
    CreateWebshopFaqItemPayload,
    UpdateWebshopFaqItemPayload,
} from '@/Domain';

import Endpoint from './Endpoint';

export default class WebshopFaqEndpoint extends Endpoint {
    public async GetWebshopFaqCategories(
        pagination: Pagination,
        sorting: Sorting,
        webshopId: Webshop['webshopId'],
    ): Promise<WebshopFaqCategoriesPage> {
        const url = `/webshop/${webshopId}/faq-category/overview`;

        const response = await this.client.get({
            url,
            params: {
                pagination,
                sorting,
            },
        });

        this.guardResponseSchema('#/definitions/WebshopFaqCategoriesPage', response);

        return response;
    }

    public async GetWebshopFaqCategoryDetails(
        webshopId: Webshop['webshopId'],
        faqCategoryId: WebshopFaqCategory['faqCategoryId'],
    ): Promise<WebshopFaqCategoryDetails> {
        const response = await this.client.get({
            url: `/webshop/${webshopId}/faq-category/${faqCategoryId}/details`,
        });

        this.guardResponseSchema('#/definitions/WebshopFaqCategoryDetails', response);

        return response;
    }

    public async CreateWebshopFaqCategory(payload: CreateWebshopFaqCategoryPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/CreateWebshopFaqCategory',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Webshop\\Faq\\FaqCategory\\UpdateFaqCategory\\UpdateFaqCategory',
                payload,
            },
        });
    }

    public async UpdateWebshopFaqCategory(payload: UpdateWebshopFaqCategoryPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/UpdateWebshopFaqCategory',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Webshop\\Faq\\FaqCategory\\UpdateFaqCategory\\UpdateFaqCategory',
                payload,
            },
        });
    }

    public async GetWebshopFaqItems(
        pagination: Pagination,
        sorting: Sorting,
        webshopId: Webshop['webshopId'],
        locale: Locale,
        filters?: {
            faqCategoryIds?: string;
        },
    ): Promise<WebshopFaqItemsPage> {
        const url = `/webshop/${webshopId}/faq-item/overview/${locale}`;

        const response = await this.client.get({
            url,
            params: {
                pagination,
                sorting,
                filters: filters
                    ? {
                          ...filters,
                          faqCategoryIds: filters.faqCategoryIds ? filters.faqCategoryIds.split(',') : undefined,
                      }
                    : undefined,
            },
        });

        this.guardResponseSchema('#/definitions/WebshopFaqItemsPage', response);

        return response;
    }

    public async GetWebshopFaqItemDetails(
        webshopId: Webshop['webshopId'],
        faqItemId: WebshopFaqItem['faqItemId'],
    ): Promise<WebshopFaqItemDetails> {
        const response = await this.client.get({
            url: `/webshop/${webshopId}/faq-item/${faqItemId}/details`,
        });

        this.guardResponseSchema('#/definitions/WebshopFaqItemDetails', response);

        return response;
    }

    public async CreateWebshopFaqItem(payload: CreateWebshopFaqItemPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/CreateWebshopFaqItem',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Webshop\\Faq\\FaqItem\\UpdateFaqItem\\UpdateFaqItem',
                payload,
            },
        });
    }

    public async UpdateWebshopFaqItem(payload: UpdateWebshopFaqItemPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/UpdateWebshopFaqItem',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Webshop\\Faq\\FaqItem\\UpdateFaqItem\\UpdateFaqItem',
                payload,
            },
        });
    }
}
