import { createAction, Selector } from '@reduxjs/toolkit';

import { Domain } from 'api';

import { withPayloadType } from '@/action';
import { webshopFaqLibraryApi } from '@/api';
import { makeActions, makeReducer, makeReducerActions, makeSelectors } from '@/makeOverviewState';
import { RootState } from '@/store';

type WebshopFaqLibraryItemProps = keyof Domain.WebshopFaqItem;

export const defaultSorting: Domain.Sorting<WebshopFaqLibraryItemProps> = {
    field: 'sortOrder',
    direction: 'ascending',
};

export const pageSize = 20;

export const setLocale = createAction('@webshopFaqItemLibrary/overview/setLocale', withPayloadType<Domain.Locale>());
export const selectLocale: Selector<RootState, Domain.Locale | undefined> = state => state.webshopFaqItemLibrary.overview.locale;

export const selectors = makeSelectors<Domain.WebshopFaqItem, WebshopFaqLibraryItemProps>({
    getState: rootState => rootState.webshopFaqItemLibrary.overview,
});

export const reducerActions = makeReducerActions<Domain.WebshopFaqItem, WebshopFaqLibraryItemProps>({
    reducerPrefix: '@webshopFaqLibraryItem/overview',
});

export const actions = makeActions<Domain.WebshopFaqItem, WebshopFaqLibraryItemProps>({
    dataTableSaveKey: 'webshopFaqItemsLibraryOverview-v5',
    loadApi: options => {
        return webshopFaqLibraryApi.GetWebshopFaqLibraryItems(
            options.pagination,
            options.sorting,
            selectLocale(options.state) || options.globallySelectedWebshopLocale || options.globallySelectedWebshopDefaultLocale || 'nl_BE',
        );
    },
    defaultSorting,
    pageSize,
    getBaseUrl: () => '/webshops/faq-items-library',
    reducerActions,
    selectors,
});

export const overviewReducer = makeReducer<
    Domain.WebshopFaqItem,
    WebshopFaqLibraryItemProps,
    {
        locale?: Domain.Locale;
    }
>({
    defaultSorting,
    pageSize,
    reducerActions,
    extraCases: builder =>
        builder.addCase(setLocale, (state, action) => {
            state.locale = action.payload;
        }),
});
