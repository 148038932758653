import { createAction, Selector, createReducer } from '@reduxjs/toolkit';

import { Domain } from 'api';

import { ThunkAction, withPayloadType } from '@/action';
import { webshopApi, webshopNewsArticleApi } from '@/api';
import { URLParams } from '@/routing';
import { RootState } from '@/store';

export interface State {
    webshop?: Domain.Webshop;
    article?: Domain.WebshopNewsArticleDetails;
}

const initialState: State = {};

const reducerActions = {
    setWebshop: createAction('@webshopNewsArticle/update/setWebshop', withPayloadType<Domain.Webshop>()),
    setArticle: createAction('@webshopNewsArticle/update/setArticle', withPayloadType<Domain.WebshopNewsArticleDetails>()),
};

export const updateReducer = createReducer(initialState, builder =>
    builder
        .addCase(reducerActions.setWebshop, (state, action) => {
            state.webshop = action.payload;
        })
        .addCase(reducerActions.setArticle, (state, action) => {
            state.article = action.payload;
        }),
);

export const selectWebshop: Selector<RootState, Domain.Webshop> = state => {
    const webshop = state.webshopNewsArticle.update.webshop;
    if (!webshop) {
        throw new Error('Webshop not loaded');
    }

    return webshop;
};

export const selectNewsArticle: Selector<RootState, Domain.WebshopNewsArticleDetails> = state => {
    const article = state.webshopNewsArticle.update.article;
    if (!article) {
        throw new Error('News article not loaded');
    }

    return article;
};

export const loadWebshop =
    (webshopId: string): ThunkAction<Promise<Domain.Webshop>> =>
    async dispatch => {
        const webshop = await webshopApi.GetWebshopDetails(webshopId);

        await dispatch(reducerActions.setWebshop(webshop));

        return webshop;
    };

export const loadNewsArticle =
    (webshopId: string, articleId: string): ThunkAction<Promise<Domain.WebshopNewsArticleDetails>> =>
    async dispatch => {
        const article = await webshopNewsArticleApi.GetWebshopNewsArticleDetails(webshopId, articleId);

        await dispatch(reducerActions.setArticle(article));

        return article;
    };

export const load =
    (options: { urlParams: URLParams }): ThunkAction =>
    async dispatch => {
        await Promise.all([
            dispatch(loadWebshop(options.urlParams.webshopId)),
            dispatch(loadNewsArticle(options.urlParams.webshopId, options.urlParams.articleId)),
        ]);
    };
