import DelegatedEventTarget from './DelegatedEventTarget';
import Peer, { PeerInfo } from './Peer';

export type TunnelSide = 'initiate' | 'expect';
type ConnectionStatus = 'closed' | 'connected' | 'connecting';

export default class PeerTunnel extends DelegatedEventTarget {
    private readonly peer: Peer;
    private otherPeerInfo: PeerInfo['peerMeta'] | undefined;
    private readonly toPeerId: string;
    private readonly tunnelSide: TunnelSide;
    private readonly maxEstablishTime: number;
    private readonly tunnelPrefix: string;
    private retryConnection: boolean;
    private readonly onConnectionFailure?: (e: Event) => void;
    private connectionTimeout: ReturnType<typeof setTimeout> | undefined;
    private connectionStatus: ConnectionStatus = 'closed';
    private otherSideIsAvailable: boolean;

    public constructor(
        peer: Peer,
        toPeerId: string,
        options: {
            tunnelPrefix?: string;
            tunnelSide: TunnelSide;
            maxEstablishTime?: number;
            retryConnection?: boolean;
            onConnectionFailure?: (e: Event) => void;
        },
    ) {
        super();

        this.peer = peer;
        this.toPeerId = toPeerId;
        this.tunnelSide = options.tunnelSide;
        this.maxEstablishTime = options.maxEstablishTime !== undefined ? options.maxEstablishTime : 10000;
        this.retryConnection = options.retryConnection || false;
        this.onConnectionFailure = options.onConnectionFailure;
        this.tunnelPrefix = options.tunnelPrefix || '';

        this.peer.joinGroup(this.getTunnelId());
        this.peer.setGroupMetaData(this.getTunnelId(), {
            ...this.peer.getInfo().peerMeta,
            isInitiator: this.tunnelSide === 'initiate',
        });

        this.peer.addEventListener('groupChanged', this.handleGroupChanged);
        this.peer.addEventListener('message', this.handleMessage);
        this.peer.addEventListener('disconnected', this.handlePeerDisconnected);

        this.startTunnel();
    }

    private handleGroupChanged = (event: MessageEvent) => {
        const data = JSON.parse(event.data);
        if (data.payload.groupId === this.getTunnelId()) {
            const otherSide = data.payload.peers.find((otherPeer: any) => {
                return otherPeer.peerId === this.toPeerId && otherPeer.peerMeta;
            });

            const otherSideIsInGroup = !!otherSide;
            this.otherPeerInfo = otherSide?.peerMeta;

            if (!this.otherSideIsAvailable && otherSideIsInGroup) {
                clearTimeout(this.connectionTimeout);
                this.otherSideIsAvailable = true;
                this.setConnectionStatus('connected');
            } else if (this.otherSideIsAvailable && !otherSideIsInGroup) {
                this.otherSideIsAvailable = false;
                this.setConnectionStatus('closed');
                clearTimeout(this.connectionTimeout);

                if (this.retryConnection) {
                    this.startTunnel();
                } else {
                    this.removeAllEventListeners();
                    if (this.tunnelSide === 'expect') {
                        this.peer.leaveGroup(this.getTunnelId());
                    }
                }
            }
        }
    };

    private handleMessage = (event: MessageEvent) => {
        const data = JSON.parse(event.data);
        if (data.action === 'message' && data.payload?.from === this.toPeerId && data.payload?.to === this.peer.id) {
            this.dispatchEvent(
                new MessageEvent('message', {
                    data: event.data,
                }),
            );
        }
    };

    private setConnectionStatus(newStatus: ConnectionStatus): void {
        if (this.connectionStatus === newStatus) {
            return;
        }

        this.connectionStatus = newStatus;
        this.dispatchEvent(new Event('connectionStatusChanged'));
    }

    public getConnectionStatus(): ConnectionStatus {
        return this.connectionStatus;
    }

    public getOtherPeerInfo() {
        return this.otherPeerInfo;
    }

    private startTunnel() {
        this.otherSideIsAvailable = false;
        this.setConnectionStatus('connecting');

        if (this.tunnelSide === 'initiate') {
            this.initiateTunnel();
        } else {
            this.expectTunnel();
        }
    }

    private initiateTunnel() {
        this.send({
            expectTunnel: true,
            prefix: this.tunnelPrefix,
        });

        clearTimeout(this.connectionTimeout);
        this.connectionTimeout = setTimeout(() => {
            if (!this.otherSideIsAvailable) {
                if (this.retryConnection) {
                    this.initiateTunnel();
                } else if (this.onConnectionFailure) {
                    this.onConnectionFailure(new ErrorEvent(`Failed to establish PeerTunnel ${this.getTunnelId()}`));
                }
            }
        }, this.maxEstablishTime);
    }

    private expectTunnel() {
        clearTimeout(this.connectionTimeout);
        this.connectionTimeout = setTimeout(() => {
            if (!this.otherSideIsAvailable) {
                if (this.retryConnection) {
                    this.expectTunnel();
                } else if (this.onConnectionFailure) {
                    this.onConnectionFailure(new ErrorEvent(`Failed to establish PeerTunnel ${this.getTunnelId()}`));
                }
            } else {
                this.setConnectionStatus('connected');
            }
        }, this.maxEstablishTime);
    }

    public close() {
        clearTimeout(this.connectionTimeout);
        this.retryConnection = false;
        this.removeAllEventListeners();
        if (this.peer.isConnected()) {
            this.peer.leaveGroup(this.getTunnelId());
        }
        this.setConnectionStatus('closed');
    }

    private handlePeerDisconnected = (): void => {
        this.setConnectionStatus('closed');
    };

    private removeAllEventListeners() {
        this.peer.removeEventListener('groupChanged', this.handleGroupChanged);
        this.peer.removeEventListener('message', this.handleMessage);
        this.peer.removeEventListener('disconnected', this.handlePeerDisconnected);
    }

    public send(message: object): void {
        const data = {
            action: 'message',
            payload: {
                from: this.peer.id,
                to: this.toPeerId,
                message,
            },
        };

        this.peer.send(data);
    }

    public getInitiatingPeerId(): string {
        if (this.tunnelSide === 'initiate') {
            return this.peer.id;
        } else {
            return this.toPeerId;
        }
    }

    public getExpectingPeerId(): string {
        if (this.tunnelSide === 'expect') {
            return this.peer.id;
        } else {
            return this.toPeerId;
        }
    }

    public getTunnelId(): string {
        return `tunnel:${this.tunnelPrefix ? this.tunnelPrefix + ':' : ''}${this.getInitiatingPeerId()}_${this.getExpectingPeerId()}`;
    }
}
