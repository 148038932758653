/* eslint-disable prefer-spread */
export default class DelegatedEventTarget implements EventTarget {
    private delegate = document.createDocumentFragment();
    private allRegisteredListeners: any[] = [];

    addEventListener(...args: any): void {
        this.delegate.addEventListener.apply(this.delegate, args);
        this.allRegisteredListeners.push(args);
    }

    dispatchEvent(...args: any): boolean {
        return this.delegate.dispatchEvent.apply(this.delegate, args);
    }

    removeEventListener(...args: any): void {
        return this.delegate.removeEventListener.apply(this.delegate, args);
    }

    removeAllRegisteredEventListeners(): void {
        for (const args of this.allRegisteredListeners) {
            return this.delegate.removeEventListener.apply(this.delegate, args);
        }

        this.allRegisteredListeners = [];
    }
}
