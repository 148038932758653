import * as React from 'react';

import classNames from 'classnames';

import { InputBase, InputBaseProps } from '@/core';

import styles from './Input.scss';

interface BaseProps {
    defaultValue?: string;
    placeholder?: string;
    value?: string;
    active?: boolean;
    iconLeft?: React.ReactNode;
    iconRight?: React.ReactNode;
    buttonRight?: React.ReactNode;
    onChange?: (newValue: string) => void;
    forwardRef?: React.Ref<HTMLInputElement>;
    onChangeStart?: () => void;
    allowLastPass?: boolean;
    small?: boolean;
}

type NativeProps = Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'css'>;

export type InputProps = NativeProps &
    BaseProps &
    (
        | {
              type?: 'text' | 'password' | 'email' | 'phone' | 'hidden' | 'tel';
          }
        | {
              type: 'number';
              min?: number;
              max?: number;
          }
    );

interface IState {
    changeInProgress: boolean;
}

class Input extends InputBase<InputProps, IState> {
    private changeTimeout: ReturnType<typeof setTimeout> | undefined = undefined;

    constructor(props: InputProps) {
        super(props);

        this.state = {
            changeInProgress: false,
        };
    }

    protected renderInput(): React.ReactNode {
        const {
            type,
            name,
            label,
            defaultValue,
            active,
            placeholder,
            value,
            disabled,
            forwardRef,
            iconLeft,
            iconRight,
            buttonRight,
            onChangeStart,
            onChange,
            error,
            errorMessage,
            description,
            highlight,
            allowLastPass,
            small,
            ...rest
        } = this.props;

        const numberInputProps: React.InputHTMLAttributes<HTMLInputElement> = {};

        if (this.props.type === 'number') {
            numberInputProps.min = this.props.min;
            numberInputProps.max = this.props.max;
        }

        return (
            <>
                <div className={styles.InputBaseWrapperInnerInner}>
                    {this.renderIconLeft()}

                    <input
                        {...rest}
                        ref={forwardRef}
                        type={type || 'text'}
                        key={name}
                        name={name}
                        style={this.props.style}
                        className={classNames(styles.Input, active ? styles.active : null, small ? styles.small : null)}
                        placeholder={placeholder}
                        value={value}
                        defaultValue={defaultValue}
                        disabled={disabled}
                        onChange={this.handleChange}
                        {...numberInputProps}
                        data-lpignore={allowLastPass ? undefined : true}
                        data-test-id={this.getDataTestId()}
                    />

                    {this.renderIconRight()}
                </div>

                {this.renderButtonRight()}
            </>
        );
    }

    protected getClassName(): string {
        const { iconLeft, iconRight, buttonRight } = this.props;

        return classNames(
            super.getClassName(),
            iconLeft ? styles['with-icon-left'] : null,
            iconRight ? styles['with-icon-right'] : null,
            buttonRight ? styles['with-button-right'] : null,
        );
    }

    private renderIconRight() {
        const { iconRight } = this.props;

        if (!iconRight) {
            return null;
        }

        return <span className={styles.InputIconRight}>{iconRight}</span>;
    }

    private renderIconLeft() {
        const { iconLeft } = this.props;

        if (!iconLeft) {
            return null;
        }

        return <span className={styles.InputIconLeft}>{iconLeft}</span>;
    }

    private renderButtonRight() {
        const { buttonRight } = this.props;

        if (!buttonRight) {
            return null;
        }

        return <span className={styles.InputButtonRight}>{buttonRight}</span>;
    }

    private handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        if (this.props.onChangeStart) {
            if (this.changeTimeout) {
                clearTimeout(this.changeTimeout);
            }

            this.changeTimeout = setTimeout(() => {
                this.setState({
                    changeInProgress: false,
                });
            }, 500);

            if (!this.state.changeInProgress) {
                this.setState({
                    changeInProgress: true,
                });

                this.props.onChangeStart();
            }
        }

        if (this.props.onChange) {
            this.props.onChange(event.target.value);
        }
    };
}

export default React.forwardRef<HTMLInputElement, InputProps & InputBaseProps>((props, ref) => {
    return (
        <Input
            forwardRef={ref}
            {...props}
        />
    );
});
