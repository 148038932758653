import { createAction, createReducer, Selector } from '@reduxjs/toolkit';

import { Domain } from 'api';

import { ThunkAction, withPayloadType } from '@/action';
import { branchApi } from '@/api';
import { selectLoggedInUserOwnershipIds } from '@/Authentication';
import { RootState } from '@/store';

export interface State {
    branch?: Domain.BranchDetails;
}

const initialState: State = {};

const reducerActions = {
    setBranch: createAction('@customPage/createAndUpdate/setBranch', withPayloadType<Domain.BranchDetails>()),
};

export const createAndUpdateReducer = createReducer(initialState, builder =>
    builder.addCase(reducerActions.setBranch, (state, action) => {
        state.branch = action.payload;
    }),
);

export const maybeSelectBranch: Selector<RootState, Domain.BranchDetails | undefined> = state => state.customPage.createAndUpdate.branch;

export const loadBranch = (): ThunkAction => async (dispatch, getState) => {
    const state = getState();
    const ownershipIds = selectLoggedInUserOwnershipIds(state);

    if (!ownershipIds.branchId) {
        return;
    }

    const branch = await branchApi.GetBranchDetails(ownershipIds.branchId);
    dispatch(reducerActions.setBranch(branch));
};
