import { createAction, createReducer, Selector } from '@reduxjs/toolkit';

import { Domain } from 'api';

import { ThunkAction, withPayloadType } from '@/action';
import { sharingGroupApi } from '@/api';
import { URLParams } from '@/routing';
import { RootState } from '@/store';

export interface State {
    sharingGroup?: Domain.SharingGroupDetails;
}

const initialState: State = {};

const reducerActions = {
    setSharingGroup: createAction('@sharingGroup/update/sharingGroup', withPayloadType<Domain.SharingGroupDetails>()),
};

export const updateReducer = createReducer(initialState, builder =>
    builder.addCase(reducerActions.setSharingGroup, (state, action) => {
        state.sharingGroup = action.payload;
    }),
);

export const selectSharingGroup: Selector<RootState, Domain.SharingGroupDetails> = state => {
    const sharingGroup = state.sharingGroup.update.sharingGroup;
    if (!sharingGroup) {
        throw new Error('Sharing group details not loaded');
    }
    return sharingGroup;
};

export const loadSharingGroup =
    (sharingGroupId: Domain.SharingGroup['sharingGroupId']): ThunkAction =>
    async dispatch => {
        const sharingGroup = await sharingGroupApi.GetSharingGroupDetails(sharingGroupId);
        dispatch(reducerActions.setSharingGroup(sharingGroup));
    };

export const load =
    (options: { urlParams: URLParams }): ThunkAction =>
    async dispatch => {
        await dispatch(loadSharingGroup(options.urlParams.sharingGroupId));
    };
