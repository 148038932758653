import { Domain } from 'api';

import { exportApi } from '@/api';
import { makeActions, makeReducer, makeReducerActions, makeSelectors } from '@/makeOverviewState';

type SearchActionsExportProps = keyof Domain.Export;

const defaultSorting: Domain.Sorting<SearchActionsExportProps> = {
    field: 'initializedOn',
    direction: 'descending',
};

const pageSize = 20;

export const selectors = makeSelectors<Domain.Export, SearchActionsExportProps>({
    getState: rootState => rootState.searchActionsExport.overview,
});

export const reducerActions = makeReducerActions<Domain.Export, SearchActionsExportProps>({
    reducerPrefix: '@orderExport/overview',
});

export const actions = makeActions<Domain.Export, SearchActionsExportProps>({
    dataTableSaveKey: 'searchActionsExportsOverview-v5',
    loadApi: options => exportApi.GetExports(options.pagination, options.sorting, { exportType: 'searchActionExport' }),
    defaultSorting,
    pageSize,
    getBaseUrl: () => '/localisation/search-actions/export',
    reducerActions,
    selectors,
});

export const overviewReducer = makeReducer<Domain.Export, SearchActionsExportProps>({
    defaultSorting,
    pageSize,
    reducerActions,
});
