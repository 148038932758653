import { GS1DigitalLinkToolkit } from './GS1DigitalLinkToolkit';

const gs1tk = new GS1DigitalLinkToolkit();

export function parseFMD(fmd: string) {
    try {
        const elements = gs1tk.extractFromGS1elementStrings(fmd.replace(/\\x1D/g, gs1tk.groupSeparator));
        return {
            fmd,
            pc: elements['01'],
            lot: elements['10'],
            exp: elements['17'],
            sn: elements['21'],
        };
    } catch (e) {
        console.info('failed parsing FMD', fmd, e);
    }
}

// const TEST_DATA = [
//     { gs1: '010335271200832217141100107654321D\x1D2110987654d3\x1D71010389358', pc: '03352712008322', exp: '141100', lot: '7654321D', sn: '10987654d3' },
//     { gs1: '010415123456789321METUAAACMAARN3\x1D1723013110VISIOTT', pc: '04151234567893', exp: '230131', lot: 'VISIOTT', sn: 'METUAAACMAARN3' },
//     { gs1: '0100312345678906211BALRDF3MVQFF6\x1D1723013110VISIOTT', pc: '00312345678906', exp: '230131', lot: 'VISIOTT', sn: '1BALRDF3MVQFF6' },
//     { gs1: '01046012345678932113JAKKAC1UUHE91ffd0927Cron83W0zfYCOyKABSpj8EMZDnF3F1yz7O3i2H++XE=', pc: '04601234567893', exp: '?', lot: '?', sn: '?' },
//     { gs1: '01078912345678957131234567890123\x1D21METAAUKCCAURDL\x1D1723013110VISIOTT', pc: '07891234567895', exp: '230131', lot: 'VISIOTT', sn: 'METAAUKCCAURDL' },
//     { gs1: '010778123456789921CEJAUKACCAUHNR\x1D10VISIOTT\x1D17230101', pc: '07781234567899', exp: '230101', lot: 'VISIOTT', sn: 'CEJAUKACCAUHNR' },
//     { gs1: '010628123456789521LB1BG1VL4HIUB\x1D1723013110VISIOTT', pc: '06281234567895', exp: '230131', lot: 'VISIOTT', sn: 'LB1BG1VL4HIUB' },
//     { gs1: '0105055037402711172411301022K154', pc: '05055037402711', exp: '241130', lot: '22K154', sn: '?' },
//     { gs1: '010868198606002221104001001\x1D1725013110TRT001', pc: '08681986060022', exp: '250131', lot: 'TRT001', sn: '104001001' },
//     { gs1: '010035914801113421100000000001\x1D1715082210AB100613', pc: '00359148011134', exp: '150822', lot: 'AB100613', sn: '100000000001' },
//     { gs1: '010415012345678217151231101A234B5\x1D211234567890123456', pc: '04150123456782', exp: '151231', lot: '1A234B5', sn: '1234567890123456' },
//     { gs1: '011648111711648710TESTA73AC8E145\x1D1723121821PK00212312AD208C946', pc: '16481117116487', exp: '231218', lot: 'TESTA73AC8E145', sn: 'PK00212312AD208C946' },
//     { gs1: '0105400581001017102968\x1D17251031211ALEAU5G9P', pc: '05400581001017', exp: '251031', lot: '?', sn: '1ALEAU5G9P'},
//     { gs1: '0109504000059118171411001076543217\x1D2110987654d371010389358', pc: '09504000059118', exp: '141100', lot: '76543217', sn: '10987654d371010389358' },
//     { gs1: '171411001076543217\x1D2110987654d371010389358\x1D0109504000059118', pc: '09504000059118', exp: '141100', lot: '76543217', sn: '10987654d371010389358' }
// ];

// function expectEqual(a: any, b: any) {
//     if (a !== b) {
//         console.error('Expected', a, 'to be equal to', b);
//     }
// }

// function runTests() {
//     for (const TEST of TEST_DATA) {
//         try {
//             const res = parseFMD(TEST.gs1);

//             console.info(TEST.gs1, '-> GTIN:', res.pc);
//             expectEqual(res.pc, TEST.pc);

//             if (TEST.exp !== '?') {
//                 expectEqual(res.exp, TEST.exp);
//             }

//             if (TEST.sn !== '?') {
//                 expectEqual(res.sn, TEST.sn);
//             }

//             if (TEST.lot !== '?') {
//                 expectEqual(res.lot, TEST.lot);
//             }
//         }
//         catch (e) {
//             console.error(e);
//         }
//     }
// }

// runTests();
