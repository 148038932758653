import * as React from 'react';

import classNames from 'classnames';

import { TouchAndMouseDragHandler, TouchAndMouseMove } from 'ui-components';

import { store, Actions } from '../state';
import { CanvasFrame } from '../types';
import { computeFrameSnapPoints } from '../utils';

import styles from './styles/FrameResizer.scss';

interface IProps {
    className?: string;
    style?: React.CSSProperties;

    frame: CanvasFrame;
    noMiddle?: boolean;
}

interface IState {
    sideMoved: string;
    isInitialMove: boolean;
}

export default class FrameResizer extends React.PureComponent<IProps, IState> {
    static contextType = store;
    declare context: React.ContextType<typeof store>;
    private moveHandler: TouchAndMouseDragHandler;
    private minHeight = 20;

    constructor(props: IProps) {
        super(props);

        this.state = {
            sideMoved: '',
            isInitialMove: true,
        };

        this.moveHandler = new TouchAndMouseDragHandler<string>(this.handleMove, this.handleMoveStart, this.handleMoveEnd, true);

        this.moveHandler.setSnapDistance(3);
    }

    render() {
        const { className, style, frame, noMiddle } = this.props;
        const { resizedFrameId } = this.context.state;

        const resizingSelf = resizedFrameId && resizedFrameId === frame.frameId;

        return (
            <span
                className={classNames(styles.FrameResizer, className, resizingSelf ? styles.FrameResizerActive : null)}
                style={style}
                onContextMenu={event => event.preventDefault()}
            >
                <FrameResizerKnob
                    className={styles.FrameResizerTop}
                    position="top"
                    onMouseDown={this.moveHandler.handleMouseDown}
                    onTouchStart={this.moveHandler.handleTouchStart}
                />
                <FrameResizerKnob
                    className={styles.FrameResizerLeft}
                    position="left"
                    onMouseDown={this.moveHandler.handleMouseDown}
                    onTouchStart={this.moveHandler.handleTouchStart}
                />
                <FrameResizerKnob
                    className={styles.FrameResizerRight}
                    position="right"
                    onMouseDown={this.moveHandler.handleMouseDown}
                    onTouchStart={this.moveHandler.handleTouchStart}
                />
                <FrameResizerKnob
                    className={styles.FrameResizerBottom}
                    position="bottom"
                    onMouseDown={this.moveHandler.handleMouseDown}
                    onTouchStart={this.moveHandler.handleTouchStart}
                />
                <FrameResizerKnob
                    className={styles.FrameResizerTopLeft}
                    position="top-left"
                    onMouseDown={this.moveHandler.handleMouseDown}
                    onTouchStart={this.moveHandler.handleTouchStart}
                />
                <FrameResizerKnob
                    className={styles.FrameResizerTopRight}
                    position="top-right"
                    onMouseDown={this.moveHandler.handleMouseDown}
                    onTouchStart={this.moveHandler.handleTouchStart}
                />
                <FrameResizerKnob
                    className={styles.FrameResizerBottomLeft}
                    position="bottom-left"
                    onMouseDown={this.moveHandler.handleMouseDown}
                    onTouchStart={this.moveHandler.handleTouchStart}
                />
                <FrameResizerKnob
                    className={styles.FrameResizerBottomRight}
                    position="bottom-right"
                    onMouseDown={this.moveHandler.handleMouseDown}
                    onTouchStart={this.moveHandler.handleTouchStart}
                />
                {!noMiddle ? (
                    <FrameResizerKnob
                        className={styles.FrameResizerMiddle}
                        position="middle"
                        onMouseDown={this.moveHandler.handleMouseDown}
                        onTouchStart={this.moveHandler.handleTouchStart}
                    />
                ) : null}
            </span>
        );
    }

    private handleMoveStart = (_1: any, side: string) => {
        const { state, dispatch } = this.context;
        const { frame } = this.props;

        this.setState(
            {
                sideMoved: side,
                isInitialMove: true,
            },
            () => {
                if (state.editedFrameId !== frame.frameId) {
                    dispatch({
                        type: Actions.SET_EDITED_FRAME,
                        editedFrameId: undefined,
                    });
                }

                dispatch({
                    type: Actions.SET_RESIZED_FRAME,
                    resizedFrameId: frame.frameId,
                });
            },
        );
    };

    private handleMoveEnd = () => {
        this.setState({
            sideMoved: '',
            isInitialMove: true,
        });
    };

    private handleMove = (move: TouchAndMouseMove) => {
        const { state, dispatch } = this.context;
        const { sideMoved, isInitialMove } = this.state;
        const { frame } = this.props;

        this.moveHandler.setSnapPoints(
            computeFrameSnapPoints(state.canvas.frames, frame.frameId, 0, state.canvas.width, 0, state.canvas.height),
        );

        this.moveHandler.snapMove(move, [
            { point: frame.width + frame.x },
            { point: frame.x },
            { point: frame.height + frame.y, vertical: true },
            { point: frame.y, vertical: true },
        ]);

        if (sideMoved === 'top' || sideMoved === 'top-left' || sideMoved === 'top-right') {
            const offset = move.movementY;

            if (offset && frame.y + offset >= 0 && frame.height - offset >= this.minHeight) {
                if (isInitialMove) {
                    dispatch({
                        type: Actions.HISTORY_SAVE,
                    });

                    this.setState({
                        isInitialMove: false,
                    });
                }

                dispatch({
                    type: Actions.SET_FRAME_TOP_OFFSET,
                    frameId: frame.frameId,
                    offset,
                });
            }
        }

        if (sideMoved === 'left' || sideMoved === 'top-left' || sideMoved === 'bottom-left') {
            const offset = move.movementX;

            if (offset && frame.x + offset >= 0 && frame.width - offset >= this.minHeight) {
                if (isInitialMove) {
                    dispatch({
                        type: Actions.HISTORY_SAVE,
                    });

                    this.setState({
                        isInitialMove: false,
                    });
                }

                dispatch({
                    type: Actions.SET_FRAME_LEFT_OFFSET,
                    frameId: frame.frameId,
                    offset,
                });
            }
        }

        if (sideMoved === 'right' || sideMoved === 'top-right' || sideMoved === 'bottom-right') {
            const offset = move.movementX;

            if (offset && frame.width + frame.x + offset <= state.canvas.width && frame.width + offset >= this.minHeight) {
                if (isInitialMove) {
                    dispatch({
                        type: Actions.HISTORY_SAVE,
                    });

                    this.setState({
                        isInitialMove: false,
                    });
                }

                dispatch({
                    type: Actions.SET_FRAME_RIGHT_OFFSET,
                    frameId: frame.frameId,
                    offset,
                });
            }
        }

        if (sideMoved === 'bottom' || sideMoved === 'bottom-left' || sideMoved === 'bottom-right') {
            const offset = move.movementY;

            if (offset && frame.height + frame.y + offset <= state.canvas.height && frame.height + offset >= this.minHeight) {
                if (isInitialMove) {
                    dispatch({
                        type: Actions.HISTORY_SAVE,
                    });

                    this.setState({
                        isInitialMove: false,
                    });
                }

                dispatch({
                    type: Actions.SET_FRAME_BOTTOM_OFFSET,
                    frameId: frame.frameId,
                    offset,
                });
            }
        }

        if (sideMoved === 'middle') {
            let offsetX = move.movementX;
            let offsetY = move.movementY;

            if (offsetX && (frame.x + offsetX < 0 || frame.x + frame.width + offsetX > state.canvas.width)) {
                offsetX = 0;
            }

            if (offsetY && (frame.y + offsetY < 0 || frame.y + frame.height + offsetY > state.canvas.height)) {
                offsetY = 0;
            }

            if (offsetX || offsetY) {
                if (isInitialMove) {
                    dispatch({
                        type: Actions.HISTORY_SAVE,
                    });

                    this.setState({
                        isInitialMove: false,
                    });
                }

                dispatch({
                    type: Actions.SET_FRAME_OFFSET,
                    frameId: frame.frameId,
                    offsetX,
                    offsetY,
                });
            }
        }
    };
}

function FrameResizerKnob(props: {
    children?: React.ReactNode;
    className?: string;
    position: string;
    onTouchStart: (event: TouchEvent, position: string) => void;
    onMouseDown: (event: React.MouseEvent, position: string) => void;
}) {
    const onTouchStart = (event: TouchEvent) => {
        event.preventDefault();
        props.onTouchStart(event, props.position);
    };
    const onMouseDown = (event: React.MouseEvent) => {
        props.onMouseDown(event, props.position);
    };

    const handle = React.useRef<HTMLSpanElement>(null);

    React.useEffect(() => {
        if (handle.current) {
            handle.current.addEventListener('touchstart', onTouchStart, { passive: false });
        }

        return () => {
            if (handle.current) {
                handle.current.removeEventListener('touchstart', onTouchStart);
            }
        };
    }, []);

    return (
        <span
            ref={handle}
            className={props.className}
            onMouseDown={onMouseDown}
        >
            {props.children}
        </span>
    );
}
