import { Domain } from 'api';

import { apiKeyApi } from '@/api';
import { makeActions, makeReducer, makeReducerActions, makeSelectors } from '@/makeDetailsState';

export const selectors = makeSelectors<Domain.ApiKeyDetails>({
    getState: rootState => rootState.apiKey.update,
});

export const reducerActions = makeReducerActions<Domain.ApiKeyDetails>({
    reducerPrefix: '@apiKey/update',
});

export const actions = makeActions<Domain.ApiKeyDetails>({
    loadApi: options => apiKeyApi.GetApiKeyDetails(options.urlParams.apiKeyId),
    reducerActions,
    selectors,
});

export const updateReducer = makeReducer<Domain.ApiKeyDetails>({
    reducerActions,
});
