import { ScientificCategorySource, ScientificCategory, APIScientificCategories } from '@/Domain';
import { mapAPIScientificCategoriesToScientificCategory } from '@/domainMappers';

import Endpoint from './Endpoint';

export default class ScientificCategoryEndpoint extends Endpoint {
    public async GetScientificCategoriesTree(source: ScientificCategorySource): Promise<ScientificCategory> {
        const url = `/available-product/scientific-category-tree/${source}`;

        const response: APIScientificCategories = await this.client.get({
            url,
        });

        this.guardResponseSchema('#/definitions/APIScientificCategories', response);

        return mapAPIScientificCategoriesToScientificCategory(response);
    }
}
