import { useNavigate } from 'react-router-dom';

import { Action } from 'redux';
import { ThunkAction as ReduxThunkAction } from 'redux-thunk';

import { RootState } from './store';

export function withPayloadType<T>() {
    return (t: T) => ({ payload: t });
}

export type ThunkAction<R = void> = ReduxThunkAction<R, RootState, () => ReturnType<typeof useNavigate>, Action<string>>;
