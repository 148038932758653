import { Domain } from 'api';

import { orderReviewApi } from '@/api';
import { makeActions, makeReducer, makeReducerActions, makeSelectors } from '@/makeOverviewState';

const defaultSorting: Domain.Sorting = { field: '', direction: 'ascending' };

const pageSize = 10;

export const selectors = makeSelectors<Domain.ApprovedEmailAddress, string>({
    getState: rootState => rootState.orderReview.approvedEmailAddresses,
});

export const reducerActions = makeReducerActions<Domain.ApprovedEmailAddress, string>({
    reducerPrefix: '@orderReview/approvedEmailAddresses',
});

export const actions = makeActions<Domain.ApprovedEmailAddress, string>({
    dataTableSaveKey: 'orderReviewapprovedEmailAddressesOverview-v1',
    loadApi: options =>
        options.globallySelectedWebshopBranchId
            ? orderReviewApi.GetApprovedEmailAddresses(options.globallySelectedWebshopBranchId, options.pagination)
            : undefined,
    defaultSorting,
    pageSize,
    reducerActions,
    selectors,
});

export const approvedEmailAddressesReducer = makeReducer<Domain.ApprovedEmailAddress, string>({
    defaultSorting,
    pageSize,
    reducerActions,
});
