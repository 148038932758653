import { Domain } from 'api';

import { customProductApi } from '@/api';
import { makeActions, makeReducer, makeReducerActions, makeSelectors } from '@/makeOverviewState';

export type CustomProductProps = keyof Domain.CustomProduct;

export const defaultSorting: Domain.Sorting<CustomProductProps> = {
    field: 'productType',
    direction: 'ascending',
};

export const pageSize = 20;

export const selectors = makeSelectors<Domain.CustomProduct, CustomProductProps>({
    getState: rootState => rootState.customProduct.overview,
});

export const reducerActions = makeReducerActions<Domain.CustomProduct, CustomProductProps>({
    reducerPrefix: '@customProduct/overview',
});

export const actions = makeActions<Domain.CustomProduct, CustomProductProps>({
    dataTableSaveKey: 'customProductsOverview-v5',
    loadApi: options =>
        customProductApi.GetCustomProducts(options.ownership, options.pagination, options.locale, undefined, options.search),
    defaultSorting,
    pageSize,
    getBaseUrl: () => '/products/custom-products',
    reducerActions,
    selectors,
});

export const overviewReducer = makeReducer<Domain.CustomProduct, CustomProductProps>({
    defaultSorting,
    pageSize,
    reducerActions,
});
