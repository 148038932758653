import { Selector } from '@reduxjs/toolkit';

import { Domain } from 'api';

import { deviceMonitoringApi } from '@/api';
import { makeActions, makeReducer, makeReducerActions, makeSelectors } from '@/makeDetailsState';
import { RootState } from '@/store';

export const selectors = makeSelectors<Domain.DeviceMonitoringOverview>({
    getState: rootState => rootState.deviceMonitoring.overview,
});

export const reducerActions = makeReducerActions<Domain.DeviceMonitoringOverview>({
    reducerPrefix: '@deviceMonitoring/overview',
});

export const actions = makeActions<Domain.DeviceMonitoringOverview>({
    loadApi: () => deviceMonitoringApi.GetMonitoringOverview(),
    reducerActions,
    selectors,
});

export const overviewReducer = makeReducer<Domain.DeviceMonitoringOverview>({
    reducerActions,
});

export const selectCompanies: Selector<
    RootState,
    {
        id: string;
        name: string;
    }[]
> = state => {
    const overview = selectors.selectDetails(state);

    const companies: {
        [key: string]: {
            id: string;
            name: string;
        };
    } = {};

    for (const device of overview.devices) {
        companies[device.companyId] = {
            id: device.companyId,
            name: device.companyName,
        };
    }

    return Object.values(companies).sort((a, b) => a.name.localeCompare(b.name));
};

export const selectBranches: Selector<
    RootState,
    {
        id: string;
        name: string;
        companyId: string;
    }[]
> = state => {
    const overview = selectors.selectDetails(state);

    const branches: {
        [key: string]: {
            id: string;
            name: string;
            companyId: string;
        };
    } = {};

    for (const device of overview.devices) {
        branches[device.branchId] = {
            id: device.branchId,
            name: device.branchName,
            companyId: device.companyId,
        };
    }

    return Object.values(branches).sort((a, b) => a.name.localeCompare(b.name));
};
