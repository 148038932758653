import { StartDeviceTransactionPayload, CancelDeviceTransactionPayload, DeviceTransactionStatus } from '@/Domain';

import Endpoint from './Endpoint';

export default class DeviceTransactionEndpoint extends Endpoint {
    public async GetDeviceTransactionStatus(transactionId: string): Promise<DeviceTransactionStatus> {
        const response = await this.client.get({
            url: `/transaction/${transactionId}/status`,
        });

        this.guardResponseSchema('#/definitions/DeviceTransactionStatus', response);

        return response;
    }

    public async StartDeviceTransaction(payload: StartDeviceTransactionPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/StartDeviceTransaction',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Transaction\\StartTransaction\\StartTransaction',
                payload,
            },
        });
    }

    public async CancelDeviceTransaction(payload: CancelDeviceTransactionPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/CancelDeviceTransaction',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Transaction\\CancelTransaction\\CancelTransaction',
                payload,
            },
        });
    }
}
