import * as React from 'react';
import { EditorValue } from 'react-rte';

import { EditorState } from 'draft-js';

export interface HTMLEditorContextValue {
    editorState: EditorState;
    editorValueSetter: (newValue: EditorValue) => void;
}

export type HTMLEditorContext = HTMLEditorContextValue & {
    set: (newValue: HTMLEditorContextValue) => void;
};

export const HTMLEditorContext = React.createContext<HTMLEditorContext>({
    set: () => undefined,
    editorState: EditorState.createEmpty(),
    editorValueSetter: () => undefined,
});
