import { createAction, Selector, createReducer } from '@reduxjs/toolkit';

import { Domain } from 'api';

import { ThunkAction, withPayloadType } from '@/action';
import { webshopLibraryNewsArticleApi } from '@/api';
import { URLParams } from '@/routing';
import { RootState } from '@/store';

export interface State {
    article?: Domain.WebshopLibraryNewsArticleDetails;
}

const initialState: State = {};

const reducerActions = {
    setDeliveryTime: createAction(
        '@webshopLibraryNewsArticle/update/setArticle',
        withPayloadType<Domain.WebshopLibraryNewsArticleDetails>(),
    ),
};

export const updateReducer = createReducer(initialState, builder =>
    builder.addCase(reducerActions.setDeliveryTime, (state, action) => {
        state.article = action.payload;
    }),
);

export const selectNewsArticle: Selector<RootState, Domain.WebshopLibraryNewsArticleDetails> = state => {
    const article = state.webshopLibraryNewsArticle.update.article;
    if (!article) {
        throw new Error('News article not loaded');
    }

    return article;
};

export const loadNewsArticle =
    (articleId: string): ThunkAction<Promise<Domain.WebshopLibraryNewsArticleDetails>> =>
    async dispatch => {
        const article = await webshopLibraryNewsArticleApi.GetWebshopLibraryNewsArticleDetails(articleId);

        await dispatch(reducerActions.setDeliveryTime(article));

        return article;
    };

export const load =
    (options: { urlParams: URLParams }): ThunkAction =>
    async dispatch => {
        await dispatch(loadNewsArticle(options.urlParams.articleId));
    };
