import * as React from 'react';

import { InputBaseProps, KeyboardHandler } from '@/core';
import { Grid, Row, Col } from '@/layout';

import Color from './Color';

import styles from './ColorPicker.scss';

interface IProps {
    color?: Color;
    onChange: (newColor: Color) => void;
    closeDropdown: (fromClick?: boolean) => void;
    forwardRef?: React.Ref<HTMLInputElement>;
}

interface IState {
    hex: string;
    prevHex: string;
    r: number;
    g: number;
    b: number;
}

class Params extends React.PureComponent<IProps, IState> {
    private keyHandler = new KeyboardHandler();

    constructor(props: IProps) {
        super(props);

        if (props.color) {
            this.state = {
                hex: props.color.toHex(),
                prevHex: props.color.toHex(),
                r: props.color.red,
                g: props.color.green,
                b: props.color.blue,
            };
        } else {
            this.state = {
                hex: '',
                prevHex: '',
                r: 0,
                g: 0,
                b: 0,
            };
        }
    }

    static getDerivedStateFromProps(nextProps: Readonly<IProps>, state: IState): IState {
        if (nextProps.color && nextProps.color.toHex() !== state.prevHex) {
            return {
                hex: nextProps.color.toHex(),
                prevHex: nextProps.color.toHex(),
                r: nextProps.color.red,
                g: nextProps.color.green,
                b: nextProps.color.blue,
            };
        }

        return state;
    }

    render() {
        const { hex, r, g, b } = this.state;
        const { forwardRef } = this.props;

        return (
            <div className={styles.ColorPickerParams}>
                <Grid cols={5}>
                    <Row>
                        <Col span={2}>
                            <input
                                ref={forwardRef}
                                id="color-hex"
                                data-test-id="color-hex"
                                type="text"
                                value={hex}
                                onChange={this.handleHexChange}
                                onKeyDown={this.keyHandler.handleKey('ENTER', () => {
                                    this.props.closeDropdown(false);
                                })}
                            />
                        </Col>
                        <Col span={1}>
                            <input
                                id="color-r"
                                data-test-id="color-r"
                                type="number"
                                value={r}
                                min={0}
                                max={255}
                                onChange={event => {
                                    const newRed = parseInt(event.target.value, 10) || 0;
                                    const { color, onChange } = this.props;

                                    if (!onChange) {
                                        return;
                                    }

                                    const newColor = (color || new Color('')).setRed(newRed);
                                    onChange(newColor.clone());

                                    this.setState({
                                        hex: newColor.toHexString(),
                                        r: newRed,
                                    });
                                }}
                                onKeyDown={this.keyHandler.handleKey('ENTER', () => {
                                    this.props.closeDropdown(false);
                                })}
                            />
                        </Col>
                        <Col span={1}>
                            <input
                                id="color-g"
                                data-test-id="color-g"
                                type="number"
                                value={g}
                                min={0}
                                max={255}
                                onChange={event => {
                                    const newGreen = parseInt(event.target.value, 10) || 0;
                                    const { color, onChange } = this.props;

                                    if (!onChange) {
                                        return;
                                    }

                                    const newColor = (color || new Color('')).setGreen(newGreen);
                                    onChange(newColor.clone());

                                    this.setState({
                                        hex: newColor.toHexString(),
                                        g: newGreen,
                                    });
                                }}
                                onKeyDown={this.keyHandler.handleKey('ENTER', () => {
                                    this.props.closeDropdown(false);
                                })}
                            />
                        </Col>
                        <Col span={1}>
                            <input
                                id="color-b"
                                data-test-id="color-b"
                                type="number"
                                value={b}
                                min={0}
                                max={255}
                                onChange={event => {
                                    const newBlue = parseInt(event.target.value, 10) || 0;
                                    const { color, onChange } = this.props;

                                    if (!onChange) {
                                        return;
                                    }

                                    const newColor = (color || new Color('')).setBlue(newBlue);
                                    onChange(newColor.clone());

                                    this.setState({
                                        hex: newColor.toHexString(),
                                        b: newBlue,
                                    });
                                }}
                                onKeyDown={this.keyHandler.handleKey('ENTER', () => {
                                    this.props.closeDropdown(false);
                                })}
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col span={2}>
                            {/* eslint-disable-next-line local-rules/force-use-gettext */}
                            <label htmlFor="color-hex">Hex</label>
                        </Col>
                        <Col span={1}>
                            <label htmlFor="color-r">R</label>
                        </Col>
                        <Col span={1}>
                            <label htmlFor="color-g">G</label>
                        </Col>
                        <Col span={1}>
                            <label htmlFor="color-b">B</label>
                        </Col>
                    </Row>
                </Grid>
            </div>
        );
    }

    private handleHexChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        this.setState(
            {
                hex: event.target.value,
            },
            () => {
                const { onChange } = this.props;

                if (!onChange) {
                    return;
                }

                let newHex = this.state.hex;

                if (newHex.length === 7 && newHex[0] === '#') {
                    newHex = newHex.substr(1);
                }

                if (newHex.length === 6) {
                    const newColor = new Color(newHex);
                    if (newColor.isValid()) {
                        onChange(newColor);
                    }
                }
            },
        );
    };
}

export default React.forwardRef<HTMLInputElement, IProps & InputBaseProps>((props, ref) => {
    return (
        <Params
            forwardRef={ref}
            {...props}
        />
    );
});
