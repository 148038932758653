const LOADED_IMAGES: {
    [key: string]: Promise<any>;
} = {};

export function preloadImage(imageSrc: string): Promise<any> {
    if (!LOADED_IMAGES.hasOwnProperty(imageSrc)) {
        LOADED_IMAGES[imageSrc] = fetch(imageSrc, { mode: 'no-cors' });
    }

    return LOADED_IMAGES[imageSrc];
}
