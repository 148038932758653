import * as React from 'react';
import { useSelector } from 'react-redux';

import { DarkMode } from 'data-store';
import { AppCore as AppCoreBase } from 'ui-components';

export default function AppCore(props: { children?: React.ReactNode }) {
    let darkMode = false;
    try {
        darkMode = useSelector(DarkMode.selectCurrentDarkMode);
    } catch (e) {
        // ignore exception, we're not un a Redux context and can't `useSelector`
    }

    return <AppCoreBase darkMode={darkMode}>{props.children}</AppCoreBase>;
}
