import {
    Pagination,
    Sorting,
    AppointmentsPage,
    AppointmentDetails,
    MakeAppointmentPayload,
    CancelAppointmentType,
    AppointmentsOverviewExportType,
    UpdateAppointmentsActiveStatusPayload,
} from '@/Domain';

import Endpoint from './Endpoint';

interface IFilters {
    type?: string;
    withoutPastAppointments?: string;
    startsAtFrom?: string;
    startsAtTo?: string;
    isToday?: string;
    excludeCanceled?: string;
}
export default class AppointmentsEndpoint extends Endpoint {
    public async GetAppointments(pagination: Pagination, sorting: Sorting, search?: string, filters?: IFilters): Promise<AppointmentsPage> {
        const newFilters = {
            withoutPastAppointments: filters && filters.withoutPastAppointments ? filters.withoutPastAppointments : 'true',
            excludeCanceled: filters && filters.excludeCanceled ? filters.excludeCanceled : 'false',
            appointmentTypeId: filters?.type ? filters.type.split(',') : undefined,
            startsAtFrom: filters?.startsAtFrom || undefined,
            startsAtTo: filters?.startsAtTo || undefined,
            isToday: filters?.isToday || undefined,
        };
        const response = await this.client.get({
            url: '/appointment/overview',
            params: {
                pagination,
                sorting,
                search,
                filters: newFilters,
            },
        });

        this.guardResponseSchema('#/definitions/AppointmentsPage', response);
        return response;
    }

    public async GetAppointmentDetails(appointmentTypeId: string): Promise<AppointmentDetails> {
        const response = await this.client.get({
            url: `/appointment/details/${appointmentTypeId}`,
        });

        this.guardResponseSchema('#/definitions/AppointmentDetails', response);

        return response;
    }

    public GetAppointmentOverviewExportDownloadURL(exportType: AppointmentsOverviewExportType, locale: string): string {
        return this.client.buildApiURL(`/appointment/overview/export/pdf/${exportType}/${locale}`, true);
    }

    public async MakeAppointment(payload: MakeAppointmentPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/MakeAppointment',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Appointment\\MakeAppointment\\MakeAppointment',
                payload,
            },
        });
    }
    public async CancelAppointment(payload: CancelAppointmentType): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/MakeAppointment',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Appointment\\CancelAppointment\\CancelAppointment',
                payload,
            },
        });
    }
    public async UpdateAppointmentsActiveStatus(payload: UpdateAppointmentsActiveStatusPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/UpdateAppointmentsActiveStatus',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Appointment\\UpdateAppointmentsActiveStatus\\UpdateAppointmentsActiveStatus',
                payload,
            },
        });
    }
}
