import { Domain } from 'api';

import { webshopDeliveryTimeApi } from '@/api';
import { makeActions, makeReducer, makeReducerActions, makeSelectors } from '@/makeOverviewState';

type WebshopDeliveryTimeProps = keyof Domain.WebshopDeliveryTime;

export const defaultSorting: Domain.Sorting<WebshopDeliveryTimeProps> = {
    field: 'deliveryTimeId',
    direction: 'ascending',
};

export const pageSize = 20;

export const selectors = makeSelectors<Domain.WebshopDeliveryTime, WebshopDeliveryTimeProps>({
    getState: rootState => rootState.webshopDeliveryTime.overview,
});

export const reducerActions = makeReducerActions<Domain.WebshopDeliveryTime, WebshopDeliveryTimeProps>({
    reducerPrefix: '@webshopDeliveryTime/overview',
});

export const actions = makeActions<Domain.WebshopDeliveryTime, WebshopDeliveryTimeProps>({
    dataTableSaveKey: 'webshopDeliveryTimesOverview-v5',
    loadApi: options => {
        if (options.globallySelectedWebshopId === undefined) {
            return;
        }

        return webshopDeliveryTimeApi.GetWebshopDeliveryTimes(options.pagination, options.globallySelectedWebshopId);
    },
    defaultSorting,
    pageSize,
    getBaseUrl: () => '/webshops/delivery-times',
    reducerActions,
    selectors,
});

export const overviewReducer = makeReducer<Domain.WebshopDeliveryTime, WebshopDeliveryTimeProps>({
    defaultSorting,
    pageSize,
    reducerActions,
});
