import { useDispatch } from 'react-redux';

import { configureStore } from '@reduxjs/toolkit';

import { rootReducer } from './rootReducer';

export const store = configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            thunk: {
                extraArgument: () => {
                    if (!(store as any).getNavigate) {
                        throw new Error('Navigation not initialized');
                    }

                    return (store as any).getNavigate();
                },
            },
            immutableCheck: false,
            serializableCheck: false,
        }),
    devTools: process.env.NODE_ENV !== 'production',
});

export type RootState = ReturnType<typeof rootReducer>;

export const useThunkDispatch = (): typeof store.dispatch => {
    return useDispatch();
};
