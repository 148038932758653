import { Route } from 'data-store';

import errorRoutes from './Pages/TVScreen/Errors/routes';
import tvScreenRoutes from './Pages/TVScreen/routes';

const routes: Route[] = [
    ...tvScreenRoutes,

    // has to be last because it matches * (no other route matched)
    ...errorRoutes,
];

export default routes;
