import { Domain } from 'api';

import { importApi } from '@/api';
import { makeActions, makeReducer, makeReducerActions, makeSelectors } from '@/makeOverviewState';

type ProductImportProps = keyof Domain.Import;

const defaultSorting: Domain.Sorting<ProductImportProps> = {
    field: 'initializedOn',
    direction: 'descending',
};

const pageSize = 20;

export const selectors = makeSelectors<Domain.Import, ProductImportProps>({
    getState: rootState => rootState.productImport.overview,
});

export const reducerActions = makeReducerActions<Domain.Export, ProductImportProps>({
    reducerPrefix: '@productImport/overview',
});

export const actions = makeActions<Domain.Import, ProductImportProps>({
    dataTableSaveKey: 'productImportsOverview-v5',
    loadApi: options => importApi.GetCsvImports(options.pagination, options.sorting),
    defaultSorting,
    pageSize,
    getBaseUrl: () => '/products/product-import',
    reducerActions,
    selectors,
});

export const overviewReducer = makeReducer<Domain.Import, ProductImportProps>({
    defaultSorting,
    pageSize,
    reducerActions,
});
