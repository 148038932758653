import { createAction, createReducer, Selector } from '@reduxjs/toolkit';

import { Domain } from 'api';

import { ThunkAction, withPayloadType } from '@/action';
import { integrationApi } from '@/api';
import { selectLoggedInUserOwnership } from '@/Authentication';
import { RootState } from '@/store';

export interface State {
    configuredIntegrationsCountByType: Domain.IntegrationsCountByType | undefined;
    configuredIntegrationsByType: {
        [key in Domain.IntegrationCapability]?: Domain.ConfiguredIntegrationsPage;
    };
}

const initialState: State = {
    configuredIntegrationsCountByType: undefined,
    configuredIntegrationsByType: {},
};

const reducerActions = {
    setConfiguredIntegrationsCountByType: createAction(
        '@integration/configuredIntegrationsType/setConfiguredIntegrationsCountByType',
        withPayloadType<Domain.IntegrationsCountByType>(),
    ),
    setConfiguredIntegrationsByType: createAction(
        '@integration/configuredIntegrationsType/setConfiguredIntegrationsByType',
        withPayloadType<{
            type: Domain.IntegrationCapability;
            integrations: Domain.ConfiguredIntegrationsPage;
        }>(),
    ),
};

export const configuredIntegrationsTypeReducer = createReducer(initialState, builder =>
    builder
        .addCase(reducerActions.setConfiguredIntegrationsCountByType, (state, action) => {
            state.configuredIntegrationsCountByType = action.payload;
        })
        .addCase(reducerActions.setConfiguredIntegrationsByType, (state, action) => {
            state.configuredIntegrationsByType[action.payload.type] = action.payload.integrations;
        }),
);

export const selectConfiguredIntegrationsCountByType: Selector<RootState, undefined | Domain.IntegrationsCountByType> = state =>
    state.integration.configuredIntegrationsType.configuredIntegrationsCountByType;

export const selectActiveConfiguredIntegrationsByType =
    (type: Domain.IntegrationCapability): Selector<RootState, Domain.ConfiguredIntegration[]> =>
    state =>
        state.integration.configuredIntegrationsType.configuredIntegrationsByType[type]?.items.filter(
            integration => integration.activeStatus === 'active',
        ) || [];

export const selectActiveAndValidDeliverPickupsIntegrations = (): Selector<RootState, Domain.ConfiguredIntegration[]> => state =>
    selectActiveConfiguredIntegrationsByType('deliverPickups')(state).filter(integration => !!integration.config?.deviceId);

export const loadConfiguredIntegrationsCountByType = (): ThunkAction => async (dispatch, getState) => {
    const state = getState();
    const ownership = selectLoggedInUserOwnership(state);

    if (ownership.type === 'company' || ownership.type === 'branch') {
        const configuredIntegrationsCountByType = await integrationApi.GetConfiguredIntegrationsCountByType(ownership);
        dispatch(reducerActions.setConfiguredIntegrationsCountByType(configuredIntegrationsCountByType));
    }
};

export const loadConfiguredIntegrationsByType =
    (type: Domain.IntegrationCapability): ThunkAction<Promise<Domain.ConfiguredIntegrationsPage | undefined>> =>
    async (dispatch, getState) => {
        const state = getState();
        const ownership = selectLoggedInUserOwnership(state);

        if (
            ownership.type === 'company' ||
            ownership.type === 'branch' ||
            (ownership.type === 'user' && ownership.ownerId.startsWith('device-'))
        ) {
            const integrations = await integrationApi.GetConfiguredIntegrationsByType(
                { page: 1, size: 999 },
                { field: 'activeStatus', direction: 'ascending' },
                type,
            );

            dispatch(
                reducerActions.setConfiguredIntegrationsByType({
                    type,
                    integrations,
                }),
            );

            return integrations;
        }
    };
