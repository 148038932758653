import {
    Pagination,
    WebshopColorSet,
    WebshopColorSetsPage,
    UpdateAvailableColorSetPayload,
    CreateAvailableColorSetPayload,
    WebshopFontSets,
    UpdateWebshopColorSetPayload,
    UpdateWebshopFontSetPayload,
    UpdateWebshopShapeSetPayload,
    UpdateWebshopNavigationSetPayload,
    WebshopNavigationSet,
    Company,
} from '@/Domain';

import Endpoint from './Endpoint';

export default class WebshopLayoutEndpoint extends Endpoint {
    public async GetWebshopNavigationSet(webshopId: string): Promise<WebshopNavigationSet> {
        const response = await this.client.get({
            url: '/webshop/' + webshopId + '/layout-configuration',
        });

        this.guardResponseSchema('#/definitions/WebshopNavigationSet', response);

        return response;
    }

    public async GetWebshopColorSets(pagination: Pagination, companyId?: Company['companyId']): Promise<WebshopColorSetsPage> {
        const response = await this.client.get({
            url: `/availableColorSet/overview${companyId ? '/' + companyId : ''}`,
            params: {
                pagination,
            },
        });

        this.guardResponseSchema('#/definitions/WebshopColorSetsPage', response);

        return response;
    }

    public async GetWebshopColorSetDetails(colorSetId: WebshopColorSet['colorSet']['colorset_id']): Promise<WebshopColorSet> {
        const response = await this.client.get({
            url: `/availableColorSet/${colorSetId}/details`,
        });

        this.guardResponseSchema('#/definitions/WebshopColorSet', response);

        return response;
    }

    public async CreateAvailableColorSet(payload: CreateAvailableColorSetPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/CreateAvailableColorSet',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Webshop\\AvailableColorSet\\UpdateAvailableColorSet\\UpdateAvailableColorSet',
                payload,
            },
        });
    }

    public async UpdateAvailableColorSet(payload: UpdateAvailableColorSetPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/UpdateAvailableColorSet',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Webshop\\AvailableColorSet\\UpdateAvailableColorSet\\UpdateAvailableColorSet',
                payload,
            },
        });
    }

    public async GetWebshopFontSets(): Promise<WebshopFontSets> {
        const response = await this.client.get({
            url: '/webshop/layout/available-font-set',
        });

        this.guardResponseSchema('#/definitions/WebshopFontSets', response);

        return response;
    }

    public async UpdateWebshopColorSet(payload: UpdateWebshopColorSetPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/UpdateWebshopColorSet',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Webshop\\UpdateColorSet\\UpdateColorSet',
                payload,
            },
        });
    }

    public async UpdateWebshopFontSet(payload: UpdateWebshopFontSetPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/UpdateWebshopFontSet',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Webshop\\UpdateFontSet\\UpdateFontSet',
                payload,
            },
        });
    }

    public async UpdateWebshopShapeSet(payload: UpdateWebshopShapeSetPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/UpdateWebshopShapeSet',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Webshop\\UpdateShapeSet\\UpdateShapeSet',
                payload,
            },
        });
    }

    public async UpdateWebshopNavigationSet(payload: UpdateWebshopNavigationSetPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/UpdateWebshopNavigationSet',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Webshop\\UpdateLayoutSet\\UpdateLayoutSet',
                payload,
            },
        });
    }
}
