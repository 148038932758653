import { Pagination, Sorting, WebshopStockSubscriptionsPage } from '@/Domain';

import Endpoint from './Endpoint';

export default class WebshopStockSubscriptionEndpoint extends Endpoint {
    public async getWebshopStockSubscriptions(
        pagination: Pagination,
        sorting: Sorting,
        webshopId: string,
        search?: string,
    ): Promise<WebshopStockSubscriptionsPage> {
        const url = `/product-stock-subscription/overview/webshop/${webshopId}`;

        const response = await this.client.get({
            url,
            params: {
                pagination,
                sorting,
                search,
            },
        });

        this.guardResponseSchema('#/definitions/WebshopStockSubscriptionsPage', response);

        return response;
    }
}
