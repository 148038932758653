import { Domain } from 'api';

import { availableProductApi } from '@/api';
import { makeActions, makeReducer, makeReducerActions, makeSelectors } from '@/makeDetailsState';

export const selectors = makeSelectors<Domain.AvailableProductDetails>({
    getState: rootState => rootState.availableProduct.details,
});

export const reducerActions = makeReducerActions<Domain.AvailableProductDetails>({
    reducerPrefix: '@availableProduct/details',
});

export const actions = makeActions<Domain.AvailableProductDetails>({
    loadApi: options => availableProductApi.GetAvailableProductDetails(options.urlParams.availableProductId, options.locale),
    reducerActions,
    selectors,
});

export const detailsReducer = makeReducer<Domain.AvailableProductDetails>({
    reducerActions,
});
