import { createAction, Selector } from '@reduxjs/toolkit';

import { Domain } from 'api';

import { ThunkAction, withPayloadType } from '@/action';
import { integrationApi, deviceApi } from '@/api';
import { makeActions, makeReducer, makeReducerActions, makeSelectors } from '@/makeDetailsState';
import { URLParams, URLQuery } from '@/routing';
import { RootState } from '@/store';

export const setAvailableIntegration = createAction(
    '@integration/details/setAvailableIntegration',
    withPayloadType<Domain.AvailableIntegration | undefined>(),
);
export const selectAvailableIntegration: Selector<RootState, Domain.AvailableIntegration | undefined> = state =>
    state.integration.details.availableIntegration;
export const loadAvailableIntegration =
    (availableIntegrationId: string): ThunkAction =>
    async dispatch => {
        dispatch(setAvailableIntegration(await integrationApi.GetAvailableIntegrationDetail(availableIntegrationId)));
    };

export const setDevice = createAction('@integration/details/setDevice', withPayloadType<Domain.Device | undefined>());
export const selectDevice: Selector<RootState, Domain.Device | undefined> = state => state.integration.details.device;
export const loadDevice =
    (deviceId: string): ThunkAction =>
    async dispatch => {
        try {
            const device = await deviceApi.GetDeviceDetails(deviceId);
            dispatch(setDevice(device));
        } catch (e) {
            console.error(e);
            dispatch(setDevice(undefined));
        }
    };

export const selectors = makeSelectors<Domain.IntegrationDetails>({
    getState: rootState => rootState.integration.details,
});

export const reducerActions = makeReducerActions<Domain.IntegrationDetails>({
    reducerPrefix: '@integration/details',
});

const baseActions = makeActions<Domain.IntegrationDetails>({
    loadApi: options => integrationApi.GetIntegrationDetail(options.urlParams.configuredIntegrationId),
    reducerActions,
    selectors,
});

export const detailsReducer = makeReducer<
    Domain.IntegrationDetails,
    {
        availableIntegration?: Domain.AvailableIntegration;
        device?: Domain.Device;
    }
>({
    reducerActions,
    extraCases: builder =>
        builder
            .addCase(setAvailableIntegration, (state, action) => {
                state.availableIntegration = action.payload;
            })
            .addCase(setDevice, (state, action) => {
                state.device = action.payload;
            }),
});

export const actions = {
    ...baseActions,
    load:
        (options: { urlParams: URLParams; urlQuery: URLQuery }): ThunkAction =>
        async dispatch => {
            const configuredIntegration = await dispatch(baseActions.load(options));

            if (configuredIntegration) {
                const promises = [dispatch(loadAvailableIntegration(configuredIntegration.availableIntegrationId))];

                if (configuredIntegration.settings && configuredIntegration.config) {
                    for (const fieldKey of Object.keys(configuredIntegration.settings)) {
                        if (configuredIntegration.settings[fieldKey].type === 'deviceId') {
                            const deviceId = configuredIntegration.config[fieldKey];
                            if (deviceId) {
                                promises.push(dispatch(loadDevice(deviceId)));
                            }
                        }
                    }
                }

                await Promise.all(promises);
            }
        },
};
