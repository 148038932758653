import { createAction, Selector, createReducer as reduxCreateReducer } from '@reduxjs/toolkit';

import { Domain } from 'api';

import { ThunkAction, withPayloadType } from '@/action';
import { webshopFaqLibraryApi } from '@/api';
import { loadLocalisations } from '@/Localisation/overviewState';
import { RootState } from '@/store';

export interface State {
    faqCategories?: Domain.WebshopFaqCategoriesPage;
}

const initialState: State = {};

const reducerActions = {
    setFaqCategories: createAction('@webshopFaqItem/create/setFaqCategories', withPayloadType<Domain.WebshopFaqCategoriesPage>()),
};

export const createReducer = reduxCreateReducer(initialState, builder =>
    builder.addCase(reducerActions.setFaqCategories, (state, action) => {
        state.faqCategories = action.payload;
    }),
);

export const selectFaqCategories: Selector<RootState, Domain.WebshopFaqCategoriesPage> = state => {
    const faqCategories = state.webshopFaqItemLibrary.create.faqCategories;
    if (!faqCategories) {
        throw new Error('FAQ categories not loaded');
    }

    return faqCategories;
};

export const loadFaqCategories = (): ThunkAction<Promise<Domain.WebshopFaqCategoriesPage>> => async dispatch => {
    const faqCategories = await webshopFaqLibraryApi.GetWebshopFaqLibraryCategories(
        { page: 1, size: 999 },
        { field: 'sortOrder', direction: 'ascending' },
    );

    await dispatch(reducerActions.setFaqCategories(faqCategories));

    return faqCategories;
};

export const load = (): ThunkAction => async dispatch => {
    await dispatch(loadFaqCategories());
    await dispatch(loadLocalisations());
};
