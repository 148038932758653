import * as React from 'react';

import { useGettext } from 'data-store';
import { Grid, Row, Col, Tx } from 'ui-components';

import styles from './LandingPage.scss';

export default function LandingPage(props: {
    children?: React.ReactNode;
    title?: string;
    description?: string;
    noFooter?: boolean;
    noLogo?: boolean;
}) {
    const { gettext } = useGettext();

    return (
        <Grid
            gutter={30}
            className={styles.LandingPage}
        >
            <Row className="LandingPageContent">
                <Col
                    span={6}
                    spanSm={0}
                >
                    <div className={styles.HeroImageWrap}>
                        <div className={styles.HeroImage} />
                    </div>
                </Col>
                <Col
                    span={6}
                    spanSm={12}
                    vAlign="center"
                >
                    {!props.noLogo && <div className={styles.Logo} />}

                    {props.title ? (
                        <Tx
                            level="heading-1"
                            className={styles.PageTitle}
                        >
                            {props.title}
                        </Tx>
                    ) : null}

                    {props.description ? (
                        <Tx
                            as="p"
                            className={styles.PageDescription}
                        >
                            {props.description}
                        </Tx>
                    ) : null}

                    {props.children}
                </Col>
            </Row>

            {!props.noFooter ? (
                <Row>
                    <Col
                        span={6}
                        spanSm={12}
                        hAlign="end"
                    >
                        <div className={styles.FooterLinks}>
                            {/* <a href="/terms-and-conditions">
                                {gettext('Terms and conditions')}
                            </a> */}
                            <Tx
                                level="body-sm"
                                as="a"
                                href="/privacy-policy"
                            >
                                {gettext('Privacy policy')}
                            </Tx>
                            <Tx level="body-sm">{gettext('© Lochting Platform 2020-2024')}</Tx>
                        </div>
                    </Col>
                </Row>
            ) : null}
        </Grid>
    );
}
