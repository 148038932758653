import { Pagination, Brand, BrandsPage, Locale } from '@/Domain';

import Endpoint from './Endpoint';

export default class BrandEndpoint extends Endpoint {
    public async GetBrands(
        pagination: Pagination,
        locale: Locale,
        search?: string,
        filters?: {
            brandIds?: string;
        },
    ): Promise<BrandsPage> {
        const response = await this.client.get({
            url: '/available-product/brand-overview',
            params: {
                pagination,
                locale,
                search,
                filters: filters
                    ? {
                          brandIds: filters.brandIds ? filters.brandIds.split(',') : undefined,
                      }
                    : undefined,
            },
        });

        this.guardResponseSchema('#/definitions/BrandsPage', response);

        return response;
    }

    public async GetBrandDetails(brandId: Brand['brandId']): Promise<Brand> {
        const response = await this.client.get({
            url: `/available-product/brand-details/${brandId}`,
        });

        this.guardResponseSchema('#/definitions/Brand', response);

        return response;
    }
}
