import * as React from 'react';

import { TouchAndMouseDragHandler, TouchAndMouseMove, KeyboardHandler } from '@/core';

import Color from './Color';

import styles from './ColorPicker.scss';

interface IProps {
    color?: Color;
    onChangeStart: () => void;
    onChange: (newColor?: Color) => void;
    onChangeComplete: (newColor?: Color) => void;
}

interface IState {
    color: Color;
}

export default class Board extends React.PureComponent<IProps, IState> {
    private board = React.createRef<HTMLDivElement>();
    private knob = React.createRef<HTMLSpanElement>();
    private dragHandler: TouchAndMouseDragHandler;
    private keyHandler = new KeyboardHandler();

    constructor(props: IProps) {
        super(props);

        this.state = {
            color: props.color || new Color(''),
        };

        this.dragHandler = new TouchAndMouseDragHandler(
            this.handleColorChange,
            () => {
                this.props.onChangeStart();
                if (this.knob.current) {
                    this.knob.current.focus();
                }
            },
            () => {
                this.props.onChangeComplete(this.state.color.clone());
            },
        );
    }

    componentDidMount(): void {
        if (!this.board.current) {
            return;
        }

        this.board.current.addEventListener('touchstart', this.dragHandler.handleTouchStart, { passive: false });
    }

    componentWillUnmount(): void {
        if (!this.board.current) {
            return;
        }

        this.board.current.removeEventListener('touchstart', this.dragHandler.handleTouchStart);
    }

    render() {
        let { color } = this.props;
        if (!color) {
            color = new Color('');
        }
        const indicatorTop = (1 - color.brightness) * 100;
        const indicatorLeft = color.saturation * 100;

        const rawColor = new Color({
            h: color.hue,
            s: 1,
            v: 1,
        });

        return (
            <div className={styles.ColorPickerBoardWrap}>
                <div
                    ref={this.board}
                    className={styles.ColorPickerBoard}
                    style={{
                        backgroundColor: rawColor.toHexString(),
                    }}
                    onClick={() => {
                        // do nothing
                    }}
                    onMouseDown={this.dragHandler.handleMouseDown}
                >
                    <div className={styles.ColorPickerBoardBackground}>
                        <div />
                    </div>

                    <span
                        ref={this.knob}
                        className={styles.ColorPickerBoardIndicator}
                        style={{
                            top: indicatorTop + '%',
                            left: indicatorLeft + '%',
                            borderColor: color.clone().delimitFromSelfBackground().toHexString(),
                        }}
                        tabIndex={0}
                        onKeyDown={this.keyHandler.handleKey(
                            ['LEFT_ARROW', 'RIGHT_ARROW', 'UP_ARROW', 'DOWN_ARROW'],
                            (_1: any, key: string, meta: { shift: boolean }) => {
                                const { onChange } = this.props;
                                // tslint:disable-next-line:no-shadowed-variable
                                let { color } = this.props;
                                if (!color) {
                                    color = new Color('');
                                }

                                let offsetSaturation = 0;
                                let offsetBrightness = 0;

                                switch (key) {
                                    case 'LEFT_ARROW':
                                        offsetSaturation -= 0.01;
                                        break;
                                    case 'RIGHT_ARROW':
                                        offsetSaturation += 0.01;
                                        break;
                                    case 'UP_ARROW':
                                        offsetBrightness += 0.01;
                                        break;
                                    case 'DOWN_ARROW':
                                        offsetBrightness -= 0.01;
                                        break;
                                    default:
                                        return;
                                }

                                if (meta.shift) {
                                    offsetSaturation *= 10;
                                    offsetBrightness *= 10;
                                }

                                if (color.saturation + offsetSaturation < 0) {
                                    offsetSaturation = -color.saturation;
                                }

                                if (color.saturation + offsetSaturation > 1) {
                                    offsetSaturation = 1 - color.saturation;
                                }

                                if (color.brightness + offsetBrightness < 0) {
                                    offsetBrightness = -color.brightness;
                                }

                                if (color.brightness + offsetBrightness > 1) {
                                    offsetBrightness = 1 - color.brightness;
                                }

                                color.setSaturation(color.saturation + offsetSaturation);
                                color.setBrightness(color.brightness + offsetBrightness);

                                this.setState(
                                    {
                                        color,
                                    },
                                    () => {
                                        if (onChange) {
                                            onChange(color ? color.clone() : undefined);
                                        }
                                    },
                                );
                            },
                        )}
                        onKeyUp={this.keyHandler.handleKey(['LEFT_ARROW', 'RIGHT_ARROW', 'UP_ARROW', 'DOWN_ARROW'], () => {
                            if (this.props.onChangeComplete) {
                                this.props.onChangeComplete(this.state.color.clone());
                            }
                        })}
                    />
                </div>
            </div>
        );
    }

    private handleColorChange = (move: TouchAndMouseMove): void => {
        if (!this.board.current) {
            return;
        }

        const { onChange } = this.props;
        let { color } = this.props;
        if (!color) {
            color = new Color('');
        }

        const rect = this.board.current.getBoundingClientRect();
        const offsetX = Math.min(Math.max(0, move.x - rect.x), rect.width);
        const offsetY = Math.min(Math.max(0, move.y - rect.y), rect.height);

        color.setSaturation(offsetX / rect.width);
        color.setBrightness(1 - offsetY / rect.height);

        this.setState(
            {
                color,
            },
            () => {
                if (onChange) {
                    onChange(color ? color.clone() : undefined);
                }
            },
        );
    };
}
