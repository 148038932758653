import { createAction, createReducer, Selector } from '@reduxjs/toolkit';

import { Domain, Infrastructure } from 'api';

import { ThunkAction, withPayloadType } from '@/action';
import { productSelectionApi, stockSourceApi, scientificCategoryApi } from '@/api';
import { selectLoggedInCompanyOrBranchManagerOwnershipIds, selectLoggedInUserOwnership } from '@/Authentication';
import { selectLoggedInCompanyOrBranchManagerCountry } from '@/Authentication/selectors';
import * as categoriesTreeState from '@/AvailableProduct/categoriesTreeState';
import { URLParams } from '@/routing';
import { RootState } from '@/store';

export type ScientificCategoryTrees = {
    [key in Domain.ScientificCategorySource]?: Domain.ScientificCategory;
};

export interface State {
    selectionType: Domain.ProductSelectionType;
    productAvailabilityIntegrations: Domain.StockSourceIds;
    scientificCategoryTrees: ScientificCategoryTrees;
    selection?: Domain.ProductSelection;
}

const initialState: State = {
    selectionType: 'inStoreProductSelection',
    productAvailabilityIntegrations: [],
    scientificCategoryTrees: {},
};

const reducerActions = {
    setSelectionType: createAction('@productSelection/update/setSelectionType', withPayloadType<Domain.ProductSelectionType>()),
    setProductAvailabilityIntegrations: createAction(
        '@productSelection/update/setProductAvailabilityIntegrations',
        withPayloadType<Domain.StockSourceIds>(),
    ),
    setSelection: createAction('@productSelection/update/setSelection', withPayloadType<Domain.ProductSelection>()),
    setScientificCategoryTrees: createAction(
        '@productSelection/update/setScientificCategoryTrees',
        withPayloadType<ScientificCategoryTrees>(),
    ),
};

export const updateReducer = createReducer(initialState, builder =>
    builder
        .addCase(reducerActions.setSelectionType, (state, action) => {
            state.selectionType = action.payload;
        })
        .addCase(reducerActions.setProductAvailabilityIntegrations, (state, action) => {
            state.productAvailabilityIntegrations = action.payload;
        })
        .addCase(reducerActions.setSelection, (state, action) => {
            state.selection = action.payload;
        })
        .addCase(reducerActions.setScientificCategoryTrees, (state, action) => {
            state.scientificCategoryTrees = action.payload;
        }),
);

export const selectSelectionType: Selector<RootState, Domain.ProductSelectionType> = state => state.productSelection.update.selectionType;

export const selectProductAvailabilityIntegrations: Selector<RootState, Domain.StockSourceIds> = state => {
    const integrations = state.productSelection.update.productAvailabilityIntegrations;

    if (!integrations) {
        throw new Error('Product Availability Integrations not loaded');
    }

    return integrations;
};

export const selectScientificCategoryTrees: Selector<RootState, ScientificCategoryTrees> = state =>
    state.productSelection.update.scientificCategoryTrees;

export const selectProductSelection: Selector<RootState, Domain.ProductSelection> = state => {
    const selection = state.productSelection.update.selection;
    if (!selection) {
        throw new Error('Selection not loaded');
    }
    return selection;
};

export const loadProductAvailabilityIntegrations =
    (companyId: Domain.Company['companyId'], branchId: Domain.Branch['branchId']): ThunkAction =>
    async dispatch => {
        const integrations = await stockSourceApi.GetStockSourceIds(companyId, branchId, { stockTypes: 'inHouse' });
        await dispatch(reducerActions.setProductAvailabilityIntegrations(integrations));
    };

export const loadSelection =
    (ownership: Domain.Ownership, selectionType: Domain.ProductSelectionType): ThunkAction =>
    async dispatch => {
        try {
            const selection = await productSelectionApi.GetProductSelection(ownership, selectionType);
            dispatch(reducerActions.setSelection(selection));
        } catch (e) {
            if (e instanceof Infrastructure.Api.HttpApiException && e.status === 404) {
                dispatch(
                    reducerActions.setSelection({
                        categoryIdsToBeExcluded: [],
                        organisationIdsToBeExcluded: [],
                        brandIdsToBeExcluded: [],
                        activeIngredientIdsToBeExcluded: [],
                        scientificCategoryIdsToBeExcluded: [],
                        productIdsToBeExcluded: [],
                        stockSourceIdsToBeIncluded: [],
                        conservationsToBeExcluded: [],
                        productIdsToBeIncluded: [],
                        productIdsToBeIncludedForRobotHomeScreen: [],
                    }),
                );
            } else {
                throw e;
            }
        }
    };

export const loadScientificCategoryTrees =
    (country: Domain.Country): ThunkAction =>
    async (dispatch, getState) => {
        const state = getState();
        const scientificCategoriesSources = state.localisation.availableCountries.countries[country]
            ? state.localisation.availableCountries.countries[country].scientificCategories
            : undefined;
        if (scientificCategoriesSources) {
            const categories = await Promise.all(
                scientificCategoriesSources.map(source => {
                    return scientificCategoryApi.GetScientificCategoriesTree(source);
                }),
            );

            const trees: ScientificCategoryTrees = {};
            let sourceIndex = 0;
            for (const source of scientificCategoriesSources) {
                trees[source] = categories[sourceIndex];
                sourceIndex += 1;
            }

            await dispatch(reducerActions.setScientificCategoryTrees(trees));
        }
    };

export const load =
    (options: { urlParams: URLParams }): ThunkAction =>
    async (dispatch, getState) => {
        const state = getState();
        const companyOrBranchManagerOwnershipIds = selectLoggedInCompanyOrBranchManagerOwnershipIds(state);
        const ownership = selectLoggedInUserOwnership(state);
        const country = selectLoggedInCompanyOrBranchManagerCountry(state);

        if (ownership.type !== 'branch' && ownership.type !== 'company') {
            throw new Error('Wrong ownership');
        }

        const type = options.urlParams.type;
        if (Domain.ProductSelectionTypes.indexOf(type) === -1) {
            throw new Error('Wrong selection type');
        }

        dispatch(reducerActions.setSelectionType(type));

        await Promise.all([
            ownership.type === 'branch'
                ? dispatch(loadProductAvailabilityIntegrations(companyOrBranchManagerOwnershipIds.companyId, ownership.ownerId))
                : undefined,
            dispatch(categoriesTreeState.actions.load(options)),
            dispatch(loadScientificCategoryTrees(country)),
            dispatch(loadSelection(ownership, type)),
        ]);
    };
