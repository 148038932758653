import * as React from 'react';

import moment from 'moment';

import { Infrastructure } from 'api';

import { Calendar } from './Calendar';
import { PrevNextMonth } from './PrevNextMonth';

import styles from './DatePicker.scss';

interface IProps {
    month?: moment.Moment;
    date?: moment.Moment;
    min?: moment.Moment;
    max?: moment.Moment;
    availableDates?: moment.Moment[];
    onChange?: (newValue: moment.Moment) => void;
    autoFocusedDay?: React.Ref<any>;
}

export const DatePicker = React.memo(
    (props: IProps) => {
        let branchTimezone = 'UTC';
        try {
            branchTimezone = Infrastructure.Container.getConstant('branchTimezone') || 'UTC';
        } catch (e) {
            console.error('Failed to get branch timezone', e);
        }
        const [visibleMonth, setVisibleMonth] = React.useState(props.month || moment().tz(branchTimezone || 'UTC'));

        return (
            <div className={styles.DatePicker}>
                <PrevNextMonth
                    onChange={offset => {
                        setVisibleMonth(visibleMonth.clone().add(offset, 'months'));
                    }}
                />

                <Calendar
                    visibleMonth={visibleMonth}
                    min={props.min}
                    max={props.max}
                    availableDates={props.availableDates}
                    selectedDate={props.date}
                    onChange={newValue => {
                        if (props.onChange) {
                            let changedValue = newValue;

                            if (props.date) {
                                changedValue = props.date.clone();
                                changedValue.year(newValue.year());
                                changedValue.dayOfYear(newValue.dayOfYear());
                            }

                            props.onChange(changedValue);
                        }
                    }}
                    autoFocusedDay={props.autoFocusedDay}
                />
            </div>
        );
    },
    (prevProps: Readonly<IProps>, nextProps: Readonly<IProps>) => {
        return (prevProps.date && nextProps.date && prevProps.date.isSame(nextProps.date, 'day')) || prevProps.date === nextProps.date;
    },
);
