import { Domain } from 'api';

export function getScreenSizeInMM(screenResolution: Domain.DeviceScreenResolution): {
    width: number;
    height: number;
} {
    // 1080x1920
    const size = {
        width: 680,
        height: 1200,
    };

    if (screenResolution === '1920x1080') {
        size.width = 1200;
        size.height = 680;
    }

    if (screenResolution === '1280x1024') {
        size.width = 376;
        size.height = 301;
    }

    if (screenResolution === '2112x1048') {
        size.width = 1200;
        size.height = 595;
    }

    return size;
}
