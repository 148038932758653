import * as React from 'react';

import { Icon } from 'ui-components';

import { Actions, store } from '../state';

export default function ClearShelfAction(props: { shelfId: number; shelfHeight: number; nextShelfId: number }) {
    const { dispatch } = React.useContext(store);

    return (
        <a
            href=""
            onClick={event => {
                event.preventDefault();

                dispatch({
                    type: Actions.CanvasActions.HISTORY_SAVE,
                });

                dispatch({
                    type: Actions.REMOVE_SHELF,
                    shelfId: props.shelfId,
                });

                dispatch({
                    type: Actions.SET_SHELF_Y_OFFSET,
                    shelfId: props.nextShelfId,
                    offset: -props.shelfHeight,
                    position: 'below',
                });
            }}
        >
            <Icon type="action_delete" />
        </a>
    );
}
