import { DeviceMonitoringOverview, DeviceTeamViewerOverview } from '@/Domain';

import Endpoint from './Endpoint';

export default class DeviceMonitoringEndpoint extends Endpoint {
    public async GetMonitoringOverview(): Promise<DeviceMonitoringOverview> {
        const response = await this.client.get({
            url: '/device/monitoring',
        });

        this.guardResponseSchema('#/definitions/DeviceMonitoringOverview', response);

        return response;
    }

    public async GetTeamViewerOverview(): Promise<DeviceTeamViewerOverview> {
        const response = await this.client.get({
            url: '/device/teamviewer',
        });

        this.guardResponseSchema('#/definitions/DeviceTeamViewerOverview', response);

        return response;
    }
}
