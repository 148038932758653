/* eslint-disable @typescript-eslint/no-var-requires */
import ApiClient from '@/Infrastructure/Api/ApiClient';
import ApiException from '@/Infrastructure/Api/ApiException';
import { getService, getConstant } from '@/Infrastructure/Container';

declare const __DEV__: boolean;

export default abstract class Endpoint {
    protected client: ApiClient;

    public constructor() {
        this.client = getService('apiClient');
    }

    protected guardResponseSchema(schema: object | string, response: object) {
        if (__DEV__) {
            const Ajv = require('ajv');
            const validator = new Ajv();

            const throwException = getConstant('apiValidationThrowsException');

            if (typeof schema === 'string') {
                const allSchemas = require('../Domain/schema.json');
                validator.addSchema(allSchemas);
            }

            const valid = validator.validate(schema, response);

            if (!valid) {
                if (throwException) {
                    let errorMessage = 'Invalid response schema';
                    const messages = [errorMessage, this.client.getLastRequestInformation(), schema];

                    if (validator.errors) {
                        messages.push(JSON.stringify(validator.errors, null, 2));
                    }

                    errorMessage = messages.join('\n');

                    throw new ApiException(errorMessage);
                } else {
                    console.warn('Invalid response schema', this.client.getLastRequestInformation(), schema, validator.errors);
                }
            }
        }
    }
}
