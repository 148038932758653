import { createAction, Selector, createReducer } from '@reduxjs/toolkit';

import { Domain } from 'api';

import { ThunkAction, withPayloadType } from '@/action';
import { productCategoryApi, productSelectionApi, branchApi } from '@/api';
import { isValidLocale } from '@/I18n';
import { URLParams } from '@/routing';
import { RootState } from '@/store';
import { selectGlobalSelectedWebshop } from '@/Webshop/globalSelectionState';

import { selectedCategoriesTreeBuilder } from './selectedCategoriesTreeBuilder';

export interface State {
    selection?: Domain.ProductSelection;
    categoriesTree?: Domain.AvailableProductCategory;
    openingHours?: Domain.OpeningHours;
}

const initialState: State = {};

const reducerActions = {
    setSelection: createAction('@webshopPage/create/setSelection', withPayloadType<Domain.ProductSelection>()),
    setCategoriesTree: createAction('@webshopPage/create/setCategoriesTree', withPayloadType<Domain.AvailableProductCategory>()),
    setOpeningHours: createAction('@webshopPage/create/setOpeningHours', withPayloadType<Domain.OpeningHours | undefined>()),
};

export const reducer = createReducer(initialState, builder =>
    builder
        .addCase(reducerActions.setSelection, (state, action) => {
            state.selection = action.payload;
        })
        .addCase(reducerActions.setCategoriesTree, (state, action) => {
            state.categoriesTree = action.payload;
        })
        .addCase(reducerActions.setOpeningHours, (state, action) => {
            state.openingHours = action.payload;
        }),
);

export const selectSelection: Selector<RootState, Domain.ProductSelection> = state => {
    const selection = state.webshopPage.create.selection;
    if (!selection) {
        throw new Error('Selection not loaded');
    }
    return selection;
};

export const selectAllCategoriesTree: Selector<RootState, Domain.AvailableProductCategory> = state => {
    const categories = state.webshopPage.create.categoriesTree;

    if (!categories) {
        throw new Error('Categories not loaded');
    }

    return categories;
};

export const selectSelectedCategoriesTree: Selector<RootState, Domain.AvailableProductCategory | undefined> = state => {
    const categories = state.webshopPage.create.categoriesTree;
    const selection = selectSelection(state);

    if (!categories) {
        throw new Error('Categories not loaded');
    }

    return selectedCategoriesTreeBuilder(categories, selection);
};

export const selectOpeningHours: Selector<RootState, Domain.OpeningHours | undefined> = state => state.webshopPage.create.openingHours;

export const loadSelection =
    (branchId: string): ThunkAction =>
    async dispatch => {
        const selection = await productSelectionApi.GetProductSelection(
            {
                type: 'branch',
                ownerId: branchId,
            },
            'webShopProductSelection',
        );
        dispatch(reducerActions.setSelection(selection));
    };

export const loadCategoriesTree =
    (locale: Domain.Locale): ThunkAction =>
    async dispatch => {
        const categoriesTree = await productCategoryApi.GetCategoriesTree(locale);
        await dispatch(reducerActions.setCategoriesTree(categoriesTree));
    };

export const loadOpeningHours =
    (branchId: string): ThunkAction =>
    async dispatch => {
        const branchDetails = await branchApi.GetBranchDetails(branchId);
        await dispatch(reducerActions.setOpeningHours(branchDetails.openingHours || undefined));
    };

export const load =
    (options: { urlParams: URLParams }): ThunkAction =>
    async (dispatch, getState) => {
        const locale = options.urlParams.locale;
        const state = getState();
        const webshop = selectGlobalSelectedWebshop(state);

        if (!webshop) {
            return;
        }

        if (!isValidLocale(locale)) {
            throw new Error('Invalid locale');
        }

        await Promise.all([
            dispatch(loadCategoriesTree(locale)),
            dispatch(loadSelection(webshop.branchId)),
            dispatch(loadOpeningHours(webshop.branchId)),
        ]);
    };
