import { combineReducers } from '@reduxjs/toolkit';

import { detailsReducer } from './detailsState';
import { overviewReducer } from './overviewState';
import { subscribedNotificationsReducer } from './subscribedNotificationsState';
import { updateReducer } from './updateState';

export const userReducer = combineReducers({
    details: detailsReducer,
    overview: overviewReducer,
    update: updateReducer,
    subscribedNotifications: subscribedNotificationsReducer,
});
