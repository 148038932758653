import { Domain } from 'api';

import { deviceApi } from '@/api';
import { makeActions, makeReducer, makeReducerActions, makeSelectors } from '@/makeOverviewState';

export type DeviceProps = keyof Domain.Device;

export const defaultSorting: Domain.Sorting<DeviceProps> = {
    field: 'name',
    direction: 'ascending',
};

export const pageSize = 20;

export const selectors = makeSelectors<Domain.Device, DeviceProps>({
    getState: rootState => rootState.device.overview,
});

export const reducerActions = makeReducerActions<Domain.Device, DeviceProps>({
    reducerPrefix: '@device/overview',
});

export const actions = makeActions<
    Domain.Device,
    DeviceProps,
    {
        isActive?: 'any' | 'yes' | 'no';
        standbyMode?: 'yes' | 'no';
    }
>({
    dataTableSaveKey: 'devicesOverview-v5',
    loadApi: options =>
        deviceApi.GetDevices(
            options.ownership,
            options.pagination,
            options.sorting,
            options.search,
            options.filters
                ? {
                      ...options.filters,
                      isActive: options.filters.isActive ? options.filters.isActive === 'yes' : undefined,
                  }
                : undefined,
        ),
    defaultSorting,
    pageSize,
    getBaseUrl: ownership => {
        return ownership.type === 'company' ? '/company/devices' : ownership.type === 'branch' ? '/branch/devices' : '/customers/devices';
    },
    reducerActions,
    selectors,
});

export const overviewReducer = makeReducer<Domain.Device, DeviceProps>({
    defaultSorting,
    pageSize,
    reducerActions,
});
