import * as React from 'react';

import classNames from 'classnames';

import { store } from '../state';
import { Frame } from '../types';

import styles from './styles/TouchTargets.scss';

export default function TouchTargets(props: {
    className?: string;
    style?: React.CSSProperties;
    scaleTo?: {
        width: number;
        height: number;
    };
    onProductClick: (productId: string) => void;
    onHiddenSlideClick: (slideId: string) => void;
}) {
    const { scaleTo, onProductClick, onHiddenSlideClick } = props;
    const { state } = React.useContext(store);
    const scaleFactor = scaleTo ? scaleTo.width / state.canvas.width : 1;

    return (
        <div
            className={classNames(props.className, styles.TouchTargets)}
            style={{
                ...props.style,
                transform: 'scale(' + scaleFactor + ')',
                width: (scaleTo ? scaleTo.width : state.canvas.width) + 'px',
                height: (scaleTo ? scaleTo.height : state.canvas.height) + 'px',
            }}
        >
            <div
                className={classNames(styles.Body, state.screenResolution)}
                style={{
                    width: state.canvas.width + 'px',
                    height: state.canvas.height + 'px',
                }}
            >
                {state.canvas.frames.map((frame: Frame) => {
                    if (frame.type !== 'product' && frame.type !== 'hotSpot') {
                        return null;
                    }

                    let clickAction: () => void;
                    let productId: string | undefined;
                    let hiddenSlideId: string | undefined;
                    if (
                        (frame.type === 'product' && frame.productId) ||
                        (frame.type === 'hotSpot' && frame.hotSpotType === 'productDetails' && frame.productId)
                    ) {
                        productId = frame.productId;
                        clickAction = () => onProductClick(productId!);
                    } else if (
                        frame.type === 'hotSpot' &&
                        frame.hotSpotType === 'hiddenSlide' &&
                        frame.hiddenSlideType &&
                        frame.hiddenSlideId
                    ) {
                        hiddenSlideId = frame.hiddenSlideId;
                        clickAction = () => onHiddenSlideClick(hiddenSlideId!);
                    }

                    return (
                        <a
                            key={frame.frameId}
                            href=""
                            className={styles.PlacedProduct}
                            style={{
                                top: frame.y + 'px',
                                left: frame.x + 'px',
                                height: frame.height + 'px',
                                width: frame.width + 'px',
                                zIndex: (frame.depth || 0) + 999,
                            }}
                            onClick={e => {
                                e.preventDefault();
                                clickAction();
                            }}
                            data-test-id={productId ? `product-${productId}` : undefined}
                        >
                            &nbsp;
                        </a>
                    );
                })}
            </div>
        </div>
    );
}
