import { createAction, createReducer } from '@reduxjs/toolkit';

import { withPayloadType } from '@/action';

import { Notification, NotificationState, State } from './types';

export const addNotification = createAction('@notifications/addNotification', withPayloadType<Notification>());
export const removeNotification = createAction(
    '@notifications/removeNotification',
    withPayloadType<{
        notificationId: string;
    }>(),
);
export const setNotificationState = createAction(
    '@notifications/setNotificationState',
    withPayloadType<{
        notificationId: string;
        state: NotificationState;
    }>(),
);

const initialState: State = {
    maxVisibleNotifications: 2,
    notifications: [],
};

export const notificationsReducer = createReducer(initialState, builder =>
    builder
        .addCase(addNotification, (state, action) => {
            state.notifications = [
                {
                    notification: action.payload,
                    state: 'adding',
                },
                ...state.notifications,
            ];
        })
        .addCase(removeNotification, (state, action) => {
            state.notifications = [
                ...state.notifications.filter(notification => notification.notification.notificationId !== action.payload.notificationId),
            ];
        })
        .addCase(setNotificationState, (state, action) => {
            state.notifications = [
                ...state.notifications.map(notification => {
                    if (notification.notification.notificationId === action.payload.notificationId) {
                        return {
                            ...notification,
                            state: action.payload.state,
                        };
                    }
                    return notification;
                }),
            ];
        }),
);
