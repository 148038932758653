export interface StartDeviceTransactionPayload {
    externalReferenceId: string;
    amountInEuroCents: number;
    transactionId: string;
    deviceId: string;
}

export interface CancelDeviceTransactionPayload {
    transactionId: string;
    deviceId: string;
}
export const VENDING_MACHINE_PAYMENT_STATUS_FILTERS = ['CANCEL', 'EXPIRED', 'PENDING', 'PAID', 'DENIED', 'FAILURE'] as const;
export const VENDING_MACHINE_PAYMENT_STATUS = [
    'CANCEL',
    'EXPIRED',
    'REFUNDING',
    'REFUND',
    'PENDING',
    'VERIFY',
    'AUTHORIZE',
    'PARTLY_CAPTURED',
    'PAID',
    'PAID_CHECKAMOUNT',
    'FAILURE',
    'DENIED',
    'CHARGEBACK',
    'PARTIAL_REFUND',
    'PARTIAL_PAYMENT',
    'REQUEST',
] as const;

export type DeviceTransactionPaymentStatus = (typeof VENDING_MACHINE_PAYMENT_STATUS)[number];

export interface DeviceTransactionStatus {
    transactionId: string;
    transactionPaymentStatus?: DeviceTransactionPaymentStatus;
}

export function deviceTransactionPaymentStatusIsIntermediary(status: DeviceTransactionPaymentStatus) {
    if (status === 'PENDING' || status === 'VERIFY' || status === 'AUTHORIZE' || status === 'PARTIAL_PAYMENT') {
        return true;
    }

    return false;
}
