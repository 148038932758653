import * as React from 'react';

import { createRoot } from 'react-dom/client';

import { config } from 'config';
import { deviceSignalingApi, deviceBackendApi, makeQuery, meditechDeviceApi } from 'data-store';
import { DeviceLog, setApplication } from 'logger';
import { webRTCShim, browserStorage } from 'utils';

import App from '@/Infrastructure/App/App';
import ErrorBoundary from '@/Pages/TVScreen/ErrorBoundary';

import routes from './tv-routes';

import './tv.scss';

if (config.useDebugConsole) {
    deviceSignalingApi.startDebugConsole();
}
deviceBackendApi.initialize();

webRTCShim();

document.documentElement.style.overflow = 'hidden';

if (config.featureFlags.disableDevDeviceSignalingAndMonitoring) {
    deviceSignalingApi.disable();
}

export const render = () => {
    setApplication('device');

    const query = makeQuery(window.location.search);
    const macAddress = query.get('mac_address');
    if (macAddress) {
        DeviceLog.setDeviceMac(macAddress);
    }

    meditechDeviceApi.SetLocalcomDisabled(query.get('disable_localcom') === 'yes');

    DeviceLog.tvAppLoaded();
    DeviceLog.setDeviceFingerprint(browserStorage.getDeviceFingerprint());

    const container = document.getElementById('root');
    const root = createRoot(container!);
    root.render(
        <ErrorBoundary>
            <App routes={routes} />
        </ErrorBoundary>,
    );
};
