import FetchPony from 'fetch-ponyfill';

import { maybeGetConstant, getConstant } from '@/Infrastructure/Container';

import ApiClient, { UrlParams, Headers } from './ApiClient';
import HttpApiException from './HttpApiException';
import urlParamsToString from './urlParamsToString';

const { fetch } = FetchPony({
    Promise,
});

const BASE_HEADERS = {
    Accept: 'application/json',
    'Referrer-Policy': 'no-referrer-when-downgrade',
};

export default class HttpApiClient implements ApiClient {
    private lastRequestInformation: string | undefined;

    public async get(options: {
        url: string;
        urlIsExternal?: boolean;
        params?: UrlParams;
        headers?: Headers;
        noDefaultHeaders?: boolean;
        noAuthHeader?: boolean;
    }): Promise<any> {
        return this.request({
            method: 'GET',
            url: options.urlIsExternal ? options.url : this.buildApiURL(options.url),
            params: options.params,
            headers: {
                ...(options.noDefaultHeaders ? {} : BASE_HEADERS),
                ...(options.urlIsExternal || options.noAuthHeader ? {} : this.getAuthHeader()),
                ...(options.urlIsExternal ? {} : this.getLocaleHeader()),
                ...options.headers,
            },
        });
    }

    public async post(options: {
        url: string;
        urlIsExternal?: boolean;
        body: object | string;
        params?: UrlParams;
        headers?: Headers;
        bodyIsFormData?: boolean;
        bodyIsString?: boolean;
        noDefaultHeaders?: boolean;
    }): Promise<any> {
        return this.request({
            method: 'POST',
            url: options.urlIsExternal ? options.url : this.buildApiURL(options.url),
            params: options.params,
            body: options.bodyIsFormData
                ? this.buildFormData(options.body as object)
                : options.bodyIsString
                  ? (options.body as string)
                  : JSON.stringify(options.body as object),
            headers: {
                ...(options.noDefaultHeaders ? {} : BASE_HEADERS),
                ...(options.urlIsExternal ? {} : this.getAuthHeader()),
                ...(options.urlIsExternal ? {} : this.getLocaleHeader()),
                ...options.headers,
            },
        });
    }

    public buildApiURL(urlPart: string, withAuthParam?: boolean, params?: UrlParams): string {
        let url = getConstant('apiBaseUrl') + urlPart;
        let allParams: UrlParams = {};

        if (withAuthParam) {
            const token = getConstant('apiToken');

            if (!token) {
                throw new HttpApiException('No apiToken set');
            }

            allParams.auth = token;
        }

        allParams = {
            ...allParams,
            ...params,
        };

        url += urlParamsToString(allParams);

        return url;
    }

    public getLastRequestInformation() {
        return this.lastRequestInformation;
    }

    private async request(options: {
        method: 'GET' | 'POST';
        url: string;
        body?: string | FormData;
        params?: UrlParams;
        headers?: Headers;
    }): Promise<object> {
        let response;
        let responseText;

        this.lastRequestInformation = `${options.method} ${options.url}`;

        try {
            response = await fetch(options.url + urlParamsToString(options.params), {
                method: options.method,
                body: options.body,
                headers: options.headers,
            });
            responseText = await response.text();

            if (response.status !== 200) {
                let message = responseText;
                let shortMessage: string | undefined = undefined;

                try {
                    const data = JSON.parse(responseText);
                    if (data.message) {
                        shortMessage = data.message;
                        message = data.message + '\n';
                    } else {
                        message = '';
                    }
                    message += JSON.stringify(data, null, 2);
                } catch (e) {
                    if (e instanceof SyntaxError) {
                        // ignore
                    } else {
                        // noinspection ExceptionCaughtLocallyJS
                        throw e;
                    }
                }

                // noinspection ExceptionCaughtLocallyJS
                throw new HttpApiException(message, response.status, response.statusText, shortMessage);
            }

            return JSON.parse(responseText);
        } catch (e) {
            if (e instanceof HttpApiException) {
                throw e;
            }

            if (e instanceof SyntaxError) {
                e.message = 'JSON Parse error: ' + e.message;
            }

            throw new HttpApiException(
                (e as Error).message,
                response ? response.status : undefined,
                response ? response.statusText : undefined,
            );
        }
    }

    private getAuthHeader(): Headers {
        const token = getConstant('apiToken');

        if (token !== null) {
            return {
                Authorization: token,
            };
        }

        return {};
    }

    private getLocaleHeader(): Headers {
        const locale = maybeGetConstant('locale');

        if (locale !== null) {
            return {
                Locale: locale,
            };
        }

        return {};
    }

    private buildFormData(data: object): FormData {
        const formData = new FormData();

        for (const key in data) {
            if (!data.hasOwnProperty(key)) {
                continue;
            }

            formData.append(key, (data as any)[key]);
        }

        return formData;
    }
}
