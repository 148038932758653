import * as React from 'react';
import { useLocation } from 'react-router-dom';

import { BaseProps } from '@/core';
import { Tx } from '@/typography';

import { AppCoreContext, Location } from './AppCoreContext';

import styles from './AppCore.scss';

export default class AppCore extends React.PureComponent<BaseProps & React.HTMLProps<HTMLDivElement>> {
    componentDidMount(): void {
        if (!document.getElementById('app-portal-container')) {
            const appPortalContainer = document.createElement('div');
            appPortalContainer.id = 'app-portal-container';
            appPortalContainer.classList.add('AppCore');
            document.body.appendChild(appPortalContainer);
        }
    }

    render() {
        const { className, darkMode, style, children } = this.props;
        return (
            <Tx
                style={style}
                className={`${styles.AppCore} ${darkMode ? styles.DarkMode : ''} ${className || ''}`}
                as="div"
            >
                <AppCoreContext.Provider
                    value={{
                        handleLocationChange: this.handleLocationChange,
                        handleLocationChangeComplete: this.handleLocationChangeComplete,
                    }}
                >
                    {children}
                </AppCoreContext.Provider>
            </Tx>
        );
    }

    private handleLocationChange = (newLocation: Location, oldLocation?: Location) => {
        if (!oldLocation || locationKey(newLocation) !== locationKey(oldLocation)) {
            document.documentElement.scrollTop = 0;
        }
    };

    private handleLocationChangeComplete = () => {
        const { darkMode } = this.props;
        const appPortalContainer = document.getElementById('app-portal-container');

        if (appPortalContainer) {
            appPortalContainer.classList.remove('DarkMode');
            if (darkMode) {
                appPortalContainer.classList.add('DarkMode');
            }
        }
    };
}

const locationKey = (location: ReturnType<typeof useLocation>) => {
    return location.pathname + location.search + location.hash;
};
