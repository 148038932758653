import * as React from 'react';

import classNames from 'classnames';

import { BaseProps } from '@/core';

export type RowValign = 'stretch' | 'start' | 'center' | 'end' | 'baseline';

interface IProps {
    vAlign?: RowValign;
    justify?: 'start' | 'end' | 'center' | 'between' | 'around';
    wrap?: boolean;
    refCallback?: (element: HTMLDivElement | null) => void;
    reverseSm?: boolean;
}

export default class Row extends React.PureComponent<Omit<React.HTMLProps<HTMLDivElement>, 'wrap'> & BaseProps & IProps> {
    render() {
        const { children, refCallback, vAlign, justify, wrap, reverseSm, ...rest } = this.props;

        return (
            <div
                {...rest}
                ref={refCallback}
                className={this.getClassName()}
            >
                {children}
            </div>
        );
    }

    protected getClassName(): string {
        const { vAlign, justify, wrap, reverseSm } = this.props;

        return classNames(
            'Row',
            vAlign ? 'v-align-' + vAlign : null,
            justify ? 'justify-' + justify : null,
            wrap ? 'wrap' : null,
            reverseSm ? 'reverse-sm' : null,
            this.props.className,
        );
    }
}
