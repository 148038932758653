import * as React from 'react';

import { BaseProps } from '@/core';

import Tabs, { ITabItem, ITabVariant } from './Tabs';

interface IState {
    activeTabIndex: number | null;
}

type IProps<T> = BaseProps &
    React.HTMLProps<HTMLDivElement> & {
        defaultActiveIndex?: number;
        items: (ITabItem<T> | undefined)[];
        variant?: ITabVariant;
        fullWidth?: boolean;
    };

export default class StatefulTabs<T = Record<string, unknown>> extends React.PureComponent<IProps<T>, IState> {
    constructor(props: IProps<T>) {
        super(props);

        this.state = {
            activeTabIndex: props.items.length > 0 ? (props.defaultActiveIndex ? props.defaultActiveIndex : 0) : null,
        };
    }

    render() {
        const { defaultActiveIndex, items, ...rest } = this.props;

        return (
            <Tabs
                {...(rest as any)}
                items={items.filter(Boolean)}
                activeTabIndex={this.state.activeTabIndex}
                onActiveTabIndexChange={activeTabIndex => this.setState({ activeTabIndex })}
            />
        );
    }
}
