import * as React from 'react';
import { Selector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { Domain, Infrastructure } from 'api';

import { ThunkAction } from './action';
import { RootState } from './store';

export interface URLParams {
    [key: string]: string;
}

export interface URLQuery {
    get: (name: string) => string | null;
    getAll: (name: string) => string[] | null;
    getInteger: (name: string) => number | null;
}

export type IsAuthorizedAction = Selector<RootState, boolean>;
export type PreloadDataAction = (options: { urlParams?: URLParams; urlQuery?: URLQuery }) => ThunkAction;

export interface BaseRoute {
    path: string;
    isAuthorized: IsAuthorizedAction | IsAuthorizedAction[];
}

export interface RedirectRoute {
    redirect: string;
}

export interface RouteRoute {
    main: () => Promise<any>;
    preloading?: React.ComponentType;
    preloadData?: PreloadDataAction;
}

export type DeviceRoute = RouteRoute & {
    isDevice: true;
};

export type BrowsershotRoute = RouteRoute & {
    isBrowsershot: true;
};

export type Route = BaseRoute & (RouteRoute | DeviceRoute | BrowsershotRoute | RedirectRoute);

export function makeQuery(search: string): URLQuery {
    const query = new URLSearchParams(search);

    return {
        get: (name: string) => query.get(name),
        getAll: (name: string) => query.getAll(name),
        getInteger: (name: string) => {
            const val = query.get(name);

            if (val === null) {
                return null;
            }

            const intVal = parseInt(val, 10);

            if (!isFinite(intVal)) {
                throw new Error(`Value of query parameter "${name}" is not an integer`);
            }

            return intVal;
        },
    };
}

export function useQuery(): URLQuery {
    return makeQuery(useLocation().search);
}

interface OverviewParams {
    search: string | null;
    filters: {
        [key: string]: string;
    } | null;
    page: number | null;
    pageSize: number | null;
    sorting: Domain.Sorting | null;
    viewMode: Domain.ViewMode | null;
}

export function getOverviewQueryParameters(options: {
    urlQuery: URLQuery;
    defaultSorting?: Domain.Sorting;
    defaultViewMode?: Domain.ViewMode;
    viewModeParameter?: string;
    searchParameter?: string;
    filterParameter?: string;
    pageParameter?: string;
    pageSizeParameter?: string;
    sortFieldParameter?: string;
    sortDirectionParameter?: string;
    sortValueParameter?: string;
}): OverviewParams {
    if (!options.viewModeParameter) {
        options.viewModeParameter = 'v';
    }

    if (!options.searchParameter) {
        options.searchParameter = 'q';
    }

    if (!options.filterParameter) {
        options.filterParameter = 'f';
    }

    if (!options.pageParameter) {
        options.pageParameter = 'p';
    }

    if (!options.pageSizeParameter) {
        options.pageSizeParameter = 'ps';
    }

    if (!options.sortFieldParameter) {
        options.sortFieldParameter = 's';
    }

    if (!options.sortDirectionParameter) {
        options.sortDirectionParameter = 'd';
    }
    if (!options.sortValueParameter) {
        options.sortValueParameter = 'sv';
    }

    const overviewParams: OverviewParams = {
        search: options.urlQuery.get(options.searchParameter),
        filters: JSON.parse(options.urlQuery.get(options.filterParameter) || '{}'),
        page: options.urlQuery.getInteger(options.pageParameter),
        pageSize: options.urlQuery.getInteger(options.pageSizeParameter),
        sorting: null,
        viewMode: null,
    };

    const viewModeField = options.urlQuery.get(options.viewModeParameter);
    overviewParams.viewMode = options.defaultViewMode ? options.defaultViewMode : null;
    if (viewModeField && (viewModeField === 'grid' || viewModeField === 'list')) {
        overviewParams.viewMode = viewModeField;
    }

    const urlSortingField = options.urlQuery.get(options.sortFieldParameter);
    overviewParams.sorting = options.defaultSorting ? options.defaultSorting : null;
    if (urlSortingField !== null) {
        overviewParams.sorting = {
            field: urlSortingField,
            direction: options.defaultSorting ? options.defaultSorting.direction : 'ascending',
            value: options.defaultSorting ? options.defaultSorting.value : undefined,
        };
    }

    const urlSortingDirection = options.urlQuery.get(options.sortDirectionParameter);
    const urlSortingValue = options.urlQuery.get(options.sortValueParameter);
    if (urlSortingDirection === 'ascending' || urlSortingDirection === 'descending') {
        overviewParams.sorting = {
            field: overviewParams.sorting ? overviewParams.sorting.field : options.defaultSorting ? options.defaultSorting.field : '',
            direction: urlSortingDirection,
            value: urlSortingValue || undefined,
        };
    }

    return overviewParams;
}

export function updateURLWithQueryParams(
    params: {
        baseURL: string;
        navigate: ReturnType<typeof useNavigate>;
        pagination?: Domain.Pagination;
        sorting?: Domain.Sorting;
        viewMode?: Domain.ViewMode;
        search?: string;
        defaultSorting?: Domain.Sorting;
        defaultViewMode?: Domain.ViewMode;
        filters?: {
            [key: string]: string | undefined;
        };
    },
    options?: {
        replace?: boolean;
    },
): boolean {
    const { sorting, search, pagination } = params;
    if (!params.defaultSorting) {
        params.defaultSorting = {
            field: '',
            direction: 'ascending',
            value: '',
        };
    }

    const s = sorting && sorting.field !== params.defaultSorting.field ? sorting.field : null;
    const sv = sorting && sorting.value !== params.defaultSorting.value ? sorting.value : null;
    const urlParams = Infrastructure.Api.urlParamsToString({
        q: search ? search : null,
        p: pagination && pagination.page && pagination.page !== 1 ? pagination.page : null,
        s,
        d: sorting && (s || sorting.direction !== params.defaultSorting.direction) ? sorting.direction : null,
        sv,
        v: params.viewMode && params.defaultViewMode && params.viewMode !== params.defaultViewMode ? params.viewMode : null,
        f: params.filters && Object.values(params.filters).filter(Boolean).length > 0 ? JSON.stringify(params.filters) : null,
    });

    const existingURL = window.location.pathname + window.location.search;
    const newURL = params.baseURL + (urlParams !== '?' ? urlParams : '');

    if (existingURL !== newURL) {
        params.navigate(newURL, options);
        return true;
    }

    return false;
}
