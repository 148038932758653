import { Domain } from 'api';

import { deviceMonitoringApi } from '@/api';
import { makeActions, makeReducer, makeReducerActions, makeSelectors } from '@/makeDetailsState';

export const selectors = makeSelectors<Domain.DeviceTeamViewerOverview>({
    getState: rootState => rootState.deviceMonitoring.teamViewerOverview,
});

export const reducerActions = makeReducerActions<Domain.DeviceTeamViewerOverview>({
    reducerPrefix: '@deviceMonitoring/teamViewerOverview',
});

export const actions = makeActions<Domain.DeviceTeamViewerOverview>({
    loadApi: () => deviceMonitoringApi.GetTeamViewerOverview(),
    reducerActions,
    selectors,
});

export const teamViewerOverviewReducer = makeReducer<Domain.DeviceTeamViewerOverview>({
    reducerActions,
});
