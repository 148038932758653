import * as React from 'react';

import PreloaderProgress, { PreloaderProgressWrap } from '@/components/PreloaderProgress';

export default function PreloaderScreen() {
    return (
        <PreloaderProgressWrap fixed={true}>
            <PreloaderProgress />
        </PreloaderProgressWrap>
    );
}
