import * as React from 'react';

import { CanvasFrame } from 'editor-canvas';
import { HTMLEditorView, RTValue, getCSSPropsFromRTValue } from 'ui-components';
import { PharmacyOnDuty } from 'utils';

import type { PharmacistOnDutyFrame } from '../types';

export default function PharmacistOnDutyFrame(props: {
    gettext: (text: string) => string;
    frame: CanvasFrame & PharmacistOnDutyFrame;
    getPharmaciesOnDuty: (numPharmacists: number, locale: string) => PharmacyOnDuty[];
    style?: React.CSSProperties;
    locale: string;
}) {
    const { gettext, frame, style, locale, getPharmaciesOnDuty } = props;
    const pharmaciesOnDuty = getPharmaciesOnDuty(frame.numPharmacists, locale);
    let html = '';
    for (const pharmacyOnDuty of pharmaciesOnDuty) {
        html += `
            <div>${pharmacyOnDuty.pharmacy.name}</div>
            <div>${pharmacyOnDuty.pharmacy.getDescription(gettext) || ''}</div>
            <div>${pharmacyOnDuty.pharmacy.phoneNumber || ''}</div>
            <div>${pharmacyOnDuty.pharmacy.address}</div>
            <div>${pharmacyOnDuty.pharmacy.geoDescription || ''}</div>
            <div>
                ${
                    pharmacyOnDuty.travel?.road_distance && pharmacyOnDuty.travel?.road_time
                        ? gettext(':roadDistance: km / :roadTime: minutes driving distance')
                              .replace(':roadDistance:', pharmacyOnDuty.travel.road_distance.toFixed(1))
                              .replace(':roadTime:', (pharmacyOnDuty.travel.road_time * 100).toFixed(0))
                        : ''
                }
            </div>
            <br />
        `;
    }

    const frameStyle = new RTValue('', frame.textStyle);

    return (
        <HTMLEditorView
            style={style}
            innerStyle={getCSSPropsFromRTValue(frameStyle)}
            value={html}
        />
    );
}
