import { Domain } from 'api';
import { config } from 'config';
import { TVScreen, productWallApi, customPageApi, CustomPage, ProductWall, Media, deviceBackendApi, useThunkDispatch } from 'data-store';
import { preloadImage as legacyPreloadImage } from 'preloaders';

type SlidesListPreloadResults = (TVScreen.types.Slide | undefined)[];
type SlideContentsPreloadResults = TVScreen.types.Slide | undefined;
type Action<T = any> = () => T;
type PerformActionFunction<T = any> = (action: Action<T>) => T;

export default class SlideshowPreloaderService {
    private preloadCache: {
        [key: string]: Promise<void>;
    } = {};

    private timelinePreloadCache: {
        [key: string]: Promise<SlidesListPreloadResults>;
    } = {};

    private slideContentsCache: {
        [key: string]: Promise<TVScreen.types.Slide | undefined>;
    } = {};

    private allProducts: {
        [key: string]: Domain.SelectedProduct;
    } = {};

    private log: typeof console.log = () => undefined;

    public async preload(
        timeline: Domain.Timeline,
        device: Domain.DeviceDetails,
        usePreviewImageForContents: boolean,
        dispatch: ReturnType<typeof useThunkDispatch>,
    ): Promise<void> {
        const preloadKey =
            device.deviceId +
            '-' +
            timeline.timelineId +
            '-' +
            (usePreviewImageForContents ? 'y' : 'n') +
            '-' +
            device.hiddenSlides?.map(slide => slide.slideId).join('|');

        if (!this.preloadCache[preloadKey]) {
            this.log('1. PRELOAD', preloadKey);
            this.preloadCache[preloadKey] = this.doPreload(timeline, device, usePreviewImageForContents, dispatch);
        } else {
            this.log('> 1. PRELOAD CACHED', preloadKey);
        }

        return this.preloadCache[preloadKey];
    }

    public getAllProducts() {
        return Object.values(this.allProducts);
    }

    private async doPreload(
        timeline: Domain.Timeline,
        device: Domain.DeviceDetails,
        usePreviewImageForContents: boolean,
        dispatch: ReturnType<typeof useThunkDispatch>,
    ): Promise<void> {
        const queuedActions: Action[] = [];
        const queueAction = (action: Action) => {
            queuedActions.push(action);
        };

        const slideContents = await this.preloadTimeline(timeline, queueAction);
        const hiddenSlideContents = await this.preloadDeviceHiddenSlides(device.hiddenSlides || [], queueAction);

        await this.preloadMediaForSlideContents(
            slideContents.filter(Boolean) as TVScreen.types.Slide[],
            timeline,
            device,
            usePreviewImageForContents,
            queueAction,
        );

        await this.preloadMediaForSlideContents(
            hiddenSlideContents.filter(Boolean) as TVScreen.types.Slide[],
            timeline,
            device,
            false,
            queueAction,
        );

        this.log('4. PRELOADED MEDIA FOR SLIDE CONTENTS');

        for (const action of queuedActions) {
            dispatch(action());
        }

        this.log('X. PERFORMED ACTIONS');
    }

    private preloadTimeline(timeline: Domain.Timeline, performAction: PerformActionFunction): Promise<SlidesListPreloadResults> {
        if (!this.timelinePreloadCache[timeline.timelineId]) {
            this.log('2. PRELOAD TIMELINE CONTENTS');
            this.timelinePreloadCache[timeline.timelineId] = this.doPreloadTimeline(timeline, performAction);
        } else {
            this.log('> 2. PRELOAD CACHED TIMELINE CONTENTS');
        }

        return this.timelinePreloadCache[timeline.timelineId];
    }

    private preloadDeviceHiddenSlides(
        slides: Domain.DeviceHiddenSlides,
        performAction: PerformActionFunction,
    ): Promise<SlidesListPreloadResults> {
        this.log('3. PRELOAD HIDDEN SLIDE CONTENTS', slides);
        return Promise.all(slides.map(slide => this.doPreloadHiddenSlideContents(slide, performAction)));
    }

    private doPreloadTimeline(timeline: Domain.Timeline, performAction: PerformActionFunction): Promise<SlidesListPreloadResults> {
        return Promise.all(timeline.content.map(slide => this.preloadSlideContents(slide, performAction)));
    }

    private preloadSlideContents(slide: Domain.SlideshowSlide, performAction: PerformActionFunction): Promise<SlideContentsPreloadResults> {
        const slideContentsId = this.getSlideContentsId(slide);
        if (!slideContentsId) {
            return Promise.resolve(undefined);
        }

        if (!this.slideContentsCache[slideContentsId]) {
            this.log('2.1. PRELOAD SLIDE CONTENTS', slideContentsId);
            this.slideContentsCache[slideContentsId] = this.doPreloadSlideContents(slide, performAction);
        } else {
            this.log('> 2.1. PRELOAD CACHED SLIDE CONTENTS', slideContentsId);
        }

        return this.slideContentsCache[slideContentsId];
    }

    private doPreloadSlideContents(
        slide: Domain.SlideshowSlide,
        performAction: PerformActionFunction,
    ): Promise<SlideContentsPreloadResults> {
        const slideContentsId = this.getSlideContentsId(slide);

        if (!slideContentsId) {
            return Promise.resolve(undefined);
        }

        if (!this.slideContentsCache[slideContentsId]) {
            let contentPromise: Promise<TVScreen.types.SlideContents | undefined>;

            if (slide.type === 'productWall') {
                contentPromise = productWallApi.GetProductWallDetails(slide.productWallId);
            } else if (slide.type === 'customPage') {
                contentPromise = customPageApi.GetCustomPageDetails(slide.customPageId);
            } else if (slide.type === 'video' || slide.type === 'image') {
                contentPromise = Promise.resolve({
                    mediaItemId: slide.mediaItemId,
                    name: slide.mediaItemName,
                });
            } else {
                contentPromise = Promise.resolve(undefined);
            }

            this.slideContentsCache[slideContentsId] = contentPromise
                .then(content => {
                    if (content) {
                        return {
                            ...content,
                            id: slide.slideId,
                        };
                    }
                    return undefined;
                })
                .catch(e => {
                    throw e;
                });
        }

        this.slideContentsCache[slideContentsId].then(slideContents => {
            if (slideContents) {
                performAction(() => TVScreen.deviceState.reducerActions.addSlide(slideContents));
                if (slide.type === 'productWall') {
                    const products = (slideContents as Domain.ProductWall).products;
                    for (const product of products) {
                        if (this.allProducts.hasOwnProperty(product.productId)) {
                            continue;
                        }

                        this.allProducts[product.productId] = product;
                    }
                }
            }
        });

        return this.slideContentsCache[slideContentsId];
    }

    private doPreloadHiddenSlideContents(
        slide: Domain.DeviceHiddenSlide,
        performAction: PerformActionFunction,
    ): Promise<SlideContentsPreloadResults> {
        const slideContentsId = slide.itemId;

        if (!slideContentsId) {
            return Promise.resolve(undefined);
        }

        if (!this.slideContentsCache[slideContentsId]) {
            this.log('3.1. PRELOAD HIDDEN SLIDE CONTENTS', slideContentsId);

            let contentPromise: Promise<TVScreen.types.SlideContents | undefined>;

            if (slide.type === 'productWall') {
                contentPromise = productWallApi.GetProductWallDetails(slide.itemId);
            } else if (slide.type === 'customPage') {
                contentPromise = customPageApi.GetCustomPageDetails(slide.itemId);
            } else if (slide.type === 'video' || slide.type === 'image') {
                contentPromise = Promise.resolve({
                    mediaItemId: slide.itemId,
                    name: slide.name,
                });
            } else {
                contentPromise = Promise.resolve(undefined);
            }

            this.slideContentsCache[slideContentsId] = contentPromise
                .then(content => {
                    if (content) {
                        return {
                            ...content,
                            id: slide.slideId,
                        };
                    }
                    return undefined;
                })
                .catch(e => {
                    throw e;
                });
        } else {
            this.log('> 3.1. PRELOAD CACHED HIDDEN SLIDE CONTENTS', slideContentsId);
        }

        this.slideContentsCache[slideContentsId].then(slide => {
            if (slide) {
                performAction(() => TVScreen.deviceState.reducerActions.addHiddenSlide(slide));
            }
        });

        return this.slideContentsCache[slideContentsId];
    }

    private async preloadMediaForSlideContents(
        allSlideContents: TVScreen.types.Slide[],
        timeline: Domain.Timeline,
        device: Domain.DeviceDetails,
        usePreviewImageForContents: boolean,
        performAction: PerformActionFunction,
    ): Promise<void> {
        this.log('4. PRELOAD MEDIA');
        let mediaPreloadPromises: Promise<void>[] = [];
        const urlsToWarmUp: string[] = [];

        const { preloadMediaItem, preloadVideoMediaItemPreview, preloadImage } = this.getMediaPreloaderFunctions((url: string) => {
            if (urlsToWarmUp.indexOf(url) === -1) {
                urlsToWarmUp.push(url);
            }
        });

        for (const slideContents of allSlideContents) {
            if (!slideContents) {
                continue;
            }

            if (TVScreen.types.isProductWallSlideContents(slideContents)) {
                let isSharedWithMe = true;
                if (device && device.companyId === slideContents.companyId && device.branchId === slideContents.branchId) {
                    isSharedWithMe = false;
                }

                mediaPreloadPromises = mediaPreloadPromises.concat(
                    ProductWall.preloader.preloadProductWall(
                        isSharedWithMe ? false : usePreviewImageForContents,
                        slideContents.content as any,
                        slideContents.products,
                        slideContents.preview || '',
                        slideContents.locale,
                        preloadMediaItem,
                        preloadVideoMediaItemPreview,
                        preloadImage,
                    ),
                );
            } else if (TVScreen.types.isCustomPageSlideContents(slideContents)) {
                let isSharedWithMe = true;
                if (device && device.companyId === slideContents.companyId && device.branchId === slideContents.branchId) {
                    isSharedWithMe = false;
                }

                mediaPreloadPromises = mediaPreloadPromises.concat(
                    CustomPage.preloader.preloadCustomPage(
                        isSharedWithMe ? false : usePreviewImageForContents,
                        slideContents.content as any,
                        slideContents.products,
                        slideContents.preview || '',
                        slideContents.locale,
                        (slideType, slideId, name) => {
                            const hiddenSlideAlreadyOnDevice =
                                device && device.hiddenSlides && device.hiddenSlides.find(slide => slide.slideId === slideId);

                            if (!hiddenSlideAlreadyOnDevice) {
                                const hiddenSlide: Domain.DeviceHiddenSlide = {
                                    type: slideType,
                                    itemId: slideId,
                                    name,
                                    slideId,
                                };

                                performAction(() => TVScreen.deviceState.reducerActions.addDeviceHiddenSlide(hiddenSlide));

                                this.doPreloadHiddenSlideContents(hiddenSlide, performAction);
                            }
                        },
                        () => {
                            performAction(() => TVScreen.slideshowState.loadPharmaciesOnDuty());
                        },
                        preloadMediaItem,
                        preloadVideoMediaItemPreview,
                        preloadImage,
                        () => {
                            performAction(() => TVScreen.slideshowState.loadEmergencyAlerts());
                        },
                    ),
                );
            } else if (TVScreen.types.isMediaItemSlideContents(slideContents)) {
                let isVideo = false;
                const timelineSlide = timeline
                    ? timeline.content.find(timelineSlide => timelineSlide.slideId === slideContents.id)
                    : undefined;
                if (timelineSlide) {
                    if (timelineSlide.type === 'video') {
                        isVideo = true;
                    }
                } else if (device && device.hiddenSlides) {
                    const hiddenSlide = device.hiddenSlides.find(hiddenSlide => hiddenSlide.itemId === slideContents.mediaItemId);
                    if (hiddenSlide && hiddenSlide.type === 'video') {
                        isVideo = true;
                    }
                }

                mediaPreloadPromises = mediaPreloadPromises.concat(preloadMediaItem(slideContents.mediaItemId, isVideo));
                mediaPreloadPromises = mediaPreloadPromises.concat(preloadVideoMediaItemPreview(slideContents.mediaItemId));
            }
        }

        for (const slideContents of allSlideContents) {
            if (!slideContents) {
                continue;
            }

            if (TVScreen.types.isProductWallSlideContents(slideContents) && slideContents.preview) {
                mediaPreloadPromises.push(preloadImage(slideContents.preview));
            } else if (TVScreen.types.isCustomPageSlideContents(slideContents) && slideContents.preview) {
                mediaPreloadPromises.push(preloadImage(slideContents.preview));
            } else if (TVScreen.types.isMediaItemSlideContents(slideContents)) {
                mediaPreloadPromises.push(preloadImage(`${config.apiBaseUrl}/media-item/${slideContents.mediaItemId}/preview/450x450`));
            }
        }

        await Promise.all(mediaPreloadPromises);

        if (deviceBackendApi.cacheIsAvailable()) {
            this.log('5. WARM UP CACHE');
            await deviceBackendApi.warmUp(urlsToWarmUp);
        }
    }

    private getSlideContentsId(slide: Domain.SlideshowSlide): string | undefined {
        if (slide.isHidden) {
            return undefined;
        }

        if (slide.type === 'productWall') {
            return slide.productWallId;
        }

        if (slide.type === 'customPage') {
            return slide.customPageId;
        }

        if (slide.type === 'video' || slide.type === 'image') {
            return slide.mediaItemId;
        }
    }

    private getMediaPreloaderFunctions(encounteredMediaUrl: (url: string) => void) {
        let preloadMediaItem = Media.preloader.preloadMediaItem;
        let preloadVideoMediaItemPreview = Media.preloader.preloadVideoMediaItemPreview;
        let preloadImage = legacyPreloadImage;

        if (deviceBackendApi.cacheIsAvailable()) {
            preloadMediaItem = (mediaItemId: string | undefined) => {
                if (mediaItemId) {
                    const mediaItemUrl = `${config.apiBaseUrl}/media-item/${mediaItemId}/preview`;
                    encounteredMediaUrl(mediaItemUrl);
                }

                return Promise.resolve();
            };

            preloadVideoMediaItemPreview = (mediaItemId: string | undefined) => {
                if (mediaItemId) {
                    const mediaItemUrl = `${config.apiBaseUrl}/media-item/${mediaItemId}/preview/450x450`;
                    encounteredMediaUrl(mediaItemUrl);
                }

                return Promise.resolve();
            };

            preloadImage = (imageSrc: string) => {
                encounteredMediaUrl(imageSrc);
                return Promise.resolve();
            };
        }

        return {
            preloadMediaItem,
            preloadVideoMediaItemPreview,
            preloadImage,
        };
    }
}
