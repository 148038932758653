type Align = 'center' | 'justify' | 'left' | 'right';
type VerticalAlign = 'flex-start' | 'center' | 'flex-end';

export interface RTValueBaseOptions {
    font?: string;
    size?: number;
    color?: string;
    bold?: boolean;
    italic?: boolean;
    underline?: boolean;
    strikethrough?: boolean;
}

export type RTValueOptions = RTValueBaseOptions & {
    align?: Align;
    verticalAlign?: VerticalAlign;
    ranges?: {
        start: number;
        end: number;
        style: RTValueBaseOptions;
    }[];
};

export default class RTValue<T = string> {
    public readonly text: T;
    public readonly options?: RTValueOptions;

    constructor(text: T, options?: RTValueOptions) {
        this.text = text;
        this.options = {};

        if (options) {
            this.options.font = options.font;
            this.options.size = options.size;
            this.options.color = options.color;
            this.options.bold = options.bold;
            this.options.italic = options.italic;
            this.options.underline = options.underline;
            this.options.strikethrough = options.strikethrough;
            this.options.align = options.align;
            this.options.verticalAlign = options.verticalAlign;
            this.options.ranges = options.ranges;
        }
    }

    public setText(text: T) {
        return new RTValue<T>(text, this.options);
    }

    public setOption(optionName: string, optionValue: any) {
        return new RTValue<T>(this.text, {
            ...this.options,
            [optionName]: optionValue,
        });
    }

    public toJSON() {
        return {
            text: this.text,
            options: this.options,
        };
    }

    public toString() {
        return JSON.stringify(this.toJSON());
    }
}
