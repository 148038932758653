import { Selector, createSelector } from '@reduxjs/toolkit';

import { Domain } from 'api';

import { getCountryCodeFromLocale } from '@/getCountryCodeFromLocale';
import { ALL_LOCALES, COUNTRY_CONFIG, selectCountries } from '@/Localisation/availableCountriesState';
import { RootState } from '@/store';

import { AuthenticatedUser, CompanyManager, BranchManager, PlatformAdministrator, SupportAgent, Reseller } from './types';

export const maybeSelectLoggedInUser: Selector<RootState, AuthenticatedUser | undefined> = state => state.authentication.loggedInAs;

export const selectIsLoggedIn: Selector<RootState, boolean> = state => {
    const loggedInUser = maybeSelectLoggedInUser(state);

    return !!loggedInUser;
};

export const selectLoggedInUser: Selector<RootState, AuthenticatedUser> = state => {
    if (!selectIsLoggedIn(state)) {
        throw new Error('No logged in user');
    }

    return maybeSelectLoggedInUser(state) as AuthenticatedUser;
};

export const selectIsLoggedInAs = (role: Domain.Role): Selector<RootState, boolean> => {
    return state => {
        const loggedInUser = maybeSelectLoggedInUser(state);

        if (!loggedInUser || loggedInUser.role !== role) {
            return false;
        }

        return true;
    };
};

export const selectIsImpersonating: Selector<RootState, boolean> = state => {
    const loggedInUser = maybeSelectLoggedInUser(state);

    return !!loggedInUser && !!loggedInUser.impersonator;
};

export const selectIsLoggedInAsPlatformAdministrator: Selector<RootState, boolean> = state => {
    return selectIsLoggedInAs('platformAdministrator')(state);
};

export const selectLoggedInPlatformAdministrator: Selector<RootState, PlatformAdministrator> = state => {
    if (!selectIsLoggedInAsPlatformAdministrator(state)) {
        throw new Error('Not logged in as platform administrator');
    }

    return selectLoggedInUser(state) as PlatformAdministrator;
};

export const selectIsLoggedInAsCompanyManager: Selector<RootState, boolean> = state => {
    return selectIsLoggedInAs('companyAdministrator')(state);
};

export const selectLoggedInCompanyManager: Selector<RootState, CompanyManager> = state => {
    if (!selectIsLoggedInAsCompanyManager(state)) {
        throw new Error('Not logged in as company manager');
    }

    return selectLoggedInUser(state) as CompanyManager;
};

export const selectIsLoggedInAsBranchManager: Selector<RootState, boolean> = state => {
    return selectIsLoggedInAs('branchAdministrator')(state);
};

export const selectLoggedInBranchManager: Selector<RootState, BranchManager> = state => {
    if (!selectIsLoggedInAsBranchManager(state)) {
        throw new Error('Not logged in as branch manager');
    }

    return selectLoggedInUser(state) as BranchManager;
};

export const selectIsLoggedInAsSupportAgent: Selector<RootState, boolean> = state => {
    return selectIsLoggedInAs('supportAgent')(state);
};

export const selectLoggedInSupportAgent: Selector<RootState, SupportAgent> = state => {
    if (!selectIsLoggedInAsSupportAgent(state)) {
        throw new Error('Not logged in as support agent');
    }

    return selectLoggedInUser(state) as SupportAgent;
};

export const selectIsLoggedInAsReseller: Selector<RootState, boolean> = state => {
    return selectIsLoggedInAs('reseller')(state);
};

export const maybeSelectLoggedInReseller: Selector<RootState, Reseller | undefined> = state => {
    if (!selectIsLoggedInAsReseller(state)) {
        return undefined;
    }

    return selectLoggedInUser(state) as Reseller;
};

export const selectLoggedInReseller: Selector<RootState, Reseller> = state => {
    const reseller = maybeSelectLoggedInReseller(state);
    if (!reseller) {
        throw new Error('Not logged in as reseller');
    }

    return reseller;
};

export const selectIsLoggedInAsCompanyOrBranchManager: Selector<RootState, boolean> = state => {
    return selectIsLoggedInAs('companyAdministrator')(state) || selectIsLoggedInAs('branchAdministrator')(state);
};

export const maybeSelectLoggedInCompanyOrBranchManager: Selector<RootState, CompanyManager | BranchManager | undefined> = state => {
    if (!selectIsLoggedInAsCompanyManager(state) && !selectIsLoggedInAsBranchManager(state)) {
        return undefined;
    }

    return selectLoggedInUser(state) as CompanyManager | BranchManager;
};

export const selectLoggedInCompanyOrBranchManager: Selector<RootState, CompanyManager | BranchManager> = state => {
    const user = maybeSelectLoggedInCompanyOrBranchManager(state);
    if (!user) {
        throw new Error('Not logged in as company or branch manager');
    }

    return user;
};

export const selectIsLoggedInAsSuperUser: Selector<RootState, boolean> = state => {
    return (
        selectIsLoggedInAs('platformAdministrator')(state) ||
        selectIsLoggedInAs('supportAgent')(state) ||
        selectIsLoggedInAs('reseller')(state)
    );
};

export const selectLoggedInCompanyOrBranchManagerOwnershipIds: Selector<
    RootState,
    {
        companyId: string;
        branchId?: string;
    }
> = state => {
    const loggedInUser = selectLoggedInCompanyOrBranchManager(state);

    if (loggedInUser.role === 'companyAdministrator') {
        return {
            companyId: loggedInUser.companyId,
        };
    }

    return {
        companyId: loggedInUser.companyId,
        branchId: loggedInUser.branchId,
    };
};

export const selectLoggedInCompanyOrBranchManagerLocales: Selector<RootState, Domain.Locale[]> = state => {
    const loggedInUser = selectLoggedInCompanyOrBranchManager(state);

    if (loggedInUser.role === 'companyAdministrator') {
        return loggedInUser.companyLocales;
    }

    return loggedInUser.branchLocales;
};

export const selectLoggedInCompanyOrBranchManagerCountry: Selector<RootState, Domain.Country> = state => {
    const locales = selectLoggedInCompanyOrBranchManagerLocales(state);

    if (locales.length === 0) {
        throw new Error('No company or branch locales');
    }

    return getCountryCodeFromLocale(locales[0]);
};

export const maybeSelectLoggedInCompanyOrBranchManagerCountry: Selector<RootState, Domain.Country | undefined> = state => {
    const user = maybeSelectLoggedInCompanyOrBranchManager(state);
    if (!user) {
        return undefined;
    }
    const locales = user.role === 'companyAdministrator' ? user.companyLocales : user.branchLocales;

    if (locales.length === 0) {
        return undefined;
    }

    return getCountryCodeFromLocale(locales[0]);
};

export const selectLoggedInUserLocales = createSelector(
    [
        maybeSelectLoggedInReseller,
        selectIsLoggedInAsCompanyManager,
        selectIsLoggedInAsBranchManager,
        maybeSelectLoggedInCompanyOrBranchManager,
        selectCountries,
    ],
    (loggedInReseller, isLoggedInAsCompanyManager, isLoggedInAsBranchManager, loggedInCompanyOrBranchManager, _1) => {
        if (loggedInReseller) {
            return [...COUNTRY_CONFIG[loggedInReseller.resellerCountry].locales];
        }

        if (!isLoggedInAsCompanyManager && !isLoggedInAsBranchManager) {
            return [...ALL_LOCALES];
        }

        const loggedInUser = loggedInCompanyOrBranchManager!;

        if (loggedInUser.role === 'companyAdministrator') {
            return loggedInUser.companyLocales;
        }

        return loggedInUser.branchLocales;
    },
);

export const selectLoggedInUserOwnership = createSelector([maybeSelectLoggedInUser], (loggedInUser): Domain.Ownership => {
    if (!loggedInUser) {
        throw new Error('No logged in user');
    }

    if (loggedInUser.role === 'platformAdministrator' || loggedInUser.role === 'supportAgent' || loggedInUser.role === 'reseller') {
        return {
            type: 'all',
        };
    } else if (loggedInUser.role === 'companyAdministrator') {
        return {
            type: 'company',
            ownerId: loggedInUser.companyId,
        };
    } else if (loggedInUser.role === 'branchAdministrator') {
        return {
            type: 'branch',
            ownerId: loggedInUser.branchId,
        };
    } else {
        return {
            type: 'user',
            ownerId: loggedInUser.userId,
        };
    }
});

export const maybeSelectLoggedInUserOwnershipIds = createSelector(maybeSelectLoggedInUser, loggedInUser => {
    if (loggedInUser) {
        if (loggedInUser.role === 'companyAdministrator') {
            return {
                companyId: loggedInUser.companyId,
                branchId: undefined,
            };
        } else if (loggedInUser.role === 'branchAdministrator') {
            return {
                companyId: loggedInUser.companyId,
                branchId: loggedInUser.branchId,
            };
        }
    }

    return {
        companyId: undefined,
        branchId: undefined,
    };
});

export const selectLoggedInUserOwnershipIds: Selector<RootState, Domain.OptionalOwnershipIds> = state => {
    const loggedInUser = maybeSelectLoggedInUser(state);

    if (!loggedInUser) {
        throw new Error('No logged in user');
    }
    return maybeSelectLoggedInUserOwnershipIds(state);
};

export const selectUserCompanyCountry: Selector<RootState, Domain.Country | undefined> = state => {
    if (selectIsLoggedInAsCompanyManager(state) || selectIsLoggedInAsBranchManager(state)) {
        const user = selectLoggedInUser(state) as CompanyManager | BranchManager;
        return user.companyCountry;
    }

    return undefined;
};

export const selectUserCompanyCurrency: Selector<RootState, Domain.Currency> = state => {
    if (selectIsLoggedInAsCompanyManager(state) || selectIsLoggedInAsBranchManager(state)) {
        const user = selectLoggedInUser(state) as CompanyManager | BranchManager;
        return COUNTRY_CONFIG[user.companyCountry].currency;
    }

    return Domain.EURO_CURRENCY;
};
