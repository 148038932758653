import { Domain } from 'api';

import { branchApi } from '@/api';
import { makeActions, makeReducer, makeReducerActions, makeSelectors } from '@/makeDetailsState';

export const selectors = makeSelectors<Domain.Branch>({
    getState: rootState => rootState.branch.details,
});

export const reducerActions = makeReducerActions<Domain.Branch>({
    reducerPrefix: '@branch/details',
});

export const actions = makeActions<Domain.Branch>({
    loadApi: options => branchApi.GetBranchDetails(options.urlParams.branchId),
    reducerActions,
    selectors,
});

export const detailsReducer = makeReducer<Domain.Branch>({
    reducerActions,
});
