import * as React from 'react';

import classNames from 'classnames';

import { InputBase, InputBaseProps } from '@/core';

import styles from './Input.scss';

interface BaseProps {
    placeholder?: string;
    value?: string;
    defaultValue?: string;
    active?: boolean;
    onChange?: (newValue: string) => void;
    minHeight?: string;
    resizable?: boolean;
    forwardRef?: React.Ref<HTMLTextAreaElement>;
    onChangeStart?: () => void;
}

type NativeProps = Omit<React.InputHTMLAttributes<HTMLTextAreaElement>, 'onChange' | 'css'>;

export type TextareaProps = NativeProps & BaseProps;

interface IState {
    changeInProgress: boolean;
}

class Textarea extends InputBase<TextareaProps, IState> {
    private changeTimeout: ReturnType<typeof setTimeout> | undefined = undefined;

    static defaultProps = {
        resizable: true,
    };

    constructor(props: TextareaProps) {
        super(props);

        this.state = {
            changeInProgress: false,
        };
    }

    protected renderInput(): React.ReactNode {
        const {
            name,
            defaultValue,
            active,
            placeholder,
            value,
            disabled,
            forwardRef,
            resizable,
            minHeight,
            onChangeStart,
            onChange,
            error,
            errorMessage,
            description,
            highlight,
            ...rest
        } = this.props;

        const minHeightStyle = minHeight ? minHeight : '75px';
        const maxHeightStyle = resizable ? 'none' : minHeightStyle;

        return (
            <textarea
                {...rest}
                ref={forwardRef}
                style={{
                    height: minHeightStyle,
                    minHeight: minHeightStyle,
                    maxHeight: maxHeightStyle,
                    resize: resizable ? 'vertical' : 'none',
                    ...this.props.style,
                }}
                className={classNames(styles.Input, active ? styles.active : null)}
                name={name}
                defaultValue={defaultValue}
                placeholder={placeholder}
                value={value}
                disabled={disabled}
                onChange={this.handleChange}
            />
        );
    }

    private handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
        if (this.props.onChangeStart) {
            if (this.changeTimeout) {
                clearTimeout(this.changeTimeout);
            }

            this.changeTimeout = setTimeout(() => {
                this.setState({
                    changeInProgress: false,
                });
            }, 500);

            if (!this.state.changeInProgress) {
                this.setState({
                    changeInProgress: true,
                });

                this.props.onChangeStart();
            }
        }

        if (this.props.onChange) {
            this.props.onChange(event.target.value);
        }
    };
}

export default React.forwardRef<HTMLTextAreaElement, TextareaProps & InputBaseProps>((props, ref) => {
    return (
        <Textarea
            forwardRef={ref}
            {...props}
        />
    );
});
