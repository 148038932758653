import { Domain } from 'api';

import { searchSynonymApi } from '@/api';
import { makeActions, makeReducer, makeReducerActions, makeSelectors } from '@/makeOverviewState';

export type SearchActionProps = keyof Domain.SearchAction;

export const defaultSorting: Domain.Sorting<SearchActionProps> = {
    field: 'numberOfSearches',
    direction: 'descending',
};

export const pageSize = 20;

export const selectors = makeSelectors<Domain.SearchAction, SearchActionProps>({
    getState: rootState => rootState.searchSynonym.overview,
});

export const reducerActions = makeReducerActions<Domain.SearchAction, SearchActionProps>({
    reducerPrefix: '@searchSynonyms/overview',
});

export const actions = makeActions<
    Domain.SearchAction,
    SearchActionProps,
    {
        locale?: Domain.Locale;
        searchChannelId?: string;
    }
>({
    dataTableSaveKey: 'searchSynonymsOverview-v5',
    loadApi: options => searchSynonymApi.GetSearchActions(options.pagination, options.sorting, options.search, options.filters),
    defaultSorting,
    pageSize,
    getBaseUrl: () => '/localisation/search-synonyms',
    reducerActions,
    selectors,
});

export const overviewReducer = makeReducer<Domain.SearchAction, SearchActionProps>({
    defaultSorting,
    pageSize,
    reducerActions,
});
