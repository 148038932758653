import { createAction, createReducer, Selector } from '@reduxjs/toolkit';

import { Domain } from 'api';

import { withPayloadType } from '@/action';
import { RootState } from '@/store';

export type PendingMediaItem = {
    mediaItemId: string;
    label: string;
    previewUrl?: string;
    previewType?: 'image' | 'video' | 'other';
    message?: string;
    hasPreview?: boolean;
} & (
    | {
          status: 'error';
          mediaItem?: never;
      }
    | {
          status: 'pending';
          mediaItem?: never;
      }
    | {
          status: 'uploading';
          mediaItem?: never;
      }
    | {
          status: 'waiting-for-pdf-processing';
          mediaItem?: never;
      }
    | {
          status: 'uploaded';
          mediaItem: Domain.MediaItem;
      }
);

type PendingMediaItemUpdate = Omit<PendingMediaItem, 'label' | 'previewUrl'>;

export type MultiPagePDFPendingMediaItem = Domain.MultiPagePDFMediaItemUploadResponse & {
    mediaItemId: string;
};

export interface State {
    pendingMediaItems: PendingMediaItem[];
    multiPagePdfPendingMediaItems: MultiPagePDFPendingMediaItem[];
}

const initialState: State = {
    pendingMediaItems: [],
    multiPagePdfPendingMediaItems: [],
};

export const reducerActions = {
    addPendingMediaItem: createAction('@media/upload/addPendingMediaItem', withPayloadType<PendingMediaItem>()),
    updatePendingMediaItem: createAction('@media/upload/updatePendingMediaItem', withPayloadType<PendingMediaItemUpdate>()),
    addMultiPagePDFPendingMediaItem: createAction(
        '@media/upload/addMultiPagePDFPendingMediaItem',
        withPayloadType<MultiPagePDFPendingMediaItem>(),
    ),
    removeMultiPagePDFPendingMediaItem: createAction(
        '@media/upload/removeMultiPagePDFPendingMediaItem',
        withPayloadType<{
            mediaItemId: string;
        }>(),
    ),
};

export const uploadReducer = createReducer(initialState, builder =>
    builder
        .addCase(reducerActions.addPendingMediaItem, (state, action) => {
            state.pendingMediaItems = [action.payload, ...state.pendingMediaItems];
        })
        .addCase(reducerActions.updatePendingMediaItem, (state, action) => {
            state.pendingMediaItems = [
                ...state.pendingMediaItems.map(pendingMediaItem => {
                    if (pendingMediaItem.mediaItemId === action.payload.mediaItemId) {
                        if (action.payload.status === 'uploaded') {
                            return {
                                ...pendingMediaItem,
                                status: action.payload.status,
                                mediaItem: action.payload.mediaItem,
                            } as PendingMediaItem;
                        }

                        return {
                            ...pendingMediaItem,
                            status: action.payload.status,
                            message: action.payload.message,
                        } as PendingMediaItem;
                    }

                    return pendingMediaItem;
                }),
            ];
        })
        .addCase(reducerActions.addMultiPagePDFPendingMediaItem, (state, action) => {
            state.multiPagePdfPendingMediaItems = [action.payload, ...state.multiPagePdfPendingMediaItems];
        })
        .addCase(reducerActions.removeMultiPagePDFPendingMediaItem, (state, action) => {
            state.multiPagePdfPendingMediaItems = [
                ...state.multiPagePdfPendingMediaItems.filter(item => item.mediaItemId !== action.payload.mediaItemId),
            ];
        }),
);

export const selectPendingMediaItems: Selector<RootState, PendingMediaItem[]> = state => {
    return state.media.upload.pendingMediaItems;
};

export const selectMultiPagePDFPendingMediaItems: Selector<RootState, MultiPagePDFPendingMediaItem[]> = state => {
    return state.media.upload.multiPagePdfPendingMediaItems;
};
