import { Shelf, PlacedProduct } from '../types';

export default function findPlacedProduct(shelves: Shelf[], placedProductId: number): PlacedProduct | undefined {
    for (const shelf of shelves) {
        if (shelf.type !== 'productsShelf') {
            continue;
        }

        for (const placedProduct of shelf.products) {
            if (placedProduct.id === placedProductId) {
                return placedProduct;
            }
        }
    }
}
