import { combineReducers } from '@reduxjs/toolkit';

import { reducer as createReducer } from './createState';
import { detailsReducer } from './detailsState';
import { reducer as incompleteReportReducer } from './incompleteReportState';
import { overviewReducer } from './overviewState';

export const pickupReducer = combineReducers({
    details: detailsReducer,
    overview: overviewReducer,
    create: createReducer,
    incompleteReport: incompleteReportReducer,
});
