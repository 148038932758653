import {
    Wwks2StatusDialog,
    wwks2ShoppingCart,
    wwks2ShoppingCartUpdateResponse,
    wwks2ShoppingCartResponse,
    wwks2ShoppingCartItem,
    wwks2ShoppingCartPayment,
    wwks2ShoppingCartUpdateMessage,
    Wwks2ShoppingCartStatus
} from '../../../../Domain';
import Wwks2CommClient from '../../Wwks2CommClient';
import { DIALOG_TIMEOUTS } from '../Wwks2DefaultValues';


export default class Wwks2ShoppingCart {

    private Wwks2CommClient: Wwks2CommClient;

    public constructor(Wwks2CommClient: Wwks2CommClient) {
        this.Wwks2CommClient = Wwks2CommClient;
    }

    public sendShoppingCartRequest(shoppingCartId: string | number = '', dialogId: string | number | boolean = false): Promise<Wwks2StatusDialog> {
        return new Promise(resolve => {

            if(typeof dialogId === 'boolean'){
                dialogId = this.Wwks2CommClient.GetWwks2DialogId();
            }

            const wwks = {
                'ShoppingCartRequest': {
                    '@': {
                        'Id': dialogId,
                        'Source': this.Wwks2CommClient.GetSourceId(),
                        'Destination': this.Wwks2CommClient.GetClientId(),
                    },
                    '#': {
                        'Criteria': {
                            '@': {
                                'ShoppingCartId': shoppingCartId
                            }
                        }
                    }
                }
            }
            this.Wwks2CommClient.createAndSendDialog(wwks, 'ShoppingCart', dialogId).then(status => {
                let processStatusDialog = this.Wwks2CommClient.GetProcessedDialogData(status.dialogId);
                if (typeof processStatusDialog !== 'boolean') {
                    if (processStatusDialog.typeDialog === 'ShoppingCart') {
                        processStatusDialog.requestShoppingCartId = shoppingCartId;
                        processStatusDialog.shoppingCartType = shoppingCartId === '' ? 'Sale' : 'Reservation';
                        this.Wwks2CommClient.SetProcessedDialogData(status.dialogId, processStatusDialog);
                    }
                }
                resolve(status);
            });
        });
    }

    public sendAndProcessShoppingCartRequest(shoppingCartId: string | number = '', dialogId: string | number | boolean = false): Promise<Wwks2StatusDialog> {
        return new Promise(resolve => {
            this.sendShoppingCartRequest(shoppingCartId, dialogId)
                .then((data) => {
                    if (data.status) {
                        const timeoutTimer = setTimeout(() => {
                            let processStatusDialog = this.Wwks2CommClient.GetProcessedDialogData(data.dialogId);
                            if (typeof processStatusDialog !== 'boolean') {
                                if (processStatusDialog.typeDialog === 'ShoppingCart') {
                                    processStatusDialog.status.cancel = true;
                                    this.Wwks2CommClient.SetProcessedDialogData(data.dialogId, processStatusDialog);
                                }
                            }
                        }, DIALOG_TIMEOUTS.ShoppingCart);
                        this.Wwks2CommClient.getResponse(data.dialogId, 'ShoppingCart')
                            .then((status) => {
                                clearTimeout(timeoutTimer);
                                resolve(status)
                            });
                    } else {
                        resolve(data);
                    }
                });
        });
    }

    public sendShoppingCartUpdateRequest(shoppingcart: wwks2ShoppingCart, dialogId: string | number | boolean = false): Promise<Wwks2StatusDialog> {
        return new Promise(resolve => {

            if(typeof dialogId === 'boolean'){
                dialogId = this.Wwks2CommClient.GetWwks2DialogId();
            }

            // shoppingcart
            let shoppingCartMap: any = {};
            if (typeof shoppingcart.id !== 'undefined' && typeof shoppingcart.status !== 'undefined' && typeof shoppingcart.articles !== 'undefined') {
                shoppingCartMap = {
                    '@': {
                        'Id': shoppingcart.id,
                        'Status': shoppingcart.status
                    }
                }
                const shoppingCartItem: any = [];
                if (Array.isArray(shoppingcart.articles)) {
                    shoppingcart.articles.forEach(article => {
                        const item: any = {
                            '@': {
                                'ArticleId': article.id,
                                'OrderedQuantity': article.orderedQuantity,
                                'Quantity': article.orderedQuantity,
                            }
                        }
                        if (typeof article.dispensedQuantity !== 'undefined') {
                            item['@'].DispensedQuantity = article.dispensedQuantity;
                        }
                        if (typeof article.paidQuantity !== 'undefined') {
                            item['@'].PaidQuantity = article.paidQuantity;
                        }
                        if (typeof article.price !== 'undefined') {
                            item['@'].Price = article.price;
                        }
                        if (typeof article.currency !== 'undefined') {
                            item['@'].Currency = article.currency;
                        }
                        shoppingCartItem.push(item);
                    })
                }
                if (shoppingCartItem.length > 0) {
                    if (typeof shoppingCartMap['#'] === 'undefined') {
                        shoppingCartMap['#'] = {};
                    }
                    if (typeof shoppingCartMap['#'].ShoppingCartItem === 'undefined') {
                        shoppingCartMap['#'].ShoppingCartItem = [];
                    }
                    shoppingCartMap['#'].ShoppingCartItem = shoppingCartItem;
                }

                if (typeof shoppingcart.paymentInfo !== 'undefined') {
                    const paymentInfo = {
                        '@': {
                            'Type': shoppingcart.paymentInfo.type,
                            'Status': shoppingcart.paymentInfo.status,
                        },
                        '#': {
                            'ShoppingCartTransaction': {
                                '@': {
                                    'TransactionId': shoppingcart.paymentInfo.transactionId
                                },
                                'CDATA': shoppingcart.paymentInfo.details
                            }
                        }
                    }

                    if (typeof shoppingCartMap['#'] === 'undefined') {
                        shoppingCartMap['#'] = {};
                    }
                    if (typeof shoppingCartMap['#'].ShoppingCartPayment === 'undefined') {
                        shoppingCartMap['#'].ShoppingCartPayment = [];
                    }
                    shoppingCartMap['#'].ShoppingCartPayment = paymentInfo;
                }
            }

            const wwks = {
                'ShoppingCartUpdateRequest': {
                    '@': {
                        'Id': dialogId,
                        'Source': this.Wwks2CommClient.GetSourceId(),
                        'Destination': this.Wwks2CommClient.GetClientId(),
                    },
                    '#': {
                        'ShoppingCart': shoppingCartMap
                    }
                }
            }
            this.Wwks2CommClient.createAndSendDialog(wwks, 'ShoppingCartUpdate', dialogId).then(status => {
                resolve(status);
            });
        });
    }

    public sendAndProcessShoppingCartUpdateRequest(shoppingcart: wwks2ShoppingCart, dialogId: string | number | boolean = false): Promise<Wwks2StatusDialog> {
        return new Promise(resolve => {
            this.sendShoppingCartUpdateRequest(shoppingcart, dialogId)
                .then((data) => {
                    if (data.status) {
                        const timeoutTimer = setTimeout(() => {
                            let processStatusDialog = this.Wwks2CommClient.GetProcessedDialogData(data.dialogId);
                            if (typeof processStatusDialog !== 'boolean') {
                                if (processStatusDialog.typeDialog === 'ShoppingCartUpdate') {
                                    processStatusDialog.status.cancel = true;
                                    this.Wwks2CommClient.SetProcessedDialogData(data.dialogId, processStatusDialog);
                                }
                            }
                        }, DIALOG_TIMEOUTS.ShoppingCartUpdate);
                        this.Wwks2CommClient.getResponse(data.dialogId, 'ShoppingCartUpdate')
                            .then((statusResponse) => {

                                if (statusResponse.status) {
                                    let processStatusDialog = this.Wwks2CommClient.GetProcessedDialogData(statusResponse.dialogId);
                                    if (typeof processStatusDialog !== 'boolean') {
                                        if (processStatusDialog.typeDialog === 'ShoppingCartUpdate') {
                                            const shoppingCartUpdateResponse = processStatusDialog.data.response as wwks2ShoppingCartUpdateResponse;
                                            if (shoppingCartUpdateResponse.updateResultStatus === 'updated') {
                                                // next fase -> check Message
                                                this.Wwks2CommClient.getMessage(statusResponse.dialogId, 'ShoppingCartUpdate')
                                                    .then((statusMessage) => {
                                                        clearTimeout(timeoutTimer);
                                                        resolve(statusMessage)
                                                    });
                                            } else if (shoppingCartUpdateResponse.updateResultStatus === 'notupdated') {
                                                // do not check for Message because no Message will be send > send a negative status back
                                                resolve(statusResponse);
                                            }
                                        } else {
                                            statusResponse.msg = 'statusDialog (key = \'status_' + data.dialogId + '\') is no ShoppingCartUpdate type';
                                            statusResponse.errorType = 'wrongStatusDialogType';
                                            statusResponse.status = false;
                                            resolve(statusResponse);
                                        }
                                    } else {
                                        statusResponse.msg = 'key \'status_' + data.dialogId + '\' not exist in statusDialog';
                                        statusResponse.errorType = 'noStatusDialog';
                                        statusResponse.status = false;
                                        resolve(statusResponse);
                                    }
                                } else {
                                    clearTimeout(timeoutTimer);
                                    resolve(statusResponse);
                                }
                            });
                    } else {
                        resolve(data);
                    }
                });
        });
    }

    public handleMessage(WWKS: any) {

        if (WWKS.ShoppingCartResponse) {

            const responseId = WWKS.ShoppingCartResponse['@attributes'].Id;
            let processStatusDialog = this.Wwks2CommClient.GetProcessedDialogData(responseId);
            if (typeof processStatusDialog !== 'boolean') {

                const shoppingcartObject: wwks2ShoppingCart = {
                    id: '',
                    status: Wwks2ShoppingCartStatus.NotActive,
                    articles: []
                }
                const responseData: wwks2ShoppingCartResponse = {
                    'clientId': 0,
                    'dialogId': 0,
                    'shoppingcart': shoppingcartObject,
                    'requestShoppingCartId': '',
                    'shoppingCartType': 'new'
                }

                responseData.clientId = WWKS.ShoppingCartResponse['@attributes'].Source;
                responseData.dialogId = responseId;

                if (typeof WWKS.ShoppingCartResponse.ShoppingCart !== 'undefined') {

                    if (typeof WWKS.ShoppingCartResponse.ShoppingCart['@attributes'] !== 'undefined') {

                        if (typeof WWKS.ShoppingCartResponse.ShoppingCart['@attributes'].Status !== 'undefined' && typeof WWKS.ShoppingCartResponse.ShoppingCart['@attributes'].Id !== 'undefined') {

                            responseData.shoppingcart.id = WWKS.ShoppingCartResponse.ShoppingCart['@attributes'].Id;

                            switch(WWKS.ShoppingCartResponse.ShoppingCart['@attributes'].Status.toLowerCase()){
                                case 'active':
                                    responseData.shoppingcart.status = Wwks2ShoppingCartStatus.Active;
                                    break;
                                case 'notactive':
                                    responseData.shoppingcart.status = Wwks2ShoppingCartStatus.NotActive;
                                    break;
                                case 'discarded':
                                    responseData.shoppingcart.status = Wwks2ShoppingCartStatus.Discarded;
                                    break;
                                case 'finished':
                                    responseData.shoppingcart.status = Wwks2ShoppingCartStatus.Finished;
                                    break;
                            }

                            responseData.shoppingcart.articles = [];

                            if (typeof WWKS.ShoppingCartResponse.ShoppingCart.ShoppingCartItem !== 'undefined') {
                                let _shopitems: any[];

                                if (!Array.isArray(WWKS.ShoppingCartResponse.ShoppingCart.ShoppingCartItem)) {
                                    _shopitems = [WWKS.ShoppingCartResponse.ShoppingCart.ShoppingCartItem];
                                } else {
                                    _shopitems = WWKS.ShoppingCartResponse.ShoppingCart.ShoppingCartItem;
                                }

                                _shopitems.forEach((shopitem) => {
                                    if (typeof shopitem['@attributes'] !== 'undefined') {
                                        if (typeof shopitem['@attributes'].ArticleId !== 'undefined' && (typeof shopitem['@attributes'].OrderedQuantity !== 'undefined' || typeof shopitem['@attributes'].Quantity !== 'undefined')) {

                                            const article: wwks2ShoppingCartItem = {
                                                'id': shopitem['@attributes'].ArticleId
                                            }
                                            if (typeof shopitem['@attributes'].Quantity !== 'undefined') {
                                                article.orderedQuantity = parseInt(shopitem['@attributes'].Quantity);
                                            }
                                            if (typeof shopitem['@attributes'].OrderedQuantity !== 'undefined') {
                                                article.orderedQuantity = parseInt(shopitem['@attributes'].OrderedQuantity);
                                            }
                                            if (typeof shopitem['@attributes'].DispensedQuantity !== 'undefined') {
                                                article.dispensedQuantity = parseInt(shopitem['@attributes'].DispensedQuantity);
                                            }
                                            if (typeof shopitem['@attributes'].PaidQuantity !== 'undefined') {
                                                article.paidQuantity = parseInt(shopitem['@attributes'].PaidQuantity);
                                            }
                                            if (typeof shopitem['@attributes'].Price !== 'undefined') {
                                                article.price = shopitem['@attributes'].Price;
                                            }
                                            if (typeof shopitem['@attributes'].Currency !== 'undefined') {
                                                article.currency = shopitem['@attributes'].Currency;
                                            }
                                            responseData.shoppingcart.articles?.push(article);
                                        }
                                    }
                                });
                            }

                            if (typeof WWKS.ShoppingCartResponse.ShoppingCart.ShoppingCartPayment !== 'undefined') {
                                if (typeof WWKS.ShoppingCartResponse.ShoppingCart.ShoppingCartPayment['@attributes'].Type !== 'undefined' && typeof WWKS.ShoppingCartResponse.ShoppingCart.ShoppingCartPayment['@attributes'].Status !== 'undefined') {

                                    const paymentInfo: wwks2ShoppingCartPayment = {
                                        'type': WWKS.ShoppingCartResponse.ShoppingCart.ShoppingCartPayment['@attributes'].Type,
                                        'status': WWKS.ShoppingCartResponse.ShoppingCart.ShoppingCartPayment['@attributes'].Status.toLowerCase(),
                                        'transactionId': '',
                                        'details': {}
                                    }

                                    if (typeof WWKS.ShoppingCartResponse.ShoppingCart.ShoppingCartPayment.ShoppingCartTransaction !== 'undefined') {
                                        if (typeof WWKS.ShoppingCartResponse.ShoppingCart.ShoppingCartPayment.ShoppingCartTransaction['@attributes'] !== 'undefined') {
                                            if (typeof WWKS.ShoppingCartResponse.ShoppingCart.ShoppingCartPayment.ShoppingCartTransaction['@attributes'].TransactionId !== 'undefined') {
                                                paymentInfo.transactionId = WWKS.ShoppingCartResponse.ShoppingCart.ShoppingCartPayment.ShoppingCartTransaction.TransactionId;
                                            }
                                        }
                                    }
                                    responseData.shoppingcart.paymentInfo = paymentInfo;
                                }
                            }

                        }
                    }
                }
                if (processStatusDialog.typeDialog === 'ShoppingCart') {
                    responseData.requestShoppingCartId = processStatusDialog.requestShoppingCartId;
                    responseData.shoppingCartType = processStatusDialog.shoppingCartType;
                    processStatusDialog.status.response = true;
                    processStatusDialog.timestamps.response = Date.now();
                    processStatusDialog.data.response = responseData;
                    this.Wwks2CommClient.SetProcessedDialogData(responseId, processStatusDialog)
                }
            }
        }

        if (WWKS.ShoppingCartUpdateResponse) {

            const responseId = WWKS.ShoppingCartUpdateResponse['@attributes'].Id;
            let processStatusDialog = this.Wwks2CommClient.GetProcessedDialogData(responseId);
            if (typeof processStatusDialog !== 'boolean') {

                const shoppingcartObject: wwks2ShoppingCart = {
                    id: '',
                    status: Wwks2ShoppingCartStatus.NotActive,
                    articles: []
                }

                const responseData: wwks2ShoppingCartUpdateResponse = {
                    'clientId': 0,
                    'dialogId': 0,
                    'shoppingcart': shoppingcartObject,
                    'updateResultStatus': 'NotUpdated'
                }

                responseData.clientId = WWKS.ShoppingCartUpdateResponse['@attributes'].Source;
                responseData.dialogId = responseId;

                if (typeof WWKS.ShoppingCartUpdateResponse.ShoppingCart !== 'undefined') {
                    if (typeof WWKS.ShoppingCartUpdateResponse.ShoppingCart['@attributes'] !== 'undefined') {

                        const shoppingcartObject = WWKS.ShoppingCartUpdateResponse.ShoppingCart;

                        if (typeof shoppingcartObject['@attributes'].Status !== 'undefined' && typeof shoppingcartObject['@attributes'].Id !== 'undefined') {

                            responseData.shoppingcart.id = shoppingcartObject['@attributes'].Id;
                            switch(shoppingcartObject['@attributes'].Status.toLowerCase()){
                                case 'active':
                                    responseData.shoppingcart.status = Wwks2ShoppingCartStatus.Active;
                                    break;
                                case 'notactive':
                                    responseData.shoppingcart.status = Wwks2ShoppingCartStatus.NotActive;
                                    break;
                                case 'discarded':
                                    responseData.shoppingcart.status = Wwks2ShoppingCartStatus.Discarded;
                                    break;
                                case 'finished':
                                    responseData.shoppingcart.status = Wwks2ShoppingCartStatus.Finished;
                                    break;
                            }


                            responseData.shoppingcart.articles = [];


                            if (typeof shoppingcartObject.ShoppingCartItem !== 'undefined') {
                                let _shopitems: any[];

                                if (!Array.isArray(shoppingcartObject.ShoppingCartItem)) {
                                    _shopitems = [shoppingcartObject.ShoppingCartItem];
                                } else {
                                    _shopitems = shoppingcartObject.ShoppingCartItem;
                                }

                                _shopitems.forEach((shopitem) => {
                                    if (typeof shopitem['@attributes'] !== 'undefined') {
                                        if (typeof shopitem['@attributes'].ArticleId !== 'undefined' && (typeof shopitem['@attributes'].OrderedQuantity !== 'undefined' || typeof shopitem['@attributes'].Quantity !== 'undefined')) {

                                            const article: wwks2ShoppingCartItem = {
                                                'id': shopitem['@attributes'].ArticleId
                                            }

                                            if (typeof shopitem['@attributes'].Quantity !== 'undefined') {
                                                article.orderedQuantity = parseInt(shopitem['@attributes'].Quantity);
                                            }
                                            if (typeof shopitem['@attributes'].OrderedQuantity !== 'undefined') {
                                                article.orderedQuantity = parseInt(shopitem['@attributes'].OrderedQuantity);
                                            }
                                            if (typeof shopitem['@attributes'].DispensedQuantity !== 'undefined') {
                                                article.dispensedQuantity = parseInt(shopitem['@attributes'].DispensedQuantity);
                                            }
                                            if (typeof shopitem['@attributes'].PaidQuantity !== 'undefined') {
                                                article.paidQuantity = parseInt(shopitem['@attributes'].PaidQuantity);
                                            }
                                            if (typeof shopitem['@attributes'].Price !== 'undefined') {
                                                article.price = shopitem['@attributes'].Price;
                                            }
                                            if (typeof shopitem['@attributes'].Currency !== 'undefined') {
                                                article.currency = shopitem['@attributes'].Currency;
                                            }
                                            responseData.shoppingcart.articles?.push(article);
                                        }
                                    }
                                });
                            }

                            if (typeof shoppingcartObject.ShoppingCartPayment !== 'undefined') {
                                if (typeof shoppingcartObject.ShoppingCartPayment['@attributes'].Type !== 'undefined' && typeof shoppingcartObject.ShoppingCartPayment['@attributes'].Status !== 'undefined') {

                                    const paymentInfo: wwks2ShoppingCartPayment = {
                                        'type': shoppingcartObject.ShoppingCartPayment['@attributes'].Type,
                                        'status': shoppingcartObject.ShoppingCartPayment['@attributes'].Status.toLowerCase(),
                                        'transactionId': '',
                                        'details': {}
                                    }


                                    if (typeof WWKS.ShoppingCartResponse.ShoppingCart.ShoppingCartPayment.ShoppingCartTransaction !== 'undefined') {
                                        if (typeof WWKS.ShoppingCartResponse.ShoppingCart.ShoppingCartPayment.ShoppingCartTransaction['@attributes'] !== 'undefined') {
                                            if (typeof WWKS.ShoppingCartResponse.ShoppingCart.ShoppingCartPayment.ShoppingCartTransaction['@attributes'].TransactionId !== 'undefined') {
                                                paymentInfo.transactionId = WWKS.ShoppingCartResponse.ShoppingCart.ShoppingCartPayment.ShoppingCartTransaction.TransactionId;
                                            }
                                        }
                                    }
                                    responseData.shoppingcart.paymentInfo = paymentInfo;
                                }
                            }
                        }
                    }
                }
                if (typeof WWKS.ShoppingCartUpdateResponse.UpdateResult !== 'undefined') {
                    if (typeof WWKS.ShoppingCartUpdateResponse.UpdateResult['@attributes'] !== 'undefined') {
                        if (typeof WWKS.ShoppingCartUpdateResponse.UpdateResult['@attributes'].Status !== 'undefined') {
                            responseData.updateResultStatus = WWKS.ShoppingCartUpdateResponse.UpdateResult['@attributes'].Status.toLowerCase();
                        }
                    }
                }
                if (processStatusDialog.typeDialog === 'ShoppingCartUpdate') {
                    processStatusDialog.status.response = true;
                    processStatusDialog.timestamps.response = Date.now();
                    processStatusDialog.data.response = responseData;
                    this.Wwks2CommClient.SetProcessedDialogData(responseId, processStatusDialog)
                }
            }
        }

        if (WWKS.ShoppingCartUpdateMessage) {

            const responseId = WWKS.ShoppingCartUpdateMessage['@attributes'].Id;
            let processStatusDialog = this.Wwks2CommClient.GetProcessedDialogData(responseId);
            if (typeof processStatusDialog !== 'boolean') {

                const shoppingcartObject: wwks2ShoppingCart = {
                    id: '',
                    status: Wwks2ShoppingCartStatus.NotActive,
                    articles: []
                }

                const responseData: wwks2ShoppingCartUpdateMessage = {
                    'clientId': 0,
                    'dialogId': 0,
                    'shoppingcart': shoppingcartObject
                }

                responseData.clientId = WWKS.ShoppingCartUpdateMessage['@attributes'].Source;
                responseData.dialogId = responseId;

                if (typeof WWKS.ShoppingCartUpdateMessage.ShoppingCart !== 'undefined') {
                    if (typeof WWKS.ShoppingCartUpdateMessage.ShoppingCart['@attributes'] !== 'undefined') {

                        const shoppingcartObject = WWKS.ShoppingCartUpdateMessage.ShoppingCart;

                        if (typeof shoppingcartObject['@attributes'].Status !== 'undefined' && typeof shoppingcartObject['@attributes'].Id !== 'undefined') {

                            responseData.shoppingcart.id = shoppingcartObject['@attributes'].Id;
                            switch(shoppingcartObject['@attributes'].Status.toLowerCase()){
                                case 'active':
                                    responseData.shoppingcart.status = Wwks2ShoppingCartStatus.Active;
                                    break;
                                case 'notactive':
                                    responseData.shoppingcart.status = Wwks2ShoppingCartStatus.NotActive;
                                    break;
                                case 'discarded':
                                    responseData.shoppingcart.status = Wwks2ShoppingCartStatus.Discarded;
                                    break;
                                case 'finished':
                                    responseData.shoppingcart.status = Wwks2ShoppingCartStatus.Finished;
                                    break;
                            }

                            responseData.shoppingcart.articles = [];


                            if (typeof shoppingcartObject.ShoppingCartItem !== 'undefined') {
                                let _shopitems: any[];

                                if (!Array.isArray(shoppingcartObject.ShoppingCartItem)) {
                                    _shopitems = [shoppingcartObject.ShoppingCartItem];
                                } else {
                                    _shopitems = shoppingcartObject.ShoppingCartItem;
                                }

                                _shopitems.forEach((shopitem) => {
                                    if (typeof shopitem['@attributes'] !== 'undefined') {
                                        if (typeof shopitem['@attributes'].ArticleId !== 'undefined' && (typeof shopitem['@attributes'].OrderedQuantity !== 'undefined' || typeof shopitem['@attributes'].Quantity !== 'undefined')) {

                                            const article: wwks2ShoppingCartItem = {
                                                'id': shopitem['@attributes'].ArticleId
                                            }
                                            if (typeof shopitem['@attributes'].Quantity !== 'undefined') {
                                                article.orderedQuantity = parseInt(shopitem['@attributes'].Quantity);
                                            }
                                            if (typeof shopitem['@attributes'].OrderedQuantity !== 'undefined') {
                                                article.orderedQuantity = parseInt(shopitem['@attributes'].OrderedQuantity);
                                            }
                                            if (typeof shopitem['@attributes'].DispensedQuantity !== 'undefined') {
                                                article.dispensedQuantity = parseInt(shopitem['@attributes'].DispensedQuantity);
                                            }
                                            if (typeof shopitem['@attributes'].PaidQuantity !== 'undefined') {
                                                article.paidQuantity = parseInt(shopitem['@attributes'].PaidQuantity);
                                            }
                                            if (typeof shopitem['@attributes'].Price !== 'undefined') {
                                                article.price = shopitem['@attributes'].Price;
                                            }
                                            if (typeof shopitem['@attributes'].Currency !== 'undefined') {
                                                article.currency = shopitem['@attributes'].Currency;
                                            }
                                            responseData.shoppingcart.articles?.push(article);
                                        }
                                    }
                                });
                            }

                            if (typeof shoppingcartObject.ShoppingCartPayment !== 'undefined') {
                                if (typeof shoppingcartObject.ShoppingCartPayment['@attributes'].Type !== 'undefined' && typeof shoppingcartObject.ShoppingCartPayment['@attributes'].Status !== 'undefined') {

                                    const paymentInfo: wwks2ShoppingCartPayment = {
                                        'type': shoppingcartObject.ShoppingCartPayment['@attributes'].Type,
                                        'status': shoppingcartObject.ShoppingCartPayment['@attributes'].Status.toLowerCase(),
                                        'transactionId': '',
                                        'details': {}
                                    }


                                    if (typeof WWKS.ShoppingCartResponse.ShoppingCart.ShoppingCartPayment.ShoppingCartTransaction !== 'undefined') {
                                        if (typeof WWKS.ShoppingCartResponse.ShoppingCart.ShoppingCartPayment.ShoppingCartTransaction['@attributes'] !== 'undefined') {
                                            if (typeof WWKS.ShoppingCartResponse.ShoppingCart.ShoppingCartPayment.ShoppingCartTransaction['@attributes'].TransactionId !== 'undefined') {
                                                paymentInfo.transactionId = WWKS.ShoppingCartResponse.ShoppingCart.ShoppingCartPayment.ShoppingCartTransaction.TransactionId;
                                            }
                                        }
                                    }
                                    responseData.shoppingcart.paymentInfo = paymentInfo;
                                }
                            }
                        }
                    }
                }
                if (processStatusDialog.typeDialog === 'ShoppingCartUpdate') {
                    processStatusDialog.status.message = true;
                    processStatusDialog.timestamps.message = Date.now();
                    processStatusDialog.data.message = responseData;
                    this.Wwks2CommClient.SetProcessedDialogData(responseId, processStatusDialog)
                }
            }
        }

    }
}