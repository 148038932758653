import * as React from 'react';

import classNames from 'classnames';

import getCSSPropsFromRTValue from './getCSSPropsFromRTValue';
import RTValue from './RTValue';

import styles from './RTView.scss';

export const RTView = React.memo(
    (
        props: {
            value?: RTValue;
            text?: string;
        } & Omit<React.HTMLProps<HTMLDivElement>, 'value' | 'css'>,
    ) => {
        const { value, text, children, style, className, ...rest } = props;
        const actualValue = value || new RTValue('');
        const textValue = props.children || props.text || actualValue.text;
        const rtStyle = getCSSPropsFromRTValue(actualValue);

        return (
            <div
                className={classNames(styles.RTView, className)}
                style={{
                    ...style,
                    ...rtStyle,
                }}
                {...rest}
            >
                {!props.children ? (
                    <div
                        style={rtStyle}
                        dangerouslySetInnerHTML={{ __html: textValue as string }}
                    />
                ) : (
                    <div style={rtStyle}>{textValue}</div>
                )}
            </div>
        );
    },
);
