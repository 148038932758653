import {
    Pagination,
    Sorting,
    WebshopAnnouncementsPage,
    WebshopAnnouncementDetails,
    CreateWebshopAnnouncementPayload,
    UpdateWebshopAnnouncementPayload,
    RemoveWebshopAnnouncementPayload,
} from '@/Domain';

import Endpoint from './Endpoint';

export default class WebshopAnnouncementEndpoint extends Endpoint {
    public async GetAnnouncements(
        pagination: Pagination,
        sorting: Sorting,
        webshopId: string,
        search?: string,
    ): Promise<WebshopAnnouncementsPage> {
        const response = await this.client.get({
            url: `/webshop/${webshopId}/announcement/overview`,
            params: {
                pagination,
                sorting,
                search,
            },
        });

        this.guardResponseSchema('#/definitions/WebshopAnnouncementsPage', response);

        return response;
    }

    public async GetAnnouncementDetails(webshopId: string, announcementId: string): Promise<WebshopAnnouncementDetails> {
        const response = await this.client.get({
            url: `/webshop/${webshopId}/announcement/${announcementId}/details`,
        });

        this.guardResponseSchema('#/definitions/WebshopAnnouncementDetails', response);

        return response;
    }

    public async CreateAnnouncement(payload: CreateWebshopAnnouncementPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/CreateWebshopAnnouncement',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Webshop\\Announcement\\UpdateAnnouncement\\UpdateAnnouncement',
                payload,
            },
        });
    }

    public async UpdateAnnouncement(payload: UpdateWebshopAnnouncementPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/UpdateWebshopAnnouncement',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Webshop\\Announcement\\UpdateAnnouncement\\UpdateAnnouncement',
                payload,
            },
        });
    }

    public async RemoveAnnouncement(payload: RemoveWebshopAnnouncementPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/RemoveWebshopAnnouncement',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Webshop\\Announcement\\RemoveAnnouncement\\RemoveAnnouncement',
                payload,
            },
        });
    }
}
