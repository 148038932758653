import { Shelf, ProductsShelf } from '../types';

export default function findPlacedProductShelf(shelves: Shelf[], placedProductId: number): ProductsShelf | undefined {
    return shelves.find(shelf => {
        if (shelf.type !== 'productsShelf') {
            return false;
        }

        return !!shelf.products.find(placedProduct => placedProduct.id === placedProductId);
    }) as ProductsShelf | undefined;
}
