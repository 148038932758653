import { IDeviceInformationEvent, IScanEvent } from 'Domain';
import { EventEmitter } from 'events';

import { IEventEmitter } from './IEventEmitter';

export enum DeviceEvent {
    EmergencyCircuitInterrupted = 'EmergencyCircuitInterrupted',
    Discovering = 'Discovering',
    AwaitingConfiguration = 'AwaitingConfiguration',
    Initialising = 'Initialising',
    Faulted = 'Faulted',
    Engaged = 'Engaged',
    Ready = 'Ready',
    Rejecting = 'Rejecting',
    Scanned = 'Scanned',
}

export class DeviceEventEmitter
    extends EventEmitter
    implements
        IEventEmitter<{
            EmergencyCircuitInterrupted: any;
            Discovering: any;
            AwaitingConfiguration: IDeviceInformationEvent;
            Initialising: any;
            Faulted: any;
            Engaged: any;
            Ready: any;
            Rejecting: any;
            Scanned: IScanEvent;
        }> {}
