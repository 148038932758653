import { createAction, createReducer, Selector } from '@reduxjs/toolkit';

import { Domain } from 'api';

import { withPayloadType } from '@/action';
import { RootState } from '@/store';

export type State = Domain.Dashboard;

export const setDashboardItems = createAction('@dashboardItems/setDashboardItems', withPayloadType<Domain.Dashboard>());

const initialState: State = {
    userId: null,
    role: null,
    branchId: null,
    companyId: null,
    components: {
        unreadNotifications: {
            items: [],
            total: 0,
            pagination: { page: 1, size: 10 },
            sorting: { field: 'createdOn', direction: 'descending' },
            search: null,
        },
        branchSales: {
            items: [],
            total: 0,
            pagination: { page: 1, size: 10 },
            sorting: { field: 'orderCount', direction: 'descending' },
            search: null,
        },
        branchRecentLogins: {
            items: [],
            total: 0,
            pagination: { page: 1, size: 10 },
            sorting: { field: 'mostRecentLogin', direction: 'descending' },
            search: null,
        },
        appointments: {
            items: [],
            total: 0,
            pagination: { page: 1, size: 10 },
            sorting: { field: 'startsAt', direction: 'ascending' },
            search: null,
        },
    },
};

export const selectDashboardItems: Selector<RootState, Domain.Dashboard> = state => state.dashboardReducer;

export const dashboardReducer = createReducer(initialState, builder =>
    builder.addCase(setDashboardItems, (state, action) => {
        state.userId = action.payload.userId;
        state.role = action.payload.role;
        state.branchId = action.payload.branchId;
        state.companyId = action.payload.companyId;
        state.components = action.payload.components;
    }),
);
