import * as React from 'react';

import ControlledToggle, { ControlledToggleProps } from './ControlledToggle';

export type UncontrolledToggleProps = ControlledToggleProps & {
    isOn?: never;
    defaultOn?: boolean;
};

interface IState {
    isOn: boolean;
}

class UncontrolledToggle extends React.PureComponent<UncontrolledToggleProps, IState> {
    constructor(props: UncontrolledToggleProps) {
        super(props);

        this.state = {
            isOn: props.defaultOn || false,
        };
    }

    render() {
        const { children, onChange, forwardRef, ...rest } = this.props;

        return (
            <ControlledToggle
                {...rest}
                ref={forwardRef}
                isOn={this.state.isOn}
                onChange={this.handleChange}
            >
                {children}
            </ControlledToggle>
        );
    }

    protected handleChange = (): void => {
        const { onChange } = this.props;
        const { isOn } = this.state;

        this.setState(
            {
                isOn: !isOn,
            },
            () => {
                if (onChange) {
                    onChange(this.state.isOn);
                }
            },
        );
    };
}

export default React.forwardRef<HTMLInputElement, UncontrolledToggleProps>((props, ref) => {
    return (
        <UncontrolledToggle
            forwardRef={ref}
            {...props}
        />
    );
});
