import { Domain } from 'api';

import { billableServiceApi } from '@/api';
import { makeActions, makeReducer, makeReducerActions, makeSelectors } from '@/makeOverviewState';

export type BillableServiceProps = keyof Domain.BillableService;

export const defaultSorting: Domain.Sorting<BillableServiceProps> = {
    field: 'billableServiceId',
    direction: 'ascending',
};

export const pageSize = 20;

export const selectors = makeSelectors<Domain.BillableService, BillableServiceProps>({
    getState: rootState => rootState.billableService.overview,
});

export const reducerActions = makeReducerActions<Domain.BillableService, BillableServiceProps>({
    reducerPrefix: '@billableService/overview',
});

export const actions = makeActions<Domain.BillableService, BillableServiceProps>({
    dataTableSaveKey: 'billableServicesOverview-v5',
    loadApi: options => billableServiceApi.GetBillableServices(options.pagination),
    defaultSorting,
    pageSize,
    getBaseUrl: () => '/billable-services',
    reducerActions,
    selectors,
});

export const overviewReducer = makeReducer<Domain.BillableService, BillableServiceProps>({
    defaultSorting,
    pageSize,
    reducerActions,
});
