import { Device } from './Device';
import { Pagination } from './Pagination';
import { ProductCodes } from './ProductCode';
import { VendingMachineProduct } from './VendingMachineCatalog';
import { LockerAddress } from './VendingMachineDelivery';

export interface NightHatchCartItemBase {
    itemId: string;
    unitPrice: number;
    quantity: number;
    quantityDeliveredFromRobot: number;
    quantityDeliveredFromLockers: number;
}

export type NightHatchCartProductItem = NightHatchCartItemBase & {
    product: VendingMachineProduct & {
        robotStock: number;
        lockerStock: number;
    };
};

export type NightHatchCartCustomItemType = 'other' | 'prescription' | 'indications' | 'product' | 'discount';
export type NightHatchCartCustomItem = NightHatchCartItemBase & {
    customItem: {
        type: NightHatchCartCustomItemType;
        description: string;
    };
};

export type NightHatchCartItem = NightHatchCartProductItem | NightHatchCartCustomItem;

export function isNightHatchCartProductItem(item: NightHatchCartItem): item is NightHatchCartProductItem {
    return item.hasOwnProperty('product');
}

export type RequestedFromCustomer = {
    itemId: string;
    message?: string;
} & (
    | {
          askFor: 'cashPayment';
          amount: number;
      }
    | {
          askFor: 'prescription';
      }
    | {
          askFor: 'other';
      }
);

export interface NightHatchState {
    cart: {
        items: NightHatchCartItem[];
        total: number;
    };
    requestedFromCustomer: RequestedFromCustomer[];
    sessionStep?: number;
    sessionSubstep?:
        | 'scan-id'
        | 'scan-id-complete'
        | 'scan-id-failed'
        | 'identify-manually'
        | 'identify-manually-basket-coming'
        | 'identify-manually-patient-confirm'
        | 'identify-manually-basket-returning'
        | 'identify-manually-basket-returned'
        | 'identify-manually-no-basket-patient-confirm'
        | 'identify-manually-id-returning'
        | 'identify-manually-id-returned'
        | 'pay-terminal'
        | 'pay-terminal-now'
        | 'pay-terminal-complete'
        | 'pay-terminal-failed'
        | 'pay-cash'
        | 'pay-cash-soon'
        | 'pay-cash-basket-coming'
        | 'pay-cash-patient-confirm'
        | 'pay-cash-basket-returning'
        | 'pay-cash-basket-returned'
        | 'pay-cash-no-basket-patient-confirm'
        | 'pay-cash-returning'
        | 'pay-cash-returned'
        | 'deliver-products-basket-coming'
        | 'deliver-products-basket-patient-confirm'
        | 'deliver-products-basket-returning'
        | 'deliver-products-basket-returned'
        | 'deliver-products-no-basket-coming'
        | 'deliver-products-no-basket-patient-confirm';
    sessionConfirmedSteps?: number[];
    cashTotal?: number;
    receivedIDCard?: boolean;
}

interface BaseMessage {
    tunnel: boolean;
}

export type NightHatchStateMessage = BaseMessage & {
    type: 'state';
    state: NightHatchState;
};

export type NightHatchProductSearchResults = (VendingMachineProduct & {
    robotStock: number;
    lockerStock: number;
})[];

export type NightHatchGetHatchPhotoDataMessage = BaseMessage & {
    type: 'getHatchPhotoData';
    data: string;
};

export type NightHatchGetScreenPhotoDataMessage = BaseMessage & {
    type: 'getScreenPhotoData';
    data: string;
};

export type NightHatchScannedBarcodeMessage = BaseMessage & {
    type: 'scannedBarcode';
    barcode: string;
};

export type NightHatchProductStockInquiryMessage = BaseMessage & {
    type: 'productStockInquiry';
    products: ProductCodes[];
};

export type NightHatchProductStockInquiryResults = {
    pcodes: ProductCodes;
    robotStock: number;
    lockerStock: number;
}[];

export type NightHatchProductStockInquiryResultsMessage = BaseMessage & {
    type: 'productStockInquiryResults';
    results: NightHatchProductStockInquiryResults;
};

export type NightHatchHatchStatus = 'closed' | 'opening' | 'opened' | 'closing';
export type NightHatchBeltStatus = 'none' | 'forward' | 'backward';
export type NightHatchRobotStatus = 'ready' | 'can-queue' | 'not-ready' | 'unknown';

export interface NightHatchHardwareStatus {
    isHatchLedOn: boolean;
    isDeliveringProductsFromRobot: boolean;
    isWaitingForTerminalTransaction: boolean;
    customerHatch: NightHatchHatchStatus;
    pharmacistHatch: NightHatchHatchStatus;
    beltMovementDirection: NightHatchBeltStatus;
    robotStatus: NightHatchRobotStatus;
}

export type NightHatchInitiateMessage = BaseMessage & {
    type: 'initiateDeviceSide';
};

export type NightHatchHardwareStatusMessage = BaseMessage & {
    type: 'hardwareStatus';
    status: NightHatchHardwareStatus;
};

export type NightHatchHardwareActionMessage = BaseMessage & {
    type: 'hardwareAction';
    action:
        | 'hatchLightOn'
        | 'hatchLightOff'
        | 'inject'
        | 'reject'
        | 'closeHatches'
        | 'closeOutsideHatch'
        | 'openOutsideHatch'
        | 'openInsideHatch';
};

export type NightHatchDeliverProductsFromRobotOrLockersItems = {
    itemId: string;
    productCodes: ProductCodes;
    quantity: number;
}[];

export type NightHatchRobotProductDeliveryActionMessage = BaseMessage & {
    type: 'deliverProductsFromRobot';
    items: NightHatchDeliverProductsFromRobotOrLockersItems;
};

export interface NightHatchRobotProductDeliveryStatus {
    success: boolean;
}

export type NightHatchRobotProductDeliveryStatusMessage = BaseMessage & {
    type: 'robotProductDeliveryStatus';
    deliveryStatus: NightHatchRobotProductDeliveryStatus;
};

export type NightHatchCartProductsStockUpdate = {
    cartItemId: string;
    stock: number;
}[];

export type NightHatchCartProductsStockUpdateMessage = BaseMessage & {
    type: 'cartProductsStockUpdate';
    stockUpdate: NightHatchCartProductsStockUpdate;
};

export type NightHatchStartTerminalTransactionActionMessage = BaseMessage & {
    type: 'startTerminalTransaction';
    amountInEuroCents: number;
};

export type NightHatchStopTerminalTransactionActionMessage = BaseMessage & {
    type: 'stopTerminalTransaction';
};

export type NightHatchSimulatedTransactionStatusMessage = BaseMessage & {
    type: 'simulatedTransactionStatus';
    transactionStatus: boolean;
};

export type NightHatchInitiateVideoCallMessage = BaseMessage & {
    type: 'initiateVideoCall';
};

export type NightHatchGetHatchPhotoMessage = BaseMessage & {
    type: 'getHatchPhoto';
};

export type NightHatchGetScreenPhotoMessage = BaseMessage & {
    type: 'getScreenPhoto';
};

export type NightHatchOpenCollectLockerMessage = BaseMessage &
    LockerAddress & {
        type: 'openCollectLocker';
    };

export type NightHatchRequestIdScanMessage = BaseMessage & {
    type: 'requestIdScan';
    isRequestStart: boolean;
};

export type NightHatchConfirmCustomerDeliveryActionMessage = BaseMessage & {
    type: 'confirmCustomerDeliveryAction';
};

export type NightHatchTunnelMessage =
    | NightHatchProductStockInquiryMessage
    | NightHatchProductStockInquiryResultsMessage
    | NightHatchStateMessage
    | NightHatchHardwareStatusMessage
    | NightHatchHardwareActionMessage
    | NightHatchRobotProductDeliveryActionMessage
    | NightHatchRobotProductDeliveryStatusMessage
    | NightHatchCartProductsStockUpdateMessage
    | NightHatchStartTerminalTransactionActionMessage
    | NightHatchStopTerminalTransactionActionMessage
    | NightHatchSimulatedTransactionStatusMessage
    | NightHatchInitiateVideoCallMessage
    | NightHatchGetHatchPhotoMessage
    | NightHatchGetHatchPhotoDataMessage
    | NightHatchGetScreenPhotoMessage
    | NightHatchGetScreenPhotoDataMessage
    | NightHatchOpenCollectLockerMessage
    | NightHatchRequestIdScanMessage
    | NightHatchScannedBarcodeMessage
    | NightHatchConfirmCustomerDeliveryActionMessage;

export interface NightHatchLogLine {
    nightHatchLogId: string;
    deviceId: Device['deviceId'];
    logText: string;
    loggedOn: number;
}

export type CreateNightHatchLogLinePayload = NightHatchLogLine;

export interface NightHatchLogPage {
    items: NightHatchLogLine[];
    total: number;
    pagination: Pagination;
    sorting?: null;
    search?: string | null;
}

export type NightHatchLogLineJsonText =
    | {
          type: 'startNightHatch';
      }
    | {
          type: 'nightHatchStarted';
      }
    | {
          type: 'nightHatchFailedToStart';
      }
    | {
          type: 'stopNightHatch';
      }
    | {
          type: 'nightHatchStopped';
      }
    | {
          type: 'nightHatchFailed';
      }
    | {
          type: 'closeOutsideHatch';
      }
    | {
          type: 'openOutsideHatch';
      }
    | {
          type: 'openInsideHatch';
      }
    | {
          type: 'closeHatches';
      }
    | {
          type: 'beltStatusChanged';
          newStatus: NightHatchBeltStatus;
      }
    | {
          type: 'lightStatusChanged';
          newStatus: 'on' | 'off';
      }
    | {
          type: 'insideHatchStatusChanged';
          newStatus: NightHatchHatchStatus;
      }
    | {
          type: 'outsideHatchStatusChanged';
          newStatus: NightHatchHatchStatus;
      }
    | {
          type: 'robotStatusChanged';
          newStatus: NightHatchRobotStatus;
      }
    | {
          type: 'nightHatchTunnelClosed';
      }
    | {
          type: 'nightHatchTunnelOpened';
      }
    | {
          type: 'deliverProductsFromRobot';
      }
    | {
          type: 'robotDeliverySuccessful';
      }
    | {
          type: 'robotDeliveryFailed';
      }
    | {
          type: 'startTransaction';
          amount: number;
      }
    | {
          type: 'cancelTransaction';
      }
    | {
          type: 'transactionFailed';
      }
    | {
          type: 'transactionSuccessful';
      }
    | {
          type: 'cartChanged';
          cart: {
              items: {
                  type: 'product' | 'custom';
                  quantity: number;
                  code: string;
                  name: any;
              }[];
              total: number;
          };
      }
    | (LockerAddress & {
          type: 'openCollectLocker';
          contents: {
              productName: string;
              quantity: number;
          }[];
      })
    | (LockerAddress & {
          type: 'collectLockerOpened';
      });

export interface NightHatchOnDutyFee {
    amountInCents: number;
    name: string;
    isDefault?: boolean | null;
}

export type NightHatchOnDutyFeeList = NightHatchOnDutyFee[];

export interface SaveNightHatchOnDutyFees {
    branchId: string;
    customDutyFees: NightHatchOnDutyFee[];
}
