import { UpdateTranslationPayload, Locale, TranslationScope, TranslationList } from '@/Domain';

import Endpoint from './Endpoint';

export default class TranslationEndpoint extends Endpoint {
    public async getTranslationByLocale(locale: Locale, translationScope: TranslationScope): Promise<TranslationList> {
        const response = await this.client.get({
            url: '/translation/' + translationScope + '/' + locale + '/overview',
        });
        this.guardResponseSchema('#/definitions/TranslationList', response);
        return response;
    }

    public async UpdateTranslation(payload: UpdateTranslationPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/UpdateTranslation',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Translation\\UpdateTranslation\\UpdateTranslation',
                payload,
            },
        });
    }
}
