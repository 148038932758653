import { Domain } from 'api';

import { scheduleApi } from '@/api';
import { makeActions, makeReducer, makeReducerActions, makeSelectors } from '@/makeDetailsState';

export const selectors = makeSelectors<Domain.ScheduleDetails>({
    getState: rootState => rootState.schedule.update,
});

export const reducerActions = makeReducerActions<Domain.ScheduleDetails>({
    reducerPrefix: '@schedule/update',
});

export const actions = makeActions<Domain.ScheduleDetails>({
    loadApi: options => scheduleApi.GetScheduleDetails(options.urlParams.scheduleId),
    reducerActions,
    selectors,
});

export const updateReducer = makeReducer<Domain.ScheduleDetails>({
    reducerActions,
});
