import * as React from 'react';
import { useSelector } from 'react-redux';

import { selectTranslations } from './selectors';
import { Translations } from './types';

export interface Context {
    translations: Translations;
}

const translationsStore = React.createContext<Context>({
    translations: {},
});

const { Provider } = translationsStore;

const I18nProvider = (props: { children: React.ReactNode }) => {
    const translations = useSelector(selectTranslations);

    return (
        <Provider
            value={{
                translations: translations || {},
            }}
        >
            {props.children}
        </Provider>
    );
};

function useGettext() {
    const { translations } = React.useContext(translationsStore);

    const pgettext = (context: string, text: string): string => {
        if (!translations[context || '']) {
            return text;
        }

        if (!translations[context || ''][text]) {
            return text;
        }

        return translations[context || ''][text];
    };

    const gettext = (text: string): string => {
        return pgettext('', text);
    };

    return {
        gettext,
        pgettext,
    };
}

export { I18nProvider, useGettext };
