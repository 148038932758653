import { Domain } from 'api';

import { localisationApi } from '@/api';
import { makeActions, makeReducer, makeReducerActions, makeSelectors } from '@/makeDetailsState';

import { ALL_COUNTRIES } from './availableCountriesState';

export const selectors = makeSelectors<Domain.Localisation>({
    getState: rootState => rootState.localisation.details,
});

export const reducerActions = makeReducerActions<Domain.Localisation>({
    reducerPrefix: '@localisation/details',
});

export const actions = makeActions<Domain.Localisation>({
    loadApi: options => {
        const country = options.urlParams.country as Domain.Country;
        if (ALL_COUNTRIES.indexOf(country) === -1) {
            return;
        }

        return localisationApi.GetLocalisationDetails(country);
    },
    reducerActions,
    selectors,
});

export const detailsReducer = makeReducer<Domain.Localisation>({
    reducerActions,
});
