import { Locale } from './Localisation';
import { Pagination } from './Pagination';
import { Sorting } from './Sorting';

export type WebshopLibraryPageBlockLayout = 'full' | 'flexible';

export type WebshopLibraryPageBlockType = 'block' | 'carousel';

export type LibraryTileType = 'floating' | 'contained' | 'plain';

export interface WebshopLibraryPageWidgetSingleMediaItem {
    mediaItemId: string;
    url: string;
    device?: 'mobile' | 'tablet' | 'desktop' | null;
}

export function WebshopLibraryPageWidgetMediaIsSingleItem(
    item: WebshopLibraryPageWidgetSingleMediaItem | WebshopLibraryPageWidgetSingleMediaItem[],
): item is WebshopLibraryPageWidgetSingleMediaItem {
    return !Array.isArray(item);
}

export type WebshopLibraryPageWidgetParameterTypeType = {
    media: WebshopLibraryPageWidgetSingleMediaItem | WebshopLibraryPageWidgetSingleMediaItem[];
    'product-ids': string[];
    text: string;
    html: string | object;
    'category-ids': string[];
    'blog-article-ids': string[];
    'opening-hours-position': 'left' | 'right';
    'tile-position': 'left' | 'right' | 'center';
    'tile-type': LibraryTileType;
    layout: 'carousel' | 'grid';
    has_opening_hours: boolean;
    'video-url': string;
};
export type WebshopLibraryPageWidgetParameterType = keyof WebshopLibraryPageWidgetParameterTypeType;

export type WebshopLibraryPageWidgetBase = {
    widget_id: string;
    width_in_percentage: number;
};

export type WebshopLibraryPageWidgetPlaceholder = WebshopLibraryPageWidgetBase & {
    widget_type: 'placeholder';
    details: Record<string, never>;
};

export type WebshopLibraryPageWidgetOpeningHours = WebshopLibraryPageWidgetBase & {
    widget_type: 'opening_hours';
    details: {
        banner?: WebshopLibraryPageWidgetParameterTypeType['media'];
        position?: WebshopLibraryPageWidgetParameterTypeType['opening-hours-position'];
    };
};

export type WebshopLibraryPageWidgetProductList = WebshopLibraryPageWidgetBase & {
    widget_type: 'product_list';
    details: {
        pids?: WebshopLibraryPageWidgetParameterTypeType['product-ids'];
        title?: WebshopLibraryPageWidgetParameterTypeType['text'];
        layout?: WebshopLibraryPageWidgetParameterTypeType['layout'];
    };
};

export type WebshopLibraryPageWidgetTile = WebshopLibraryPageWidgetBase & {
    widget_type: 'tile';
    details: {
        type: WebshopLibraryPageWidgetParameterTypeType['tile-type'];
        position: WebshopLibraryPageWidgetParameterTypeType['tile-position'];
        banner?: WebshopLibraryPageWidgetParameterTypeType['media'];
        title?: WebshopLibraryPageWidgetParameterTypeType['text'];
        description?: WebshopLibraryPageWidgetParameterTypeType['html'];
        button_link?: WebshopLibraryPageWidgetParameterTypeType['text'];
        button_title?: WebshopLibraryPageWidgetParameterTypeType['text'];
        has_opening_hours?: WebshopLibraryPageWidgetParameterTypeType['has_opening_hours'];
    };
};

export type WebshopLibraryPageWidgetCategoryList = WebshopLibraryPageWidgetBase & {
    widget_type: 'category_list';
    details: {
        title?: WebshopLibraryPageWidgetParameterTypeType['text'];
        categoryIds: WebshopLibraryPageWidgetParameterTypeType['category-ids'];
    };
};

export type WebshopLibraryPageWidgetPrescriptionForm = WebshopLibraryPageWidgetBase & {
    widget_type: 'prescription_form';
    details: Record<string, never>;
};

export type WebshopLibraryPageWidgetBlogArticleList = WebshopLibraryPageWidgetBase & {
    widget_type: 'blog_article_list';
    details: {
        articleIds: WebshopLibraryPageWidgetParameterTypeType['blog-article-ids'];
        title?: WebshopLibraryPageWidgetParameterTypeType['text'];
        layout?: WebshopLibraryPageWidgetParameterTypeType['layout'];
    };
};

export type WebshopLibraryPageWidgetVideo = WebshopLibraryPageWidgetBase & {
    widget_type: 'video';
    details: {
        url?: WebshopLibraryPageWidgetParameterTypeType['video-url'];
    };
};

export type WebshopLibraryPageWidget =
    | WebshopLibraryPageWidgetPlaceholder
    | WebshopLibraryPageWidgetOpeningHours
    | WebshopLibraryPageWidgetProductList
    | WebshopLibraryPageWidgetTile
    | WebshopLibraryPageWidgetCategoryList
    | WebshopLibraryPageWidgetPrescriptionForm
    | WebshopLibraryPageWidgetBlogArticleList
    | WebshopLibraryPageWidgetVideo;

export type WebshopLibraryPageWidgetType = WebshopLibraryPageWidget['widget_type'];

export interface WebshopLibraryPageBlock {
    block_id: string;
    block_type: WebshopLibraryPageBlockType;
    block_layout: WebshopLibraryPageBlockLayout;
    scale_to_contain?: boolean;
    widgets: WebshopLibraryPageWidget[];
}

type PageType = 'globalPage' | 'defaultPage' | 'customPage';

export interface WebshopLibraryPage {
    pageId: string;
    title: string;
    slug: string;
    status: 'active' | 'inactive';
    locale: Locale;
    webshopPageType: PageType;
    pageCode?: string | null;
}

export type WebshopLibraryPageDetails = WebshopLibraryPage & {
    blocks: WebshopLibraryPageBlock[];
    heading?: string | null;
    metaTitle?: string | null;
    metaDescription?: string | null;
};

export interface WebshopLibraryPagesPage {
    items: WebshopLibraryPage[];
    total: number;
    pagination: Pagination;
    sorting?: Sorting<keyof WebshopLibraryPage> | null;
    search?: string | null;
}

export type UpdateWebshopLibraryPagePayload = Omit<WebshopLibraryPageDetails, 'companyId' | 'branchId' | 'webshopPageType'> & {
    pageType: PageType;
};

export interface ValidateWebshopLibraryPageTitleResponse {
    valid: boolean;
    generatedSlug: string;
}

export interface RemoveWebshopLibraryPagePayload {
    pageId: string;
}
