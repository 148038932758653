import * as JsSIP from 'jssip';
import { RTCSessionEvent } from 'jssip/lib/UA';

import { config } from 'config';

type RegistrationChangedHandler = () => void;
type RegistrationFailedHandler = () => void;
type IncomingCallStatusChangeHandler = () => void;

let ua: JsSIP.UA | undefined = undefined;
let handleRegistrationChanged: RegistrationChangedHandler | undefined = undefined;
let handleRegistrationFailed: RegistrationFailedHandler | undefined = undefined;
let handleIncomingCallStatusChange: IncomingCallStatusChangeHandler | undefined = undefined;
let _isFailingToRegister = false;
let _incomingCall: RTCSessionEvent | undefined = undefined;
let _incomingCallTracks: {
    kind: MediaStreamTrack['kind'];
    stream: MediaStream;
}[] = [];
let hasAudio = false;
let hasVideo = false;

export function register(options: { sipExtension: string; sipUri: string; sipSecret: string }) {
    if (ua) {
        if (ua.get('authorization_user') === options.sipExtension) {
            return;
        } else {
            unregister();
        }
    }

    hasAudio = false;
    navigator.mediaDevices
        .getUserMedia({
            audio: true,
        })
        .then(stream => {
            stream.getTracks().forEach(track => track.stop());
            hasAudio = true;
        })
        .catch(() => console.info('No audio'));

    hasVideo = false;
    navigator.mediaDevices
        .getUserMedia({
            video: true,
        })
        .then(stream => {
            stream.getTracks().forEach(track => track.stop());
            hasVideo = true;
        })
        .catch(() => console.info('No video'));

    if (handleRegistrationChanged) {
        handleRegistrationChanged();
    }
    const socket = new JsSIP.WebSocketInterface(config.videoCalling.sipWebsocketUri);
    const configuration = {
        sockets: [socket],
        uri: options.sipUri,
        password: options.sipSecret,
        no_answer_timeout: 60, // seconds
    };

    ua = new JsSIP.UA(configuration);
    ua.start();

    ua.on('registered', () => {
        _isFailingToRegister = false;

        if (handleRegistrationChanged) {
            handleRegistrationChanged();
        }
    });

    ua.on('unregistered', () => {
        if (handleRegistrationChanged) {
            handleRegistrationChanged();
        }
    });

    ua.on('registrationFailed', () => {
        _isFailingToRegister = true;

        if (handleRegistrationChanged) {
            handleRegistrationChanged();
        }

        if (handleRegistrationFailed) {
            handleRegistrationFailed();
        }
    });

    ua.on('newRTCSession', (event: RTCSessionEvent) => {
        _incomingCallTracks = [];
        _incomingCall = event;
        if (handleIncomingCallStatusChange) {
            handleIncomingCallStatusChange();
        }

        _incomingCall.session.addListener('ended', () => {
            _incomingCall = undefined;
            _incomingCallTracks = [];

            if (handleIncomingCallStatusChange) {
                handleIncomingCallStatusChange();
            }
        });

        _incomingCall.session.addListener('failed', () => {
            _incomingCall = undefined;
            _incomingCallTracks = [];

            if (handleIncomingCallStatusChange) {
                handleIncomingCallStatusChange();
            }
        });
    });
}

export function unregister() {
    if (!ua) {
        return;
    }

    ua.stop();
    ua = undefined;

    if (handleRegistrationChanged) {
        handleRegistrationChanged();
    }
}

export function isFailingToRegister() {
    if (!ua) {
        return false;
    }

    return _isFailingToRegister;
}

export function isRegistered() {
    if (!ua) {
        return false;
    }

    return ua.isRegistered();
}

export function answerIncomingCall() {
    if (!_incomingCall || (!hasAudio && !hasVideo)) {
        return;
    }

    _incomingCall.session.answer({
        mediaConstraints: { audio: hasAudio, video: hasVideo },
    });

    if (handleIncomingCallStatusChange) {
        handleIncomingCallStatusChange();
    }

    _incomingCall.session.connection.ontrack = trackEvent => {
        if (trackEvent.track.kind === 'audio' || trackEvent.track.kind === 'video') {
            _incomingCallTracks.push({
                kind: trackEvent.track.kind,
                stream: trackEvent.streams[0],
            });

            if (handleIncomingCallStatusChange) {
                handleIncomingCallStatusChange();
            }
        }
    };
}

export function rejectIncomingCall() {
    if (!_incomingCall) {
        return;
    }

    _incomingCall.session.terminate();
    _incomingCall = undefined;
    _incomingCallTracks = [];

    if (handleIncomingCallStatusChange) {
        handleIncomingCallStatusChange();
    }
}

export function mute() {
    if (!_incomingCall || _incomingCall.session.isMuted().audio) {
        return;
    }

    _incomingCall.session.mute({
        video: false,
        audio: true,
    });

    if (handleIncomingCallStatusChange) {
        handleIncomingCallStatusChange();
    }
}

export function unmute() {
    if (!_incomingCall || !_incomingCall.session.isMuted()) {
        return;
    }

    _incomingCall.session.unmute({
        video: false,
        audio: true,
    });

    if (handleIncomingCallStatusChange) {
        handleIncomingCallStatusChange();
    }
}

export function isMuted() {
    if (!_incomingCall) {
        return false;
    }

    return _incomingCall.session.isMuted().audio || false;
}

export function incomingCall() {
    return _incomingCall;
}

export function incomingCallTracks() {
    return _incomingCallTracks;
}

export function incomingCallInProgress() {
    return (_incomingCall && _incomingCall.session && _incomingCall.session.isEstablished()) || false;
}

export function onRegistrationChanged(handler: RegistrationChangedHandler) {
    handleRegistrationChanged = handler;
}

export function onRegistrationFailed(handler: RegistrationFailedHandler) {
    handleRegistrationFailed = handler;
}

export function onIncomingCallStatusChange(handler: IncomingCallStatusChangeHandler) {
    handleIncomingCallStatusChange = handler;
}
