import { Route, anyAuthorization, TVScreen } from 'data-store';
import { PreloaderScreen } from 'vision-ui';

const routes: Route[] = [
    {
        path: '/',
        main: () => import('./TVScreen'),
        preloadData: TVScreen.deviceState.load,
        preloading: PreloaderScreen,
        isAuthorized: anyAuthorization,
        isDevice: true,
    },
    {
        path: '/matic-on-vision',
        main: () => import('./MaticOnVision'),
        isAuthorized: anyAuthorization,
        isDevice: true,
    },
    {
        path: '/slim-on-vision',
        main: () => import('./SlimOnVision'),
        isAuthorized: anyAuthorization,
        isDevice: true,
    },
];

export default routes;
