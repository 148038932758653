import { Domain } from 'api';
import { config } from 'config';

import { PlacedProduct } from '../types';

export default function getSelectedImage(placedProduct: PlacedProduct | undefined, availableProduct: Domain.SlideshowProduct) {
    if (placedProduct && placedProduct.mediaItemId) {
        return {
            id: placedProduct.mediaItemId,
            url: `${config.apiBaseUrl}/media-item/${placedProduct.mediaItemId}/preview`,
        };
    }

    if (Domain.slideshowProductHasFrontalImage(availableProduct) && availableProduct.allFrontals) {
        const selectedImage = availableProduct.allFrontals.find(image => {
            if (placedProduct && placedProduct.imageId) {
                return image.id === placedProduct.imageId;
            } else {
                return image.url === availableProduct.frontalImage;
            }
        });

        if (selectedImage) {
            return selectedImage;
        }
    }

    return {
        id: 'any',
        url: Domain.slideshowProductHasFrontalImage(availableProduct) ? availableProduct.frontalImage : '',
    };
}
