import { Domain } from 'api';

import { productCategoryApi } from '@/api';
import { makeActions, makeReducer, makeReducerActions, makeSelectors } from '@/makeDetailsState';

export const selectors = makeSelectors<Domain.AvailableProductCategory>({
    getState: rootState => rootState.availableProduct.categoriesTree,
});

export const reducerActions = makeReducerActions<Domain.AvailableProductCategory>({
    reducerPrefix: '@availableProduct/categoriesTree',
});

export const actions = makeActions<Domain.AvailableProductCategory>({
    loadApi: options => productCategoryApi.GetCategoriesTree(options.urlParams.locale as Domain.Locale),
    reducerActions,
    selectors,
});

export const categoriesTreeReducer = makeReducer<Domain.AvailableProductCategory>({
    reducerActions,
});
