import ApiClient, { UrlParams } from '@/Infrastructure/Api/ApiClient';
import HttpApiClient from '@/Infrastructure/Api/HttpApiClient';
import { getConstant } from '@/Infrastructure/Container';

export default class DeviceBackend {
    private client: ApiClient;
    private capabilities: {
        version: string;
        cache: boolean;
        monitor: boolean;
    } = {
        version: 'unknown',
        cache: false,
        monitor: false,
    };
    private warmedUpUrls: string[] = [];
    private readonly maxParallelWarmups: number;

    public constructor(maxParallelWarmups = 6) {
        this.maxParallelWarmups = maxParallelWarmups;
        this.client = new HttpApiClient();
    }

    public async initialize(): Promise<void> {
        try {
            const version = await this.get({ url: '/info' });
            this.capabilities.version = version;
            this.capabilities.cache = true;
            this.capabilities.monitor = true;
            console.info('DEVICE BACKEND IS AVAILABLE, ' + version);

            (window as any).BACKEND_SELF_UPDATE = this.selfUpdate.bind(this);
            (window as any).BACKEND_INFO = () => this.get({ url: '/info' });
        } catch (e) {
            console.info('DEVICE BACKEND IS NOT AVAILABLE');
        }
    }

    public cacheIsAvailable(): boolean {
        return this.capabilities.cache;
    }

    public monitorIsAvailable(): boolean {
        return this.capabilities.monitor;
    }

    public async warmUp(urls: string[]): Promise<void> {
        const coldUrls: string[] = [];
        for (const url of urls) {
            if (this.warmedUpUrls.indexOf(url) === -1) {
                coldUrls.push(url);
                this.warmedUpUrls.push(url);
            }
        }

        if (coldUrls.length > 0) {
            const chunks = this.chunkUrls(coldUrls);
            await Promise.all(
                chunks.map(chunk =>
                    this.post({
                        url: '/cache/warmup',
                        body: chunk,
                    }),
                ),
            );
        }

        return Promise.resolve();
    }

    public buildCachedItemUrl(url: string): string {
        if (url.startsWith(getConstant('lochtingDeviceBackendBaseUrl'))) {
            return url;
        }
        return getConstant('lochtingDeviceBackendBaseUrl') + '/cache/item/' + encodeURIComponent(url);
    }

    public async getSystemUsage(): Promise<object> {
        return {
            version: this.capabilities.version,
            ...(await this.get({ url: '/monitor/usage' })),
        };
    }

    public async selfUpdate(sourceUrl: string): Promise<number> {
        return await this.get({ url: '/self-update/' + encodeURIComponent(sourceUrl) });
    }

    private async get(options: { url: string; params?: UrlParams }): Promise<any> {
        return this.client.get({
            ...options,
            url: getConstant('lochtingDeviceBackendBaseUrl') + options.url,
            urlIsExternal: true,
            noDefaultHeaders: true,
            noAuthHeader: true,
        });
    }

    private async post(options: { url: string; body: object | string; params?: UrlParams }): Promise<any> {
        return this.client.post({
            ...options,
            url: getConstant('lochtingDeviceBackendBaseUrl') + options.url,
            urlIsExternal: true,
            noDefaultHeaders: true,
            bodyIsFormData: false,
            bodyIsString: false,
        });
    }

    private chunkUrls(urls: string[]): string[][] {
        const copy = [...urls];
        const chunks: string[][] = [];
        for (let i = this.maxParallelWarmups; i > 0; i--) {
            chunks.push(copy.splice(0, Math.ceil(copy.length / i)));
        }
        return chunks;
    }
}
