import { createAction, createReducer, Selector } from '@reduxjs/toolkit';

import { Domain } from 'api';

import { ThunkAction, withPayloadType } from '@/action';
import { orderReviewApi, scientificCategoryApi } from '@/api';
import * as categoryTreeState from '@/AvailableProduct/categoriesTreeState';
import { getCountryCodeFromLocale } from '@/getCountryCodeFromLocale';
import { URLParams, URLQuery, makeQuery } from '@/routing';
import { RootState } from '@/store';
import { selectGlobalSelectedWebshop } from '@/Webshop/globalSelectionState';

import * as approvedEmailAddressesState from './approvedEmailAddressesState';

export type ScientificCategoryTrees = {
    [key in Domain.ScientificCategorySource]?: Domain.ScientificCategory;
};

export interface State {
    conditions?: Domain.OrderReviewConditions;
    scientificCategoryTrees: ScientificCategoryTrees;
}

const initialState: State = {
    scientificCategoryTrees: {},
};

const reducerActions = {
    setConditions: createAction('@orderReview/update/setConditions', withPayloadType<Domain.OrderReviewConditions | undefined>()),
    setScientificCategoryTrees: createAction('@orderReview/update/setScientificCategoryTrees', withPayloadType<ScientificCategoryTrees>()),
};

export const updateReducer = createReducer(initialState, builder =>
    builder
        .addCase(reducerActions.setConditions, (state, action) => {
            state.conditions = action.payload;
        })
        .addCase(reducerActions.setScientificCategoryTrees, (state, action) => {
            state.scientificCategoryTrees = action.payload;
        }),
);

export const selectConditions: Selector<RootState, Domain.OrderReviewConditions | undefined> = state => state.orderReview.update.conditions;
export const selectScientificCategoryTrees: Selector<RootState, ScientificCategoryTrees> = state =>
    state.orderReview.update.scientificCategoryTrees;

export const loadConditions =
    (branchId: string): ThunkAction =>
    async dispatch => {
        const conditions = await orderReviewApi.GetOrderReviewConditions(branchId);
        await dispatch(reducerActions.setConditions(conditions));
    };

export const loadScientificCategoryTrees = (): ThunkAction => async (dispatch, getState) => {
    const state = getState();
    const webshop = selectGlobalSelectedWebshop(state);
    if (!webshop) {
        return;
    }

    const country = getCountryCodeFromLocale(webshop.defaultLocale || webshop.locales[0]);
    const scientificCategoriesSources = state.localisation.availableCountries.countries[country]
        ? state.localisation.availableCountries.countries[country].scientificCategories
        : undefined;
    if (scientificCategoriesSources) {
        const categories = await Promise.all(
            scientificCategoriesSources.map(source => {
                return scientificCategoryApi.GetScientificCategoriesTree(source);
            }),
        );

        const trees: ScientificCategoryTrees = {};
        let sourceIndex = 0;
        for (const source of scientificCategoriesSources) {
            trees[source] = categories[sourceIndex];
            sourceIndex += 1;
        }

        await dispatch(reducerActions.setScientificCategoryTrees(trees));
    }
};

export const load =
    (loadOptions: { urlQuery: URLQuery; urlParams: URLParams }): ThunkAction =>
    async (dispatch, getState) => {
        const state = getState();
        const webshop = selectGlobalSelectedWebshop(state);
        if (!webshop) {
            return;
        }

        await Promise.all([
            dispatch(loadConditions(webshop.branchId)),
            dispatch(loadScientificCategoryTrees()),
            dispatch(approvedEmailAddressesState.actions.load(loadOptions)),
            dispatch(
                categoryTreeState.actions.load({
                    ...loadOptions,
                    urlParams: {
                        locale: webshop.defaultLocale || webshop.locales[0],
                        ...loadOptions.urlParams,
                    },
                }),
            ),
        ]);
    };

export const reload = (): ThunkAction => async dispatch => {
    await dispatch(
        load({
            urlQuery: makeQuery(''),
            urlParams: {},
        }),
    );
};

export const reloadConditions = (): ThunkAction => async (dispatch, getState) => {
    const state = getState();
    const webshop = selectGlobalSelectedWebshop(state);
    if (!webshop) {
        return;
    }

    await dispatch(loadConditions(webshop.branchId));
};

export const reloadEmailAddresses = (): ThunkAction => async dispatch => {
    await dispatch(
        approvedEmailAddressesState.actions.load({
            urlQuery: makeQuery(''),
            urlParams: {},
        }),
    );
};
