import { Domain } from 'api';

import { exportApi } from '@/api';
import { makeActions, makeReducer, makeReducerActions, makeSelectors } from '@/makeOverviewState';

type DeviceOrdersExportProps = keyof Domain.Export;

const defaultSorting: Domain.Sorting<DeviceOrdersExportProps> = {
    field: 'initializedOn',
    direction: 'descending',
};

const pageSize = 20;

export const selectors = makeSelectors<Domain.Export, DeviceOrdersExportProps>({
    getState: rootState => rootState.deviceOrderExport.overview,
});

export const reducerActions = makeReducerActions<Domain.Export, DeviceOrdersExportProps>({
    reducerPrefix: '@orderExport/overview',
});

export const actions = makeActions<Domain.Export, DeviceOrdersExportProps>({
    dataTableSaveKey: 'deviceOrderExportsOverview-v5',
    loadApi: options => exportApi.GetExports(options.pagination, options.sorting, { exportType: 'deviceOrderExport' }),
    defaultSorting,
    pageSize,
    getBaseUrl: () => '/vending-machines/order-export',
    reducerActions,
    selectors,
});

export const overviewReducer = makeReducer<Domain.Export, DeviceOrdersExportProps>({
    defaultSorting,
    pageSize,
    reducerActions,
});
