import { createAction, createReducer } from '@reduxjs/toolkit';

import { withPayloadType } from '@/action';

import { AuthenticatedUser, State } from './types';

export const setLoggedInAs = createAction('@authentication/setLoggedInAs', withPayloadType<AuthenticatedUser>());
export const setLoggedOut = createAction('@authentication/setLoggedOut');

const initialState: State = {
    loggedInAs: undefined,
};

export const authenticationReducer = createReducer(initialState, builder =>
    builder
        .addCase(setLoggedInAs, (state, action) => {
            if (JSON.stringify(state.loggedInAs) === JSON.stringify(action.payload)) {
                return state;
            }

            state.loggedInAs = action.payload;
        })
        .addCase(setLoggedOut, state => {
            state.loggedInAs = undefined;
        }),
);
