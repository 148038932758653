import { Domain } from 'api';

import { userApi } from '@/api';
import { makeActions, makeReducer, makeReducerActions, makeSelectors } from '@/makeDetailsState';

export const selectors = makeSelectors<Domain.UserDetails>({
    getState: rootState => rootState.user.update,
});

export const reducerActions = makeReducerActions<Domain.UserDetails>({
    reducerPrefix: '@user/update',
});

export const actions = makeActions<Domain.UserDetails>({
    loadApi: options => userApi.GetUserDetails(options.urlParams.userId),
    reducerActions,
    selectors,
});

export const updateReducer = makeReducer<Domain.UserDetails>({
    reducerActions,
});
