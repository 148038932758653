import * as React from 'react';

import ControlledSlider, { ControlledSliderProps } from './ControlledSlider';

export type UncontrolledSliderProps = ControlledSliderProps & {
    defaultValue?: number;
};

interface IState {
    value: number;
}

class UncontrolledSlider extends React.PureComponent<UncontrolledSliderProps, IState> {
    constructor(props: UncontrolledSliderProps) {
        super(props);

        this.state = {
            value: props.defaultValue !== undefined ? props.defaultValue : props.min,
        };
    }

    render() {
        const { children, onChange, onChangeComplete, forwardRef, ...rest } = this.props;

        return (
            <ControlledSlider
                {...rest}
                ref={forwardRef}
                value={this.state.value}
                onChange={this.handleChange}
                onChangeComplete={this.handleChangeComplete}
            />
        );
    }

    protected handleChange = (newValue: number): void => {
        const { onChange } = this.props;

        this.setState(
            {
                value: newValue,
            },
            () => {
                if (onChange) {
                    onChange(this.state.value);
                }
            },
        );
    };

    protected handleChangeComplete = (newValue: number): void => {
        const { onChangeComplete } = this.props;

        this.setState(
            {
                value: newValue,
            },
            () => {
                if (onChangeComplete) {
                    onChangeComplete(this.state.value);
                }
            },
        );
    };
}

export default React.forwardRef<HTMLInputElement, UncontrolledSliderProps>((props, ref) => {
    return (
        <UncontrolledSlider
            forwardRef={ref}
            {...props}
        />
    );
});
