import * as React from 'react';

import classNames from 'classnames';

import { BaseProps } from '@/core';
import { Tx } from '@/typography';

import styles from './Breadcrumbs.scss';

type BaseCrumbProps = {
    label: React.ReactNode;
};

type NoActionProps = Record<string, unknown>;

type ActionProps = {
    action: (event: React.MouseEvent) => void;
};

type CustomProps<T> = T & {
    customProps: T;
};

export type Crumb = BaseCrumbProps & (NoActionProps | ActionProps);

export type CrumbWithCustomComponent<T> = BaseCrumbProps & (NoActionProps | ActionProps | CustomProps<T>);

interface IPropsNoCustomComponent {
    linkComponent?: never;
    path: Crumb[];
}

interface IPropsCustomComponent<T> {
    linkComponent: React.ComponentType<T>;
    path: CrumbWithCustomComponent<T>[];
}

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
type IProps<T extends any> = BaseProps & (IPropsNoCustomComponent | IPropsCustomComponent<T>);

export default class Breadcrumbs<T> extends React.PureComponent<IProps<T> & React.HTMLProps<HTMLDivElement>> {
    render() {
        const { path, linkComponent, className, style, ...rest } = this.props;

        return (
            <div
                {...rest}
                className={classNames(styles.Breadcrumbs, className)}
                style={style}
            >
                {(path as any).map((crumb: CrumbWithCustomComponent<T>, index: number) => {
                    const LinkComponent: any = this.getLinkComponent(crumb);
                    const linkProps = this.getCustomLinkProps(crumb);

                    return (
                        <div
                            className={styles.Crumb}
                            key={index}
                        >
                            <Tx
                                level="body-lg"
                                as="span"
                            >
                                <LinkComponent {...linkProps}>{crumb.label}</LinkComponent>
                            </Tx>

                            <div className={styles.Separator}>&#47;</div>
                        </div>
                    );
                })}
            </div>
        );
    }

    private getLinkComponent(crumb: CrumbWithCustomComponent<T>): 'a' | React.ComponentType<T> | 'span' {
        if (this.crumbHasAction(crumb)) {
            return 'a';
        } else if (this.hasCustomComponent(this.props) && this.crumbHasCustomComponent(crumb)) {
            return this.props.linkComponent;
        }

        return 'span';
    }

    private getCustomLinkProps(crumb: CrumbWithCustomComponent<T>): Record<string, unknown> | ActionProps | T {
        if (this.crumbHasAction(crumb)) {
            return {
                href: '',
                onClick: (event: React.MouseEvent) => this.handleCrumbClick(event, crumb),
            };
        } else if (this.hasCustomComponent(this.props) && this.crumbHasCustomComponent(crumb)) {
            return crumb.customProps;
        }

        return {};
    }

    private hasCustomComponent(props: IProps<T>): props is IPropsCustomComponent<T> {
        return (props as IPropsCustomComponent<T>).hasOwnProperty('linkComponent');
    }

    private crumbHasAction(crumb: CrumbWithCustomComponent<T>): crumb is BaseCrumbProps & ActionProps {
        return (crumb as ActionProps).hasOwnProperty('action');
    }

    private crumbHasCustomComponent(crumb: CrumbWithCustomComponent<T>): crumb is T & BaseCrumbProps & CustomProps<T> {
        return (crumb as CustomProps<T>).hasOwnProperty('customProps');
    }

    private handleCrumbClick = (event: React.MouseEvent, crumb: ActionProps): void => {
        if (crumb.action) {
            crumb.action(event);
        }
    };
}
