import { createAction, Selector, createReducer } from '@reduxjs/toolkit';

import { Domain } from 'api';

import { ThunkAction, withPayloadType } from '@/action';
import { webshopFaqLibraryApi } from '@/api';
import { URLParams } from '@/routing';
import { RootState } from '@/store';

export interface State {
    faqItem?: Domain.WebshopFaqItemDetails;
    faqCategories?: Domain.WebshopFaqCategoriesPage;
}

const initialState: State = {};

const reducerActions = {
    setFaqItem: createAction('@webshopFaqItem/update/setFaqItem', withPayloadType<Domain.WebshopFaqItemDetails>()),
    setFaqCategories: createAction('@webshopFaqItem/update/setFaqCategories', withPayloadType<Domain.WebshopFaqCategoriesPage>()),
};

export const updateReducer = createReducer(initialState, builder =>
    builder
        .addCase(reducerActions.setFaqItem, (state, action) => {
            state.faqItem = action.payload;
        })
        .addCase(reducerActions.setFaqCategories, (state, action) => {
            state.faqCategories = action.payload;
        }),
);

export const selectFaqItem: Selector<RootState, Domain.WebshopFaqItemDetails> = state => {
    const faqItem = state.webshopFaqItemLibrary.update.faqItem;
    if (!faqItem) {
        throw new Error('FAQ library item not loaded');
    }

    return faqItem;
};

export const selectFaqCategories: Selector<RootState, Domain.WebshopFaqCategoriesPage> = state => {
    const faqCategories = state.webshopFaqItemLibrary.update.faqCategories;
    if (!faqCategories) {
        throw new Error('FAQ library categories not loaded');
    }

    return faqCategories;
};

export const loadFaqItem =
    (faqItemId: Domain.WebshopFaqItem['faqItemId']): ThunkAction<Promise<Domain.WebshopFaqItemDetails>> =>
    async dispatch => {
        const faqItem = await webshopFaqLibraryApi.GetWebshopFaqLibraryItemDetails(faqItemId);

        await dispatch(reducerActions.setFaqItem(faqItem));

        return faqItem;
    };

export const loadFaqCategories = (): ThunkAction<Promise<Domain.WebshopFaqCategoriesPage>> => async dispatch => {
    const faqCategories = await webshopFaqLibraryApi.GetWebshopFaqLibraryCategories(
        { page: 1, size: 999 },
        { field: 'sortOrder', direction: 'ascending' },
    );

    await dispatch(reducerActions.setFaqCategories(faqCategories));

    return faqCategories;
};

export const load =
    (options: { urlParams: URLParams }): ThunkAction =>
    async dispatch => {
        await Promise.all([dispatch(loadFaqCategories()), dispatch(loadFaqItem(options.urlParams.faqItemId))]);
    };
