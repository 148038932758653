import { Wwks2StatusDialog } from '../../../../Domain';
import Wwks2CommClient from '../../Wwks2CommClient';


export default class Wwks2StockLocation {

    private Wwks2CommClient: Wwks2CommClient;

    public constructor(Wwks2CommClient: Wwks2CommClient) {
        this.Wwks2CommClient = Wwks2CommClient;
    }

    public sendStockLocationInfoResponse(dialogId: string | number, location: string | string[]): Promise<Wwks2StatusDialog> {
        return new Promise(resolve => {

            let locations: object[];
            if (Array.isArray(location)) {
                locations = location.map((loc: string) => {
                    return {
                        '@': {
                            'Id': loc
                        }
                    }
                })
            } else {
                locations = [location].map((loc: string) => {
                    return {
                        '@': {
                            'Id': loc
                        }
                    }
                })
            }

            const wwks = {
                'StockLocationInfoResponse': {
                    '@': {
                        'Id': dialogId,
                        'Source': this.Wwks2CommClient.GetSourceId(),
                        'Destination': this.Wwks2CommClient.GetClientId(),
                    },
                    '#': {
                        'StockLocation': locations
                    }
                }
            }
            this.Wwks2CommClient.createAndSendDialog(wwks, 'StockLocationInfo', dialogId, 'response').then(status => {
                resolve(status);
            });
        });
    }

    public handleMessage(WWKS: any) {

        if (typeof WWKS.StockLocationInfoRequest !== 'undefined') {
            const responseId = WWKS.StockLocationInfoRequest['@attributes'].Id;
            const clientId = WWKS.StockLocationInfoRequest['@attributes'].Source;
            const sourceId = WWKS.StockLocationInfoRequest['@attributes'].Destination;

            if ((this.Wwks2CommClient.checkWwks2SourceDestinationIdInRequestMessage && sourceId == this.Wwks2CommClient.GetSourceId()) || !this.Wwks2CommClient.checkWwks2SourceDestinationIdInRequestMessage) {   // check if the message is for us
                if ((this.Wwks2CommClient.checkWwks2SourceDestinationIdInRequestMessage && clientId == this.Wwks2CommClient.GetClientId()) || !this.Wwks2CommClient.checkWwks2SourceDestinationIdInRequestMessage) { // check if the message comes from our connected client
                    this.sendStockLocationInfoResponse(responseId, this.Wwks2CommClient.GetStockLocation()).then((status) => {
                        if (status.status) {
                            // trigger an event when stockInfoRequest is been done
                        }
                    })
                }
            }
        }

    }
}