import { createAction, createReducer } from '@reduxjs/toolkit';

import { Domain } from 'api';

import { withPayloadType } from '@/action';

export interface PlatformNotificationsState {
    unreadCount: number;
    readCount: number;
    allNotifications: Domain.NotificationsPage;
}

export const setUserUnreadNotificationsCount = createAction(
    '@platformNotifications/setUserUnreadNotificationsCount',
    withPayloadType<Domain.NotificationsCount>(),
);
export const setAllUserNotifications = createAction(
    '@platformNotifications/setAllUserNotifications',
    withPayloadType<Domain.NotificationsPage>(),
);

const initialState: PlatformNotificationsState = {
    unreadCount: 0,
    readCount: 0,
    allNotifications: {
        items: [],
        total: 0,
        pagination: {
            page: 0,
            size: 10,
        },
    },
};

export const platformNotificationReducer = createReducer(initialState, builder =>
    builder
        .addCase(setUserUnreadNotificationsCount, (state, action) => {
            state.unreadCount = action.payload.unread;
            state.readCount = action.payload.read;
        })
        .addCase(setAllUserNotifications, (state, action) => {
            state.allNotifications = action.payload;
        }),
);
