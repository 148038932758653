import { Pagination, Sorting, Ownership, InvoicesPage } from '@/Domain';

import Endpoint from './Endpoint';

export default class InvoiceEndpoint extends Endpoint {
    public async GetInvoices(
        ownership: Ownership,
        pagination: Pagination,
        sorting: Sorting,
        search?: string,
        filters?: {
            companyId?: string;
            branchId?: string;
        },
    ): Promise<InvoicesPage> {
        let url = '/invoice/overview';
        if (ownership.type === 'company') {
            url = `/company/${ownership.ownerId}/invoices`;
        } else if (ownership.type === 'branch') {
            url = '/invoice/overview/branch';
        }

        const response = await this.client.get({
            url,
            params: {
                pagination,
                sorting,
                search,
                filters,
            },
        });

        this.guardResponseSchema('#/definitions/InvoicesPage', response);

        return response;
    }

    public GetInvoiceDownloadURL(invoiceId: string): string {
        return this.client.buildApiURL(`/invoice/${invoiceId}/pdf`, true);
    }
}
