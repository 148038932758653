import { Pagination, Sorting, ActivityLogPage, LogActivityPayload, CreateDeliveryBoxImagePayload } from '@/Domain';

import Endpoint from './Endpoint';

export default class ActivityLogEndpoint extends Endpoint {
    public async GetActivityLog(
        companyId: string,
        branchId: string,
        subject: string,
        pagination: Pagination,
        sorting: Sorting,
        filters?: {
            hasImage: boolean;
        },
    ): Promise<ActivityLogPage> {
        const response = await this.client.get({
            url: `/activity-log/${companyId}/${branchId}/${subject}`,
            params: {
                pagination,
                sorting,
                filters,
            },
        });

        this.guardResponseSchema('#/definitions/ActivityLogPage', response);
        return response;
    }

    public async LogActivity(payload: LogActivityPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/LogActivity',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Logging\\ActivityLog\\LogActivity\\LogActivity',
                payload,
            },
        });
    }

    public async CreateDeliveryBoxImage(payload: CreateDeliveryBoxImagePayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/CreateDeliveryBoxImage',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Logging\\ActivityLog\\LogDeliveryImage\\LogDeliveryImage',
                payload,
            },
        });
    }

    public GetDeliveryBoxImageURL(activityLogId: string): string {
        return this.client.buildApiURL(`/activity-log/${activityLogId}/image`, true);
    }
}
