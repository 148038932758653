import * as React from 'react';

import classNames from 'classnames';

import { store } from '../state';
import { Frame } from '../types';
import { computeShelfMetas } from '../utils';

import styles from './styles/TouchTargets.scss';

export default function TouchTargets(props: {
    className?: string;
    style?: React.CSSProperties;
    scaleTo?: {
        width: number;
        height: number;
    };
    onProductClick: (productId: string) => void;
}) {
    const { scaleTo, onProductClick } = props;
    const { state } = React.useContext(store);
    const shelves = computeShelfMetas(state.canvas, state.screenResolution, state.availableProducts, state.productImageSizes);
    const scaleFactor = scaleTo ? scaleTo.width / state.canvas.width : 1;

    return (
        <div
            className={classNames(props.className, styles.TouchTargets)}
            style={{
                ...props.style,
                transform: 'scale(' + scaleFactor + ')',
                width: (scaleTo ? scaleTo.width : state.canvas.width) + 'px',
                height: (scaleTo ? scaleTo.height : state.canvas.height) + 'px',
            }}
        >
            <div
                className={classNames(styles.Body, state.screenResolution)}
                style={{
                    width: state.canvas.width + 'px',
                    height: state.canvas.height + 'px',
                }}
            >
                {shelves.map(item => {
                    const { shelf } = item;

                    return (
                        <div
                            key={shelf.id}
                            className={classNames(styles.BodyArea, shelf.type)}
                            style={{
                                top: shelf.y + 'px',
                                height: shelf.height + 'px',
                            }}
                        >
                            {shelf.type === 'productsShelf' && item.meta
                                ? item.meta.placedProducts.map(placedProduct => {
                                      return (
                                          <a
                                              key={placedProduct.area.id}
                                              href=""
                                              className={styles.PlacedProduct}
                                              style={{
                                                  left: placedProduct.area.x + 'px',
                                                  width: placedProduct.area.width + 'px',
                                                  height: placedProduct.area.height + 'px',
                                              }}
                                              onClick={e => {
                                                  e.preventDefault();
                                                  onProductClick(placedProduct.product.productId);
                                              }}
                                              data-test-id={`product-${placedProduct.product.productId}`}
                                          >
                                              &nbsp;
                                          </a>
                                      );
                                  })
                                : null}
                        </div>
                    );
                })}

                {state.canvas.frames.map((frame: Frame) => {
                    if (frame.type === 'hotSpot' && frame.hotSpotType === 'productDetails') {
                        return (
                            <a
                                key={frame.frameId}
                                href=""
                                className={styles.PlacedProduct}
                                style={{
                                    top: frame.y + 'px',
                                    left: frame.x + 'px',
                                    height: frame.height + 'px',
                                    width: frame.width + 'px',
                                    zIndex: (frame.depth || 0) + 999,
                                }}
                                onClick={e => {
                                    e.preventDefault();

                                    if (frame.productId) {
                                        onProductClick(frame.productId);
                                    }
                                }}
                                data-test-id={`product-${frame.productId}`}
                            >
                                &nbsp;
                            </a>
                        );
                    }

                    return null;
                })}
            </div>
        </div>
    );
}
