import * as React from 'react';

import { Portal } from 'ui-components';

import { store } from '../state';

import styles from './styles/DraggableBlockDragLayer.scss';

export default function DraggableBlockDragLayer(props: { draggedBlockRenderer?: (type: string) => React.ReactNode }) {
    const { state } = React.useContext(store);

    if (!state.draggedBlockType) {
        return null;
    }

    return (
        <Portal aboveSidebar>
            <div
                className={styles.DraggableBlockDragLayer}
                style={{
                    left: state.draggedBlockPosition.x + 'px',
                    top: state.draggedBlockPosition.y + 'px',
                    width: state.draggedBlockPosition.width + 'px',
                    height: state.draggedBlockPosition.height + 'px',
                }}
            >
                {props.draggedBlockRenderer ? props.draggedBlockRenderer(state.draggedBlockType) : null}
            </div>
        </Portal>
    );
}
