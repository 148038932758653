import { combineReducers } from '@reduxjs/toolkit';

import { createReducer } from './createState';
import { overviewReducer } from './overviewState';
import { updateReducer } from './updateState';

export const webshopFaqItemLibraryReducer = combineReducers({
    overview: overviewReducer,
    update: updateReducer,
    create: createReducer,
});
