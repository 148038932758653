import { combineReducers } from '@reduxjs/toolkit';

import { detailsReducer } from './detailsState';
import { globalSelectionReducer } from './globalSelectionState';
import { overviewReducer } from './overviewState';
import { updateReducer } from './updateState';

export const webshopReducer = combineReducers({
    globalSelection: globalSelectionReducer,
    overview: overviewReducer,
    details: detailsReducer,
    update: updateReducer,
});
