import * as React from 'react';

import TouchAndMouseDragHandler from './TouchAndMouseDragHandler';

import styles from './SortableGrid.scss';

interface IProps {
    items: React.ReactNode[];
    itemWidth: number;
    itemHeight: number;
    onItemMove: (itemIndex: number, moveOffset: number) => void;
}

interface IState {
    draggedItemIndex: number | undefined;
    totalXMove: number;
    totalYMove: number;
    moveXOffset: number;
    moveYOffset: number;
    gridWidth: number;
    gridHeight: number;
}

export default class SortableGrid extends React.PureComponent<IProps, IState> {
    private readonly moveHandler: TouchAndMouseDragHandler<number>;

    constructor(props: IProps) {
        super(props);

        this.state = {
            draggedItemIndex: undefined,
            totalXMove: 0,
            totalYMove: 0,
            moveXOffset: 0,
            moveYOffset: 0,
            gridWidth: 0,
            gridHeight: 0,
        };

        // we always use this.props inside the move handler, otherwise it's a reference to the initial constructor props
        this.moveHandler = new TouchAndMouseDragHandler<number>(
            move => {
                const newTotalXMove = this.state.totalXMove + move.movementX;
                const newTotalYMove = this.state.totalYMove + move.movementY;

                this.setState({
                    totalXMove: newTotalXMove,
                    totalYMove: newTotalYMove,
                    moveXOffset: Math.round(newTotalXMove / this.props.itemWidth),
                    moveYOffset: Math.round(newTotalYMove / this.props.itemHeight),
                });
            },
            (_1, index) => {
                this.setState({
                    draggedItemIndex: index,
                });
            },
            () => {
                const { draggedItemIndex, moveXOffset, moveYOffset } = this.state;

                if (draggedItemIndex !== undefined && (moveXOffset !== 0 || moveYOffset !== 0)) {
                    const numColumns = Math.floor(this.state.gridWidth / this.props.itemWidth);
                    const moveOffset = moveYOffset * numColumns + moveXOffset;
                    let cappedMoveOffset = moveOffset;
                    if (draggedItemIndex + cappedMoveOffset > this.props.items.length - 1) {
                        cappedMoveOffset = this.props.items.length - 1 - draggedItemIndex;
                    } else if (draggedItemIndex + cappedMoveOffset < 0) {
                        cappedMoveOffset = -draggedItemIndex;
                    }

                    this.props.onItemMove(draggedItemIndex, cappedMoveOffset);
                }

                this.setState({
                    draggedItemIndex: undefined,
                    totalXMove: 0,
                    totalYMove: 0,
                    moveXOffset: 0,
                    moveYOffset: 0,
                });
            },
        );
    }

    render() {
        const { items, itemWidth, itemHeight } = this.props;
        const { draggedItemIndex, totalXMove, totalYMove, moveXOffset, moveYOffset } = this.state;
        const numColumns = Math.floor(this.state.gridWidth / itemWidth);
        const moveOffset = moveYOffset * numColumns + moveXOffset;

        let cappedMoveOffset = moveOffset;

        const itemIndexes = [];
        for (let i = 0; i < items.length; i++) {
            itemIndexes.push(i);
        }

        if (draggedItemIndex !== undefined && moveOffset !== 0) {
            if (draggedItemIndex + cappedMoveOffset > items.length - 1) {
                cappedMoveOffset = items.length - 1 - draggedItemIndex;
            } else if (draggedItemIndex + cappedMoveOffset < 0) {
                cappedMoveOffset = -draggedItemIndex;
            }

            const itemToMove = itemIndexes.splice(draggedItemIndex, 1)[0];
            itemIndexes.splice(draggedItemIndex + cappedMoveOffset, 0, itemToMove);
        }

        return (
            <div
                className={styles.SortableGrid}
                ref={element => {
                    if (element) {
                        const elementBox = element.getBoundingClientRect();
                        this.setState({
                            gridWidth: elementBox.width,
                            gridHeight: elementBox.height,
                        });
                    }
                }}
            >
                {itemIndexes.map(index => {
                    const isDraggedItem = this.state.draggedItemIndex === index;
                    const itemStyle: React.CSSProperties = {};

                    if (isDraggedItem) {
                        itemStyle.left = totalXMove - moveXOffset * itemWidth + 'px';
                        itemStyle.top = totalYMove - moveYOffset * itemHeight + 'px';
                    }

                    return (
                        <div
                            key={index}
                            className={`
                                ${styles.SortableGridItem}
                                ${this.state.draggedItemIndex !== undefined ? styles.DraggingSortableGridItem : ''}
                                ${isDraggedItem ? styles.DraggedSortableGridItem : ''}
                            `}
                            style={itemStyle}
                            onMouseDown={event => this.moveHandler.handleMouseDown(event, index)}
                            onTouchStart={event => this.moveHandler.handleTouchStart(event, index)}
                        >
                            {items[index]}
                        </div>
                    );
                })}
            </div>
        );
    }
}
