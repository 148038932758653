export const fonts: {
    label: string;
    family: string;
    sourceUrl?: string;
}[] = [
    {
        label: 'Alegreya Sans',
        family: '"Alegreya Sans", sans-serif',
        sourceUrl: 'https://fonts.googleapis.com/css2?family=Alegreya+Sans:wght@400;700&display=swap',
    },
    { label: 'Arial', family: 'Arial, sans-serif' },
    { label: 'Calibre', family: 'Calibre, sans-serif' },
    { label: 'Campaign', family: 'campaign, sans-serif', sourceUrl: 'https://use.typekit.net/nyg7jgy.css' },
    {
        label: 'Exo 2',
        family: '"Exo 2", sans-serif',
        sourceUrl: 'https://fonts.googleapis.com/css2?family=Exo+2:wght@400;700&display=swap',
    },
    { label: 'Fira Sans', family: 'fira-sans, sans-serif', sourceUrl: 'https://use.typekit.net/nyg7jgy.css' },
    {
        label: 'Josefin Slab',
        family: '"Josefin Slab", serif',
        sourceUrl: 'https://fonts.googleapis.com/css2?family=Josefin+Slab:wght@400;700&display=swap',
    },
    { label: 'Kanit', family: 'Kanit, sans-serif', sourceUrl: 'https://fonts.googleapis.com/css2?family=Kanit:wght@400;700&display=swap' },
    { label: 'Lato', family: 'Lato, sans-serif', sourceUrl: 'https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap' },
    {
        label: 'Merriweather',
        family: 'Merriweather, serif',
        sourceUrl: 'https://fonts.googleapis.com/css2?family=Merriweather:wght@400;700&display=swap',
    },
    {
        label: 'Merriweather Sans',
        family: '"Merriweather Sans", sans-serif',
        sourceUrl: 'https://fonts.googleapis.com/css2?family=Merriweather+Sans:wght@400;700&display=swap',
    },
    {
        label: 'Montserrat',
        family: 'Montserrat, sans-serif',
        sourceUrl: 'https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap',
    },
    {
        label: 'Noto Sans',
        family: '"Noto Sans", sans-serif',
        sourceUrl: 'https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;700&display=swap',
    },
    {
        label: 'Nunito',
        family: 'Nunito, sans-serif',
        sourceUrl: 'https://fonts.googleapis.com/css2?family=Nunito:wght@400;700&display=swap',
    },
    {
        label: 'Open Sans',
        family: '"Open Sans", sans-serif',
        sourceUrl: 'https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap',
    },
    {
        label: 'Oswald',
        family: 'Oswald, sans-serif',
        sourceUrl: 'https://fonts.googleapis.com/css2?family=Oswald:wght@400;700&display=swap',
    },
    {
        label: 'Playfair Display',
        family: '"Playfair Display", serif',
        sourceUrl: 'https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;700&display=swap',
    },
    {
        label: 'PT Sans',
        family: '"PT Sans", sans-serif',
        sourceUrl: 'https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;700&display=swap',
    },
    {
        label: 'Raleway',
        family: 'Raleway, sans-serif',
        sourceUrl: 'https://fonts.googleapis.com/css2?family=Raleway:wght@400;700&display=swap',
    },
    {
        label: 'Roboto',
        family: 'Roboto, sans-serif',
        sourceUrl: 'https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap',
    },
    { label: 'Sofia Pro', family: 'sofia-pro, sans-serif', sourceUrl: 'https://use.typekit.net/nyg7jgy.css' },
    { label: 'Soleil', family: 'soleil, sans-serif', sourceUrl: 'https://use.typekit.net/nyg7jgy.css' },
    {
        label: 'Source Sans Pro',
        family: '"Source Sans Pro", sans-serif',
        sourceUrl: 'https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;700&display=swap',
    },
    {
        label: 'Titillium Web',
        family: '"Titillium Web", sans-serif',
        sourceUrl: 'https://fonts.googleapis.com/css2?family=Titillium+Web:wght@400;700&display=swap',
    },
    {
        label: 'Ubuntu',
        family: 'Ubuntu, sans-serif',
        sourceUrl: 'https://fonts.googleapis.com/css2?family=Ubuntu:wght@400;700&display=swap',
    },
    { label: 'Zeitung Micro', family: 'zeitung-micro, sans-serif', sourceUrl: 'https://use.typekit.net/nyg7jgy.css' },
];
