import * as React from 'react';

import classNames from 'classnames';

import { BaseProps } from '@/core';

import Icon, { IconSize } from './Icon';

import styles from './Icon.scss';

interface IProps {
    iconSize?: IconSize;
    src: string;
}

export default class BadgeIcon extends React.PureComponent<BaseProps & IProps & React.HTMLProps<HTMLSpanElement>> {
    render() {
        const { iconSize, src, className, children, ...rest } = this.props;

        return (
            <Icon
                {...(rest as any)}
                iconSize={iconSize}
                className={classNames(styles.BadgeIcon, className)}
                src={src}
            >
                {children}
            </Icon>
        );
    }
}
