import { Domain } from 'api';

export default function getProductImages(product: Domain.SlideshowProduct) {
    let productImage = '';
    let allPackshots: Domain.SlideshowProductWithProductImage['allPackshots'] = [];
    let frontalImage = '';
    let allFrontals: Domain.SlideshowProductWithFrontalImage['allFrontals'] = [];

    if (Domain.slideshowProductHasProductImage(product)) {
        productImage = product.productImage;
        allPackshots = product.allPackshots || [];
    }

    if (Domain.slideshowProductHasFrontalImage(product)) {
        frontalImage = product.frontalImage;
        allFrontals = product.allFrontals || [];
    }

    return {
        productImage,
        allPackshots,
        frontalImage,
        allFrontals,
    };
}
