import * as React from 'react';

import classNames from 'classnames';

import Tx from '../typography/Typography';
import BaseProps from './BaseProps';

import styles from './InputBase.scss';
export type InputBaseProps = BaseProps & {
    name?: string;
    label?: string | React.ReactNode;
    disabled?: boolean;
    description?: string | React.ReactNode;
    error?: boolean;
    errorMessage?: string | React.ReactNode;
    forwardRef?: React.Ref<any>;
    highlight?: boolean;
    'data-test-id'?: string;
};

export default class InputBase<P, S = Record<string, unknown>> extends React.PureComponent<P & InputBaseProps, S> {
    protected readonly wrapperRef = React.createRef<any>();

    render() {
        const Wrapper = this.getBaseWrapperComponent() as any;

        return (
            <Wrapper
                ref={this.wrapperRef}
                className={this.getClassName()}
                style={this.getStyle()}
                tabIndex={this.getTabIndex()}
                {...this.getOtherBaseWrapperProps()}
            >
                {this.renderLabel()}

                <span className={styles.InputBaseWrapperInner}>{this.renderInput()}</span>

                {this.renderDescription()}
                {this.renderError()}
            </Wrapper>
        );
    }

    protected getBaseWrapperComponent(): string {
        return 'label';
    }

    protected getDataTestIdProps(prefix = ''): any {
        const otherProps: any = {};
        const dataTestId = this.getDataTestId();
        if (dataTestId !== undefined) {
            otherProps['data-test-id'] = `${prefix}${prefix ? '-' : ''}${dataTestId}`;
        }

        return otherProps;
    }

    protected getOtherBaseWrapperProps(): any {
        return this.getDataTestIdProps('input-wrapper');
    }

    protected getDataTestId(): string | undefined {
        return this.props['data-test-id'] || (this.props.name ? `input-name-${this.props.name}` : undefined);
    }

    protected renderInput(): React.ReactNode {
        return null;
    }

    protected renderLabel() {
        const { label, highlight } = this.props;

        if (label === undefined || label === null) {
            return null;
        }

        return (
            <Tx
                level="body-md"
                sx={{
                    weight: 'medium',
                }}
                className={styles.InputBaseLabel}
            >
                {label}
                {highlight ? '*' : null}
            </Tx>
        );
    }

    protected renderDescription() {
        const { description, error, errorMessage } = this.props;

        if ((error && errorMessage) || !description) {
            return null;
        }

        return (
            <Tx
                level="body-xs"
                sx={{
                    weight: 'light',
                }}
                className={styles.InputBaseDescription}
            >
                {description}
            </Tx>
        );
    }

    protected renderError() {
        const { errorMessage } = this.props;

        if (!this.hasError() || !errorMessage) {
            return null;
        }

        let message: string | React.ReactNode = errorMessage;
        if ((message as any).message) {
            message = (message as any).message;
        }

        return (
            <Tx
                level="body-xs"
                sx={{
                    weight: 'regular',
                }}
                className={styles.InputBaseError}
            >
                {message}
            </Tx>
        );
    }

    protected getClassName() {
        const { disabled, className } = this.props;

        return classNames(
            styles.InputBaseWrapper,
            disabled ? styles.InputBaseDisabledWrapper : null,
            this.hasError() ? styles.InputBaseWithErrorWrapper : null,
            className,
        );
    }

    protected hasError(): boolean {
        return !!this.props.error;
    }

    protected getStyle(): React.CSSProperties | undefined {
        return this.props.style;
    }

    protected getTabIndex(): number {
        return -1;
    }
}
