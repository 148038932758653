import * as React from 'react';

import { TouchAndMouseDragHandler, TouchAndMouseMove, KeyboardHandler } from '@/core';

import Color from './Color';

import styles from './ColorPicker.scss';

interface IProps {
    color?: Color;
    onChangeStart: () => void;
    onChange: (newColor?: Color) => void;
    onChangeComplete: (newColor?: Color) => void;
}

interface IState {
    color: Color;
}

export default class Ribbon extends React.PureComponent<IProps, IState> {
    private ribbon = React.createRef<HTMLDivElement>();
    private knob = React.createRef<HTMLSpanElement>();
    private dragHandler: TouchAndMouseDragHandler;
    private keyHandler = new KeyboardHandler();

    constructor(props: IProps) {
        super(props);

        this.state = {
            color: props.color || new Color(''),
        };

        this.dragHandler = new TouchAndMouseDragHandler(
            this.handleColorChange,
            () => {
                this.props.onChangeStart();
                if (this.knob.current) {
                    this.knob.current.focus();
                }
            },
            () => {
                this.props.onChangeComplete(this.state.color.clone());
            },
        );
    }

    componentDidMount(): void {
        if (!this.ribbon.current) {
            return;
        }

        this.ribbon.current.addEventListener('touchstart', this.dragHandler.handleTouchStart, { passive: false });
    }

    componentWillUnmount(): void {
        if (!this.ribbon.current) {
            return;
        }

        this.ribbon.current.removeEventListener('touchstart', this.dragHandler.handleTouchStart);
    }

    render() {
        let { color } = this.props;
        if (!color) {
            color = new Color('');
        }

        const rawColor = new Color({
            h: color ? color.hue : 0,
            s: 1,
            v: 1,
        });

        return (
            <div
                ref={this.ribbon}
                className={styles.ColorPickerRibbon}
                onClick={() => {
                    // do nothing
                }}
                onMouseDown={this.dragHandler.handleMouseDown}
            >
                <span
                    ref={this.knob}
                    className={styles.ColorPickerRibbonIndicator}
                    style={{
                        left: (color.hue / 360) * 100 + '%',
                        borderColor: rawColor.delimitFromSelfBackground().toHexString(),
                    }}
                    tabIndex={0}
                    onKeyDown={this.keyHandler.handleKey(
                        ['LEFT_ARROW', 'RIGHT_ARROW'],
                        (_1: any, key: string, meta: { shift: boolean }) => {
                            const { onChange } = this.props;
                            // tslint:disable-next-line:no-shadowed-variable
                            let { color } = this.props;
                            if (!color) {
                                color = new Color('');
                            }

                            let offsetHue = 0;

                            switch (key) {
                                case 'LEFT_ARROW':
                                    offsetHue -= 1;
                                    break;
                                case 'RIGHT_ARROW':
                                    offsetHue += 1;
                                    break;
                                default:
                                    return;
                            }

                            if (meta.shift) {
                                offsetHue *= 10;
                            }

                            if (color.hue + offsetHue < 0) {
                                offsetHue = -color.hue;
                            }

                            if (color.hue + offsetHue > 360) {
                                offsetHue = 360 - color.hue;
                            }

                            color.setHue(color.hue + offsetHue);

                            this.setState(
                                {
                                    color,
                                },
                                () => {
                                    if (onChange) {
                                        onChange(color ? color.clone() : undefined);
                                    }
                                },
                            );
                        },
                    )}
                    onKeyUp={this.keyHandler.handleKey(['LEFT_ARROW', 'RIGHT_ARROW'], () => {
                        if (this.props.onChangeComplete) {
                            this.props.onChangeComplete(this.state.color.clone());
                        }
                    })}
                />
            </div>
        );
    }

    private handleColorChange = (move: TouchAndMouseMove): void => {
        if (!this.ribbon.current) {
            return;
        }

        const { onChange } = this.props;
        let { color } = this.props;
        if (!color) {
            color = new Color('');
        }

        const rect = this.ribbon.current.getBoundingClientRect();
        const offsetX = Math.min(Math.max(0, move.x - rect.x), rect.width);

        color.setHue((offsetX / rect.width) * 360);

        this.setState(
            {
                color,
            },
            () => {
                if (onChange) {
                    onChange(color ? color.clone() : undefined);
                }
            },
        );
    };
}
