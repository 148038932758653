import { Domain } from 'api';

import { webshopNewsletterSubscriptionApi } from '@/api';
import { makeActions, makeReducer, makeReducerActions, makeSelectors } from '@/makeOverviewState';

type WebshopNewsletterSubscriptionProps = keyof Domain.WebshopNewsletterSubscription;

export const defaultSorting: Domain.Sorting<WebshopNewsletterSubscriptionProps> = {
    field: 'subscribedAt',
    direction: 'descending',
};

export const pageSize = 20;

export const selectors = makeSelectors<Domain.WebshopNewsletterSubscription, WebshopNewsletterSubscriptionProps>({
    getState: rootState => rootState.WebshopNewsletterSubscription.overview,
});

export const reducerActions = makeReducerActions<Domain.WebshopNewsletterSubscription, WebshopNewsletterSubscriptionProps>({
    reducerPrefix: '@WebshopNewsletterSubscription/overview',
});

export const actions = makeActions<Domain.WebshopNewsletterSubscription, WebshopNewsletterSubscriptionProps>({
    dataTableSaveKey: 'WebshopNewsletterSubscriptionsOverview-v5',
    loadApi: options => {
        if (options.globallySelectedWebshopId === undefined) {
            return;
        }

        return webshopNewsletterSubscriptionApi.getWebshopNewsletterSubscriptions(
            options.pagination,
            options.sorting,
            options.globallySelectedWebshopId,
            options.search,
        );
    },
    defaultSorting,
    pageSize,
    getBaseUrl: () => '/webshops/newsletter-subscriptions',
    reducerActions,
    selectors,
});

export const overviewReducer = makeReducer<
    Domain.WebshopNewsletterSubscription,
    WebshopNewsletterSubscriptionProps,
    {
        emailAddress?: string;
    }
>({
    defaultSorting,
    pageSize,
    reducerActions,
});
