import {
    Pagination,
    Sorting,
    Locale,
    SearchActionsPage,
    SearchSynonyms,
    UpdateSearchSynonymsPayload,
    RemoveSearchSynonymsPayload,
} from '@/Domain';

import Endpoint from './Endpoint';

interface Filters {
    locale?: Locale;
    salesChannelId?: string;
}

export default class SearchSynonymEndpoint extends Endpoint {
    public async GetSearchActions(
        pagination: Pagination,
        sorting: Sorting,
        search?: string,
        filters?: Filters,
    ): Promise<SearchActionsPage> {
        const cleanFilters = { ...filters };
        if (cleanFilters && cleanFilters.salesChannelId === 'all') {
            delete cleanFilters.salesChannelId;
        }

        const response = await this.client.get({
            url: '/search/search-actions/overview',
            params: {
                pagination,
                sorting,
                search,
                filters: cleanFilters,
            },
        });

        this.guardResponseSchema('#/definitions/SearchActionsPage', response);

        return response;
    }

    public async GetSearchSynonyms(searchTerm: string, locale: Locale): Promise<SearchSynonyms> {
        const response = await this.client.get({
            url: `/search/search-synonym/details/${searchTerm}/${locale}`,
        });

        this.guardResponseSchema('#/definitions/SearchSynonyms', response);

        return response;
    }

    public async UpdateSearchSynonyms(payload: UpdateSearchSynonymsPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/UpdateSearchSynonyms',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Search\\SearchSynonym\\UpdateSearchSynonym\\UpdateSearchSynonym',
                payload,
            },
        });
    }

    public async RemoveSearchSynonyms(payload: RemoveSearchSynonymsPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/RemoveSearchSynonyms',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Search\\SearchSynonym\\RemoveSearchSynonym\\RemoveSearchSynonym',
                payload,
            },
        });
    }
}
