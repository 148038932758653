import * as React from 'react';

import { preloadFont } from 'preloaders';

import RTValue from './RTValue';

export default function getCSSPropsFromRTValue(value: RTValue<any>): React.CSSProperties {
    const style: React.CSSProperties = {
        textDecoration: '',
    };

    const options = value ? value.options || {} : {};

    if (options.font) {
        preloadFont(options.font);
        style.fontFamily = options.font;
    }

    if (options.size !== undefined) {
        style.fontSize = options.size + 'px';
        style.lineHeight = options.size + 4 + 'px';
    }

    if (options.color) {
        style.color = options.color;
    }

    if (options.bold) {
        style.fontWeight = 'bold';
    }

    if (options.italic) {
        style.fontStyle = 'italic';
    }

    if (options.underline) {
        style.textDecoration += ' underline';
    }

    if (options.strikethrough) {
        style.textDecoration += ' line-through';
    }

    if (!style.textDecoration) {
        style.textDecoration = undefined;
    }

    if (options.align) {
        style.textAlign = options.align;
    }

    if (options.verticalAlign) {
        style.alignSelf = options.verticalAlign;
    }

    return style;
}
