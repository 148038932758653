import {
    Wwks2StatusDialog,
    wwks2KeepAliveResponse
} from '../../../../Domain';
import Wwks2CommClient from '../../Wwks2CommClient';
import { DIALOG_TIMEOUTS } from '../Wwks2DefaultValues';


export default class Wwks2KeepAlive {

    private Wwks2CommClient: Wwks2CommClient;
    private keepAliveTimer: ReturnType<typeof setTimeout>;
    private keepAlivePolling: number = 60000;

    public constructor(Wwks2CommClient: Wwks2CommClient) {
        this.Wwks2CommClient = Wwks2CommClient;
    }

    public sendKeepAliveRequest(dialogId: string | number | boolean = false): Promise<any> {
        return new Promise(resolve => {

            if(typeof dialogId === 'boolean'){
                dialogId = this.Wwks2CommClient.GetWwks2DialogId();
            }

            const wwks = {
                'KeepAliveRequest': {
                    '@': {
                        'Id': dialogId,
                        'Source': this.Wwks2CommClient.GetSourceId(),
                        'Destination': this.Wwks2CommClient.GetClientId(),
                    }
                }
            }
            this.Wwks2CommClient.createAndSendDialog(wwks, 'KeepAlive', dialogId).then(status => {
                resolve(status);
            });
        });
    }

    public sendKeepAliveResponse(dialogId: string | number): Promise<Wwks2StatusDialog> {
        return new Promise(resolve => {
            const wwks = {
                'KeepAliveResponse': {
                    '@': {
                        'Id': dialogId,
                        'Source': this.Wwks2CommClient.GetSourceId(),
                        'Destination': this.Wwks2CommClient.GetClientId(),
                    }
                }
            }
            this.Wwks2CommClient.createAndSendDialog(wwks, 'KeepAlive', dialogId, 'response').then(status => {
                resolve(status);
            });
        });
    }

    public getKeepAliveResponse(dialogId: string | number): Promise<any> {

        return new Promise((resolve) => {

            const statusDialog: Wwks2StatusDialog = {
                status: false,
                msg: '',
                dialogId: dialogId,
                dialogType: 'KeepAlive',
                isDialogType: 'response',
                canceled: false,
                errorType: 'none'
            }
            let processStatusDialog = this.Wwks2CommClient.GetProcessedDialogData(dialogId);
            if (typeof processStatusDialog !== 'boolean') {
                if (processStatusDialog.typeDialog === 'KeepAlive') {
                    if (processStatusDialog.status.response) {
                        const responseData = processStatusDialog.data.response as wwks2KeepAliveResponse;
                        if (responseData.clientId == this.Wwks2CommClient.GetClientId()) {
                            statusDialog.status = true;
                            statusDialog.msg = 'Keep Alive Response received and accepted';
                            resolve(statusDialog);
                        } else {
                            statusDialog.status = false;
                            statusDialog.msg = 'Keep Alive Response received and not accepted because different clientId';
                            statusDialog.errorType = 'responseDialogNotFromClient';
                            resolve(statusDialog);
                        }
                    } else if (processStatusDialog.status.cancel) {
                        statusDialog.status = false;
                        statusDialog.msg = 'check for Keep Alive Response canceled';
                        statusDialog.errorType = 'checkStatusDialogCanceled';
                        resolve(statusDialog);
                    } else {
                        setTimeout(() => {
                            resolve(this.getKeepAliveResponse(dialogId));
                        }, 500)
                    }
                } else {

                    statusDialog.status = false;
                    statusDialog.msg = 'statusDialogType is not KeepAlive';
                    statusDialog.errorType = 'wrongStatusDialogType';
                    resolve(statusDialog);
                }
            } else {

                statusDialog.status = false;
                statusDialog.msg = 'statusDialog not exist for this dialogId';
                statusDialog.errorType = 'noStatusDialog';
                resolve(statusDialog);
            }
        });
    }

    public sendAndProcessKeepAliveRequest(dialogId: string | number | boolean = false): Promise<Wwks2StatusDialog> {
        return new Promise(resolve => {
            this.sendKeepAliveRequest(dialogId)
                .then((data: Wwks2StatusDialog) => {
                    if (data.status) {
                        const timeoutTimer = setTimeout(() => {
                            let processStatusDialog = this.Wwks2CommClient.GetProcessedDialogData(data.dialogId);
                            if (typeof processStatusDialog !== 'boolean') {
                                if (processStatusDialog.typeDialog === 'KeepAlive') {
                                    processStatusDialog.status.cancel = true;
                                    this.Wwks2CommClient.SetProcessedDialogData(data.dialogId, processStatusDialog);
                                }
                            }
                        }, DIALOG_TIMEOUTS.KeepAlive);
                        this.getKeepAliveResponse(data.dialogId)
                            .then((status) => {
                                clearTimeout(timeoutTimer);
                                resolve(status)
                            });
                    } else {
                        resolve(data);
                    }
                });
        });
    }

    public handleMessage(WWKS: any) {

        if (typeof WWKS.KeepAliveRequest !== 'undefined') {
            const responseId = WWKS.KeepAliveRequest['@attributes'].Id;
            const clientId = WWKS.KeepAliveRequest['@attributes'].Source;
            const sourceId = WWKS.KeepAliveRequest['@attributes'].Destination;

            if ((this.Wwks2CommClient.checkWwks2SourceDestinationIdInRequestMessage && sourceId == this.Wwks2CommClient.GetSourceId()) || !this.Wwks2CommClient.checkWwks2SourceDestinationIdInRequestMessage) {   // check if the message is for us
                if ((this.Wwks2CommClient.checkWwks2SourceDestinationIdInRequestMessage && clientId == this.Wwks2CommClient.GetClientId()) || !this.Wwks2CommClient.checkWwks2SourceDestinationIdInRequestMessage) { // check if the message comes from our connected client
                    this.sendKeepAliveResponse(responseId).then((status) => {
                        if (status.status) {
                            // KeepAlive is send back
                            // reset our KeepAlive Timer
                            this.resetKeepAlive();
                        }
                    })
                }
            }
        }
        if (typeof WWKS.KeepAliveResponse !== 'undefined') {

            const responseId = WWKS.KeepAliveResponse['@attributes'].Id;
            const sourceId = WWKS.KeepAliveResponse['@attributes'].Source;
            const destinationId = WWKS.KeepAliveResponse['@attributes'].Destination;
            if ((this.Wwks2CommClient.checkWwks2SourceDestinationIdInRequestMessage && destinationId == this.Wwks2CommClient.GetSourceId()) || !this.Wwks2CommClient.checkWwks2SourceDestinationIdInRequestMessage) {   // check if the message is for us
                let processStatusDialog = this.Wwks2CommClient.GetProcessedDialogData(responseId);
                if (typeof processStatusDialog !== 'boolean') {
                    const responseData: wwks2KeepAliveResponse = {
                        'clientId': sourceId,
                    }
                    if (processStatusDialog.typeDialog === 'KeepAlive') {
                        processStatusDialog.status.response = true;
                        processStatusDialog.timestamps.response = Date.now();
                        processStatusDialog.data.response = responseData;
                        this.Wwks2CommClient.SetProcessedDialogData(responseId, processStatusDialog);
                    }
                }
            }
        }

    }

    public initKeepAlive() {
        this.stopKeepAlive();

        setTimeout(() => {
            this.startKeepAlive();
        }, 500);
    }

    public stopKeepAlive() {
        if (this.keepAliveTimer) {
            clearTimeout(this.keepAliveTimer);
        }
    }

    public startKeepAlive() {
        this.sendAndProcessKeepAliveRequest()
            .then(status => {
                let continueWithKeepAlive = true;
                let closeConnection = false;
                if (!status.status) {
                    if (status.errorType == 'responseDialogNotFromClient' || status.errorType == 'checkStatusDialogCanceled' || status.errorType == 'clientDoNotSupportDialog') {
                        continueWithKeepAlive = false;
                    }
                    if (status.errorType == 'responseDialogNotFromClient' || status.errorType == 'checkStatusDialogCanceled') {
                        closeConnection = true;
                    }
                }
                
                

                if (continueWithKeepAlive) {
                    this.keepAliveTimer = setTimeout(() => {
                        this.startKeepAlive();
                    }, this.keepAlivePolling);
                }
                if (closeConnection) {
                    this.stopKeepAlive();
                    this.Wwks2CommClient.closeConnection(true); // connection will be closed but it will reinitialized
                }
            })
    }

    public resetKeepAlive() {
        if (this.keepAliveTimer) {
            clearTimeout(this.keepAliveTimer);
        }
        this.keepAliveTimer = setTimeout(() => {
            this.startKeepAlive();
        }, this.keepAlivePolling);
    }



}