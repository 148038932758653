import { Domain } from 'api';

import { ThunkAction } from '@/action';
import { companyApi } from '@/api';
import { loadLocalisations } from '@/Localisation/overviewState';
import { makeActions, makeReducer, makeReducerActions, makeSelectors } from '@/makeOverviewState';
import { URLParams, URLQuery } from '@/routing';

type CompanyProps = keyof Domain.Company;

const defaultSorting: Domain.Sorting<CompanyProps> = {
    field: 'name',
    direction: 'ascending',
};

const pageSize = 20;

export const selectors = makeSelectors<Domain.Company, CompanyProps>({
    getState: rootState => rootState.company.overview,
});

export const reducerActions = makeReducerActions<Domain.Company, CompanyProps>({
    reducerPrefix: '@company/overview',
});

const baseActions = makeActions<Domain.Company, CompanyProps>({
    dataTableSaveKey: 'companiesOverview-v5',
    loadApi: options => companyApi.GetCompanies(options.pagination, options.sorting, options.search, options.filters),
    defaultSorting,
    pageSize,
    getBaseUrl: () => '/customers/companies',
    reducerActions,
    selectors,
});

export const actions = {
    ...baseActions,
    load:
        (options: { urlParams: URLParams; urlQuery: URLQuery }): ThunkAction =>
        async dispatch => {
            return await Promise.all([dispatch(baseActions.load(options)), dispatch(loadLocalisations(999))]);
        },
};

export const overviewReducer = makeReducer<Domain.Company, CompanyProps>({
    defaultSorting,
    pageSize,
    reducerActions,
});
