import { createAction, createReducer, Selector } from '@reduxjs/toolkit';

import { Domain } from 'api';
import { config } from 'config';

import { ThunkAction, withPayloadType } from '@/action';
import { translationApi } from '@/api';
import { selectLoggedInUser } from '@/Authentication';
import { COUNTRY_CONFIG } from '@/Localisation/availableCountriesState';
import { RootState } from '@/store';

export interface State {
    localisations?: Domain.LocalisationsPage;
    translations?: Domain.TranslationList;
}

const initialState: State = {
    localisations: undefined,
    translations: undefined,
};

const reducerActions = {
    setTranslations: createAction('@translation/overview/setTranslations', withPayloadType<Domain.TranslationList>()),
    setLocalisations: createAction('@localisation/overview/setLocalisations', withPayloadType<Domain.LocalisationsPage>()),
};

export const overviewReducer = createReducer(initialState, builder =>
    builder
        .addCase(reducerActions.setTranslations, (state, action) => {
            state.translations = action.payload;
        })
        .addCase(reducerActions.setLocalisations, (state, action) => {
            state.localisations = action.payload;
        }),
);

export const selectTranslations: Selector<RootState, Domain.TranslationList> = state => {
    const translations = state.translation.overview.translations;
    if (!translations) {
        throw new Error('Translations not loaded');
    }
    return translations;
};

export const selectLocalisations: Selector<RootState, Domain.LocalisationsPage> = state => {
    const localisations = state.translation.overview.localisations;
    if (!localisations) {
        throw new Error('Localisations not loaded');
    }
    return localisations;
};

export const loadTranslations =
    (locale: Domain.Locale, translationScope: Domain.TranslationScope): ThunkAction =>
    async dispatch => {
        const translations = await translationApi.getTranslationByLocale(locale, translationScope);
        dispatch(reducerActions.setTranslations(translations));
    };

export const load = (): ThunkAction => async (dispatch, getState) => {
    const state = getState();
    const loggedInUser = selectLoggedInUser(state);
    if (loggedInUser.role === 'reseller') {
        const locale = COUNTRY_CONFIG[loggedInUser.resellerCountry].locales[0];
        await dispatch(loadTranslations(locale, 'platform'));
    } else {
        await dispatch(loadTranslations(config.defaultLocale, 'platform'));
    }
};
