import * as React from 'react';

import { BaseProps } from '@/core';

import Icon, { IconSize } from './Icon';

import styles from './CircleOutlineIcon.scss';

interface IProps {
    color?: string;
    iconSize?: IconSize;
    children?: React.ReactNode;
    style?: React.CSSProperties;
}

export default class CircleOutlineIcon extends React.PureComponent<BaseProps & IProps & React.HTMLProps<HTMLDivElement>> {
    render() {
        const { color, iconSize, className, children, ...rest } = this.props;

        return (
            <div
                className={styles.CircleOutlineIcon}
                {...rest}
            >
                <Icon
                    color={color}
                    iconSize={iconSize}
                    type="action_circle"
                />
                <span className={styles.InsideCircle}>{children}</span>
            </div>
        );
    }
}
