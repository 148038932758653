import { createAction, createReducer, Selector } from '@reduxjs/toolkit';

import { Domain } from 'api';

import { withPayloadType } from '@/action';
import { RootState } from '@/store';

export interface LivePreviewState {
    enabled: boolean;

    livePreviewMessage?: Domain.DeviceLivePreviewSignalPayload;
}

const initialState: LivePreviewState = {
    enabled: false,
};

export const reducerActions = {
    setEnabled: createAction('@tvScreen/livePreview/setEnabled', withPayloadType<boolean>()),
    setLivePreviewMessage: createAction(
        '@tvScreen/livePreview/setLivePreviewMessage',
        withPayloadType<Domain.DeviceLivePreviewSignalPayload | undefined>(),
    ),
};

export const livePreviewReducer = createReducer(initialState, builder =>
    builder
        .addCase(reducerActions.setEnabled, (state, action) => {
            state.enabled = action.payload;
        })
        .addCase(reducerActions.setLivePreviewMessage, (state, action) => {
            state.livePreviewMessage = action.payload;
        }),
);

export const selectEnabled: Selector<RootState, boolean> = state => state.tvScreen.livePreview.enabled;
export const selectLivePreviewMessage: Selector<RootState, Domain.DeviceLivePreviewSignalPayload | undefined> = state =>
    state.tvScreen.livePreview.livePreviewMessage;
