import { Pagination, Sorting, Country, Reseller, ResellersPage, CreateResellerPayload, UpdateResellerPayload } from '@/Domain';

import Endpoint from './Endpoint';

export default class ResellerEndpoint extends Endpoint {
    public async GetResellers(
        pagination: Pagination,
        sorting: Sorting,
        search?: string,
        filters?: {
            country?: Country;
        },
    ): Promise<ResellersPage> {
        const resellers = await this.client.get({
            url: '/reseller/overview',
            params: {
                pagination,
                sorting,
                search,
                filters,
            },
        });

        this.guardResponseSchema('#/definitions/ResellersPage', resellers);

        return resellers;
    }

    public async GetResellerDetails(resellerId: Reseller['resellerId']): Promise<Reseller> {
        const reseller = await this.client.get({
            url: `/reseller/${resellerId}/details`,
        });

        this.guardResponseSchema('#/definitions/Reseller', reseller);

        return reseller;
    }

    public async CreateReseller(payload: CreateResellerPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/CreateReseller',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Reseller\\CreateReseller\\CreateReseller',
                payload,
            },
        });
    }

    public async UpdateReseller(payload: UpdateResellerPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/UpdateReseller',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Reseller\\UpdateReseller\\UpdateReseller',
                payload,
            },
        });
    }
}
