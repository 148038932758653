import * as React from 'react';

import { Domain } from 'api';
import { RTView, RTValue, RTEditor, Icon, HTMLEditorView, HTMLEditor } from 'ui-components';
import { HTMLEditorValue, PharmacyOnDuty } from 'utils';

import { store, Actions } from '../state';
import { Frame, hasEyedropCapableFrameMedia } from '../types';
import EmergencyAlertFrame from './EmergencyAlertFrame';
import FrameEyedropperArea from './FrameEyedropperArea';
import OpeningHoursFrame from './OpeningHoursFrame';
import PharmacistOnDutyFrame from './PharmacistOnDutyFrame';
import ProductFrame from './ProductFrame';

import styles from './styles/FrameBody.scss';

export default function FrameBody(props: {
    gettext: (text: string) => string;
    frame: Frame;
    proxyImageURL: (url: string) => string;
    getMediaItemURL: (mediaItemId: string) => string;
    getVideoDataURL?: (videoURL: string) => string | undefined;
    getVideoMediaItemPreviewURL: (mediaItemId: string) => string;
    autoPlayVideos: boolean;
    disableVideoPosters: boolean;
    loopVideos: boolean;
    renderVideo: (videoProps: React.VideoHTMLAttributes<any>) => React.ReactElement;
    getPharmaciesOnDuty: (numPharmacists: number, locale: string) => PharmacyOnDuty[];
    getEmergencyAlerts: (locale: string) => Domain.BeAlert[];
    getOpeningHours: () => Domain.OpeningHours | null;
    onMouseDown?: () => void;
    onMouseUp?: () => void;
    onMouseMove?: (x: number, y: number, w: number, h: number) => void;
    onMouseOut?: () => void;
    locale: string;
}) {
    const {
        gettext,
        frame,
        proxyImageURL,
        getMediaItemURL,
        getVideoDataURL,
        getVideoMediaItemPreviewURL,
        getEmergencyAlerts,
        autoPlayVideos,
        disableVideoPosters,
        loopVideos,
        renderVideo,
        getPharmaciesOnDuty,
        getOpeningHours,
        locale,
    } = props;
    const { state, dispatch } = React.useContext(store);

    const frameIsEdited = !!(state.editedFrameId && state.editedFrameId === frame.frameId);

    const style: React.CSSProperties = {};
    let backgroundImage: string | undefined;
    let backgroundObjectFit: React.CSSProperties['objectFit'] | undefined;

    if (
        (((frame.type === 'text' || frame.type === 'richText') && frame.styleBackground) || frame.type === 'background') &&
        frame.backgroundType === 'color'
    ) {
        style.backgroundColor = frame.backgroundColor;
    }

    if (
        ((frame.type === 'text' || frame.type === 'richText') && frame.styleBackground && frame.backgroundType === 'image') ||
        (frame.type === 'media' && frame.mediaType === 'image') ||
        (frame.type === 'background' && frame.backgroundType === 'image')
    ) {
        const mediaItemId =
            frame.type === 'text' || frame.type === 'richText' || frame.type === 'background'
                ? frame.backgroundMediaItemId
                : frame.mediaItemId;
        const imageCrop =
            frame.type === 'text' || frame.type === 'richText' || frame.type === 'background' ? frame.backgroundImageCrop : frame.imageCrop;

        backgroundImage = mediaItemId ? getMediaItemURL(mediaItemId) : undefined;

        backgroundObjectFit = 'contain';
        if (imageCrop === 'stretch-fit') {
            backgroundObjectFit = 'fill';
        }
        if (imageCrop === 'proportional-outside') {
            backgroundObjectFit = 'cover';
        }
    }

    let video: React.ReactNode;
    if (
        ((frame.type === 'text' || frame.type === 'richText') && frame.styleBackground && frame.backgroundType === 'video') ||
        (frame.type === 'media' && frame.mediaType === 'video') ||
        (frame.type === 'background' && frame.backgroundType === 'video')
    ) {
        const videoCrop =
            frame.type === 'text' || frame.type === 'richText' || frame.type === 'background' ? frame.backgroundImageCrop : frame.imageCrop;

        if (videoCrop === 'stretch-fit') {
            style.objectFit = 'fill';
        }
        if (videoCrop === 'proportional-outside') {
            style.objectFit = 'cover';
        }

        const videoURL = getMediaItemURL(
            (frame.type === 'text' || frame.type === 'richText' || frame.type === 'background'
                ? frame.backgroundMediaItemId
                : frame.mediaItemId) || '',
        );
        let src = videoURL;
        if (getVideoDataURL) {
            src = getVideoDataURL(videoURL) || '';
        }

        video = renderVideo({
            width: '100%',
            height: '100%',
            style,
            autoPlay: autoPlayVideos || false,
            loop: loopVideos,
            src,
            poster: !disableVideoPosters
                ? getVideoMediaItemPreviewURL(
                      (frame.type === 'text' || frame.type === 'richText' || frame.type === 'background'
                          ? frame.backgroundMediaItemId
                          : frame.mediaItemId) || '',
                  )
                : undefined,
        });
    }

    return (
        <>
            {video}

            {frame.type === 'text' && !frameIsEdited && !frame.text ? (
                <div
                    className={styles.EmptyFrame}
                    style={style}
                >
                    <Icon type="action_text" />
                </div>
            ) : null}

            {frame.type === 'text' && frame.text && !frameIsEdited ? (
                <RTView
                    style={style}
                    value={new RTValue(frame.text, frame.textStyle)}
                />
            ) : null}

            {frame.type === 'text' && frameIsEdited ? (
                <RTEditor
                    style={style}
                    value={new RTValue(frame.text || '', frame.textStyle)}
                    onChangeStart={() => {
                        dispatch({
                            type: Actions.CanvasActions.HISTORY_SAVE,
                        });
                    }}
                    onChange={newValue => {
                        dispatch({
                            type: Actions.SET_FRAME_TEXT,
                            frameId: frame.frameId,
                            text: newValue.text,
                            textStyle: newValue.options || {
                                size: 16,
                            },
                        });
                    }}
                />
            ) : null}

            {frame.type === 'richText' && !frameIsEdited && !frame.html ? (
                <div
                    className={styles.EmptyFrame}
                    style={style}
                >
                    <Icon type="action_text" />
                </div>
            ) : null}

            {frame.type === 'richText' && frame.html && !frameIsEdited ? (
                <HTMLEditorView
                    style={style}
                    value={frame.html}
                />
            ) : null}

            {frame.type === 'richText' && frameIsEdited ? (
                <HTMLEditor
                    __dangerouslyUseInternalCache={true}
                    style={style}
                    gettext={gettext}
                    value={HTMLEditorValue.createFromString(frame.html || '')}
                    onChangeStart={() => {
                        dispatch({
                            type: Actions.CanvasActions.HISTORY_SAVE,
                        });
                    }}
                    onChange={newValue => {
                        dispatch({
                            type: Actions.SET_FRAME_HTML,
                            frameId: frame.frameId,
                            html: HTMLEditorValue.toString(newValue),
                        });
                    }}
                    hideBuiltInToolbar={true}
                />
            ) : null}

            {frame.type === 'media' &&
            (frame.mediaType === 'image' || frame.mediaType === 'video') &&
            !frameIsEdited &&
            !frame.mediaItemId ? (
                <div className={styles.EmptyFrame}>
                    <Icon type="action_photo" />
                </div>
            ) : null}

            {frame.type === 'background' || (frame.type === 'media' && frame.mediaType === 'image' && frame.mediaItemId) ? (
                <div
                    className={styles.EmptyFrame}
                    style={style}
                />
            ) : null}

            {frame.type === 'pharmacistOnDuty' ? (
                <PharmacistOnDutyFrame
                    gettext={gettext}
                    frame={frame}
                    getPharmaciesOnDuty={getPharmaciesOnDuty}
                    style={style}
                    locale={locale}
                />
            ) : null}
            {frame.type === 'emergencyAlert' ? (
                <EmergencyAlertFrame
                    frame={frame}
                    getEmergencyAlerts={getEmergencyAlerts}
                    style={style}
                    locale={locale}
                    gettext={gettext}
                />
            ) : null}

            {frame.type === 'openingHours' ? (
                <OpeningHoursFrame
                    gettext={gettext}
                    frame={frame}
                    getOpeningHours={getOpeningHours}
                    style={style}
                />
            ) : null}

            {backgroundImage ? (
                <img
                    className={styles.FrameImage}
                    alt=""
                    src={backgroundImage}
                    style={{
                        objectFit: backgroundObjectFit,
                    }}
                />
            ) : null}

            {frame.type === 'shelf' ? (
                <div className={styles.ShelfFrame + ' style' + frame.shelfStyle}>
                    <span className={styles.GFXTopLeft} />
                    <span className={styles.GFXTop} />
                    <span className={styles.GFXTopRight} />
                    <span className={styles.GFXLeft} />
                    <span className={styles.GFXRight} />
                    <span className={styles.GFXBottomLeft} />
                    <span className={styles.GFXBottom} />
                    <span className={styles.GFXBottomRight} />
                </div>
            ) : null}

            {frame.type === 'product' ? (
                <ProductFrame
                    frame={frame}
                    onMouseDown={props.onMouseDown}
                    onMouseUp={props.onMouseUp}
                    onMouseMove={props.onMouseMove}
                    onMouseOut={props.onMouseOut}
                    locale={locale}
                    proxyImageURL={proxyImageURL}
                />
            ) : null}

            {state.eyedropperEnabled && hasEyedropCapableFrameMedia(frame) && frame.type !== 'product' ? (
                <FrameEyedropperArea
                    onMouseDown={props.onMouseDown}
                    onMouseUp={props.onMouseUp}
                    onMouseMove={props.onMouseMove}
                    onMouseOut={props.onMouseOut}
                    style={{
                        left: 0,
                        right: 0,
                        top: 0,
                        bottom: 0,
                    }}
                />
            ) : null}
        </>
    );
}
