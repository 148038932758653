function buildChains(rootType: string) {
    return {
        Black: rootType + '-Black',
        White: rootType + '-White',
        Error: rootType + '-Error',
        ErrorLight: rootType + '-ErrorLight',
        ErrorLighter: rootType + '-ErrorLighter',
        Success: rootType + '-Success',
        Warn: rootType + '-Warn',

        Primary: {
            Green: rootType + '-Primary-Green',
            GreenLight: rootType + '-Primary-GreenLight',
            Yellow: rootType + '-Primary-Yellow',
            YellowLight: rootType + '-Primary-YellowLight',
            Blue: rootType + '-Primary-Blue',
            BlueLight: rootType + '-Primary-BlueLight',
            Violet: rootType + '-Primary-Violet',
            VioletLight: rootType + '-Primary-VioletLight',
        },
        Grey: {
            Dark1: rootType + '-Grey-Dark1',
            Dark2: rootType + '-Grey-Dark2',
            Dark3: rootType + '-Grey-Dark3',
            Light1: rootType + '-Grey-Light1',
            Light2: rootType + '-Grey-Light2',
            Light3: rootType + '-Grey-Light3',
            Light4: rootType + '-Grey-Light4',
        },
    };
}

export function colorTransform(color: string | undefined, to: 'Text' | 'BG' | 'Border'): string | undefined {
    if (!color) {
        return color;
    }

    if (to === 'Text') {
        return color.replace('BG-', 'Text-');
    } else if (to === 'BG') {
        return color.replace('Text-', 'BG-');
    } else if (to === 'Border') {
        return color.replace('Text-', 'Border-');
    }

    return color;
}

export default {
    ...buildChains('Text'),
    BG: buildChains('BG'),
    Border: buildChains('Border'),
};
