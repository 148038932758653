import * as React from 'react';

import classNames from 'classnames';

import { BaseProps } from '@/core';

import Container from './Container';

import styles from './Grid.scss';

export type GridCols = 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

interface IProps {
    gutter?: 14 | 16 | 19 | 24 | 30;
    cols?: GridCols;
}

export default class Grid extends React.PureComponent<Omit<React.HTMLProps<HTMLDivElement>, 'ref'> & BaseProps & IProps> {
    static defaultProps = {
        cols: 12,
    };

    render() {
        const { gutter, cols, children, className, ...rest } = this.props;

        return (
            <Container
                {...rest}
                className={classNames(styles.Grid, gutter ? 'gutter-' + gutter : null, cols ? 'cols-' + cols : null, className)}
            >
                {children}
            </Container>
        );
    }
}
