import { Pagination, Sorting, Webhook, WebhooksPage, CreateWebhookPayload, UpdateWebhookPayload, RemoveWebhookPayload } from '@/Domain';

import Endpoint from './Endpoint';

export default class WebhookEndpoint extends Endpoint {
    public async GetWebhooks(pagination: Pagination, sorting: Sorting): Promise<WebhooksPage> {
        const response = await this.client.get({
            url: '/webhook/overview/platform',
            params: {
                pagination,
                sorting,
            },
        });

        this.guardResponseSchema('#/definitions/WebhooksPage', response);

        return response;
    }

    public async GetWebhookDetails(webhookId: string): Promise<Webhook> {
        const response = await this.client.get({
            url: `/webhook/${webhookId}/details`,
        });

        this.guardResponseSchema('#/definitions/Webhook', response);

        return response;
    }

    public async CreateWebhook(payload: CreateWebhookPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/CreateWebhook',
            body: {
                commandName: 'App\\Domain\\WriteModel\\PublicApi\\Webhook\\UpdateWebhook\\UpdateWebhook',
                payload,
            },
        });
    }

    public async UpdateWebhook(payload: UpdateWebhookPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/UpdateWebhook',
            body: {
                commandName: 'App\\Domain\\WriteModel\\PublicApi\\Webhook\\UpdateWebhook\\UpdateWebhook',
                payload,
            },
        });
    }

    public async RemoveWebhook(payload: RemoveWebhookPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/RemoveWebhook',
            body: {
                commandName: 'App\\Domain\\WriteModel\\PublicApi\\Webhook\\RemoveWebhook\\RemoveWebhook',
                payload,
            },
        });
    }
}
