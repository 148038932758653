import { Wwks2ProcessStatus } from '../../../Domain';


export const DIALOG_TIMEOUTS = {
    Hello: 60000,
    KeepAlive: 60000,
    Output: 300000,
    StockLocationInfo: 60000,
    StockInfo: 120000,
    ShoppingCart: 120000,
    ShoppingCartUpdate: 120000,
    ArticlePrice: 120000,
    ArticleInfo: 120000,
    Status: 60000
};

export const WWKS2_PROCESSSTATUS : Wwks2ProcessStatus = {
    dialogId: 0,
    clientId: 0,
    typeDialog: '',
    clearStatus: false,
    isDialogType: 'request',
    status: {
        request: false,
        response: false,
        message: false,
        cancel: false,
    },
    data: {
        request: undefined,
        response: undefined,
        message: undefined,
    },
    timestamps: {
        request: 0,
        response: 0,
        message: 0,
    },
    createdTimestamp: 0
    
};

export const WWKS2_TYPE: string = 'VendingMachine';
export const WWKS2_MANUFACTURER: string = 'MediTech';
export const WWKS2_PRODUCTINFO: string = 'MTMATIC';
export const WWKS2_VERSIONINFO: string = '1.0.1';