import ApiClient from './Api/ApiClient';

type Services = {
    apiClient: ApiClient;
};

type Constants = {
    fingerprint: string;
    apiBaseUrl: string;
    apiToken: string | null;
    deviceSignalingIsEnabled: boolean;
    deviceSignalingToken: string | null;
    deviceSignalingServerHost: string;
    locale: string | null;
    lochtingDeviceBackendBaseUrl: string;
    apiValidationThrowsException: boolean;
    branchTimezone: string | null;
};

// We force these to be valid even though implementations should be required
//    because invalid calls to `setService`, `getService`, `setConstant` and `getConstant` will not compile
//    and the throws will never actually happen at runtime

const services: Services = {} as Services;
const constants: Constants = {} as Constants;

export function setService<Key extends keyof Services, Value extends Services[Key]>(serviceName: Key, service: Value): void {
    services[serviceName] = service;
}

export function getService<Key extends keyof Services>(serviceName: Key) {
    if (services.hasOwnProperty(serviceName)) {
        return services[serviceName];
    }

    throw new Error(`Service ${serviceName} was not initialized`);
}

export function setConstant<Key extends keyof Constants, Value extends Constants[Key]>(constantName: Key, value: Value): void {
    constants[constantName] = value;
}

export function getConstant<Key extends keyof Constants>(constantName: Key) {
    if (constants.hasOwnProperty(constantName)) {
        return constants[constantName];
    }

    throw new Error(`Constant ${constantName} was not initialized`);
}

export function maybeGetConstant<Key extends keyof Constants>(constantName: Key) {
    if (constants.hasOwnProperty(constantName)) {
        return constants[constantName];
    }

    return null;
}
