import { Domain } from 'api';
import { preloadImage } from 'preloaders';

import { ThunkAction } from '@/action';
import { productWallApi } from '@/api';
import { selectCurrentLocale } from '@/I18n';
import { makeActions, makeReducer, makeReducerActions, makeSelectors } from '@/makeDetailsState';
import * as Media from '@/Media';
import { URLParams } from '@/routing';

import { preloadProductWall } from './preloader';

export const selectors = makeSelectors<Domain.ProductWall>({
    getState: rootState => rootState.productWall.preview,
});

export const reducerActions = makeReducerActions<Domain.ProductWall>({
    reducerPrefix: '@productWall/preview',
});

const baseActions = makeActions<Domain.ProductWall>({
    loadApi: options => productWallApi.GetProductWallDetails(options.urlParams.productWallId),
    reducerActions,
    selectors,
});

export const actions = {
    ...baseActions,
    load:
        (options: { urlParams: URLParams }): ThunkAction =>
        async (dispatch, getState) => {
            await dispatch(baseActions.load(options));
            const state = getState();
            const locale = selectCurrentLocale(state);

            const productWall = selectors.selectDetails(state);

            await Promise.all(
                preloadProductWall(
                    false,
                    productWall.content as any,
                    productWall.products,
                    productWall.preview || '',
                    locale,
                    Media.preloader.preloadMediaItem,
                    Media.preloader.preloadVideoMediaItemPreview,
                    preloadImage,
                ),
            );
        },
};

export const previewReducer = makeReducer<Domain.ProductWall>({
    reducerActions,
});
