import { Domain } from 'api';

import { customProductApi } from '@/api';
import { makeActions, makeReducer, makeReducerActions, makeSelectors } from '@/makeDetailsState';

export const selectors = makeSelectors<Domain.CustomProductDetails>({
    getState: rootState => rootState.customProduct.details,
});

export const reducerActions = makeReducerActions<Domain.CustomProductDetails>({
    reducerPrefix: '@customProduct/details',
});

export const actions = makeActions<Domain.CustomProductDetails>({
    loadApi: options => customProductApi.GetCustomProductDetails(options.urlParams.customProductId, options.locale),
    reducerActions,
    selectors,
});

export const detailsReducer = makeReducer<Domain.CustomProductDetails>({
    reducerActions,
});
