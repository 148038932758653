import React from 'react';

import type { ContentBlock, ContentState } from 'draft-js';

type Props = {
    children: React.ReactNode;
    entityKey: string;
    contentState: ContentState;
};

type EntityRangeCallback = (start: number, end: number) => void;

function Link(props: Props) {
    const { url, target } = props.contentState.getEntity(props.entityKey).getData();
    return (
        <a
            href={url}
            target={target !== '_self' ? target : undefined}
        >
            {props.children}
        </a>
    );
}

function findLinkEntities(contentBlock: ContentBlock, callback: EntityRangeCallback, contentState?: ContentState) {
    contentBlock.findEntityRanges(character => {
        const entityKey = character.getEntity();
        if (entityKey != null) {
            const entity = contentState ? contentState.getEntity(entityKey) : null;
            return entity != null && entity.getType() === 'LINK';
        }
        return false;
    }, callback);
}

export default {
    strategy: findLinkEntities,
    component: Link,
};
