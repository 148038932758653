import * as React from 'react';

import { ISearchProvider } from 'utils';

import { Button } from '@/button';
import { Card } from '@/card';
import { Checkbox } from '@/checkbox';
import color from '@/color';
import { Icon } from '@/icon';
import { Input } from '@/input';
import { Container, Row, Col } from '@/layout';
import { Pill } from '@/pill';
import { Tx } from '@/typography';
import useDebounce from '@/useDebounce';

import styles from './SelectTree.scss';

interface Searchable {
    label: string;
    value: string;
}

export interface TreeItem {
    id: string;
    children: TreeItem[];
}

interface BaseProps {
    name?: string;
    forwardRef?: React.Ref<HTMLSelectElement>;
    items: TreeItem[];
    label?: string;
    renderItemLabel: (itemId: string, inHeader?: boolean) => React.ReactNode;
    onChange?: (newValue: string[]) => void;
    allowParentSelection?: boolean;
    disableTopLevelParentSelection?: boolean;
    applyButtonRenderer?: React.ReactNode;
    disabled?: boolean;
    error?: boolean;
    errorMessage?: string | React.ReactNode;
    disableRemoveTag?: boolean;
    highlight?: boolean;
    collapsibleBranches?: boolean;
}

type WithoutSearch = {
    searchPlaceholder?: never;
    searchProvider?: never;
};

type WithSearch = {
    searchPlaceholder?: string;
    searchProvider: ISearchProvider<Searchable>;
};

export type ControlledSelectTreeProps = BaseProps & (WithSearch | WithoutSearch);

export type ControlledSelectTreePropsWithValue = ControlledSelectTreeProps & {
    value: string[];
};

function isWithSearch(props: ControlledSelectTreeProps): props is BaseProps & WithSearch {
    return (props as WithSearch).hasOwnProperty('searchProvider') && !!(props as WithSearch).searchProvider;
}

export default function ControlledSelectTree(props: ControlledSelectTreePropsWithValue) {
    const [query, setQuery] = React.useState('');
    const [filteredIds, setFilteredIds] = React.useState<string[]>([]);
    const [expandedBranchIds, setExpandedBranchIds] = React.useState<string[]>([]);
    const debouncedQuery = useDebounce(query, 250);

    React.useEffect(() => {
        if (isWithSearch(props)) {
            const search = async () => {
                const results = await props.searchProvider.search(debouncedQuery);
                setFilteredIds(results.map(result => result.value));
            };

            search();
        }
    }, [debouncedQuery]);

    const handleCheckboxClick = (checked: boolean, value: string) => {
        let newSelection = [...props.value];
        if (checked) {
            newSelection = [...newSelection, value];
        } else {
            newSelection = newSelection.filter(selectedValue => selectedValue !== value);
        }

        if (props.onChange) {
            props.onChange(newSelection);
        }
    };

    const handleBranchExpandToggle = (branchId: string) => {
        if (expandedBranchIds.includes(branchId)) {
            setExpandedBranchIds([...expandedBranchIds.filter(item => item !== branchId)]);
        } else {
            setExpandedBranchIds([...expandedBranchIds, branchId]);
        }
    };

    const renderSelectedItemNames = () => {
        return (
            <div>
                {props.value.map(itemId => {
                    return (
                        <Pill
                            color="tertiary"
                            size="sm"
                            radius="sm"
                            key={itemId}
                            className="mr-4 mb-4"
                            onDelete={() => {
                                handleCheckboxClick(false, itemId);
                            }}
                            disabled={props.disabled || props.disableRemoveTag}
                            data-test-id={`remove-${itemId}`}
                        >
                            {props.renderItemLabel(itemId, true)}
                        </Pill>
                    );
                })}
            </div>
        );
    };

    const renderHiddenInput = () => {
        const { name, value, forwardRef } = props;

        const hiddenOptions = value.map(oneValue => {
            return {
                value: oneValue,
                label: oneValue,
            };
        });

        return (
            <select
                name={name}
                ref={ref => {
                    if (forwardRef && typeof forwardRef === 'function') {
                        forwardRef(ref);
                    }
                }}
                value={value}
                multiple={true}
                onChange={() => {
                    // do nothing
                }}
            >
                <option value={undefined} />
                {hiddenOptions.map(option => (
                    <option
                        key={option.value}
                        value={option.value}
                    >
                        {option.value}
                    </option>
                ))}
            </select>
        );
    };

    const renderError = () => {
        const { error, errorMessage } = props;

        if (!error || !errorMessage) {
            return null;
        }

        return <span className="InputBaseError">{errorMessage}</span>;
    };

    return (
        <>
            <Card
                hSpacing="none"
                vSpacing="none"
                className={`${styles.SelectTreeInput} ${props.error ? styles.SelectTreeInputWithError : ''}`}
            >
                <div className={styles.SelectTreeHeader}>
                    {props.label ? (
                        <Tx
                            level="body-lg"
                            sx={{
                                color: '--content-primary',
                                weight: 'strong',
                            }}
                            className="mb-8"
                            as="label"
                        >
                            {props.label}
                            {props.highlight ? '*' : null}
                        </Tx>
                    ) : null}
                    {renderSelectedItemNames()}

                    {isWithSearch(props) ? (
                        <Input
                            placeholder={props.searchPlaceholder || 'Search'}
                            className="mt-12"
                            iconRight={
                                !query ? (
                                    <Icon
                                        type="action_search"
                                        iconSize="m"
                                        className={color.Grey.Dark2}
                                    />
                                ) : (
                                    <Button
                                        variant="plain"
                                        className="px-0"
                                        onClick={() => setQuery('')}
                                        startIcon={<Icon type="action_remove" />}
                                    />
                                )
                            }
                            value={query}
                            onChange={setQuery}
                            onKeyDown={e => {
                                if (e.key === 'Enter') {
                                    e.preventDefault();
                                }
                            }}
                            disabled={props.disabled}
                            {...(props.name
                                ? {
                                      'data-test-id': `${props.name}-tree-search`,
                                  }
                                : {})}
                        />
                    ) : null}
                </div>

                <ItemsTree
                    items={props.items}
                    filteredIds={debouncedQuery && filteredIds.length > 0 ? filteredIds : null}
                    selectedIds={props.value}
                    handleCheckboxClick={handleCheckboxClick}
                    renderItemLabel={props.renderItemLabel}
                    allowParentSelection={props.allowParentSelection}
                    disableTopLevelParentSelection={props.disableTopLevelParentSelection}
                    collapsibleBranches={props.collapsibleBranches && !query}
                    expandedBranchIds={expandedBranchIds}
                    onBranchExpandToggle={handleBranchExpandToggle}
                    disabled={props.disabled}
                />

                {renderHiddenInput()}
                {props.applyButtonRenderer}
            </Card>
            {renderError()}
        </>
    );
}

const ItemsTree = React.memo(
    (props: {
        items: TreeItem[];
        selectedIds: string[];
        filteredIds: null | string[];
        handleCheckboxClick: (checked: boolean, value: string) => void;
        renderItemLabel: (itemId: string) => React.ReactNode;
        allowParentSelection?: boolean;
        disableTopLevelParentSelection?: boolean;
        collapsibleBranches?: boolean;
        expandedBranchIds: string[];
        onBranchExpandToggle: (branchId: string) => void;
        disabled?: boolean;
    }) => {
        const renderTreeChildren = (items: TreeItem[], parentId: string, depth = 0) => {
            if (items.length === 0) {
                return null;
            }

            return items.map(item => {
                const branchId = parentId + '-' + item.id;
                const children = renderTreeChildren(item.children, branchId, depth + 1);

                if (
                    (item.children.length > 0 &&
                        (!children || children.filter(Boolean).length === 0) &&
                        (!props.filteredIds || props.filteredIds.indexOf(item.id) === -1)) ||
                    (props.filteredIds && item.children.length === 0 && props.filteredIds.indexOf(item.id) === -1)
                ) {
                    return null;
                }

                const checked = props.selectedIds.indexOf(item.id) > -1;
                const expanded = props.expandedBranchIds.includes(branchId);
                const selectable =
                    (item.children.length === 0 || props.allowParentSelection) && (depth > 0 || !props.disableTopLevelParentSelection);

                return (
                    <React.Fragment key={item.id}>
                        <Row
                            onClick={event => {
                                event.preventDefault();
                                if (selectable) {
                                    props.handleCheckboxClick(!checked, item.id);
                                }
                            }}
                        >
                            <Col
                                fullWidth={true}
                                className="p-8"
                            >
                                <div className={styles.SelectTreeBranch + ' depth-' + depth}>
                                    <div
                                        onClick={event => {
                                            event.stopPropagation();
                                            props.onBranchExpandToggle(branchId);
                                        }}
                                        className={`${styles.SelectTreeBranchCollapseToggle} ${props.collapsibleBranches && item.children.length > 0 ? '' : styles.SelectTreeBranchCollapseToggleHidden}`}
                                    >
                                        {expanded ? <Icon type="action_minus" /> : <Icon type="action_add" />}
                                    </div>
                                    {selectable ? (
                                        <Checkbox
                                            checked={checked}
                                            onChange={() => props.handleCheckboxClick(!checked, item.id)}
                                            disabled={props.disabled}
                                            size="l"
                                            data-test-id={item.id}
                                        >
                                            {props.renderItemLabel(item.id)}
                                        </Checkbox>
                                    ) : (
                                        <Tx
                                            as="span"
                                            level="body-md"
                                            sx={{
                                                color: '--content-primary',
                                                weight: 'regular',
                                            }}
                                        >
                                            {props.renderItemLabel(item.id)}
                                        </Tx>
                                    )}
                                </div>
                            </Col>
                        </Row>

                        {!props.collapsibleBranches || expanded ? children : null}
                    </React.Fragment>
                );
            });
        };

        return (
            <Container
                className={styles.SelectTree}
                gutter={14}
            >
                {renderTreeChildren(props.items, 'root')}
            </Container>
        );
    },
    (a, b) => {
        if (
            a.allowParentSelection !== b.allowParentSelection ||
            a.collapsibleBranches !== b.collapsibleBranches ||
            a.selectedIds.join('$') !== b.selectedIds.join('$') ||
            (a.filteredIds || []).join('$') !== (b.filteredIds || []).join('$') ||
            a.expandedBranchIds.sort().join('$') !== b.expandedBranchIds.sort().join('$') ||
            JSON.stringify(a.items) !== JSON.stringify(b.items)
        ) {
            return false;
        }
        return true;
    },
);
