import {
    Pagination,
    Ownership,
    CustomPagesPage,
    CreateCustomPagePayload,
    UpdateCustomPagePayload,
    ShareCustomPagePayload,
    DuplicateCustomPagePayload,
    CustomPage,
    Sorting,
} from '@/Domain';

import Endpoint from './Endpoint';

export default class CustomPageEndpoint extends Endpoint {
    public async GetCustomPages(
        ownership: Ownership,
        pagination: Pagination,
        sorting: Sorting,
        search?: string,
        filters?: {
            companyId?: string;
            branchId?: string;
            locale?: string;
            includeShared?: string;
            screenResolution?: string;
            ownedByCompany?: string;
        },
    ): Promise<CustomPagesPage> {
        let url = '/custom-page/overview';
        if (ownership.type !== 'all') {
            url = `/custom-page/${ownership.type}/${ownership.ownerId}/overview`;
        }

        const response = await this.client.get({
            url,
            params: {
                pagination,
                sorting,
                filters,
                search,
            },
        });

        this.guardResponseSchema('#/definitions/CustomPagesPage', response);

        return response;
    }

    public async GetCustomPageDetails(customPageId: string): Promise<CustomPage> {
        const response = await this.client.get({
            url: '/custom-page/' + customPageId + '/details',
        });

        this.guardResponseSchema('#/definitions/CustomPage', response);

        return response;
    }

    public async CreateCustomPage(payload: CreateCustomPagePayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/CreateCustomPage',
            body: {
                commandName: 'App\\Domain\\WriteModel\\CustomPage\\CreateCustomPage\\CreateCustomPage',
                payload,
            },
        });
    }

    public async UpdateCustomPage(payload: UpdateCustomPagePayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/UpdateCustomPage',
            body: {
                commandName: 'App\\Domain\\WriteModel\\CustomPage\\UpdateCustomPage\\UpdateCustomPage',
                payload,
            },
        });
    }

    public async RemoveCustomPage(customPageId: string): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/RemoveCustomPage',
            body: {
                commandName: 'App\\Domain\\WriteModel\\CustomPage\\RemoveCustomPage\\RemoveCustomPage',
                payload: {
                    customPageId,
                },
            },
        });
    }

    public async ShareCustomPage(payload: ShareCustomPagePayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/ShareCustomPage',
            body: {
                commandName: 'App\\Domain\\WriteModel\\CustomPage\\ShareCustomPage\\ShareCustomPage',
                payload,
            },
        });
    }

    public async DuplicateCustomPage(payload: DuplicateCustomPagePayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/DuplicateCustomPage',
            body: {
                commandName: 'App\\Domain\\WriteModel\\CustomPage\\DuplicateSharedCustomPage\\DuplicateSharedCustomPage',
                payload,
            },
        });
    }
}
