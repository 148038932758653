import { Pagination, Sorting, Locale, AvailableProductDetails, AvailableProductsPage, ActiveIngredientsPage } from '@/Domain';

import Endpoint from './Endpoint';

export default class AvailableProductEndpoint extends Endpoint {
    public async GetAvailableProducts(
        pagination: Pagination,
        sorting: Sorting,
        locale: Locale,
        search?: string,
        filters?: {
            productIds?: string;
            brandIds?: string;
            productCodes?: string;
            productImageShotType?: string;
            categoryIds?: string;
            organisationIds?: string;
            stockSourceIds?: string;
        },
    ): Promise<AvailableProductsPage> {
        const response = await this.client.get({
            url: '/available-product/overview',
            params: {
                pagination,
                sorting,
                locale,
                search: search || '',
                productCodes: filters ? filters.productCodes : undefined,
                productIds: filters ? filters.productIds : undefined,
                filters: {
                    ...filters,
                    categoryIds: filters && filters.categoryIds ? filters.categoryIds.split(',') : undefined,
                    organisationIds: filters && filters.organisationIds ? filters.organisationIds.split(',') : undefined,
                    brandIds: filters && filters.brandIds ? filters.brandIds.split(',') : undefined,
                    stockSourceIds: filters && filters.stockSourceIds ? filters.stockSourceIds.split(',') : undefined,
                },
            },
        });

        this.guardResponseSchema('#/definitions/AvailableProductsPage', response);

        return response;
    }

    public async GetAvailableProductDetails(availableProductId: string, locale?: Locale): Promise<AvailableProductDetails> {
        const response: AvailableProductDetails = await this.client.get({
            url: `/available-product/${availableProductId}/details`,
            params: {
                locale,
            },
        });

        if ((response.localizedDescriptions as any) instanceof Array) {
            response.localizedDescriptions = {};
        }

        this.guardResponseSchema('#/definitions/AvailableProductDetails', response);

        return response;
    }

    public async GetProductActiveIngredientsOverview(
        pagination: Pagination,
        sorting: Sorting,
        locale: Locale,
        filters?: {
            activeIngredientIds: string[];
        },
        search?: string,
    ): Promise<ActiveIngredientsPage> {
        const response = await this.client.get({
            url: '/available-product/active-ingredient-overview',
            params: {
                pagination,
                sorting,
                locale,
                filters,
                search,
            },
        });

        this.guardResponseSchema('#/definitions/ActiveIngredientsPage', response);

        return response;
    }
}
