import * as React from 'react';

import classNames from 'classnames';

import { ClickOutsideListener } from 'ui-components';

import { Actions, store } from '../state';
import { CanvasFrame } from '../types';
import FrameActions from './FrameActions';
import FrameResizer from './FrameResizer';

import styles from './styles/Frame.scss';

export default function Frame(props: {
    className?: string;
    scaleFactor?: number;
    frame: CanvasFrame;
    frameRenderer: (frame: CanvasFrame, frameIsFocused: boolean, frameIsEdited: boolean) => React.ReactNode;
    resizeMiddleIsDisabledWhenEditingFrame: (frame: CanvasFrame) => boolean;
    onClickOutside: (event: MouseEvent) => void;
    disableEditing?: boolean;
}) {
    const { frame, onClickOutside, className, disableEditing } = props;
    const { state, dispatch } = React.useContext(store);

    const frameIsFocused = !!(state.resizedFrameId && state.resizedFrameId === frame.frameId);
    const frameIsEdited = !!(state.editedFrameId && state.editedFrameId === frame.frameId);

    return (
        <ClickOutsideListener
            onClickOutside={event => {
                if (frameIsEdited || frameIsFocused) {
                    onClickOutside(event);
                }
            }}
            mouseEvent="onMouseUp"
        >
            <div
                className={classNames(styles.Frame, frameIsFocused || frameIsEdited ? styles.FocusedFrame : undefined, className)}
                style={{
                    top: frame.y + 'px',
                    left: frame.x + 'px',
                    height: frame.height + 'px',
                    width: frame.width + 'px',
                    zIndex: (frame.depth || 0) + 999,
                }}
                onClick={event => {
                    event.nativeEvent.stopImmediatePropagation();

                    dispatch({
                        type: Actions.SET_EDITED_FRAME,
                        editedFrameId: props.frame.frameId,
                    });
                }}
            >
                {!disableEditing ? <FrameActions frame={frame} /> : null}

                {!disableEditing ? (
                    <FrameResizer
                        frame={frame}
                        noMiddle={frameIsEdited && props.resizeMiddleIsDisabledWhenEditingFrame(frame)}
                    />
                ) : null}

                {props.frameRenderer(frame, frameIsFocused, frameIsEdited)}
            </div>
        </ClickOutsideListener>
    );
}
