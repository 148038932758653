import { Domain } from 'api';

import { branchApi } from '@/api';
import { makeActions, makeReducer, makeReducerActions, makeSelectors } from '@/makeOverviewState';

export type BranchProps = keyof Domain.Branch;

export const defaultSorting: Domain.Sorting<BranchProps> = {
    field: 'branchName',
    direction: 'ascending',
};

export const pageSize = 20;

export const selectors = makeSelectors<Domain.Branch, BranchProps>({
    getState: rootState => rootState.branch.overview,
});

export const reducerActions = makeReducerActions<Domain.Branch, BranchProps>({
    reducerPrefix: '@branch/overview',
});

export const actions = makeActions<
    Domain.Branch,
    BranchProps,
    {
        companyId?: Domain.Branch['companyId'];
        branchName?: Domain.Branch['branchName'];
        address?: Domain.Branch['address'];
        zipCode?: Domain.Branch['zipCode'];
        city?: Domain.Branch['city'];
    }
>({
    dataTableSaveKey: 'branchesOverview-v5',
    loadApi: options =>
        branchApi.GetBranches(
            options.ownership,
            options.pagination,
            options.sorting,
            options.search,
            options.filters ? options.filters.companyId : undefined,
            options.filters,
        ),
    defaultSorting,
    pageSize,
    getBaseUrl: ownership => {
        return ownership.type === 'company' ? '/company/branches' : '/customers/branches';
    },
    reducerActions,
    selectors,
});

export const overviewReducer = makeReducer<Domain.Branch, BranchProps>({
    defaultSorting,
    pageSize,
    reducerActions,
});
