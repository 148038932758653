import { createAction, createReducer, Selector } from '@reduxjs/toolkit';

import { Domain } from 'api';

import { ThunkAction, withPayloadType } from '@/action';
import { webshopOrderShipmentApi } from '@/api';
import { RootState } from '@/store';

export interface State {
    externalStatuses: Domain.OrderShipmentExternalStatusesList;
}

const initialState: State = {
    externalStatuses: [],
};

const reducerActions = {
    setExternalStatuses: createAction(
        '@webshopOrderShipment/shipmentExternalStatuses/setExternalStatuses',
        withPayloadType<State['externalStatuses']>(),
    ),
};

export const shipmentExternalStatusesReducer = createReducer(initialState, builder =>
    builder.addCase(reducerActions.setExternalStatuses, (state, action) => {
        state.externalStatuses = action.payload;
    }),
);

export const selectExternalStatuses: Selector<RootState, State['externalStatuses']> = state => {
    return state.webshopOrderShipment.shipmentExternalStatuses.externalStatuses;
};

export const load =
    (webshopId: string): ThunkAction =>
    async dispatch => {
        const externalStatuses = await webshopOrderShipmentApi.GetExternalStatuses(webshopId);
        dispatch(reducerActions.setExternalStatuses(externalStatuses));
    };
