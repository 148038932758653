import { createAction, Selector, createReducer } from '@reduxjs/toolkit';

import { Domain } from 'api';

import { ThunkAction, withPayloadType } from '@/action';
import { cmsApi } from '@/api';
import { selectCurrentLocale } from '@/I18n/selectors';
import { RootState } from '@/store';

export interface State {
    type?: Domain.CmsItemType;
    cmsItem?: Domain.CmsItem;
}

const initialState: State = {};

const reducerActions = {
    setType: createAction('@cmsItem/page/setType', withPayloadType<Domain.CmsItemType | undefined>()),
    setCmsItem: createAction('@cmsItem/page/setCmsItem', withPayloadType<Domain.CmsItem | undefined>()),
};

export const pageReducer = createReducer(initialState, builder =>
    builder
        .addCase(reducerActions.setType, (state, action) => {
            state.type = action.payload;
        })
        .addCase(reducerActions.setCmsItem, (state, action) => {
            state.cmsItem = action.payload;
        }),
);

export const maybeSelectCmsItemType: Selector<RootState, Domain.CmsItemType | undefined> = state => state.cmsItem.page.type;
export const maybeSelectCmsItem: Selector<RootState, Domain.CmsItem | undefined> = state => state.cmsItem.page.cmsItem;

export const loadCMSItem =
    (type: Domain.CmsItemType): ThunkAction<Promise<void>> =>
    async (dispatch, getState) => {
        const state = getState();
        const locale = selectCurrentLocale(state);

        try {
            const cmsItem = await cmsApi.GetCmsItemDetailsByTypeAndLocale(type, locale);

            await dispatch(reducerActions.setCmsItem(cmsItem));
        } catch (e) {
            console.error(e);
            await dispatch(reducerActions.setCmsItem(undefined));
        }
    };

export const load = (type: Domain.CmsItemType) => (): ThunkAction => async dispatch => {
    await dispatch(reducerActions.setType(type));
    await dispatch(loadCMSItem(type));
};

export const reload = (): ThunkAction => async (dispatch, getState) => {
    const state = getState();
    const type = maybeSelectCmsItemType(state);
    if (type) {
        await dispatch(loadCMSItem(type));
    } else {
        console.error('Cannot reload CMS Item, no type set');
        await dispatch(reducerActions.setCmsItem(undefined));
    }
};
