import { createAction, createReducer } from '@reduxjs/toolkit';

import { Domain } from 'api';
import { config } from 'config';

import { withPayloadType } from '@/action';

import { getCurrentLocale } from './browserStorage';
import { Translations, State } from './types';

export const setLocale = createAction('@i18n/setLocale', withPayloadType<Domain.Locale>());
export const setLoadedTranslationsLocale = createAction('@i18n/setLoadedTranslationsLocale', withPayloadType<Domain.Locale>());
export const setTranslations = createAction('@i18n/setTranslations', withPayloadType<Translations>());

const initialState: State = {
    locale: getCurrentLocale() || config.defaultLocale,
    loadedTranslationsLocale: undefined,
    translations: undefined,
};

export const i18nReducer = createReducer(initialState, builder =>
    builder
        .addCase(setLocale, (state, action) => {
            state.locale = action.payload;
        })
        .addCase(setLoadedTranslationsLocale, (state, action) => {
            state.loadedTranslationsLocale = action.payload;
        })
        .addCase(setTranslations, (state, action) => {
            state.translations = action.payload;
        }),
);
