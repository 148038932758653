import tinycolor from 'tinycolor2';

export default class Color {
    private color: tinycolor.Instance;
    private redValue: number;
    private greenValue: number;
    private blueValue: number;
    private hueValue: number;
    private saturationValue: number;
    private brightnessValue: number;
    private lightnessValue: number;

    constructor(
        input: any,
        redValue?: number,
        greenValue?: number,
        blueValue?: number,
        hueValue?: number,
        saturationValue?: number,
        brightnessValue?: number,
        lightnessValue?: number,
    ) {
        this.color = tinycolor(input);

        this.initRgb();
        this.initHsb();

        if (redValue !== undefined) {
            this.redValue = redValue;
        }

        if (greenValue !== undefined) {
            this.greenValue = greenValue;
        }

        if (blueValue !== undefined) {
            this.blueValue = blueValue;
        }

        if (hueValue !== undefined) {
            this.hueValue = hueValue;
        }

        if (saturationValue !== undefined) {
            this.saturationValue = saturationValue;
        }

        if (brightnessValue !== undefined) {
            this.brightnessValue = brightnessValue;
        }

        if (lightnessValue !== undefined) {
            this.lightnessValue = lightnessValue;
        }
    }

    static isValidHex(hex: string) {
        return tinycolor(hex).isValid();
    }

    initRgb = () => {
        const { r, g, b } = this.color.toRgb();

        this.redValue = r;
        this.greenValue = g;
        this.blueValue = b;
    };

    initHsb = () => {
        const { h, s, v } = this.color.toHsv();

        this.hueValue = h;
        this.saturationValue = s;
        this.brightnessValue = v;
    };

    toHex = () => {
        return this.color.toHex();
    };

    toHexString = () => {
        return this.color.toHexString();
    };

    toRgbString = () => {
        return this.color.toRgbString();
    };

    toJson = () => {
        return {
            red: this.redValue,
            green: this.greenValue,
            blue: this.blueValue,
        };
    };

    clone = () => {
        return new Color(
            this.toHexString(),
            this.redValue,
            this.greenValue,
            this.blueValue,
            this.hueValue,
            this.saturationValue,
            this.brightnessValue,
            this.lightnessValue,
        );
    };

    isDark = () => {
        return this.color.isDark();
    };

    isValid = () => {
        return this.color.isValid();
    };

    get hex() {
        return this.color.toHex();
    }

    setHue(value: number) {
        this.color = tinycolor({
            h: value,
            s: this.saturation,
            v: this.brightness,
        });

        this.initRgb();
        this.hueValue = value;

        return this;
    }

    get hue() {
        return this.hueValue;
    }

    setSaturation(value: number) {
        this.color = tinycolor({
            h: this.hue,
            s: value,
            v: this.brightness,
        });

        this.initRgb();
        this.saturationValue = value;

        return this;
    }

    get saturation() {
        return this.saturationValue;
    }

    delimitFromWhiteBackground() {
        this.color = this.color.darken((this.isDark() ? this.saturation : 1 - this.saturation) * 30);

        return this;
    }

    darken(value: number) {
        this.color.darken(value);
        this.initRgb();
        this.initHsb();
        return this;
    }

    brighten(value: number) {
        this.color.brighten(value);
        this.initRgb();
        this.initHsb();
        return this;
    }

    delimitFromSelfBackground() {
        if (this.isDark()) {
            this.color.brighten(70);
        } else {
            this.color.darken(70);
        }

        return this;
    }

    setLightness(value: number) {
        this.color = tinycolor({
            h: this.hue,
            s: this.saturation,
            l: value,
        });

        this.initRgb();
        this.lightnessValue = value;

        return this;
    }

    get lightness() {
        return this.lightnessValue;
    }

    setBrightness(value: number) {
        this.color = tinycolor({
            h: this.hue,
            s: this.saturation,
            v: value,
        });

        this.initRgb();
        this.brightnessValue = value;

        return this;
    }

    get brightness() {
        return this.brightnessValue;
    }

    setRed(value: number) {
        const rgb = this.color.toRgb();
        this.color = tinycolor({
            ...rgb,
            r: value,
        });

        this.initHsb();
        this.redValue = value;

        return this;
    }

    get red() {
        return this.redValue;
    }

    setGreen(value: number) {
        const rgb = this.color.toRgb();
        this.color = tinycolor({
            ...rgb,
            g: value,
        });

        this.initHsb();
        this.greenValue = value;

        return this;
    }

    get green() {
        return this.greenValue;
    }

    setBlue(value: number) {
        const rgb = this.color.toRgb();
        this.color = tinycolor({
            ...rgb,
            b: value,
        });

        this.initHsb();
        this.blueValue = value;

        return this;
    }

    get blue() {
        return this.blueValue;
    }

    setAlpha(value: number) {
        this.color.setAlpha(value / 100);

        return this;
    }

    get alpha() {
        return this.color.getAlpha() * 100;
    }
}
