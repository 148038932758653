import * as React from 'react';

import classNames from 'classnames';

import { Icon } from '@/icon';
import { AnchoredPortal } from '@/portal';

import BaseProps from './BaseProps';
import ClickOutsideListener from './ClickOutsideListener';
import KeyboardHandler from './KeyboardHandler';

import styles from './Dropdown.scss';

interface IProps {
    headerRenderer: (isOpen: boolean, anchorRef: React.RefObject<HTMLElement>) => React.ReactNode;
    headerArrow?: boolean;
    body: React.ReactNode | ((close: () => void) => React.ReactNode);
    bodyClassName?: string;
    portalClassName?: string;
    doNotCloseOnClickOutside?: boolean;
    doNotCloseOnHeaderClick?: boolean;
    disabled?: boolean;
    onMouseDown?: (event: React.MouseEvent) => void;
    disableFocus?: boolean;
    onOpen?: (fromClick?: boolean) => void;
    onClose?: (fromClick?: boolean) => void;
    onClick?: (e: React.MouseEvent) => void;
    anchorRight?: boolean;
    anchorBottom?: boolean;
    anchorTop?: boolean;
    inheritDropdownWidthFromHeader?: boolean;
}

interface IDropdownState {
    opened: boolean;
}

export default class Dropdown extends React.PureComponent<BaseProps & IProps, IDropdownState> {
    private anchorRef = React.createRef<HTMLElement>();
    private header = React.createRef<HTMLDivElement>();
    private dropdown = React.createRef<HTMLDivElement>();
    private dropdownBody = React.createRef<HTMLDivElement>();
    private keyHandler = new KeyboardHandler();

    constructor(props: IProps) {
        super(props);

        this.state = {
            opened: false,
        };
    }

    componentWillUnmount() {
        this.handleClosed(false);
    }

    render() {
        const { disableFocus } = this.props;
        const { opened } = this.state;

        return (
            <ClickOutsideListener
                onClickOutside={this.handleClickOutside}
                mouseEvent="onMouseDown"
            >
                <div
                    ref={this.dropdown}
                    className={classNames(
                        styles.Dropdown,
                        opened ? styles.DropdownOpened : undefined,
                        disableFocus ? styles.DropdownDisabledFocus : undefined,
                        this.props.className,
                    )}
                    style={this.props.style}
                    onMouseDown={this.props.onMouseDown}
                    onKeyDown={this.keyHandler.handleKey(['ENTER', 'ESCAPE'], this.stopKeyPropagation)}
                    onClick={this.props.onClick}
                >
                    {this.renderHeader()}
                    {this.renderDropdown()}
                </div>
            </ClickOutsideListener>
        );
    }

    public open = (fromClick = true): void => {
        this.setState(
            {
                opened: true,
            },
            () => {
                if (this.state.opened) {
                    this.handleOpened(fromClick);
                }
            },
        );
    };

    public close = async (fromClick = true): Promise<void> => {
        return new Promise(resolve => {
            this.setState(
                {
                    opened: false,
                },
                () => {
                    if (!this.state.opened) {
                        this.handleClosed(fromClick);
                    }

                    resolve();
                },
            );
        });
    };

    private renderHeader() {
        const { opened } = this.state;
        const { headerRenderer, headerArrow, disabled, disableFocus } = this.props;

        return (
            <div
                ref={this.header}
                className={classNames(
                    styles.DropdownHeader,
                    headerArrow ? styles.DropdownHeaderWithArrow : undefined,
                    disabled ? styles.DropdownHeaderDisabled : undefined,
                )}
                onClick={this.handleHeaderClick}
                onKeyDown={this.keyHandler.handleKey(['ENTER', 'ESCAPE'], this.handleHeaderKeyDown)}
                tabIndex={disabled || disableFocus ? -1 : 0}
            >
                {headerRenderer(opened, this.anchorRef)}
            </div>
        );
    }

    private renderDropdown() {
        const { body, bodyClassName, portalClassName, anchorRight, anchorBottom, anchorTop, inheritDropdownWidthFromHeader } = this.props;
        const { opened } = this.state;

        if (!opened) {
            return null;
        }

        return (
            <AnchoredPortal
                anchorElement={this.anchorRef.current || this.header.current}
                inheritMinWidthFromAnchor={inheritDropdownWidthFromHeader === undefined || inheritDropdownWidthFromHeader === true}
                anchorVPosition={anchorBottom ? 'bottom' : anchorTop ? 'top' : 'auto'}
                anchorHPosition={anchorRight ? 'left-align' : undefined}
                className={`${portalClassName ? 'Portal-' + portalClassName : ''} full-screen-sm`}
            >
                <div
                    ref={this.dropdownBody}
                    className={styles.DropdownBody + ' ' + (bodyClassName || '')}
                    onKeyDown={this.keyHandler.handleKey('ESCAPE', () => {
                        this.close(false);
                    })}
                    onMouseDown={this.props.onMouseDown}
                >
                    <span
                        className={styles.DropdownBg}
                        onClick={e => {
                            e.preventDefault();
                            this.close(false);
                        }}
                        onMouseDown={e => {
                            e.preventDefault();
                            e.stopPropagation();
                        }}
                    />

                    <a
                        href=""
                        className={styles.DropdownCloseAction}
                        onClick={e => {
                            e.preventDefault();
                            this.close(false);
                        }}
                    >
                        <Icon type="action_remove" />
                    </a>

                    {typeof body === 'function' ? body(this.close) : body}
                </div>
            </AnchoredPortal>
        );
    }

    private handleHeaderClick = (event: React.MouseEvent): void => {
        event.preventDefault();

        this.toggleDropdown();
    };

    private stopKeyPropagation = (event: React.KeyboardEvent): void => {
        event.stopPropagation();
        event.nativeEvent.stopImmediatePropagation();
    };

    private handleHeaderKeyDown = (_1: any, key: string): void => {
        if (key === 'ESCAPE') {
            this.close(false);
        } else {
            this.toggleDropdown(false);
        }
    };

    private handleClickOutside = (): void => {
        const { opened } = this.state;
        if (!opened) {
            return;
        }
        const { doNotCloseOnClickOutside } = this.props;

        if (doNotCloseOnClickOutside) {
            return;
        }

        this.close(true);
    };

    private handleDocumentKeydown = (event: KeyboardEvent): void => {
        const { doNotCloseOnClickOutside } = this.props;

        if (doNotCloseOnClickOutside) {
            return;
        }

        if (event.key !== undefined && ['ENTER'].indexOf(this.keyHandler.getEventKey(event as KeyboardEvent) as string) === -1) {
            return;
        }

        this.close(event.key === undefined);
    };

    private toggleDropdown(fromClick = true) {
        const { opened } = this.state;
        const { disabled, doNotCloseOnHeaderClick } = this.props;

        if ((opened && doNotCloseOnHeaderClick && fromClick) || disabled) {
            return;
        }

        this.setState(
            {
                opened: !opened,
            },
            () => {
                if (this.state.opened) {
                    this.handleOpened(fromClick);
                } else {
                    this.handleClosed(fromClick);
                }
            },
        );
    }

    private handleOpened(fromClick = true) {
        document.addEventListener('keydown', this.handleDocumentKeydown);

        if (this.props.onOpen) {
            this.props.onOpen(fromClick);
        }
    }

    private handleClosed(fromClick = true) {
        document.removeEventListener('keydown', this.handleDocumentKeydown);

        if (this.props.onClose) {
            this.props.onClose(fromClick);
        }

        if (fromClick && this.header.current && !this.props.disableFocus) {
            this.header.current.focus();
        }
    }
}
