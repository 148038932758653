import { combineReducers } from '@reduxjs/toolkit';

import { detailsReducer } from './detailsState';
import { overviewReducer } from './overviewState';
import { updateReducer } from './updateState';

export const branchReducer = combineReducers({
    details: detailsReducer,
    overview: overviewReducer,
    update: updateReducer,
});
