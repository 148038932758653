import { StartShipmentPayload, UpdateShipmentPayload, UpdateShipmentItemPayload, SealShipmentPayload } from '@/Domain';

import Endpoint from './Endpoint';

export default class OrderShipmentEndpoint extends Endpoint {
    public async StartShipment(payload: StartShipmentPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/StartShipment',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Shipment\\UpdateShipment\\UpdateShipment',
                payload,
            },
        });
    }

    public async UpdateShipment(payload: UpdateShipmentPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/UpdateShipment',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Shipment\\UpdateShipment\\UpdateShipment',
                payload,
            },
        });
    }

    public async UpdateShipmentItem(payload: UpdateShipmentItemPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/UpdateShipmentItem',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Shipment\\UpdateShipmentItem\\UpdateShipmentItem',
                payload,
            },
        });
    }
    public async RemoveShipmentItem(payload: { shipmentItemId: string; shipmentId: string }): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/RemoveShipmentItem',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Shipment\\RemoveShipmentItem\\RemoveShipmentItem',
                payload,
            },
        });
    }
    public async SealShipment(payload: SealShipmentPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/SealShipment',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Shipment\\SealShipment\\SealShipment',
                payload,
            },
        });
    }
}
