import * as React from 'react';

import classNames from 'classnames';

import { ControlledCheckboxBase, ControlledCheckboxProps } from '@/checkbox/ControlledCheckbox';
import { RadioGroupContext } from '@/radioGroup/RadioGroupContext';
import { Tx } from '@/typography';

const radioOff = require('./assets/radio-button-off.svg');
const radioOn = require('./assets/radio-button-on.svg');

import styles from './RadioButton.scss';
import checkboxStyles from '@/checkbox/Checkbox.scss';

interface RadioButtonProps {
    defaultChecked?: boolean;
    icon?: React.ReactNode;
}

class RadioButton extends ControlledCheckboxBase<RadioButtonProps> {
    static contextType = RadioGroupContext;
    declare context: React.ContextType<typeof RadioGroupContext>;

    protected renderInput(): React.ReactNode {
        const { name, children, defaultChecked, icon } = this.props;
        return (
            <>
                <input
                    ref={this.inputRef}
                    type="radio"
                    name={name}
                    defaultChecked={defaultChecked}
                    checked={this.isChecked()}
                    onChange={this.handleChange}
                    tabIndex={-1}
                    disabled={this.isDisabled()}
                />

                {icon ? (
                    <span className={styles.gfx}>{icon}</span>
                ) : (
                    <span
                        className={styles.gfx}
                        dangerouslySetInnerHTML={{ __html: this.isChecked() ? radioOn : radioOff }}
                    />
                )}

                {children ? (
                    <Tx
                        className={checkboxStyles.CheckboxLabel}
                        level="body-sm"
                        as="span"
                        sx={{
                            disableSelection: true,
                        }}
                    >
                        {children}
                    </Tx>
                ) : null}
            </>
        );
    }

    protected handleChange = (): void => {
        const checkboxList = this.context;
        const { onChange, value } = this.props;

        if (checkboxList.name && checkboxList.onChange) {
            checkboxList.onChange(true, value);
        }

        if (onChange) {
            onChange(true);
        }
    };

    protected isChecked(): boolean | undefined {
        const checkboxList = this.context;

        if (checkboxList.name) {
            const { value } = this.props;
            return !!(value && checkboxList.value === value);
        }

        return undefined;
    }

    protected getClassName(): string {
        return classNames(super.getClassName(), styles.RadioButton);
    }
}

export default React.forwardRef<HTMLInputElement, ControlledCheckboxProps & RadioButtonProps>((props, ref) => {
    return (
        <RadioButton
            forwardRef={ref}
            checked={false}
            {...props}
        />
    );
});
