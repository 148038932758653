import { BaseOrderDetails } from '@/Domain';

import Endpoint from './Endpoint';

export default class ExternalSourceOrderEndpoint extends Endpoint {
    public async GetOrderDetails(orderId: string): Promise<BaseOrderDetails> {
        const response = await this.client.get({
            url: `/order/${orderId}/details`,
        });

        this.guardResponseSchema('#/definitions/BaseOrderDetails', response);
        return response;
    }
}
