import { ProductFrame } from '../types';
import { getProductImages } from './index';

export default function getSelectedImage(
    productImages: ReturnType<typeof getProductImages>,
    productMediaType: ProductFrame['productMediaType'],
    imageId?: ProductFrame['imageId'],
) {
    if (productMediaType === 'frontal') {
        const image = productImages.allFrontals.find(image => {
            if (imageId) {
                return image.id === imageId;
            } else {
                return image.url === productImages.frontalImage;
            }
        });

        if (image) {
            return image;
        }
    } else if (productMediaType === 'packshot') {
        const image = productImages.allPackshots.find(image => {
            if (imageId) {
                return image.id === imageId;
            } else {
                return image.url === productImages.productImage;
            }
        });

        if (image) {
            return image;
        }
    }

    return {
        id: 'any',
        url: productImages.frontalImage || productImages.productImage,
    };
}
