import defaultConfig from './defaultConfg';
import localConfig from './localConfig';
import { Config } from './types';

const config: Config = {
    ...defaultConfig,
    ...localConfig,
    featureFlags: {
        ...defaultConfig.featureFlags,
        ...localConfig.featureFlags,
    },
};

export default config;
