import * as React from 'react';

import { Actions, store } from '../state';
import { ShelfWithMeta, ShelfStyleParameters } from '../types';
import ShelfGFX from './ShelfGFX';

import styles from './styles/SplitShelfAction.scss';

export default function SplitShelfAction(props: { shelf: ShelfWithMeta; lastShelf?: boolean }) {
    const { state, dispatch } = React.useContext(store);
    const LayoutParams = ShelfStyleParameters[state.canvas.style];
    const { shelf } = props;
    const itemsHeight = Math.max(0, shelf.meta ? shelf.meta.maxBottom : 0);
    const minHeight = shelf.shelf.height - LayoutParams.minShelfHeightBeforeSplit;

    if (!state.draggedProduct && minHeight > 0 && minHeight > itemsHeight) {
        return (
            <div className={styles.SplitShelfAction}>
                <a
                    className={styles.BodyArea}
                    style={
                        props.lastShelf && shelf.shelf.type === 'emptyShelf'
                            ? { top: shelf.shelf.height - LayoutParams.shelfHeight }
                            : undefined
                    }
                    href=""
                    onClick={event => {
                        event.preventDefault();

                        let shelfHeight = LayoutParams.minShelfHeight;

                        if (props.lastShelf && shelf.shelf.type === 'emptyShelf') {
                            shelfHeight = shelf.shelf.height;
                        }

                        dispatch({
                            type: Actions.CanvasActions.HISTORY_SAVE,
                        });

                        dispatch({
                            type: Actions.SPLIT_SHELF,
                            shelfId: shelf.shelf.id,
                            height: shelfHeight,
                        });
                    }}
                >
                    <ShelfGFX />
                </a>
            </div>
        );
    }

    return null;
}
