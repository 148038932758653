import * as React from 'react';

import classNames from 'classnames';

import { BaseProps } from '@/core';

import { GridCols } from './Grid';

export type ColSpan = 1 | GridCols;

type NativeProps = Omit<React.HTMLProps<HTMLDivElement>, 'ref' | 'css'>;
type IProps = NativeProps & {
    span?: ColSpan;
    spanSm?: 0 | ColSpan;
    offset?: ColSpan;
    order?: ColSpan;
    vAlign?: 'start' | 'center' | 'end';
    hAlign?: 'start' | 'center' | 'end';
    fullWidth?: boolean;
    canShrink?: boolean;
    forwardRef?: React.Ref<HTMLDivElement>;
};

class Col extends React.PureComponent<React.HTMLProps<HTMLDivElement> & BaseProps & IProps> {
    static defaultProps = {
        span: undefined,
        spanSm: undefined,
        offset: undefined,
        order: undefined,
        fullWidth: false,
    };

    render() {
        const { className, children, span, spanSm, offset, order, vAlign, hAlign, canShrink, fullWidth, forwardRef, ...rest } = this.props;

        return (
            <div
                {...rest}
                ref={forwardRef}
                className={classNames(
                    'Col',
                    span !== undefined ? 'col-' + span : null,
                    spanSm !== undefined ? 'col-sm-' + spanSm : null,
                    offset !== undefined ? 'offset-' + offset : null,
                    order !== undefined ? 'order-' + order : null,
                    vAlign ? 'v-align-' + vAlign : null,
                    hAlign ? 'h-align-' + hAlign : null,
                    fullWidth ? 'full-width' : null,
                    canShrink ? 'can-shrink' : null,
                    className,
                )}
            >
                {children}
            </div>
        );
    }
}

export default React.forwardRef<HTMLDivElement, IProps>((props, ref) => {
    return (
        <Col
            forwardRef={ref}
            {...props}
        />
    );
});
