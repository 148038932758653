import * as React from 'react';
import { useSelector } from 'react-redux';

import { Domain } from 'api';
import { TVScreen, useThunkDispatch, useGettext } from 'data-store';

import findHiddenSlideContents from '@/utils/findHiddenSlideContents';
import findSlideContents from '@/utils/findSlideContents';
import getDeviceViewStyle from '@/utils/getDeviceViewStyle';

import PreloaderProgress, { PreloaderProgressWrap } from './PreloaderProgress';
import SlideshowPreloaderService from './SlideshowPreloaderService';

import styles from './SlideshowPreloader.scss';

const preloaderService = new SlideshowPreloaderService();

export default function SlideshowPreloader(props: {
    children: React.ReactNode | React.ReactNode[];
    usePreviewImageForContents?: boolean;
    deviceDetails?: {
        isConnectedToLockers: boolean;
        isConnectedToRobot: boolean;
    };
}) {
    const dispatch = useThunkDispatch();
    const device = useSelector(TVScreen.selectors.selectDevice);
    const configurationIsInvalid = useSelector(TVScreen.selectors.selectConfigurationIsInvalid);
    const schedule = useSelector(TVScreen.selectors.selectSchedule);
    const timeline = useSelector(TVScreen.selectors.selectTimeline);
    const slides = useSelector(TVScreen.selectors.selectSlides);
    const hiddenSlides = useSelector(TVScreen.selectors.selectHiddenSlides);
    const allProducts = useSelector(TVScreen.selectors.selectAllSlideshowProducts);
    const deviceDeliverPickupsIntegrations = useSelector(TVScreen.selectors.selectDeviceDeliverPickupsIntegrations);

    const [timelineIdToShow, setTimelineIdToShow] = React.useState<false | Domain.Timeline['timelineId']>(false);
    const [allSlidesAreLoaded, setAllSlidesAreLoaded] = React.useState(false);
    const { gettext } = useGettext();

    const getAllSlidesAreLoaded = () => {
        if (timeline) {
            for (const slide of timeline.content) {
                if (!slide.isHidden && !findSlideContents(slide, slides)) {
                    return false;
                }
            }
        }
        if (device && device.hiddenSlides) {
            for (const hiddenSlide of device.hiddenSlides) {
                if (!findHiddenSlideContents(hiddenSlide, hiddenSlides)) {
                    return false;
                }
            }
        }
        return true;
    };

    const handleDeviceLoad = async () => {
        if (device && timeline) {
            if (timelineIdToShow !== timeline.timelineId) {
                console.log('HANDLE NEW TIMELINE');
                setTimelineIdToShow(timeline.timelineId);
                dispatch(TVScreen.slideshowState.handleNewSlideshow());
            }

            preloaderService.preload(timeline, device, props.usePreviewImageForContents || false, dispatch);

            const newAllSlidesLoaded = getAllSlidesAreLoaded();
            if (allSlidesAreLoaded !== newAllSlidesLoaded) {
                setAllSlidesAreLoaded(newAllSlidesLoaded);
                dispatch(TVScreen.slideshowState.reducerActions.setPreloaded(newAllSlidesLoaded));
                if (newAllSlidesLoaded) {
                    const newProducts = preloaderService.getAllProducts();
                    if (JSON.stringify(newProducts) !== JSON.stringify(allProducts)) {
                        dispatch(TVScreen.slideshowState.reducerActions.setAllProducts(newProducts));
                    }
                }
                console.log('SLIDESHOW PRELOAD STATUS', newAllSlidesLoaded ? 'preloaded' : 'not yet');
            }
        }
    };

    React.useEffect(() => {
        handleDeviceLoad();
    }, [device, timeline, timelineIdToShow]);

    const getErrorMessage = () => {
        if (device?.type === 'vending-machine' && props.deviceDetails?.isConnectedToLockers && !deviceDeliverPickupsIntegrations.lockers) {
            return gettext('Collect Device is not configured correctly: no locker + deliverPickups integration');
        }
        if (device?.type === 'vending-machine' && props.deviceDetails?.isConnectedToRobot && !deviceDeliverPickupsIntegrations.robot) {
            return gettext('Matic + Robot Device is not configured correctly: no robot + deliverPickups integration');
        }
        if (device?.type === 'vending-machine' && !device?.configuration.mtMaticPayConfiguredIntegrationId) {
            return gettext('This device has no pay integration.');
        }
        if (configurationIsInvalid) {
            return gettext('This device is not configured correctly. Please check the configuration page.');
        }
        if (!schedule || schedule.length === 0) {
            return gettext('Please configure a schedule for this device.');
        }
        if (device && !device.isActive) {
            return gettext('This device is inactive.');
        }
        if (!allSlidesAreLoaded) {
            return null;
        }
        return null;
    };

    const errorMessage = getErrorMessage();

    if (errorMessage) {
        return (
            <PreloaderProgressWrap style={getDeviceViewStyle(device)}>
                <div className={styles.SlideshowPreloaderMessage}>{errorMessage}</div>
            </PreloaderProgressWrap>
        );
    }

    return (
        <PreloaderProgressWrap style={getDeviceViewStyle(device)}>
            {!allSlidesAreLoaded ? <PreloaderProgress /> : props.children}
        </PreloaderProgressWrap>
    );
}
