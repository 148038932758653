import { createAction, createReducer, Selector, Draft, ActionReducerMapBuilder } from '@reduxjs/toolkit';

import { Domain } from 'api';

import { ThunkAction, withPayloadType } from './action';
import { selectLoggedInUserOwnership, maybeSelectLoggedInUser, AuthenticatedUser } from './Authentication';
import { selectCurrentLocale } from './I18n';
import { URLParams } from './routing';
import { RootState } from './store';
import { globalSelectionState } from './Webshop';

export interface DetailsState<T> {
    details?: T;
    urlParams: URLParams;
}

interface ReducerActionsStateOptions {
    reducerPrefix: string;
}

interface ActionsStateOptions<T> {
    loadApi: (options: {
        ownership: Domain.Ownership;
        user?: AuthenticatedUser;
        locale: Domain.Locale;
        globallySelectedWebshopId: Domain.Webshop['webshopId'] | undefined;
        urlParams: URLParams;
    }) => Promise<T> | undefined;
}

interface ReducerStateOptions<T, X = void> {
    extraCases?: (builder: ActionReducerMapBuilder<DetailsState<T> & X>) => void;
}

interface SelectorsStateOptions<T> {
    getState: (rootState: RootState) => DetailsState<T>;
}

export function makeReducerActions<T>(stateOptions: ReducerActionsStateOptions) {
    return {
        setDetails: createAction(stateOptions.reducerPrefix + '/setDetails', withPayloadType<T>()),
        setUrlParams: createAction(stateOptions.reducerPrefix + '/setUrlParams', withPayloadType<URLParams>()),
    };
}

export function makeActions<T>(
    stateOptions: ActionsStateOptions<T> & {
        reducerActions: ReturnType<typeof makeReducerActions>;
        selectors: ReturnType<typeof makeSelectors>;
    },
) {
    const loadDetails = (): ThunkAction<Promise<T | undefined>> => async (dispatch, getState) => {
        if (stateOptions.loadApi) {
            const state = getState();
            const ownership = selectLoggedInUserOwnership(state);
            const user = maybeSelectLoggedInUser(state);
            const locale = selectCurrentLocale(state);
            const globallySelectedWebshopId = globalSelectionState.selectGlobalSelectedWebshopId(state);
            const urlParams = stateOptions.selectors.selectUrlParams(state);

            const details = await stateOptions.loadApi({
                ownership,
                user,
                locale,
                globallySelectedWebshopId,
                urlParams,
            });

            if (details !== undefined) {
                await dispatch(stateOptions.reducerActions.setDetails(details));
            }

            return details;
        }
    };

    const load =
        (loadOptions: { urlParams: URLParams }): ThunkAction<Promise<T | undefined>> =>
        async dispatch => {
            await dispatch(stateOptions.reducerActions.setUrlParams(loadOptions.urlParams));

            return await dispatch(loadDetails());
        };

    return {
        loadDetails,
        load,
    };
}

export function makeReducer<T, X = void>(
    stateOptions: ReducerStateOptions<T, X> & {
        reducerActions: ReturnType<typeof makeReducerActions>;
    },
) {
    const initialState = {
        urlParams: {},
    } as DetailsState<T> & X;

    return createReducer(initialState, builder => {
        builder
            .addCase(stateOptions.reducerActions.setDetails, (state, action) => {
                state.details = action.payload as Draft<T>;
            })
            .addCase(stateOptions.reducerActions.setUrlParams, (state, action) => {
                state.urlParams = action.payload;
            });

        if (stateOptions.extraCases) {
            stateOptions.extraCases(builder);
        }
    });
}

export function makeSelectors<T>(stateOptions: SelectorsStateOptions<T>) {
    const maybeSelectDetails: Selector<any, T | undefined> = state => stateOptions.getState(state).details;
    const selectDetails: Selector<any, T> = state => {
        const details = maybeSelectDetails(state);
        if (!details) {
            throw new Error('Details not loaded');
        }
        return details;
    };

    const selectUrlParams: Selector<any, URLParams> = state => stateOptions.getState(state).urlParams;

    return {
        maybeSelectDetails,
        selectDetails,
        selectUrlParams,
    };
}
