import { CSSProperties } from 'react';
import RichTextEditor, { EditorValue } from 'react-rte';

import { DraftInlineStyle, EditorState, CompositeDecorator, ContentBlock } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { stateFromHTML } from 'draft-js-import-html';
// @ts-ignore
import { getTextAlignStyles, getTextAlignBlockMetadata } from 'react-rte/lib/lib/blockStyleFunctions';

import LinkDecorator from './HTMLEditorValueLinkDecorator';

const rgb2hex = (rgb: string) =>
    `#${((rgb.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/) || '').slice(1) as string[]).map(n => parseInt(n, 10).toString(16).padStart(2, '0')).join('')}`;

export class HTMLEditorValue extends EditorValue {
    static createEmpty() {
        return RichTextEditor.createEmptyValue();
    }

    static mapFromString(
        value: string,
        options?: {
            handleFontFamily?: (fontFamily: string) => void;
        },
    ) {
        EditorValue.createFromState(
            EditorState.createWithContent(
                stateFromHTML(value, {
                    customInlineFn: element => {
                        const fontFamily = (element as HTMLElement).style.fontFamily;
                        if (fontFamily) {
                            if (options && options.handleFontFamily) {
                                options.handleFontFamily(fontFamily);
                            }
                        }
                        return undefined;
                    },
                }),
                new CompositeDecorator([LinkDecorator]),
            ),
        );
    }

    static createFromString(value: string) {
        return EditorValue.createFromState(
            EditorState.createWithContent(
                stateFromHTML(value, {
                    customBlockFn: (element: HTMLElement) => {
                        const bulletColorMetadata: {
                            bulletColor?: string;
                        } = {};
                        if (element.style.color) {
                            bulletColorMetadata.bulletColor = element.style.color;
                        }

                        return {
                            data: {
                                ...(getTextAlignBlockMetadata(element).data || {}),
                                ...bulletColorMetadata,
                            },
                        };
                    },
                    customInlineFn: (element, { Style }) => {
                        const styles: string[] = [];

                        const fontFamily = (element as HTMLElement).style.fontFamily;
                        if (fontFamily) {
                            styles.push('FONT_FAMILY:' + fontFamily);
                        }

                        const fontSize = (element as HTMLElement).style.fontSize;
                        if (fontSize) {
                            styles.push('FONT_SIZE:' + fontSize.replace('px', ''));
                        }

                        const textColor = (element as HTMLElement).style.color;
                        if (textColor) {
                            styles.push('TEXT_COLOR:' + rgb2hex(textColor));
                        }

                        if (styles.length > 0) {
                            return Style(styles as any);
                        }

                        return undefined;
                    },
                }),
                new CompositeDecorator([LinkDecorator]),
            ),
        );
    }

    static toString(value: EditorValue) {
        return stateToHTML(value.getEditorState().getCurrentContent(), {
            blockStyleFn: (block: ContentBlock) => {
                const bulletColorStyles: CSSProperties = {};
                const data = block.getData().toJS();
                if (data.bulletColor) {
                    bulletColorStyles.color = data.bulletColor;
                }

                return {
                    style: {
                        ...(getTextAlignStyles(block).style || {}),
                        ...bulletColorStyles,
                    },
                };
            },
            inlineStyleFn: (
                styles: DraftInlineStyle,
            ): {
                style?: CSSProperties;
            } => {
                const style: CSSProperties = {};
                const fontFamilyStyle = styles.find(style => {
                    if (style) {
                        return style.indexOf('FONT_FAMILY:') === 0;
                    }

                    return false;
                });

                const fontSizeStyle = styles.find(style => {
                    if (style) {
                        return style.indexOf('FONT_SIZE:') === 0;
                    }

                    return false;
                });

                const textColorStyle = styles.find(style => {
                    if (style) {
                        return style.indexOf('TEXT_COLOR:') === 0;
                    }

                    return false;
                });

                if (fontFamilyStyle) {
                    style.fontFamily = fontFamilyStyle.replace('FONT_FAMILY:', '');
                }

                if (fontSizeStyle) {
                    style.fontSize = fontSizeStyle.replace('FONT_SIZE:', '') + 'px';
                }

                if (textColorStyle) {
                    style.color = textColorStyle.replace('TEXT_COLOR:', '');
                }

                if (Object.keys(style).length === 0) {
                    return {};
                }

                return {
                    style,
                };
            },
        } as any);
    }
}
