import { createAction, Selector, createReducer } from '@reduxjs/toolkit';

import { Domain } from 'api';

import { ThunkAction, withPayloadType } from '@/action';
import { webshopFaqLibraryApi } from '@/api';
import { URLParams } from '@/routing';
import { RootState } from '@/store';

export interface State {
    faqCategory?: Domain.WebshopFaqCategoryDetails;
}

const initialState: State = {};

const reducerActions = {
    setFaqCategory: createAction('@webshopFaqCategory/update/setFaqCategory', withPayloadType<Domain.WebshopFaqCategoryDetails>()),
};

export const updateReducer = createReducer(initialState, builder =>
    builder.addCase(reducerActions.setFaqCategory, (state, action) => {
        state.faqCategory = action.payload;
    }),
);

export const selectFaqCategory: Selector<RootState, Domain.WebshopFaqCategoryDetails> = state => {
    const faqCategory = state.webshopFaqCategoryLibrary.update.faqCategory;
    if (!faqCategory) {
        throw new Error('FAQ category not loaded');
    }

    return faqCategory;
};

export const loadFaqCategory =
    (faqCategoryId: Domain.WebshopFaqCategory['faqCategoryId']): ThunkAction<Promise<Domain.WebshopFaqCategoryDetails>> =>
    async dispatch => {
        const faqCategory = await webshopFaqLibraryApi.GetWebshopFaqLibraryCategoryDetails(faqCategoryId);

        await dispatch(reducerActions.setFaqCategory(faqCategory));

        return faqCategory;
    };

export const load =
    (options: { urlParams: URLParams }): ThunkAction =>
    async dispatch => {
        await Promise.all([dispatch(loadFaqCategory(options.urlParams.faqCategoryId))]);
    };
