import { Domain } from 'api';
import { reducer as canvasReducer, Actions as CanvasActions, getMaxFrameDepth, getMinFrameDepth } from 'editor-canvas';
import { computeProductSize, PRODUCT_SCALE_ALGORITHM_VERSION } from 'utils';

import { PageLayout, Frame } from '../types';
import { getSelectedImage, getProductImages, computeMaxUsableScale } from '../utils';
import * as Actions from './actions';
import { State } from './state';

const defaults = {
    undoStack: [],
    redoStack: [],
    draggedBlockType: undefined,
    draggedBlockPosition: {
        x: 0,
        y: 0,
        width: 0,
        height: 0,
    },
    draggedBlockDroppedFlag: false,
    settingsMenuVisible: false,
    eyedropperEnabled: false,
    eyedropperToolFrameInfo: {
        frameId: undefined,
        isDown: false,
        x: 0,
        y: 0,
        w: 1,
        h: 1,
    },
};

const initLayout: PageLayout = {
    width: 596,
    height: 1060,
    frames: [],
    priceStyle: {
        shape: 'rect-top-left',
        text: {
            color: '#FFFFFF',
            align: 'center',
            font: 'Roboto, sans-serif',
            size: 11,
        },
        backgroundColor: '#FFA500',
        borderColor: '#DE8F00',
    },
    promoPriceStyle: {
        shape: 'circle',
        text: {
            color: '#0E3E78',
            align: 'center',
            font: 'Roboto, sans-serif',
            size: 11,
        },
        promoText: {
            color: '#FFFFFF',
            align: 'center',
            font: 'Roboto, sans-serif',
            size: 11,
        },
        backgroundColor: '#41AAEB',
        borderColor: '#41AAEB',
    },
};

export const initialState: State = {
    canvas: initLayout,
    availableProducts: [],
    productImageSizes: {},
    initialState: JSON.stringify(initLayout),
    inPreviewMode: false,
    screenResolution: '1080x1920',
    ...defaults,
};

export function reducer(state = initialState, action: Actions.ActionTypes): State {
    switch (action.type) {
        case Actions.SET_AVAILABLE_PRODUCTS:
            return {
                ...state,
                availableProducts: action.availableProducts,
            };

        case Actions.UPDATE_AVAILABLE_PRODUCT:
            return {
                ...state,
                availableProducts: [
                    ...state.availableProducts.filter(availableProduct => availableProduct.productId !== action.availableProduct.productId),
                    action.availableProduct,
                ],
            };

        case Actions.SET_PRODUCT_IMAGE_SIZE:
            return ensureProductScalingIsCorrect(
                {
                    ...state,
                    productImageSizes: {
                        ...state.productImageSizes,
                        [action.imageUrl]: {
                            width: action.width,
                            height: action.height,
                        },
                    },
                },
                state.screenResolution,
            );

        case CanvasActions.ADD_FRAME:
            if (!state.draggedBlockType) {
                throw new Error('Cannot add frame while not dragging block');
            }

            const newFrame: Frame = {
                type: state.draggedBlockType,
                depth:
                    state.draggedBlockType === 'background'
                        ? getMinFrameDepth(state.canvas.frames) - 1
                        : getMaxFrameDepth(state.canvas.frames) + 1,
                ...getFrameDefaults(state.draggedBlockType as Frame['type']),
                ...action.frame,
            } as Frame;

            return {
                ...state,
                canvas: {
                    ...state.canvas,
                    frames: [...state.canvas.frames, newFrame],
                },
                draggedBlockType: undefined,
                editedFrameId: newFrame.frameId,
            };

        case Actions.SET_FRAME_TYPE:
            return deepMapFrame(
                frame => {
                    return {
                        frameId: frame.frameId,
                        x: frame.x,
                        y: frame.y,
                        width: frame.width,
                        height: frame.height,
                        depth: frame.depth,
                        type: action.frameType,
                        ...getFrameDefaults(action.frameType),
                    } as Frame;
                },
                state,
                action.frameId,
            );

        case Actions.SET_FRAME_TEXT:
            return deepMapFrame(
                frame => {
                    if (frame.type === 'text') {
                        frame.text = action.text || '';
                        frame.textStyle = action.textStyle;
                    }
                    return frame;
                },
                state,
                action.frameId,
            );

        case Actions.SET_FRAME_TEXT_STYLE:
            return deepMapFrame(
                frame => {
                    if (
                        frame.type === 'pharmacistOnDuty' ||
                        frame.type === 'openingHours' ||
                        frame.type === 'text' ||
                        frame.type === 'emergencyAlert'
                    ) {
                        frame.textStyle = action.textStyle;
                    }
                    return frame;
                },
                state,
                action.frameId,
            );

        case Actions.SET_PHARMACIST_ON_DUTY_FRAME_NUM_PHARMACISTS:
            return deepMapFrame(
                frame => {
                    if (frame.type === 'pharmacistOnDuty') {
                        frame.numPharmacists = action.numPharmacists;
                    }
                    return frame;
                },
                state,
                action.frameId,
            );

        case Actions.SET_OPENING_HOURS_FRAME_SHOW_DAY:
            return deepMapFrame(
                frame => {
                    if (frame.type === 'openingHours') {
                        frame.showDays[action.day] = action.show;
                    }
                    return frame;
                },
                state,
                action.frameId,
            );

        case Actions.SET_FRAME_HTML:
            return deepMapFrame(
                frame => {
                    if (frame.type === 'richText') {
                        frame.html = action.html || '';
                    }
                    return frame;
                },
                state,
                action.frameId,
            );

        case Actions.SET_TEXT_FRAME_HAS_STYLED_BACKGROUND:
            return deepMapFrame(
                frame => {
                    if (frame.type === 'text' || frame.type === 'richText') {
                        frame.styleBackground = action.styleBackground;
                    }
                    return frame;
                },
                state,
                action.frameId,
            );

        case Actions.SET_TEXT_FRAME_BACKGROUND_TYPE:
            return deepMapFrame(
                frame => {
                    if (frame.type === 'text' || frame.type === 'richText') {
                        frame.backgroundType = action.backgroundType;
                        if (frame.backgroundType === 'image' || frame.backgroundType === 'video') {
                            frame.backgroundMediaItemId = '';
                        }
                    }
                    return frame;
                },
                state,
                action.frameId,
            );

        case Actions.SET_TEXT_FRAME_BACKGROUND_COLOR:
            return deepMapFrame(
                frame => {
                    if ((frame.type === 'text' || frame.type === 'richText') && frame.backgroundType === 'color') {
                        frame.backgroundColor = action.backgroundColor;
                    }
                    return frame;
                },
                state,
                action.frameId,
            );

        case Actions.SET_TEXT_FRAME_BACKGROUND_MEDIA_ITEM_ID:
            return deepMapFrame(
                frame => {
                    if (
                        (frame.type === 'text' || frame.type === 'richText') &&
                        (frame.backgroundType === 'image' || frame.backgroundType === 'video')
                    ) {
                        frame.backgroundMediaItemId = action.backgroundMediaItemId;
                        if (!frame.backgroundImageCrop) {
                            frame.backgroundImageCrop = 'proportional-inside';
                        }
                    }
                    return frame;
                },
                state,
                action.frameId,
            );

        case Actions.SET_TEXT_FRAME_BACKGROUND_IMAGE_CROP:
            return deepMapFrame(
                frame => {
                    if ((frame.type === 'text' || frame.type === 'richText') && frame.backgroundType === 'image') {
                        frame.backgroundImageCrop = action.backgroundImageCrop;
                    }
                    return frame;
                },
                state,
                action.frameId,
            );

        case Actions.SET_MEDIA_FRAME_TYPE:
            return deepMapFrame(
                frame => {
                    if (frame.type === 'media') {
                        if (frame.mediaType === 'image') {
                            frame.prevImageMediaItemId = frame.mediaItemId;
                        } else if (frame.mediaType === 'video') {
                            frame.prevVideoMediaItemId = frame.mediaItemId;
                        }

                        frame.mediaType = action.mediaType;

                        if (frame.mediaType === 'image') {
                            frame.mediaItemId = frame.prevImageMediaItemId;
                        } else if (frame.mediaType === 'video') {
                            frame.mediaItemId = frame.prevVideoMediaItemId;
                        }
                    }
                    return frame;
                },
                state,
                action.frameId,
            );

        case Actions.SET_MEDIA_FRAME_MEDIA_ITEM_ID:
            return deepMapFrame(
                frame => {
                    if (frame.type === 'media' && (frame.mediaType === 'image' || frame.mediaType === 'video')) {
                        frame.mediaItemId = action.mediaItemId;

                        if (frame.mediaType === 'image') {
                            frame.prevImageMediaItemId = frame.mediaItemId;
                        } else if (frame.mediaType === 'video') {
                            frame.prevVideoMediaItemId = frame.mediaItemId;
                        }
                    }
                    if (frame.type === 'media' && frame.mediaType === 'video') {
                        frame.videoDuration = action.videoDuration;
                    } else {
                        delete (frame as any).videoDuration;
                    }
                    return frame;
                },
                state,
                action.frameId,
            );

        case Actions.SET_MEDIA_FRAME_MEDIA_CROP:
            return deepMapFrame(
                frame => {
                    if (frame.type === 'media' && (frame.mediaType === 'image' || frame.mediaType === 'video')) {
                        frame.imageCrop = action.imageCrop;
                    }
                    return frame;
                },
                state,
                action.frameId,
            );

        case Actions.SET_SHELF_FRAME_STYLE:
            return deepMapFrame(
                frame => {
                    if (frame.type === 'shelf') {
                        frame.shelfStyle = action.shelfStyle;
                    }
                    return frame;
                },
                state,
                action.frameId,
            );

        case Actions.SET_PRODUCT_FRAME_PRODUCT:
            return deepMapFrame(
                frame => {
                    if (frame.type === 'product') {
                        frame.productId = action.product.productId;

                        if (frame.productMediaType) {
                            if (!Domain.slideshowProductHasFrontalImage(action.product)) {
                                frame.productMediaType = 'packshot';
                            } else if (!Domain.slideshowProductHasProductImage(action.product)) {
                                frame.productMediaType = 'frontal';
                            }
                        } else if (Domain.slideshowProductHasFrontalImage(action.product)) {
                            frame.productMediaType = 'frontal';
                        } else {
                            frame.productMediaType = 'packshot';
                        }
                    }
                    return frame;
                },
                {
                    ...state,
                    availableProducts: [
                        ...state.availableProducts.filter(searchedProduct => searchedProduct.productId !== action.product.productId),
                        action.product,
                    ],
                },
                action.frameId,
            );

        case Actions.SET_FRAME_HOTSPOT_PRODUCT_ID:
            return deepMapFrame(
                frame => {
                    if (frame.type === 'hotSpot' && frame.hotSpotType === 'productDetails') {
                        frame.productId = action.product.productId;
                    }
                    return frame;
                },
                {
                    ...state,
                    availableProducts: [
                        ...state.availableProducts.filter(searchedProduct => searchedProduct.productId !== action.product.productId),
                        action.product,
                    ],
                },
                action.frameId,
            );

        case Actions.SET_PRODUCT_FRAME_PRODUCT_MEDIA_TYPE:
            return deepMapFrame(
                frame => {
                    if (frame.type === 'product') {
                        frame.productMediaType = action.productMediaType;
                        frame.imageId = undefined;
                    }
                    return frame;
                },
                state,
                action.frameId,
            );

        case Actions.SET_PLACED_PRODUCT_ITEMS_LIMIT_ENABLED:
            return deepMapFrame(
                frame => {
                    if (frame.type === 'product') {
                        frame.placedProduct.enableItemsLimit = action.enabled;
                    }
                    return frame;
                },
                state,
                action.frameId,
            );

        case Actions.SET_PLACED_PRODUCT_ITEMS_LIMIT:
            return deepMapFrame(
                frame => {
                    if (frame.type === 'product') {
                        frame.placedProduct.itemsLimit = action.limit;
                    }
                    return frame;
                },
                state,
                action.frameId,
            );

        case Actions.SET_PLACED_PRODUCT_SCALE:
            return deepMapFrame(
                frame => {
                    if (frame.type === 'product') {
                        frame.placedProduct.scale = action.scale;
                    }
                    return frame;
                },
                state,
                action.frameId,
            );

        case Actions.SET_PLACED_PRODUCT_ALIGN:
            return deepMapFrame(
                frame => {
                    if (frame.type === 'product') {
                        frame.placedProduct.alignItems = action.align;
                    }
                    return frame;
                },
                state,
                action.frameId,
            );

        case Actions.SET_PLACED_PRODUCT_X_SPACING:
            return deepMapFrame(
                frame => {
                    if (frame.type === 'product') {
                        frame.placedProduct.spacingX = action.spacing;
                    }
                    return frame;
                },
                state,
                action.frameId,
            );

        case Actions.SET_PLACED_PRODUCT_Y_SPACING:
            return deepMapFrame(
                frame => {
                    if (frame.type === 'product') {
                        frame.placedProduct.spacingY = action.spacing;
                    }
                    return frame;
                },
                state,
                action.frameId,
            );

        case Actions.SET_PLACED_PRODUCT_IMAGE_ID:
            return deepMapFrame(
                frame => {
                    if (frame.type === 'product') {
                        frame.imageId = action.imageId;
                    }
                    return frame;
                },
                state,
                action.frameId,
            );

        case Actions.SET_PLACED_PRODUCT_SHOW_PRICE:
            return deepMapFrame(
                frame => {
                    if (frame.type === 'product') {
                        frame.placedProduct.showPrice = action.showPrice;
                    }
                    return frame;
                },
                state,
                action.frameId,
            );

        case Actions.SET_PLACED_PRODUCT_HIDE_ORIGINAL_PRICE:
            return deepMapFrame(
                frame => {
                    if (frame.type === 'product') {
                        frame.placedProduct.hideOriginalPrice = action.hideOriginalPrice;
                    }
                    return frame;
                },
                state,
                action.frameId,
            );

        case Actions.SET_PLACED_PRODUCT_ENABLE_CUSTOM_PRICE_STYLE:
            return deepMapFrame(
                frame => {
                    if (frame.type === 'product') {
                        frame.placedProduct.enableCustomPriceStyling = action.enableCustomPriceStyling;

                        if (!frame.placedProduct.customPriceStyle) {
                            frame.placedProduct.customPriceStyle = state.canvas.priceStyle;
                        }
                        if (!frame.placedProduct.customPromoPriceStyle) {
                            frame.placedProduct.customPromoPriceStyle = state.canvas.promoPriceStyle;
                        }
                    }
                    return frame;
                },
                state,
                action.frameId,
            );

        case Actions.SET_PLACED_PRODUCT_CUSTOM_PRICE_STYLE_LABEL_SHAPE:
            return deepSetPlacedProductPriceStyleProp(
                state,
                action.frameId,
                {
                    shape: action.shape,
                },
                action.promo,
            );

        case Actions.SET_PLACED_PRODUCT_CUSTOM_PRICE_STYLE_LABEL_TEXT:
            return deepSetPlacedProductPriceStyleProp(
                state,
                action.frameId,
                {
                    text: action.text,
                },
                action.promo,
            );

        case Actions.SET_PLACED_PRODUCT_CUSTOM_PRICE_STYLE_LABEL_PROMO_TEXT:
            return deepSetPlacedProductPriceStyleProp(
                state,
                action.frameId,
                {
                    promoText: action.promoText,
                },
                true,
            );

        case Actions.SET_PLACED_PRODUCT_CUSTOM_PRICE_STYLE_LABEL_BACKGROUND_COLOR:
            return deepSetPlacedProductPriceStyleProp(
                state,
                action.frameId,
                {
                    backgroundColor: action.backgroundColor,
                },
                action.promo,
            );

        case Actions.SET_PLACED_PRODUCT_CUSTOM_PRICE_STYLE_LABEL_BORDER_COLOR:
            return deepSetPlacedProductPriceStyleProp(
                state,
                action.frameId,
                {
                    borderColor: action.borderColor,
                },
                action.promo,
            );

        case Actions.SET_BACKGROUND_FRAME_BACKGROUND_TYPE:
            return deepMapFrame(
                frame => {
                    if (frame.type === 'background') {
                        frame.backgroundType = action.backgroundType;
                        if (frame.backgroundType === 'image' || frame.backgroundType === 'video') {
                            frame.backgroundMediaItemId = '';
                        }
                    }
                    return frame;
                },
                state,
                action.frameId,
            );

        case Actions.SET_BACKGROUND_FRAME_BACKGROUND_COLOR:
            return deepMapFrame(
                frame => {
                    if (frame.type === 'background' && frame.backgroundType === 'color') {
                        frame.backgroundColor = action.backgroundColor;
                    }
                    return frame;
                },
                state,
                action.frameId,
            );

        case Actions.SET_BACKGROUND_FRAME_BACKGROUND_MEDIA_ITEM_ID:
            return deepMapFrame(
                frame => {
                    if (frame.type === 'background' && (frame.backgroundType === 'image' || frame.backgroundType === 'video')) {
                        frame.backgroundMediaItemId = action.backgroundMediaItemId;
                        if (!frame.backgroundImageCrop && frame.backgroundType === 'image') {
                            frame.backgroundImageCrop = 'proportional-inside';
                        }
                    }
                    return frame;
                },
                state,
                action.frameId,
            );

        case Actions.SET_BACKGROUND_FRAME_BACKGROUND_MEDIA_CROP:
            return deepMapFrame(
                frame => {
                    if (frame.type === 'background' && (frame.backgroundType === 'image' || frame.backgroundType === 'video')) {
                        frame.backgroundImageCrop = action.backgroundImageCrop;
                    }
                    return frame;
                },
                state,
                action.frameId,
            );

        case Actions.TOGGLE_SETTINGS_MENU:
            return {
                ...state,
                editedFrameId: undefined,
                settingsMenuVisible: action.settingsMenuVisible,
            };

        case Actions.SET_STYLE_PRICE_LABEL_SHAPE:
            return deepSetLayoutProp(
                state,
                {
                    shape: action.shape,
                },
                action.promo,
            );

        case Actions.SET_STYLE_PRICE_LABEL_TEXT:
            return deepSetLayoutProp(
                state,
                {
                    text: action.text,
                },
                action.promo,
            );

        case Actions.SET_STYLE_PRICE_LABEL_PROMO_TEXT:
            return deepSetLayoutProp(
                state,
                {
                    promoText: action.promoText,
                },
                true,
            );

        case Actions.SET_STYLE_PRICE_LABEL_BACKGROUND_COLOR:
            return deepSetLayoutProp(
                state,
                {
                    backgroundColor: action.backgroundColor,
                },
                action.promo,
            );

        case Actions.SET_STYLE_PRICE_LABEL_BORDER_COLOR:
            return deepSetLayoutProp(
                state,
                {
                    borderColor: action.borderColor,
                },
                action.promo,
            );

        case Actions.SET_IN_PREVIEW_MODE:
            return {
                ...state,
                inPreviewMode: action.inPreviewMode,
            };

        case Actions.SET_EYEDROPPER_ENABLED:
            return {
                ...state,
                eyedropperEnabled: action.eyedropperEnabled,
                eyedropperChangeHandlers: action.changeHandlers,
                eyedropperToolFrameInfo: {
                    frameId: undefined,
                    isDown: false,
                    x: 0,
                    y: 0,
                    w: 1,
                    h: 1,
                },
            };

        case Actions.SET_EYEDROPPER_TOOL_FRAME_COORDINATES:
            return {
                ...state,
                eyedropperToolFrameInfo: {
                    ...state.eyedropperToolFrameInfo,
                    frameId: action.frameId,
                    x: action.x,
                    y: action.y,
                    w: action.w,
                    h: action.h,
                },
            };

        case Actions.SET_EYEDROPPER_TOOL_FRAME_MOUSE_IS_DOWN:
            return {
                ...state,
                eyedropperToolFrameInfo: {
                    ...state.eyedropperToolFrameInfo,
                    frameId: action.frameId,
                    isDown: action.isDown,
                },
            };

        case Actions.SET_FRAME_HOTSPOT_TYPE:
            return deepMapFrame(
                frame => {
                    if (frame.type === 'hotSpot') {
                        frame.hotSpotType = action.hotSpotType;
                    }
                    return frame;
                },
                state,
                action.frameId,
            );

        case Actions.SET_FRAME_HOTSPOT_HIDDEN_SLIDE:
            return deepMapFrame(
                frame => {
                    if (frame.type === 'hotSpot' && frame.hotSpotType === 'hiddenSlide') {
                        frame.hiddenSlideType = action.hiddenSlideType;
                        frame.hiddenSlideId = action.hiddenSlideId;
                        frame.hiddenSlideName = action.hiddenSlideName;
                    }
                    return frame;
                },
                state,
                action.frameId,
            );

        case CanvasActions.SET_CANVAS:
            return ensureProductScalingIsCorrect(canvasReducer(state, action) as State, state.screenResolution);

        default:
            return canvasReducer(state, action) as State;
    }
}

function ensureProductScalingIsCorrect(state: State, screenResolution: Domain.DeviceScreenResolution): State {
    return deepMapFrames(frames => {
        return frames.map(frame => {
            if (frame.type === 'product') {
                const availableProduct = state.availableProducts.find(searchedProduct => searchedProduct.productId === frame.productId);
                if (!availableProduct) {
                    return frame;
                }

                const productImages = getProductImages(availableProduct);
                const selectedImage = getSelectedImage(productImages, frame.productMediaType, frame.imageId);
                if (!state.productImageSizes.hasOwnProperty(selectedImage.url)) {
                    return frame;
                }

                const productSize = computeProductSize(
                    screenResolution,
                    availableProduct,
                    state.productImageSizes[selectedImage.url],
                    false,
                    frame.placedProduct.version || 0,
                );

                if (productSize.width === 0 || productSize.height === 0) {
                    return frame;
                }

                const maxScale = computeMaxUsableScale(frame, productSize, frame.placedProduct.scale || 2);

                if (!frame.placedProduct.scale || frame.placedProduct.scale > maxScale) {
                    return {
                        ...frame,
                        placedProduct: {
                            ...frame.placedProduct,
                            scale: maxScale,
                        },
                    };
                }
            }
            return frame;
        });
    }, state);
}

function getFrameDefaults(type: Frame['type']): Partial<Frame> {
    if (type === 'media') {
        return {
            mediaType: 'image',
        };
    } else if (type === 'text') {
        return {
            text: '',
            textStyle: {
                size: 16,
            },
            styleBackground: false,
            backgroundType: 'color',
        };
    } else if (type === 'richText') {
        return {
            html: '',
            styleBackground: false,
            backgroundType: 'color',
        };
    } else if (type === 'shelf') {
        return {
            shelfStyle: '2D',
        };
    } else if (type === 'product') {
        return {
            placedProduct: {
                version: PRODUCT_SCALE_ALGORITHM_VERSION,
            },
        };
    } else if (type === 'background') {
        return {
            backgroundType: 'color',
            backgroundColor: '#cac3c3',
        };
    } else if (type === 'hotSpot') {
        return {
            hotSpotType: 'productDetails',
            productId: '',
        };
    } else if (type === 'pharmacistOnDuty') {
        return {
            textStyle: {
                size: 16,
            },
            numPharmacists: 3,
        };
    } else if (type === 'emergencyAlert') {
        return {
            textStyle: {
                size: 16,
            },
        };
    } else if (type === 'openingHours') {
        return {
            textStyle: {
                size: 15,
            },
            showDays: {
                Monday: true,
                Tuesday: true,
                Wednesday: true,
                Thursday: true,
                Friday: true,
                Saturday: true,
                Sunday: true,
            },
        };
    }

    return {};
}

function deepMapFrame(mapper: (frame: Frame) => Frame, state: State, frameId: string) {
    return deepMapFrames(
        frames => [
            ...frames.map(frame => {
                if (frame.frameId === frameId) {
                    return mapper(frame);
                }

                return frame;
            }),
        ],
        state,
    );
}

function deepMapFrames(mapper: (frames: Frame[]) => Frame[], state: State) {
    return {
        ...state,
        canvas: {
            ...state.canvas,
            frames: [...mapper(state.canvas.frames)],
        },
    };
}

function deepSetPlacedProductPriceStyleProp(
    state: State,
    frameId: string,
    prop: {
        [key: string]: any;
    },
    promo?: boolean,
) {
    const style = promo ? 'customPromoPriceStyle' : 'customPriceStyle';
    return deepMapFrame(
        frame => {
            if (frame.type === 'product') {
                if (!frame.placedProduct[style]) {
                    throw new Error(`${style} is undefined`);
                }
                frame.placedProduct[style] = {
                    ...frame.placedProduct[style],
                    ...(prop as any),
                };
            }
            return frame;
        },
        state,
        frameId,
    );
}

function deepSetLayoutProp(
    state: State,
    prop: {
        [key: string]: any;
    },
    promo?: boolean,
) {
    const style = promo ? 'promoPriceStyle' : 'priceStyle';
    return {
        ...state,
        canvas: {
            ...state.canvas,
            [style]: {
                ...state.canvas[style],
                ...prop,
            },
        },
    };
}
