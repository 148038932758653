import * as React from 'react';

import classNames from 'classnames';

import { Domain } from 'api';
import { computeProductSize, PRODUCT_SCALE_ALGORITHM_VERSION } from 'utils';

import { Actions, store } from '../state';
import { DropArea, ShelfWithMeta, ShelfStyleParameters, PlacedProductFillOptions } from '../types';
import { computeMaxUsableScale, findPlacedProduct, findPlacedProductShelf, fillProductArea, getSelectedImage } from '../utils';

import styles from './styles/ProductsShelfDropArea.scss';

export default function ProductsShelfDropArea(props: { dropArea: DropArea; shelf: ShelfWithMeta }) {
    const { state, dispatch } = React.useContext(store);
    const { dropArea, shelf } = props;
    const dropAreaRef = React.useRef<HTMLDivElement>(null);
    const LayoutParams = ShelfStyleParameters[state.canvas.style];

    let isDraggingOver = false;
    let leftDragPosition = 0;
    let product: Domain.SlideshowProduct | undefined;
    let fill;
    let fillBoxImageUrl = '';

    if (state.draggedProduct) {
        const target = document.elementFromPoint(state.draggedProductPosition.x, state.draggedProductPosition.y);

        if (target && dropAreaRef.current && dropAreaRef.current === target) {
            isDraggingOver = true;
            leftDragPosition = state.draggedProductPosition.x - target.getBoundingClientRect().left - dropArea.x;
        }

        if (isDraggingOver) {
            product = state.draggedProduct;
            const selectedImage = getSelectedImage(undefined, product);
            fillBoxImageUrl = selectedImage.url;

            if (state.productImageSizes.hasOwnProperty(selectedImage.url)) {
                const fillOptions: PlacedProductFillOptions = {
                    scale: 1,
                    itemsLimit: undefined,
                    alignItems: 'center',
                    spacingX: 0,
                    spacingY: 0,
                    imageId: undefined,
                };

                const productSize = computeProductSize(
                    state.screenResolution,
                    state.draggedProduct,
                    state.productImageSizes[selectedImage.url],
                    true,
                    PRODUCT_SCALE_ALGORITHM_VERSION,
                );

                fillOptions.scale = computeMaxUsableScale(
                    {
                        width: dropArea.width,
                        height: shelf.shelf.height - LayoutParams.shelfBottomOffset,
                    },
                    productSize,
                    1,
                );

                let height = productSize.height * fillOptions.scale;
                height += LayoutParams.shelfBottomOffset;
                height = Math.ceil(height);
                let width = productSize.width * fillOptions.scale;
                width = Math.ceil(width);

                if (state.draggedPlacedProductId !== undefined) {
                    const placedProduct = findPlacedProduct(state.canvas.shelves, state.draggedPlacedProductId);
                    if (placedProduct) {
                        const maxScaleForDraggedProduct = computeMaxUsableScale(
                            {
                                width: dropArea.width,
                                height: shelf.shelf.height - LayoutParams.shelfBottomOffset,
                            },
                            productSize,
                            2,
                        );

                        fillOptions.scale = Math.min(maxScaleForDraggedProduct, placedProduct.scale || 1);
                        fillOptions.itemsLimit = placedProduct.itemsLimit;
                        fillOptions.alignItems = placedProduct.alignItems || 'center';
                        fillOptions.spacingX = placedProduct.spacingX || 0;
                        fillOptions.spacingY = placedProduct.spacingY || 0;
                        fillOptions.showPrice = placedProduct.showPrice;
                        fillOptions.enableCustomPriceStyling = placedProduct.enableCustomPriceStyling;
                        fillOptions.customPriceStyle = placedProduct.customPriceStyle;
                        fillOptions.customPromoPriceStyle = placedProduct.customPromoPriceStyle;

                        width = Math.min(placedProduct.width, dropArea.width);
                        height = Math.min(placedProduct.height, shelf.shelf.height);

                        const selectedImage = getSelectedImage(placedProduct, product);
                        fillBoxImageUrl = selectedImage.url;
                        fillOptions.imageId = selectedImage.id;
                    }
                }

                leftDragPosition -= width / 2;

                leftDragPosition = Math.max(0, Math.min(dropArea.x + leftDragPosition, dropArea.width - width));

                fill = fillProductArea({
                    areaWidth: width,
                    areaHeight: height,
                    productWidth: productSize.width,
                    productHeight: productSize.height,
                    bottomOffset: LayoutParams.shelfBottomOffset,
                    ...fillOptions,
                });

                if (state.draggedProductDroppedFlag) {
                    dispatch({
                        type: Actions.SET_DRAGGED_PRODUCT_DROPPED_FLAG,
                        dropped: false,
                    });

                    dispatch({
                        type: Actions.CanvasActions.HISTORY_SAVE,
                    });

                    dispatch({
                        type: Actions.ADD_PRODUCT_TO_SHELF,
                        product: state.draggedProduct,
                        shelfId: shelf.shelf.id,
                        area: {
                            x: dropArea.x + leftDragPosition,
                            width,
                            height: height,
                        },
                        ...fillOptions,
                    });

                    if (state.draggedPlacedProductId) {
                        const fromShelf = findPlacedProductShelf(state.canvas.shelves, state.draggedPlacedProductId);
                        if (fromShelf) {
                            dispatch({
                                type: Actions.REMOVE_PRODUCT_FROM_SHELF,
                                shelfId: fromShelf.id,
                                placedProductId: state.draggedPlacedProductId,
                            });
                        }
                    }
                }
            }
        }
    }

    return (
        <div
            ref={dropAreaRef}
            className={classNames(styles.DropArea, state.draggedProduct || (product && fill) ? styles.DropAreaActive : null)}
            style={{
                left: dropArea.x + 'px',
                width: dropArea.width + 'px',
            }}
        >
            {product && fill ? (
                <div className={styles.PlacedProduct}>
                    <>
                        {fill.boxes.map(box => {
                            if (!product) {
                                return null;
                            }

                            return (
                                <img
                                    key={box.key}
                                    src={fillBoxImageUrl}
                                    style={{
                                        height: box.height + 'px',
                                        width: box.width + 'px',
                                        left: leftDragPosition + box.left + 'px',
                                        bottom: box.bottom + 'px',
                                    }}
                                    alt=""
                                />
                            );
                        })}
                    </>
                </div>
            ) : null}
        </div>
    );
}
