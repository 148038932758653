import { Domain } from 'api';

import { ThunkAction } from '@/action';
import { pickupApi } from '@/api';
import { loadConfiguredIntegrationsByType } from '@/Integration/configuredIntegrationsTypeState';
import { makeActions, makeReducer, makeReducerActions, makeSelectors } from '@/makeOverviewState';
import { URLParams, URLQuery } from '@/routing';

export type PickupProps = keyof Domain.Pickup;

export const defaultSorting: Domain.Sorting<PickupProps> = {
    field: 'createdAt',
    direction: 'descending',
};

export const pageSize = 20;

export const selectors = makeSelectors<Domain.Pickup, PickupProps>({
    getState: rootState => rootState.pickup.overview,
});

export const reducerActions = makeReducerActions<Domain.Pickup, PickupProps>({
    reducerPrefix: '@pickup/overview',
});

const baseActions = makeActions<
    Domain.Pickup,
    PickupProps,
    {
        configuredIntegrationIds: string;
    }
>({
    dataTableSaveKey: 'pickupsOverview-v2',
    loadApi: options =>
        pickupApi.GetPickups(
            options.ownership,
            options.pagination,
            options.sorting,
            options.search,
            options.filters
                ? {
                      ...options.filters,
                      configuredIntegrationIds: options.filters.configuredIntegrationIds
                          ? options.filters.configuredIntegrationIds.split(',')
                          : undefined,
                  }
                : undefined,
        ),
    defaultSorting,
    pageSize,
    getBaseUrl: () => '/reservations',
    reducerActions,
    selectors,
});

export const overviewReducer = makeReducer<Domain.Pickup, PickupProps>({
    defaultSorting,
    pageSize,
    reducerActions,
});

export const actions = {
    ...baseActions,
    load:
        (options: { urlParams: URLParams; urlQuery: URLQuery }): ThunkAction =>
        async dispatch => {
            await Promise.all([dispatch(loadConfiguredIntegrationsByType('deliverPickups')), dispatch(baseActions.load(options))]);
        },
};
