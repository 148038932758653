import { Domain } from 'api';

import { integrationApi } from '@/api';
import { makeActions, makeReducer, makeReducerActions, makeSelectors } from '@/makeDetailsState';

export const selectors = makeSelectors<Domain.IntegrationDetails>({
    getState: rootState => rootState.integration.update,
});

export const reducerActions = makeReducerActions<Domain.IntegrationDetails>({
    reducerPrefix: '@integration/update',
});

export const actions = makeActions<Domain.IntegrationDetails>({
    loadApi: options => integrationApi.GetIntegrationDetail(options.urlParams.configuredIntegrationId),
    reducerActions,
    selectors,
});

export const updateReducer = makeReducer<Domain.IntegrationDetails>({
    reducerActions,
});
