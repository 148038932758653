import { DeviceHomeScreenProductsV2, VendingMachineViewMode, VendingMachineDayNightMode } from './Device';
import { ImageWithSize } from './Media';

export interface VendingMachineDesign {
    lightModeColors: VendingMachineColorSet;
    darkModeColors: VendingMachineColorSet;
    typography: {
        font: VendingMachineFontSet;
    };
    logo?: ImageWithSize;
    homeScreenProducts?: DeviceHomeScreenProductsV2;
    forcedViewMode?: VendingMachineViewMode;
    forcedDayNightMode?: VendingMachineDayNightMode;
}

export interface VendingMachineFontSet {
    fontSetId: string;
    fontFamily: string;
    fontSetName: string;
    fontProvider: 'adobe' | 'google';
    fontSetUrl: string;
    fallback: string[];
}

export interface VendingMachineColor {
    red: number;
    green: number;
    blue: number;
}

export interface VendingMachineColorSet {
    colorSetId: string;
    colorSetName: string;
    emphasize: VendingMachineColor;
    pageBackground: VendingMachineColor;
    primary: VendingMachineColor;
    textPrimary: VendingMachineColor;
    borderPrimary: VendingMachineColor;
    secondary: VendingMachineColor;
    textSecondary: VendingMachineColor;
    borderSecondary: VendingMachineColor;
    textDark: VendingMachineColor;
    white: VendingMachineColor;
    error: VendingMachineColor;
}

export const VENDING_MACHINE_FONTS: VendingMachineFontSet[] = [
    {
        fontSetId: 'fontset-sofia-pro',
        fontSetName: 'Sofia Pro',
        fontFamily: 'sofia-pro',
        fontSetUrl: 'https://use.typekit.net/dij1egp.css',
        fallback: ['sans-serif'],
        fontProvider: 'adobe',
    },
    {
        fontSetId: 'fontset-soleil',
        fontSetName: 'Soleil',
        fontFamily: 'soleil',
        fontSetUrl: 'https://use.typekit.net/dqf1nmq.css',
        fallback: ['sans-serif'],
        fontProvider: 'adobe',
    },
    {
        fontSetId: 'fontset-zeitung-micro',
        fontSetName: 'Zeitung Micro',
        fontFamily: 'zeitung-micro',
        fontSetUrl: 'https://use.typekit.net/wsr3exg.css',
        fallback: ['sans-serif'],
        fontProvider: 'adobe',
    },
    {
        fontSetId: 'fontset-campaign',
        fontSetName: 'Campaign',
        fontFamily: 'campaign',
        fontSetUrl: 'https://use.typekit.net/hds1wco.css',
        fallback: ['sans-serif'],
        fontProvider: 'adobe',
    },
    {
        fontSetId: 'fontset-fira-sans',
        fontSetName: 'Fira Sans',
        fontFamily: 'fira-sans',
        fontSetUrl: 'https://use.typekit.net/gpd8mfm.css',
        fallback: ['sans-serif'],
        fontProvider: 'adobe',
    },
    {
        fontSetId: 'fontset-roboto',
        fontSetName: 'Roboto',
        fontFamily: 'Roboto',
        fontSetUrl: 'https://fonts.googleapis.com/css2?family=Roboto:wght@300;500&display=swap',
        fallback: ['sans-serif'],
        fontProvider: 'google',
    },
    {
        fontSetId: 'fontset-lato',
        fontSetName: 'Lato',
        fontFamily: 'Lato',
        fontSetUrl: 'https://fonts.googleapis.com/css2?family=Lato:wght@300;700&display=swap',
        fallback: ['sans-serif'],
        fontProvider: 'google',
    },
    {
        fontSetId: 'fontset-montserrat',
        fontSetName: 'Montserrat',
        fontFamily: 'Montserrat',
        fontSetUrl: 'https://fonts.googleapis.com/css2?family=Montserrat:wght@300;500&display=swap',
        fallback: ['sans-serif'],
        fontProvider: 'google',
    },
    {
        fontSetId: 'fontset-oswald',
        fontSetName: 'Oswald',
        fontFamily: 'Oswald',
        fontSetUrl: 'https://fonts.googleapis.com/css2?family=Oswald:wght@300;500&display=swap',
        fallback: ['sans-serif'],
        fontProvider: 'google',
    },
    {
        fontSetId: 'fontset-source-sans-pro',
        fontSetName: 'Source Sans Pro',
        fontFamily: 'Source Sans Pro',
        fontSetUrl: 'https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;600&display=swap',
        fallback: ['sans-serif'],
        fontProvider: 'google',
    },
    {
        fontSetId: 'fontset-raleway',
        fontSetName: 'Raleway',
        fontFamily: 'Raleway',
        fontSetUrl: 'https://fonts.googleapis.com/css2?family=Raleway:wght@300;500&display=swap',
        fallback: ['sans-serif'],
        fontProvider: 'google',
    },
    {
        fontSetId: 'fontset-pt-sans',
        fontSetName: 'PT Sans',
        fontFamily: 'PT Sans',
        fontSetUrl: 'https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;700&display=swap',
        fallback: ['sans-serif'],
        fontProvider: 'google',
    },
    {
        fontSetId: 'fontset-merriweather-sans',
        fontSetName: 'Merriweather Sans',
        fontFamily: 'Merriweather Sans',
        fontSetUrl: 'https://fonts.googleapis.com/css2?family=Merriweather+Sans:wght@300;500&display=swap',
        fallback: ['sans-serif'],
        fontProvider: 'google',
    },
    {
        fontSetId: 'fontset-merriweather',
        fontSetName: 'Merriweather',
        fontFamily: 'Merriweather',
        fontSetUrl: 'https://fonts.googleapis.com/css2?family=Merriweather:wght@300;700&display=swap',
        fallback: ['serif'],
        fontProvider: 'google',
    },
    {
        fontSetId: 'fontset-noto-sans',
        fontSetName: 'Noto Sans',
        fontFamily: 'Noto Sans',
        fontSetUrl: 'https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;700&display=swap',
        fallback: ['sans-serif'],
        fontProvider: 'google',
    },
    {
        fontSetId: 'fontset-nunito-sans',
        fontSetName: 'Nunito Sans',
        fontFamily: 'Nunito Sans',
        fontSetUrl: 'https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;600&display=swap',
        fallback: ['sans-serif'],
        fontProvider: 'google',
    },
    {
        fontSetId: 'fontset-prompt',
        fontSetName: 'Prompt',
        fontFamily: 'Prompt',
        fontSetUrl: 'https://fonts.googleapis.com/css2?family=Prompt:wght@300;500&display=swap',
        fallback: ['sans-serif'],
        fontProvider: 'google',
    },
    {
        fontSetId: 'fontset-work-sans',
        fontSetName: 'Work Sans',
        fontFamily: 'Work Sans',
        fontSetUrl: 'https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;500&display=swap',
        fallback: ['sans-serif'],
        fontProvider: 'google',
    },
];

export const VENDING_MACHINE_DEFAULT_FONT_SET_ID = 'fontset-sofia-pro';

const PRI = { red: 36, green: 36, blue: 36 };
const PRI_DARK = { red: 214, green: 220, blue: 218 };

const REDESIGN_COLORS = {
    emphasize: PRI,

    textSecondary: PRI,
    borderSecondary: PRI,
    borderPrimary: PRI,
    primary: PRI,

    pageBackground: { red: 226, green: 226, blue: 226 },
    textPrimary: { red: 255, green: 255, blue: 255 },
    secondary: { red: 255, green: 255, blue: 255 },
    textDark: { red: 51, green: 53, blue: 76 },
    white: { red: 255, green: 255, blue: 255 },
    error: { red: 175, green: 13, blue: 13 },
};

const REDESIGN_COLORS_DARK = {
    emphasize: PRI_DARK,

    primary: PRI_DARK,
    borderPrimary: PRI_DARK,
    textSecondary: PRI_DARK,
    borderSecondary: PRI_DARK,

    textDark: { red: 214, green: 220, blue: 218 },
    pageBackground: { red: 18, green: 21, blue: 20 },
    textPrimary: { red: 18, green: 21, blue: 20 },
    secondary: { red: 18, green: 21, blue: 20 },
    white: { red: 18, green: 21, blue: 20 },
    error: { red: 175, green: 13, blue: 13 },
};

const makeColors = (emphasize: [number, number, number], primary: [number, number, number], dark?: boolean) => {
    const e = { red: emphasize[0], green: emphasize[1], blue: emphasize[2] };
    const p = { red: primary[0], green: primary[1], blue: primary[2] };

    return {
        ...(dark ? REDESIGN_COLORS_DARK : REDESIGN_COLORS),
        emphasize: e,
        textSecondary: p,
        borderSecondary: p,
        borderPrimary: p,
        primary: p,
    };
};

export const VENDING_MACHINE_COLORS: VendingMachineColorSet[] = [
    {
        colorSetId: 'colorset-redesign-1',
        colorSetName: 'Green 1',
        ...makeColors([45, 141, 127], [1, 32, 48]),
    },
    {
        colorSetId: 'colorset-redesign-6',
        colorSetName: 'Green 2',
        ...makeColors([91, 140, 42], [38, 59, 18]),
    },
    {
        colorSetId: 'colorset-redesign-7',
        colorSetName: 'Green 3',
        ...makeColors([0, 144, 64], [0, 51, 22]),
    },
    {
        colorSetId: 'colorset-redesign-10',
        colorSetName: 'Green 4',
        ...makeColors([36, 144, 98], [10, 41, 29]),
    },
    {
        colorSetId: 'colorset-redesign-2',
        colorSetName: 'Orange',
        ...makeColors([198, 100, 6], [24, 12, 0]),
    },
    {
        colorSetId: 'colorset-redesign-3',
        colorSetName: 'Blue 1',
        ...makeColors([5, 108, 242], [1, 22, 50]),
    },
    {
        colorSetId: 'colorset-redesign-8',
        colorSetName: 'Blue 2',
        ...makeColors([35, 122, 229], [32, 47, 29]),
    },
    {
        colorSetId: 'colorset-redesign-4',
        colorSetName: 'Pink',
        ...makeColors([242, 5, 135], [49, 2, 27]),
    },
    {
        colorSetId: 'colorset-redesign-5',
        colorSetName: 'Red',
        ...makeColors([217, 26, 26], [45, 6, 6]),
    },
    {
        colorSetId: 'colorset-redesign-9',
        colorSetName: 'Magenta',
        ...makeColors([190, 59, 255], [47, 45, 4]),
    },
];

export const VENDING_MACHINE_COLORS_DARK_MODE: VendingMachineColorSet[] = [
    {
        colorSetId: 'colorset-redesign-1',
        colorSetName: 'Redesign 1',
        ...makeColors([45, 141, 127], [242, 254, 230], true),
    },
    {
        colorSetId: 'colorset-redesign-2',
        colorSetName: 'Redesign 2',
        ...makeColors([198, 100, 6], [255, 246, 232], true),
    },
    {
        colorSetId: 'colorset-redesign-3',
        colorSetName: 'Redesign 3',
        ...makeColors([5, 108, 242], [230, 241, 254], true),
    },
    {
        colorSetId: 'colorset-redesign-4',
        colorSetName: 'Redesign 4',
        ...makeColors([242, 5, 135], [254, 231, 243], true),
    },
    {
        colorSetId: 'colorset-redesign-5',
        colorSetName: 'Redesign 5',
        ...makeColors([217, 26, 26], [252, 232, 232], true),
    },
    {
        colorSetId: 'colorset-redesign-6',
        colorSetName: 'Redesign 6',
        ...makeColors([91, 140, 42], [242, 249, 235], true),
    },
    {
        colorSetId: 'colorset-redesign-7',
        colorSetName: 'Redesign 7',
        ...makeColors([0, 144, 64], [230, 255, 241], true),
    },
    {
        colorSetId: 'colorset-redesign-8',
        colorSetName: 'Redesign 8',
        ...makeColors([35, 122, 229], [240, 245, 239], true),
    },
    {
        colorSetId: 'colorset-redesign-9',
        colorSetName: 'Redesign 9',
        ...makeColors([190, 59, 255], [253, 252, 231], true),
    },
    {
        colorSetId: 'colorset-redesign-10',
        colorSetName: 'Redesign 10',
        ...makeColors([36, 144, 98], [235, 250, 244], true),
    },
];

export const VENDING_MACHINE_DEFAULT_COLOR_SET_ID = 'colorset-redesign-10';
