import { createAction, createReducer, Selector } from '@reduxjs/toolkit';

import { Infrastructure, Domain } from 'api';

import { ThunkAction, withPayloadType } from '@/action';
import { authenticationApi, branchApi } from '@/api';
import { logOverviewState } from '@/NightHatch';
import { URLQuery, URLParams } from '@/routing';
import { RootState } from '@/store';

import { selectGlobalSelectedDevice } from './globalSelectionState';

export type ConnectionStatus = 'disconnected' | 'connected' | 'connecting' | 'failed' | 'disconnecting';

export interface State {
    tunnelingJwt?: Infrastructure.JWT.JsonWebToken;
    connectionStatus: ConnectionStatus;
    nightHatch: Domain.NightHatchState;
    branch?: Domain.BranchDetails;
}

const initialNightHatch: Domain.NightHatchState = {
    cart: {
        items: [],
        total: 0,
    },
    requestedFromCustomer: [],
};

const initialState: State = {
    connectionStatus: 'disconnected',
    nightHatch: initialNightHatch,
};

export const reducerActions = {
    setTunnelingJwt: createAction(
        '@vendingMachine/nightHatch/setTunnelingJwt',
        withPayloadType<Infrastructure.JWT.JsonWebToken | undefined>(),
    ),
    setConnectionStatus: createAction('@vendingMachine/nightHatch/setConnectionStatus', withPayloadType<ConnectionStatus>()),
    setNightHatch: createAction('@vendingMachine/nightHatch/setNightHatch', withPayloadType<Domain.NightHatchState>()),
    resetNightHatch: createAction('@vendingMachine/nightHatch/resetNightHatch'),
    setBranch: createAction('@vendingMachine/nightHatch/setBranch', withPayloadType<Domain.BranchDetails>()),
};

export const nightHatchReducer = createReducer(initialState, builder =>
    builder
        .addCase(reducerActions.setTunnelingJwt, (state, action) => {
            state.tunnelingJwt = action.payload;
        })
        .addCase(reducerActions.setConnectionStatus, (state, action) => {
            state.connectionStatus = action.payload;
        })
        .addCase(reducerActions.setNightHatch, (state, action) => {
            state.nightHatch = action.payload;
        })
        .addCase(reducerActions.resetNightHatch, state => {
            state.nightHatch = initialNightHatch;
        })
        .addCase(reducerActions.setBranch, (state, action) => {
            state.branch = action.payload;
        }),
);

export const selectTunnelingJwt: Selector<RootState, Infrastructure.JWT.JsonWebToken> = state => {
    const token = state.device.nightHatch.tunnelingJwt;

    if (!token) {
        throw new Error('Tunneling JWT not loaded');
    }

    return token;
};

export const selectConnectionStatus: Selector<RootState, ConnectionStatus> = state => state.device.nightHatch.connectionStatus;
export const selectNightHatch: Selector<RootState, Domain.NightHatchState> = state => state.device.nightHatch.nightHatch;
export const maybeSelectBranch: Selector<RootState, Domain.BranchDetails | undefined> = state => state.device.nightHatch.branch;

export const loadDeviceSignalingTunnelToken =
    (deviceId: string): ThunkAction =>
    async dispatch => {
        const token = await authenticationApi.GetDeviceSignalingTunnelToken(deviceId);
        dispatch(reducerActions.setTunnelingJwt(token));
    };

export const loadDevice = (): ThunkAction => async (dispatch, getState) => {
    const state = getState();
    const device = selectGlobalSelectedDevice(state);

    if (!device) {
        throw new Error('No selected device');
    }

    await dispatch(loadDeviceSignalingTunnelToken(device.deviceId));
};

export const loadBranch = (): ThunkAction => async (dispatch, getState) => {
    const state = getState();
    const device = selectGlobalSelectedDevice(state);

    if (!device) {
        return;
    }

    await dispatch(reducerActions.setBranch(await branchApi.GetBranchDetails(device.branchId)));
};

export const load =
    (options: { urlQuery: URLQuery; urlParams: URLParams }): ThunkAction =>
    async dispatch => {
        await Promise.all([dispatch(loadDevice()), dispatch(loadBranch()), dispatch(logOverviewState.actions.load(options))]);
    };
export const reload = (): ThunkAction => async dispatch => {
    await dispatch(logOverviewState.actions.setPaginationPage(1));
    await Promise.all([dispatch(loadDevice()), dispatch(loadBranch()), dispatch(logOverviewState.actions.loadCurrentPage())]);
};

export const HandleDisconnect = (): ThunkAction => async (dispatch, getState) => {
    const state = getState();
    const connectionStatus = selectConnectionStatus(state);

    if (connectionStatus === 'disconnecting') {
        await dispatch(reducerActions.setConnectionStatus('disconnected'));
    } else {
        await dispatch(reducerActions.setConnectionStatus('failed'));
    }
};
