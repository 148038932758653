import { EventEmitter } from 'events';

import { ILockStatus, IModuleStatus } from '../Domain';
import { IEventEmitter } from './IEventEmitter';

export enum LockerEvent {
    ModuleStateChange = 'ModuleStateChanged',
    LockStateChanged = 'LockStateChanged',
}

export class LockerEventEmitter
    extends EventEmitter
    implements
        IEventEmitter<{
            ModuleStateChanged: IModuleStatus;
            LockStateChanged: ILockStatus;
        }> {}
