import {
    Pagination,
    ProductCategory,
    ProductCategoriesPage,
    Locale,
    AvailableProductCategory,
    APIAvailableProductCategories,
} from '@/Domain';
import { mapAPICategoriesToCategory } from '@/domainMappers';

import Endpoint from './Endpoint';

export default class ProductCategoryEndpoint extends Endpoint {
    public async GetProductCategories(pagination: Pagination, locale: Locale, search?: string): Promise<ProductCategoriesPage> {
        const response = await this.client.get({
            url: '/available-product/category-overview',
            params: {
                pagination,
                locale,
                search,
            },
        });

        this.guardResponseSchema('#/definitions/ProductCategoriesPage', response);

        return response;
    }

    public async GetProductCategoryDetails(categoryId: ProductCategory['categoryId']): Promise<ProductCategory> {
        const response = await this.client.get({
            url: `/available-product/category-details/${categoryId}`,
        });

        this.guardResponseSchema('#/definitions/ProductCategory', response);

        return response;
    }

    public async GetCategoriesTree(locale?: Locale): Promise<AvailableProductCategory> {
        let url = '/available-product/category-tree';
        if (locale) {
            url = `/available-product/category-tree/${locale}`;
        }

        const response: APIAvailableProductCategories = await this.client.get({
            url,
        });

        this.guardResponseSchema('#/definitions/APIAvailableProductCategories', response);

        return mapAPICategoriesToCategory(response);
    }
}
