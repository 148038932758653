import { createAction, createReducer, Selector } from '@reduxjs/toolkit';

import { Domain } from 'api';

import { ThunkAction, withPayloadType } from '@/action';
import { sharingGroupApi } from '@/api';
import { URLParams } from '@/routing';
import { RootState } from '@/store';

export interface State {
    sharingGroup?: Domain.SharingGroupDetails;
}

const initialState: State = {};

const reducerActions = {
    setSharingGroup: createAction('@sharingGroup/details/setSharingGroup', withPayloadType<Domain.SharingGroupDetails>()),
};

export const detailsReducer = createReducer(initialState, builder =>
    builder.addCase(reducerActions.setSharingGroup, (state, action) => {
        state.sharingGroup = action.payload;
    }),
);

export const selectSharingGroup: Selector<RootState, Domain.SharingGroupDetails | undefined> = state =>
    state.sharingGroup.details.sharingGroup;

export const loadSharingGroup =
    (sharingGroupId: Domain.SharingGroup['sharingGroupId']): ThunkAction =>
    async dispatch => {
        const sharingGroupDetails = await sharingGroupApi.GetSharingGroupDetails(sharingGroupId);
        dispatch(reducerActions.setSharingGroup(sharingGroupDetails));
    };

export const load =
    (options: { urlParams: URLParams }): ThunkAction =>
    async dispatch => {
        await dispatch(loadSharingGroup(options.urlParams.sharingGroupId));
    };
