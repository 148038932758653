import { createAction, createReducer as createReduxReducer, combineReducers, Selector } from '@reduxjs/toolkit';

import { Domain } from 'api';

import { ThunkAction, withPayloadType } from '@/action';
import { productWallApi } from '@/api';
import * as categoriesTreeState from '@/AvailableProduct/categoriesTreeState';
import { loadConfiguredIntegrationsCountByType } from '@/Integration/configuredIntegrationsTypeState';
import {
    makeSelectors,
    makeActions,
    makeReducerActions,
    makeReducer,
    OverviewState,
    initialState,
} from '@/ProductSelection/makeOverviewState';
import { URLParams } from '@/routing';
import { RootState } from '@/store';

export const productSelectors = makeSelectors({
    getState: rootState => rootState.productWall.update.products,
});

const productReducerActions = makeReducerActions({
    reducerPrefix: '@productWall/update/products',
});

export const productActions = makeActions(
    {
        dataTableSaveKey: 'updateProductWallProducts-v3',
        reducerActions: productReducerActions,
        selectors: productSelectors,
    },
    'inStoreProductSelection',
);

const productReducer = makeReducer({
    reducerActions: productReducerActions,
    initialState: {
        ...initialState,
        completeness: 'suitedForProductWall',
    },
});

export interface ProductWallState {
    productWall?: Domain.ProductWall;
}

const initialProductWallState: ProductWallState = {};

export interface State {
    main: ProductWallState;
    products: OverviewState;
}

export const reducerActions = {
    setProductWall: createAction('@productWall/update/setProductWall', withPayloadType<Domain.ProductWall | undefined>()),
};

export const updateReducer = combineReducers({
    main: createReduxReducer(initialProductWallState, builder =>
        builder.addCase(reducerActions.setProductWall, (state, action) => {
            state.productWall = action.payload;
        }),
    ),
    products: productReducer,
});

export const selectProductWall: Selector<RootState, Domain.ProductWall> = state => {
    const productWall = state.productWall.update.main.productWall;

    if (!productWall) {
        throw new Error('Product wall not loaded');
    }

    return productWall;
};

export const loadProductWall =
    (productWallId: string): ThunkAction<Promise<Domain.ProductWall>> =>
    async dispatch => {
        const productWallDetails = await productWallApi.GetProductWallDetails(productWallId);
        dispatch(reducerActions.setProductWall(productWallDetails));
        return productWallDetails;
    };

export const load =
    (options: { urlParams: URLParams }): ThunkAction =>
    async dispatch => {
        const productWall = await dispatch(loadProductWall(options.urlParams.productWallId));
        if (options.urlParams.step === 'select-products') {
            await Promise.all([
                dispatch(productActions.loadProducts(false, productWall.locale)),
                dispatch(
                    categoriesTreeState.actions.load({
                        ...options,
                        urlParams: {
                            locale: productWall.locale,
                            ...options.urlParams,
                        },
                    }),
                ),
            ]);
        }

        if (options.urlParams.step === 'designer' || options.urlParams.step === 'select-products') {
            await dispatch(loadConfiguredIntegrationsCountByType());
        }
    };
