import { createAction, Selector } from '@reduxjs/toolkit';

import { Domain } from 'api';

import { ThunkAction, withPayloadType } from '@/action';
import { webshopOrderApi, branchApi } from '@/api';
import { loadConfiguredIntegrationsByType } from '@/Integration/configuredIntegrationsTypeState';
import { makeActions, makeReducer, makeReducerActions, makeSelectors } from '@/makeDetailsState';
import * as approvedEmailAddressesState from '@/OrderReview/approvedEmailAddressesState';
import { URLParams, URLQuery } from '@/routing';
import { RootState } from '@/store';

export const setBranch = createAction('@webshopOrder/details/setBranch', withPayloadType<Domain.Branch>());
export const selectBranch: Selector<RootState, Domain.Branch | undefined> = state => state.webshopOrder.details.branch;

export const selectors = makeSelectors<Domain.WebshopOrderDetails>({
    getState: rootState => rootState.webshopOrder.details,
});

export const reducerActions = makeReducerActions<Domain.WebshopOrderDetails>({
    reducerPrefix: '@webshopOrder/details',
});

const baseActions = makeActions<Domain.WebshopOrderDetails>({
    loadApi: options => webshopOrderApi.GetWebshopOrderDetails(options.urlParams.webshopId, options.urlParams.orderId),
    reducerActions,
    selectors,
});

export const detailsReducer = makeReducer<
    Domain.WebshopOrderDetails,
    {
        branch?: Domain.Branch;
    }
>({
    reducerActions,
    extraCases: builder =>
        builder.addCase(setBranch, (state, action) => {
            state.branch = action.payload;
        }),
});

export const actions = {
    ...baseActions,
    load:
        (options: { urlParams: URLParams; urlQuery: URLQuery }): ThunkAction =>
        async dispatch => {
            const [order] = await Promise.all([
                dispatch(baseActions.load(options)),
                dispatch(loadConfiguredIntegrationsByType('deliverPickups')),
                dispatch(approvedEmailAddressesState.actions.load(options)),
            ]);

            if (order) {
                const branch = await branchApi.GetBranchDetails(order.branchId);
                dispatch(setBranch(branch));
            }
        },
};
