import * as React from 'react';

import { InputBaseProps } from '@/core';

import ControlledHTMLEditor, { ControlledHTMLEditorWithValue } from './ControlledHTMLEditor';
import UncontrolledHTMLEditor, { UncontrolledHTMLEditorProps } from './UncontrolledHTMLEditor';

type HTMLEditorProps = ControlledHTMLEditorWithValue | UncontrolledHTMLEditorProps;

class HTMLEditor extends React.PureComponent<HTMLEditorProps> {
    render() {
        if (this.isControlled(this.props)) {
            return <ControlledHTMLEditor {...this.props} />;
        }

        return <UncontrolledHTMLEditor {...this.props} />;
    }

    private isControlled(props: HTMLEditorProps): props is ControlledHTMLEditorWithValue {
        return (props as ControlledHTMLEditorWithValue).hasOwnProperty('value');
    }
}

export default React.forwardRef<HTMLInputElement, HTMLEditorProps & InputBaseProps>((props, ref) => {
    return (
        <HTMLEditor
            forwardRef={ref}
            {...props}
        />
    );
});
