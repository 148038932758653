import * as React from 'react';

import moment from 'moment';

import ControlledDateTimePicker, { ControlledDateTimePickerProps } from './ControlledDateTimePicker';

export type UncontrolledDateTimePickerProps = ControlledDateTimePickerProps & {
    defaultValue?: moment.Moment;
};

interface IState {
    value: moment.Moment | null;
}

class UncontrolledDateTimePicker extends React.PureComponent<UncontrolledDateTimePickerProps, IState> {
    constructor(props: UncontrolledDateTimePickerProps) {
        super(props);

        this.state = {
            value: props.defaultValue || null,
        };
    }

    render() {
        const { children, defaultValue, onChange, onChangeComplete, forwardRef, ...rest } = this.props;

        return (
            <ControlledDateTimePicker
                {...rest}
                ref={forwardRef}
                value={this.state.value || null}
                onChange={this.handleChange}
                onChangeComplete={this.handleChangeComplete}
            >
                {children}
            </ControlledDateTimePicker>
        );
    }

    protected handleChange = (newValue: moment.Moment): void => {
        const { onChange } = this.props;

        this.setState(
            {
                value: newValue,
            },
            () => {
                if (onChange && this.state.value !== null) {
                    onChange(this.state.value);
                }
            },
        );
    };

    protected handleChangeComplete = (newValue: moment.Moment): void => {
        const { onChangeComplete } = this.props;

        this.setState(
            {
                value: newValue,
            },
            () => {
                if (onChangeComplete && this.state.value !== null) {
                    onChangeComplete(this.state.value);
                }
            },
        );
    };
}

export default React.forwardRef<HTMLInputElement, UncontrolledDateTimePickerProps>((props, ref) => {
    return (
        <UncontrolledDateTimePicker
            forwardRef={ref}
            {...props}
        />
    );
});
