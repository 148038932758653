export function scrollLabelIntoView(node: Element, depth = 0, originalNode?: Element) {
    if ((node === document.documentElement || depth === 10) && originalNode) {
        originalNode.scrollIntoView();
    } else if (((node.tagName && node.tagName.toLowerCase() === 'label') || !node.parentElement) && node.scrollIntoView) {
        node.scrollIntoView();
    } else if (node.parentElement) {
        scrollLabelIntoView(node.parentElement, depth + 1, originalNode || node);
    }
}

export function injectStyle(css: string, id: string) {
    let style: HTMLStyleElement | null = document.getElementById(id) as HTMLStyleElement;
    if (!style) {
        style = document.createElement('style');
        style.id = id;
        style.type = 'text/css';
        document.head.appendChild(style);
        style.appendChild(document.createTextNode(css));
    }
}
