import {
    Pagination,
    Sorting,
    Locale,
    WebshopNewsArticlesPage,
    WebshopNewsArticleDetails,
    CreateWebshopNewsArticlePayload,
    UpdateWebshopNewsArticlePayload,
} from '@/Domain';

import Endpoint from './Endpoint';

export default class WebshopNewsArticleEndpoint extends Endpoint {
    public async GetWebshopNewsArticles(
        pagination: Pagination,
        sorting: Sorting,
        webshopId: string,
        locale: Locale,
        search?: string,
        filters?: {
            activeStatus?: string;
            authorUserId?: string;
            without?: string;
        },
    ): Promise<WebshopNewsArticlesPage> {
        const url = `/webshop/${webshopId}/article/overview/${locale}`;

        const response = await this.client.get({
            url,
            params: {
                pagination,
                sorting,
                search,
                filters,
            },
        });

        this.guardResponseSchema('#/definitions/WebshopNewsArticlesPage', response);

        return response;
    }

    public async GetWebshopNewsArticleDetails(webshopId: string, articleId: string): Promise<WebshopNewsArticleDetails> {
        const response = await this.client.get({
            url: `/webshop/${webshopId}/article/${articleId}/details`,
        });

        this.guardResponseSchema('#/definitions/WebshopNewsArticleDetails', response);

        return response;
    }

    public async CreateWebshopNewsArticle(payload: CreateWebshopNewsArticlePayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/CreateWebshopNewsArticle',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Webshop\\Blog\\UpdateArticle\\UpdateArticle',
                payload,
            },
        });
    }

    public async UpdateWebshopNewsArticle(payload: UpdateWebshopNewsArticlePayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/UpdateWebshopNewsArticle',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Webshop\\Blog\\UpdateArticle\\UpdateArticle',
                payload,
            },
        });
    }
    public async RemoveWebshopNewsArticle({ articleId, webshopId }: { articleId: string; webshopId: string }): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/RemoveArticle',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Webshop\\Blog\\RemoveArticle\\RemoveArticle',
                payload: {
                    articleId,
                    webshopId,
                },
            },
        });
    }
}
