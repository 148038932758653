import { combineReducers } from '@reduxjs/toolkit';

import { availableCountriesReducer } from './availableCountriesState';
import { detailsReducer } from './detailsState';
import { overviewReducer } from './overviewState';

export const localisationReducer = combineReducers({
    overview: overviewReducer,
    details: detailsReducer,
    availableCountries: availableCountriesReducer,
});
