import * as React from 'react';

export interface CheckboxListContextBase {
    name?: string;
    onChange?: (checked: boolean, value?: string) => void;
    disabled?: boolean;
    value: any;
}

export type CheckboxListContext = CheckboxListContextBase & {
    value: string[];
};

export const CheckboxListContext = React.createContext<CheckboxListContext>({ value: [] });
