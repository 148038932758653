import { createAction, createReducer, Selector } from '@reduxjs/toolkit';

import { Domain } from 'api';

import { ThunkAction, withPayloadType } from '@/action';
import { productSelectionApi, integrationApi } from '@/api';
import { selectLoggedInUserOwnership } from '@/Authentication';
import { selectCurrentLocale } from '@/I18n/selectors';
import { URLParams } from '@/routing';
import { RootState } from '@/store';

export interface State {
    selectedProduct?: Domain.SelectedProductDetails | Domain.CustomProductDetails;
    configuredIntegrationsCountByType: Domain.IntegrationsCountByType | undefined;
}

const initialState: State = {
    configuredIntegrationsCountByType: undefined,
};

const reducerActions = {
    setSelectedProduct: createAction(
        '@productSelection/details/setSelectedProduct',
        withPayloadType<Domain.SelectedProductDetails | Domain.CustomProductDetails>(),
    ),
    setConfiguredIntegrationsCountByType: createAction(
        '@productSelection/details/setConfiguredIntegrationsCountByType',
        withPayloadType<Domain.IntegrationsCountByType>(),
    ),
};

export const detailsReducer = createReducer(initialState, builder =>
    builder
        .addCase(reducerActions.setSelectedProduct, (state, action) => {
            state.selectedProduct = action.payload;
        })
        .addCase(reducerActions.setConfiguredIntegrationsCountByType, (state, action) => {
            state.configuredIntegrationsCountByType = action.payload;
        }),
);

export const selectProduct: Selector<RootState, Domain.SelectedProductDetails | Domain.CustomProductDetails> = state => {
    const selectedProduct = state.productSelection.details.selectedProduct;

    if (!selectedProduct) {
        throw new Error('Product details not loaded');
    }

    return selectedProduct;
};

export const selectConfiguredIntegrationsCountByType: Selector<RootState, undefined | Domain.IntegrationsCountByType> = state =>
    state.productSelection.details.configuredIntegrationsCountByType;

export const loadSelectedProductDetails =
    (selectedProductId: string, selectionType: Domain.ProductSelectionType): ThunkAction =>
    async (dispatch, getState) => {
        const state = getState();
        const ownership = selectLoggedInUserOwnership(state);
        const locale = selectCurrentLocale(state);

        const selectedProductDetails = await productSelectionApi.GetSelectedProductDetails(
            ownership,
            selectedProductId,
            selectionType,
            locale,
        );
        dispatch(reducerActions.setSelectedProduct(selectedProductDetails));
    };

export const loadConfiguredIntegrationsCountByType = (): ThunkAction => async (dispatch, getState) => {
    const state = getState();
    const ownership = selectLoggedInUserOwnership(state);

    if (ownership.type === 'company' || ownership.type === 'branch') {
        const configuredIntegrationsCountByType = await integrationApi.GetConfiguredIntegrationsCountByType(ownership);
        dispatch(reducerActions.setConfiguredIntegrationsCountByType(configuredIntegrationsCountByType));
    }
};

export const load =
    (options: { urlParams: URLParams }): ThunkAction =>
    async (dispatch, getState) => {
        const state = getState();

        const type = options.urlParams.type;
        if (Domain.ProductSelectionTypes.indexOf(type) === -1) {
            throw new Error('Wrong selection type');
        }

        const promises = [dispatch(loadConfiguredIntegrationsCountByType())];

        if (
            !state.productSelection.details.selectedProduct ||
            state.productSelection.details.selectedProduct.productId !== options.urlParams.selectedProductId
        ) {
            promises.push(dispatch(loadSelectedProductDetails(options.urlParams.selectedProductId, type)));
        }

        await Promise.all(promises);
    };
