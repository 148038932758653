import { Pagination, Sorting, StockReplenishPage, Locale } from '@/Domain';

import Endpoint from './Endpoint';

export default class StockReplenishEndpoint extends Endpoint {
    public async GetStockReplenish(
        channelType: string,
        pagination: Pagination,
        sorting: Sorting,
        locale: Locale,
        search?: string,
        filters?: {
            productIds?: string;
            brandIds?: string;
            productCodes?: string;
            categoryIds?: string;
            organisationIds?: string;
            onHomeScreen?: string;
        },
    ): Promise<StockReplenishPage> {
        const url = `/stock-replenish/${channelType}/overview`;

        const stockReplenishList = await this.client.get({
            url,
            params: {
                pagination,
                sorting,
                search,
                locale,
                filters: {
                    ...filters,
                    categoryIds: filters && filters.categoryIds ? filters.categoryIds.split(',') : undefined,
                    organisationIds: filters && filters.organisationIds ? filters.organisationIds.split(',') : undefined,
                    brandIds: filters && filters.brandIds ? filters.brandIds.split(',') : undefined,
                    onHomeScreen: filters?.onHomeScreen === 'true' ? true : undefined,
                    productCodes: filters && filters.productCodes ? filters.productCodes.split(',') : undefined,
                },
            },
        });
        this.guardResponseSchema('#/definitions/StockReplenishPage', stockReplenishList);
        return stockReplenishList;
    }
}
