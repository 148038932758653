export const PRODUCT_CODES_FOR_SIMULATED_LOCKERS = [
    '5413134003772',
    '5413134001020',
    '5410063036352',
    '5407005690239',
    '5413134804805',
    '5407005693100',
    '5413134802795',
    '5430000531238',
    '3701145600182',
    '3202983',
];

export const PRODUCT_CODES_FOR_SIMULATED_STANDALONE_MATIC = [
    // START for E2E tests
    '1245789865325',
    '9874563216969',
    '8585858585454',
    '3282770148435',
    '9519518565456',
    '4419518565456',

    // END
    '5413134003772',
    '5413134001020',
    '5410063036352',
    '5407005690239',
    '5413134804805',
    '5425012104426',
    '8436008300231',
    '5400433238097',
    '4051895037851',
    '5400433261866',
    '5430002883007',
    '4051895036847',
    '5425036460973',
    '5430000724128',
    '7613037041033',
    '3401561859286',
    '3760293230283',
    '5430002936093',
    '5060014059970',
    '5016533647662',
    '5016533635430',
    '5420050400028',
    '5430002936642',
    '5430002936116',
    '5425013130059',
    '366439100024',
    '3760302222797',
    '4051895034669',
    '5407005693063',
    '4003053094718',
    '5400433265390',
    '5430002936321',
    '5413134804072',
    '5400433276525',
    '5407004530239',
    '3701052009542',
    '3551102127548',
    '5400433273999',
    '5425039527512',
    '4051895024448',
    '5425039527185',
    '4004148057069',
    '5425000677710',
    '3578835500684',
    '5400433225196',
    '3700306932506',
    '5425039527222',
    '5400433277188',
    '3760293230252',
    '5407005692738',
    '3578835500608',
    '5425026030452',
    '8712400159500',
    '8713975020011',
    '5413134802382',
    '5419980116178',
    '3701052008361',
    '3401528538766',
    '4051895033440',
    '8713975600022',
    '5414963023665',
    '5419980116123',
    '5425039528069',
    '5016533642964',
    '8422947282585',
    '3760293230146',
    '5420024602168',
    '5413134000061',
    '4051895036656',
    '5420024604186',
    '5400433309636',
    '5414963021906',
    '5407005690949',
    '5425026030032',
    '5430002883038',
    '5430002936710',
    '7613035828254',
    '5413134003789',
    '5060014053282',
    '5425039527161',
    '4051895006604',
    '4051895034454',
    '8712400748063',
    '4051895035680',
    '5413134002270',
    '8716900550898',
    '3578835503395',
    '4051895034751',
    '5060385940457',
    '5413134001679',
    '868176000176',
    '5425012104396',
    '5425039527604',
    '5391520942457',
    '3401543539885',
    '5425003042461',
    '5430002936192',
    '3578835500653',
    '5425039527353',
    '8008698023723',
    '5430002936765',
    '4051895033211',
    '5430002936628',
    '5420024602144',
    '8715345004447',
    '5400433296219',
    '8008698003688',
    '5016533648294',
    '5400706613842',
    '5400433180921',
    '5420050400592',
    '5016533637939',
    '5413134002850',
    '5407005693339',
    '5407005691427',
    '3401599014206',
    '5400433277256',
    '8716900575143',
    '3578835502503',
    '5391520945755',
    '768990016806',
    '3578835500660',
    '5430002883021',
    '5407005692783',
    '3701052009641',
    '5413134001525',
    '3700790022141',
    '7442924645678',
    '5414963021890',
    '3578835500691',
    '5400433286302',
    '8716900550904',
    '3578836010243',
    '5420008534881',
    '3578836010205',
    '3401252051784',
    '3760293230535',
    '5413134380361',
    '4086000011693',
    '4051895035437',
    '8716900573965',
    '5407005692776',
    '8713975600039',
    '5413134002386',
    '5430002388533',
    '3700225640834',
    '5060385943052',
    '8713975443360',
    '5407005692318',
    '3664524000273',
    '868176000107',
    '5430002388144',
    '5425039527192',
    '5425026030018',
    '3770008244238',
    '5400433270004',
    '5430002936697',
    '4051895035536',
    '5400433269985',
    '5413134000634',
    '6418029905314',
    '8713975600008',
    '8008698041208',
    '5391520944291',
    '5400433227770',
    '5060014059840',
    '5400706618007',
    '4051895012384',
    '3401248112383',
    '4051895028255',
    '3760293230276',
    '5400706617680',
    '5425012104440',
    '3401560243246',
    '5430002936277',
    '4086000017121',
    '5425039526232',
    '7436943960966',
    '8430433000179',
    '5425039527130',
    '8713975443247',
    '2250000000144',
    '5060014051646',
    '3578836010083',
    '5413134001334',
    '3578835500639',
    '5400706612104',
    '5400433263259',
    '7613032832179',
    '3701132600874',
    '5016533647099',
    '868176000152',
    '5016533647693',
    '5407005692080',
    '3760302221912',
    '5016533627848',
    '3701052000334',
    '4051895036403',
    '4051895005539',
    '5060014059918',
    '3760293230108',
    '8715345005178',
    '3760289220595',
    '5407005692912',
    '7613037036329',
    '5420050400196',
    '5414963021203',
    '4051895027531',
    '5430003465059',
    '5420008542589',
    '4051895035406',
    '8716900574122',
    '4051895038063',
    '3401546602364',
    '4051895034812',
    '5400433227763',
    '5413134803556',
    '3578836010120',
    '4051895034997',
    '5414963021197',
    '7611136102723',
    '5413134302660',
    '5425026031008',
    '5400706205603',
    '3578830129231',
    '5413134001143',
    '3701052009597',
    '5407005693117',
    '5400433197516',
    '5400618108290',
    '5400433273982',
    '5413134001518',
    '4003053091748',
    '4051895035055',
    '5425039528250',
    '4051895034935',
    '5400433275306',
    '5425035670434',
    '5407005691892',
    '5400706613248',
    '5413134799903',
    '5400433228609',
    '5413134001242',
    '8720648370052',
    '8713975444299',
    '5413134001655',
    '4051895030104',
    '5400433250228',
    '3401560504477',
    '5400433280034',
    '5016533634068',
    '5410063012561',
    '5407005692806',
    '8436571630353',
    '5407005693070',
    '3700790021359',
    '5425012104389',
    '3551100752193',
    '5407005691328',
    '5407005690253',
    '5425026030582',
    '5420024601758',
    '3700225640674',
    '4051895035345',
    '5400433306017',
    '4086000007818',
    '5400433270448',
    '5413134002461',
    '3760293230528',
    '5430002936260',
    '5425039528298',
    '5413134002089',
    '7613033125188',
    '4051895016054',
    '5425039527697',
    '3701052006800',
    '5016533065336',
    '5400433180907',
    '5400433309643',
    '4051895028170',
    '5413134803389',
    '8716900573729',
    '5400706612203',
    '5425039527994',
    '5400618100430',
    '3455270811172',
    '5407005691434',
    '5060385940372',
    '4005800030727',
    '4051895037790',
    '4051895035376',
    '5430002936833',
    '7613035725799',
    '5410063036338',
    '5016533627985',
    '3700225640773',
    '3401572637545',
    '4008976413349',
    '5400433297452',
    '8714266104045',
    '5412360020959',
    '3700790001092',
    '8424409309748',
    '5400433269831',
    '4051895005348',
    '5407005693346',
    '5400433279052',
    '5400433181270',
    '5400433046173',
    '5413134002263',
    '5407011031477',
    '5413134001211',
    '3578835502688',
    '5413134798401',
    '4051895012407',
    '3760293230399',
    '5420008527654',
    '5413134804843',
    '5425039528083',
    '5400433270158',
    '5016533092219',
    '5425039527550',
    '5060385940112',
    '5430001990034',
    '868176000114',
    '4051895035086',
    '3700790001573',
    '5413134804898',
    '5400433306031',
    '4008976681236',
    '3760293230214',
    '5413134003796',
    '4051895014879',
    '5400433277249',
    '4051895036373',
    '5413134001136',
    '5400618108740',
    '5413134796377',
    '5413134002911',
    '8437019515263',
    '5425039528212',
    '4051895012438',
    '4051895042565',
    '8429420107526',
    '8436008300217',
    '4051895006581',
    '8436571631336',
    '3400935874191',
    '5400433180914',
    '5413134802405',
    '8712400157506',
    '5400272530703',
    '5425000677611',
    '5413134002904',
    '5407005690246',
    '4051895042534',
    '3700225640605',
    '5425039527260',
    '3401572628321',
    '8720648370069',
    '4051895035789',
    '5425003041372',
    '7613033125157',
    '4051895035758',
    '5430000545600',
    '4051895035871',
    '5413134003413',
    '5400433260388',
    '5413134002874',
    '4051895024349',
    '5400706613194',
    '7613032489694',
    '5413134001617',
    '5407009330155',
    '4051895036311',
    '5425012104433',
    '7613033450105',
    '5407004530253',
    '5413134003031',
    '5413134804102',
    '5412360001866',
    '5413134001167',
    '4051895035604',
    '7613035791237',
    '8437019515232',
    '8720648370113',
    '5060014059246',
    '3701052009580',
    '5413134803037',
    '5400433218747',
    '4051895012421',
    '5430002883014',
    '3401547941035',
    '3701056801203',
    '5400433277157',
    '5413134804782',
    '3578835502220',
    '5413134804089',
    '5420024601284',
    '5400706613149',
    '8712400157544',
    '5400433281253',
    '5400272562704',
    '4051895036908',
    '3401560273274',
    '5430001886047',
    '3401545071383',
    '8716900550928',
    '8422947111410',
    '4051895035284',
    '3700225640780',
    '5400433212219',
    '4051895023434',
    '5407005691465',
    '5407005692189',
    '5400433230565',
    '5430002883137',
    '5407005692950',
    '3701052009573',
    '5021807320738',
    '4051895033525',
    '5425014922332',
    '4051895036342',
    '4051895042633',
    '5430002936956',
    '5400433305980',
    '5413134800296',
    '5420045703844',
    '4051895035253',
    '4001638502535',
    '8717438010182',
    '5413134001440',
    '4051895034485',
    '7613035692060',
    '5425039527277',
    '5400706613286',
    '5407005690932',
    '5400433167113',
    '5407005692134',
    '3700225640940',
    '8429420160576',
    '3401564685431',
    '4051895035642',
    '5425039527291',
    '5400433261903',
    '5412360020911',
    '761303272054',
    '4051895012452',
    '5430002936901',
    '8713975010043',
    '5400433277379',
    '5400433034903',
    '5400433272992',
    '5400433280201',
    '8429420192249',
    '4008976597452',
    '5413134801941',
    '5400706618175',
    '5391520941771',
    '8720648370083',
    '5400433277331',
    '5413134002034',
    '5407004530185',
    '5430000531504',
    '3401546907056',
    '5410063012547',
    '5391520942440',
    '5413134001198',
    '5425026030971',
    '5413134003321',
    '5425018610174',
    '5425034191015',
    '5400433245293',
    '5413134003819',
    '5400272564463',
    '5425026030643',
    '5707390120619',
    '5425039527543',
    '5016533627947',
    '5425039528106',
    '5400706205573',
    '5413134420166',
    '4051895028330',
    '5413134798456',
    '5413134003765',
    '5400433276174',
    '5400618108757',
    '4051895023564',
    '3401248113274',
    '5407004530086',
    '4051895042510',
    '5425039527123',
    '5410063018457',
    '5425000677734',
    '4051895029672',
    '3662361001187',
    '5413134002935',
    '5407005693049',
    '5407005692752',
    '5400433257784',
    '4051895016634',
    '4051895034904',
    '7613035683952',
    '5425012104488',
    '5400706614641',
    '5060014059536',
    '5420024601291',
    '3578835500561',
    '5016533627572',
    '5413134000801',
    '5410063018464',
    '5430002936512',
    '5400618107040',
    '5016533637748',
    '5413134798241',
    '5430002936437',
    '4051895032221',
    '5016533633887',
    '5413134804751',
    '5400433255810',
    '4051895038223',
    '4051895034843',
    '5400433274811',
    '3770008244221',
    '3664524000808',
    '5016533627558',
    '3578835500646',
    '4051895023540',
    '5400618106999',
    '4051895034638',
    '5060014055958',
    '5413134804881',
    '5400706617789',
    '5400433276181',
    '5414963022262',
    '4051895034515',
    '3701052008378',
    '4051895037615',
    '8008698002223',
    '3578835502664',
    '8715345004805',
    '3578835500523',
    '5021807323043',
    '5414963021043',
    '7613032490058',
    '4051895035499',
    '768990807435',
    '5400433277164',
    '5016533632729',
    '8716900554933',
    '4004148017179',
    '5400433064924',
    '5400433180938',
    '5413134799347',
    '5413134002959',
    '5425003042386',
    '5425026030711',
    '5400706612401',
    '5425012104358',
    '5413134003024',
    '4051895036779',
    '5425039528274',
    '8713975443414',
    '5016533411102',
    '8713975443520',
    '5413134000054',
    '5414963022217',
    '5425003041365',
    '3760293230177',
    '3401545245791',
    '5016533646498',
    '3401170179089',
    '5400433221303',
    '5430000545693',
    '4086000007801',
    '5413134001327',
    '5413134804836',
    '5400433228708',
    '5391520945786',
    '3700790001405',
    '5400433218167',
    '5400433291047',
    '8720648370120',
    '8712400763004',
    '8713975443179',
    '4004148059018',
    '3700225640896',
    '5430002936840',
    '5425039527611',
    '5430002936161',
    '5413134803051',
    '5413134003826',
    '4051895036991',
    '3456951170823',
    '5400433277287',
    '5400433284612',
    '5413134804119',
    '5400433277348',
    '5016533629293',
    '4051895034782',
    '5407005690321',
    '5407005693155',
    '5430001990058',
    '8716900573842',
    '5400433273098',
    '5425003042270',
    '8720648370076',
    '5425039527284',
    '4051895034966',
    '5419980116109',
    '5016533631951',
    '5430002936024',
    '8715345005147',
    '5400433228586',
    '8720648370090',
    '4051895025636',
    '4051895042664',
    '5413134798692',
    '5420050400141',
    '5413134000443',
    '5400433215227',
    '5407005691908',
    '4051895035468',
    '5407011031514',
    '4051895036816',
    '5425000677321',
    '4008976599265',
    '4051895006598',
    '8008698020142',
    '5413134001310',
    '5425012104549',
    '4051895036618',
    '5413134002515',
    '4051895010434',
    '5425012767102',
    '7613036531474',
    '4051895037899',
    '3578835502671',
    '8716900574580',
    '8713975443346',
    '4051895037714',
    '8424409306457',
    '5400433028209',
    '5400706614689',
    '5400433263280',
    '5413134798463',
    '8712400157193',
    '8720648370038',
    '5425026030520',
    '5413134002928',
    '2200000005137',
    '4051895033280',
    '5400433212202',
    '3770001879000',
    '5400433303078',
    '4051895039107',
    '5425039527239',
    '5413134002027',
    '4051895024189',
    '5413134000610',
    '7613287804396',
    '5420024601734',
    '5413134802351',
    '5425012104341',
    '5413134424263',
    '8720648370021',
    '3760293230191',
    '5420050400578',
    '5413134001563',
    '5400433263266',
    '8716900574467',
    '3700225640735',
    '4051895034874',
    '5400433279076',
    '4051895034577',
    '5407004530345',
    '5016533631999',
    '7613037041286',
    '4051895033815',
    '4051895022970',
    '3700225640520',
    '5400433277171',
    '5016533634044',
    '5413134803532',
    '3701056803214',
    '5400272564937',
    '4051895023458',
    '5413134796407',
    '5430001886030',
    '5400433276518',
    '5400433234167',
    '8445290734396',
    '5400706613279',
    '4051895035901',
    '3401260948298',
    '5425018610099',
    '5016533655292',
    '5407005691380',
    '8713975600015',
    '5425039527628',
    '7613035683938',
    '5425012104815',
    '5400433285060',
    '5425025502639',
    '4051895036694',
    '4051895037691',
    '8720648370007',
    '3578835501186',
    '5400433265406',
    '5425039527635',
    '5400433261880',
    '5425025503421',
    '5400272558509',
    '5400433223611',
    '4051895026596',
    '5407005693162',
    '3551102086715',
    '5400433272275',
    '4051895026473',
    '5413134001228',
    '4051895027715',
    '3401560047585',
    '3770008374065',
    '5413134798449',
    '5400433279557',
    '5412360017911',
    '5400618106944',
    '5419980116116',
    '5413134002492',
    '4086000009935',
    '5413134804829',
    '5407005693186',
    '5425000677680',
    '5400433240212',
    '8715345004874',
    '5016533623833',
    '780053034367',
    '8712400157186',
    '7613033303128',
    '4051895035840',
    '5425039527598',
    '5413134002423',
    '8713975443339',
    '8713975600053',
    '5412360021123',
    '3701145690206',
    '5420008528019',
    '5413134001723',
    '5400433212240',
    '5420008531446',
    '5400618106654',
    '5060014058546',
    '3282770203813',
    '3664391000031',
    '5420024604131',
    '5414963022286',
    '5407005692172',
    '4051895026619',
    '5391520942464',
    '4051895033402',
    '7613039548332',
    '3578835502640',
    '5413134002300',
    '5413134802672',
    '5400272560915',
    '3578835500585',
    '5400272555898',
    '4051895037837',
    '8008698041253',
    '5400706618182',
    '5425026030483',
    '5400433272213',
    '5413134001235',
    '5425039527925',
    '5420024601680',
    '8715345004638',
    '5425025503186',
    '5400433281215',
    '8426420003858',
    '8719324246866',
    '4051895039169',
    '5425039527253',
    '5400272562650',
    '3760289220694',
    '5425039528236',
    '8716900574245',
    '3401579811139',
    '5400433282908',
    '5425003042478',
    '5413134804065',
    '4003053091472',
    '5400433281314',
    '5400433263273',
    '4086000017831',
    '5407005693056',
    '5425000677758',
    '8713975600046',
    '5413134001341',
    '5425012104471',
    '3701052009689',
    '5413134001006',
    '4051895035024',
    '5016533364728',
    '8713975443513',
    '5413134001716',
    '3664524000198',
    '3578835502459',
    '5400706618038',
    '5400433272268',
    '8716900579455',
    '5413134002348',
    '4051895034690',
    '3701052008354',
    '5407005693032',
    '5413134798678',
    '5413134003451',
    '5016533631975',
    '5419980116192',
    '3401548978146',
    '5407005691373',
    '8715345005215',
    '4051895008219',
    '5430002936420',
    '5425039528229',
    '8715345004836',
    '7613034727091',
    '4051895034546',
    '7640111631644',
    '5413134001266',
    '3578835502619',
    '5400433181058',
    '5410063018433',
    '5400433167021',
    '5407005692998',
    '3578836010236',
    '5425025501984',
    '5016533627930',
    '780053034336',
    '5400951001098',
    '5060014059857',
    '3760293230184',
    '8716900577857',
    '8715345004959',
    '4051895037653',
    '5413134802955',
    '8712400151702',
    '5391520942471',
    '8720648370151',
    '5425039527451',
    '4003053091502',
    '5400433285268',
    '3401548978207',
    '4008976671664',
    '4051895035819',
    '8715345005789',
    '5414963009546',
    '5400706613361',
    '8716900554872',
    '5413134002072',
    '5430002936338',
    '5400706205719',
    '5016533633481',
    '5420024600072',
    '5407005692769',
    '5425039527246',
    '3401560183368',
    '5430002936079',
    '5400433240199',
    '5430002173184',
    '5400618108726',
    '5425014925135',
    '3401571481194',
    '4051895037677',
    '8716900557736',
    '8716900574344',
    '8482829457562',
    '5413134000023',
    '5413134000092',
    '8422947285807',
    '5407005693193',
    '5407005692790',
    '5413134002898',
    '3401551004856',
    '4008976680291',
    '5425037920162',
    '4008169203009',
    '5410151050802',
    '5400272560939',
    '5410038122950',
    '8716900550881',
    '4051895024288',
    '8716900569029',
    '5400706614603',
    '4051895037592',
    '8716900550874',
    '3578836010229',
    '4051895014883',
    '5414963021586',
    '8001040100820',
    '4051895036731',
    '5400618108351',
    '5407005693124',
    '5400433164006',
    '3701132601024',
    '4051895038209',
    '5016533647709',
    '4051895035727',
    '8719327555958',
    '5425039528090',
    '5400433164013',
    '5400706614665',
    '5410063003064',
    '8712400686808',
    '3401248113793',
    '5407005693100',
    '5413134802795',
    '5430000531238',
    '3701145600182',
];
