import { createAction, Selector, createReducer } from '@reduxjs/toolkit';

import { Domain } from 'api';

import { ThunkAction, withPayloadType } from '@/action';
import { webshopApi, webshopPromotionApi } from '@/api';
import * as categoryTreeState from '@/AvailableProduct/categoriesTreeState';
import { URLParams } from '@/routing';
import { RootState } from '@/store';

export interface State {
    webshop?: Domain.Webshop;
    promotion?: Domain.WebshopPromotionDetails;
}

const initialState: State = {};

const reducerActions = {
    setWebshop: createAction('@webshopPromotion/update/setWebshop', withPayloadType<Domain.Webshop>()),
    setPromotion: createAction('@webshopPromotion/update/setPromotion', withPayloadType<Domain.WebshopPromotionDetails>()),
};

export const updateReducer = createReducer(initialState, builder =>
    builder
        .addCase(reducerActions.setWebshop, (state, action) => {
            state.webshop = action.payload;
        })
        .addCase(reducerActions.setPromotion, (state, action) => {
            state.promotion = action.payload;
        }),
);

export const selectWebshop: Selector<RootState, Domain.Webshop> = state => {
    const webshop = state.webshopPromotion.update.webshop;
    if (!webshop) {
        throw new Error('Webshop not loaded');
    }

    return webshop;
};

export const selectPromotion: Selector<RootState, Domain.WebshopPromotionDetails> = state => {
    const promotion = state.webshopPromotion.update.promotion;
    if (!promotion) {
        throw new Error('Promotion not loaded');
    }

    return promotion;
};

export const loadWebshop =
    (webshopId: string): ThunkAction<Promise<Domain.Webshop>> =>
    async dispatch => {
        const webshop = await webshopApi.GetWebshopDetails(webshopId);

        await dispatch(reducerActions.setWebshop(webshop));

        return webshop;
    };

export const loadPromotion =
    (webshopId: string, promotionId: string): ThunkAction<Promise<Domain.WebshopPromotionDetails>> =>
    async dispatch => {
        const promotion = await webshopPromotionApi.GetWebshopPromotionDetails(webshopId, promotionId);

        await dispatch(reducerActions.setPromotion(promotion));

        return promotion;
    };

export const load =
    (options: { urlParams: URLParams }): ThunkAction =>
    async dispatch => {
        const webshop = await dispatch(loadWebshop(options.urlParams.webshopId));
        await Promise.all([
            dispatch(loadPromotion(options.urlParams.webshopId, options.urlParams.promotionId)),
            dispatch(
                categoryTreeState.actions.load({
                    ...options,
                    urlParams: {
                        locale: webshop.defaultLocale || webshop.locales[0],
                        ...options.urlParams,
                    },
                }),
            ),
        ]);
    };
