import * as React from 'react';
import { useSelector } from 'react-redux';

import { useThunkDispatch, TVScreen, I18n } from 'data-store';
import { Grid, Row, Col, Icon } from 'ui-components';
import { keyboardLayout } from 'utils';

import Button from './Button';

import styles from './OnScreenKeyboard.scss';

export default function OnScreenKeyboard(props: { inputElement: HTMLInputElement | undefined; forMacAddress?: boolean }) {
    const dispatch = useThunkDispatch();
    const currentHistory = useSelector(TVScreen.interactionState.selectCurrentHistory);
    const locale = useSelector(I18n.selectCurrentLocale);

    let KEYS = [
        ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
        ['Q', 'W', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P'],
        ['A', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L', 'M'],
        ['Z', 'X', 'C', 'V', 'B', 'N', '@', '-', '_', '.'],
    ];

    if (keyboardLayout(locale) === 'azerty') {
        KEYS = [
            ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
            ['A', 'Z', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P'],
            ['Q', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L', 'M'],
            ['W', 'X', 'C', 'V', 'B', 'N', '@', '-', '_', '.'],
        ];
    }

    if (props.forMacAddress) {
        KEYS = [
            ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
            ['A', 'B', 'C', 'D', 'E', 'F', ':'],
        ];
    }

    if (
        !currentHistory ||
        (currentHistory.type !== 'search' && currentHistory.type !== 'slidesOverview' && currentHistory.type !== 'manageScreen')
    ) {
        return null;
    }

    const handleKeyPress = (key: string) => {
        let newQuery = currentHistory.query;
        let caretPosition = newQuery.length;

        if (props.inputElement && props.inputElement.selectionStart !== null) {
            caretPosition = props.inputElement.selectionStart;
        }

        if (caretPosition === 0) {
            newQuery = key + newQuery;
        } else {
            newQuery = [newQuery.slice(0, caretPosition), key, newQuery.slice(caretPosition)].join('');
        }

        if (newQuery === 'MANAGE SCREEN') {
            dispatch(
                TVScreen.interactionState.reducerActions.pushToHistory({
                    type: 'manageScreen',
                    query: '',
                }),
            );
            return;
        }

        dispatch(TVScreen.interactionState.reducerActions.setSearchQuery(newQuery));

        if (props.inputElement) {
            props.inputElement.focus();
            setTimeout(() => {
                if (props.inputElement) {
                    let newCaretPosition = caretPosition + 1;
                    if (newCaretPosition > newQuery.length) {
                        newCaretPosition = newQuery.length;
                    }
                    props.inputElement.setSelectionRange(newCaretPosition, newCaretPosition);
                }
            }, 10);
        }
    };

    const handleBackspacePress = () => {
        let newQuery = currentHistory.query;
        let caretPosition = newQuery.length;

        if (props.inputElement && props.inputElement.selectionStart !== null) {
            caretPosition = props.inputElement.selectionStart;
        }

        if (caretPosition === 0) {
            if (props.inputElement) {
                props.inputElement.focus();
            }

            return;
        }

        newQuery = [newQuery.slice(0, caretPosition - 1), newQuery.slice(caretPosition)].join('');

        dispatch(TVScreen.interactionState.reducerActions.setSearchQuery(newQuery));

        if (props.inputElement) {
            props.inputElement.focus();
            setTimeout(() => {
                if (props.inputElement) {
                    let newCaretPosition = caretPosition - 1;
                    if (newCaretPosition < 0) {
                        newCaretPosition = 0;
                    }

                    props.inputElement.setSelectionRange(newCaretPosition, newCaretPosition);
                }
            }, 10);
        }
    };

    return (
        <Grid
            className={styles.OnScreenKeyboard}
            gutter={14}
            cols={10}
        >
            {KEYS.map((row, index) => {
                return (
                    <Row key={index}>
                        {row.map(key => {
                            return (
                                <Col
                                    key={key}
                                    span={1}
                                >
                                    <Button
                                        variant="tertiary"
                                        className={styles.OnScreenKeyboardKey}
                                        onMouseDown={e => {
                                            e.preventDefault();
                                            e.stopPropagation();

                                            handleKeyPress(key);
                                        }}
                                    >
                                        {key}
                                    </Button>
                                </Col>
                            );
                        })}
                    </Row>
                );
            })}
            <Row>
                {!props.forMacAddress ? (
                    <Col span={8}>
                        <Button
                            variant="tertiary"
                            className={styles.OnScreenKeyboardKey}
                            onMouseDown={e => {
                                e.preventDefault();
                                e.stopPropagation();

                                handleKeyPress(' ');
                            }}
                        >
                            <span className={styles.OnScreenKeyboardKeySpaceIcon} />
                        </Button>
                    </Col>
                ) : null}

                <Col span={2}>
                    <Button
                        variant="tertiary"
                        className={styles.OnScreenKeyboardKey}
                        onMouseDown={e => {
                            e.preventDefault();
                            e.stopPropagation();

                            handleBackspacePress();
                        }}
                        startIcon={<Icon type="action_arrow_right" />}
                    />
                </Col>
            </Row>
        </Grid>
    );
}
