import * as React from 'react';

// This is based on the solution presented here https://github.com/facebook/react/issues/10962#issuecomment-444622208
//   and works with portals
export default function ClickOutsideListener({
    children,
    onClickOutside,
    mouseEvent = 'onClick',
}: {
    children: React.ReactElement;
    onClickOutside: (event: MouseEvent) => void;
    mouseEvent?: 'onClick' | 'onMouseDown' | 'onMouseUp';
}) {
    const isClickedInside = React.useRef(false);

    if (!children) {
        return null;
    }

    const nativeEventName = mouseEvent.substring(2).toLowerCase();
    const captureEventName = mouseEvent + 'Capture';

    React.useEffect(() => {
        const handler = (e: MouseEvent) => {
            if (isClickedInside.current) {
                isClickedInside.current = false;
                return;
            }

            onClickOutside(e);
        };
        document.addEventListener(nativeEventName, handler);
        return () => {
            document.removeEventListener(nativeEventName, handler);
        };
    }, [onClickOutside]);

    const handleClickInside = () => {
        isClickedInside.current = true;
    };

    const childrenProps: any = { [captureEventName]: handleClickInside };
    return React.cloneElement(React.Children.only(children), childrenProps);
}
