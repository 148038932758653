import * as React from 'react';

import { Domain } from 'api';

import { getCountryCodeFromLocale } from '@/getCountryCodeFromLocale';
import { COUNTRY_CONFIG } from '@/Localisation/availableCountriesState';

export function Localize(props: {
    value?: Domain.LocalizedValue | null | undefined;
    locale: Domain.Locale;
    preferredFallback?: Domain.Locale;
}) {
    const localizedValue = localize(props.locale, props.value, props.preferredFallback);

    if (localizedValue) {
        return <>{localizedValue}</>;
    }

    return null;
}

export function localize(locale: Domain.Locale, value?: Domain.LocalizedValue | null | undefined, preferredFallback?: Domain.Locale) {
    if (value === null || value === undefined) {
        return undefined;
    }

    let localizedValue: string | null | undefined;

    if (value.hasOwnProperty(locale)) {
        localizedValue = value[locale];

        if (localizedValue !== null && localizedValue !== undefined && localizedValue !== '') {
            return localizedValue;
        }
    }

    const country = locale ? getCountryCodeFromLocale(locale) : 'BE';
    const countryLocales = COUNTRY_CONFIG[country].locales;

    for (const countryLocale of countryLocales) {
        localizedValue = value[countryLocale];
        if (localizedValue !== null && localizedValue !== undefined && localizedValue !== '') {
            return localizedValue;
        }
    }

    if (preferredFallback) {
        localizedValue = value[preferredFallback];
        if (localizedValue !== null && localizedValue !== undefined && localizedValue !== '') {
            return localizedValue;
        }
    }

    for (const valueLocale in value) {
        if (!value.hasOwnProperty(valueLocale)) {
            continue;
        }

        localizedValue = value[valueLocale as Domain.Locale];
        if (localizedValue !== null && localizedValue !== undefined && localizedValue !== '') {
            return localizedValue;
        }
    }

    return undefined;
}
