import { Pagination, BillableService, BillableServicesPage, UpdateBillableServicePayload } from '@/Domain';

import Endpoint from './Endpoint';

export default class BillableServiceEndpoint extends Endpoint {
    public async GetBillableServices(pagination: Pagination): Promise<BillableServicesPage> {
        const response = await this.client.get({
            url: '/billable-service/overview',
            params: {
                pagination,
            },
        });

        this.guardResponseSchema('#/definitions/BillableServicesPage', response);

        return response;
    }

    public async GetBillableServiceDetails(billableServiceId: BillableService['billableServiceId']): Promise<BillableService> {
        const response = await this.GetBillableServices({
            page: 1,
            size: 999,
        });

        const billableService = response.items.find(item => item.billableServiceId === billableServiceId);

        if (!billableService) {
            throw new Error(`BillableService with ID ${billableServiceId} not found`);
        }

        return billableService;
    }

    public async UpdateBillableService(payload: UpdateBillableServicePayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/UpdateBillableService',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Invoicing\\BillableService\\UpdateBillableService\\UpdateBillableService',
                payload,
            },
        });
    }
}
