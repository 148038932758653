import * as React from 'react';

import { Domain } from 'api';
import { AnchoredPortal, Icon } from 'ui-components';

import { store, Actions } from '../state';
import { PlacedProduct, ShelfWithMeta } from '../types';
import { findPlacedProductShelf } from '../utils';

import styles from './styles/PlacedProductActions.scss';

export default function PlacedProductActions(props: {
    shelf: ShelfWithMeta;
    placedProduct: PlacedProduct;
    product: Domain.SlideshowProduct;
}) {
    const { state, dispatch } = React.useContext(store);
    const actionsRef = React.useRef<HTMLDivElement>();

    const resizedProductShelf = findPlacedProductShelf(state.canvas.shelves, state.resizedProductId ? state.resizedProductId : -1);
    const productIsFocused =
        state.resizedProductId &&
        resizedProductShelf &&
        state.resizedProductId === props.placedProduct.id &&
        resizedProductShelf.id === props.shelf.shelf.id;

    return (
        <div
            ref={actionsRef as any}
            className={styles.ProductWallDesignerPlacedProductActionsWrap}
        >
            {productIsFocused && actionsRef.current ? (
                <AnchoredPortal anchorElement={actionsRef.current}>
                    <div className={styles.ProductWallDesignerPlacedProductActions}>
                        <a
                            href=""
                            onClick={event => {
                                event.preventDefault();

                                dispatch({
                                    type: Actions.CanvasActions.HISTORY_SAVE,
                                });

                                dispatch({
                                    type: Actions.REMOVE_PRODUCT_FROM_SHELF,
                                    shelfId: props.shelf.shelf.id,
                                    placedProductId: props.placedProduct.id,
                                });
                            }}
                        >
                            <Icon type="action_delete" />
                        </a>
                    </div>
                </AnchoredPortal>
            ) : null}
        </div>
    );
}
