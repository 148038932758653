import { Locale } from './Localisation';
import { Pagination } from './Pagination';
import { Sorting } from './Sorting';

export type WebshopPageBlockLayout = 'full' | 'flexible';

export type WebshopPageBlockType = 'block' | 'carousel';

export type TileType = 'floating' | 'contained' | 'plain';

export interface WebshopPageWidgetSingleMediaItem {
    mediaItemId: string;
    url: string;
    device?: 'mobile' | 'tablet' | 'desktop' | null;
}
export interface WebshopPageWidgetDocumentItem {
    mediaItemId: string;
    url: string;
}

export function webshopPageWidgetMediaIsSingleItem(
    item: WebshopPageWidgetSingleMediaItem | WebshopPageWidgetSingleMediaItem[],
): item is WebshopPageWidgetSingleMediaItem {
    return !Array.isArray(item);
}
export interface WebshopPageWidgetTable {
    rows: { content: string }[][];
    numCols?: number;
    numRows?: number;
}
export type WebshopPageWidgetParameterTypeType = {
    media: WebshopPageWidgetSingleMediaItem | WebshopPageWidgetSingleMediaItem[];
    document: WebshopPageWidgetDocumentItem;
    'product-ids': string[];
    text: string;
    html: string | object;
    'category-ids': string[];
    'blog-article-ids': string[];
    'opening-hours-position': 'left' | 'right';
    'tile-position': 'left' | 'right' | 'center';
    'tile-type': TileType;
    layout: 'carousel' | 'grid';
    has_opening_hours: boolean;
    has_transparent_background: boolean;
    'video-url': string;
    autoplay?: boolean;
    table?: WebshopPageWidgetTable;
};
export type WebshopPageWidgetParameterType = keyof WebshopPageWidgetParameterTypeType;

export type WebshopPageWidgetBase = {
    widget_id: string;
    width_in_percentage: number;
};

export type WebshopPageWidgetPlaceholder = WebshopPageWidgetBase & {
    widget_type: 'placeholder';
    details: {};
};

export type WebshopPageWidgetOpeningHours = WebshopPageWidgetBase & {
    widget_type: 'opening_hours';
    details: {
        banner?: WebshopPageWidgetParameterTypeType['media'];
        position?: WebshopPageWidgetParameterTypeType['opening-hours-position'];
    };
};

export type WebshopPageWidgetProductList = WebshopPageWidgetBase & {
    widget_type: 'product_list';
    details: {
        pids?: WebshopPageWidgetParameterTypeType['product-ids'];
        title?: WebshopPageWidgetParameterTypeType['text'];
        layout?: WebshopPageWidgetParameterTypeType['layout'];
        autoplay?: WebshopPageWidgetParameterTypeType['autoplay'];
    };
};

export type WebshopPageWidgetTile = WebshopPageWidgetBase & {
    widget_type: 'tile';
    details: {
        type: WebshopPageWidgetParameterTypeType['tile-type'];
        position: WebshopPageWidgetParameterTypeType['tile-position'];
        banner?: WebshopPageWidgetParameterTypeType['media'];
        document?: WebshopPageWidgetParameterTypeType['document'];
        title?: WebshopPageWidgetParameterTypeType['text'];
        description?: WebshopPageWidgetParameterTypeType['html'];
        button_link?: WebshopPageWidgetParameterTypeType['text'];
        button_title?: WebshopPageWidgetParameterTypeType['text'];
        has_opening_hours?: WebshopPageWidgetParameterTypeType['has_opening_hours'];
        table?: WebshopPageWidgetParameterTypeType['table'];
        has_transparent_background?: WebshopPageWidgetParameterTypeType['has_transparent_background'];
    };
};

export type WebshopPageWidgetCategoryList = WebshopPageWidgetBase & {
    widget_type: 'category_list';
    details: {
        title?: WebshopPageWidgetParameterTypeType['text'];
        categoryIds: WebshopPageWidgetParameterTypeType['category-ids'];
    };
};

export type WebshopPageWidgetPrescriptionForm = WebshopPageWidgetBase & {
    widget_type: 'prescription_form';
    details: {};
};

export type WebshopPageWidgetBlogArticleList = WebshopPageWidgetBase & {
    widget_type: 'blog_article_list';
    details: {
        articleIds: WebshopPageWidgetParameterTypeType['blog-article-ids'];
        title?: WebshopPageWidgetParameterTypeType['text'];
        layout?: WebshopPageWidgetParameterTypeType['layout'];
        autoplay?: WebshopPageWidgetParameterTypeType['autoplay'];
    };
};

export type WebshopPageWidgetVideo = WebshopPageWidgetBase & {
    widget_type: 'video';
    details: {
        url?: WebshopPageWidgetParameterTypeType['video-url'];
    };
};

export type WebshopPageWidget =
    | WebshopPageWidgetPlaceholder
    | WebshopPageWidgetOpeningHours
    | WebshopPageWidgetProductList
    | WebshopPageWidgetTile
    | WebshopPageWidgetCategoryList
    | WebshopPageWidgetPrescriptionForm
    | WebshopPageWidgetBlogArticleList
    | WebshopPageWidgetVideo;

export type WebshopPageWidgetType = WebshopPageWidget['widget_type'];

export interface WebshopPageBlock {
    block_id: string;
    block_type: WebshopPageBlockType;
    block_layout: WebshopPageBlockLayout;
    scale_to_contain?: boolean;
    widgets: WebshopPageWidget[];
    autoplay?: boolean;
}

export interface WebshopPage {
    pageId: string;
    webshopId: string;
    title: string;
    slug: string;
    status: 'active' | 'inactive';
    locale: Locale;
    pageCode?: string | null;
    originalPageId?: string;
}

export type WebshopPageDetails = WebshopPage & {
    blocks: WebshopPageBlock[];
    heading?: string | null;
    metaTitle?: string | null;
    metaDescription?: string | null;
    companyId: string;
    branchId: string;
    webshopPageType: 'globalPage' | 'defaultPage' | 'customPage';
};

export interface WebshopPagesPage {
    items: WebshopPage[];
    total: number;
    pagination: Pagination;
    sorting?: Sorting<keyof WebshopPage> | null;
    search?: string | null;
}

export type UpdateWebshopPagePayload = Omit<WebshopPageDetails, 'companyId' | 'branchId' | 'webshopPageType'>;

export interface ValidateWebshopPageTitleResponse {
    valid: boolean;
    generatedSlug: string;
}

export interface RemoveWebshopPagePayload {
    webshopId: string;
    pageId: string;
    locale: Locale;
}
