export function slugify(text: string): string {
    let slug = text.toLowerCase().trim();

    // remove accents from charaters
    slug = slug.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

    // replace invalid chars with spaces
    slug = slug.replace(/[^a-z0-9\s-]/g, ' ').trim();

    // replace multiple spaces or hyphens with a single hyphen
    slug = slug.replace(/[\s-]+/g, '-');

    return slug;
}

export function slugifyLocalizable(localizable: { [key: string]: string | undefined | null }): string {
    const items = Object.values(localizable).filter(Boolean);
    if (items.length > 0 && items[0]) {
        return slugify(items[0]);
    }

    return '';
}
