import { Domain } from 'api';

import { webshopOrderShipmentApi } from '@/api';
import { makeActions, makeReducer, makeReducerActions, makeSelectors } from '@/makeDetailsState';

export const selectors = makeSelectors<Domain.WebshopOrderShipmentDetails>({
    getState: rootState => rootState.webshopOrderShipment.details,
});

export const reducerActions = makeReducerActions<Domain.WebshopOrderShipmentDetails>({
    reducerPrefix: '@webshopOrderShipment/details',
});

export const actions = makeActions<Domain.WebshopOrderShipmentDetails>({
    loadApi: options => webshopOrderShipmentApi.GetWebshopOrderShipmentDetails(options.urlParams.webshopId, options.urlParams.shipmentId),
    reducerActions,
    selectors,
});

export const detailsReducer = makeReducer<Domain.WebshopOrderShipmentDetails>({
    reducerActions,
});
