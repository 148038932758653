import * as React from 'react';

import ControlledRTEditor, { ControlledRTEditorWithValue } from './ControlledRTEditor';
import UncontrolledRTEditor, { UncontrolledRTEditorProps } from './UncontrolledRTEditor';

type RTEditorProps = ControlledRTEditorWithValue | UncontrolledRTEditorProps;

class RTEditor extends React.PureComponent<RTEditorProps> {
    render() {
        if (this.isControlled(this.props)) {
            return <ControlledRTEditor {...this.props} />;
        }

        return <UncontrolledRTEditor {...this.props} />;
    }

    private isControlled(props: RTEditorProps): props is ControlledRTEditorWithValue {
        return (props as ControlledRTEditorWithValue).hasOwnProperty('value');
    }
}

export default React.forwardRef<HTMLInputElement, RTEditorProps>((props, ref) => {
    return (
        <RTEditor
            forwardRef={ref}
            {...props}
        />
    );
});
