import { Domain } from 'api';

import { ThunkAction } from '@/action';
import { qualityLabelApi } from '@/api';
import { loadLocalisations } from '@/Localisation/overviewState';
import { makeActions, makeReducer, makeReducerActions, makeSelectors } from '@/makeOverviewState';
import { URLParams, URLQuery } from '@/routing';

export type QualityLabelProps = keyof Domain.QualityLabel;

export const defaultSorting: Domain.Sorting<QualityLabelProps> = {
    field: 'name',
    direction: 'ascending',
};

export const pageSize = 20;

export const selectors = makeSelectors<Domain.QualityLabel, QualityLabelProps>({
    getState: rootState => rootState.qualityLabel.overview,
});

export const reducerActions = makeReducerActions<Domain.QualityLabel, QualityLabelProps>({
    reducerPrefix: '@qualityLabel/overview',
});

const baseActions = makeActions<Domain.QualityLabel, QualityLabelProps>({
    dataTableSaveKey: 'qualityLabelsOverview-v5',
    loadApi: options => qualityLabelApi.GetQualityLabels(options.pagination, options.sorting, options.filters),
    defaultSorting,
    pageSize,
    getBaseUrl: () => '/localisation/quality-labels',
    reducerActions,
    selectors,
});

export const overviewReducer = makeReducer<Domain.QualityLabel, QualityLabelProps>({
    defaultSorting,
    pageSize,
    reducerActions,
});

export const actions = {
    ...baseActions,
    load:
        (options: { urlParams: URLParams; urlQuery: URLQuery }): ThunkAction =>
        async dispatch => {
            await Promise.all([dispatch(baseActions.load(options)), dispatch(loadLocalisations())]);
        },
};
