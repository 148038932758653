import * as React from 'react';

import { Domain } from 'api';
import { CanvasFrame } from 'editor-canvas';
import { HTMLEditorView, RTValue, getCSSPropsFromRTValue } from 'ui-components';

import type { OpeningHoursFrame } from '../types';

export default function OpeningHoursFrame(props: {
    gettext: (text: string) => string;
    frame: CanvasFrame & OpeningHoursFrame;
    getOpeningHours: () => Domain.OpeningHours | null;
    style?: React.CSSProperties;
}) {
    const { gettext, frame, style, getOpeningHours } = props;
    const openingHours = getOpeningHours();

    const DAY_LABELS: {
        [p in Domain.Day]: string;
    } = {
        Monday: gettext('Monday'),
        Tuesday: gettext('Tuesday'),
        Wednesday: gettext('Wednesday'),
        Thursday: gettext('Thursday'),
        Friday: gettext('Friday'),
        Saturday: gettext('Saturday'),
        Sunday: gettext('Sunday'),
    };

    let html = '';
    for (const day of Domain.WEEK_DAYS) {
        if (!frame.showDays[day]) {
            continue;
        }

        const schedule = openingHours ? openingHours[day] : undefined;
        html += `
            <div><strong>${DAY_LABELS[day]}</strong></div>
        `;

        if (!schedule || schedule.length === 0) {
            html += `
                <div>${gettext('Closed')}</div>
                <br />
            `;
        } else {
            html += `
                <div>
            `;

            for (const slice of schedule) {
                html += `
                    <div>
                        <div>${slice.from} - ${slice.till}</div>
                    </div>
                `;
            }

            html += `
                </div>
                <br />
            `;
        }
    }

    const frameStyle = new RTValue('', frame.textStyle);

    return (
        <HTMLEditorView
            style={style}
            innerStyle={getCSSPropsFromRTValue(frameStyle)}
            value={html}
        />
    );
}
