import { combineReducers } from '@reduxjs/toolkit';

import { overviewReducer } from './overviewState';
import { updateReducer } from './updateState';
import { uploadReducer } from './uploadState';

export const mediaReducer = combineReducers({
    overview: overviewReducer,
    upload: uploadReducer,
    update: updateReducer,
});
