import { Domain } from 'api';

import { webshopAnnouncementApi } from '@/api';
import { makeActions, makeReducer, makeReducerActions, makeSelectors } from '@/makeOverviewState';

type WebshopAnnouncementProps = keyof Domain.WebshopAnnouncement;

export const defaultSorting: Domain.Sorting<WebshopAnnouncementProps> = {
    field: 'createdOn',
    direction: 'descending',
};

export const pageSize = 20;

export const selectors = makeSelectors<Domain.WebshopAnnouncement, WebshopAnnouncementProps>({
    getState: rootState => rootState.webshopAnnouncement.overview,
});

export const reducerActions = makeReducerActions<Domain.WebshopAnnouncement, WebshopAnnouncementProps>({
    reducerPrefix: '@webshopAnnouncement/overview',
});

export const actions = makeActions<Domain.WebshopAnnouncement, WebshopAnnouncementProps>({
    dataTableSaveKey: 'webshopAnnouncementsOverview-v1',
    loadApi: options => {
        if (options.globallySelectedWebshopId === undefined) {
            return;
        }

        return webshopAnnouncementApi.GetAnnouncements(
            options.pagination,
            options.sorting,
            options.globallySelectedWebshopId,
            options.search,
        );
    },
    defaultSorting,
    pageSize,
    getBaseUrl: () => '/webshops/announcements',
    reducerActions,
    selectors,
});

export const overviewReducer = makeReducer<Domain.WebshopAnnouncement, WebshopAnnouncementProps>({
    defaultSorting,
    pageSize,
    reducerActions,
});
