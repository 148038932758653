import { CompanyLandingPageConfiguration } from './CompanyLandingPageConfiguration';
import { ExternalReferenceIds } from './ExternalReferenceId';
import { Locale, Country } from './Localisation';
import { Pagination } from './Pagination';
import { Reseller } from './Reseller';
import { Sorting } from './Sorting';

export const CompanyTypes = ['agency', 'pharmacy', 'contentProvider', 'other'] as const;
export type CompanyType = (typeof CompanyTypes)[number];

export interface EnrichedCompany {
    companyId: string;
    name: string;
}

export type EditableCompanyProps = EnrichedCompany & {
    legalName: string;
    address: string;
    city: string;
    country: Country;
    locales: Locale[];
    isActive: boolean;
    zipCode: string;
    phoneNumber: string;
    type: CompanyType;
    url?: string | null;
    emailAddress?: string | null;
    createdOn: string;
    updatedOn: string;
    isTestCompany?: boolean | null;
    resellerId?: Reseller['resellerId'] | null;
    reseller?: Reseller | null;
    externalReferenceIds?: ExternalReferenceIds | null;
};

export interface CompanySharingProps {
    canShareWithPlatform: boolean;
    canUseSharingGroups: boolean;
    canShareWithCountries: Country[] | null;
}

export type Company = EditableCompanyProps;

export type CompanyDetails = Company &
    CompanySharingProps & {
        landingPageConfiguration?: CompanyLandingPageConfiguration | null;
    };

export interface CompaniesPage {
    items: Company[];
    total: number;
    pagination: Pagination;
    sorting?: Sorting<keyof Company> | null;
    search?: string | null;
}

export type CreateCompanyPayload = EditableCompanyProps & {
    country: Country;
} & CompanySharingProps;

export type UpdateCompanyPayload = Omit<EditableCompanyProps, 'country'> & CompanySharingProps;

export interface AssignCompanyToResellerPayload {
    companyId: Company['companyId'];
    resellerId: Reseller['resellerId'];
}
export interface UpdateCompanyLandingPageConfigurationPayload {
    companyId: Company['companyId'];
    landingPageConfiguration: CompanyLandingPageConfiguration;
}
