import moment from 'moment';

import { Domain } from 'api';

import { ThunkAction } from '@/action';
import { appointmentTypeApi } from '@/api';
import { makeActions, makeReducer, makeReducerActions, makeSelectors } from '@/makeOverviewState';
import { RootState } from '@/store';

type AppointmentTypesProps = keyof Domain.AppointmentTypes;

export const defaultSorting: Domain.Sorting<AppointmentTypesProps> = {
    field: 'createdAt',
    direction: 'descending',
};

export const pageSize = 20;

export const selectors = makeSelectors<Domain.AppointmentTypes, AppointmentTypesProps>({
    getState: rootState => rootState.appointmentTypesReducer.overview,
});

export const getAvailableAppointmentTypes = (rootState: RootState) => {
    const appointmentTypes = selectors.maybeSelectCurrentPage(rootState);
    const availableAppointmentTypes = appointmentTypes?.items.filter(appointmentType => moment(appointmentType.endDate).isAfter(moment()));
    return availableAppointmentTypes || [];
};
export const reducerActions = makeReducerActions<Domain.AppointmentTypes, AppointmentTypesProps>({
    reducerPrefix: '@AppointmentTypes/overview',
});

export const actions = makeActions<Domain.AppointmentTypes, AppointmentTypesProps>({
    dataTableSaveKey: 'appointmentTypesOverview-v1',
    loadApi: options => {
        return appointmentTypeApi.GetAppointmentTypes(options.pagination, options.sorting, options.search);
    },
    defaultSorting,
    pageSize,
    getBaseUrl: () => '/webshops/appointment-types/overview',
    reducerActions,
    selectors,
});

export const overviewReducer = makeReducer<Domain.AppointmentTypes, AppointmentTypesProps>({
    defaultSorting,
    pageSize,
    reducerActions,
});

export const loadAppointmentTypes =
    (pageSize = 30): ThunkAction =>
    async dispatch => {
        const appointmentTypes = await appointmentTypeApi.GetAppointmentTypes({ page: 1, size: pageSize });
        dispatch(reducerActions.setCurrentPage(appointmentTypes));
    };
