import * as React from 'react';

import moment from 'moment';

import { Slider } from '@/slider';

import styles from './TimePicker.scss';

export const TimePicker = React.memo(
    (props: {
        date?: moment.Moment;
        defaultDate?: moment.Moment;
        min?: moment.Moment;
        max?: moment.Moment;
        onChangeStart?: () => void;
        onChange?: (newValue: moment.Moment) => void;
        onChangeComplete?: (newValue: moment.Moment) => void;
        showSeconds?: boolean;
        autoFocusedSlider?: React.Ref<any>;
    }) => {
        let minSelectableHours = 0;
        let maxSelectableHours = 23;
        let minSelectableMinutes = 0;
        let maxSelectableMinutes = 59;
        let minSelectableSeconds = 0;
        let maxSelectableSeconds = 59;

        const date = props.date || props.defaultDate;

        const [hours, setHours] = React.useState(date ? date.hours() : props.min ? props.min.hours() : 0);
        const [minutes, setMinutes] = React.useState(date ? date.minutes() : props.min ? props.min.minutes() : 0);
        const [seconds, setSeconds] = React.useState(date ? date.seconds() : props.min ? props.min.seconds() : 0);

        if (props.min && ((props.date && props.min.isSame(props.date, 'day')) || !props.date)) {
            minSelectableHours = props.min.hours();

            if (hours === props.min.hours()) {
                minSelectableMinutes = props.min.minutes();

                if (minutes === props.min.minutes()) {
                    minSelectableSeconds = props.min.seconds();
                }
            }
        }

        if (props.max && ((props.date && props.max.isSame(props.date, 'day')) || !props.date)) {
            maxSelectableHours = props.max.hours();

            if (hours === props.max.hours()) {
                maxSelectableMinutes = props.max.minutes();

                if (minutes === props.max.minutes()) {
                    maxSelectableSeconds = props.max.seconds();
                }
            }
        }

        const getFallbackTime = () => {
            const now = moment();
            now.set('hour', 0);
            now.set('minute', 0);
            now.set('second', 0);
            now.set('millisecond', 0);

            return now;
        };

        const hoursChangeHandler = (newHours: number) => {
            setHours(newHours);

            if (props.date) {
                return;
            }

            if (newHours === minSelectableHours && minutes <= minSelectableMinutes) {
                setMinutes(minSelectableMinutes);

                if (seconds < minSelectableSeconds) {
                    setSeconds(minSelectableSeconds);
                }
            }

            if (newHours === maxSelectableHours && minutes >= maxSelectableMinutes) {
                setMinutes(maxSelectableMinutes);

                if (seconds > maxSelectableSeconds) {
                    setSeconds(maxSelectableSeconds);
                }
            }
        };

        const minutesChangeHandler = (newMinutes: number) => {
            setMinutes(newMinutes);

            if (hours === minSelectableHours && newMinutes === minSelectableMinutes && seconds < minSelectableSeconds) {
                setSeconds(minSelectableSeconds);
            }

            if (hours === maxSelectableHours && newMinutes === maxSelectableMinutes && seconds > maxSelectableSeconds) {
                setSeconds(maxSelectableSeconds);
            }
        };

        return (
            <div className={styles.TimePicker}>
                <Slider
                    knobRef={props.autoFocusedSlider}
                    label="Hours"
                    min={0}
                    max={23}
                    selectableMin={minSelectableHours}
                    selectableMax={maxSelectableHours}
                    step={1}
                    valueLabel={true}
                    value={props.date ? props.date.hours() : hours}
                    onChangeStart={props.onChangeStart}
                    onChange={newHours => {
                        hoursChangeHandler(newHours);

                        if (props.onChange) {
                            props.onChange((props.date || props.defaultDate || getFallbackTime()).clone().hours(newHours));
                        }
                    }}
                    onChangeComplete={newHours => {
                        hoursChangeHandler(newHours);

                        if (props.onChangeComplete) {
                            props.onChangeComplete((props.date || props.defaultDate || getFallbackTime()).clone().hours(newHours));
                        }
                    }}
                />

                <Slider
                    label="Minutes"
                    min={0}
                    max={59}
                    selectableMin={minSelectableMinutes}
                    selectableMax={maxSelectableMinutes}
                    step={1}
                    valueLabel={true}
                    value={props.date ? props.date.minutes() : minutes}
                    onChangeStart={props.onChangeStart}
                    onChange={newMinutes => {
                        minutesChangeHandler(newMinutes);

                        if (props.onChange) {
                            props.onChange((props.date || props.defaultDate || getFallbackTime()).clone().minutes(newMinutes));
                        }
                    }}
                    onChangeComplete={newMinutes => {
                        minutesChangeHandler(newMinutes);

                        if (props.onChangeComplete) {
                            props.onChangeComplete((props.date || props.defaultDate || getFallbackTime()).clone().minutes(newMinutes));
                        }
                    }}
                />

                {props.showSeconds ? (
                    <Slider
                        label="Seconds"
                        min={0}
                        max={59}
                        selectableMin={minSelectableSeconds}
                        selectableMax={maxSelectableSeconds}
                        step={1}
                        valueLabel={true}
                        value={props.date ? props.date.seconds() : seconds}
                        onChangeStart={props.onChangeStart}
                        onChange={newSeconds => {
                            setSeconds(newSeconds);

                            if (props.onChange) {
                                props.onChange((props.date || props.defaultDate || getFallbackTime()).clone().seconds(newSeconds));
                            }
                        }}
                        onChangeComplete={newSeconds => {
                            setSeconds(newSeconds);

                            if (props.onChangeComplete) {
                                props.onChangeComplete((props.date || props.defaultDate || getFallbackTime()).clone().seconds(newSeconds));
                            }
                        }}
                    />
                ) : null}
            </div>
        );
    },
);
