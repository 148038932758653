import { ThunkAction } from '@/action';
import { dashboardApi } from '@/api';
import { selectIsLoggedIn } from '@/Authentication';

import { setDashboardItems } from './state';

export const loadDashboard = (): ThunkAction => async (dispatch, getState) => {
    const state = getState();
    const isLoggedIn = selectIsLoggedIn(state);

    if (isLoggedIn) {
        const dashboardItems = await dashboardApi.GetDashboard();
        await dispatch(setDashboardItems(dashboardItems));
    }
};
