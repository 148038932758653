import { EditorState, genKey, ContentBlock, ContentState, Modifier, SelectionState } from 'draft-js';
import Immutable from 'immutable';

/**
 * Function returns collection of currently selected blocks.
 */
export function getSelectedBlocksMap(editorState: EditorState, currentSelection?: SelectionState) {
    if (!currentSelection) {
        currentSelection = editorState.getSelection();
    }
    const contentState = editorState.getCurrentContent();
    const startKey = currentSelection.getStartKey();
    const endKey = currentSelection.getEndKey();
    const blockMap = contentState.getBlockMap();
    return blockMap
        .toSeq()
        .skipUntil((_, k) => k === startKey)
        .takeUntil((_, k) => k === endKey)
        .concat([[endKey, blockMap.get(endKey)]]);
}

/**
 * Function returns collection of currently selected blocks.
 */
export function getSelectedBlocksList(editorState: EditorState, currentSelection?: SelectionState) {
    return getSelectedBlocksMap(editorState, currentSelection).toList();
}

export function insertBlockAfter(editorState: EditorState, blockKey: string, newType: string, newData: Immutable.Map<any, any>) {
    const content = editorState.getCurrentContent();
    const blockMap = content.getBlockMap();
    const block = blockMap.get(blockKey);
    const blocksBefore = blockMap.toSeq().takeUntil(function (v) {
        return v === block;
    });
    const blocksAfter = blockMap
        .toSeq()
        .skipUntil(function (v) {
            return v === block;
        })
        .rest();
    const newBlockKey = genKey();
    const newBlock = new ContentBlock({
        key: newBlockKey,
        type: newType,
        text: '',
        characterList: block.getCharacterList().slice(0, 0),
        depth: 0,
    });
    const newBlockMap = blocksBefore
        .concat(
            [
                [blockKey, block],
                [newBlockKey, newBlock],
            ],
            blocksAfter,
        )
        .toOrderedMap();
    const selection = editorState.getSelection();
    const newContent: ContentState = content.merge({
        blockMap: newBlockMap,
        selectionBefore: selection,
        selectionAfter: selection.merge({
            anchorKey: newBlockKey,
            anchorOffset: 0,
            focusKey: newBlockKey,
            focusOffset: 0,
            isBackward: false,
        }),
    }) as ContentState;

    let newState = EditorState.push(editorState, newContent, 'split-block');

    newState = EditorState.push(
        newState,
        Modifier.mergeBlockData(newState.getCurrentContent(), newState.getSelection(), newData),
        'change-block-data',
    );

    return newState;
}
