import { createAction, Selector, createReducer } from '@reduxjs/toolkit';

import { Domain } from 'api';

import { ThunkAction, withPayloadType } from '@/action';
import { webshopApi, webshopFaqApi } from '@/api';
import { URLParams } from '@/routing';
import { RootState } from '@/store';

export interface State {
    webshop?: Domain.Webshop;
    faqCategory?: Domain.WebshopFaqCategoryDetails;
}

const initialState: State = {};

const reducerActions = {
    setWebshop: createAction('@webshopFaqCategory/update/setWebshop', withPayloadType<Domain.Webshop>()),
    setFaqCategory: createAction('@webshopFaqCategory/update/setFaqCategory', withPayloadType<Domain.WebshopFaqCategoryDetails>()),
};

export const updateReducer = createReducer(initialState, builder =>
    builder
        .addCase(reducerActions.setWebshop, (state, action) => {
            state.webshop = action.payload;
        })
        .addCase(reducerActions.setFaqCategory, (state, action) => {
            state.faqCategory = action.payload;
        }),
);

export const selectWebshop: Selector<RootState, Domain.Webshop> = state => {
    const webshop = state.webshopFaqCategory.update.webshop;
    if (!webshop) {
        throw new Error('Webshop not loaded');
    }

    return webshop;
};

export const selectFaqCategory: Selector<RootState, Domain.WebshopFaqCategoryDetails> = state => {
    const faqCategory = state.webshopFaqCategory.update.faqCategory;
    if (!faqCategory) {
        throw new Error('FAQ category not loaded');
    }

    return faqCategory;
};

export const loadWebshop =
    (webshopId: Domain.Webshop['webshopId']): ThunkAction<Promise<Domain.Webshop>> =>
    async dispatch => {
        const webshop = await webshopApi.GetWebshopDetails(webshopId);

        await dispatch(reducerActions.setWebshop(webshop));

        return webshop;
    };

export const loadFaqCategory =
    (
        webshopId: Domain.Webshop['webshopId'],
        faqCategoryId: Domain.WebshopFaqCategory['faqCategoryId'],
    ): ThunkAction<Promise<Domain.WebshopFaqCategoryDetails>> =>
    async dispatch => {
        const faqCategory = await webshopFaqApi.GetWebshopFaqCategoryDetails(webshopId, faqCategoryId);

        await dispatch(reducerActions.setFaqCategory(faqCategory));

        return faqCategory;
    };

export const load =
    (options: { urlParams: URLParams }): ThunkAction =>
    async dispatch => {
        await Promise.all([
            dispatch(loadWebshop(options.urlParams.webshopId)),
            dispatch(loadFaqCategory(options.urlParams.webshopId, options.urlParams.faqCategoryId)),
        ]);
    };
