import { Domain } from 'api';

import { mediaItemApi } from '@/api';
import { makeActions, makeReducer, makeReducerActions, makeSelectors } from '@/makeDetailsState';

export const selectors = makeSelectors<Domain.MediaItem>({
    getState: rootState => rootState.media.update,
});

export const reducerActions = makeReducerActions<Domain.MediaItem>({
    reducerPrefix: '@media/update',
});

export const actions = makeActions<Domain.MediaItem>({
    loadApi: options => mediaItemApi.GetMediaItemDetails(options.urlParams.mediaItemId),
    reducerActions,
    selectors,
});

export const updateReducer = makeReducer<Domain.MediaItem>({
    reducerActions,
});
