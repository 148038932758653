import moment from 'moment';

import { Domain } from 'api';

type IGetText = (text: string) => string;
interface Coordinates {
    type: 'Point';
    coordinates: [number, number];
}
interface Travel {
    road_distance?: number | null;
    road_time?: number | null;
}
export interface PharmacyOnDuty {
    pharmacy: {
        coordinate?: Coordinates | null;
        id?: number;
        name: string;
        phoneNumber?: string | null;
        address: string;
        geoDescription?: string | null;
        getDescription: (gettext: IGetText) => string;
    };
    travel?: Travel;
}

const formatGeowacthResponse = (response: Domain.GeowacthPharmacyOnDutyList): PharmacyOnDuty[] => {
    const { results } = response;

    return results.map(pharmacyResult => {
        const { pharmacy, travel } = pharmacyResult;
        const {
            coordinate,
            id,
            name,
            address_street,
            address_streetnr,
            address_postalcode,
            address_locality,
            phone_nr_formatted,
            pharmacist_description,
            address_geodescription,
        } = pharmacy;
        return {
            pharmacy: {
                coordinate: coordinate || null,
                id: id,
                name: name,
                phoneNumber: phone_nr_formatted,
                address: `${address_street} ${address_streetnr}, ${address_postalcode} ${address_locality}`,
                description: pharmacist_description,
                getDescription: () => pharmacist_description,
                geoDescription: address_geodescription,
            },
            travel,
        };
    });
};

const formatGardeResponse = (response: Domain.GardePharmacyOnDutyList): PharmacyOnDuty[] => {
    const { garde } = response;
    const today = moment().format('YYYY-MM-DD');
    const pharmacyItem = garde.find(item => item.date === today);
    if (!pharmacyItem) {
        return [];
    }
    const { pharmacie: currentDayPharmacies } = pharmacyItem.pharmacies;
    return currentDayPharmacies.map(pharmacy => {
        return {
            pharmacy: {
                name: pharmacy.nom,
                address: pharmacy.adresse,
                phoneNumber: pharmacy.telephone,
                getDescription: () => '',
            },
        };
    });
};

const formatDeResponse = (response: Domain.NotdienstdatenPharmacyOnDutyList): PharmacyOnDuty[] => {
    const { apotheken } = response;
    let originalPharmacies = apotheken.apotheke;

    if (!Array.isArray(originalPharmacies)) {
        originalPharmacies = [originalPharmacies];
    }

    return originalPharmacies.map(apotheke => ({
        pharmacy: {
            id: parseInt(apotheke.id),
            name: apotheke.name || '',
            phoneNumber: apotheke.telefon,
            address: `${apotheke.strasse}, ${apotheke.plz} ${apotheke.ort}`,
            geoDescription: apotheke.distanz ? Number(apotheke.distanz).toFixed(2) + ' km' : '',
            getDescription: (gettext: IGetText) =>
                gettext('Operational from :startdatum: at :startzeit: to :enddatum: at :endzeit:')
                    .replace(':startdatum:', apotheke.startdatum || '')
                    .replace(':startzeit:', apotheke.startzeit || '')
                    .replace(':enddatum:', apotheke.enddatum || '')
                    .replace(':endzeit:', apotheke.endzeit || ''),
        },
    }));
};
interface GetPharmaciesOnDutyParams {
    country: Domain.Country;
    apb?: string | null;
    limit?: number;
    language?: string;
}

export const getPharmaciesOnDuty = async ({
    country,
    GetPharmaciesOnDuty,
    ...rest
}: GetPharmaciesOnDutyParams & {
    GetPharmaciesOnDuty: (
        params: GetPharmaciesOnDutyParams,
    ) => Promise<Domain.GeowacthPharmacyOnDutyList | Domain.GardePharmacyOnDutyList | Domain.NotdienstdatenPharmacyOnDutyList | null>;
}) => {
    try {
        const response = await GetPharmaciesOnDuty({ ...rest, country });
        if (country === 'BE') {
            return formatGeowacthResponse(response as Domain.GeowacthPharmacyOnDutyList);
        } else if (country === 'LU') {
            return formatGardeResponse(response as Domain.GardePharmacyOnDutyList);
        } else if (country === 'DE') {
            return formatDeResponse(response as Domain.NotdienstdatenPharmacyOnDutyList);
        }
    } catch (e) {
        console.error(e);
    }
    return [];
};
