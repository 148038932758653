import * as React from 'react';

import styles from './PreloaderProgress.scss';

export default function PreloaderProgress() {
    return <div className={styles.PreloaderProgress} />;
}

export function PreloaderProgressWrap(props: {
    className?: string;
    style?: React.CSSProperties;
    children?: React.ReactNode;
    fixed?: boolean;
}) {
    return (
        <div
            className={`${styles.PreloaderProgressWrap} ${props.fixed ? styles.PreloaderProgressWrapFixed : ''} ${props.className || ''}`}
            style={props.style}
        >
            {props.children}
        </div>
    );
}
