import { createAction, Selector, createReducer } from '@reduxjs/toolkit';

import { Domain } from 'api';

import { ThunkAction, withPayloadType } from '@/action';
import { webshopPromotionApi } from '@/api';
import { RootState } from '@/store';
import { selectGlobalSelectedWebshopId } from '@/Webshop/globalSelectionState';

export interface State {
    promotions: Domain.WebshopPromotion[];
}

const initialState: State = {
    promotions: [],
};

const reducerActions = {
    setPromotions: createAction('@webshopPromotion/managePriorities/setPromotions', withPayloadType<Domain.WebshopPromotion[]>()),
};

export const managePrioritiesReducer = createReducer(initialState, builder =>
    builder.addCase(reducerActions.setPromotions, (state, action) => {
        state.promotions = action.payload;
    }),
);

export const selectPromotions: Selector<RootState, Domain.WebshopPromotion[]> = state => {
    const promotions = state.webshopPromotion.managePriorities.promotions;
    if (!promotions) {
        throw new Error('Promotions not loaded');
    }

    return promotions;
};

export const loadPromotions = (): ThunkAction => async (dispatch, getState) => {
    const state = getState();
    const webshopId = selectGlobalSelectedWebshopId(state);

    if (!webshopId) {
        dispatch(reducerActions.setPromotions([]));
        return;
    }

    const promotions = await webshopPromotionApi.GetWebshopPromotions(
        { page: 1, size: 999 },
        { field: 'campaignName', direction: 'ascending' },
        webshopId,
    );

    dispatch(reducerActions.setPromotions(promotions.items.sort((a, b) => a.priority - b.priority)));
};

export const load = (): ThunkAction => async dispatch => {
    await dispatch(loadPromotions());
};
