import { createAction, createReducer, Selector } from '@reduxjs/toolkit';

import { Domain } from 'api';

import { ThunkAction, withPayloadType } from '@/action';
import { productSelectionApi } from '@/api';
import { selectLoggedInUserOwnership } from '@/Authentication';
import { getDataTableSavedSettings, setDataTableSavedSettings } from '@/dataTableSavedSettings';
import { selectCurrentLocale } from '@/I18n';
import { Filters } from '@/makeOverviewState';
import { getOverviewQueryParameters, updateURLWithQueryParams, URLQuery } from '@/routing';
import { RootState } from '@/store';

type SelectedProductProps = keyof Domain.SelectedProduct | 'checkbox' | 'stockSourceIds';

export interface OverviewState {
    products?: Domain.SelectedProductsPage;
    pagination: Domain.Pagination;
    search: string;
    visibleColumns: undefined | SelectedProductProps[];
    sortableColumns?: SelectedProductProps[];
    completeness: Domain.ProductCompletenessFilter | '';
    productsType: Domain.SelectedProductType | '';
    filters: Filters;
}

export const initialState: OverviewState = {
    pagination: {
        page: 1,
        size: 20,
    },
    search: '',
    visibleColumns: undefined,
    sortableColumns: undefined,
    completeness: '',
    productsType: '',
    filters: {},
};

interface StateOptionsForReducerActions {
    reducerPrefix: string;
}

interface StateOptionsForActions {
    dataTableSaveKey: string;
    baseURL?: string;
}

interface StateOptionsForReducer {
    initialState?: OverviewState;
}

interface StateOptionsForSelectors {
    getState: (rootState: RootState) => OverviewState;
}

export function makeReducerActions(stateOptions: StateOptionsForReducerActions) {
    return {
        setProducts: createAction(stateOptions.reducerPrefix + '/setProducts', withPayloadType<Domain.SelectedProductsPage>()),
        setPagination: createAction(stateOptions.reducerPrefix + '/setPagination', withPayloadType<Domain.Pagination>()),
        setPaginationPage: createAction(stateOptions.reducerPrefix + '/setPaginationPage', withPayloadType<Domain.Pagination['page']>()),
        setSearch: createAction(stateOptions.reducerPrefix + '/setSearch', withPayloadType<string>()),
        setCompleteness: createAction(
            stateOptions.reducerPrefix + '/setCompleteness',
            withPayloadType<Domain.ProductCompletenessFilter | ''>(),
        ),
        setProductsType: createAction(stateOptions.reducerPrefix + '/setProductsType', withPayloadType<Domain.SelectedProductType | ''>()),
        setVisibleColumns: createAction(stateOptions.reducerPrefix + '/setVisibleColumns', withPayloadType<SelectedProductProps[]>()),
        setSortableColumns: createAction(stateOptions.reducerPrefix + '/setSortableColumns', withPayloadType<SelectedProductProps[]>()),
        setFilters: createAction(stateOptions.reducerPrefix + '/setFilters', withPayloadType<Filters>()),
    };
}

export function makeActions(
    stateOptions: StateOptionsForActions & {
        reducerActions: ReturnType<typeof makeReducerActions>;
        selectors: ReturnType<typeof makeSelectors>;
    },
    selection: Domain.ProductSelectionType,
) {
    let previousRequestKey = '';

    const setVisibleColumns =
        (visibleColumns: SelectedProductProps[]): ThunkAction =>
        async dispatch => {
            setDataTableSavedSettings(stateOptions.dataTableSaveKey, {
                visibleColumns,
            });

            await dispatch(stateOptions.reducerActions.setVisibleColumns(visibleColumns));
        };
    const setSortableColumns =
        (sortableColumns: SelectedProductProps[]): ThunkAction =>
        async dispatch => {
            setDataTableSavedSettings(stateOptions.dataTableSaveKey, {
                sortableColumns,
            });
            await dispatch(stateOptions.reducerActions.setSortableColumns(sortableColumns));
        };
    const setSearch =
        (search: string): ThunkAction =>
        async dispatch => {
            await dispatch(stateOptions.reducerActions.setSearch(search));
        };

    const setCompleteness =
        (completeness: Domain.ProductCompletenessFilter | ''): ThunkAction =>
        async dispatch => {
            await dispatch(stateOptions.reducerActions.setCompleteness(completeness));
        };

    const setProductsType =
        (productsType: Domain.SelectedProductType | ''): ThunkAction =>
        async dispatch => {
            await dispatch(stateOptions.reducerActions.setProductsType(productsType));
        };

    const setPaginationPage =
        (page: Domain.Pagination['page']): ThunkAction =>
        async dispatch => {
            await dispatch(stateOptions.reducerActions.setPaginationPage(page));
        };

    const setFilters =
        (filters: Filters): ThunkAction =>
        async dispatch => {
            await dispatch(stateOptions.reducerActions.setFilters(filters));
        };

    const loadProducts =
        (updateURL?: boolean, locale?: Domain.Locale, ownership?: Domain.Ownership, force?: boolean): ThunkAction =>
        async (dispatch, getState, getNavigate) => {
            const state = getState();
            if (!locale) {
                locale = selectCurrentLocale(state);
            }
            const navigate = getNavigate();
            if (!ownership) {
                ownership = selectLoggedInUserOwnership(state);
            }
            const pagination = stateOptions.selectors.selectPagination(state);
            const search = stateOptions.selectors.selectSearch(state);
            const productCompleteness = stateOptions.selectors.selectCompleteness(state);
            const productsType = stateOptions.selectors.selectProductsType(state);
            const filters = stateOptions.selectors.selectFilters(state);

            const newRequestKey = JSON.stringify({
                ownership,
                locale,
                selection,
                pagination,
                search,
                productCompleteness,
                productsType,
                filters,
            });

            if (!force && previousRequestKey === newRequestKey) {
                return;
            }

            previousRequestKey = newRequestKey;

            if (updateURL && !stateOptions.baseURL) {
                throw new Error('No baseURL set in state');
            }

            if (updateURL && stateOptions.baseURL) {
                updateURLWithQueryParams({
                    baseURL: stateOptions.baseURL,
                    navigate,
                    pagination,
                    search,
                    filters,
                });
            } else {
                const products = await productSelectionApi.GetSelectedProducts(
                    ownership,
                    selection,
                    pagination,
                    locale,
                    search,
                    productCompleteness === '' ? undefined : productCompleteness,
                    productsType === '' ? undefined : productsType,
                    filters,
                );
                await dispatch(stateOptions.reducerActions.setProducts(products));
            }
        };

    const load =
        (options: { urlQuery: URLQuery }): ThunkAction =>
        async dispatch => {
            const savedSettings = getDataTableSavedSettings(stateOptions.dataTableSaveKey);
            if (savedSettings) {
                if (savedSettings.visibleColumns) {
                    await dispatch(stateOptions.reducerActions.setVisibleColumns(savedSettings.visibleColumns as SelectedProductProps[]));
                }
                if (savedSettings.sortableColumns) {
                    await dispatch(stateOptions.reducerActions.setSortableColumns(savedSettings.sortableColumns as SelectedProductProps[]));
                }
            }

            const queryParams = getOverviewQueryParameters({
                urlQuery: options.urlQuery,
            });

            if (queryParams.search !== null) {
                await dispatch(stateOptions.reducerActions.setSearch(queryParams.search));
            }

            if (queryParams.page !== null) {
                await dispatch(stateOptions.reducerActions.setPaginationPage(queryParams.page));
            }

            if (queryParams.filters && queryParams.filters.productCompleteness) {
                if (
                    Domain.ProductCompletenessFilterTypes.indexOf(queryParams.filters.productCompleteness) === -1 &&
                    queryParams.filters.productCompleteness !== ''
                ) {
                    throw new Error('Wrong productCompleteness filter');
                } else {
                    await dispatch(stateOptions.reducerActions.setCompleteness(queryParams.filters.productCompleteness || ''));
                }
            }

            if (queryParams.filters && queryParams.filters.productsType) {
                if (
                    queryParams.filters.productsType !== 'customProduct' &&
                    queryParams.filters.productsType !== 'availableProduct' &&
                    queryParams.filters.productsType !== ''
                ) {
                    throw new Error('Wrong products type filter');
                } else {
                    await dispatch(stateOptions.reducerActions.setProductsType(queryParams.filters.productsType || ''));
                }
            }

            if (queryParams.filters && queryParams.filters.productImageShotType) {
                if (
                    queryParams.filters.productImageShotType !== 'productshot' &&
                    queryParams.filters.productImageShotType !== 'packshot' &&
                    queryParams.filters.productImageShotType !== 'frontal' &&
                    queryParams.filters.productImageShotType !== ''
                ) {
                    throw new Error('Wrong products image shot type filter');
                } else {
                    await dispatch(
                        stateOptions.reducerActions.setFilters({ productImageShotType: queryParams.filters.productImageShotType }),
                    );
                }
            }

            if (queryParams.filters && queryParams.filters.hasImportedPrice) {
                if (queryParams.filters.hasImportedPrice !== 'true' && queryParams.filters.hasImportedPrice !== '') {
                    throw new Error('Wrong hasImportedPrice filter');
                } else {
                    await dispatch(stateOptions.reducerActions.setFilters({ hasImportedPrice: queryParams.filters.hasImportedPrice }));
                }
            }

            if (queryParams.filters && queryParams.filters.hasImportedPromoPrice) {
                if (queryParams.filters.hasImportedPromoPrice !== 'true' && queryParams.filters.hasImportedPromoPrice !== '') {
                    throw new Error('Wrong hasImportedPromoPrice filter');
                } else {
                    await dispatch(
                        stateOptions.reducerActions.setFilters({ hasImportedPromoPrice: queryParams.filters.hasImportedPromoPrice }),
                    );
                }
            }

            if (queryParams.filters && queryParams.filters.hasOverridePrice) {
                if (queryParams.filters.hasOverridePrice !== 'true' && queryParams.filters.hasOverridePrice !== '') {
                    throw new Error('Wrong hasOverridePrice filter');
                } else {
                    await dispatch(stateOptions.reducerActions.setFilters({ hasOverridePrice: queryParams.filters.hasOverridePrice }));
                }
            }

            if (queryParams.filters && queryParams.filters.hasOverridePromoPrice) {
                if (queryParams.filters.hasOverridePromoPrice !== 'true' && queryParams.filters.hasOverridePromoPrice !== '') {
                    throw new Error('Wrong hasOverridePromoPrice filter');
                } else {
                    await dispatch(
                        stateOptions.reducerActions.setFilters({ hasOverridePromoPrice: queryParams.filters.hasOverridePromoPrice }),
                    );
                }
            }

            if (queryParams.filters && queryParams.filters.hasLocalStock) {
                if (queryParams.filters.hasLocalStock !== 'true' && queryParams.filters.hasLocalStock !== '') {
                    throw new Error('Wrong hasLocalStock filter');
                } else {
                    await dispatch(stateOptions.reducerActions.setFilters({ hasLocalStock: queryParams.filters.hasLocalStock }));
                }
            }

            if (queryParams.filters && queryParams.filters.categoryIds) {
                await dispatch(stateOptions.reducerActions.setFilters({ categoryIds: queryParams.filters.categoryIds }));
            }

            if (queryParams.filters) {
                await dispatch(stateOptions.reducerActions.setFilters(queryParams.filters));
            }

            await dispatch(loadProducts());
        };

    return {
        setVisibleColumns,
        setSortableColumns,
        setSearch,
        setFilters,
        setCompleteness,
        setProductsType,
        setPaginationPage,
        loadProducts,
        load,
    };
}

export type OverviewActions = ReturnType<typeof makeActions>;

export function makeReducer(
    stateOptions: StateOptionsForReducer & {
        reducerActions: ReturnType<typeof makeReducerActions>;
    },
) {
    return createReducer(stateOptions.initialState || initialState, builder =>
        builder
            .addCase(stateOptions.reducerActions.setProducts, (state, action) => {
                state.products = action.payload;
            })
            .addCase(stateOptions.reducerActions.setPagination, (state, action) => {
                state.pagination = action.payload;
            })
            .addCase(stateOptions.reducerActions.setPaginationPage, (state, action) => {
                state.pagination.page = action.payload;
            })
            .addCase(stateOptions.reducerActions.setSearch, (state, action) => {
                state.search = action.payload;
            })
            .addCase(stateOptions.reducerActions.setFilters, (state, action) => {
                state.filters = action.payload;
            })
            .addCase(stateOptions.reducerActions.setCompleteness, (state, action) => {
                state.completeness = action.payload;
            })
            .addCase(stateOptions.reducerActions.setProductsType, (state, action) => {
                state.productsType = action.payload;
            })
            .addCase(stateOptions.reducerActions.setVisibleColumns, (state, action) => {
                state.visibleColumns = action.payload;
            })
            .addCase(stateOptions.reducerActions.setSortableColumns, (state, action) => {
                state.sortableColumns = action.payload;
            }),
    );
}

export function makeSelectors(stateOptions: StateOptionsForSelectors) {
    const selectProducts: Selector<RootState, Domain.SelectedProductsPage> = state => {
        const products = stateOptions.getState(state).products;

        if (!products) {
            throw new Error('Products not loaded');
        }

        return products;
    };

    const selectPagination: Selector<RootState, Domain.Pagination> = state => stateOptions.getState(state).pagination;
    const selectSearch: Selector<RootState, string> = state => stateOptions.getState(state).search;
    const selectCompleteness: Selector<RootState, Domain.ProductCompletenessFilter | ''> = state =>
        stateOptions.getState(state).completeness;
    const selectProductsType: Selector<RootState, Domain.SelectedProductType | ''> = state => stateOptions.getState(state).productsType;
    const selectVisibleColumns: Selector<RootState, undefined | SelectedProductProps[]> = state =>
        stateOptions.getState(state).visibleColumns;
    const selectFilters: Selector<any, Filters> = state => stateOptions.getState(state).filters;
    const selectSortableColumns: Selector<RootState, undefined | SelectedProductProps[]> = state =>
        stateOptions.getState(state).sortableColumns;
    return {
        selectProducts,
        selectPagination,
        selectSearch,
        selectCompleteness,
        selectProductsType,
        selectVisibleColumns,
        selectFilters,
        selectSortableColumns,
    };
}
