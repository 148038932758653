import {
    Pagination,
    Sorting,
    Ownership,
    CreateSchedulePayload,
    UpdateSchedulePayload,
    SchedulesPage,
    Schedule,
    ScheduleDetails,
    Device,
    DeviceSchedule,
    DevicesSchedule,
} from '@/Domain';

import Endpoint from './Endpoint';

export default class ScheduleEndpoint extends Endpoint {
    public async GetSchedules(
        ownership: Ownership,
        pagination: Pagination,
        sorting?: Sorting,
        search?: string,
        filters?: {
            name?: string;
            priority?: '1' | '2' | '3' | '4';
            branchId?: Schedule['branchId'];
            companyId?: Schedule['companyId'];
            isActive?: Schedule['isActive'];
        },
    ): Promise<SchedulesPage> {
        let url = '/schedule/overview';
        if (ownership.type !== 'all') {
            url = `/schedule/${ownership.type}/${ownership.ownerId}/overview`;
        }

        const response = await this.client.get({
            url,
            params: {
                pagination,
                sorting,
                search,
                filters,
            },
        });

        this.guardResponseSchema('#/definitions/SchedulesPage', response);

        return response;
    }

    public async GetScheduleDetails(scheduleId: Schedule['scheduleId']): Promise<ScheduleDetails> {
        const response = await this.client.get({
            url: '/schedule/details/' + scheduleId,
        });

        this.guardResponseSchema('#/definitions/ScheduleDetails', response);

        return response;
    }

    public async CreateSchedule(payload: CreateSchedulePayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/CreateSchedule',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Schedule\\UpdateSchedule\\UpdateSchedule',
                payload,
            },
        });
    }

    public async UpdateSchedule(payload: UpdateSchedulePayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/UpdateSchedule',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Schedule\\UpdateSchedule\\UpdateSchedule',
                payload,
            },
        });
    }

    public async GetScheduleForDevice(): Promise<DeviceSchedule> {
        const response = await this.client.get({
            url: '/schedule/device',
        });

        this.guardResponseSchema('#/definitions/DeviceSchedule', response);

        return response;
    }

    public async GetScheduleForDevicesOnDate(deviceIds: Device['deviceId'][], date: string): Promise<DevicesSchedule> {
        const response = await this.client.get({
            url: `/schedule/devices/${deviceIds}/on/${date}`,
        });

        this.guardResponseSchema('#/definitions/DevicesSchedule', response);

        return response;
    }

    public async RemoveSchedule(scheduleId: Schedule['scheduleId']): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/RemoveSchedule',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Schedule\\RemoveSchedule\\RemoveSchedule',
                payload: {
                    scheduleId,
                },
            },
        });
    }
}
