import * as React from 'react';

import ControlledSelect, { ControlledSelectPropsWithValue } from './ControlledSelect';
import UncontrolledSelect, { UncontrolledSelectProps } from './UncontrolledSelect';

export type SelectProps = ControlledSelectPropsWithValue | UncontrolledSelectProps;

class Select extends React.PureComponent<SelectProps> {
    render() {
        if (this.isControlled(this.props)) {
            return <ControlledSelect {...this.props} />;
        }

        return <UncontrolledSelect {...this.props} />;
    }

    private isControlled(props: SelectProps): props is ControlledSelectPropsWithValue {
        return (props as ControlledSelectPropsWithValue).hasOwnProperty('value');
    }
}

export default React.forwardRef<HTMLSelectElement, SelectProps>((props, ref) => {
    return (
        <Select
            forwardRef={ref}
            {...props}
        />
    );
});
