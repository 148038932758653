import { Dashboard } from '@/Domain';

import Endpoint from './Endpoint';

export default class DashboardEndpoint extends Endpoint {
    public async GetDashboard(): Promise<Dashboard> {
        const dashboard = await this.client.get({
            url: '/dashboard',
        });

        this.guardResponseSchema('#/definitions/Dashboard', dashboard);

        return dashboard;
    }
}
