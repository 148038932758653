/*export interface PlcJobStatus {
    jobCode: string,
    status: 'busy' | 'evaluating' | 'eojok' | 'eojnok' | 'idle' | 'valid' | 'invalid'
    mode: string
    state: string
    errorCode: string
    startNewJobStatus: 'sended' | 'plcIsNotReady'
}*/

export interface PlcJobHandleStatus {
    status: boolean
    statusId: number | string
}

export interface PlcHandleValue {
    status?: {
        boxDetect?: {
            status?: boolean
            counter?: number | boolean
        }
        emergency?: {
            stopCircuitClosed?: boolean
            pilzStatus?: boolean
            hmiStopbtn?: boolean
        }
        plcDevice?: {
            localCommIsConnectedWithPLC?: boolean
            adsState?: string
            deviceState?: string
        }
        sleeping?: boolean
        upsBattDischarged?: boolean
        bUPS_BattOperation?: boolean
        shutdownMTMATIC?: boolean
        led?: boolean
        transactionBusy?: boolean
        techinalFrontDoorOpen?: boolean
        machineType?: number
        prgVersion?: number
        plcUpTime?: number
        functionButton?: boolean
    }
    MTMATIC?: {
        status?: {
            mode: string
            state: string
            errorCode: string
        }
        job?: {
            state: string
            reqCode: string
            ackCode: string
            errorCode: string
        }
    }
    MTMATIC_CV?: {
        status?: {
            state: string
            errorCode: string
        }
        job?: {
            state: string
            reqCode: string
            ackCode: string
            errorCode: string
        }
    }
    MTMATIC_F_DOOR?: {
        status?: {
            state: string
            errorCode: string
        }
        job?: {
            state: string
            reqCode: string
            ackCode: string
            errorCode: string
        }
    }
    MTMATIC_R_DOOR?: {
        status?: {
            state: string
            errorCode: string
        }
        job?: {
            state: string
            reqCode: string
            ackCode: string
            errorCode: string
        }
    }
    messages?: Array<PlcMessages>
}

export interface ReceivedPlcMessage {
    MT_MATIC?: {
        iMode: string
        iState: string
        bError: string
        Job?: {
            iState: string
            iReqCode: string
            iAckCode: string
            iErrorCode: string
        }
    }
    MT_MATIC_CV?: {
        iState: string
        bError: string
        Job?: {
            iState: string
            iReqCode: string
            iAckCode: string
            iErrorCode: string
        }
    }
    MT_MATIC_F_DOOR?: {
        iState: string
        bError: string
        Job?: {
            iState: string
            iReqCode: string
            iAckCode: string
            iErrorCode: string
        }
    }
    MT_MATIC_R_DOOR?: {
        iState: string
        bError: string
        Job?: {
            iState: string
            iReqCode: string
            iAckCode: string
            iErrorCode: string
        }
    }
    MT_MATIC_DOOR?: {
        iState: string
        bError: string
        Job?: {
            iState: string
            iReqCode: string
            iAckCode: string
            iErrorCode: string
        }
    }
    MT_MATIC_SWITCH?: {
        iState: string
        bError: string
        Job?: {
            iState: string
            iReqCode: string
            iAckCode: string
            iErrorCode: string
        }
    }
    plc_msg?: []
    status?: {
        Connection: boolean
        AdsState: string
        DeviceState: string
    }
    command?: {
        variable: string
        value: boolean | number
    }
}

export interface PlcMessages {
    code: string | number
    name: string
    level: string,
    timestamp: number
}

export interface PlcStatus {
    status: {
        sleeping: boolean
        upsBattDischarged: boolean
        bUPS_BattOperation: boolean
        emergency: {
            stopCircuitClosed: boolean
            hmiPilzStatus: boolean
            hmiEStopBtn1: boolean
            activated: boolean
            status: 'activated' | 'readyToRelease' | 'busyWithRelease' | 'idle'
        }
        released: boolean
        releaseStatus: 'notReadyForRelease' | 'readyToRelease' | 'busyWithRelease' | 'released'
        shutdownMTMATIC: boolean
        boxDetect: {
            sensor: boolean
            plcCounter: number
            softwareCounter: number
        }
        plcDevice: {
            localCommIsConnectedWithPLC: boolean
            adsState: string
            deviceState: string
        }
        led: boolean
        transactionBusy: boolean
        techinalFrontDoorOpen: boolean
        machineType: number
        prgVersion: number
        plcUpTime: number
        functionButton: boolean
    }
    MTMATIC: {
        status: {
            mode: string
            state: string
            errorCode: string
        }
        job: {
            state: string
            reqCode: string
            ackCode: string
            errorCode: string
        }
    }
    MTMATIC_CV: {
        status: {
            state: string
            errorCode: string
        }
        job: {
            state: string
            reqCode: string
            ackCode: string
            errorCode: string
        }
    }
    MTMATIC_F_DOOR: {
        status: {
            state: string
            errorCode: string
        }
        job: {
            state: string
            reqCode: string
            ackCode: string
            errorCode: string
        }
    }
    MTMATIC_R_DOOR: {
        status: {
            state: string
            errorCode: string
        },
        job: {
            state: string
            reqCode: string
            ackCode: string
            errorCode: string
        }
    }
    statusEventsReceivedSinceLocalcommConnectionWasEstablished: {
        sleeping: boolean
        upsBattDischarged: boolean
        bUPS_BattOperation: boolean
        emergency: {
            stopCircuitClosed: boolean
            hmiPilzStatus: boolean
            hmiEStopBtn1: boolean
        }
        shutdownMTMATIC: boolean
        boxDetect: {
            sensor: boolean
            plcCounter: boolean
        }
        plcDevice: {
            adsState: boolean
            deviceState: boolean
        }
        led: boolean
        transactionBusy: boolean
        techinalFrontDoorOpen: boolean
        machineType: boolean
        prgVersion: boolean
        plcUpTime: boolean
        functionButton: boolean,
        MTMATIC:{
            status:{
                mode: boolean
                state: boolean
                errorCode: boolean
            }
            job: {
                state: boolean
                reqCode: boolean
                ackCode: boolean
                errorCode: boolean
            }
        }
        MTMATIC_CV:{
            status:{
                mode: boolean
                state: boolean
                errorCode: boolean
            }
            job: {
                state: boolean
                reqCode: boolean
                ackCode: boolean
                errorCode: boolean
            }
        }
        MTMATIC_F_DOOR:{
            status:{
                mode: boolean
                state: boolean
                errorCode: boolean
            }
            job: {
                state: boolean
                reqCode: boolean
                ackCode: boolean
                errorCode: boolean
            }
        }
        MTMATIC_R_DOOR:{
            status:{
                mode: boolean
                state: boolean
                errorCode: boolean
            }
            job: {
                state: boolean
                reqCode: boolean
                ackCode: boolean
                errorCode: boolean
            }
        }
    },
    plcMessagesCodes: Array<number>
    messages: Array<PlcMessages>
    errorPLCMessageStatus: {
        [emergencyCircuitInterrupted:string]: boolean
        unlockEmergencyButton: boolean
        fuseActuatorsInterrupted: boolean
        preAlarmUPSBatteryDischarged: boolean
        dcPowerSupplyDischarged: boolean,
        technicalDoorLockNotReacting: boolean
        rearDoorLockNotReacting: boolean
        technicalDoorLock1NotReacting: boolean
        technicalDoorLock2NotReacting: boolean
        cvAxisError: boolean
        cvAxisBlocked: boolean
        cvAxisErrorDuringMCFunction: boolean
        deliveryDoorAxisError: boolean
        deliveryDoorAxisBlocked: boolean
        deliveryDoorAxisErrorDuringMCFunction: boolean
        deliveryDoorAxisBlockedAfterRetries: boolean
        rearDoorAxisError: boolean
        rearDoorAxisBlocked: boolean
        rearDoorAxisErrorDuringMCFunction: boolean
        rearDoorHomingSensorNotDetectedTimeout: boolean
        ventilationAxisError: boolean
        ventilationAxisBlocked: boolean
        ventilationAxisErrorDuringMCFunction: boolean
    }
    warningPLCMessageStatus: {
        [upsFailure: string]: boolean
        inBatteryOperation: boolean
        plcAutomaticResetInitiated: boolean
        adsConnectionToRobotFailed: boolean
        deliveryDoorOpenTimeout: boolean
        rearDoorHomingSensorNotDetected: boolean
    }
    infoPLCMessageStatus: {
        [sleeping:string]: boolean
        resetButtonPushed: boolean
        hostRequestReset: boolean
        hostRequestStartManual: boolean
        hostRequestStartAuto: boolean
        hostRequestStop: boolean
        hostRequestHoming: boolean
        startPLCUpdate: boolean
        jobRequestDisabledDuringUpdateOrBatteryOperation: boolean
        technicalDoorOpen: boolean
        techinicalDoorLock1Open: boolean
        techinicalDoorLock2Open: boolean
        rearDoorOpen: boolean
        rearDoorLockOpen: boolean
        deliveryDoorOpen: boolean
        deliveryDoorLockOpen: boolean
        cvAxisIsMoving: boolean
        deliveryDoorAxisIsMoving: boolean
        rearDoorAxisIsMoving: boolean
        ventilationAxisIsMoving: boolean
        cvAxisPositionUnknown: boolean
        deliveryDoorAxisPositionUnknown: boolean
        rearDoorAxisPositionUnknown: boolean
        ventilationAxisPositionUnknown: boolean
        cvAxisPositionNotCalibrated: boolean
        deliveryDoorAxisPositionNotCalibrated: boolean
        rearDoorAxisPositionNotCalibrated: boolean
        ventilationAxisPositionNotCalibrated: boolean
    }
    debugPLCMessageStatus: {
        [waitingForHomingSensorDeliveryDoor:string]: boolean
        waitingForHomingSensorRearDoor: boolean
        openingTechnicalDoorLock1: boolean
        openingTechnicalDoorLock2: boolean
        openingDeliveryDoorLock: boolean
        openingRearDoorLock: boolean
        closingTechnicalDoorLock1: boolean
        closingTechnicalDoorLock2: boolean
        closingDeliveryDoorLock: boolean
        closingRearDoorLock: boolean
        deliveryDoorDetectedObstruction: boolean
    }
    currentJobId: number
    currentJobCode: number
    currentJobIsAccepted: boolean
    currentJobIsEvaluated: boolean
    currentJobIsNotAccepted: boolean
    currentJobIsDone: boolean
    currentJobIsFailed: boolean
    currentJobIsBusy: boolean
}

export interface PlcChangeRunningModeProcess {
    type: 'reset' | 'stop' | 'resetPlcError' | 'auto' | 'man' | 'none'
    nextStep: 'none' | 'stop' | 'reset' | 'auto' | 'man' | 'done' | 'failed'
    busyWith: 'none' | 'stop' | 'reset' | 'auto' | 'man'
    busyWithType: boolean
    cancel: boolean
}

export interface PlcJobStatus{
    jobId: number
    jobCode: number
    jobIsAccepted: boolean
    jobIsEvaluated: boolean
    jobIsNotAccepted: boolean
    jobIsDone: boolean
    jobIsFailed: boolean
    jobIsBusy: boolean
    status: string
    mode: string
    state: string
    errorCode: string
    job: {
        state: string
        reqCode: string
        ackCode: string
        errorCode: string
    }
    createdTime: number
    updatedTime: number
    isTimeOut: boolean
    statusId: string | number
}

export const PLCSTATUS_DEFAULT: PlcStatus = {
    status: {
        sleeping: false,
        upsBattDischarged: false,
        bUPS_BattOperation: false,
        emergency: {
            stopCircuitClosed: true,
            hmiPilzStatus: true,
            hmiEStopBtn1: false,
            activated: false,
            status: 'idle'
        },
        released: false,
        releaseStatus: 'readyToRelease',
        shutdownMTMATIC: false,
        boxDetect: {
            sensor: false,
            plcCounter: 0,
            softwareCounter: 0
        },
        plcDevice: {
            localCommIsConnectedWithPLC: false,
            adsState: 'run',
            deviceState: 'normal'
        },
        led: false,
        transactionBusy: false,
        techinalFrontDoorOpen: false,
        machineType: 0,
        prgVersion: 0,
        plcUpTime: 0,
        functionButton: false
    },
    MTMATIC: {
        status: {
            mode: 'armed',
            state: 'idle',
            errorCode: ''
        },
        job: {
            state: 'ejok',
            reqCode: '',
            ackCode: '',
            errorCode: ''
        }
    },
    MTMATIC_CV: {
        status: {
            state: 'idle',
            errorCode: ''
        },
        job: {
            state: 'ejok',
            reqCode: '',
            ackCode: '',
            errorCode: ''
        }
    },
    MTMATIC_F_DOOR: {
        status: {
            state: 'idle',
            errorCode: ''
        },
        job: {
            state: 'ejok',
            reqCode: '',
            ackCode: '',
            errorCode: ''
        }
    },
    MTMATIC_R_DOOR: {
        status: {
            state: 'idle',
            errorCode: ''
        },
        job: {
            state: 'ejok',
            reqCode: '',
            ackCode: '',
            errorCode: ''
        }
    },
    plcMessagesCodes: [],
    messages: [],
    errorPLCMessageStatus: {
        emergencyCircuitInterrupted: false,
        unlockEmergencyButton: false,
        fuseActuatorsInterrupted: false,
        preAlarmUPSBatteryDischarged: false,
        dcPowerSupplyDischarged: false,
        technicalDoorLockNotReacting: false,
        rearDoorLockNotReacting: false,
        technicalDoorLock1NotReacting: false,
        technicalDoorLock2NotReacting: false,
        cvAxisError: false,
        cvAxisBlocked: false,
        cvAxisErrorDuringMCFunction: false,
        deliveryDoorAxisError: false,
        deliveryDoorAxisBlocked: false,
        deliveryDoorAxisErrorDuringMCFunction: false,
        deliveryDoorAxisBlockedAfterRetries: false,
        rearDoorAxisError: false,
        rearDoorAxisBlocked: false,
        rearDoorAxisErrorDuringMCFunction: false,
        rearDoorHomingSensorNotDetectedTimeout: false,
        ventilationAxisError: false,
        ventilationAxisBlocked: false,
        ventilationAxisErrorDuringMCFunction: false,
    },
    warningPLCMessageStatus: {
        upsFailure: false,
        inBatteryOperation: false,
        plcAutomaticResetInitiated: false,
        adsConnectionToRobotFailed: false,
        deliveryDoorOpenTimeout: false,
        rearDoorHomingSensorNotDetected: false,
    },
    infoPLCMessageStatus: {
        sleeping: false,
        resetButtonPushed: false,
        hostRequestReset: false,
        hostRequestStartManual: false,
        hostRequestStartAuto: false,
        hostRequestStop: false,
        hostRequestHoming: false,
        startPLCUpdate: false,
        jobRequestDisabledDuringUpdateOrBatteryOperation: false,
        technicalDoorOpen: false,
        techinicalDoorLock1Open: false,
        techinicalDoorLock2Open: false,
        rearDoorOpen: false,
        rearDoorLockOpen: false,
        deliveryDoorOpen: false,
        deliveryDoorLockOpen: false,
        cvAxisIsMoving: false,
        deliveryDoorAxisIsMoving: false,
        rearDoorAxisIsMoving: false,
        ventilationAxisIsMoving: false,
        cvAxisPositionUnknown: false,
        deliveryDoorAxisPositionUnknown: false,
        rearDoorAxisPositionUnknown: false,
        ventilationAxisPositionUnknown: false,
        cvAxisPositionNotCalibrated: false,
        deliveryDoorAxisPositionNotCalibrated: false,
        rearDoorAxisPositionNotCalibrated: false,
        ventilationAxisPositionNotCalibrated: false,
    },
    debugPLCMessageStatus: {
        waitingForHomingSensorDeliveryDoor: false,
        waitingForHomingSensorRearDoor: false,
        openingTechnicalDoorLock1: false,
        openingTechnicalDoorLock2: false,
        openingDeliveryDoorLock: false,
        openingRearDoorLock: false,
        closingTechnicalDoorLock1: false,
        closingTechnicalDoorLock2: false,
        closingDeliveryDoorLock: false,
        closingRearDoorLock: false,
        deliveryDoorDetectedObstruction: false
    },
    currentJobId: 0,
    currentJobCode: 0,
    currentJobIsAccepted: false,
    currentJobIsEvaluated: false,
    currentJobIsNotAccepted: false,
    currentJobIsDone: false,
    currentJobIsFailed: false,
    currentJobIsBusy: false,
    statusEventsReceivedSinceLocalcommConnectionWasEstablished: {
        sleeping: false,
        upsBattDischarged: false,
        bUPS_BattOperation: false,
        emergency: {
            stopCircuitClosed: false,
            hmiPilzStatus: false,
            hmiEStopBtn1: false,
        },
        shutdownMTMATIC: false,
        boxDetect: {
            sensor: false,
            plcCounter: false,
        },
        plcDevice: {
            adsState: false,
            deviceState: false,
        },
        led: false,
        transactionBusy: false,
        techinalFrontDoorOpen: false,
        machineType: false,
        prgVersion: false,
        plcUpTime: false,
        functionButton: false,
        MTMATIC:{
            status:{
                mode: false,
                state: false,
                errorCode: false
            },
            job: {
                state: false,
                reqCode: false,
                ackCode: false,
                errorCode: false
            }
        },
        MTMATIC_CV:{
            status:{
                mode: false,
                state: false,
                errorCode: false
            },
            job: {
                state: false,
                reqCode: false,
                ackCode: false,
                errorCode: false
            }
        },
        MTMATIC_F_DOOR:{
            status:{
                mode: false,
                state: false,
                errorCode: false
            },
            job: {
                state: false,
                reqCode: false,
                ackCode: false,
                errorCode: false
            }
        },
        MTMATIC_R_DOOR:{
            status:{
                mode: false,
                state: false,
                errorCode: false
            },
            job: {
                state: false,
                reqCode: false,
                ackCode: false,
                errorCode: false
            }
        }
    },
}

export const PLCSUPPORTEDJOBCODES: number[] = [201, 202, 203, 204, 205, 206, 207];

export const PLCMESSAGEERRORCODES: {[key: string]: number} = {
    emergencyCircuitInterrupted: 1,
    unlockEmergencyButton: 2,
    fuseActuatorsInterrupted: 10,
    preAlarmUPSBatteryDischarged: 11,
    dcPowerSupplyDischarged: 12,
    technicalDoorLockNotReacting: 21,
    rearDoorLockNotReacting: 22,
    technicalDoorLock1NotReacting: 23,
    technicalDoorLock2NotReacting: 24,
    cvAxisError: 50,
    cvAxisBlocked: 51,
    cvAxisErrorDuringMCFunction: 52,
    deliveryDoorAxisError: 70,
    deliveryDoorAxisBlocked: 71,
    deliveryDoorAxisErrorDuringMCFunction: 72,
    deliveryDoorAxisBlockedAfterRetries: 75,
    rearDoorAxisError: 90,
    rearDoorAxisBlocked: 91,
    rearDoorAxisErrorDuringMCFunction: 92,
    rearDoorHomingSensorNotDetectedTimeout: 100,
    ventilationAxisError: 110,
    ventilationAxisBlocked: 111,
    ventilationAxisErrorDuringMCFunction: 112
}

export const PLCMESSAGEWARNINGCODES: {[key: string]: number} = {
    upsFailure: 200,
    inBatteryOperation: 201,
    plcAutomaticResetInitiated: 202,
    adsConnectionToRobotFailed: 203,
    deliveryDoorOpenTimeout: 220,
    rearDoorHomingSensorNotDetected: 230,
}

export const PLCMESSAGEINFOCODES: {[key: string]: number} = {
    sleeping: 500,
    resetButtonPushed: 501,
    hostRequestReset: 502,
    hostRequestStartManual: 503,
    hostRequestStartAuto: 504,
    hostRequestStop: 505,
    hostRequestHoming: 506,
    startPLCUpdate: 507,
    jobRequestDisabledDuringUpdateOrBatteryOperation: 508,
    technicalDoorOpen: 520,
    techinicalDoorLock1Open: 528,
    techinicalDoorLock2Open: 529,
    rearDoorOpen: 521,
    rearDoorLockOpen: 527,
    deliveryDoorOpen: 522,
    deliveryDoorLockOpen: 526,
    cvAxisIsMoving: 601,
    deliveryDoorAxisIsMoving: 602,
    rearDoorAxisIsMoving: 603,
    ventilationAxisIsMoving: 604,
    cvAxisPositionUnknown: 621,
    deliveryDoorAxisPositionUnknown: 622,
    rearDoorAxisPositionUnknown: 623,
    ventilationAxisPositionUnknown: 624,
    cvAxisPositionNotCalibrated: 631,
    deliveryDoorAxisPositionNotCalibrated: 632,
    rearDoorAxisPositionNotCalibrated: 633,
    ventilationAxisPositionNotCalibrated: 634
}

export const PLCMESSAGEDEBUGCODES: {[key: string]: number} = {
    waitingForHomingSensorDeliveryDoor: 900,
    waitingForHomingSensorRearDoor: 901,
    openingTechnicalDoorLock1: 911,
    openingTechnicalDoorLock2: 912,
    openingDeliveryDoorLock: 913,
    openingRearDoorLock: 914,
    closingTechnicalDoorLock1: 916,
    closingTechnicalDoorLock2: 917,
    closingDeliveryDoorLock: 918,
    closingRearDoorLock: 919,
    deliveryDoorDetectedObstruction: 930
}