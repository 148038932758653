import {
    Pagination,
    Sorting,
    Export,
    ExportType,
    InitializeProductExportPayload,
    InitializeOrderExportPayload,
    InitializeSearchActionsExportPayload,
    InitializeStockReplenishExportPayload,
    DeleteExportsPayload,
    ExportsPage,
} from '@/Domain';

import Endpoint from './Endpoint';

export default class ExportEndpoint extends Endpoint {
    public async GetExports(
        pagination: Pagination,
        sorting: Sorting,
        filters?: {
            exportType?: ExportType;
        },
        search?: string,
    ): Promise<ExportsPage> {
        const response = await this.client.get({
            url: '/export/overview',
            params: {
                pagination,
                sorting,
                filters,
                search,
            },
        });

        this.guardResponseSchema('#/definitions/ExportsPage', response);

        return response;
    }

    public async InitializeProductExport(payload: InitializeProductExportPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/InitializeProductExport',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Export\\InitializeProductExport\\InitializeProductExport',
                payload,
            },
        });
    }

    public async InitializeOrderExport(payload: InitializeOrderExportPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/InitializeOrderExport',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Order\\OrderExport\\InitializeOrderExport\\InitializeOrderExport',
                payload,
            },
        });
    }

    public async InitializeSearchActionsExport(payload: InitializeSearchActionsExportPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/InitializeSearchActionExport',
            body: {
                commandName:
                    'App\\Domain\\WriteModel\\Search\\SearchAction\\SearchActionExport\\InitializeSearchActionExport\\InitializeSearchActionExport',
                payload,
            },
        });
    }
    public async InitializeStockReplenishExport(payload: InitializeStockReplenishExportPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/InitializeRobotStockExport',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Device\\Robot\\InitializeRobotStockExport\\InitializeRobotStockExport',
                payload,
            },
        });
    }
    public GetExportDownloadURL(exportId: Export['exportId']): string {
        return this.client.buildApiURL(`/export/download/${exportId}`, true);
    }

    public async BulkDeleteExports(payload: DeleteExportsPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/BulkDeleteExports',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Export\\DeleteExports\\DeleteExports',
                payload,
            },
        });
    }
}
