import * as React from 'react';

import { Domain } from 'api';
import { FrameDepthSorting } from 'editor-canvas';
import {
    Card,
    CardItem,
    Button,
    RadioGroup,
    RadioButton,
    Container,
    Row,
    Col,
    RTControls,
    RTValue,
    Select,
    HTMLEditorToolbar,
} from 'ui-components';
import { HTMLEditorValue, getScreenSizeInPX } from 'utils';

import { Actions, store, getPresetColors } from '../state';
import { Frame, ImageFrameCrop, MediaItemPickerRenderer } from '../types';
import { findFrame, getProductImages } from '../utils';

import styles from './styles/SettingsDialog.scss';

export default function FrameSettingsDialog(props: {
    dialog: {
        frame: Frame;
    };
    gettext: (text: string) => string;
    renderMediaItemPicker: MediaItemPickerRenderer;
    localizeProductName: (name: Domain.LocalizedValue) => string;
}) {
    const { gettext, renderMediaItemPicker } = props;
    const { state, dispatch } = React.useContext(store);

    const frame = findFrame(state.canvas.frames, props.dialog.frame.frameId);
    if (!frame) {
        return null;
    }

    const scaleFactor = getScreenSizeInPX(state.screenResolution).width / state.canvas.width;

    return (
        <Card
            elevated={true}
            className={styles.ProductWallDesignerSettingsDialog}
            onMouseUp={event => {
                event.nativeEvent.stopImmediatePropagation();
            }}
        >
            {frame.type !== 'text' ? (
                <CardItem>
                    <RadioGroup
                        value={frame.type || 'none'}
                        onChange={(frameType: Frame['type']) => {
                            dispatch({
                                type: Actions.CanvasActions.HISTORY_SAVE,
                            });

                            dispatch({
                                type: Actions.SET_FRAME_TYPE,
                                frameType,
                                frameId: frame.frameId,
                            });
                        }}
                    >
                        <Container gutter={24}>
                            <Row vAlign="center">
                                <Col>
                                    <label className="InputBaseLabel">{gettext('Type')}</label>
                                </Col>
                                <Col>
                                    <RadioButton value="richText">{gettext('Text')}</RadioButton>
                                </Col>
                                <Col>
                                    <RadioButton value="image">{gettext('Image')}</RadioButton>
                                </Col>
                                <Col>
                                    <RadioButton value="video">{gettext('Video')}</RadioButton>
                                </Col>
                                <Col>
                                    <RadioButton value="hotSpot">{gettext('Hotspot')}</RadioButton>
                                </Col>
                            </Row>
                        </Container>
                    </RadioGroup>
                </CardItem>
            ) : null}

            {frame.type === 'text' ? (
                <CardItem>
                    <RTControls
                        value={new RTValue(frame.text || '', frame.textStyle)}
                        onTextChangeStart={() => {
                            dispatch({
                                type: Actions.CanvasActions.HISTORY_SAVE,
                            });
                        }}
                        onChange={newValue => {
                            dispatch({
                                type: Actions.SET_FRAME_TEXT,
                                frameId: frame.frameId,
                                text: frame.text || '',
                                textStyle: newValue.options || {
                                    size: 16,
                                },
                            });
                        }}
                        strikethrough={true}
                        verticalAlign={true}
                        fontLabel={gettext('Font')}
                        fontSearchPlaceholder={gettext('Font name')}
                        fontNoSearchResultsPlaceholder={gettext('No matching font found')}
                        fontSearchInProgressPlaceholder={gettext('Searching...')}
                        onEyedropStart={(changeStart, change, changeComplete) => {
                            dispatch({
                                type: Actions.SET_EYEDROPPER_ENABLED,
                                eyedropperEnabled: true,
                                changeHandlers: {
                                    changeStart,
                                    change,
                                    changeComplete,
                                },
                            });
                        }}
                        onEyedropEnd={() => {
                            dispatch({
                                type: Actions.SET_EYEDROPPER_ENABLED,
                                eyedropperEnabled: false,
                            });
                        }}
                        presetColors={getPresetColors(state)}
                    />
                </CardItem>
            ) : null}

            {frame.type === 'richText' ? (
                <CardItem>
                    <HTMLEditorToolbar
                        gettext={gettext}
                        refocus={() => undefined}
                        handleChange={newValue => {
                            dispatch({
                                type: Actions.SET_FRAME_HTML,
                                frameId: frame.frameId,
                                html: HTMLEditorValue.toString(newValue),
                            });
                        }}
                        visionToolbar={true}
                        onEyedropStart={(changeStart, change, changeComplete) => {
                            dispatch({
                                type: Actions.SET_EYEDROPPER_ENABLED,
                                eyedropperEnabled: true,
                                changeHandlers: {
                                    changeStart,
                                    change,
                                    changeComplete,
                                },
                            });
                        }}
                        onEyedropEnd={() => {
                            dispatch({
                                type: Actions.SET_EYEDROPPER_ENABLED,
                                eyedropperEnabled: false,
                            });
                        }}
                        presetColors={getPresetColors(state)}
                    />
                </CardItem>
            ) : null}

            {frame.type === 'image' || frame.type === 'video' ? (
                <CardItem>
                    {renderMediaItemPicker({
                        value: frame.mediaItemId,
                        onChange: (mediaItemId: string, videoDuration?: number) => {
                            dispatch({
                                type: Actions.CanvasActions.HISTORY_SAVE,
                            });

                            dispatch({
                                type: Actions.SET_FRAME_MEDIA_ITEM_ID,
                                frameId: frame.frameId,
                                mediaItemId,
                                videoDuration,
                            });
                        },
                        placeholder: frame.type === 'image' ? gettext('Select image') : gettext('Select video'),
                        type: frame.type,
                        desiredMinimumResolution: {
                            width: frame.width * scaleFactor,
                            height: frame.height * scaleFactor,
                        },
                    })}
                </CardItem>
            ) : null}

            {frame.type === 'image' ? (
                <CardItem>
                    <RadioGroup
                        value={frame.imageCrop || 'proportional-inside'}
                        onChange={(imageCrop: ImageFrameCrop) => {
                            dispatch({
                                type: Actions.CanvasActions.HISTORY_SAVE,
                            });

                            dispatch({
                                type: Actions.SET_FRAME_IMAGE_CROP,
                                imageCrop,
                                frameId: frame.frameId,
                            });
                        }}
                    >
                        <Container gutter={24}>
                            <Row vAlign="center">
                                <Col>
                                    <label className="InputBaseLabel">{gettext('Image fit')}</label>
                                </Col>
                                <Col>
                                    <RadioButton value="proportional-inside">{gettext('Proportional')}</RadioButton>
                                </Col>
                                <Col>
                                    <RadioButton value="stretch-fit">{gettext('Stretch')}</RadioButton>
                                </Col>
                            </Row>
                        </Container>
                    </RadioGroup>
                </CardItem>
            ) : null}

            {frame.type === 'hotSpot' && frame.hotSpotType === 'productDetails' ? (
                <CardItem>
                    <Select
                        label={gettext('Linked product')}
                        placeholder={gettext('Select a product to link to')}
                        value={frame.productId}
                        onChange={(productId: string) => {
                            dispatch({
                                type: Actions.CanvasActions.HISTORY_SAVE,
                            });

                            dispatch({
                                type: Actions.SET_FRAME_HOTSPOT_PRODUCT_ID,
                                productId,
                                frameId: frame.frameId,
                            });
                        }}
                        options={state.availableProducts.map(availableProduct => {
                            const productImages = getProductImages(availableProduct);
                            return {
                                value: availableProduct.productId,
                                label: (
                                    <span className={styles.HotspotProductSelectLabel}>
                                        <img
                                            src={productImages.frontalImage || productImages.productImage}
                                            alt=""
                                        />
                                        {availableProduct.localizedNames ? props.localizeProductName(availableProduct.localizedNames) : ''}
                                    </span>
                                ),
                            };
                        })}
                    />
                </CardItem>
            ) : null}

            <CardItem>
                <FrameDepthSorting
                    gettext={gettext}
                    frame={frame}
                />
            </CardItem>

            <CardItem>
                <Button
                    variant="tertiary"
                    variantSize="s"
                    onClick={event => {
                        event.stopPropagation();

                        dispatch({
                            type: Actions.CanvasActions.SET_EDITED_FRAME,
                            editedFrameId: undefined,
                        });
                    }}
                >
                    {gettext('Close')}
                </Button>
            </CardItem>
        </Card>
    );
}
