import { Domain } from 'api';

import { ThunkAction } from '@/action';
import { customPageApi } from '@/api';
import * as categoriesTreeState from '@/AvailableProduct/categoriesTreeState';
import { loadConfiguredIntegrationsCountByType } from '@/Integration/configuredIntegrationsTypeState';
import { makeActions, makeReducer, makeReducerActions, makeSelectors } from '@/makeDetailsState';
import { URLParams } from '@/routing';

import * as createAndUpdateState from './createAndUpdateState';

export const selectors = makeSelectors<Domain.CustomPage>({
    getState: rootState => rootState.customPage.update,
});

export const reducerActions = makeReducerActions<Domain.CustomPage>({
    reducerPrefix: '@customPage/update',
});

const baseActions = makeActions<Domain.CustomPage>({
    loadApi: options => customPageApi.GetCustomPageDetails(options.urlParams.customPageId),
    reducerActions,
    selectors,
});

export const actions = {
    ...baseActions,
    load:
        (options: { urlParams: URLParams }): ThunkAction =>
        async dispatch => {
            await Promise.all([
                dispatch(baseActions.load(options)),
                dispatch(loadConfiguredIntegrationsCountByType()),
                dispatch(categoriesTreeState.actions.load(options)),
                dispatch(createAndUpdateState.loadBranch()),
            ]);
        },
};

export const updateReducer = makeReducer<Domain.CustomPage>({
    reducerActions,
});
