import * as React from 'react';

import { AnchoredPortal, Icon } from 'ui-components';

import { store, Actions } from '../state';
import { CanvasFrame } from '../types';

import styles from './styles/FrameActions.scss';

export default function FrameActions(props: { frame: CanvasFrame }) {
    const { frame } = props;
    const { state, dispatch } = React.useContext(store);
    const actionsRef = React.useRef<HTMLDivElement>();

    const frameIsFocused = !!(state.resizedFrameId && state.resizedFrameId === frame.frameId);

    return (
        <div
            ref={actionsRef as any}
            className={styles.FrameActionsWrap}
        >
            {frameIsFocused && actionsRef.current ? (
                <AnchoredPortal anchorElement={actionsRef.current}>
                    <div className={styles.FrameActions}>
                        <a
                            href=""
                            onClick={event => event.preventDefault()}
                            onMouseUp={event => {
                                event.nativeEvent.stopImmediatePropagation();

                                dispatch({
                                    type: Actions.HISTORY_SAVE,
                                });

                                dispatch({
                                    type: Actions.REMOVE_FRAME,
                                    frameId: props.frame.frameId,
                                });
                            }}
                        >
                            <Icon type="action_delete" />
                        </a>
                    </div>
                </AnchoredPortal>
            ) : null}
        </div>
    );
}
