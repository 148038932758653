import { Domain } from 'api';

import { cmsApi } from '@/api';
import { makeActions, makeReducer, makeReducerActions, makeSelectors } from '@/makeOverviewState';

type CmsTagItemProps = keyof Domain.CmsTag;

export const defaultSorting: Domain.Sorting<CmsTagItemProps> = {
    field: 'createdOn',
    direction: 'descending',
};

export const pageSize = 30;

export const selectors = makeSelectors<Domain.CmsTag, CmsTagItemProps>({
    getState: rootState => rootState.cmsItem.publicCmsTagsOverviewReducer,
});

export const reducerActions = makeReducerActions<Domain.CmsTag, CmsTagItemProps>({
    reducerPrefix: '@cmsItem/publicCmsTagsState',
});

export const actions = makeActions<Domain.CmsTag, CmsTagItemProps>({
    dataTableSaveKey: 'publicCmsTags-v1',
    loadApi: options => cmsApi.GetPublicCmsTags(options.pagination, options.sorting, options.locale, options.search),
    defaultSorting,
    pageSize,
    reducerActions,
    selectors,
});

export const publicCmsTagsOverviewReducer = makeReducer<Domain.CmsTag, CmsTagItemProps>({
    defaultSorting,
    pageSize,
    reducerActions,
});
