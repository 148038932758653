import * as React from 'react';

export interface TouchAndMouseMove {
    x: number;
    y: number;
    movementX: number;
    movementY: number;
    pageX: number;
    pageY: number;
    startOffsetX?: number;
    startOffsetY?: number;
}

export interface SnapPoints {
    vertical: number[];
    horizontal: number[];
}

type MoveHandler<T> = (move: TouchAndMouseMove, payload?: T) => void;

export default class TouchAndMouseDragHandler<T = any> {
    private readonly moveHandler?: MoveHandler<T>;
    private readonly dragStartHandler?: MoveHandler<T>;
    private readonly dragEndHandler?: MoveHandler<T>;
    private readonly preventTouchPropagation?: boolean;
    private touchId?: number;
    private lastX?: number;
    private lastY?: number;
    private minimumMove?: number;
    private payloads: {
        [key: number]: T | undefined;
    } = {};
    private snapPoints?: SnapPoints;
    private snapDistance?: number;

    constructor(
        moveHandler?: MoveHandler<T>,
        dragStartHandler?: MoveHandler<T>,
        dragEndHandler?: MoveHandler<T>,
        preventTouchPropagation?: boolean,
    ) {
        this.moveHandler = moveHandler;
        this.dragStartHandler = dragStartHandler;
        this.dragEndHandler = dragEndHandler;
        this.preventTouchPropagation = preventTouchPropagation;
    }

    public setMinimumMove(minimumMove?: number) {
        this.minimumMove = minimumMove;
    }

    public handleMouseDown = (event: React.MouseEvent | MouseEvent, payload?: T): void => {
        event.preventDefault();
        event.stopPropagation();
        if ((event as React.MouseEvent).nativeEvent) {
            (event as React.MouseEvent).nativeEvent.stopImmediatePropagation();
        }

        document.addEventListener('mouseup', this.handleDocumentMouseUp, true);
        document.addEventListener('mousemove', this.handleDocumentMouseMove);

        this.handleMoveStart(event as MouseEvent, payload, event.currentTarget as Element);
    };

    public handleTouchStart = (event: React.TouchEvent<any> | TouchEvent, payload?: T): void => {
        event.preventDefault();

        if (this.preventTouchPropagation) {
            event.stopPropagation();
            if ((event as React.TouchEvent<any>).nativeEvent) {
                (event as React.TouchEvent<any>).nativeEvent.stopImmediatePropagation();
            }
        }

        document.addEventListener('touchend', this.handleDocumentTouchEnd, true);
        document.addEventListener('touchmove', this.handleDocumentTouchMove, { passive: false });
        document.addEventListener('touchforcechange', this.handleDocumentTouchForceChange, { passive: false });

        if (event.changedTouches.length === 0) {
            return;
        }

        const touch = event.changedTouches[0];

        this.touchId = touch.identifier;
        this.handleMoveStart(touch as Touch, payload, event.currentTarget as Element);
    };

    public forceStart() {
        this.touchId = 0;
        this.lastX = 0;
        this.lastY = 0;

        document.addEventListener('touchend', this.handleDocumentTouchEnd, true);
        document.addEventListener('touchmove', this.handleDocumentTouchMove, { passive: false });
        document.addEventListener('touchforcechange', this.handleDocumentTouchForceChange, { passive: false });
        document.addEventListener('mouseup', this.handleDocumentMouseUp, true);
        document.addEventListener('mousemove', this.handleDocumentMouseMove);
    }

    public forceStop() {
        document.removeEventListener('touchend', this.handleDocumentTouchEnd, true);
        document.removeEventListener('touchmove', this.handleDocumentTouchMove);
        document.removeEventListener('touchforcechange', this.handleDocumentTouchForceChange);
        document.removeEventListener('mouseup', this.handleDocumentMouseUp, true);
        document.removeEventListener('mousemove', this.handleDocumentMouseMove);

        this.lastX = undefined;
        this.lastY = undefined;
        this.touchId = undefined;
    }

    public setSnapPoints(snapPoints?: SnapPoints) {
        this.snapPoints = snapPoints;
    }

    public setSnapDistance(snapDistance?: number) {
        this.snapDistance = snapDistance;
    }

    public snapMove(
        move: TouchAndMouseMove,
        points: {
            point: number;
            vertical?: boolean;
        }[],
    ) {
        const offsetX = move.movementX;
        if (offsetX) {
            for (const point of points) {
                if (point.vertical) {
                    continue;
                }

                const snapPoint = this.findNearbySnapPoint(point.point + offsetX, false);
                if (snapPoint !== undefined) {
                    move.movementX = snapPoint - point.point;
                    break;
                }
            }
        }

        const offsetY = move.movementY;
        if (offsetY) {
            for (const point of points) {
                if (!point.vertical) {
                    continue;
                }

                const snapPoint = this.findNearbySnapPoint(point.point + offsetY, true);
                if (snapPoint !== undefined) {
                    move.movementY = snapPoint - point.point;
                    break;
                }
            }
        }
    }

    private findNearbySnapPoint(actualPoint: number, vertical = false) {
        if (!this.snapPoints) {
            return undefined;
        }

        const points = vertical ? this.snapPoints.vertical : this.snapPoints.horizontal;

        return points.find(snapPoint => Math.abs(snapPoint - actualPoint) <= (this.snapDistance || 0));
    }

    private handleDocumentMouseUp = (event: MouseEvent): void => {
        event.stopPropagation();

        document.removeEventListener('mouseup', this.handleDocumentMouseUp, true);
        document.removeEventListener('mousemove', this.handleDocumentMouseMove);

        this.handleMoveEnd(event);
    };

    private handleDocumentTouchEnd = (event: TouchEvent): void => {
        const touch = this.findCurrentTouch(event.changedTouches);

        if (!touch) {
            return;
        }

        this.touchId = undefined;
        document.removeEventListener('touchend', this.handleDocumentTouchEnd, true);
        document.removeEventListener('touchmove', this.handleDocumentTouchMove);
        document.removeEventListener('touchforcechange', this.handleDocumentTouchForceChange);

        this.handleMoveEnd(touch);
    };

    private handleDocumentMouseMove = (event: MouseEvent): void => {
        this.handleMove(event);
    };

    private handleDocumentTouchMove = (event: TouchEvent): void => {
        event.preventDefault();

        if (this.preventTouchPropagation) {
            event.stopPropagation();
        }

        const touch = this.findCurrentTouch(event.changedTouches);

        if (!touch) {
            return;
        }

        this.handleMove(touch);
    };

    private handleDocumentTouchForceChange = (event: TouchEvent): void => {
        event.preventDefault();
    };

    private handleMoveStart = (event: MouseEvent | Touch, payload?: T, target?: Element): void => {
        this.lastX = event.clientX;
        this.lastY = event.clientY;

        if (!target) {
            return;
        }

        const targetRect = target.getBoundingClientRect();

        const move = {
            x: event.clientX,
            y: event.clientY,
            movementX: 0,
            movementY: 0,
            pageX: event.pageX,
            pageY: event.pageY,
            startOffsetX: event.clientX - targetRect.x,
            startOffsetY: event.clientY - targetRect.y,
        };

        if (this.isTouchEvent(event)) {
            this.payloads[event.identifier] = payload;
        } else {
            this.payloads[-1] = payload;
        }

        if (this.dragStartHandler) {
            this.dragStartHandler(move, payload);
        }

        if (this.moveHandler) {
            this.moveHandler(move, payload);
        }
    };

    private handleMoveEnd = (event: MouseEvent | Touch): void => {
        const move = {
            x: event.clientX,
            y: event.clientY,
            movementX: this.lastX !== undefined ? event.clientX - this.lastX : 0,
            movementY: this.lastY !== undefined ? event.clientY - this.lastY : 0,
            pageX: event.pageX,
            pageY: event.pageY,
        };

        let payload;
        if (this.isTouchEvent(event)) {
            payload = this.payloads[event.identifier];
            this.payloads[event.identifier] = undefined;
        } else {
            payload = this.payloads[-1];
            this.payloads[-1] = undefined;
        }

        if (this.moveHandler) {
            this.moveHandler(move, payload);
        }

        this.lastX = undefined;
        this.lastY = undefined;

        if (this.dragEndHandler) {
            this.dragEndHandler(move, payload);
        }
    };

    private handleMove = (event: MouseEvent | Touch): void => {
        if (this.moveHandler) {
            const movementX = this.lastX !== undefined ? event.clientX - this.lastX : 0;
            const movementY = this.lastY !== undefined ? event.clientY - this.lastY : 0;

            if (this.minimumMove !== undefined && Math.abs(movementX) < this.minimumMove && Math.abs(movementY) < this.minimumMove) {
                return;
            }

            let payload;
            if (this.isTouchEvent(event)) {
                payload = this.payloads[event.identifier];
            } else {
                payload = this.payloads[-1];
            }

            this.moveHandler(
                {
                    x: event.clientX,
                    y: event.clientY,
                    movementX,
                    movementY,
                    pageX: event.pageX,
                    pageY: event.pageY,
                },
                payload,
            );
        }

        this.lastX = event.clientX;
        this.lastY = event.clientY;
    };

    private findCurrentTouch(touches: TouchList): Touch | undefined {
        if (this.touchId === undefined) {
            return undefined;
        }

        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < touches.length; i++) {
            if (touches[i].identifier === this.touchId) {
                return touches[i];
            }
        }

        return undefined;
    }

    private isTouchEvent(event: MouseEvent | Touch): event is Touch {
        return !((event as MouseEvent).type && (event as MouseEvent).type.indexOf('mouse') > -1);
    }
}
