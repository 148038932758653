import { createAction, createReducer, Selector } from '@reduxjs/toolkit';

import { Domain } from 'api';

import { ThunkAction, withPayloadType } from '@/action';
import { productSelectionApi } from '@/api';
import { selectLoggedInUserOwnership, selectLoggedInUserOwnershipIds } from '@/Authentication';
import * as categoryTreeState from '@/AvailableProduct/categoriesTreeState';
import { getDataTableSavedSettings, setDataTableSavedSettings, SavedSettings } from '@/dataTableSavedSettings';
import { selectCurrentLocale } from '@/I18n';
import { loadConfiguredIntegrationsCountByType } from '@/Integration/configuredIntegrationsTypeState';
import { Filters } from '@/makeOverviewState';
import { getOverviewQueryParameters, updateURLWithQueryParams, URLQuery, URLParams } from '@/routing';
import { RootState } from '@/store';

const SELECTED_PRODUCTS_OVERVIEW_DATA_TABLE_SAVE_KEY = 'selectedProductsOverview-v4';

type SelectedProductProps = keyof Domain.SelectedProduct | 'stockSourceIds' | 'name';
export const defaultSorting: Domain.Sorting<SelectedProductProps> = {
    field: 'name',
    direction: 'ascending',
};
export interface State {
    selectionType: Domain.ProductSelectionType;
    selectedProducts?: Domain.SelectedProductsPage;
    pagination: Domain.Pagination;
    search: string;
    visibleColumns: undefined | SelectedProductProps[];
    sortableColumns: undefined | SelectedProductProps[];
    productCompleteness: Domain.ProductCompletenessFilter | '';
    productsType: Domain.SelectedProductType | '';
    configuredIntegrationsCountByType: Domain.IntegrationsCountByType | undefined;
    filters: Filters;
    sorting: Domain.Sorting<SelectedProductProps>;
}

const initialState: State = {
    selectionType: 'inStoreProductSelection',
    pagination: {
        page: 1,
        size: 20,
    },
    search: '',
    productCompleteness: '',
    productsType: '',
    sortableColumns: undefined,
    visibleColumns: undefined,
    configuredIntegrationsCountByType: undefined,
    filters: {},
    sorting: defaultSorting,
};

const reducerActions = {
    setSelectionType: createAction('@productSelection/overview/setSelectionType', withPayloadType<Domain.ProductSelectionType>()),
    setSelectedProducts: createAction('@productSelection/overview/setSelectedProducts', withPayloadType<Domain.SelectedProductsPage>()),
    setPagination: createAction('@productSelection/overview/setPagination', withPayloadType<Domain.Pagination>()),
    setPaginationPage: createAction('@productSelection/overview/setPaginationPage', withPayloadType<Domain.Pagination['page']>()),
    setSearch: createAction('@productSelection/overview/setSearch', withPayloadType<string>()),
    setCompleteness: createAction('@productSelection/overview/setCompleteness', withPayloadType<Domain.ProductCompletenessFilter | ''>()),
    setProductsType: createAction('@productSelection/overview/setProductsType', withPayloadType<Domain.SelectedProductType | ''>()),
    setVisibleColumns: createAction('@productSelection/overview/setVisibleColumns', withPayloadType<SelectedProductProps[]>()),
    setSortableColumns: createAction('@productSelection/overview/setSortableColumns', withPayloadType<SelectedProductProps[]>()),

    setConfiguredIntegrationsType: createAction(
        '@productSelection/overview/setConfiguredIntegrationsType',
        withPayloadType<Domain.IntegrationsCountByType>(),
    ),
    setFilters: createAction('@productSelection/overview/setFilters', withPayloadType<Filters>()),
    setSorting: createAction('@productSelection/overview//setSorting', withPayloadType<Domain.Sorting<SelectedProductProps>>()),
};

export const overviewReducer = createReducer(initialState, builder =>
    builder
        .addCase(reducerActions.setSelectionType, (state, action) => {
            state.selectionType = action.payload;
        })
        .addCase(reducerActions.setSelectedProducts, (state, action) => {
            state.selectedProducts = action.payload;
        })
        .addCase(reducerActions.setPagination, (state, action) => {
            state.pagination = action.payload;
        })
        .addCase(reducerActions.setPaginationPage, (state, action) => {
            state.pagination.page = action.payload;
        })
        .addCase(reducerActions.setSearch, (state, action) => {
            state.search = action.payload;
        })
        .addCase(reducerActions.setCompleteness, (state, action) => {
            state.productCompleteness = action.payload;
        })
        .addCase(reducerActions.setProductsType, (state, action) => {
            state.productsType = action.payload;
        })
        .addCase(reducerActions.setVisibleColumns, (state, action) => {
            state.visibleColumns = action.payload;
        })
        .addCase(reducerActions.setSortableColumns, (state, action) => {
            state.sortableColumns = action.payload;
        })
        .addCase(reducerActions.setConfiguredIntegrationsType, (state, action) => {
            state.configuredIntegrationsCountByType = action.payload;
        })
        .addCase(reducerActions.setFilters, (state, action) => {
            state.filters = action.payload;
        })
        .addCase(reducerActions.setSorting, (state, action) => {
            state.sorting = action.payload;
        }),
);

export const selectSelectionType: Selector<RootState, Domain.ProductSelectionType> = state => state.productSelection.overview.selectionType;
export const selectSelectedProducts: Selector<RootState, Domain.SelectedProductsPage> = state => {
    const selectedProducts = state.productSelection.overview.selectedProducts;

    if (!selectedProducts) {
        throw new Error('Selected products data not loaded');
    }

    return selectedProducts;
};
export const selectPagination: Selector<RootState, Domain.Pagination> = state => state.productSelection.overview.pagination;
export const selectSearch: Selector<RootState, string> = state => state.productSelection.overview.search;
export const selectCompleteness: Selector<RootState, Domain.ProductCompletenessFilter | ''> = state =>
    state.productSelection.overview.productCompleteness;
export const selectProductsType: Selector<RootState, Domain.SelectedProductType | ''> = state =>
    state.productSelection.overview.productsType;
export const selectVisibleColumns: Selector<RootState, undefined | SelectedProductProps[]> = state =>
    state.productSelection.overview.visibleColumns;
export const selectSortableColumns: Selector<RootState, undefined | SelectedProductProps[]> = state =>
    state.productSelection.overview.sortableColumns;
export const selectConfiguredIntegrationsType: Selector<RootState, undefined | Domain.IntegrationsCountByType> = state =>
    state.productSelection.overview.configuredIntegrationsCountByType;
export const selectFilters: Selector<RootState, Filters> = state => state.productSelection.overview.filters;
export const selectSorting: Selector<RootState, Domain.Sorting<SelectedProductProps>> = state => state.productSelection.overview.sorting;

const updateDataTableSavedSettings =
    (newSettings: SavedSettings): ThunkAction =>
    async (_1, getState) => {
        const state = getState();
        const selectionType = selectSelectionType(state);
        const search = selectSearch(state);
        const visibleColumns = selectVisibleColumns(state);
        const productCompleteness = selectCompleteness(state);
        const productsType = selectProductsType(state);
        const ownershipIds = selectLoggedInUserOwnershipIds(state);
        const filters = selectFilters(state);

        setDataTableSavedSettings(
            SELECTED_PRODUCTS_OVERVIEW_DATA_TABLE_SAVE_KEY +
                '-' +
                selectionType +
                '-' +
                ownershipIds.companyId +
                '-' +
                ownershipIds.branchId,
            {
                search,
                visibleColumns,
                ...newSettings,

                filters: {
                    ...filters,
                    productCompleteness,
                    productsType,
                    ...newSettings.filters,
                },
            },
        );
    };

export const setPaginationPage =
    (page: Domain.Pagination['page']): ThunkAction =>
    async dispatch => {
        await dispatch(reducerActions.setPaginationPage(page));
    };

export const setSearch =
    (search: string): ThunkAction =>
    async dispatch => {
        dispatch(
            updateDataTableSavedSettings({
                search,
            }),
        );

        dispatch(reducerActions.setSearch(search));
    };

export const setCompleteness =
    (productCompleteness: Domain.ProductCompletenessFilter | ''): ThunkAction =>
    async dispatch => {
        await dispatch(
            updateDataTableSavedSettings({
                filters: {
                    productCompleteness,
                },
            }),
        );

        await dispatch(reducerActions.setCompleteness(productCompleteness));
    };

export const setProductsType =
    (productsType: Domain.SelectedProductType | ''): ThunkAction =>
    async dispatch => {
        await dispatch(
            updateDataTableSavedSettings({
                filters: {
                    productsType,
                },
            }),
        );

        await dispatch(reducerActions.setProductsType(productsType));
    };

export const setVisibleColumns =
    (visibleColumns: SelectedProductProps[]): ThunkAction =>
    async dispatch => {
        await dispatch(
            updateDataTableSavedSettings({
                visibleColumns,
            }),
        );

        await dispatch(reducerActions.setVisibleColumns(visibleColumns));
    };
export const setSortableColumns =
    (sortableColumns: SelectedProductProps[]): ThunkAction =>
    async dispatch => {
        await dispatch(
            updateDataTableSavedSettings({
                sortableColumns,
            }),
        );

        await dispatch(reducerActions.setSortableColumns(sortableColumns));
    };
export const setFilters =
    (filters: Filters): ThunkAction =>
    async dispatch => {
        await dispatch(
            updateDataTableSavedSettings({
                filters,
            }),
        );

        await dispatch(reducerActions.setFilters(filters));
    };
export const setSorting =
    (sorting: Domain.Sorting<SelectedProductProps>): ThunkAction =>
    async dispatch => {
        await dispatch(
            updateDataTableSavedSettings({
                sorting,
            }),
        );
        await dispatch(reducerActions.setSorting(sorting));
    };
export const loadSelectedProducts =
    (pushNewHistoryItem?: boolean): ThunkAction =>
    async (dispatch, getState, getNavigate) => {
        const state = getState();
        const navigate = getNavigate();
        const selectionType = selectSelectionType(state);
        const locale = selectCurrentLocale(state);
        const search = selectSearch(state);
        const productCompleteness = selectCompleteness(state);
        const productsType = selectProductsType(state);
        const pagination = selectPagination(state);
        const ownership = selectLoggedInUserOwnership(state);
        const filters = selectFilters(state);
        const sorting = selectSorting(state);

        const urlUpdated = updateURLWithQueryParams(
            {
                baseURL: `/products/selection/${selectionType}`,
                navigate,
                search,
                pagination,
                sorting,
                filters: {
                    ...filters,
                    productCompleteness,
                    productsType,
                },
            },
            {
                replace: !pushNewHistoryItem,
            },
        );

        if (!urlUpdated) {
            const selection = await productSelectionApi.GetSelectedProducts(
                ownership,
                selectionType,
                pagination,
                locale,
                search,
                productCompleteness === '' ? undefined : productCompleteness,
                productsType === '' ? undefined : productsType,
                filters,
                sorting,
            );

            await dispatch(reducerActions.setSelectedProducts(selection));
        }
    };

export const load =
    (options: { urlQuery: URLQuery; urlParams: URLParams }): ThunkAction =>
    async (dispatch, getState) => {
        const state = getState();
        const existingSelectionType = selectSelectionType(state);
        const ownershipIds = selectLoggedInUserOwnershipIds(state);

        const selectionType = options.urlParams.type;
        if (Domain.ProductSelectionTypes.indexOf(selectionType) === -1) {
            throw new Error('Wrong selection type');
        }

        await dispatch(reducerActions.setSelectionType(selectionType));
        if (existingSelectionType !== selectionType) {
            await dispatch(reducerActions.setSearch(''));
            await dispatch(reducerActions.setCompleteness(''));
            await dispatch(reducerActions.setProductsType(''));
            await dispatch(reducerActions.setPaginationPage(1));
        }

        const savedSettings = getDataTableSavedSettings(
            SELECTED_PRODUCTS_OVERVIEW_DATA_TABLE_SAVE_KEY +
                '-' +
                selectionType +
                '-' +
                ownershipIds.companyId +
                '-' +
                ownershipIds.branchId,
        );
        if (savedSettings) {
            if (savedSettings.visibleColumns) {
                await dispatch(reducerActions.setVisibleColumns(savedSettings.visibleColumns as SelectedProductProps[]));
            }
            if (savedSettings.sortableColumns) {
                await dispatch(reducerActions.setSortableColumns(savedSettings.sortableColumns as SelectedProductProps[]));
            }

            if (savedSettings.search) {
                await dispatch(reducerActions.setSearch(savedSettings.search));
            }
            if (savedSettings.filters && savedSettings.filters.productCompleteness) {
                await dispatch(
                    reducerActions.setCompleteness(savedSettings.filters.productCompleteness as Domain.ProductCompletenessFilter),
                );
            }
            if (savedSettings.filters && savedSettings.filters.productsType) {
                await dispatch(reducerActions.setProductsType(savedSettings.filters.productsType as Domain.SelectedProductType));
            }
            if (savedSettings.filters) {
                await dispatch(reducerActions.setFilters(savedSettings.filters));
            }
            if (savedSettings.sorting) {
                await dispatch(reducerActions.setSorting(savedSettings.sorting as Domain.Sorting<SelectedProductProps>));
            }
        }

        const queryParams = getOverviewQueryParameters({
            urlQuery: options.urlQuery,
        });

        const type = options.urlParams.type;
        if (Domain.ProductSelectionTypes.indexOf(type) === -1) {
            throw new Error('Wrong selection type');
        }

        if (queryParams.search !== null) {
            await dispatch(reducerActions.setSearch(queryParams.search));
        }

        if (queryParams.page !== null) {
            await dispatch(reducerActions.setPaginationPage(queryParams.page));
        }

        if (queryParams.filters && queryParams.filters.productCompleteness) {
            if (
                Domain.ProductCompletenessFilterTypes.indexOf(queryParams.filters.productCompleteness) === -1 &&
                queryParams.filters.productCompleteness !== ''
            ) {
                throw new Error('Wrong productCompleteness filter');
            } else {
                await dispatch(reducerActions.setCompleteness(queryParams.filters.productCompleteness || ''));
            }
        }

        if (queryParams.filters && queryParams.filters.productsType) {
            if (
                queryParams.filters.productsType !== 'customProduct' &&
                queryParams.filters.productsType !== 'availableProduct' &&
                queryParams.filters.productsType !== ''
            ) {
                throw new Error('Wrong products type filter');
            } else {
                await dispatch(reducerActions.setProductsType(queryParams.filters.productsType || ''));
            }
        }

        if (queryParams.filters && queryParams.filters.productImageShotType) {
            if (
                queryParams.filters.productImageShotType !== 'productshot' &&
                queryParams.filters.productImageShotType !== 'packshot' &&
                queryParams.filters.productImageShotType !== 'frontal' &&
                queryParams.filters.productImageShotType !== ''
            ) {
                throw new Error('Wrong products image shot type filter');
            } else {
                await dispatch(reducerActions.setFilters({ productImageShotType: queryParams.filters.productImageShotType }));
            }
        }

        if (queryParams.filters && queryParams.filters.hasImportedPrice) {
            if (queryParams.filters.hasImportedPrice !== 'true' && queryParams.filters.hasImportedPrice !== '') {
                throw new Error('Wrong hasImportedPrice filter');
            } else {
                await dispatch(reducerActions.setFilters({ hasImportedPrice: queryParams.filters.hasImportedPrice }));
            }
        }

        if (queryParams.filters && queryParams.filters.hasImportedPromoPrice) {
            if (queryParams.filters.hasImportedPromoPrice !== 'true' && queryParams.filters.hasImportedPromoPrice !== '') {
                throw new Error('Wrong hasImportedPromoPrice filter');
            } else {
                await dispatch(reducerActions.setFilters({ hasImportedPromoPrice: queryParams.filters.hasImportedPromoPrice }));
            }
        }

        if (queryParams.filters && queryParams.filters.hasOverridePrice) {
            if (queryParams.filters.hasOverridePrice !== 'true' && queryParams.filters.hasOverridePrice !== '') {
                throw new Error('Wrong hasOverridePrice filter');
            } else {
                await dispatch(reducerActions.setFilters({ hasOverridePrice: queryParams.filters.hasOverridePrice }));
            }
        }

        if (queryParams.filters && queryParams.filters.hasOverridePromoPrice) {
            if (queryParams.filters.hasOverridePromoPrice !== 'true' && queryParams.filters.hasOverridePromoPrice !== '') {
                throw new Error('Wrong hasOverridePromoPrice filter');
            } else {
                await dispatch(reducerActions.setFilters({ hasOverridePromoPrice: queryParams.filters.hasOverridePromoPrice }));
            }
        }

        if (queryParams.filters && queryParams.filters.categoryIds) {
            await dispatch(reducerActions.setFilters({ categoryIds: queryParams.filters.categoryIds }));
        }

        if (queryParams.filters) {
            await dispatch(reducerActions.setFilters(queryParams.filters));
        }
        if (queryParams.sorting) {
            await dispatch(reducerActions.setSorting(queryParams.sorting as Domain.Sorting<SelectedProductProps>));
        }

        await Promise.all([
            dispatch(loadSelectedProducts()),
            dispatch(loadConfiguredIntegrationsCountByType()),
            dispatch(categoryTreeState.actions.load(options)),
        ]);
    };
