import {
    Pagination,
    Sorting,
    Ownership,
    UsersPage,
    UserDetails,
    CreateUserPayload,
    UpdateUserPayload,
    GrantRolePayload,
    UserSubscribedNotifications,
    UpdateUserSubscribedNotificationsPayload,
} from '@/Domain';

import Endpoint from './Endpoint';

export default class UserEndpoint extends Endpoint {
    public async GetUsers(
        ownership: Ownership,
        pagination: Pagination,
        sorting: Sorting,
        search?: string,
        filters?: {
            companyId?: string;
            branchId?: string;
            name?: string;
            emailAddress?: string;
            resellerId?: string;
            includeInactive?: string;
        },
    ): Promise<UsersPage> {
        let url = '/user/overview';
        if (ownership.type === 'company') {
            url = `/company/${ownership.ownerId}/users`;
        }

        const response = await this.client.get({
            url,
            params: {
                pagination,
                sorting,
                search,
                filters,
            },
        });

        this.guardResponseSchema('#/definitions/UsersPage', response);

        return response;
    }

    public async GetUserDetails(userId: string): Promise<UserDetails> {
        const response = await this.client.get({
            url: '/user/details/' + userId,
        });

        this.guardResponseSchema('#/definitions/UserDetails', response);

        return response;
    }

    public async CreateUser(payload: CreateUserPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/CreateUser',
            body: {
                commandName: 'App\\Domain\\WriteModel\\User\\CreateUser\\CreateUser',
                payload,
            },
        });
    }

    public async UpdateUser(payload: UpdateUserPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/UpdateUser',
            body: {
                commandName: 'App\\Domain\\WriteModel\\User\\UpdateUser\\UpdateUser',
                payload,
            },
        });
    }

    public async InactivateUser(userId: string): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/InactivateUser',
            body: {
                commandName: 'App\\Domain\\WriteModel\\User\\InactivateUser\\InactivateUser',
                payload: {
                    userId,
                },
            },
        });
    }

    public async GrantRole(payload: GrantRolePayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/GrantRole',
            body: {
                commandName: 'App\\Domain\\WriteModel\\User\\GrantRole\\GrantRole',
                payload,
            },
        });
    }

    public GetUsersExportURL(exportType: 'csv' | 'xlsx', isActive?: boolean): string {
        return this.client.buildApiURL(`/user/export/${exportType}`, true, {
            filters: {
                isActive,
            },
        });
    }

    public async GetUserSubscribedNotifications(userId: string): Promise<UserSubscribedNotifications> {
        const response = await this.client.get({
            url: `/user/${userId}/subscribed-notifications`,
        });

        this.guardResponseSchema('#/definitions/UserSubscribedNotifications', response);

        return response;
    }

    public async UpdateUserSubscribedNotifications(payload: UpdateUserSubscribedNotificationsPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/UpdateUserSubscribedNotifications',
            body: {
                commandName: 'App\\Domain\\WriteModel\\User\\UpdateUserSubscribedNotifications\\UpdateUserSubscribedNotifications',
                payload,
            },
        });
    }

    public async AnonymiseUser(userId: string): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/AnonymiseUser',
            body: {
                commandName: 'App\\Domain\\WriteModel\\User\\AnonymizeUser\\AnonymizeUser',
                payload: {
                    userId,
                },
            },
        });
    }
}
