import * as React from 'react';

import styles from './Button.scss';
export type ButtonSize = 'xl' | 'l';
export type ButtonVariant = 'primary' | 'tertiary' | 'plain';

export default function ({
    variantSize = 'l',
    variant = 'tertiary',
    disabled = false,
    className = '',
    children,
    startIcon,
    endIcon,
    onClick,
    onMouseDown,
    ...rest
}: {
    variantSize?: ButtonSize;
    variant?: ButtonVariant;
    disabled?: boolean;
    className?: string;
    startIcon?: React.ReactNode;
    endIcon?: React.ReactNode;
    children?: React.ReactNode;
    onClick?: (event: React.MouseEvent) => void;
    onMouseDown?: (event: React.MouseEvent) => void;
    'data-test-id'?: string;
}) {
    return (
        <button
            type="button"
            disabled={disabled}
            className={`${styles.Button} ${variantSize} ${variant} ${className}`}
            onClick={onClick}
            onMouseDown={onMouseDown}
            {...rest}
        >
            {startIcon}
            {children}
            {endIcon}
        </button>
    );
}
