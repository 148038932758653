import { Pagination, Sorting, QualityLabelsPage, QualityLabel, CreateQualityLabelPayload, UpdateQualityLabelPayload } from '@/Domain';

import Endpoint from './Endpoint';

export default class QualityLabelEndpoint extends Endpoint {
    public async GetQualityLabels(
        pagination: Pagination,
        sorting: Sorting,
        filters?: {
            country?: string;
        },
    ): Promise<QualityLabelsPage> {
        const response = await this.client.get({
            url: '/qualityLabel/overview',
            params: {
                pagination,
                sorting,
                filters,
            },
        });

        this.guardResponseSchema('#/definitions/QualityLabelsPage', response);

        return response;
    }

    public async GetQualityLabelDetails(qualityLabelId: string): Promise<QualityLabel> {
        const response = await this.client.get({
            url: `/qualityLabel/${qualityLabelId}/details`,
        });

        this.guardResponseSchema('#/definitions/QualityLabel', response);

        return response;
    }

    public async CreateQualityLabel(payload: CreateQualityLabelPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/CreateQualityLabel',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Company\\QualityLabel\\UpdateQualityLabel\\UpdateQualityLabel',
                payload,
            },
        });
    }

    public async UpdateQualityLabel(payload: UpdateQualityLabelPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/UpdateQualityLabel',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Company\\QualityLabel\\UpdateQualityLabel\\UpdateQualityLabel',
                payload,
            },
        });
    }
}
