import { ProductsShelfAreaBox, ProductFrame } from '../types';

export default function fillProductArea(config: {
    areaWidth: number;
    areaHeight: number;
    productWidth: number;
    productHeight: number;
    scale: number;
    itemsLimit?: number;
    alignItems: ProductFrame['placedProduct']['alignItems'];
    spacingX: number;
    spacingY: number;
    bottomOffset: number;
}): ProductsShelfAreaBox[] {
    const { areaWidth, areaHeight, productWidth, productHeight, scale, spacingX, spacingY, alignItems, bottomOffset } = config;
    const itemsLimit = config.itemsLimit || 999;
    const itemWidth = productWidth * scale;
    const itemHeight = productHeight * scale;
    const itemSpacedWidth = itemWidth + spacingX;
    const itemSpacedHeight = itemHeight + spacingY;
    const maxItemsPerRow = Math.floor((areaWidth + spacingX) / itemSpacedWidth);
    const itemsOnRows: ProductsShelfAreaBox[][] = [];

    if (maxItemsPerRow === 0) {
        return [];
    }
    const maxRows = Math.floor((areaHeight - bottomOffset + spacingY) / itemSpacedHeight);
    if (maxRows === 0) {
        return [];
    }

    let totalItems = 0;
    if (maxItemsPerRow < itemsLimit) {
        let numRows = 0;
        let itemsOnCurrentRow = 0;
        itemsOnRows[numRows] = [];

        while (totalItems < itemsLimit && numRows < maxRows) {
            totalItems += 1;
            itemsOnCurrentRow += 1;

            itemsOnRows[numRows].push({
                key: totalItems,
                left: 0,
                bottom: 0,
                width: itemWidth,
                height: itemHeight,
            });

            if (itemsOnCurrentRow >= maxItemsPerRow) {
                itemsOnCurrentRow = 0;
                numRows += 1;

                if (numRows < maxRows) {
                    itemsOnRows[numRows] = [];
                }
            }
        }
    } else {
        itemsOnRows[0] = [];
        for (let i = 0; i < itemsLimit; i++) {
            itemsOnRows[0].push({
                key: i,
                left: 0,
                bottom: 0,
                width: itemWidth,
                height: itemHeight,
            });
        }
    }

    const leftAlignPadding = (spacingX + areaWidth - maxItemsPerRow * itemSpacedWidth) / 2;
    const items = [];
    // tslint:disable-next-line:forin
    for (const rowIndex in itemsOnRows) {
        const row = itemsOnRows[rowIndex];
        const rowWidth = row.length * itemSpacedWidth - spacingX;

        let alignPadding = leftAlignPadding;
        if (alignItems === 'center') {
            alignPadding = (areaWidth - rowWidth) / 2;
        } else if (alignItems === 'right') {
            alignPadding = areaWidth - rowWidth;
        } else if (alignItems === 'left') {
            alignPadding = 0;
        }

        // tslint:disable-next-line:forin
        for (const itemIndex in row) {
            const item = row[itemIndex];

            item.left = alignPadding + (itemIndex as unknown as number) * (itemWidth + spacingX);
            item.bottom = bottomOffset + (rowIndex as unknown as number) * (itemHeight + spacingY);

            items.push(item);
        }
    }

    return items;
}
