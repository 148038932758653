import * as React from 'react';

import ControlledSelectTree, { ControlledSelectTreeProps } from './ControlledSelectTree';

type SelectTreeProps = {
    defaultValue?: string[];
};

export type UncontrolledSelectTreeProps = ControlledSelectTreeProps & SelectTreeProps;

interface IState {
    value?: string[];
}

class UncontrolledSelectTree extends React.PureComponent<UncontrolledSelectTreeProps, IState> {
    constructor(props: UncontrolledSelectTreeProps) {
        super(props);

        this.state = {
            value: props.defaultValue || [],
        };
    }

    render() {
        const { onChange, forwardRef, ...rest } = this.props;

        return (
            <ControlledSelectTree
                {...rest}
                forwardRef={forwardRef}
                value={this.state.value as any}
                onChange={this.handleChange}
            />
        );
    }

    protected handleChange = (newValue: string[], callback?: () => void): void => {
        const { onChange } = this.props;

        this.setState(
            {
                value: newValue,
            },
            () => {
                if (onChange) {
                    onChange(this.state.value as any);
                }

                if (callback) {
                    callback();
                }
            },
        );
    };
}

export default React.forwardRef<HTMLSelectElement, UncontrolledSelectTreeProps>((props, ref) => {
    return (
        <UncontrolledSelectTree
            forwardRef={ref}
            {...props}
        />
    );
});
