import * as React from 'react';

import { store } from '../state';
import { ShelfStyleParameters, ShelfWithMeta } from '../types';
import { computeShelfMoveMaxY, computeShelfMoveMinY } from '../utils';
import ShelfMover from './ShelfMover';

// import ClearShelfAction from './ClearShelfAction';
import styles from './styles/Canvas.scss';

export default function CustomShelfBody(props: {
    shelf: ShelfWithMeta;
    prevShelf?: ShelfWithMeta;
    nextShelf?: ShelfWithMeta;
    hideMoverHandle?: boolean;
}) {
    const { state } = React.useContext(store);
    const { shelf, nextShelf, hideMoverHandle } = props;

    const LayoutParams = ShelfStyleParameters[state.canvas.style];

    return (
        <>
            <div
                className={styles.CustomShelf}
                style={{
                    backgroundColor: state.canvas.headerColor || '#fff',
                }}
            />

            <ShelfMover
                style={{
                    height: LayoutParams.shelfBaseHeight,
                }}
                shelf={shelf.shelf}
                belowShelf={nextShelf ? nextShelf.shelf : undefined}
                y={shelf.shelf.y + shelf.shelf.height}
                minY={computeShelfMoveMinY(state.canvas, shelf)}
                maxY={computeShelfMoveMaxY(state.canvas, nextShelf)}
                hideMoverHandle={hideMoverHandle}
                otherActions={
                    nextShelf ? (
                        <span />
                    ) : // <ClearShelfAction
                    //     shelfId={shelf.shelf.id}
                    //     shelfHeight={shelf.shelf.height}
                    //     nextShelfId={nextShelf.shelf.id}
                    // />
                    null
                }
            />
        </>
    );
}
